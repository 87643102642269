import React, {FC, useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import {Card, CardBody, Col, Row} from "reactstrap";
import ModalAddTypeNutrition from "./ModalAddTypeNutrition";
import ModalUpdateTypeNutrition from "./ModalUpdateTypeNutrition";
import AxiosClient from "../../../api/AxiosClient";
import Notification from "../../widgets/Notification";

interface IListTypesNutrition {
    id: string | number,
    setCateringOrganizers: (value: any[]) => void,
    selectTypePlannedMenuFilter: string
}

const ListTypesNutrition: FC<IListTypesNutrition> = ({id, setCateringOrganizers, selectTypePlannedMenuFilter}) => {

    const [addTypeNutrition, setAddTypeNutrition] = useState(false);
    const [addUpdateTypeNutrition, setAddUpdateTypeNutrition] = useState(false);
    const [selectTypeNutrition, setSelectTypeNutrition] = useState<null | any>(null);
    const [typePlannedMenu, setTypePlannedMenu] = useState<any[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState(false);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<string>('');
    const [notificationMessage, setNotificationMessage] = useState<string>('');

    useEffect(() => {
        fetchTypePlannedMenu(currentPage, perPage, selectTypePlannedMenuFilter)
    }, [selectTypePlannedMenuFilter, id]);

    const fetchTypePlannedMenu = async (page: number, size = perPage, selectTypePlannedMenuFilter = '') => {
        setSearch(true)
        await AxiosClient.get(`catering_organizaers/${id}/type_planned_menu`, {params: {page: page, per_page: size, selectTypePlannedMenuFilter}})
            .then((response) => {
                setSearch(false)
                setTypePlannedMenu(response.data.typePlannedMenu);
                setTotalRows(response.data.total);
            })
            .catch(() => {
                console.log('error')
                setSearch(false)
            });
    };

    const handlePageChange = (page: number) => {
        fetchTypePlannedMenu(page, perPage, selectTypePlannedMenuFilter);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        await fetchTypePlannedMenu(page, newPerPage, selectTypePlannedMenuFilter);
        setPerPage(newPerPage);
    };

    const updateData = () => {
        fetchTypePlannedMenu(currentPage, perPage, selectTypePlannedMenuFilter)
    }

    const columns = [
        {
            id: "date",
            name: <span className='font-weight-bold accessibility_size_table text-left w-100 ms-5'>Тип планового меню</span>,
            cell: (row: any) => <p className='text-left w-100 ms-5 accessibility_size_table'>{row.type_planned_menu.name}</p>,
            center: true,
            width: '300px',
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Категория</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.age_category.name}</span>,
            center: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Цена (руб.)</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.price}</span>,
            center: true,
            wrap: true
        },
        {
            cell: (row: any) => <i className="bx bx-archive-in fs-3 cursor-pointer" title="Добавить в архив" onClick={() => {
                AxiosClient.post(`/catering_organizer_type_planned_menu/archive/${row.id}`)
                    .then(() => window.location.href = '/types_nutrition')
                    .catch(() => console.log('error'))
            }}></i>,
            center: true,
            width: '70px'
        },
    ]

    return (
        <Card>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={typePlannedMenu}
                    noDataComponent={'Нет данных'}
                    //progressComponent={<Spinner></Spinner>}
                    //progressPending={search}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationDefaultPage={currentPage}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно типов:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                    onRowClicked={
                        (row) => {
                            if(window.innerWidth <= 768){
                                setSelectTypeNutrition(row)
                                setAddUpdateTypeNutrition(true)
                            }
                        }
                    }
                    onRowDoubleClicked={
                        (row) => {
                            if(window.innerWidth > 768){
                                setSelectTypeNutrition(row)
                                setAddUpdateTypeNutrition(true)
                            }
                        }
                    }
                />
                <Row className="m-0 mt-2">
                    <Col sm={12} lg={4} xxl={3}>
                        <button className="btn btn-primary w-100 mt-1" id="addNewProject" onClick={() => setAddTypeNutrition(true)}>Добавить</button>
                    </Col>
                </Row>
            </CardBody>
            {
                addTypeNutrition &&
                <ModalAddTypeNutrition id={id} updateData={() => updateData()} addTypeNutrition={addTypeNutrition} setAddTypeNutrition={(value) => setAddTypeNutrition(value)} setNotificationType={(value) => setNotificationType(value)} setShowNotification={(value) => setShowNotification(value)} setNotificationMessage={(value) => setNotificationMessage(value)}/>
            }
            {
                addUpdateTypeNutrition &&
                <ModalUpdateTypeNutrition updateData={() => updateData()} addUpdateTypeNutrition={addUpdateTypeNutrition} setUpdateAddTypeNutrition={(value) => setAddUpdateTypeNutrition(value)} data={selectTypeNutrition} setNotificationType={(value) => setNotificationType(value)} setShowNotification={(value) => setShowNotification(value)} setNotificationMessage={(value) => setNotificationMessage(value)}/>
            }
            {showNotification && <Notification type={notificationType} message={notificationMessage} setShowNotification={(value: boolean) => setShowNotification(value)}/>}
        </Card>
    );
};

export default ListTypesNutrition;
