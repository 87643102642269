import React, {FC, useState} from 'react';
import DataTable from "react-data-table-component";
import AddDisinfectantGeneralCleaning from "../GeneralCleaning/AddDisinfectantGeneralCleaning";
import AddVentilation from "./AddVentilation";
interface  IUpdateVentilations{
    addMyVentilation: any,
    setAddMyVentilation(value: any): void
}

const UpdateVentilations: FC <IUpdateVentilations> = ({addMyVentilation, setAddMyVentilation}) => {

    const [updateVentilation, setUpdateVentilation] = useState(false);
    const [selectVentilation, setSelectVentilation] = useState<any | null>(null);

    const columns : any = [
        {
            name: <span className='font-weight-bold fs-13'>Вид</span>,
            selector: (row: any) => row.vid_ventilation,
            center: true,
            compact: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Начало</span>,
            selector: (row: any) => row.time,
            center: true,
            compact: true,
            width: '90px'
        },
        {
            name: <span className='font-weight-bold fs-13'>Окончание</span>,
            selector: (row: any) => row.endtime,
            center: true,
            compact: true,
            width: '90px'
        },
        {
            name: <span className='font-weight-bold fs-13'>t°</span>,
            selector: (row: any) => row.temperature,
            center: true,
            compact: true,
            width: '60px'
        },
        {
            name: <span className='font-weight-bold fs-13'></span>,
            selector: (row: any) => <i className="bx bx-trash fs-24 cursor-pointer" onClick={() => {removeVent(row.uid)}}></i>,
            width: '60px'
        },
    ]

    const removeVent = (uid: string) => {
        setAddMyVentilation(addMyVentilation.filter((item: any) => item.uid !== uid));
    }
    return (
        <>
            <DataTable
                columns={columns}
                data={addMyVentilation}
                noDataComponent={'Нет данных'}
                pagination
                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                paginationComponentOptions={
                    {
                        rowsPerPageText: 'Видно:',
                        rangeSeparatorText: 'из'
                    }
                }
                highlightOnHover
                customStyles={{
                    rows: {
                        highlightOnHoverStyle: {
                            backgroundColor: "#ffebb5"
                        },
                    },
                    expanderRow: {
                        style: {
                            backgroundColor: "#ffebb5",
                            paddingTop: '5px',
                            paddingBottom: '5px'
                        },
                    },
                }}
                onRowClicked={(row) => {
                    setSelectVentilation(row);
                    setUpdateVentilation(true);
                }}
            />
            <button className="btn btn-primary" type="button"
                    onClick={() => {
                        setSelectVentilation(null)
                        setUpdateVentilation(true)}}>
                Добавить
            </button>
            <AddVentilation updateVentilation={updateVentilation}
                            setUpdateVentilation={(value: any) => setUpdateVentilation(value)}
                            addMyVentilation={addMyVentilation}
                            setAddMyVentilation={(value: any) => setAddMyVentilation(value)}
                            selectVentilation={selectVentilation}/>
        </>
    );
};

export default UpdateVentilations;
