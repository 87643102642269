import React, {FC, useState} from 'react';
import {Col, Input} from "reactstrap";
import {Field} from "formik";
import DataTable from "react-data-table-component";
import {useAppSelector} from "../../../hooks/redux";
import {IPage} from "../../../models/IPage";
interface ITableSelectPage {
    setCheckAll: (check: boolean) => void
}
const TableSelectPage: FC<ITableSelectPage> = ({setCheckAll}) => {

    const {pages} = useAppSelector(state => state.AdminReducer)
    const [search, setSearch] = useState<string>('')
    const columns: any = [
        {
            name: <Input type="checkbox" style={{width: '20px', height: '20px'}} onChange={(e) => setCheckAll(e.target.checked)} />,
            selector: (row: IPage) => <Field type="checkbox" name='page' style={{width: '20px', height: '20px'}} value={`${row.id}`}/>,
            width: '140px'
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Имя</span>,
            selector: (row: IPage) => `${row.name}(${row.header_page.name})(${row.group_page.name})`,
            cell: (row: IPage) => <span className="accessibility_size_table">{(`${row.name}(${row.header_page.name})(${row.group_page.name})`)}</span>,
            sortable: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Путь</span>,
            selector: (row: IPage) => row.path,
            cell: (row: IPage) => <span className="accessibility_size_table">{row.path}</span>,
            sortable: true,
            wrap: true
        }
    ];

    return (
        <>
            <Col lg={3}>
                <div className="mb-3">
                    <input onChange={(e) => setSearch(e.target.value)} className="form-control" placeholder="Поиск"/>
                </div>
            </Col>
            <DataTable
                columns={columns}
                data={pages.filter((item) => (item.path.indexOf(search) !== -1 || item.name.indexOf(search) !== -1 || item.group_page.name.indexOf(search) !== -1 || item.header_page.name.indexOf(search) !== -1))}
                pagination
                paginationComponentOptions={
                    {
                        rowsPerPageText: 'Видно страниц:',
                        rangeSeparatorText: 'из'
                    }
                }
                noDataComponent={'Нет страниц'}
            />
        </>

    )
};

export default TableSelectPage;
