import React, {useEffect} from 'react';
import {Col, Container,  Row} from "reactstrap";
import AxiosClient from "../api/AxiosClient";
import {
    setChildren
} from "../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../hooks/redux";
import {Helmet} from "react-helmet";
import WidgetRequiredSelectGroup from "../components/widgets/requireds/WidgetRequiredSelectGroup";
import WidgetRequiredFlatpikerPeriod from "../components/widgets/requireds/WidgetRequiredFlatpikerPeriod";
import WidgetTableAllInfoGroup from "../components/widgets/tables/WidgetTableAllInfoGroup";
import WidgetTableAllDeviationsGroup from "../components/widgets/tables/WidgetTableAllDeviationsGroup";
import WidgetGraphProjectOverview from "../components/widgets/graphs/WidgetGraphProjectOverview";
import WidgetGraphRound from "../components/widgets/graphs/WidgetGraphRound";
import NotificationShow from "../components/widgets/NotificationShow";


const MainPage = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        AxiosClient.post('/childrens')
            .then(
                res => {
                    dispatch(setChildren(res.data))
                }
            )
    }, [dispatch])

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Табель основной</title>
        </Helmet>
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col xxl={3}>
                        <WidgetRequiredFlatpikerPeriod/>
                        <WidgetRequiredSelectGroup/>
                        <WidgetGraphRound/>
                        <WidgetTableAllDeviationsGroup/>
                    </Col>
                    <Col xxl={9}>
                        <WidgetGraphProjectOverview/>
                        <WidgetTableAllInfoGroup/>
                    </Col>
                </Row>
                <NotificationShow />
            </Container>
        </div>

    </>)
};

export default MainPage;
