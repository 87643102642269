import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import bgImage from "../../assets/images/small/img-5.jpg";
import avatar1 from "../../assets/images/users/avatarSeminar.jpg";
import {Link} from "react-router-dom";
import AxiosClient from "../../api/AxiosClient";
import axiosClient from "../../api/AxiosClient";

const Seminar = () => {

    //TODO: сделать боллее оптимизированную синхронизацию с БД.
    const [countUser, setCountUser] = useState(0);

    useEffect(() => {
        AxiosClient.get('/seminar_count_user', {params: {id: '1'}}).then(r => {
            setCountUser(r.data)
        })
    }, []);

    const handleExport = () => {
        axiosClient.get('/seminar/export/1', { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'seminar.xlsx');
                document.body.appendChild(link);
                link.click();
            });
    };

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Семинары</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Card>
                        <CardHeader>
                            <h5>Февраль 2024</h5>
                        </CardHeader>
                        <CardBody>
                            <Row className="team-list grid-view-filter">
                                <Col lg={6} xl={4} xxl={3}>
                                    <Card className="team-box">
                                        <div className="team-cover">
                                            {
                                                <img src={bgImage} alt="Decorative img" className="img-fluid"/>
                                            }
                                        </div>
                                        <CardBody className="p-4">
                                            <Row className="align-items-center team-row">
                                                <Col lg={4} className="col">
                                                    <div className="team-profile-img">
                                                        <div
                                                            className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
                                                            <img src={avatar1} alt="Аvatar"
                                                                 className="img-fluid d-block rounded-circle"/>
                                                        </div>
                                                        <div className="team-content">
                                                            <Link to="#"><h5 className="fs-16 mb-1">Цифровизация
                                                                посещаемости основного образования в дошкольном
                                                                образовательном учреждении</h5></Link>
                                                            {/*<p className="text-muted mb-0">Описание семинар 1</p>*/}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={4} className="col">
                                                    <Row className="text-muted text-center justify-content-center">
                                                        <Col xs={12}>
                                                            <h5 className="mb-1">01.02.2024</h5>
                                                            <p className="text-muted mb-0">Дата</p>
                                                        </Col>
                                                        <Row className="d-flex justify-content-center mt-1">
                                                            <Col xs={6} className="border-end border-end-dashed">
                                                                <h5 className="mb-1">{countUser}</h5>
                                                                <p className="text-muted mb-0">Участников</p>
                                                            </Col>
                                                            <Col xs={6}>
                                                                <h5 className="mb-1">10:00 МСК</h5>
                                                                <p className="text-muted mb-0">Время</p>
                                                            </Col>
                                                        </Row>
                                                    </Row>
                                                </Col>
                                                <Col lg={2} className="col">
                                                    <div className="text-end">
                                                        <button className="btn btn-light view-btn"
                                                                onClick={handleExport}>Экспортировать записавшихся</button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Row>
            </Container>
        </div>
    );
};

export default Seminar;
