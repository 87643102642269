import React, {FC, useState} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import AxiosClient from "../../../api/AxiosClient";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../hooks/redux";

interface IModalNotifyDebt {
    openModalNotifyDebt: boolean,
    setOpenModalNotifyDebt: (value: boolean) => void,
    selectSchool: string,
    selectBuild: string,
    selectClass: string
}

const ModalNotifyDebt: FC<IModalNotifyDebt> = ({setOpenModalNotifyDebt, openModalNotifyDebt, selectClass, selectSchool, selectBuild}) => {

    const dispatch = useAppDispatch();
    const [type, setType] = useState('debt');
    useEscapeKey(() => dispatch(setModalAdd(false)), openModalNotifyDebt, "ModalNotifyDebt");

    return (
        <Modal id="ModalNotifyDebt" isOpen={openModalNotifyDebt}
               toggle={() => setOpenModalNotifyDebt(!openModalNotifyDebt)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setOpenModalNotifyDebt(!openModalNotifyDebt)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Уведомление о задолженности
            </ModalHeader>
            <ModalBody>
                <div>
                    <select className="form-select" name="type" id="type" value={type}
                            onChange={event => setType(event.target.value)}>
                        <option value="debt">Уведомить о задолженности</option>
                        <option value="replenish">Уведомить о пополнении</option>
                        <option value="low">Уведомить о низком балансе</option>
                    </select>
                </div>
                <div className="mt-2">
                    {
                        type === 'debt' &&
                        <Formik initialValues={{
                            message: 'Мы обращаем ваше внимание на то, что на ваших лицевых счетах имеется задолженность по оплате в размере [Сумма общая]. Просим вас произвести оплату в ближайшее время.\n' +
                                '\n' +
                                'ℹ️ Детали задолженности:\n' +
                                '\n' +
                                'Сумма: [Сумма]\n' +
                                'Номер счета: [Номер счета]\n' +
                                'На дату: [Дата]\n' +
                                '--------------------------------\n' +
                                'Сумма: [Сумма]\n' +
                                'Номер счета: [Номер счета]\n' +
                                'На дату: [Дата]\n' +
                                '📅 Просим вас внести платеж в течении [Количество дней]',
                            countDay: 3
                        }}
                                validationSchema={
                                    Yup.object({
                                        countDay: Yup.number().typeError('Только числа').integer('Только целые числа').required('Обязательное поле')
                                    })
                                }
                                onSubmit={(values) => {
                                    AxiosClient.post('/users/notify_telegram/payments', {type, countDay: values.countDay, classId: selectClass, schoolId: selectSchool, buildingId: selectBuild})
                                        .then(() => {

                                        })
                                        .catch(() => {
                                            console.log('error')
                                        })
                                }}>
                            {
                                ({errors, touched}) => (
                                    <Form>
                                        <div>
                                            <label htmlFor="">Шаблон</label>
                                            <Field placeholder="Сообщение для отправки о задолженности" rows={14}
                                                   disabled name="message" as="textarea" className="form-control" style={{resize: 'none'}}/>
                                        </div>
                                        <div className="mt-2">
                                            <label htmlFor="">Колличество дней</label>
                                            <Field placeholder="Укажите дни для пополнения" name="countDay"
                                                   className={`form-control ${errors.countDay && touched.countDay && "border border-2 border-danger"}`}/>
                                            <ErrorMessage name="countDay">{errors => <div className="text-danger">{errors}</div>}</ErrorMessage>
                                        </div>
                                        <div className="mt-2">
                                            <SubmitWithLoading text="Отправить"/>
                                        </div>
                                    </Form>
                                )
                            }
                        </Formik>
                    }
                    {
                        type === 'replenish' &&
                        <Formik initialValues={{
                            message: 'Мы обращаем ваше внимание на то, что ваши лицевые счета нуждаются в пополнении баланса.\n' +
                                '\n' +
                                'ℹ️ Детали лицевых счетов:\n' +
                                '\n' +
                                'Сумма: [Сумма]\n' +
                                'Номер счета: [Номер счета]\n' +
                                'На дату: [Дата]\n' +
                                '--------------------------------\n' +
                                'Сумма: [Сумма]\n' +
                                'Номер счета: [Номер счета]\n' +
                                'На дату: [Дата]\n' +
                                '📅 Просим вас внести платеж'
                        }} onSubmit={(values) => {
                            AxiosClient.post('/users/notify_telegram/payments', {type, classId: selectClass, schoolId: selectSchool, buildingId: selectBuild})
                                .then(() => {

                                })
                                .catch(() => {
                                    console.log('error')
                                })
                        }}>
                            {
                                ({errors, touched}) => (
                                    <Form>
                                        <div>
                                            <label htmlFor="">Шаблон</label>
                                            <Field placeholder="Сообщение для отправки о задолженности" rows={13} resize={"false"} disabled
                                                   name="message" as="textarea" className="form-control" style={{resize: 'none'}}/>
                                        </div>
                                        <div className="mt-2">
                                            <SubmitWithLoading text="Отправить"/>
                                        </div>
                                    </Form>
                                )
                            }
                        </Formik>
                    }
                    {
                        type === 'low' &&
                        <Formik initialValues={{
                            message: 'Мы обращаем ваше внимание на текущий статус вашего баланса. Ваш баланс находится на уровне, который может потребовать дополнительного пополнения.\n' +
                                '\n' +
                                'ℹ️ Детали лицевых счетов:\n' +
                                '\n' +
                                'Сумма: [Сумма]\n' +
                                'Номер счета: [Номер счета]\n' +
                                'На дату: [Дата]\n' +
                                '--------------------------------\n' +
                                'Сумма: [Сумма]\n' +
                                'Номер счета: [Номер счета]\n' +
                                'На дату: [Дата]\n' +
                                '📅 Просим вас внести платеж',
                            sum: 100
                        }} onSubmit={(values) => {
                            AxiosClient.post('/users/notify_telegram/payments', {type, sum: values.sum, classId: selectClass, schoolId: selectSchool, buildingId: selectBuild})
                                .then(() => {

                                })
                                .catch(() => {
                                    console.log('error')
                                })
                        }}>
                            {
                                ({errors, touched}) => (
                                    <Form>
                                        <div>
                                            <label htmlFor="">Шаблон</label>
                                            <Field placeholder="Сообщение для отправки о задолженности" rows={14} disabled
                                                   name="message" as="textarea" className="form-control" style={{resize: 'none'}}/>
                                        </div>
                                        <div className="mt-2">
                                            <label htmlFor="">Сумма до которой отправить уведомление</label>
                                            <Field placeholder="Сумма" name="sum"
                                                   className={`form-control ${errors.sum && touched.sum && "border border-2 border-danger"}`}/>
                                            <ErrorMessage name="sum">{errors => <div className="text-danger">{errors}</div>}</ErrorMessage>
                                        </div>
                                        <div className="mt-2">
                                            <SubmitWithLoading text="Отправить"/>
                                        </div>
                                    </Form>
                                )
                            }
                        </Formik>
                    }
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ModalNotifyDebt;
