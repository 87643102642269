import React, {FC, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import WidgetService from "../../../api/services/widgetService/WidgetService";
import {setToUpdateGraphMainPage} from "../../../store/reducers/widgets/WidgetsSlice";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {ProjectsJournalHaccpGeneralCleaning, ProjectsJournalPurchaseMaterials} from "../../widgets/ProjectStatusCharts";

const WidgetGraphPurchaseMaterials = () => {
    const {toUpdateGraphMainPage, date, journalPurchaseMaterials} = useAppSelector(state => state.WidgetReducer)
    const dispatch = useAppDispatch();


    useEffect(() => {
        toUpdateGraphMainPage &&
        WidgetService.getDeviationGroupMonth(null, date)
            .then(res => {
                dispatch(setToUpdateGraphMainPage(false))
            })
    }, [toUpdateGraphMainPage, date, dispatch])

    return (
        <>
            <Card>
                <CardHeader className="border-0 align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Заявка на приобретение материалов</h4>
                </CardHeader>

                <CardHeader className="p-0 border-0 bg-soft-light">
                    <Row className="g-0 text-center">
                        <Col xs={6}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <span className="counter-value">
                                        {journalPurchaseMaterials ? journalPurchaseMaterials.length : 'Загрузка'}
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Количество заявок</p>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <span className="counter-value">
                                        {journalPurchaseMaterials ? journalPurchaseMaterials.reduce((sum: any, item: any) => {
                                            const materialsCount = item.materials.reduce((materialSum: any, material: any) => materialSum + material.count, 0);
                                            return sum + materialsCount;
                                        }, 0) : 'Загрузка'}
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Количество материалов</p>
                            </div>
                        </Col>
                    </Row>
                </CardHeader>

                <CardBody className="p-0 pb-2">
                    <div>
                        {
                            journalPurchaseMaterials && <ProjectsJournalPurchaseMaterials series={journalPurchaseMaterials}
                                                                                                dataColors='["--vz-green", "--vz-warning", "--vz-danger"]'/>
                        }
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default WidgetGraphPurchaseMaterials;
