import React, {FC} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {Field} from "formik";
import MaskedInput from "react-text-mask";

interface IOtherChildrenForm{
    listChildrenInfo: any[],
    setListChildrenInfo: (value: any) => void,
    fieldErrors: any,
    citizenships: any[];
}

const OtherChildrenForm: FC <IOtherChildrenForm> = ({listChildrenInfo, setListChildrenInfo, fieldErrors, citizenships}) => {

    const listChildrenInfoAdd = (typeChild: number) => {
        const object = {
            fullNameListChildrenInfo: '',
            birthdateListChildrenInfo: '',
            genderListChildrenInfo: 'male',
            certificateBirthListChildrenInfo: '',
            dateCertificateListChildrenInfo: '',
            whoCertificateListChildrenInfo: '',
            snilsListChildrenInfo: '',
            citizenshipListChildrenInfo: '',
            typeListChildrenInfo: typeChild
        }
        const data = [...listChildrenInfo, object];

        setListChildrenInfo(data);
    }

    const  handleFormChange = (event: any, index: number) => {
        let data = [...listChildrenInfo];
        data[index][event.target.name] = event.target.value;
        setListChildrenInfo(data);
    }

    const removeFields = (index: number) => {
        let data = [...listChildrenInfo];
        data.splice(index, 1)
        setListChildrenInfo(data)
    }

    return (
        <>
            <Row>
                <p className="mx-3">*Если есть дети старше возраста зачисляемого ребенка, необходимо внести данные о каждом таком ребенке  для этого нажмите кнопку «Добавить старшего ребенка».</p>
            </Row>
            <Row>
                <Col xxl={3}>
                    <Button type="button" color="primary"  className="mt-1 w-100 btn bg-success border-success"
                            onClick={() => listChildrenInfoAdd(1)}>
                        Добавить старшего ребенка
                    </Button>
                </Col>
                <Col xxl={3}>
                    <Button type="button" color="primary"  className="mt-1 mb-3 w-100 btn bg-primary"
                            onClick={() => listChildrenInfoAdd(0)}>
                        Добавить ребенка для зачисления
                    </Button>
                </Col>
            </Row>
            <Row>
                {
                    listChildrenInfo.map((childrenInfo, index) => {
                        return <Card key={index} className={childrenInfo.typeListChildrenInfo == 0 ? "bg-primary bg-opacity-25" : "bg-success bg-opacity-25"}>
                            <CardHeader className={"d-flex mt-sm-3 mt-lg-3 mt-xl-3 mt-xll-3 text-center"} style={{ backgroundColor: 'rgba(255, 255, 255, 0)' }}>
                                <h4 className="mr-3">{childrenInfo.typeListChildrenInfo == 0 ? "Данные ребенка для зачисления №"+(index+1): "Данные старшего ребенка №"+(index+1)}</h4>
                                <i className="bx bx-trash fs-3 cursor-pointer ms-1" title="Удалить карточку" onClick={() => {removeFields(index)}}></i>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={12}>
                                        <Field
                                            name='fullNameListChildrenInfo'
                                            value={childrenInfo.fullNameListChildrenInfo}
                                            placeholder="ФИО ребенка"
                                            className={`mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 ${
                                                fieldErrors[index]?.fullNameListChildrenInfo ? "is-invalid" : ""
                                            }`}
                                            onChange={(value: any) => handleFormChange(value, index)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <Field name="birthdateListChildrenInfo">
                                            {({
                                                  field,
                                                  form: {touched, errors},
                                              }:any) => (
                                                <MaskedInput
                                                    name="dateCertificate"
                                                    mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                    placeholder="Дата рождения"
                                                    {...field}
                                                    className={`mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 ${
                                                        fieldErrors[index]?.birthdateListChildrenInfo ? "is-invalid" : ""
                                                    }`}
                                                    value={childrenInfo.birthdateListChildrenInfo}
                                                    onChange={(value: any) => handleFormChange(value, index)}
                                                />
                                            )}
                                        </Field>
                                    </Col>
                                    <Col sm={6}>
                                        <Field
                                            as="select"
                                            name="genderListChildrenInfo"
                                            className="mt-2 form-select mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0"
                                            value={childrenInfo.genderListChildrenInfo}
                                            onChange={(value: any) => handleFormChange(value, index)}
                                        >
                                            <option value="male">Мужской</option>
                                            <option value="female">Женский</option>
                                        </Field>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Field
                                            as="select"
                                            name="citizenshipListChildrenInfo"
                                            value={childrenInfo.citizenshipListChildrenInfo}
                                            className={`mt-2 form-select mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 ${
                                                fieldErrors[index]?.citizenshipListChildrenInfo ? "is-invalid" : ""
                                            }`}
                                            onChange={(value: any) => handleFormChange(value, index)}
                                        >
                                            <option value="" disabled hidden>Выберите гражданство</option>
                                            {citizenships.map((citizenship) => (
                                                <option key={citizenship.id} value={citizenship.id}>
                                                    {citizenship.name}
                                                </option>
                                            ))}
                                        </Field>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Field
                                            name="certificateBirthListChildrenInfo"
                                            placeholder="Св-во о рождении"
                                            className={`mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 ${
                                                fieldErrors[index]?.certificateBirthListChildrenInfo ? "is-invalid" : ""
                                            }`}
                                            value={childrenInfo.certificateBirthListChildrenInfo}
                                            onChange={(value: any) => handleFormChange(value, index)}/>
                                    </Col>
                                    <Col>
                                        <Field name="dateCertificateListChildrenInfo">
                                            {({
                                                  field,
                                                  form: {touched, errors},
                                              }:any) => (
                                                <MaskedInput
                                                    name="dateCertificate"
                                                    mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                    placeholder="Дата выдачи"
                                                    {...field}
                                                    className={`mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 ${
                                                        fieldErrors[index]?.dateCertificate ? "is-invalid" : ""
                                                    }`}
                                                    value={childrenInfo.dateCertificate}
                                                    onChange={(value: any) => handleFormChange(value, index)}
                                                />
                                            )}
                                        </Field>
                                    </Col>
                                    <Col xxl={6}>
                                        <Field
                                            name="whoCertificateListChildrenInfo"
                                            placeholder="Кем выдано"
                                            className={`mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 ${
                                                fieldErrors[index]?.whoCertificateListChildrenInfo ? "is-invalid" : ""
                                            }`}
                                            value={childrenInfo.whoCertificateListChildrenInfo}
                                            onChange={(value: any) => handleFormChange(value, index)}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Field
                                            name='snilsListChildrenInfo'
                                            value={childrenInfo.snilsListChildrenInfo}
                                            placeholder="СНИЛС"
                                            className={`mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 ${
                                                fieldErrors[index]?.snilsListChildrenInfo ? "is-invalid" : ""
                                            }`}
                                            onChange={(value: any) => handleFormChange(value, index)}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Field
                                            as="select"
                                            name="typeListChildrenInfo"
                                            className={`mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 ${
                                                fieldErrors[index]?.typeListChildrenInfo ? "is-invalid" : ""
                                            }`}
                                            value={childrenInfo.typeListChildrenInfo}
                                            onChange={(value: any) => handleFormChange(value, index)}
                                        >
                                            <option value="0">Ребенок для зачисления</option>
                                            <option value="1">Старший ребенок</option>
                                        </Field>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    })
                }
            </Row>
        </>
    );
};

export default OtherChildrenForm;
