import React, {FC, useState} from 'react';
import {Alert, Modal, ModalBody, ModalHeader} from "reactstrap";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import Sleep from "./FormDailyReport/Sleep";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd as setMA} from "../../../store/reducers/widgets/WidgetsSlice";
import ModalConfirmExitHaccp from "../../journalHaccp/ModalConfirmExitHaccp";

interface IModalAddDayliReportNutrition {
    modalAdd: boolean,
    setModalAdd: (modalAdd: boolean) => void
}
const ModalAddDayliReportSleep: FC<IModalAddDayliReportNutrition> = ({modalAdd, setModalAdd}) => {

    const {isError, successUpdate} = useAppSelector(state => state.WidgetReducer);

    const dispatch = useAppDispatch();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [showConfirmExit, setShowConfirmExit] = useState(false);

    const handleAttemptClose = () => {
        if (isFormDirty) {
            setShowConfirmExit(true);
        } else {
            setModalAdd(false);
        }
    };

    const handleConfirmClose = () => {
        setShowConfirmExit(false);
        setModalAdd(false);
        setIsFormDirty(false);
    };

    const handleStay = () => {
        setShowConfirmExit(false);
    };

    useEscapeKey(handleAttemptClose, modalAdd, "ModalAddDayliReportSleep");

    return (
        <>
        <Modal id="ModalAddDayliReportSleep" isOpen={modalAdd} toggle={handleAttemptClose}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={handleAttemptClose} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Отчет за день(сон)
            </ModalHeader>
            <ModalBody>
                {successUpdate && <Alert>{successUpdate}</Alert>}
                {isError && <Alert color="danger">{isError}</Alert>}
                <Sleep setModalAdd={setModalAdd} setIsFormDirty={setIsFormDirty}/>
            </ModalBody>
        </Modal>
            <ModalConfirmExitHaccp
                isOpen={showConfirmExit}
                onClose={handleConfirmClose}
                onStay={handleStay}
                hasUnsavedChanges={isFormDirty}
            />
        </>
    );
};

export default ModalAddDayliReportSleep;
