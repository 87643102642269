import React, { FC, useEffect, useState } from 'react';
import AxiosClient from "../../../api/AxiosClient";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import moment from "moment";

interface IAddLamps {
    updateLamps: boolean;
    setUpdateLamps: (value: boolean) => void;
    addLamp: any[];
    setAddLamp: (value: any[]) => void;
    selectLamps: any;
}

const AddLamps: FC<IAddLamps> = ({
                                                                                 updateLamps,
                                                                                 setUpdateLamps,
                                                                                 addLamp,
                                                                                 setAddLamp,
                                                                                 selectLamps
                                                                             }) => {
    const [lamps, setLamps] = useState<any[]>([]);

    useEffect(() => {
        // AxiosClient.get('haccp/getLamps')
        //     .then((r: any) => setLamps(r.data))
        //     .catch((error) => console.log(error));
    }, []);

    return (
        <Modal
            id="createProjectModal"
            isOpen={updateLamps}
            toggle={() => setUpdateLamps(!updateLamps)}
            modalClassName="zoomIn"
            tabIndex={-1}
            centered
        >
            <ModalHeader
                toggle={() => setUpdateLamps(!updateLamps)}
                className="p-3 bg-soft-primary"
                id="createProjectModalLabel"
            >
                Обновление дезифицирующих средств
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={{
                        uid: selectLamps?.uid || '',
                        concentration: selectLamps?.concentration || ''
                    }}
                    validationSchema={
                        Yup.object({
                            uid: Yup.string().required('Требуется выбрать дезинфицирующее средство'),
                            concentration: Yup.string().required('Требуется указать концентрацию')
                        })}
                    onSubmit={(values) => {
                        const newObject = {
                            uid: values.uid,
                            disinfectant_name: lamps.find((value) => value.uid === values.uid).name,
                            concentration: values.concentration,
                            remove: selectLamps ? selectLamps.remove : "0"
                        };

                        setAddLamp(
                            Array.isArray(addLamp)
                                ? addLamp.some(item => item.uid === values.uid)
                                    ? addLamp.map(item =>
                                        item.uid === values.uid ? { ...item, ...newObject } : item
                                    )
                                    : [...addLamp, newObject]
                                : [newObject]
                        );

                        setUpdateLamps(false);
                    }}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <div className="mb-3">
                                <label className="w-100">
                                    Объект помещения:
                                    <Field
                                        as="select"
                                        name="uid"
                                        className={`form-select ${errors.uid && touched.uid && "border border-2 border-danger"}`}
                                    >
                                        <option value="">Не выбрано</option>
                                        {lamps.map(el => (
                                            <option key={el.uid} value={el.uid}>{el.name}</option>
                                        ))}
                                    </Field>
                                </label>
                            </div>
                            <div className="mb-3 row">
                                <label className="col">
                                    Включена
                                    <div className="input-group">
                                        <Flatpickr
                                            className="form-control border-1  "
                                            //value={}
                                            name="time"
                                            options={{
                                                locale: Russian,
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i",
                                                time_24hr: true,
                                                disableMobile: true,
                                                mode: 'single'
                                            }}
                                            onChange={(selectedDates) => {
                                                const selectedTime = selectedDates[0] ? moment(selectedDates[0]).format("HH:mm") : '';
                                            }}
                                            style={{width: '120px'}}
                                        />
                                    </div>
                                </label>
                                <label className="col">
                                    Выключена
                                    <div className="input-group">
                                        <Flatpickr
                                            className="form-control border-1  "
                                            //value={}
                                            name="time"
                                            options={{
                                                locale: Russian,
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i",
                                                time_24hr: true,
                                                disableMobile: true,
                                                mode: 'single'
                                            }}
                                            onChange={(selectedDates) => {
                                                const selectedTime = selectedDates[0] ? moment(selectedDates[0]).format("HH:mm") : '';
                                            }}
                                            style={{width: '120px'}}
                                        />
                                    </div>
                                </label>
                            </div>
                            <div className="hstack gap-2 justify-content-end mt-1">
                                <SubmitWithLoading text={"Сохранить"}/>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default AddLamps;
