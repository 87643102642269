import React, {useEffect, useState} from 'react';
import { Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getDailyReportNutritional} from "../../../store/reducers/widgets/ActionCreator";
import ModalAddDayliReportNutrition from "../modal/ModalAddDayliReportNutrition";
import {setDateAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {IAllDailyReportsNutrition} from "../../../models/widgets/IAllDailyReportsNutrition";
import ModalDeleteDayliReportNutrition from "../modal/ModalDeleteDayliReportNutrition";
import moment from "moment/moment";

const WidgetTableDayliReportNutrition = () => {

    const dispatch = useAppDispatch();

    const {groupAllDeviations, date, allDailyReportNutritional} = useAppSelector(state => state.WidgetReducer)

    const [modalAdd, setModalAdd] = useState<boolean>(false);
    const [modalDelete, setModalDelete] = useState<boolean>(false);

    useEffect(() => {
        groupAllDeviations &&
        dispatch(getDailyReportNutritional(date, groupAllDeviations)).then()

    }, [date, groupAllDeviations, dispatch])

    const groupedData = allDailyReportNutritional.reduce((acc:any, curr:any) => {
        const date = curr.date;
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(curr);
        return acc;
    }, {});

    const resultArray = Object.keys(groupedData).map(date => ({
        date,
        data: groupedData[date]
    }));

    const columns = [
        {
            name: <span className='font-weight-bold'>Дата</span>,
            selector: (row: { date: string }) => row.date,
            cell: (row: { date: string }) => <span className='accessibility_size_table'>{row.date}</span>,
            sortable: true,
            width: '120px'
        },
    ];

    const ExpandedRow: React.FC<{ data: any }> = ({ data }) => {
        return (
            <div>
                <DataTable
                    columns={[
                        {
                            width: '20px'
                        },
                        {
                            name: <span className='font-weight-bold accessibility_size_table'>Режим питания</span>,
                            selector: (row: IAllDailyReportsNutrition) => row.nutrition,
                            cell: (row: IAllDailyReportsNutrition) => <span className='accessibility_size_table'>{row.nutrition}</span>,
                            sortable: true,
                            width: "21%"
                        },
                        {
                            name: <span className='font-weight-bold accessibility_size_table'>Съел все</span>,
                            selector: (row: IAllDailyReportsNutrition) => row.eat,
                            cell: (row: IAllDailyReportsNutrition) => <span className='accessibility_size_table'>{row.eat}</span>,
                            sortable: true,
                            center: true,
                        },
                        {
                            name: <span className='font-weight-bold accessibility_size_table'>Частично</span>,
                            selector: (row: IAllDailyReportsNutrition) => row.eat_middle,
                            cell: (row: IAllDailyReportsNutrition) => <span className='accessibility_size_table'>{row.eat_middle}</span>,
                            sortable: true,
                            center: true,
                        },
                        {
                            name: <span className='font-weight-bold accessibility_size_table'>Не ел</span>,
                            selector: (row: IAllDailyReportsNutrition) => row.not_eat,
                            cell: (row: IAllDailyReportsNutrition) => <span className='accessibility_size_table'>{row.not_eat}</span>,
                            sortable: true,
                            center: true,
                        },
                        {
                            name: <span className='font-weight-bold accessibility_size_table'>Не был</span>,
                            selector: (row: IAllDailyReportsNutrition) => row.null_eat,
                            cell: (row: IAllDailyReportsNutrition) => <span className='accessibility_size_table'>{row.null_eat}</span>,
                            sortable: true,
                            center: true
                        }
                    ]}
                    data={data.data}
                    noDataComponent={'Нет данных'}
                    pagination={false}
                    pointerOnHover
                    customStyles={{
                        rows: {
                            style: {
                                backgroundColor: "rgba(236,234,247, 0.5)"
                            }
                        },
                        headCells: {
                            style: {
                                backgroundColor: "rgba(236,234,247, 1)"
                            },
                        },
                    }}
                    onRowDoubleClicked={
                        (row) => {
                            if(window.innerWidth > 768){
                                dispatch(setDateAdd(moment(row.date, "DD.MM.YYYY").toDate()));
                                setModalAdd(true)
                            }
                        }
                    }
                />
            </div>
        );
    };

    return (
        <>
            <Card>
                <CardHeader>
                    Отчет по питанию ребенка
                </CardHeader>
                <CardBody>
                    <DataTable
                        columns={columns}
                        data={resultArray}
                        noDataComponent={'Нет данных'}
                        highlightOnHover
                        pagination
                        paginationRowsPerPageOptions={[6]}
                        paginationComponentOptions={{
                            rowsPerPageText: 'Видно отчетов:',
                            rangeSeparatorText: 'из'
                        }}
                        paginationPerPage={6}
                        defaultSortFieldId={'date'}
                        defaultSortAsc={false}
                        pointerOnHover
                        expandableRows
                        expandableRowsComponent={ExpandedRow}
                        customStyles={{
                            rows: {
                                highlightOnHoverStyle: {
                                    backgroundColor: "#ffebb5"
                                }
                            },
                        }}
                    />
                </CardBody>
                <CardFooter className='d-flex gap-2'>
                    <div>
                        <button className="btn btn-primary" onClick={() => setModalAdd(true)}>Добавить данные</button>
                    </div>
                    <div>
                        <button className="btn btn-primary" onClick={() => setModalDelete(true)}>Удалить</button>
                    </div>
                </CardFooter>
            </Card>
            {
                modalDelete &&
                <ModalDeleteDayliReportNutrition modalDelete={modalDelete} setModalDelete={(value) => setModalDelete(value)}/>
            }
            {
                modalAdd &&
                <ModalAddDayliReportNutrition modalAdd={modalAdd} setModalAdd={(modalAdd) => setModalAdd(modalAdd)}/>
            }
        </>
    );
};

export default WidgetTableDayliReportNutrition;
