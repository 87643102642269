import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import AxiosClient from "../../../api/AxiosClient";
import {useAppDispatch} from "../../../hooks/redux";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";

interface IModelAddSchool {
    updateClass: boolean,
    setUpdateClass: (value: boolean) => void,
    builds: any[],
    setBuilds: (value: any[]) => void
    newBuilds: any[],
    setNewBuilds: (value: any[]) => void,
    selectClass: any | null,
    selectBuild: string,
    parallelClasses: any,
    shifts: any
}

const ModalUpdateClass: FC<IModelAddSchool> = ({parallelClasses, shifts, setUpdateClass, updateClass, builds, setBuilds, newBuilds, setNewBuilds, selectClass, selectBuild}) => {

    const onCheckParallel = (name: string) => {
        const parallel = parallelClasses.find((el: any) => name.replace(/[^+\d]/g, '') === el.slug)
        if (parallel)
            return parallel.id
        return ''
    }
    const dispatch = useAppDispatch();
    useEscapeKey(() => dispatch(setModalAdd(false)), updateClass, "updateClass");

    return (
        <Modal id="updateClass" isOpen={updateClass}
               toggle={() => setUpdateClass(!updateClass)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setUpdateClass(!updateClass)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Обновление класса
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            build: selectBuild,
                            name: selectClass?.name,
                            parallel_classes: selectClass?.parallel_class_id ? selectClass?.parallel_class_id : '',
                            shift: selectClass?.shift ? selectClass?.shift_id : ''
                        }
                    }
                    validationSchema={
                        Yup.object({
                            name: Yup.string().required('Обязательное поле'),
                        })
                    }
                    onSubmit={(values) => {

                        if (selectBuild === values.build) {
                            setBuilds(builds.map(b => {
                                if (b.name === values.build){
                                    return {
                                        ...b,
                                        class: b.class.map((c: any) => {
                                            if(c.name === selectClass.name){
                                                return {
                                                    ...c,
                                                    name: values.name,
                                                    parallel_class_id: values.parallel_classes,
                                                    shift_id: values.shift
                                                }
                                            }
                                            return c
                                        })
                                    }
                                }
                                return b
                            }))
                            setNewBuilds!(newBuilds!.map(b => {
                                if (b.name === values.build){
                                    return {
                                        ...b,
                                        class: b.class.map((c: any) => {
                                            if(c.name === selectClass.name){
                                                return {
                                                    ...c,
                                                    name: values.name,
                                                    parallel_class_id: values.parallel_classes,
                                                    shift_id: values.shift
                                                }
                                            }
                                            return c
                                        })
                                    }
                                }
                                return b
                            }))
                        } else {
                            const class1 = builds.find(b => b.name === selectBuild).class.find((c: any) => c.name === selectClass.name)
                            setBuilds(builds.map(b => {
                                if (b.name === values.build){
                                    return {
                                        ...b,
                                        class: [...b.class, {...class1, name: values.name, parallel_class_id: values.parallel_classes, shift_id: values.shift}]
                                    }
                                }
                                if (b.name === selectBuild){
                                    return {
                                        ...b,
                                        class: b.class.filter((c: any) => c.name !== selectClass.name)
                                    }
                                }
                                return b
                            }))

                            setNewBuilds!(newBuilds!.map(b => {
                                if (b.name === values.build){
                                    return {
                                        ...b,
                                        class: [...b.class, {...class1, name: values.name, parallel_class_id: values.parallel_classes, shift_id: values.shift}]
                                    }
                                }
                                if (b.name === selectBuild){
                                    return {
                                        ...b,
                                        class: b.class.filter((c: any) => c.name !== selectClass.name)
                                    }
                                }
                                return b
                            }))
                        }
                    }}>
                    {
                        ({errors, touched, setFieldValue}) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Корпус:</label>
                                        <Field name="build" as="select" className="form-select">
                                            {
                                                builds.map(b => (<option key={b.name} value={b.name}>{b.name}</option>))
                                            }
                                        </Field>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Название:</label>
                                        <Field name="name" className={`form-control ${errors.name && touched.name && "border border-2 border-danger"}`}
                                               onChange={
                                                   (e: any) => {
                                                       setFieldValue('name', e.target.value)
                                                       !e.target.value ? setFieldValue('parallel_classes', '') :
                                                           setFieldValue('parallel_classes', onCheckParallel(e.target.value))
                                                   }
                                               }
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Параллель:</label>
                                        <Field name="parallel_classes" as="select" className="form-select">
                                            <option value={''}>Не выбрано</option>
                                            {
                                                parallelClasses.map((b: any) => (<option key={b.id} value={b.id}>{b.name}</option>))
                                            }
                                        </Field>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Смена:</label>
                                        <Field name="shift" as="select" className="form-select">
                                            <option value={''}>Не выбрано</option>
                                            {
                                                shifts.map((b: any) => (<option key={b.id} value={b.id}>{b.name}</option>))
                                            }
                                        </Field>
                                    </Col>
                                </Row>
                                <div className="hstack gap-2">
                                    <button type="submit" className="btn btn-primary" id="addNewProject" onClick={() => setUpdateClass(false)}>Сохранить</button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalUpdateClass;
