import React, { FC, memo, useEffect } from 'react';
import DataTable from "react-data-table-component";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import moment from "moment";
import { Card, CardBody, CardHeader } from "reactstrap";
import { EducationSlice } from "../../../store/reducers/education/EducationSlice";

interface ITrainingEvent {
    id: number; // Добавляем id
    date: Date;
    time?: string;
    topic: string;
    location: string;
    hours: number;
    completed: boolean;
}

interface ITableEducation {
    date: Date;
}

const TableEducation: FC<ITableEducation> = ({ date }) => {
    const dispatch = useAppDispatch();
    const { trainingEvents } = useAppSelector(state => state.EducationReducer);

    const filteredEvents = trainingEvents.filter(event =>
        moment(event.date).isSame(date, 'month') &&
        moment(event.date).isSame(date, 'year')
    );

    // Функция для переключения статуса "Пройдено"
    const toggleCompleted = (eventId: number) => {
        dispatch(EducationSlice.actions.toggleTrainingCompleted(eventId));
    };

    const columns = [
        {
            name: <span className='font-weight-bold accessibility_size_table'>Дата</span>,
            selector: (row: ITrainingEvent) => moment(row.date).format('DD.MM.YYYY'),
            cell: (row: ITrainingEvent) => (
                <span className="accessibility_size_table">{moment(row.date).format('DD.MM.YYYY')}</span>
            ),
            sortable: true,
            style: { fontSize: '14px' },
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Время</span>,
            selector: (row: ITrainingEvent) => row.time || 'Не указано',
            cell: (row: ITrainingEvent) => (
                <span className="accessibility_size_table">{row.time || 'Не указано'}</span>
            ),
            sortable: true,
            style: { fontSize: '14px' },
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Тема</span>,
            selector: (row: ITrainingEvent) => row.topic,
            cell: (row: ITrainingEvent) => (
                <span className="accessibility_size_table">{row.topic}</span>
            ),
            sortable: true,
            style: { fontSize: '14px' },
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Место</span>,
            selector: (row: ITrainingEvent) => row.location,
            cell: (row: ITrainingEvent) => (
                <span className="accessibility_size_table">{row.location}</span>
            ),
            sortable: true,
            style: { fontSize: '14px' },
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Кол-во часов</span>,
            selector: (row: ITrainingEvent) => row.hours,
            cell: (row: ITrainingEvent) => (
                <span className="accessibility_size_table">{row.hours}</span>
            ),
            sortable: true,
            center: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Пройдено</span>,
            cell: (row: ITrainingEvent) => (
                <input
                    type="checkbox"
                    checked={row.completed}
                    onChange={() => toggleCompleted(row.id)} // Используем id вместо index
                    className="form-check-input"
                />
            ),
            center: true,
        },
    ];

    const conditionalRowStyles = [
        {
            when: (row: ITrainingEvent) => row.completed,
            style: {
                backgroundColor: '#e9ecef',
            },
        },
    ];

    return (
        <Card>
            <CardHeader className="border-0 align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Обучение</h4>
            </CardHeader>
            <CardBody className="py-0">
                <DataTable
                    columns={columns}
                    data={filteredEvents}
                    noDataComponent={'Нет данных об обучении'}
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Видно:',
                        rangeSeparatorText: 'из',
                    }}
                    defaultSortFieldId="hours"
                    defaultSortAsc={false}
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5",
                            },
                        },
                    }}
                    conditionalRowStyles={conditionalRowStyles}
                />
            </CardBody>
        </Card>
    );
};

export default memo(TableEducation);