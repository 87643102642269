import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import {
    Button,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useStateContext } from "../../hooks/useStateContext";
import RecentTests from "../../components/education/PlaningEducation/RecentTests";
import TestingCenters from "../../components/education/PlaningEducation/TestingCenters";
import ActionBlock from "../../components/education/PlaningEducation/ActionBlock";
import StatsCards from "../../components/education/PlaningEducation/StatsCards";
import ModalGradingScale from "../../components/education/PlaningEducation/modal/ModalGradingScale";
import NotificationShow from "../../components/widgets/NotificationShow";
import RecentEducation from "../../components/education/PlaningEducation/RecentEducation";
import MonthSelectPlugin from "flatpickr/dist/plugins/monthSelect/index";
import { EducationSlice } from "../../store/reducers/education/EducationSlice";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import ModalCreateTest from "../../components/education/PlaningEducation/modal/ModalCreateTest";
import ModalCreateCenter from "../../components/education/PlaningEducation/modal/ModalCreateCenter";

const PlaningEducation = () => {
    const dispatch = useAppDispatch();
    const { user } = useStateContext();
    const { selectedDate, trainingEvents, certificationEvents } = useAppSelector(state => state.EducationReducer);

    useEffect(() => {
        // добавить логику инициализации
    }, [dispatch]);

    const [modalGradingScaleOpen, setModalGradingScaleOpen] = useState(false);
    const [modalCreateTestOpen, setModalCreateTestOpen] = useState(false);
    const [modalCreateCenterOpen, setModalCreateCenterOpen] = useState(false);

    // Функции для открытия/закрытия модальных окон
    const toggleGradingScaleModal = () => setModalGradingScaleOpen(!modalGradingScaleOpen);
    const toggleCreateTestModal = () => setModalCreateTestOpen(!modalCreateTestOpen);
    const toggleCreateCenterModal = () => setModalCreateCenterOpen(!modalCreateCenterOpen);

    // Данные для карточек
    const statsData = [
        { title: "Тестов сдано", value: 0 },
        { title: "Тестов опубликовано", value: 5 },
        { title: "Центров тестирования", value: 3 },
    ];

    // Обработчик сохранения теста
    const handleSaveTest = (data: { title: string; description: string; gradingScale: string; timeLimit: boolean; timeLimitMinutes: number; attemptLimit: boolean; attemptLimitCount: number; taskOrder: string; allowSkip: boolean }) => {
        console.log("Сохраненные данные теста:", data);
    };
    // Обработчик сохранения центра тестирования
    const handleSaveCenter = (data: { name: string; link: string }) => {
        console.log("Сохраненные данные центра тестирования:", data);
    };

    return (
        <>
            <div className="page-content">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Планирование обучения</title>
                </Helmet>
                <Container fluid>

                    {/* Верхняя полоска с карточками */}
                    <StatsCards stats={statsData} />

                    <Row>
                        <Col md={3}>
                            <Flatpickr
                                className="form-control border-0 dash-filter-picker shadow mb-2"
                                value={selectedDate}
                                options={{
                                    locale: Russian,
                                    dateFormat: "m Y",
                                    maxDate: new Date(),
                                    mode: 'single',
                                    disableMobile: true,
                                    plugins: [MonthSelectPlugin({
                                        shorthand: true,
                                        dateFormat: "M y",
                                        altFormat: "F Y",
                                    })]
                                }}
                                onClose={(date) => {
                                    dispatch(EducationSlice.actions.setDate(date[0]));
                                }}
                            />
                        </Col>
                        <Col md={12}>
                            <TestingCenters />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <RecentTests selectedDate={selectedDate} />
                        </Col>
                        <Col md={6}>
                            <RecentEducation selectedDate={selectedDate} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <ActionBlock
                                icon="bi bi-bar-chart"
                                title="Шкала оценок"
                                description="Настроить уровни оценок в зависимости от набранного балла"
                                onClick={toggleGradingScaleModal}
                            />
                        </Col>
                        <Col md={4}>
                            <ActionBlock
                                icon="bi bi-clipboard-plus"
                                title="Создать тест"
                                description="Инструмент создания теста"
                                onClick={toggleCreateTestModal}
                            />
                        </Col>
                        <Col md={4}>
                            <ActionBlock
                                icon="bi bi-house-add"
                                title="Создать центр тестирования"
                                description="Инструмент создания центра тестирования с различными тестами"
                                onClick={toggleCreateCenterModal}
                            />
                        </Col>
                    </Row>
                </Container>
                <NotificationShow />
                {/* Модальное окно для Шкалы оценок */}
                <ModalGradingScale isOpen={modalGradingScaleOpen} toggle={toggleGradingScaleModal} />

                {/* Модальное окно для Создания теста */}
                <ModalCreateTest
                    isOpen={modalCreateTestOpen}
                    toggle={toggleCreateTestModal}
                    onSave={handleSaveTest}
                />

                {/* Модальное окно для Создания центра тестирования */}
                <ModalCreateCenter
                    isOpen={modalCreateCenterOpen}
                    toggle={toggleCreateCenterModal}
                    onSave={handleSaveCenter}
                />
            </div>
        </>
    );
};

export default PlaningEducation;