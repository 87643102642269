import React, {FC} from 'react';
import {Col} from "reactstrap";
import cn from "classnames";
import {rgba2Hex} from "@pixi/ui/lib/stories/utils/color";

interface IProps {
    shotName: string;
    shotImage: any;
    selectShot: () => void;
    handleOpenSettings: () => void;
    index: number;
    onDragStart: (index: number) => void;
    onDragEnter: (index: number) => void;
    onDrop: () => void;
    isHighlighted: boolean;
}
const Shot: FC<IProps> = ({handleOpenSettings, shotName, shotImage, selectShot, onDragEnter, onDragStart, onDrop, index, isHighlighted}) => {

   const CheckBgImage = () => {

    if(shotImage!= null ){

        return  process.env.REACT_APP_API_BASE_URL + shotImage.path
}
    }

    return (
        <Col sm={12}
             draggable
             onDragStart={() => onDragStart(index)}
             onDragEnter={() => onDragEnter(index)}
             onDragOver={(e) => e.preventDefault()}
             onDrop={onDrop}
        >
            <div style={{height: 110, width: 330, backgroundImage: `url(${CheckBgImage()})`, backgroundSize: '100% 100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}
                 className={cn(' p-1 m-1 rounded d-flex justify-content-center align-items-center shadow select_shot cursor-pointer', {
                'border border-1 border-dark': isHighlighted
            })} onClick={() => {
                handleOpenSettings()
                selectShot()
            }}>
                <h3 className="m-0 bg-dark bg-opacity-50 text-light">{shotName}</h3>
            </div>
        </Col>
    );
};

export default Shot;
