import React, {FC, useEffect, useState} from 'react';
import {IDetail} from "../../../models/parent/ICashAccounting";
import {CardBody} from "reactstrap";
import DataTable from "react-data-table-component";
import AxiosClient from "../../../api/AxiosClient";
import moment from "moment/moment";
interface ITableCashAccounting {
    selectChildren: string | number,
    date: Date[]
}
const TableCashAccounting: FC<ITableCashAccounting> = ({selectChildren, date}) => {

    const [tableCashAccounting, setTableCashAccounting] = useState<any[]>([]);

    useEffect(() => {
        selectChildren &&
        AxiosClient.get('/students/cash_movement_account_total', {params: {id: selectChildren, date: [moment(date[0]).format('YYYY-MM-DD').toString(), moment(date[1]).format('YYYY-MM-DD').toString()]}})
            .then(r => setTableCashAccounting(r.data))
    }, [selectChildren, date]);

    const columns: any = [
        {
            name: <span className='font-weight-bold accessibility_size_table'>Дата</span>,
            selector: (row: IDetail) =>moment(row.date).format('DD.MM.YYYY').toString(),
            cell: (row: IDetail) => <span className="accessibility_size_table">{moment(row.date).format('DD.MM.YYYY').toString()}</span>,
            sortable: true,
            style: {
                minWidth: '110px'
            },
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Поступления</span>,
            cell: (row: IDetail) => {
                if (!row.income){
                    return <span className="accessibility_size_table">&mdash;</span>
                }
                return <span className="accessibility_size_table">{row.income}</span>
            },
            center: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Услуги за питание</span>,
            cell: (row: IDetail) => {
                if (!row.food){
                    return <span className="accessibility_size_table">&mdash;</span>
                }
                return <span className="accessibility_size_table">{row.food}</span>
            },
            center: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Услуга</span>,
            cell: (row: IDetail) => {
                if (!row.service){
                    return <span className="accessibility_size_table">&mdash;</span>
                }
                return <span className="accessibility_size_table">{row.service}</span>
            },
            center: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Итого расход</span>,
            cell: (row: IDetail) => {
                if (!row.outgo){
                    return <span className="accessibility_size_table">&mdash;</span>
                }
                return <span className="accessibility_size_table">{row.outgo}</span>
            },
            center: true,
            wrap: true
        },
    ];


    return (
        <>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={tableCashAccounting}
                    noDataComponent={'Нет данных'}
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </>

    );
};

export default TableCashAccounting;
