import React, {FC, Fragment} from 'react';
import {Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown} from "reactstrap";
import NewsDocs from "./NewsDocs";
import AxiosClient from "../../api/AxiosClient";
import moment from "moment/moment";
import {Link, useNavigate} from "react-router-dom";
import {useStateContext} from "../../hooks/useStateContext";
import {useAppSelector} from "../../hooks/redux";

interface IDesctopNews {
    news: any[],
    setNews: (news: any[]) => void;
}

const DesctopNews: FC<IDesctopNews> = ({news, setNews}) => {
    const {user} = useStateContext();
    const navigate = useNavigate()
    const {groups} = useAppSelector(state => state.WidgetReducer);

    const deleteNews = (id: number) => {
        AxiosClient.post(`/news/delete/${id}`)
            .then(() => AxiosClient.get('/news', {params: {groups}}).then(r => setNews(r.data)))
    }

    function badge(id: string) {
        switch (id) {
            case '1':
                return <span className="badge bg-soft-success text-primary align-middle">Не срочно</span>
            case '2':
                return <span className="badge bg-soft-warning text-primary align-middle">Требует внимание</span>
            case '3':
                return <span className="badge bg-soft-danger text-primary align-middle">Срочно</span>
        }
    }

    function NewNews(date: Date) {
        const dateNews = moment(date).add(1, "days")
        const dateNew = moment();
        if (moment(dateNews).isSameOrAfter(dateNew)) {
            return <span className="badge bg-success align-middle">Новая</span>
        }
    }

    return (
        <div className="timeline-2">
            {
                news.map((newses: any, i) => {
                    return <Fragment key={i}>
                        <Row>
                            <Col xs={3}>
                                <div className="timeline-year">
                                    <p>{newses.date}</p>
                                </div>
                            </Col>
                        </Row>
                        {
                            newses.news.map((i: any) => {
                                return <Row key={i.id} className="timeline-right">
                                    <Col xs={12}>
                                        <p className="timeline-date">
                                            {i.date}
                                        </p>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="timeline-box">
                                            <div className="timeline-text">
                                                {
                                                    user.id === i.user_id &&
                                                    <UncontrolledDropdown direction='start'
                                                                          className=" settings-image mt-n3 me-n4">
                                                        <DropdownToggle tag="a" id="dropdownMenuLink2" role="button">
                                                            <i className="ri-more-fill fs-21 text-dark"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem className="dropdown-item edit-list"
                                                                          href="#addmemberModal"
                                                                          onClick={() => deleteNews(i.id)}
                                                            >
                                                                <i className="ri-delete-bin-5-line me-2 align-bottom text-muted"></i>Удалить
                                                            </DropdownItem>
                                                            <DropdownItem className="dropdown-item edit-list"
                                                                          onClick={() => navigate(`/news/update/${i.id}`)}>
                                                                <i className="ri-pencil-line me-2 align-bottom text-muted"></i>Изменить
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                }
                                                <p className="mb-1 me-2 mt-1">{badge(i.rang)} {NewNews(new Date(i.origDate))}</p>
                                                <Link to={`/news/more/${i.id}`}><h5 className="card-title">{i.theme} </h5></Link>
                                                <p className="text-muted mb-0">{i.description}</p>
                                                {
                                                    i.documents.map((doc: any) => (<NewsDocs key={doc.url} url={doc}/>))
                                                }
                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                            })
                        }
                    </Fragment>
                })
            }
        </div>
    );
};

export default DesctopNews;
