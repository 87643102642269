import React, {FC, useEffect, useState} from 'react';
import {
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import classNames from "classnames";
import ModalAddOperationControlPointControl from "./ModalAddOperationControlPointControl";
import AxiosClient from "../../../api/AxiosClient";
import {Field, Form, Formik} from "formik";
import UserService from "../../../api/services/userService/UserService";
import moment from "moment";
import {useAppDispatch} from "../../../hooks/redux";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";

interface IModalAddOperationControl {
    open: boolean,
    setOpen: (value: boolean) => void,
    updateAllData: () => void
}

const ModalAddOperationControl: FC<IModalAddOperationControl> = ({open, setOpen, updateAllData}) => {
    const [date, setDate] = useState(new Date());

    const [activeTab, setActiveTab] = useState("1");
    const [listPlanControl, setListPlanControl] = useState<any[]>([]);
    const [listTypeControl, setListTypeControl] = useState<any[]>([]);
    const [users, setUsers] = useState<any[]>([]);
    const [planControl, setPlanControl] = useState<string>('');
    const [listPointControl, setListPointControl] = useState<any[]>([]);
    const [listPointControlNew, setListPointControlNew] = useState<any[]>([]);
    const [listMark, setListMark] = useState<any[]>([]);
    const [point, setPoint] = useState<null | any>(null);
    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const [openPointControl, setOpenPointControl] = useState<boolean>(false);

    useEffect(() => {
        AxiosClient.get('/get_list_plan_control')
            .then((r) => setListPlanControl(r.data))
        AxiosClient.get('/get_list_type_control')
            .then((r) => setListTypeControl(r.data))
        UserService.getUsersOrganization()
            .then(r => setUsers(r.data))
        AxiosClient.get('/get_list_mark')
            .then(r => setListMark(r.data))
    }, []);

    function classes(mark: string) {
        let classes = 'nested-1 cursor-pointer text-white';

        if(mark === 'Хорошо'){
            classes += ' bg-success'
        }
        if(mark === 'Удовлетворительно'){
            classes += ' bg-warning'
        }
        if(mark === 'Неудовлетворительно'){
            classes += ' bg-danger'
        }

        return classes
    }
    useEffect(() => {
        planControl &&
        AxiosClient.get(`/get_list_point_control/${planControl}`)
            .then((r) => {
                setListPointControl(r.data)
                setListPointControlNew(r.data.map((elem: any, index: number) => {
                    const newIndex = index + 1;
                    return {
                        files: [],
                        number: newIndex,
                        subject_name: elem.name,
                        subject_uid: elem.uid,
                        mark: 'Хорошо',
                        message: '',
                        responsible: '',
                        deadline_date: moment(date).format('DD.MM.YY'),
                        base64: ''
                    }
                }))
            })
    }, [planControl]);
    const dispatch = useAppDispatch();
    useEscapeKey(() => dispatch(setModalAdd(false)), open, "ModalAddOperationControl");


    return (
        <Modal id="ModalAddOperationControl" isOpen={open}
               toggle={() => setOpen(!open)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setOpen(!open)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавить оперативный контроль
            </ModalHeader>
            <ModalBody>
                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                     role="tablist">
                    <NavItem>
                        <NavLink
                            className={classNames({active: activeTab === "1", "p-2": true})}
                            onClick={() => {
                                tabChange("1");
                            }}
                            type="button">
                            <i className="fas fa-home"></i>
                            План контроля
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classNames({active: activeTab === "2", "p-2": true})}
                            onClick={() => {
                                tabChange("2");
                            }}
                            type="button">
                            <i className="fas fa-home"></i>
                            Пункты контроля
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className="mt-2">
                    <TabPane tabId="1" className="align-items-center">
                        <Formik
                            enableReinitialize
                            initialValues={{
                                planControl: planControl,
                                typeControl: listTypeControl[0] && listTypeControl[0].name,
                                controller: users[0] && users[0].user1c,
                                responsible: users[0] && users[0].user1c
                            }}
                            onSubmit={(values) => {
                                const data = {
                                    check_option_uid: planControl,
                                    control_type: values.typeControl,
                                    create_date: moment(date).format("DD.MM.YYYY"),
                                    group_uid: "",
                                    inspector_uid: values.controller,
                                    responsible_uid: values.responsible,
                                    start_process: "0",
                                    uid: "",
                                    remarks: listPointControlNew
                                }
                                AxiosClient.post('/save_operational_control', {data})
                                    .then(() => {
                                        setOpen(false)
                                        updateAllData()
                                    })
                                    .catch()
                            }}
                        >
                            <Form>
                                <div className="col-auto mb-2">
                                    <label>Период:</label>
                                    <div className="col">
                                        <div className="input-group">
                                            <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                value={date}
                                                disabled
                                                options={{
                                                    locale: Russian, mode: "single", dateFormat: "d m Y",
                                                }}
                                                onChange={(date) => setDate(date[0])}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto mb-2">
                                    <label>План контроля:</label>
                                    <div className="col">
                                        <div className="input-group">
                                            <select className="form-control"
                                                    onChange={event => setPlanControl(event.target.value)}>
                                                <option
                                                        value={""}>Выберите</option>
                                                {
                                                    listPlanControl.map((elem) => <option key={elem.uid}
                                                                                          value={elem.uid}>{elem.name}</option>)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto mb-2">
                                    <label>Тип контроля:</label>
                                    <div className="col">
                                        <div className="input-group">
                                            <Field as="select" name="typeControl" className="form-control">
                                                {
                                                    listTypeControl.map((elem) => <option key={elem.name}
                                                                                          value={elem.name}>{elem.synonim}</option>)
                                                }
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto mb-2">
                                    <label>Контролер:</label>
                                    <div className="col">
                                        <div className="input-group">
                                            <Field as="select" name="controller" className="form-control">
                                                {
                                                    users.map((elem, index) => <option key={index}
                                                                                       value={elem.user1c}>{`${elem.name} ${elem.middle_name}`}</option>)
                                                }
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto mb-2">
                                    <label>Ответственный:</label>
                                    <div className="col">
                                        <div className="input-group">
                                            <Field as="select" name="responsible" className="form-control">
                                                {
                                                    users.map((elem, index) => <option key={index}
                                                                                       value={elem.user1c}>{`${elem.name} ${elem.middle_name}`}</option>)
                                                }
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                                <div className="hstack gap-2 d-flex justify-content-end">
                                    <button type="submit" className="btn btn-primary" id="addNewProject">Сохранить</button>
                                </div>
                            </Form>
                        </Formik>
                    </TabPane>
                    <TabPane tabId="2" className="align-items-center">
                        <ListGroup className="col nested-list nested-sortable-handle fs-14">
                            {
                                listPointControlNew.map((elem, index) =>
                                    <ListGroupItem key={index} className={classes(elem.mark)}
                                                   onClick={() => {
                                                       setOpenPointControl(true)
                                                       setPoint(elem)
                                                   }}><i
                                        className="ri-drag-move-fill align-bottom handle cursor-pointer"></i>
                                        {elem.subject_name}
                                    </ListGroupItem>)
                            }
                        </ListGroup>
                    </TabPane>
                </TabContent>
            </ModalBody>
            <ModalAddOperationControlPointControl listMark={listMark} open={openPointControl} point={point}
                                                  listPointControlNew={listPointControlNew}
                                                  setListPointControlNew={(value) => setListPointControlNew(value)}
                                                  setOpen={(value) => setOpenPointControl(value)}/>
        </Modal>
    );
};

export default ModalAddOperationControl;
