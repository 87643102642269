import React, {FC, useState} from 'react';
import DataTable from "react-data-table-component";
import {Col, Row} from "reactstrap";
import moment from "moment";
import ModalAddHomeEducation from "./ModalAddHomeEducation";
import ModalUpdateHomeEducation from "./ModalUpdateHomeEducation";

interface IListHomeEducation {
    listHomeEducation: any[],
    setListHomeEducation: (value: any[]) => void,
    listMenu: any[],
    typeMenu: any[]
}
const ListHomeEducation: FC<IListHomeEducation> = ({setListHomeEducation, listHomeEducation, listMenu, typeMenu}) => {
    const [selectHomeEducation, setSelectHomeEducation] = useState<any | null>(null);
    const [updateHomeEducation, setUpdateHomeEducation] = useState(false);
    const [addHomeEducation, setAddHomeEducation] = useState(false);
    const columns = [
        {
            id: 'index',
            cell: (row: any) => <span className='accessibility_size_table'> {row.index}</span>,
            omit: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Тип меню</span>,
            cell: (row: any) => <span className='accessibility_size_table'> {row.type_menu.name}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Тип план. меню</span>,
            cell: (row: any) => <span className='accessibility_size_table'> {row.menu.name}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Дата с</span>,
            cell: (row: any) => <span className='accessibility_size_table'> {moment(row.date_begin).format('DD.MM.Y')}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Дата по</span>,
            cell: (row: any) => row.date_end ?
                <span className='accessibility_size_table'> {moment(row.date_end).format('DD.MM.Y')}</span>
                : '',
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'></span>,
            cell: (row: any, index: number) => <i className="bx bx-trash cursor-pointer fs-3" onClick={() => setListHomeEducation(listHomeEducation.filter((value, index) => index !== row.index))}></i>,
            width: '50px'
        }
    ]

    return (
        <div>
            <DataTable
                columns={columns}
                data={listHomeEducation.map((el: any, index) => ({...el, index}))}
                keyField={'index'}
                noDataComponent={'Нет данных'}
                highlightOnHover
                customStyles={{
                    rows: {
                        highlightOnHoverStyle: {
                            backgroundColor: "#ffebb5"
                        }
                    }
                }}
                onRowClicked={(row) => {
                    setSelectHomeEducation(row)
                    setUpdateHomeEducation(true)
                }}
            />
            <Row>
                <Col xxl={8} xl={8} lg={8} sm={8}>
                    <div className="hstack gap-2">
                        <button type="button" className="btn btn-primary mt-3" onClick={() => setAddHomeEducation(true)}>Добавить</button>
                    </div>
                </Col>
            </Row>
            <ModalAddHomeEducation listHomeEducation={listHomeEducation} setListHomeEducation={value => setListHomeEducation(value)} typeMenu={typeMenu} addHomeEducation={addHomeEducation} setAddHomeEducation={value => setAddHomeEducation(value)} listMenu={listMenu}/>
            <ModalUpdateHomeEducation updateHomeEducation={updateHomeEducation} setUpdateHomeEducation={value => setUpdateHomeEducation(value)} listMenu={listMenu} typeMenu={typeMenu} listHomeEducation={listHomeEducation} setListHomeEducation={value => setListHomeEducation(value)} selectHomeEducation={selectHomeEducation}/>
        </div>
    );
};

export default ListHomeEducation;
