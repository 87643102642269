import React, {useEffect} from 'react';
import DataTable from "react-data-table-component";
import {Input} from "reactstrap";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {ParentSlice} from "../../../store/reducers/parent/ParentSlice";
import {getDatesEvolution} from "../../../store/reducers/parent/ActionCreator";
import {IDateEvolution} from "../../../models/parent/IDateEvolution";

const TableInfoEvolutionsChildren = () => {

    const dispatch = useAppDispatch();

    const {datesEvolution, selectDatesEvolution} = useAppSelector(state => state.ParentReducer)


    const checkDatesEvolution = (dateEvolution: IDateEvolution) => {
        if (selectDatesEvolution.includes(dateEvolution)) {
            dispatch(ParentSlice.actions.setSelectDatesEvolution(selectDatesEvolution.filter(item => item !== dateEvolution)))
        } else {
            dispatch(ParentSlice.actions.setSelectDatesEvolution([...selectDatesEvolution, dateEvolution]))
        }
    }

    useEffect(() => {
            dispatch(getDatesEvolution()).then()
    }, [dispatch]);

    const columnsEvolutions: any = [
        {
            selector: (row: IDateEvolution) =>
                row.period,
            cell: (row: IDateEvolution) =>
                (<span className="accessibility_size_table" onClick={() => checkDatesEvolution(row)}>
                    {row.period}
                </span>),
            sortable: true,
            wrap: true,
            grow: 3
        },
        {
            selector: (row: IDateEvolution) => (
                <Input type="checkbox" className="border-primary m-0" style={{width: '20px', height: '20px'}}
                       value={row.period}
                       checked={!!selectDatesEvolution.find(k => k.period === row.period)}
                       onChange={() => checkDatesEvolution(row)}/>
            ),
            center: true
        }
    ];


    return (
        <DataTable
            columns={columnsEvolutions}
            data={datesEvolution}
            noDataComponent={'Нет данных'}
            defaultSortAsc={false}
            noTableHead
            onRowClicked={(row) => checkDatesEvolution(row)}
            highlightOnHover
            pointerOnHover
            customStyles={{
                rows: {
                    highlightOnHoverStyle: {
                        backgroundColor: "#ffebb5"
                    }
                }
            }}
        />
    );
};

export default TableInfoEvolutionsChildren;
