import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {Field, Form, Formik} from "formik";
import Select from "react-select";
import WidgetService from "../../../api/services/widgetService/WidgetService";
import {convertDateFullTime, convertDateTimeFull1C} from "../../../functions/ConvertDate";
import AxiosClient from "../../../api/AxiosClient";
import InfoLoaderModal from "../../layouts/SceletonLoaders/InfoLoaderModal";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface IModalUpdateRegisterDefectEquipment {
    id: string
    setModalUpdate: (data: boolean) => void,
    modalUpdate: boolean
}

const ModalUpdateRegisterDefectEquipment: FC<IModalUpdateRegisterDefectEquipment> = ({
                                                                                         id,
                                                                                         setModalUpdate,
                                                                                         modalUpdate
                                                                                     }) => {
    const dispatch = useAppDispatch();
    const {date} = useAppSelector(state => state.WidgetReducer)
    const [dateTime, setDateTime] = useState(new Date());
    const [initiator, setInitiator] = useState<any[]>([]);
    const [warehouses, setWarehouses] = useState<any[]>([]);
    const [repairTeam, setRepairTeam] = useState<any[]>([]);
    const [objectRepair, setObjectRepair] = useState<any[]>([]);
    const [typeDefect, setTypeDefect] = useState<any[]>([]);
    const [criticalDefect, setCriticalDefect] = useState<any[]>([]);
    const [defectCauses, setDefectCauses] = useState<any[]>([]);
    const [organizations, setOrganizations] = useState<any[]>([]);
    const [selectInitiator, setSelectInitiator] = useState<any | null>(null);
    const [selectWarehouse, setSelectWarehouse] = useState<any>({value:'', label:''});
    const [selectRepairTeam, setSelectRepairTeam] = useState<any | null>(null);
    const [selectObjectRepair, setSelectObjectRepair] = useState<any | null>(null);
    const [selectTypeDefect, setSelectTypeDefect] = useState<any | null>(null);
    const [selectCriticalDefect, setSelectCriticalDefect] = useState<any | null>(null);
    const [selectDefectCause, setSelectDefectCauses] = useState<any | null>(null);
    const [selectOrganization, setSelectOrganizations] = useState<any | null>(null);
    const [docInfo, setDocInfo] = useState<any | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (id){
            setLoading(true)
            WidgetService.getInfoDispatchServiceRequest(id)
                .then(r => {
                    setDocInfo(r.data[0])
                    setDateTime(new Date(convertDateFullTime(r.data[0].date)))
                    setSelectOrganizations({value: r.data[0].org_id, label: r.data[0].org_name})
                    setSelectInitiator({value: r.data[0].person_id, label: r.data[0].person})
                    setSelectWarehouse({value: r.data[0].sklad_id, label: r.data[0].sklad_name})
                    setSelectRepairTeam({value: r.data[0].repair_podr_id, label: r.data[0].repair_podr})
                    setSelectObjectRepair({value: r.data[0].repair_id, label: r.data[0].repair})
                    setSelectTypeDefect({
                        value: r.data[0]?.Defect_repair[0]?.vid_defect_id,
                        label: r.data[0]?.Defect_repair[0]?.vid_defect
                    })
                    setSelectCriticalDefect({
                        value: r.data[0]?.Defect_repair[0]?.defect_criticality_id,
                        label: r.data[0]?.Defect_repair[0]?.defect_criticality
                    })
                    setSelectDefectCauses({
                        value: r.data[0]?.Defect_repair[0]?.defect_causes_id,
                        label: r.data[0]?.Defect_repair[0]?.defect_causes
                    })
                    setLoading(false)
                })
        }

    }, [id]);

    useEffect(() => {
        WidgetService.getListTypeDefect().then(r => setTypeDefect(r.data))
        WidgetService.getListCriticalDefect().then(r => setCriticalDefect(r.data))
        WidgetService.getListDefectCauses().then(r => setDefectCauses(r.data))
        WidgetService.getInfoOrganization().then(r => setOrganizations(r.data))
    }, []);

    useEffect(() => {
        WidgetService.getListPerson(date).then(r => setInitiator(r.data))
        WidgetService.getListObjectWarehouse(date).then(r => {
            setWarehouses(r.data)
        })
        WidgetService.getListRepairTeam(date).then(r => setRepairTeam(r.data))
    }, [date]);

    useEffect(() => {
        selectWarehouse?.value &&
        WidgetService.getListObjectRepair(dateTime, selectWarehouse.value).then(r => setObjectRepair(r.data))
    }, [selectWarehouse, dateTime]);

    const customStyles = {
        control: (ds: any) => ({
            ...ds,
            backgroundColor: "#fff",
        }),
        option: (ds: any, state: any) => ({
            ...ds,
            backgroundColor: state.isFocused ? "#4b38b3" : "#fff",
            color: state.isFocused ? "#fff" : "#000",
        })
    }
    useEscapeKey(() => dispatch(setModalAdd(false)), modalUpdate, "ModalUpdateRegisterDefectEquipment");

    return (
        <Modal id="ModalUpdateRegisterDefectEquipment" isOpen={modalUpdate}
               toggle={() => setModalUpdate(!modalUpdate)}
               modalClassName="zoomIn" tabIndex={-1} centered size='lg'>
            <ModalHeader toggle={() => setModalUpdate(!modalUpdate)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Обновить заявку диспетчерской службы
            </ModalHeader>
            <ModalBody>
                {
                    loading ? <Row><Col className="d-flex justify-content-center"><InfoLoaderModal msg="Загружаю данные по заявке"/></Col></Row>
                        :
                        <Formik
                            enableReinitialize
                            initialValues={
                                {
                                    description: docInfo?.Defect_repair[0]?.defect_opisanie,
                                    phone: docInfo?.phone
                                }
                            }
                            onSubmit={(values) => {
                                const data = {
                                    nomer: docInfo.nomer,
                                    org_uid: selectOrganization.value,
                                    date: convertDateTimeFull1C(dateTime),
                                    sklad_id: selectWarehouse.value,
                                    repair_obj_id: objectRepair.find(or => or.os_uid === selectObjectRepair.value).uid, // repair_obj
                                    repair_id: selectObjectRepair.value,// основное средство
                                    person_id: selectInitiator.value,
                                    repair_podr_id: selectRepairTeam.value,
                                    vid_defect_id: selectTypeDefect.value,
                                    defect_criticality_id: selectCriticalDefect.value,
                                    defect_causes_id: selectDefectCause.value,
                                    defect_opisanie: values.description,
                                    description: values.description,
                                    phone: values.phone
                                }
                                dispatch(setIsSend(true));
                                AxiosClient.post('/save_dispatch_service_request', {doc_id: docInfo.doc_uid, data: data})
                                    .then(() => {
                                        setModalUpdate(false);
                                        dispatch(setIsSend(false));
                                    })
                                    .catch(() => dispatch(setIsSend(false)))
                            }}>
                            <Form>
                                <Row>
                                    <Col className="mb-3">
                                        <label>Дата:</label>
                                        <div className="col">
                                            <div className="input-group">
                                                <Flatpickr
                                                    className="form-control border-0 dash-filter-picker shadow"
                                                    value={dateTime}
                                                    options={{
                                                        locale: Russian,
                                                        mode: "single",
                                                        dateFormat: "d.m.Y H:i",
                                                        disableMobile: true
                                                    }}
                                                    onChange={(date) => setDateTime(date[0])}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mb-3">
                                        <label>Организация:</label>
                                        <Select options={organizations.map(org => ({value: org.id, label: org.name}))}
                                                value={selectOrganization}
                                                onChange={(change: any) => {
                                                    setSelectOrganizations({value: change.value, label: change.label})
                                                }}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" md="6" className="mb-3">
                                        <label>Инициатор:</label>
                                        <Select options={initiator.map(org => ({value: org.uid, label: org.FIO}))}
                                                name="organization"
                                                value={selectInitiator}
                                                onChange={(change: any) => {
                                                    setSelectInitiator({value: change.value, label: change.label})
                                                }}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                        />
                                    </Col>
                                    <Col xs="12" md="6" className="mb-3">
                                        <label>Номер телефона:</label>
                                        <div className="input-group">
                                            <Field className="form-control" name="phone"/>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mb-3">
                                        <label className="w-100">Где находится оборудование:
                                        <Select options={warehouses.map(org => ({value: org.uid, label: org.name}))}
                                                value={selectWarehouse}
                                                onChange={(change: any) => {
                                                    setSelectWarehouse({value: change.value, label: change.label})
                                                }}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                        /></label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mb-3">
                                        <label>Ремонтная служба:</label>
                                        <Select options={repairTeam.map(org => ({value: org.uid, label: org.name}))}
                                                value={selectRepairTeam}
                                                onChange={(change: any) => {
                                                    setSelectRepairTeam({value: change.value, label: change.label})
                                                }}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" md="6" className="mb-3">
                                        <label>Отказавшее оборудование:</label>
                                        <Select options={objectRepair.map(object => ({
                                            value: object.os_uid,
                                            label: object.os_name
                                        }))}
                                                value={selectObjectRepair}
                                                onChange={(change: any) => {
                                                    setSelectObjectRepair({value: change.value, label: change.label})
                                                }}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                        />
                                    </Col>
                                    <Col xs="12" md="6" className="mb-3">
                                        <label>Вид дефекта:</label>
                                        <Select options={typeDefect.map(defect => ({value: defect.uid, label: defect.name}))}
                                                value={selectTypeDefect}
                                                onChange={(change: any) => {
                                                    setSelectTypeDefect({value: change.value, label: change.label})
                                                }}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" md="6" className="mb-3">
                                        <label>Критичность дефекта:</label>
                                        <Select
                                            options={criticalDefect.map(defect => ({value: defect.uid, label: defect.name}))}
                                            value={selectCriticalDefect}
                                            onChange={(change: any) => {
                                                setSelectCriticalDefect({value: change.value, label: change.label})
                                            }}
                                            styles={customStyles}
                                            placeholder="Поиск"
                                        />
                                    </Col>
                                    <Col xs="12" md="6" className="mb-3">
                                        <label>Причина дефекта:</label>
                                        <Select options={defectCauses.map(defect => ({value: defect.uid, label: defect.name}))}
                                                value={selectDefectCause}

                                                onChange={(change: any) => {
                                                    setSelectDefectCauses({value: change.value, label: change.label})
                                                }}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mb-3">
                                        <label>Описание дефекта:</label>
                                        <div className="input-group">
                                            <Field as="textarea" className="form-control" name="description"/>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="hstack gap-2 justify-content-end">
                                    <SubmitWithLoading text={"Сохранить"}/>
                                </div>
                            </Form>
                        </Formik>
                }
            </ModalBody>
        </Modal>
    );
};

export default ModalUpdateRegisterDefectEquipment;
