import React, {FC, useRef, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Nav, NavItem, Row, TabContent, TabPane} from "reactstrap";
import {NavLink} from "react-router-dom";
import classNames from "classnames";
import {Russian} from "flatpickr/dist/l10n/ru";
import Flatpickr from "react-flatpickr";
import {convertDateLineInDot} from "../../../../functions/ConvertDate";
import {Field, Form, Formik} from "formik";
import MyTaskService from "../../../../api/services/documentsFlowService/MyTaskService";
import HTMLReactParser from "html-react-parser";
import Docs from "../../Docs";
import {useAppDispatch} from "../../../../hooks/redux";
import {useEscapeKey} from "../../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../../store/reducers/widgets/WidgetsSlice";

interface IModalMyTaskInfo {
    openModalMyTaskInfo: boolean,
    setOpenMyTaskInfo: (value: boolean) => void
    myTaskInfo: any | null
}

const ModalMyTaskInfo: FC<IModalMyTaskInfo> = ({openModalMyTaskInfo, setOpenMyTaskInfo, myTaskInfo}) => {
    const dispatch  =useAppDispatch()

    const [activeTab, setActiveTab] = useState("1");
    const [date, setDate] = useState(new Date());
    const [btn, setBtn] = useState<number | null>(null);
    const ref = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<any>({filename: '', filetype: '', base64: ''});
    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    function addFile() {
        ref.current?.click()
    }

    const base64 = (file: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file[0]);
        reader.onload = function () {
            setFile({filename: file[0].name, filetype: file[0].type, base64: (reader.result as string).split(',')[1]})
        };
        // reader.onerror = function (error) {
        //     console.log('Error: ', error);
        // };
    }
    useEscapeKey(() => dispatch(setModalAdd(false)), openModalMyTaskInfo, "ModalMyTaskInfo");
    function parse(info: any) {
        const parse1 = info?.history.replace(/<\/?(html|head|body)>/g, '').replace(/"/g, '')
        return HTMLReactParser(parse1)
    }


    return (
        <Modal id="createProjectModal" isOpen={openModalMyTaskInfo}
               toggle={() => setOpenMyTaskInfo(!openModalMyTaskInfo)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setOpenMyTaskInfo(!openModalMyTaskInfo)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Информация о задаче мне
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                             role="tablist">
                            <NavItem className="mt-2">
                                <NavLink
                                    className={`px-2 ${classNames({"border-bottom border-2 border-primary": activeTab === "1"})}`}
                                    onClick={() => {
                                        tabChange("1");
                                    }}
                                    type="button" to="#">
                                    <i className="fas fa-home"></i>
                                    Информация
                                </NavLink>
                            </NavItem>
                            <NavItem className="mt-2">
                                <NavLink
                                    className={`px-2 ${classNames({"border-bottom border-2 border-primary": activeTab === "2"})}`}
                                    onClick={() => {
                                        tabChange("2");
                                    }}
                                    type="button" to="#">
                                    <i className="fas fa-home"></i>
                                    Задача
                                </NavLink>
                            </NavItem>
                            <NavItem className="mt-2">
                                <NavLink
                                    className={`px-2 ${classNames({"border-bottom border-2 border-primary": activeTab === "3"})}`}
                                    onClick={() => {
                                        tabChange("3");
                                    }}
                                    type="button" to="#">
                                    <i className="fas fa-home"></i>
                                    История выполнения
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <Row>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1" className="align-items-center">
                                    <div className="table-responsive">
                                        <table className="table mb-0">
                                            <tbody>
                                            <tr>
                                                <th scope="row" style={{ width: "200px" }}>
                                                    Организация
                                                </th>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Дата старта</th>
                                                <td>{myTaskInfo && convertDateLineInDot(myTaskInfo?.data)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Автор</th>
                                                <td>{myTaskInfo?.author}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Дата создания</th>
                                                <td>{myTaskInfo && convertDateLineInDot(myTaskInfo?.data)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Дата завершения</th>
                                                <td>{myTaskInfo && (convertDateLineInDot(myTaskInfo?.enddate?.split(" ")[0]) + " " + myTaskInfo?.enddate?.split(" ")[1])}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Название</th>
                                                <td>{myTaskInfo?.name}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Описание</th>
                                                <td>{myTaskInfo?.description}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2" className="align-items-center">
                                    <Formik
                                        initialValues={
                                            {
                                                message: ''
                                            }
                                        }
                                        onSubmit={(values) => {
                                            const data = {
                                                message: values.message,
                                                uid: myTaskInfo?.uid,
                                                button: btn,
                                                ...file
                                            }
                                            MyTaskService.nextWaypoint(data).then(r => console.log(r.data))
                                        }}
                                    >
                                        <Form>
                                            <div className="table-responsive">
                                                <table className="table mb-0">
                                                    <tbody>
                                                    <tr>
                                                        <th scope="row" style={{ width: "200px" }}>Срок</th>
                                                        <td>{myTaskInfo && (convertDateLineInDot(myTaskInfo?.enddate?.split(" ")[0]) + " " + myTaskInfo?.enddate?.split(" ")[1])}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Автор</th>
                                                        <td>{myTaskInfo?.author}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Наименование</th>
                                                        <td>{myTaskInfo?.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Описание</th>
                                                        <td>{myTaskInfo?.description}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Файл <br/>
                                                            <button className="btn btn-primary" type={"button"} onClick={() => addFile()}>Добавить</button>
                                                            <input type="file" className="d-none" ref={ref} onChange={(file) => base64(file?.target?.files)}/>
                                                        </th>
                                                        <td>
                                                            {
                                                                file.base64 &&
                                                                <Docs url={{url: file.base64, name: file.filename}}/>
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Дата следующего этапа</th>
                                                        <td>
                                                            <Flatpickr
                                                                className="form-control border-0 dash-filter-picker shadow"
                                                                value={date}
                                                                options={{
                                                                    locale: Russian,
                                                                    mode: "single",
                                                                    dateFormat: "d.m.Y H:i",
                                                                    enableTime: true
                                                                }}
                                                                onChange={(date) => setDate(date[0])}
                                                            />
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <div className="mt-2 px-2">
                                                    <span className="fw-bolder">Отчет о выполнении</span>
                                                    <Field as="textarea" name="message" id="" className="form-control mt-2"/>
                                                </div>
                                            </div>
                                            <div className="mt-2 ">
                                                <h4>{myTaskInfo?.point}</h4>
                                                <div className="d-flex gap-2">
                                                    {
                                                        myTaskInfo && myTaskInfo.answers?.map((ans: any) => (<button key={ans.key} className="btn btn-primary" type={"submit"} onClick={() => setBtn(ans.key)}>{ans.value}</button>))
                                                    }
                                                </div>
                                            </div>
                                        </Form>
                                    </Formik>
                                </TabPane>
                                <TabPane tabId="3" className="align-items-center">
                                    <div className="table-responsive pt-2">
                                        {/*<table className="table mb-0">*/}
                                        {/*    <tbody>*/}
                                        {/*        <tr>*/}
                                        {/*            <th scope="row" style={{ width: "200px" }}>30.01.2023</th>*/}
                                        {/*            <td> Выполнить / БутаковаЕА / Выполнена</td>*/}
                                        {/*        </tr>*/}
                                        {/*    </tbody>*/}
                                        {/*</table>*/}

                                        {
                                            parse(myTaskInfo)
                                        }
                                    </div>
                                </TabPane>
                            </TabContent>
                        </Row>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

export default ModalMyTaskInfo;
