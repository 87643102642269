import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import AxiosClient from "../../../api/AxiosClient";
import {customStyles} from "../../settings";
import Select from "react-select";
import moment from "moment";
import {useAppDispatch} from "../../../hooks/redux";
import {setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import ModalConfirmExit from "../Schools/ModalConfirmExit";
import useDebounce from "../../../hooks/useDebounce";
import _ from "lodash";
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface IAddCashMovement {
    addCashMovement: boolean,
    setAddCashMovement: (value: boolean) => void
    setListCashMovement: () => void
}

const AddCashMovement: FC<IAddCashMovement> = ({setAddCashMovement, addCashMovement, setListCashMovement}) => {
    const idButtonSave: string = 'buttonSaveUpdateCashMovement';
    const [date, setDate] = useState(new Date());
    const [schools, setSchools] = useState<any[]>([]);
    const [selectSchool, setSelectSchool] = useState<any>({value: '', label: ''});
    const [selectBuild, setSelectBuild] = useState<any>({value: '', label: ''});
    const [selectClass, setSelectClass] = useState<any>({value: '', label: ''});
    const [builds, setBuilds] = useState<any[]>([]);
    const [classes, setClasses] = useState<any[]>([])
    const [banks, setBanks] = useState<any[]>([]);
    const [students, setStudents] = useState<any[]>([]);
    const [selectStudent, setSelectStudent] = useState({value: '', label: ''});
    const [personalAccounts, setPersonalAccounts] = useState<any[]>([]);
    const [selectPersonalAccount, setSelectPersonalAccount] = useState({value: '', label: ''});
    const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
    const [typeOperation, setTypeOperation] = useState<any[]>([]);
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [pageOption, setPageOption] = useState(1);
    const [totalOptions, setTotalOptions] = useState(0);
    const [confirmExit, setConfirmExit] = useState(false);
    const [currentObject, setCurrentObject] = useState<any>({ date: '', number_transaction: '', catering_organizer_id: '', student_id: '', personal_account_id: '',
        school_id: '', building_id: '', class_id: '', type_operation_id: '',  payment_method_id: '', bank_id: '', sum: '', cvit: ''});
    const [newObject, setNewObject] = useState<any>({ date: '', number_transaction: '', catering_organizer_id: '', student_id: '', personal_account_id: '',
        school_id: '', building_id: '', class_id: '', type_operation_id: '',  payment_method_id: '', bank_id: '', sum: '', cvit: ''});
    const [isChanges, setIsChanges] = useState(false);
    const [searchChildrenFio, setSearchChildrenFio] = useState('');
    const [searchPersonalAccount, setSearchPersonalAccount] = useState('');
    const [listCateringOrganizer, setListCateringOrganizer] = useState<any[]>([]);
    const debounceSearchFio = useDebounce(searchChildrenFio, 300)
    const debounceSearchPa = useDebounce(searchPersonalAccount, 300)
    const dispatch = useAppDispatch()
    useEffect(() => {
        AxiosClient.get('/schools')
            .then((r) => {
                setSchools(r.data.schools)
            })
            .catch(() => console.log('error'))
        AxiosClient.get('/banks')
            .then(r => setBanks(r.data))
        AxiosClient.get('/payment_methods')
            .then(r => setPaymentMethods(r.data))
        AxiosClient.get('/type_operations')
            .then(r => setTypeOperation(r.data))
        AxiosClient.get(`/users/catering_organizers`)
            .then((r) => setListCateringOrganizer(r.data))
        fetchMoreStudents(1)
            .then(r => {
                setStudents(r)
                if (r[0]) {
                    setPersonalAccounts(r[0].personal_accounts)
                    setSelectStudent({
                        value: r[0].id.toString(),
                        label: `${r[0].last_name} ${r[0].name} ${r[0].middle_name}`
                    })
                    if (r[0].personal_accounts[0]) {
                        setSelectPersonalAccount({
                            value: r[0].personal_accounts[0].id,
                            label: r[0].personal_accounts[0].personal_account
                        })
                        setSelectSchool({
                            value: r[0].personal_accounts[0].school.id,
                            label: r[0].personal_accounts[0].school.name
                        })
                        setSelectBuild({
                            value: r[0].personal_accounts[0].building.id,
                            label: r[0].personal_accounts[0].building.name
                        })
                        setSelectClass({
                            value: r[0].personal_accounts[0].class.id,
                            label: r[0].personal_accounts[0].class.name
                        })
                    }
                }
            })
            .catch(() => console.log('error'))
    }, []);

    useEffect(() => {
        fetchMoreStudents(1, debounceSearchFio, searchPersonalAccount)
            .then(r => {
                setStudents(r)
                if (r[0]) {
                    setPersonalAccounts(r[0].personal_accounts)
                    setSelectStudent({
                        value: r[0].id.toString(),
                        label: `${r[0].last_name} ${r[0].name} ${r[0].middle_name}`
                    })
                    if (r[0].personal_accounts[0]) {
                        setSelectPersonalAccount({
                            value: r[0].personal_accounts[0].id,
                            label: r[0].personal_accounts[0].personal_account
                        })
                        setSelectSchool({
                            value: r[0].personal_accounts[0].school.id,
                            label: r[0].personal_accounts[0].school.name
                        })
                        setSelectBuild({
                            value: r[0].personal_accounts[0].building.id,
                            label: r[0].personal_accounts[0].building.name
                        })
                        setSelectClass({
                            value: r[0].personal_accounts[0].class.id,
                            label: r[0].personal_accounts[0].class.name
                        })
                    }
                }
            })
            .catch(() => console.log('error'))
            setPageOption(1)
    }, [debounceSearchFio]);

    useEffect(() => {
        fetchMoreStudents(1, searchChildrenFio, debounceSearchPa)
            .then(r => {
                setStudents(r)
                if (r[0]) {
                    setPersonalAccounts(r[0].personal_accounts)
                    setSelectStudent({
                        value: r[0].id.toString(),
                        label: `${r[0].last_name} ${r[0].name} ${r[0].middle_name}`
                    })
                    if (r[0].personal_accounts[0]) {
                        setSelectPersonalAccount({
                            value: r[0].personal_accounts[0].id,
                            label: r[0].personal_accounts[0].personal_account
                        })
                        setSelectSchool({
                            value: r[0].personal_accounts[0].school.id,
                            label: r[0].personal_accounts[0].school.name
                        })
                        setSelectBuild({
                            value: r[0].personal_accounts[0].building.id,
                            label: r[0].personal_accounts[0].building.name
                        })
                        setSelectClass({
                            value: r[0].personal_accounts[0].class.id,
                            label: r[0].personal_accounts[0].class.name
                        })
                    }
                }
            })
            .catch(() => console.log('error'))
            setPageOption(1)
    }, [debounceSearchPa]);


    useEffect(() => {
        fetchBuildings(selectSchool.value)
            .then(r => setBuilds(r))
    }, [selectSchool.value, schools])

    useEffect(() => {
        const sortClass = builds?.find(s => s.id.toString() === selectBuild.value.toString())?.class
        setClasses(sortClass)
        if (sortClass?.length > 0) {
            setSelectClass({value: sortClass[0].id.toString(), label: sortClass[0].name})
        } else {
            setSelectClass({value: '', label: ''})
        }
    }, [builds, selectBuild.value])

    const fetchBuildings = async (selectSchool: string) => {
        if (selectSchool) {
            const response = await AxiosClient.get(`/schools/${selectSchool}/buildings/classes`)
            return response?.data
        }
        return []
    };

    const onChangeStudent = (student: { value: string, label: string }) => {
        setSelectStudent(student)
        const personalAccounts = students.find(s => s.id.toString() === student.value.toString())?.personal_accounts
        setPersonalAccounts(personalAccounts)
        setSelectPersonalAccount({value: personalAccounts[0].id, label: personalAccounts[0].personal_account})
        setSelectSchool({value: personalAccounts[0].school.id, label: personalAccounts[0].school.name})
        setSelectBuild({value: personalAccounts[0].building.id, label: personalAccounts[0].building.name})
        setSelectClass({value: personalAccounts[0].class.id, label: personalAccounts[0].class.name})
    }

    const onChangePersonalAccount = (personalAccount: { value: string, label: string }) => {

        setSelectPersonalAccount(personalAccount)
        const pa = personalAccounts.find(p => p.id.toString() === personalAccount.value.toString())
        setSelectSchool({value: pa.school.id, label: pa.school.name})
        setSelectBuild({value: pa.building.id, label: pa.building.name})
        setSelectClass({value: pa.class.id, label: pa.class.name})
    }

    const fetchMoreStudents = async (page: number, fio = '', personalAccount = '') => {
        setLoadingOptions(true)
        const response = await AxiosClient.get(`/students`, {params: {page: page, per_page: 10, fio, personalAccount}})
        setLoadingOptions(false)
        setTotalOptions(response?.data.total)
        return response?.data.students
    };

    const loadMoreData = async () => {
        if (Math.ceil(totalOptions / 10) === pageOption)
            return
        fetchMoreStudents(pageOption + 1, searchChildrenFio, searchPersonalAccount).then(r => {
            setStudents(prevOptions => [...prevOptions, ...r]);
            setPageOption(state => state + 1)
        }); // функция, которая загружает дополнительные данные

    };

    const handleMenuScroll = async (event: any) => {
        const target = event.target;
        if (totalOptions !== students.length && target.scrollHeight - target.scrollTop === target.clientHeight) {
            await loadMoreData();
        }
    };

    const confirmOrExit = () => {
        if (!_.isEqual(currentObject, newObject)) {
            setConfirmExit(true);
        } else {
            setAddCashMovement(false);
        }
    }
    useEscapeKey(() => dispatch(setModalAdd(false)), addCashMovement, "addCashMovement");


    return (
        <Modal id="addCashMovement" isOpen={addCashMovement}
               toggle={() => confirmOrExit()}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => confirmOrExit()}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление движения денежных средств
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            date: '',
                            number_transaction: '',
                            cvit: '',
                            bank_id: banks[0] && banks[0].id,
                            payment_method_id: paymentMethods[0] && paymentMethods[0].id,
                            type_operation_id: typeOperation[0] && typeOperation[0].id,
                            personal_account_id: '',
                            catering_organizer_id: newObject.catering_organizer_id,
                            student_id: '',
                            school_id: '',
                            building_id: '',
                            class_id: '',
                            sum: ''
                        }
                    }
                    validationSchema={
                        Yup.object({
                            number_transaction: Yup.number().typeError('Только числа').integer('Целые числа'),
                            catering_organizer_id: Yup.string().required('Обязательное поле'),
                            sum: Yup.number().typeError('Только числа').required('Обязательное поле'),
                        })
                    }
                    onSubmit={(values) => {
                        dispatch(setIsSend(true))
                        values.date = moment(date).format("Y-M-D").toString()
                        values.personal_account_id = selectPersonalAccount.value
                        values.student_id = selectStudent.value
                        values.school_id = selectSchool.value
                        values.building_id = selectBuild.value
                        values.class_id = selectClass.value
                        AxiosClient.post('/cash_movements/create', values)
                            .then(() => {
                                dispatch(setIsSend(false))
                                setListCashMovement()
                                setAddCashMovement(false)
                            })
                            .catch(() => {
                                dispatch(setIsSend(false))
                                console.log('error')
                            })
                    }}>
                    {
                        ({setFieldValue, errors, touched}) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Дата:</label>
                                        <Flatpickr
                                            className="form-control border-0 dash-filter-picker shadow"
                                            value={date}
                                            options={{
                                                locale: Russian, dateFormat: "d M Y"
                                            }}
                                            onClose={(date) => {
                                                setDate(date[0]);
                                                setNewObject({...newObject, 'date': date[0]});
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <div>
                                            <label>Номер транзакции:</label>
                                            <Field name="number_transaction"
                                                   className={`form-control ${errors.number_transaction && touched.number_transaction && "border border-2 border-danger"}`}
                                                   onChange={(e: any) => {
                                                       setNewObject({...newObject, 'number_transaction': e.target.value});
                                                       setFieldValue('number_transaction', e.target.value);
                                                   }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>

                                    {/* <Col>
                                        <div>
                                            <label>Квитовка:</label>
                                            <Field name="cvit" className={`form-control ${errors.cvit && touched.cvit && "border border-2 border-danger"}`}/>
                                        </div>
                                    </Col> */}
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Организатор питания:</label>
                                        <Field name="catering_organizer_id" as="select" className={`form-select ${errors.catering_organizer_id && touched.catering_organizer_id && "border border-2 border-danger"}`}
                                               onChange={(e: any) => {
                                                   setIsChanges(true);
                                                   setNewObject({...newObject, 'catering_organizer_id': e.target.value});
                                               }}
                                        >
                                            <option value="">Не выбрано</option>
                                            {
                                                listCateringOrganizer.map((ca) => (<option key={ca.id} value={ca.id}>{ca.name}</option>))
                                            }
                                        </Field>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <label>Ученик:</label>
                                            <Select
                                                options={students?.map(v => ({
                                                    value: v.id,
                                                    label: `${v.last_name} ${v.name} ${v.middle_name}`
                                                }))}
                                                filterOption={(data, newValue) => {
                                                    if (newValue === '') {
                                                        setSearchChildrenFio(newValue)
                                                        setSearchPersonalAccount(newValue)
                                                        return true
                                                    }
                                                    if (/^[а-яa-z]/i.test(newValue)) {
                                                        setSearchChildrenFio(newValue)
                                                    } else {
                                                        setSearchPersonalAccount(newValue)
                                                    }
                                                    return true
                                                }}
                                                value={selectStudent}
                                                onChange={(change: any) => {
                                                    onChangeStudent({value: change.value, label: change.label})
                                                    setNewObject({...newObject, 'student_id': change.value});
                                                }}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                                isLoading={loadingOptions}
                                                onMenuScrollToBottom={handleMenuScroll}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <label>Лицевой счет:</label>
                                            <Select
                                                options={personalAccounts?.map(v => ({
                                                    value: v.id,
                                                    label: v.personal_account
                                                }))}
                                                value={selectPersonalAccount}
                                                onChange={(change: any) => {
                                                    onChangePersonalAccount({value: change.value, label: change.label})
                                                    setNewObject({...newObject, 'personal_account_id': change.value});
                                                }}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                                name="personal_account_id"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <label>Школа:</label>
                                            <Select
                                                options={schools?.map(v => ({value: v.id, label: v.name}))}
                                                value={selectSchool}
                                                onChange={(change: any) => {
                                                    setSelectSchool({value: change.value, label: change.label})
                                                    setNewObject({...newObject, 'school_id': change.value});
                                                }}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                                name="school_id"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <label>Корпус:</label>
                                            <Select
                                                options={builds?.map(v => ({value: v.id, label: v.name}))}
                                                value={selectBuild}
                                                onChange={(change: any) => {
                                                    setSelectBuild({value: change.value, label: change.label})
                                                    setNewObject({...newObject, 'building_id': change.value});
                                                }}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                                name="name"
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div>
                                            <label>Класс:</label>
                                            <Select
                                                options={classes?.map(v => ({value: v.id, label: v.name}))}
                                                value={selectClass}
                                                onChange={(change: any) => {
                                                    setSelectClass({value: change.value, label: change.label})
                                                    setNewObject({...newObject, 'class_id': change.value});
                                                }}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                                name="name"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <label>Тип операции:</label>
                                            <Field name="type_operation_id" as="select" className="form-select"
                                                   onChange={(e: any) => {
                                                       setNewObject({...newObject, 'type_operation_id': e.target.value});
                                                       setFieldValue('type_operation_id', e.target.value);
                                                   }}
                                            >
                                                {
                                                    typeOperation.map((bank) => <option key={bank.id}
                                                                                        value={bank.id}>{bank.name}</option>)
                                                }
                                            </Field>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <label>Способ оплаты:</label>
                                            <Field name="payment_method_id" as="select" className="form-select"
                                                   onChange={(e: any) => {
                                                       setNewObject({...newObject, 'payment_method_id': e.target.value});
                                                       setFieldValue('payment_method_id', e.target.value);
                                                   }}
                                            >
                                                {
                                                    paymentMethods.map((bank) => <option key={bank.id}
                                                                                         value={bank.id}>{bank.name}</option>)
                                                }
                                            </Field>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div>
                                            <label>Банк:</label>
                                            <Field name="bank_id" as="select" className="form-select"
                                                   onChange={(e: any) => {
                                                       setNewObject({...newObject, 'bank_id': e.target.value});
                                                       setFieldValue('bank_id', e.target.value);
                                                   }}
                                            >
                                                {
                                                    banks.map((bank) => <option key={bank.id}
                                                                                value={bank.id}>{bank.name}</option>)
                                                }
                                            </Field>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <label>Сумма:</label>
                                            <Field name="sum"
                                                   className={`form-control ${errors.sum && touched.sum && "border border-2 border-danger"}`}
                                                   onChange={(e: any) => {
                                                       setNewObject({...newObject, 'sum': e.target.value});
                                                       setFieldValue('sum', e.target.value);
                                                   }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <div className="mt-2">
                                    <SubmitWithLoading id={idButtonSave} text={"Добавить"}/>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
            <ModalConfirmExit idButtonSave={idButtonSave} setUpdate={value => setAddCashMovement(value)} confirmExit={confirmExit}
                              setConfirmExit={value => setConfirmExit(value)}/>
        </Modal>
    );
};

export default AddCashMovement;
