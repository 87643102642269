import React, {FC, lazy, Suspense, useCallback, useEffect, useState} from 'react';
import Draggable, {DraggableData, DraggableEvent} from 'react-draggable';
import {
    Badge, Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalHeader, Row,
    UncontrolledDropdown
} from "reactstrap";
//import SettingsTexture from "./SettingsTexture";
import {ISettingsAnswerShot, ISettingsShot, IShot} from "../../../models/game/IShot";
import {ITexture} from "../../../models/game/ITexture";
import {TypeSettings} from "../../../models/game/typesSettings/TypeSettings";
import {ISettingsCollect, ISettingsSearch} from "../../../models/game/ISettingsTexture";
import {ResizableBox} from "react-resizable";
import "react-resizable/css/styles.css";
import './style.scss'
import {TypeSettingsShot} from "../../../models/game/typesSettings/TypeSettingsShot";
import {IGame} from "../../../models/game/IGame";
import bg from "../../../assetsGame/icons/bgStartEnd.jpg";

const SettingsTexture = lazy(() => import("./SettingsTexture"));

interface IProps {
    shot: IShot,
    openFullShot: boolean,
    saveSettingsShot: (shot: IShot) => void,
    game: IGame | null
}

const FullShot: FC<IProps> = ({shot, openFullShot, saveSettingsShot, game}) => {
    const [openSettingsTexture, setOpenSettingsTexture] = useState(false);
    const [openSettingsPersonage, setOpenSettingsPersonage] = useState(false);
    const [selectTexture, setSelectTexture] = useState<ITexture | null>(null);
    const [isOpenCopySettings, setIsOpenCopySettings] = useState(false);
    const [textureToCopy, setTextureToCopy] = useState<ITexture[]>([]);
    const [isResize, setIsResize] = useState(false);

    const toggleOpenTextureCopySettings = () => {
        setSelectTexture(null)
        setIsOpenCopySettings(false)
    }
    const handleStopTexture = (e: DraggableEvent, data: DraggableData, texture: ITexture) => {
        const elem = document.getElementById('window')
        const onePercentX = elem?.offsetWidth ? elem.offsetWidth / 100 : 0;
        const onePercentY = elem?.offsetHeight ? elem.offsetHeight / 100 : 0;

        saveSettingsShot({
            ...shot,
            textures: shot.textures.map((el) => {
                if (el.pivot.name === texture.pivot.name) {
                    return {
                        ...el,
                        pivot: {
                            ...el.pivot,
                            x: data.x,
                            y: data.y,
                            percentX: +(data.x / onePercentX).toFixed(0),
                            percentY: +(data.y / onePercentY).toFixed(0)
                        }
                    }
                }
                return el;
            })
        })
    };

    const handleStopWords = (e: DraggableEvent, data: DraggableData) => {
        const elem = document.getElementById('window')
        const onePercentX = elem?.offsetWidth ? elem.offsetWidth / 100 : 0;
        const onePercentY = elem?.offsetHeight ? elem.offsetHeight / 100 : 0;

        const { settings } = shot;
        if (settings) {
            saveSettingsShot({
                ...shot,
                settings: {
                    ...settings,
                    ...(settings.settings && {
                        settings: {
                            ...settings.settings,
                            x: data.x,
                            y: data.y,
                            percentX: +(data.x / onePercentX).toFixed(0),
                            percentY: +(data.y / onePercentY).toFixed(0)
                        }
                    }),
                }
            })
        }
    };

    const handleOpenSettingsTexture = () => {
        setOpenSettingsTexture(!openSettingsTexture)
    }

    const saveSettingsTexture = (texture: ITexture) => {
        if (texture.pivot.settings.type === TypeSettings.COLLECT) {
            const textures = (texture.pivot.settings.settings as ISettingsCollect).textures.map(el => ({
                ...el,
                pivot: {
                    ...el.pivot,
                    settings: {
                        ...el.pivot.settings,
                        type: TypeSettings.SELECT_COLLECT,
                        settings: {
                            parent: texture
                        }
                    }
                }
            } as ITexture))
            saveSettingsShot({
                ...shot,
                textures: shot.textures.map((el) => {
                    if (el.pivot.name === texture.pivot.name) {
                        return texture
                    }
                    const t = textures.find((t) => t.id === el.id)
                    if (t) {
                        return t
                    }
                    return el
                })
            })
        } else {
            saveSettingsShot({
                ...shot,
                textures: shot.textures.map((el) => {
                    if (el.pivot.name === texture.pivot.name) {
                        return texture
                    }
                    return el
                })
            })
        }
    }

    const removeTexture = (texture: ITexture) => {
        saveSettingsShot({
            ...shot,
            textures: shot.textures.filter(el => el.pivot.name !== texture.pivot.name)
        })
    }

    const defaultPosition = (percentX: number, percentY: number) => {
        const elem = document.getElementById('window')
        const onePercentX = elem?.offsetWidth ? elem.offsetWidth / 100 : 0;
        const onePercentY = elem?.offsetHeight ? elem.offsetHeight / 100 : 0;

        const x = onePercentX * percentX
        const y = onePercentY * percentY
        return {x, y}
    }

    const copyTexture = (texture: ITexture) => {
        const elem = document.getElementById('window')
        const onePercentX = elem?.offsetWidth ? elem.offsetWidth / 100 : 0;
        const onePercentY = elem?.offsetHeight ? elem.offsetHeight / 100 : 0;
        const x =  elem?.offsetWidth ? elem.offsetWidth / 2 : texture.pivot.x
        const y = elem?.offsetHeight ? elem.offsetHeight / 2 : texture.pivot.y

        const numbers = shot.textures.map(obj => {
            const match = obj.pivot.name.match(/\d+/); // Находим число в 'name'
            return match ? parseInt(match[0], 10) : 0; // Преобразуем в число или возвращаем 0
        });
        const maxNumber = numbers.length > 0 ? Math.max(...numbers) + 1 : 1;

        saveSettingsShot({
            ...shot,
            textures: [...shot.textures, {
                ...texture,
                pivot: {
                    ...texture.pivot,
                    name: 'Текстура' + maxNumber,
                    x: x,
                    y: y,
                    percentX: +(x / onePercentX).toFixed(0),
                    percentY: +(y / onePercentY).toFixed(0)
                }
            }]
        })
    };

    const copySettings = () => {
        selectTexture &&
        saveSettingsShot({
            ...shot,
            textures: shot.textures.map(texture => {
                if (textureToCopy.find(el => el.pivot.name === texture.pivot.name)) {
                    return {
                        ...texture,
                        pivot: {
                            ...texture.pivot,
                            settings: selectTexture.pivot.settings,
                        },
                    }
                }
                return texture
            })
        })
        setIsOpenCopySettings(false)
        setSelectTexture(null)
        setTextureToCopy([])
    }

    const maxContains = (): [number, number] => {
        const sized = document.getElementById('mainSize')
        if (sized){
            return [sized.offsetWidth, sized.offsetHeight]
        }
        return [1000, 1000]
    }

    const showTexture = (el: ITexture) => {
        const type = el.pivot.settings.type;
        if (type === TypeSettings.SEARCH) {
            const settings = el.pivot.settings.settings as ISettingsSearch

            if (settings.closeTexture === "rectangle") {
                return <div className="w-100 h-100 position-absolute top-0 rounded opacity-50" style={{background: settings.color}}>
                </div>
            }

            if (settings.closeTexture === "circle") {
                return <div className="w-100 h-100 position-absolute top-0 rounded-circle opacity-50" style={{background: settings.color}}>
                </div>
            }
        }
    }
    const RaspolozheniePoCol = () => {
        const Zn = 'auto '
        const TextureSearch = shot.textures.filter(el => (el.pivot.settings as any).type === TypeSettings.SEARCH).length
        if(TextureSearch <=4 && TextureSearch%2 === 0){
            return Zn.repeat(TextureSearch/2)
        }
        else if(TextureSearch >4 && TextureSearch%2 === 0){
            return Zn.repeat(TextureSearch/2)
        }
        else if(TextureSearch%3 === 0){
            return Zn.repeat(TextureSearch/3)
        }
        else{
            if(TextureSearch <=4 && TextureSearch%2 === 1) {
                return Zn.repeat(2)
            }
            else if(TextureSearch >4 && TextureSearch%2 === 1){
                return Zn.repeat(4)
            }
            else{
                return Zn.repeat(3)
            }
        }
    }

    const RaspolozheniePoRow = () => {
        const Zn = 'auto '
        const TextureSearch = shot.textures.filter(el => (el.pivot.settings as any).type === TypeSettings.SEARCH).length
        if(TextureSearch%2 === 0){
            return Zn.repeat(2)
        }
        else if(TextureSearch%3 === 0){
            return Zn.repeat(3)
        }
        else{
            return Zn.repeat(2)
        }
    }

    return (
        openFullShot ? (
            <div className="position-absolute top-0 start-0 h-100 w-100 bg-white col-xxl-3"
                 id="mainSize"
                 style={shot.backgroundImage ? {
                     backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL + shot.backgroundImage.path})`,
                     backgroundSize: '100% 100%',
                     backgroundPosition: 'center'
                 } : {}}>
                {shot.textures.filter(el => (el.pivot.settings as any).type !== TypeSettings.SEARCH).map((el, index) => (
                    <Draggable
                        key={index}
                        position={defaultPosition(el.pivot.percentX, el.pivot.percentY)}
                        bounds="parent"
                        enableUserSelectHack={false}
                        disabled={false}
                        onStop={(e, data) => {
                            if(!isResize) {
                                handleStopTexture(e, data, el)
                            }
                        }}
                    >
                        <div className="position-absolute">
                            <UncontrolledDropdown
                                className="cursor-pointer position-absolute top-0 end-0"
                                direction="end"
                                style={{zIndex: 10}}
                            >
                                <DropdownToggle
                                    color="primary"
                                >
                                    <i className="bx bx-cog fs-20"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => {
                                        !openSettingsTexture &&
                                            setOpenSettingsTexture(true)
                                        setSelectTexture(el)
                                    }}>
                                        Редактировать
                                    </DropdownItem>
                                    <DropdownItem onClick={() => {
                                        setIsOpenCopySettings(true)
                                        setSelectTexture(el)
                                    }}>
                                        Копировать настройки
                                    </DropdownItem>
                                    <DropdownItem onClick={() => {
                                        copyTexture(el)
                                    }}>
                                        Копировать
                                    </DropdownItem>
                                    <DropdownItem onClick={() => removeTexture(el)}>
                                        Удалить
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <ResizableBox
                                width={el.pivot.settings.width}
                                height={el.pivot.settings.height}
                                minConstraints={[50, 50]} // Минимальные размеры
                                maxConstraints={maxContains()}
                                className="custom-resizable-box"
                                onResize={(w, h) => {
                                    saveSettingsShot({
                                        ...shot,
                                        textures: shot.textures.map((t) => {
                                            if (el.id === t.id) {
                                                return {
                                                    ...t,
                                                    pivot: {
                                                        ...t.pivot,
                                                        settings: {
                                                            ...t.pivot.settings,
                                                            width: h.size.width,
                                                            height: h.size.height
                                                        }
                                                    }
                                                }
                                            }
                                            return t
                                        })
                                    })
                                }}
                                onResizeStart={() => {
                                    setIsResize(true)
                                }}
                                onResizeStop={() => {
                                    setIsResize(false)
                                }}
                                resizeHandles={['nw', 'n', 'w']} // Изменение размера за правый нижний угол
                            >

                                <div className="w-100 h-100">
                                    <div className="position-absolute w-100 h-100">
                                    </div>
                                    <img src={process.env.REACT_APP_API_BASE_URL + el.path}
                                         style={{width: "100%", height: "100%"}}/>
                                    {
                                        showTexture(el)
                                    }
                                </div>
                            </ResizableBox>
                        </div>
                    </Draggable>
                ))}
                {shot.settings &&
                    (shot.settings).type === TypeSettingsShot.SEARCH &&
                    <div className="containerGame w-100 h-100" style={{border: 'none', gridTemplateColumns: `${RaspolozheniePoCol()}`, gridTemplateRows: `${RaspolozheniePoRow()}`}}>
                        {shot.textures.filter(el => (el.pivot.settings as any).type === TypeSettings.SEARCH).map((el, index) => (
                            <div className="position-relative" style={{zIndex: 5, padding: '0.5vw'}}>
                                <UncontrolledDropdown
                                    className="cursor-pointer position-absolute top-0 end-0"
                                    direction="end"
                                    style={{zIndex: 10}}
                                >
                                    <DropdownToggle
                                        color="primary"
                                    >
                                        <i className="bx bx-cog fs-20"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => {
                                            !openSettingsTexture &&
                                            setOpenSettingsTexture(true)
                                            setSelectTexture(el)
                                        }}>
                                            Редактировать
                                        </DropdownItem>
                                        <DropdownItem onClick={() => {
                                            setIsOpenCopySettings(true)
                                            setSelectTexture(el)
                                        }}>
                                            Копировать настройки
                                        </DropdownItem>
                                        <DropdownItem onClick={() => {
                                            copyTexture(el)
                                        }}>
                                            Копировать
                                        </DropdownItem>
                                        <DropdownItem onClick={() => removeTexture(el)}>
                                            Удалить
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <ResizableBox
                                    width={el.pivot.settings.width}
                                    height={el.pivot.settings.height}
                                    minConstraints={[50, 50]} // Минимальные размеры
                                    maxConstraints={[280, 280]}
                                    className="custom-resizable-box"
                                    onResize={(w, h) => {
                                        saveSettingsShot({
                                            ...shot,
                                            textures: shot.textures.map((t) => {
                                                return {
                                                    ...t,
                                                    pivot: {
                                                        ...t.pivot,
                                                        settings: {
                                                            ...t.pivot.settings,
                                                            width: h.size.width,
                                                            height: h.size.height
                                                        }
                                                    }
                                                }
                                                return t
                                            })
                                        })
                                    }}
                                    onResizeStart={() => {
                                        setIsResize(true)
                                    }}
                                    onResizeStop={() => {
                                        setIsResize(false)
                                    }}
                                    resizeHandles={['nw', 'n', 'w']} // Изменение размера за правый нижний угол
                                >
                                    <div className="w-100 h-100">
                                        <div className="position-absolute w-100 h-100">
                                        </div>
                                        <img src={process.env.REACT_APP_API_BASE_URL + el.path} alt=""
                                             style={{width: "100%", height: "100%"}}/>
                                        {
                                            showTexture(el)
                                        }
                                    </div>
                                </ResizableBox>
                            </div>
                        ))}
                    </div>
                }
                {
                    shot.settings &&
                    (shot.settings).type === TypeSettingsShot.ANSWER &&
                    <Draggable

                        bounds="parent"
                        defaultPosition={defaultPosition(((shot.settings as ISettingsShot).settings as ISettingsAnswerShot).percentX, ((shot.settings as ISettingsShot).settings as ISettingsAnswerShot).percentY)}
                        onStop={(e, data) => handleStopWords(e, data)}
                    >
                        <div className="position-absolute">
                            <ResizableBox
                                width={((shot.settings as ISettingsShot).settings as ISettingsAnswerShot).width}
                                height={((shot.settings as ISettingsShot).settings as ISettingsAnswerShot).height}
                                minConstraints={[100, 100]} // Минимальные размеры
                                maxConstraints={[700, 700]} // Максимальные размеры
                                className="custom-resizable-box"
                                onResize={(w, h) => {
                                    const { settings } = shot;
                                    if(settings) {
                                        saveSettingsShot({
                                            ...shot,
                                            settings: {
                                                ...settings,
                                                ...(settings.settings && {
                                                    settings: {
                                                        ...settings.settings,
                                                        width: h.size.width,
                                                        height: h.size.height
                                                    }
                                                }),
                                            }
                                        })
                                    }
                                }}
                                resizeHandles={['nw', 'n', 'w']} // Изменение размера за правый нижний угол
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: (shot.settings.settings as ISettingsAnswerShot).htmlVid
                                    }}
                                />
                            </ResizableBox>
                        </div>
                    </Draggable>
                }
                {
                    selectTexture &&
                    <Suspense>
                    <SettingsTexture shot={shot} game={game} openSettingsTexture={openSettingsTexture}
                                     handleOpenSettingsTexture={handleOpenSettingsTexture} selectTexture={selectTexture}
                                     saveSettingsTexture={(texture) => saveSettingsTexture(texture)}/>
                    </Suspense>
                }
                {
                    selectTexture &&
                    <Suspense>
                    <Modal isOpen={isOpenCopySettings} toggle={toggleOpenTextureCopySettings} centered>
                        <ModalHeader toggle={toggleOpenTextureCopySettings} className="p-3 bg-soft-primary">Текстуры для копирования настроек</ModalHeader>
                        <ModalBody>
                            <Row>
                                {
                                    shot.textures.map((el, index) => (
                                        el.pivot.name !== selectTexture.pivot.name ?
                                        <Col xxl={4} lg={4} sm={6} xs={6} key={index} className="position-relative d-flex justify-content-center">
                                            {
                                                textureToCopy.find(el1 => el1.pivot.name === el.pivot.name) &&
                                                <Badge color="success" style={{width: 30, height: 30}}
                                                       className="rounded-circle d-flex justify-content-center align-items-center position-absolute top-0 start-100 translate-middle"><i
                                                    className="bx bx-check fs-3"></i></Badge>
                                            }
                                            <img src={process.env.REACT_APP_API_BASE_URL + el.path}
                                                 alt="user" className="cursor-pointer"
                                                 style={{height: 100, width: 100}}
                                                 onClick={() => {
                                                     if (textureToCopy.find(el1 => el1.pivot.name === el.pivot.name))
                                                         setTextureToCopy((prev) => prev.filter((el1) => el1.pivot.name !== el.pivot.name))
                                                     else
                                                         setTextureToCopy((prev) => [...prev, el])
                                                 }}/>
                                        </Col> : <></>
                                    ))
                                }
                            </Row>
                            <div className="mt-3 gap-2 d-flex">
                                <button className="btn btn-primary" type="button" onClick={() => copySettings()}>
                                    Копировать
                                </button>
                                <button className="btn btn-primary" type="button" onClick={toggleOpenTextureCopySettings}>
                                    Отмена
                                </button>
                            </div>
                        </ModalBody>
                    </Modal>
                    </Suspense>
                }
            </div>
        ) : <></>
    );
};

export default FullShot;
