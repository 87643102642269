import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import AxiosClient from "../../../api/AxiosClient";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import Select from "react-select";
import {customStyles, customStylesImport} from "../../settings";
import {useAppDispatch} from "../../../hooks/redux";
import {setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import * as Yup from "yup";
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface IUpdateBuffet {
    updateBuffet: boolean,
    setUpdateBuffet: (value: boolean) => void,
    selectBuffetOrder: any,
    syncBuffetOrders: () => void
}

const UpdateBuffet: FC<IUpdateBuffet> = ({updateBuffet, setUpdateBuffet, selectBuffetOrder, syncBuffetOrders}) => {

    const [date, setDate] = useState(new Date());
    const [listPersonalAccounts, setListPersonalAccounts] = useState<any[]>([]);
    const [selectPersonalAccount, setSelectPersonalAccount] = useState({value: '', label: 'Не выбрано'});
    const [schools, setSchools] = useState<any[]>([]);
    const [builds, setBuilds] = useState<any[]>([]);
    const [cashRegisters, setCashRegisters] = useState<any[]>([]);
    const [assortments, setAssortments] = useState<any[]>([]);
    const [selectAssortments, setSelectAssortments] = useState({value: '', label: 'Не выбрано', price: 0});
    const [selectSchool, setSelectSchool] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectBuild, setSelectBuild] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectCashRegisters, setSelectCashRegisters] = useState<any>({value: '', label: 'Не выбрано'});
    const [count, setCount] = useState(0);
    const [price, setPrice] = useState(0);
    const [pageOptionPersonalAccount, setPageOptionPersonalAccount] = useState(1);
    const [totalOptionsPersonalAccount, setTotalOptionsPersonalAccount] = useState(0);

    const dispatch = useAppDispatch()

    useEffect(() => {
        AxiosClient.get('/schools')
            .then((r) => {
                setSchools(r.data.schools)
            })
        fetchMoreStudentsPersonalAccounts(1)
            .then(r => {
                setPageOptionPersonalAccount(state => state + 1)
                setListPersonalAccounts(r)
            })
        AxiosClient.get('/cash_registers')
            .then((r) => {
                setCashRegisters(r.data)
            })
        AxiosClient.get('/products', {params: {type: 'buffet'}})
            .then((r) => {
                setAssortments(r.data)
            })
    }, []);

    useEffect(() => {
        if (selectBuffetOrder){
            setDate(moment(selectBuffetOrder?.date).toDate())
            if (selectBuffetOrder?.personal_account){
                setSelectPersonalAccount({value: selectBuffetOrder?.personal_account.id, label: selectBuffetOrder?.personal_account.personal_account})
                setSelectBuild({value: selectBuffetOrder?.personal_account.building?.id.toString(), label: selectBuffetOrder?.personal_account.building?.name})
                setSelectSchool({value: selectBuffetOrder?.personal_account.school.id.toString(), label: selectBuffetOrder?.personal_account.school.name})
            }
            else
                changeSchool({value: selectBuffetOrder?.school.id, label: selectBuffetOrder?.school.name})

            setSelectBuild({value: selectBuffetOrder?.building.id, label: selectBuffetOrder?.building.name})
            setSelectCashRegisters({value: selectBuffetOrder?.cash_register?.id, label: selectBuffetOrder?.cash_register?.name})
            setSelectAssortments({value: selectBuffetOrder?.product?.id, label: selectBuffetOrder?.product?.name, price: selectBuffetOrder?.product?.price})
            setCount(selectBuffetOrder?.count)
            setPrice(selectBuffetOrder?.price)
        }
    }, [selectBuffetOrder]);
    const fetchMoreStudentsPersonalAccounts = async (page: number) => {
        const response = await AxiosClient.get(`/personal_accounts`, {params: {page: page, per_page: 10}})
        setTotalOptionsPersonalAccount(response?.data.total)
        return response?.data.personalAccounts
    };

    const loadMoreDataPersonalAccounts = async () => {
        fetchMoreStudentsPersonalAccounts(pageOptionPersonalAccount).then(r => {
            setListPersonalAccounts(prevOptions => [...prevOptions, ...r]);
            setPageOptionPersonalAccount(state => state + 1)
        }); // функция, которая загружает дополнительные данные

    };

    const handleMenuScrollPersonalAccount = async (event: any) => {
        const target = event.target;
        if (totalOptionsPersonalAccount !== listPersonalAccounts.length && target.scrollHeight - target.scrollTop === target.clientHeight) {
            await loadMoreDataPersonalAccounts();
        }
    };

    const changeSchool = (data: {value: string, label: string}) => {
        if (data.value){
            const sortBuilds = schools?.find(s => s.id.toString() === data.value.toString())?.builds
            setBuilds(sortBuilds)
            setSelectSchool(data)
            setSelectPersonalAccount({value: '', label: 'Не выбрано'})
        } else{
            setSelectSchool(data)
            setSelectBuild(data)
        }

    }

    const changePersonalAccount = (data: {value: string, label: string}) => {
        if (data.value !== ''){
            setSelectPersonalAccount(data)
            const pa = listPersonalAccounts?.find(s => s.id.toString() === data.value.toString())
            AxiosClient.get(`/schools/${pa.school.id}/buildings/classes`)
                .then((r) => setBuilds(r.data))
            setSelectBuild({value: pa.building?.id.toString(), label: pa.building?.name})
            setSelectSchool({value: pa.school.id.toString(), label: pa.school.name})
        } else{
            setSelectPersonalAccount(data)
            setSelectSchool(data)
            setSelectBuild(data)
        }

    }
    useEscapeKey(() => dispatch(setModalAdd(false)), updateBuffet, "updateBuffet");


    return (
        <Modal id="updateBuffet" isOpen={updateBuffet}
               toggle={() => setUpdateBuffet(!updateBuffet)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setUpdateBuffet(!updateBuffet)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавить покупки
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={{
                        personal_account_id: selectPersonalAccount.value,
                        school_id: selectSchool.value,
                        school_building_id: selectBuild.value,
                        cash_register: selectCashRegisters.value,
                        assortment_id: selectAssortments.value,
                        count: count,
                        price: price,
                        sum: price * count
                    }}
                    validationSchema={
                        Yup.object({
                            personal_account_id: Yup.string().required('Обязательное поле'),
                            school_id: Yup.string().required('Обязательное поле'),
                            school_building_id: Yup.string().required('Обязательное поле'),
                            cash_register: Yup.string().required('Обязательное поле'),
                            assortment_id: Yup.string().required('Обязательное поле'),
                        })
                    }
                    onSubmit={(values) => {
                        dispatch(setIsSend(true))
                        const data = {
                            date: moment(date).format('YYYY-MM-DD').toString(),
                            personal_account_id: values.personal_account_id,
                            school_id: values.school_id,
                            school_building_id: values.school_building_id,
                            cash_register_id: values.cash_register,
                            assortment_id: values.assortment_id,
                            count: values.count,
                            price: values.price,
                            sum: values.sum,
                        }
                        AxiosClient.post(`/buffet_orders/update/${selectBuffetOrder?.id}`, data)
                            .then(() => {
                                dispatch(setIsSend(false))
                                syncBuffetOrders()
                                setUpdateBuffet(false)
                            })
                            .catch(() => {
                                dispatch(setIsSend(false))
                                console.log('error')
                            })
                    }}
                >
                    {({setFieldValue, errors, touched}) => (
                    <Form>
                        <Row>
                            <Col>
                                <div className="">
                                    <label className="w-100">Дата:
                                    <Flatpickr
                                        className="form-control border-0 dash-filter-picker shadow"
                                        value={date}
                                        options={{
                                            locale: Russian, dateFormat: "d M Y",
                                        }}
                                        onChange={(date) => setDate(date[0])}
                                    />
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="">
                                    <label className="w-100">Лицевой счет:
                                    <Select
                                        name="personal_account_id"
                                        options={listPersonalAccounts?.length > 0 ? [{
                                            value: '',
                                            label: 'Не выбрано'
                                        }, ...listPersonalAccounts.map(v => ({value: v.id, label: v.personal_account}))] : [{value: '', label: 'Не выбрано'}]}
                                        value={selectPersonalAccount}
                                        onChange={(change: any) => {
                                            changePersonalAccount({value: change.value, label: change.label})
                                            setFieldValue('personal_account_id', change.value);
                                        }}
                                        styles={(errors.personal_account_id && touched.personal_account_id) ? customStylesImport() : customStyles}
                                        placeholder="Поиск"
                                        onMenuScrollToBottom={handleMenuScrollPersonalAccount}
                                    />
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="">
                                    <label className="w-100">Школа:
                                    <Select
                                        name="school_id"
                                        options={schools?.length > 0 ? [{
                                            value: '',
                                            label: 'Не выбрано'
                                        }, ...schools.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                                        value={selectSchool}
                                        onChange={(change: any) => {
                                            changeSchool({value: change.value, label: change.label})
                                            setFieldValue('school_id', change.value);
                                        }}
                                        styles={(errors.school_id && touched.school_id) ? customStylesImport() : customStyles}
                                        placeholder="Поиск"
                                    />
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="">
                                    <label className="w-100">Корпус:
                                    <Select
                                        name="school_building_id"
                                        options={builds?.length > 0 ? [{
                                            value: '',
                                            label: 'Не выбрано'
                                        }, ...builds?.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                                        value={selectBuild}
                                        onChange={(change: any) => {
                                            setSelectBuild({value: change.value, label: change.label})
                                            setFieldValue('school_building_id', change.value);
                                        }}
                                        styles={(errors.school_building_id && touched.school_building_id) ? customStylesImport() : customStyles}
                                        placeholder="Поиск"
                                    />
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="">
                                    <label className="w-100">Касса:
                                    <Select
                                        name="cash_register"
                                        options={cashRegisters?.length > 0 ? [{
                                            value: '',
                                            label: 'Не выбрано',
                                            price: 0
                                        }, ...cashRegisters?.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано', price: 0}]}
                                        value={selectCashRegisters as any}
                                        onChange={(change: any) => {
                                            setFieldValue('cash_register', change.value);
                                        }}
                                        styles={(errors.cash_register && touched.cash_register) ? customStylesImport() : customStyles}
                                        placeholder="Поиск"
                                    />
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="">
                                    <label className="w-100">Технологическая карта:
                                    <Select
                                        name="assortment_id"
                                        options={assortments?.length > 0 ? [{
                                            value: '',
                                            label: 'Не выбрано',
                                            price: 0
                                        }, ...assortments?.map(v => ({value: v.id, label: v.name, price: v.price}))] : [{value: '', label: 'Не выбрано', price: 0}]}
                                        value={selectAssortments as any}
                                        onChange={(change: any) => {
                                            setSelectAssortments({value: change.value, label: change.label, price: change.price})
                                            setPrice(+change.price)
                                            setFieldValue('assortment_id', change.value);
                                        }}
                                        styles={(errors.assortment_id && touched.assortment_id) ? customStylesImport() : customStyles}
                                        placeholder="Поиск"
                                    />
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="">
                                    <label className="w-100">Кол-во:
                                    <input className="form-control" value={count}
                                           name="count"
                                           onChange={(event) => {
                                               !isNaN(+event.target.value) && setCount(+event.target.value)
                                               setFieldValue('count', event.target.value);
                                               setFieldValue('sum', count * price);
                                           }}/>
                                    </label>
                                </div>
                            </Col>
                            <Col>
                                <div className="">
                                    <label className="w-100">Цена:
                                    <input className="form-control" value={price}
                                           name="price"
                                           onChange={(event) => {
                                               !isNaN(+event.target.value) && setPrice(+event.target.value)
                                               setFieldValue('price', event.target.value);
                                               setFieldValue('sum', count * price);
                                           }}/>
                                    </label>
                                </div>
                            </Col>
                            <Col>
                                <div className="">
                                    <label className="w-100">Сумма:
                                        <Field className="form-control" name="sum" disabled/>
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <div className="mt-2">
                            <SubmitWithLoading text={"Сохранить"}/>
                        </div>
                    </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default UpdateBuffet;
