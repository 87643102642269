import React, { FC, useEffect, useState } from 'react';
import AxiosClient from "../../../api/AxiosClient";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import Select from "react-select";
import {customStyles} from "../../settings";
import {useAppDispatch} from "../../../hooks/redux";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";

interface IAddMaterials {
    update: boolean;
    setUpdate: (value: boolean) => void;
    addMyMaterials: any[];
    setAddMyMaterials: (value: any[]) => void;
    selectMaterials: any;
}

const AddMaterials: FC<IAddMaterials> = ({
                                                                                 update,
                                                                                 setUpdate,
                                                                                 addMyMaterials,
                                                                                 setAddMyMaterials,
                                                                                 selectMaterials
                                                                             }) => {
    const [materials, setMaterials] = useState<any[]>([]);
    const [selectMaterial, setSelectMaterial] = useState<any>({value:'', label:''});

    useEffect(() => {
        if(update){
            AxiosClient.get('getMaterialList')
                .then((r: any) => {
                    setMaterials(r.data)
                    if(selectMaterials) setSelectMaterial({value:selectMaterials.uid_material, label:selectMaterials.name_material})
                    else setSelectMaterial({value: r.data[0]?.uid, label: r.data[0]?.name});
                })
                .catch((error) => console.log(error));
        }
    }, [update]);
    const dispatch = useAppDispatch();
    useEscapeKey(() => dispatch(setModalAdd(false)), update, "AddMaterials");


    return (
        <Modal
            id="AddMaterials"
            isOpen={update}
            toggle={() => setUpdate(!update)}
            modalClassName="zoomIn"
            tabIndex={-1}
            centered
        >
            <ModalHeader
                toggle={() => setUpdate(!update)}
                className="p-3 bg-soft-primary"
                id="createProjectModalLabel"
            >
                Обновление материала
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={{
                        uid: selectMaterial.value,
                        count: selectMaterials?.count || ''
                    }}
                    validationSchema={
                        Yup.object({
                            count: Yup.string().required('Требуется указать количество')
                    })}
                    onSubmit={(values) => {
                        const newObject = {
                            uid_material: selectMaterial.value,
                            name_material: selectMaterial.label,
                            count: values.count,
                        };

                        setAddMyMaterials(
                            addMyMaterials.some(mat => mat.uid_material === selectMaterial.value)
                                    ? addMyMaterials.map(item =>
                                        item.uid_material === selectMaterial.value ? newObject : item
                                    )
                                    : [newObject, ...addMyMaterials]
                        );

                        setUpdate(false);
                    }}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <div className="mb-3">
                                <label className="w-100"> Название:
                                    <Select options={materials.map(org => ({value: org.uid, label: org.name}))}
                                            value={selectMaterial}
                                            onChange={(change: any) => {
                                                setSelectMaterial({value: change.value, label: change.label})
                                            }}
                                            styles={customStyles}
                                            placeholder="Поиск"
                                    />
                                </label>
                            </div>
                            <div className="mb-3">
                                <label className="w-100">
                                    Количество:
                                    <Field name="count" className={`form-control ${errors.count && touched.count && "border border-2 border-danger"}`} />
                                </label>
                            </div>
                            <div className="hstack gap-2 justify-content-end mt-1">
                                <SubmitWithLoading text={"Сохранить"}/>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default AddMaterials;
