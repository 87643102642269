import React, {useState} from 'react';
import {Link} from "react-router-dom";
import ModalDataProtection from "./ModalDataProtection";

const StringAsDataProtection = () => {
    const [modalProtection, setModalProtection] = useState(false);

    return (
        <>
            <Link to="#" className="ms-3" onClick={() => {setModalProtection(true)}}>
                Как мы защищаем ваши данные?
            </Link>
            <ModalDataProtection modalProtection={modalProtection} setModalProtection={(value: boolean) => setModalProtection(value)}/>
        </>
    );
};

export default StringAsDataProtection;
