import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import WidgetService from "../../../api/services/widgetService/WidgetService";
import {setToUpdateGraphMainPage} from "../../../store/reducers/widgets/WidgetsSlice";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {
    ProjectsJournalHaccpAntisepticTableware,
    ProjectsJournalHaccpMeasuringTemperetureBody
} from "../../widgets/ProjectStatusCharts";

const WidgetGraphAntisepticTableware = () => {
    const {toUpdateGraphMainPage, date, journalHaccpAntisepticTableware} = useAppSelector(state => state.WidgetReducer)
    const dispatch = useAppDispatch();

    useEffect(() => {
        toUpdateGraphMainPage &&
        WidgetService.getDeviationGroupMonth(null, date)
            .then(res => {
                dispatch(setToUpdateGraphMainPage(false))
            })
    }, [toUpdateGraphMainPage, date, dispatch])

    return (
        <>
            <Card>
                <CardHeader className="border-0 align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Журнал обработки посуды</h4>
                </CardHeader>

                <CardHeader className="p-0 border-0 bg-soft-light">
                    <Row className="g-0 text-center">
                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <span className="counter-value">
                                        {journalHaccpAntisepticTableware.length > 0 ? journalHaccpAntisepticTableware.length : "0"}
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Количество записей</p>
                            </div>
                        </Col>
                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <span className="counter-value">
                                        {journalHaccpAntisepticTableware.length > 0 ?
                                            ((journalHaccpAntisepticTableware.reduce((sum, value) => sum + parseInt(value.count), 0))/journalHaccpAntisepticTableware.length).toFixed()
                                            : '0'
                                        }
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Среднее количество питающихся</p>
                            </div>
                        </Col>
                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <span className="counter-value">
                                         {journalHaccpAntisepticTableware.length > 0 ?
                                             ((journalHaccpAntisepticTableware.reduce((total, item) => {
                                                 return total + item.dishes.reduce((linenTotal : any, linenItem: any) => {
                                                     return linenTotal + parseInt(linenItem.units);
                                                 }, 0);
                                             }, 0) / journalHaccpAntisepticTableware.length).toFixed()) :
                                             '0'
                                         }
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Среднее количество посуды</p>
                            </div>
                        </Col>
                    </Row>
                </CardHeader>

                <CardBody className="p-0 pb-2">
                    <div>
                        {
                            journalHaccpAntisepticTableware && <ProjectsJournalHaccpAntisepticTableware series={journalHaccpAntisepticTableware}
                                                           dataColors='[ "--vz-green",  "--vz-warning", "--vz-danger", "--vz-blue", "--vz-purple", "--vz-orange", "--vz-teal", "--vz-pink", "--vz-cyan",  "--vz-indigo" ]'/>

                        }
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default WidgetGraphAntisepticTableware;
