import React, {useEffect} from 'react';
import DataTable from "react-data-table-component";
import {Input} from "reactstrap";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {ParentSlice} from "../../../store/reducers/parent/ParentSlice";

const TableInfoEvolutionsChildren = () => {

    const dispatch = useAppDispatch();

    const {evolutionWays, allInfoEvolutionChild} = useAppSelector(state => state.ParentReducer)

    const checkGroup = (evolutionWay: string) => {
        if (evolutionWays.includes(evolutionWay)) {
            dispatch(ParentSlice.actions.setEvolutionWay(evolutionWays.filter(item => item !== evolutionWay)))
        } else {
            dispatch(ParentSlice.actions.setEvolutionWay([...evolutionWays, evolutionWay]))
        }
    }

    useEffect(() => {
        allInfoEvolutionChild.length > 0 &&
            dispatch(ParentSlice.actions.setEvolutionWay(allInfoEvolutionChild[0].evolution_way?.map(item => item.name)))
    }, [allInfoEvolutionChild, dispatch]);

    const columnsEvolutions: any = [
        {
            selector: (row: string) =>
                (<span className="accessibility_size_table" onClick={() => checkGroup(row)}>
                    {row}
                </span>),
            wrap: true,
            grow: 3
        },
        {
            selector: (row: string) => (
                <Input type="checkbox" className="border-primary m-0" style={{width: '20px', height: '20px'}}
                       value={row}
                       checked={evolutionWays.includes(row)}
                       onChange={() => checkGroup(row)}/>
            ),
            center: true
        }
    ];


    return (
        <DataTable
            columns={columnsEvolutions}
            data={allInfoEvolutionChild.length > 0 ? allInfoEvolutionChild[0].evolution_way?.map(item => item.name) : []}
            noDataComponent={'Нет данных'}
            defaultSortAsc={false}
            noTableHead
            onRowClicked={(row) => checkGroup(row)}
            highlightOnHover
            pointerOnHover
            customStyles={{
                rows: {
                    highlightOnHoverStyle: {
                        backgroundColor: "#ffebb5"
                    }
                }
            }}
        />
    );
};

export default TableInfoEvolutionsChildren;
