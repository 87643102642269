import React, {useEffect, useState} from 'react';
import {
    setDateAdd, setGroupModalAddDO, setModalAddDO, setModalSignDO
} from "../../../store/reducers/widgets/WidgetsSlice";
import {Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import ModalAddTableAllInfoGroupDO from "../modal/ModalAddTableAllInfoGroupDO";
import ModalAddSignTableAllInfoGroupDO from "../modal/ModalAddSignTableAllInfoGroupDO";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {ITableAllInfoGroup} from "../../../models/widgets/ITableAllInfoGroup";
import {getAllInfoGroupDo} from "../../../store/reducers/widgets/ActionCreator";
import ModalGetTabel from "../modal/ModalGetTabel";
import moment from "moment/moment";

const WidgetTableAllInfoGroupDo = () => {
    const dispatch = useAppDispatch();

    const {date, groupAllDeviationsDO, allInfoGroupDo} = useAppSelector(state => state.WidgetReducer)
    const [getTabel, setGetTabel] = useState(false);

    useEffect(() => {
        dispatch(getAllInfoGroupDo(date)).then()
    }, [date, dispatch])


    const columns: any = [
        {
            id: "date",
            name: <span className='font-weight-bold mb-0 accessibility_size_table'>Дата</span>,
            selector: (row: ITableAllInfoGroup) => <span className="accessibility_size_table">{row.data}</span>,
            sortable: true,
            center: true,
            width: '120px',
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Группа</span>,
            selector: (row: ITableAllInfoGroup) => <span className="accessibility_size_table">{row.group_name}</span>,
            center: true,
            compact: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Был</span>,
            selector: (row: ITableAllInfoGroup) => <span className="accessibility_size_table">{row.present}</span>,
            center: true,
            compact: true,
            sortable: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Не был</span>,
            selector: (row: ITableAllInfoGroup) => <span className="accessibility_size_table">{row.no_present}</span>,
            center: true,
            compact: true,
            sortable: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Статус</span>,
            selector: (row: ITableAllInfoGroup) => {
                switch (row.status) {
                    case 'Закрыт':
                        return <span className="badge bg-danger accessibility_size_table"> {row.status} </span>;
                    case 'Подписан':
                        return <span className="badge bg-success accessibility_size_table"> {row.status} </span>;
                    case 'Проведен':
                        return <span className="badge bg-info accessibility_size_table"> {row.status} </span>;
                    default:
                        break;
                }
            },
            center: true,
            sortable: true
        }
    ];

    return (
        <>
            <Card>
                <CardHeader>
                    Таблица посещения по группам ДО
                </CardHeader>
                <CardBody className="py-0">
                    <DataTable
                        columns={columns}
                        data={allInfoGroupDo.filter(item => item.group_id === groupAllDeviationsDO)}
                        pagination
                        paginationRowsPerPageOptions={[5, 10, 20, 30]}
                        paginationComponentOptions={
                            {
                                rowsPerPageText: 'Видно дней:',
                                rangeSeparatorText: 'из'
                            }
                        }
                        noDataComponent={'Нет групп'}
                        defaultSortFieldId={"date"}
                        defaultSortAsc={false}
                        highlightOnHover
                        pointerOnHover
                        customStyles={{
                            rows: {
                                highlightOnHoverStyle: {
                                    backgroundColor: "#ffebb5"
                                }
                            }
                        }}

                        onRowClicked={
                            (row: ITableAllInfoGroup) => {
                                if(window.innerWidth <= 768){
                                    dispatch(setGroupModalAddDO(row.group_id));
                                    dispatch(setModalAddDO(true));
                                    dispatch(setDateAdd(moment(row.data, "DD.MM.YYYY").toDate()));
                                }
                            }
                        }
                        onRowDoubleClicked={
                            (row: ITableAllInfoGroup) => {
                                if(window.innerWidth > 768){
                                    dispatch(setGroupModalAddDO(row.group_id));
                                    dispatch(setModalAddDO(true));
                                    dispatch(setDateAdd(moment(row.data, "DD.MM.YYYY").toDate()));
                                }
                            }
                        }
                    />
                </CardBody>
                <CardFooter className="d-flex justify-content-between">
                    <div className='d-flex'>
                        <div className="button_modal mx-1">
                            <button className="btn btn-primary w-100"
                                    onClick={() => dispatch(setModalAddDO(true))}>Добавить
                            </button>
                        </div>
                        <div className="button_modal mx-1">
                            <button className="btn btn-primary w-100"
                                    onClick={() => dispatch(setModalSignDO(true))}>Подписать
                            </button>
                        </div>
                        <div className="button_modal mx-1">
                            <button className="btn btn-primary w-100"
                                    onClick={() => setGetTabel(true)}>Табель
                            </button>
                        </div>
                    </div>
                </CardFooter>
            </Card>
            <ModalGetTabel modalSign={getTabel} setModalSign={(value) => setGetTabel(value)} group_id={groupAllDeviationsDO}/>
            <ModalAddTableAllInfoGroupDO/>
            <ModalAddSignTableAllInfoGroupDO/>
        </>
    );
};

export default WidgetTableAllInfoGroupDo;
