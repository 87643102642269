import React, {FC} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import DataTable from "react-data-table-component";

interface IListClass {
    listClass: any[],
    openListClass: boolean,
    setOpenListClass: (value: boolean) => void;
}
const ListClass: FC<IListClass> = ({openListClass, setOpenListClass, listClass}) => {

    const columns = [
        {
            name: <span className='font-weight-bold accessibility_size_table'>Класс</span>,
            cell: (row: any) => <span className='accessibility_size_table'> {row.name}</span>,
            wrap: true,
        }
    ]

    return (
        <Modal id="createProjectModal" isOpen={openListClass}
               toggle={() => setOpenListClass(!openListClass)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setOpenListClass(!openListClass)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Классы
            </ModalHeader>
            <ModalBody>
                <DataTable
                    columns={columns}
                    data={listClass}
                    noDataComponent={'Нет данных'}
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно классов:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </ModalBody>
        </Modal>
    );
};

export default ListClass;
