import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    setDateAdd,
    WidgetsSlice
} from "../../../store/reducers/widgets/WidgetsSlice";
import {Col, Modal, ModalBody, ModalHeader} from "reactstrap";
import {Field, Form, Formik} from "formik";
import moment from "moment/moment";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";

import * as Yup from "yup";
import ModalConfirmExitHaccp from "../ModalConfirmExitHaccp";
import {useModalWithConfirm} from "../../../hooks/useModalWithConfirm";


interface IModalAddCleaningDisinfection {
    updateData: any
}

const ModalAddCleaningDisinfection: FC<IModalAddCleaningDisinfection> = ({updateData}) => {
    const dispatch = useAppDispatch();
    const {
        modalAdd,
        groupAllDeviations,
        date,
        groupModalAdd,
        groups,
        dateAdd,
        successUpdate,
        journalCleaningDisinfection
    } = useAppSelector(state => state.WidgetReducer);
    const [activeTab, setActiveTab] = useState("1");

    const [uidGroup, setUidGroup] = useState("");
    const [addLamp, setAddLamp] = useState<any>();

    const {
        isFormChange,
        showConfirmExit,
        handleClose,
        handleFormChange,
        handleConfirmClose,
        handleStay,
    } = useModalWithConfirm("addReportJournalCleaningDisinfection");

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(WidgetsSlice.actions.successUpdate(""))
        }, 5000)
        return () => clearTimeout(timer)
    }, [successUpdate, dispatch]);

    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        if (modalAdd && !updateData) {
            dispatch(setDateAdd(new Date()));
        }
    }, [modalAdd]);

    return (
        <>
            <Modal id="ModalAddCleaningDisinfection" isOpen={modalAdd}  toggle={handleClose}
                   modalClassName="zoomIn" tabIndex={-1} centered>
                <ModalHeader  toggle={handleClose} className="p-3 bg-soft-primary"
                              id="createProjectModalLabel">
                    Добавление информации об уборке и дезинфекции
                </ModalHeader>
                <ModalBody>

                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            date: moment(dateAdd).format("DD.MM.YYYY"),
                            lamps: addLamp,
                        }}
                        validationSchema={
                            Yup.object({
                                linen: Yup.array().min(1, '')
                            })}
                        onSubmit={(value) => {
                            // dispatch(setIsSend(true));

                            let prop = {
                                uid: updateData ? updateData.uid : uidGroup !== '' ? uidGroup : '',
                                data: moment(dateAdd).format('DD.MM.YYYY'),
                                group_uid: groupAllDeviations,
                                lamps: addLamp,
                            };

                        }}
                    >
                        {({errors, touched, setFieldValue}) => (
                            <Form onChange={handleFormChange}>

                                <div className="hstack gap-2 justify-content-end mt-1">
                                    <SubmitWithLoading text={"Сохранить"}/>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
            <ModalConfirmExitHaccp
                isOpen={showConfirmExit}
                onClose={handleConfirmClose}
                onStay={handleStay}
                hasUnsavedChanges={isFormChange}
            />
        </>
    );
};

export default ModalAddCleaningDisinfection;
