import React, {FC} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {useAppDispatch} from "../../../hooks/redux";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";

interface IModelAddSchool {
    updateBuild: boolean,
    setUpdateBuild: (value: boolean) => void,
    setBuilds: (value: any) => void,
    build: any | null,
    builds: any[],
    setNewBuilds: (value: any) => void,
    newBuilds: any[]
}

const ModalUpdateBuilding: FC<IModelAddSchool> = ({setUpdateBuild, updateBuild, setBuilds, build, builds, setNewBuilds, newBuilds}) => {
    const dispatch = useAppDispatch();
    useEscapeKey(() => dispatch(setModalAdd(false)), updateBuild, "updateBuild");

    return (
        <Modal id="updateBuild" isOpen={updateBuild}
               toggle={() => setUpdateBuild(!updateBuild)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setUpdateBuild(!updateBuild)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Обновление корпуса
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            name: build?.name,
                        }
                    }
                    validationSchema={
                        Yup.object({
                            name: Yup.string().required('Обязательное поле'),
                        })
                    }
                    onSubmit={(values) => {
                        setBuilds(builds.map(b => {
                            if (b.name === build?.name)
                            {
                                return {
                                    ...b,
                                    name: values.name
                                }
                            }
                            return b
                        }))

                        setNewBuilds(newBuilds.map(b => {
                            if (b.name === build?.name)
                            {
                                return {
                                    ...b,
                                    name: values.name
                                }
                            }
                            return b
                        }))
                    }}>
                    {
                        ({errors, touched}) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Название:</label>
                                        <Field name="name" className={`form-control ${errors.name && touched.name && "border border-2 border-danger"}`}/>
                                    </Col>
                                </Row>
                                <div className="hstack gap-2">
                                    <button type="submit" className="btn btn-primary" id="addNewProject" onClick={() => setUpdateBuild(false)}>Сохранить</button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalUpdateBuilding;
