import React, {FC} from 'react';
import {
    Modal as ReactstrapModal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
} from "reactstrap";

interface IModalConfirmDeleteChild {
    isOpen: boolean;
    childToDelete: any | null;
    onConfirm: () => void;
    onCancel: () => void;
}

const ModalConfirmDeleteChild: FC<IModalConfirmDeleteChild> = ({
                                                                   isOpen,
                                                                   childToDelete,
                                                                   onConfirm,
                                                                   onCancel
                                                               }) => {
    return (
        <ReactstrapModal
            isOpen={isOpen}
            toggle={onCancel}
            centered
        >
            <ModalHeader toggle={onCancel}>
                Подтверждение удаления
            </ModalHeader>
            <ModalBody>
                Уверены ли вы, что хотите удалить ребенка "{childToDelete?.fio || 'Новый ребенок'}"?
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onConfirm}>
                    Да
                </Button>
                <Button color="secondary" onClick={onCancel}>
                    Нет
                </Button>
            </ModalFooter>
        </ReactstrapModal>
    );
};

export default ModalConfirmDeleteChild;