import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    setDateAdd, setGroupAllDeviations, setGroupModalAdd,
    setIsSend,
    setModalAdd,
    WidgetsSlice
} from "../../../store/reducers/widgets/WidgetsSlice";
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import moment from "moment/moment";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import AxiosClient from "../../../api/AxiosClient";
import { getJournalHaccpMeasuringTemperatureBody} from "../../../store/reducers/widgets/ActionCreator";
import Notification from "../../widgets/Notification";
import ModalConfirmExitHaccp from "../ModalConfirmExitHaccp";
import {useModalWithConfirm} from "../../../hooks/useModalWithConfirm";

interface IModalAddReportJournalMeasuringTemperatureBody{
    updateData: any
}

const ModalAddReportJournalMeasuringTemperatureBody: FC<IModalAddReportJournalMeasuringTemperatureBody> = ({updateData}) => {
    const dispatch = useAppDispatch();
    const {modalAdd, groupAllDeviations, date, groupModalAdd, groups, dateAdd, successUpdate} = useAppSelector(state => state.WidgetReducer);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<string>('');
    const [notificationMessage, setNotificationMessage] = useState<string>('');

    const {
        isFormChange,
        showConfirmExit,
        handleClose,
        handleFormChange,
        handleConfirmClose,
        handleStay,
    } = useModalWithConfirm("addReportJournalMeasuringTemperatureBody");

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(WidgetsSlice.actions.successUpdate(""))
        }, 5000)
        return () => clearTimeout(timer)
    }, [successUpdate, dispatch]);
    useEffect(() => {
        if (modalAdd && !updateData) {
            dispatch(setDateAdd(new Date()));
        }
    }, [modalAdd]);
    function saveMeasuringTemperature(prop: any){
        AxiosClient.post('haccp/saveJournalMeasuringTemperatureBody', prop)
            .then((r: any) => {
                dispatch(getJournalHaccpMeasuringTemperatureBody(date, groupAllDeviations)).then();
                dispatch(setIsSend(false));
                dispatch(setModalAdd(false));
            })
            .catch((error) => {
                dispatch(setIsSend(false))
            })
    }

    return (
        <>
        <Modal id="ModalAddReportJournalMeasuringTemperatureBody" isOpen={modalAdd} toggle={handleClose}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={handleClose} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление параметров замера t° тела посетителей
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        group: `${groupAllDeviations}`,
                        date: moment(dateAdd).format("DD-MM-YYYY"),
                        temperature: updateData? updateData.temperature : '36.6',
                        visitor_uid: updateData? updateData.visitor_uid : '',
                        visitor_name: updateData? updateData.visitor_name : '',
                        numb: updateData? updateData.numb : '',
                        series: updateData? updateData.series : '',
                    }}
                    onSubmit={(value) => {
                        dispatch(setIsSend(true));

                        if(value.visitor_uid){
                            saveMeasuringTemperature({
                                group_id : groupAllDeviations,
                                uid: updateData? updateData.uid : '',
                                data: moment(dateAdd).format("DD-MM-YYYY"),
                                temperature: value.temperature,
                                visitor_uid: value.visitor_uid,
                                visitor_name: value.visitor_name,
                                numb: value.numb,
                                series: value.series,
                            });
                            handleConfirmClose();
                        }else {
                            AxiosClient.get('haccp/findVisitor', {params: {
                                    numb: value.numb,                                    series: value.series
                                }})
                                .then((r: any) => {
                                    if(value.visitor_name==="" && r.data.visitor_uid===""){
                                        dispatch(setIsSend(false));
                                        setNotificationType('error');
                                        setNotificationMessage('Заполните ФИО посетителя!');
                                        setShowNotification(true);
                                    }else{
                                        saveMeasuringTemperature({
                                            group_id : groupAllDeviations,
                                            uid: updateData? updateData.uid : '',
                                            data: moment(dateAdd).format("DD-MM-YYYY"),
                                            temperature: value.temperature,
                                            visitor_uid: r.data.visitor_uid ? r.data.visitor_uid : '',
                                            visitor_name: r.data.visitor_name!=="" ? r.data.visitor_name : value.visitor_name,
                                            numb: value.numb,
                                            series: value.series,
                                        });
                                    }

                                })
                                .catch((error) => {
                                    dispatch(setIsSend(false));
                                    setNotificationType('error');
                                    setNotificationMessage('Ошибка!');
                                    setShowNotification(true);
                                })
                        }
                    }}
                >
                    <Form onChange={handleFormChange}>
                        <Row>
                            <Col>
                                <label className="w-100">Дата:
                                    <Flatpickr
                                        className="form-control border-0 dash-filter-picker shadow"
                                        value={dateAdd}
                                        options={{
                                            locale: Russian, dateFormat: "d M Y", disableMobile: true, mode: 'single'
                                        }}
                                        onChange={(date) => {
                                            dispatch(setDateAdd(moment(date[0]).toDate()))
                                        }}
                                    />
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <label className="w-100">Группа:
                                <Field as="select" className="form-select shadow" value={groupModalAdd}
                                       onChange={(e: any) => {
                                        dispatch(setGroupAllDeviations(e.target.value))
                                        dispatch(setGroupModalAdd(e.target.value))
                                       }} name="group">
                                    {groups.map((group) => {
                                        return <option key={group.group_id}
                                                       value={group.group_id}>{group.group_name}</option>
                                    })}
                                </Field>
                            </label>
                        </Row>
                        <Row>
                            <label className="w-100">
                                Температура:
                                <Field name="temperature" className={`form-control`}/>
                            </label>
                        </Row>
                        <Row>
                            <label className="w-100">
                                ФИО посетителя:
                                <Field name="visitor_name" className={`form-control`}/>
                            </label>
                        </Row>
                        <Row>
                            <Col>
                                <label className="w-100">
                                    Серия паспорта:
                                    <Field name="series" className={`form-control`}/>
                                </label>
                            </Col>
                            <Col>
                                <label className="w-100">
                                    Номер:
                                    <Field name="numb" className={`form-control`}/>
                                </label>
                            </Col>
                        </Row>
                        <div className="d-flex gap-5 justify-content-between">
                            <div></div>
                            <div className="hstack gap-2 justify-content-end mt-1">
                                <SubmitWithLoading text={"Сохранить"}/>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </ModalBody>
        </Modal>
            <ModalConfirmExitHaccp
                isOpen={showConfirmExit}
                onClose={handleConfirmClose}
                onStay={handleStay}
                hasUnsavedChanges={isFormChange}
            />
            {showNotification && <Notification type={notificationType} message={notificationMessage} setShowNotification={(value: boolean) => setShowNotification(value)}/>}
        </>
    );
};

export default ModalAddReportJournalMeasuringTemperatureBody;
