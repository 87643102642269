import React from 'react';
import {ParentSlice} from "../../store/reducers/parent/ParentSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import btn from "../../assetsGame/RPG/fonmenunewgame.png";
import bg from '../../assetsGame/RPG/taverna.png'
import {useNavigate} from "react-router-dom";

const StartMainGame = () => {
    const {children, child} = useAppSelector(state => state.ParentReducer)
    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    return (
        <div className="h-100 w-100">
            <div className="position-relative"
                     style={{
                         height: '40.5vw',
                         backgroundImage: `url(${bg})`,
                         backgroundSize: '100% 100%',
                         backgroundPosition: 'center',
                         backgroundRepeat: 'no-repeat'
                     }}>
                <div className="position-absolute start-0 cursor-pointer p-3" style={{width: '15vw'}}>
                        <select className="form-select fs-14" value={child} onChange={(event) => dispatch(ParentSlice.actions.setChild(event.target.value))}>
                            {
                                children.map(child =>
                                    (
                                        <option key={child.account_uid} value={child.account_uid} className="fs-14">{child.account_name}</option>
                                    )
                                )
                            }
                        </select>
                </div>
                        <div className="position-relative d-flex justify-content-center align-items-center" style={{width:'26vw', height:'10vw', top:'17vw', left: '30vw',
                            backgroundImage: `url(${btn})`,
                            backgroundSize: '100% 100%', backgroundPosition: 'center', backgroundRepeat: "no-repeat"}}>
                            <button type={"button"} className="position-relative pt-2 border-0 bg-transparent cursor-pointer fw-bold text-black text-center"
                                    style={{width: `23vw`, fontFamily: 'Ratanegra-Cyrillic',
                                        fontSize: `3vw`}} onClick={() => {
                               // navigate('/game')
                            }}>ИГРАТЬ</button>
                        </div>
                </div>
            </div>
    );
};

export default StartMainGame;
