import React, {FC, useState} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {deleteDailyReportSleep} from "../../../store/reducers/widgets/ActionCreator";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";

interface IModalDeleteDayliReportSleep {
    modalDelete: boolean,
    setModalDelete: (value: boolean) => void
}

const ModalDeleteDayliReportSleep: FC<IModalDeleteDayliReportSleep> = ({modalDelete,setModalDelete}) => {

    const {groupAllDeviations, date} = useAppSelector(state => state.WidgetReducer)
    const [dateDelete, setDateDelete] = useState(new Date());
    const dispatch = useAppDispatch();

    function del() {
        dispatch(deleteDailyReportSleep(date, dateDelete, groupAllDeviations)).then(() => setModalDelete(false))
    }
    useEscapeKey(() => dispatch(setModalAdd(false)), modalDelete, "ModalDeleteDayliReportSleep");

    return (
        <Modal id="ModalDeleteDayliReportSleep" isOpen={modalDelete} toggle={() => setModalDelete(!modalDelete)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setModalDelete(!modalDelete)} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Удалить отчет за день(Сон)
            </ModalHeader>
            <ModalBody>
                <div className="mb-3">
                    <label>Дата:</label>
                    <Flatpickr
                        className="form-control border-0 dash-filter-picker shadow"
                        value={dateDelete}
                        options={{
                            locale: Russian, mode: "single", dateFormat: "d m Y",
                        }}
                        onChange={(date) => setDateDelete(date[0])}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <div>
                    <button className="btn btn-primary" onClick={del}>Удалить</button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default ModalDeleteDayliReportSleep;
