import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch} from "../../../hooks/redux";
import AxiosClient from "../../../api/AxiosClient";
import {setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import Select from "react-select";
import {customStyles, customStylesImport} from "../../settings";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {useEscapeKey} from "../../../hooks/useEscapeKey";


interface IModalExportStudents {
    openModalExportStudents: boolean,
    setOpenModalExportStudents: (value: boolean) => void
    setStudents: (value: any[]) => void,
    setSchoolId: (value: string) => void,
    setBuildingId: (value: string) => void,
    setClassId: (value: string) => void,
    setTotalRowsStudents: (value: number) => void
    groupBy: any,
    setGroupBy: (value: any) => void
    setTableConfirmationImport: (value: string) => void
}

const ModalExportStudents: FC<IModalExportStudents> = ({setTableConfirmationImport ,setGroupBy, groupBy ,setOpenModalExportStudents, setTotalRowsStudents, setClassId, setBuildingId, setSchoolId, openModalExportStudents, setStudents}) => {
    const [schools, setSchools] = useState<any[]>([]);
    const [builds, setBuilds] = useState<any[]>([]);
    const [classes, setClasses] = useState<any[]>([])
    const [selectSchool, setSelectSchool] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectBuild, setSelectBuild] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectClass, setSelectClass] = useState<any>({value: '', label: 'Не выбрано'});
    const [date, setDate] = useState(new Date());
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [loadingOptionsBuildings, setLoadingOptionsBuildings] = useState(false);
    const [totalOptions, setTotalOptions] = useState(0);
    const [pageOption, setPageOption] = useState(1);
    const [error, setError] = useState(false);

    const dispatch = useAppDispatch()

    useEffect(() => {
        fetchMoreSchools(1)
            .then((r) => {
                setPageOption(state => state + 1)
                setSchools(r)
            })
    }, []);


    const onChangeBuild = (data: { value: string, label: string }) => {
        const sortClass = builds?.find(s => s.id.toString() === data.value.toString())?.class
        setSelectBuild(data)
        setBuildingId(data.value)
        setClasses(sortClass)
        setSelectClass({ value: '', label: 'Не выбрано' })
    }

    const onChangeClass = (data: { value: string, label: string }) => {
        setSelectClass(data)
        setClassId(data.value)
    }

    const onChangeSchool = (data: { value: string, label: string }) => {
        fetchBuildings(data.value)
            .then(r => setBuilds(r))
        setSelectSchool(data)
        setSchoolId(data.value)
    }

    const onChangeDate = (data: Date) => {
        setDate(data)
    }

    const fetchMoreSchools = async (page: number) => {
        setLoadingOptions(true)
        const response = await AxiosClient.get(`/schools`, {params: {page: page, per_page: 3}})
        setLoadingOptions(false)
        setTotalOptions(response?.data.total)
        return response?.data.schools
    };

    const fetchBuildings = async (selectSchool: string) => {
        if(selectSchool) {
            setLoadingOptionsBuildings(true)
            const response = await AxiosClient.get(`/schools/${selectSchool}/buildings/classes`)
            setLoadingOptionsBuildings(false)
            return response?.data
        }
        return []
    };

    const loadMoreData = async () => {
        fetchMoreSchools(pageOption)
            .then(r => {
                setPageOption(state => state + 1)
                setSchools(prevOptions => [...prevOptions, ...r]);
            })

    };

    const handleMenuScroll = async (event: any) => {
        const target = event.target;
        if (totalOptions !== schools.length && target.scrollHeight - target.scrollTop === target.clientHeight) {
            await loadMoreData();
        }
    };

    const getFile = () => {
        if (!selectSchool.value){
            setError(true)
            return;
        } else {
            dispatch(setIsSend(true))
            setError(false)
            const params = {
                schoolId: selectSchool.value,
                buildingId: selectBuild.value,
                classId: selectClass.value,
                groupBy: groupBy.value
            }
            AxiosClient.get(`/students/export`, {params: {page: 1, per_page: 10, ...params}})
                .then((response) => {
                    dispatch(setIsSend(false))
                    setStudents(response.data.students);
                    setTotalRowsStudents(response.data.total);
                    setOpenModalExportStudents(false)
                    setTableConfirmationImport(groupBy.value);
                })
                .catch(() => {
                    dispatch(setIsSend(false))
                    console.log('error')
                });
        }
    }
    useEscapeKey(() => dispatch(setModalAdd(false)), openModalExportStudents, "openModalExportStudents");

    return (
        <Modal id="openModalExportStudents" isOpen={openModalExportStudents}
               toggle={() => setOpenModalExportStudents(!openModalExportStudents)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setOpenModalExportStudents(!openModalExportStudents)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Отчет учеников
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm={12}>
                        <label  className="w-100">Дата:
                        <Flatpickr
                            className="form-control border-0 dash-filter-picker shadow"
                            value={date}
                            options={{
                                locale: Russian, dateFormat: "d M Y"
                            }}
                            onClose={(dates) => onChangeDate(dates[0])}
                        />
                        </label>
                    </Col>
                    <Col sm={12}>
                        <label  className="w-100">Школа:
                        <Select
                            options={schools?.length > 0 ? [{
                                value: '',
                                label: 'Не выбрано'
                            }, ...schools.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                            value={selectSchool}
                            onChange={(change: any) => {
                                onChangeSchool({value: change.value, label: change.label})
                            }}
                            isLoading={loadingOptions}
                            onMenuScrollToBottom={handleMenuScroll}
                            styles={!error ? customStyles : customStylesImport()}
                            placeholder="Поиск"
                            name="name"
                        />
                        </label>
                        {
                            error &&
                            <div>
                                <p className="text-danger">Выберите школу</p>
                            </div>
                        }
                    </Col>
                    <Col sm={12}>
                        <label  className="w-100">Корпус:
                        <Select
                            options={builds?.length > 0 ? [{
                                value: '',
                                label: 'Не выбрано'
                            }, ...builds?.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                            value={selectBuild}
                            onChange={(change: any) => {
                                onChangeBuild({value: change.value, label: change.label})
                            }}
                            styles={customStyles}
                            placeholder="Поиск"
                            name="name"
                            isLoading={loadingOptionsBuildings}
                        />
                        </label>
                    </Col>
                    <Col sm={12}>
                        <label  className="w-100">Класс:
                        <Select
                            options={classes?.length > 0 ? [{
                                value: '',
                                label: 'Не выбрано'
                            }, ...classes?.map(v => ({value: v.id, label: v.name}))] : [{
                                value: '',
                                label: 'Не выбрано'
                            }]}
                            value={selectClass}
                            onChange={(change: any) => {
                                onChangeClass({value: change.value, label: change.label})
                            }}
                            styles={customStyles}
                            placeholder="Поиск"
                            name="name"
                            isLoading={loadingOptionsBuildings}
                        />
                        </label>
                    </Col>
                    <Col sm={12}>
                        <label  className="w-100">Сгруппировать по:
                            <Select
                                options={[
                                    {value: 'default', label: 'Не группировать'},
                                    {value: 'typePlannedMenu', label: 'Тип планового меню'},
                                    {value: 'benefit', label: 'Льгота'},
                                ]}
                                value={groupBy}
                                onChange={(change: any) => {
                                    setGroupBy({value: change.value, label: change.label})
                                }}
                                styles={customStyles}
                                placeholder="Поиск"
                                name="group"
                            />
                        </label>
                    </Col>
                    <div className="hstack gap-2 mt-2">
                        <SubmitWithLoading onClick={() => getFile()} text={'Просмотр'}/>
                    </div>
                </Row>
            </ModalBody>
        </Modal>
    );
};

export default ModalExportStudents;
