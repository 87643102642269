import React, {FC, useState} from 'react';
import {Alert, Modal, ModalBody, ModalHeader} from "reactstrap";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import Breakfast from "./FormDailyReport/Breakfast";
import SecondBreakfast from "./FormDailyReport/SecondBreakfast";
import Dinner from "./FormDailyReport/Dinner";
import Snack from "./FormDailyReport/Snack";
import Brunch from "./FormDailyReport/Brunch";
import SecondBrunch from "./FormDailyReport/SecondBrunch";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd as setMA} from "../../../store/reducers/widgets/WidgetsSlice";
import ModalConfirmExitHaccp from "../../journalHaccp/ModalConfirmExitHaccp";

interface IModalAddDayliReportNutrition {
    modalAdd: boolean,
    setModalAdd: (modalAdd: boolean) => void
}
const ModalAddDayliReportNutrition: FC<IModalAddDayliReportNutrition> = ({modalAdd, setModalAdd}) => {

    const {isError, successUpdate} = useAppSelector(state => state.WidgetReducer);

    const [nutrition, setNutrition] = useState('breakfast');

    const [isFormDirty, setIsFormDirty] = useState(false);
    const [showConfirmExit, setShowConfirmExit] = useState(false);

    const handleAttemptClose = () => {
        if (isFormDirty) {
            setShowConfirmExit(true);
        } else {
            setModalAdd(false);
        }
    };

    const handleConfirmClose = () => {
        setShowConfirmExit(false);
        setModalAdd(false);
        setIsFormDirty(false);
    };

    const handleStay = () => {
        setShowConfirmExit(false);
    };

    useEscapeKey(handleAttemptClose, modalAdd, "ModalAddDayliReportNutrition");

    const nutritions = (nutrition: string) => {
        switch (nutrition) {
            case 'breakfast':
                return <Breakfast setModalAdd={setModalAdd} setIsFormDirty={setIsFormDirty}/>
            case 'second_breakfast':
                return <SecondBreakfast setModalAdd={setModalAdd} setIsFormDirty={setIsFormDirty}/>
            case 'dinner':
                return <Dinner setModalAdd={setModalAdd} setIsFormDirty={setIsFormDirty}/>
            case 'snack':
                return <Snack setModalAdd={setModalAdd} setIsFormDirty={setIsFormDirty}/>
            case 'brunch':
                return <Brunch setModalAdd={setModalAdd} setIsFormDirty={setIsFormDirty}/>
            case 'second_brunch':
                return <SecondBrunch setModalAdd={setModalAdd} setIsFormDirty={setIsFormDirty}/>
        }
    }
    const dispatch = useAppDispatch();


    return (
        <>
        <Modal id="ModalAddDayliReportNutrition" isOpen={modalAdd} toggle={handleAttemptClose}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={handleAttemptClose} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Отчет за день(питание)
            </ModalHeader>
            <ModalBody>
                {successUpdate && <Alert>{successUpdate}</Alert>}
                {isError && <Alert color="danger">{isError}</Alert>}
                <div className="mb-3">
                    <label>Прием пищи:</label>
                    <select className="form-select" value={nutrition} onChange={(e) => setNutrition(e.target.value)}>
                        <option value="breakfast">Завтрак</option>
                        <option value="second_breakfast">Второй завтрак</option>
                        <option value="dinner">Обед</option>
                        <option value="snack">Полдник</option>
                        <option value="brunch">Ужин</option>
                        <option value="second_brunch">Второй ужин</option>
                    </select>
                </div>
                {
                    nutritions(nutrition)
                }
            </ModalBody>
        </Modal>
        <ModalConfirmExitHaccp
            isOpen={showConfirmExit}
            onClose={handleConfirmClose}
            onStay={handleStay}
            hasUnsavedChanges={isFormDirty}
        />
        </>
    );
};

export default ModalAddDayliReportNutrition;
