import React, {FC, memo} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import QRCode from "react-qr-code";
import {useAppDispatch} from "../../hooks/redux";
import {useEscapeKey} from "../../hooks/useEscapeKey";
import {setModalAdd} from "../../store/reducers/widgets/WidgetsSlice";

interface ILoginModalQrTelegram {
    modalShowQR: boolean,
    setModalShowQR: (val: boolean) => void
}
const LoginModalQrTelegram: FC<ILoginModalQrTelegram> = ({modalShowQR, setModalShowQR}) => {
    const QRCodee: any = memo(QRCode)
    const dispatch = useAppDispatch();
    useEscapeKey(() => dispatch(setModalAdd(false)), modalShowQR, "modalShowQR");

    return (
        <Modal id="modalShowQR" isOpen={modalShowQR} toggle={() => setModalShowQR(!modalShowQR)}
               modalClassName="zoomIn" tabIndex={-1} centered size="sm" >
            <ModalHeader toggle={() => setModalShowQR(!modalShowQR)} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Подключение
            </ModalHeader>
            <ModalBody>
                <QRCodee value="https://t.me/mydou_bot" style={{'marginTop': '10'}} size={250}/>
                <p style={{'marginTop': '10px'}}>Подключитесь к боту Telegram по QR-code или перейдите по ссылке <a
                    href='https://t.me/mydou_bot' onClick={(e) => {
                    e.preventDefault();
                    window.open('https://t.me/mydou_bot', '_blank');
                }} className="text-danger">Ссылка на бота</a> или вставьте в строку поиска по диалогам @mydou_bot что-бы
                    зарегестрировать свой номер телефона в нашей системе. Если вы этого еще не сделали.</p>
            </ModalBody>
        </Modal>
    );
};

export default LoginModalQrTelegram;
