import React, {FC, useEffect, useState} from 'react';
import {Field} from "formik";
import {Card, CardBody, Input} from "reactstrap";
import DataTable from "react-data-table-component";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getGroups} from "../../store/reducers/widgets/ActionCreator";

interface ITableGroupParty {
    groupsAll: any[],
    selectedRows: Array<{ [key: string]: boolean }>,
    setSelectedRows(value: any): void,
}

const TableGroupParty: FC<ITableGroupParty> = ({groupsAll, selectedRows , setSelectedRows}) => {
    const dispatch = useAppDispatch()
    const {groups, date} = useAppSelector(state => state.WidgetReducer);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        dispatch(getGroups(date)).then()
    }, [date, dispatch]);

    useEffect(() => {
        if(groups){
            const initialSelection = groupsAll.map((group) => ({
                [group.group_id]: groups.some((g) => g.group_id === group.group_id),
            }));
            setSelectedRows(initialSelection);
        }else{
            const initialSelection = groupsAll.map((group) => ({ [group.group_id]: false, }));
            setSelectedRows(initialSelection);
        }
    }, [groupsAll, groups]);

    const toggleSelectAll = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);

        const updatedSelections = groupsAll.map((group) => ({ [group.group_id]: newSelectAll, }));
        setSelectedRows(updatedSelections);
    };

    const toggleRowSelection = (group_id: string) => {
        setSelectedRows((prev: any) =>
            prev.map((row: any) =>
                row[group_id] !== undefined
                    ? { [group_id]: !row[group_id] }
                    : row
            )
        );
    };

    const columns: any = [
        {
            name: (
                <Input
                    type="checkbox"
                    className="border-primary m-0"
                    style={{ width: '20px', height: '20px' }}
                    checked={selectAll}
                    onChange={toggleSelectAll}
                />
            ),
            selector: (row: any) => (
                <Field
                    type="checkbox"
                    className="border-primary m-0"
                    style={{ width: '20px', height: '20px' }}
                    name={`checkbox.${row.group_id}`}
                    checked={
                        !!selectedRows.find((item) =>
                            Object.keys(item).includes(row.group_id)
                        )?.[row.group_id]
                    }
                    onChange={() => toggleRowSelection(row.group_id)}
                />
            ),
            style: { fontSize: '14px' },
            width: '20%',
        },
        {
            id: 'name',
            name: <span className="font-weight-bold accessibility_size_table">Группы</span>,
            selector: (row: any) => <span className="accessibility_size_table">{row.group_name}</span>,
            cell: (row: any) => row.group_name,
            sortable: true,
        },
    ];

    return (
        <Card>
            <CardBody>
                <DataTable columns={columns}
                           data={groupsAll ? [...groupsAll].sort((a, b) => a.group_name.localeCompare(b.group_name)) : []}
                           noDataComponent={'Нет групп'}
                           fixedHeaderScrollHeight={"32rem"}
                           fixedHeader={true}/>
            </CardBody>
        </Card>
    );
};

export default TableGroupParty;
