import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useStateContext } from "../../hooks/useStateContext";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru";
import MonthSelectPlugin from "flatpickr/dist/plugins/monthSelect/index";
import { EducationSlice } from "../../store/reducers/education/EducationSlice";
import moment from "moment";
import TableEducation from "../../components/education/MainEducation/TableEducation";
import TableCertification from "../../components/education/MainEducation/TableCertification";
import EducationDonutChart from "../../components/education/MainEducation/EducationDonutChart";
import CertificationDonutChart from "../../components/education/MainEducation/CertificationDonutChart";

const MainEducation = () => {
    const dispatch = useAppDispatch();
    const { user } = useStateContext();
    const { selectedDate, trainingEvents, certificationEvents } = useAppSelector(state => state.EducationReducer);

    useEffect(() => {
        // добавить логику инициализации
    }, [dispatch]);

    const trainingDates = trainingEvents.map(event => moment(event.date).format('DD.MM.YYYY'));
    const certificationDates = certificationEvents.map(event => moment(event.date).format('DD.MM.YYYY'));

    return (
        <>
            <div className="page-content">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Личный кабинет</title>
                </Helmet>
                <Container fluid>
                    <Row >
                        <Col xxl={3}>
                            <div className="mb-3">
                                <div className="input-group">
                                    <Flatpickr
                                        className="form-control border-0 dash-filter-picker shadow"
                                        value={selectedDate}
                                        options={{
                                            locale: Russian,
                                            dateFormat: "m Y",
                                            maxDate: new Date(),
                                            mode: 'single',
                                            disableMobile: true,
                                            plugins: [MonthSelectPlugin({
                                                shorthand: true,
                                                dateFormat: "M y",
                                                altFormat: "F Y",
                                            })]
                                        }}
                                        onClose={(date) => {
                                            dispatch(EducationSlice.actions.setDate(date[0]));
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col">
                                <Card>
                                    <CardHeader className="">
                                        <h4 className="card-title mb-2 flex-grow-1">{user?.middle_name + ' ' + user?.name || 'Не указано'}</h4>
                                        <p className="mb-1"><strong>Должность:</strong> {user?.position.name || 'Не указано'}</p>
                                        <p className="mb-1"><strong>Организация:</strong> {user?.organization.name || 'Не указано'}</p>
                                    </CardHeader>
                                    <CardBody>
                                        <Flatpickr
                                            className="form-control border-0 dash-filter-picker d-none"
                                            value={[moment(selectedDate).startOf("month").toDate(), moment(selectedDate).endOf("month").toDate()]}
                                            options={{
                                                locale: Russian,
                                                disableMobile: true,
                                                inline: true,
                                                maxDate: new Date(),
                                            }}
                                            onMonthChange={(dates, currentDateString, self) => {
                                                dispatch(EducationSlice.actions.setDate(
                                                    moment().month(self.currentMonth).year(self.currentYear).startOf('month').toDate()
                                                ));
                                            }}
                                            onDayCreate={(dates, currentDateString, self, data) => {
                                                const dayDate = moment(data.dateObj).format('DD.MM.YYYY');

                                                const isTrainingDay = trainingDates.includes(dayDate);
                                                const isCertificationDay = certificationDates.includes(dayDate);

                                                // Базовые стили для всех дней
                                                data.classList.add('border-2');
                                                data.classList.add('border-white');
                                                data.classList.add('rounded-1');
                                                data.classList.add('shadow-none');

                                                // Добавляем цвет для обучения (зеленый)
                                                if (isTrainingDay && !isCertificationDay) {
                                                    data.classList.add('bg-success'); // #34c38f
                                                    data.classList.add('text-white');
                                                    data.classList.add('opacity-75');
                                                }

                                                // Добавляем цвет для аттестации (синий)
                                                if (isCertificationDay && !isTrainingDay) {
                                                    data.classList.add('bg-primary'); // #556ee6
                                                    data.classList.add('opacity-75');
                                                    data.classList.add('text-white');
                                                }

                                                // Если день и обучение, и аттестация (зел в син рамке)
                                                if (isTrainingDay && isCertificationDay) {
                                                    data.classList.remove('border-white');
                                                    data.classList.add('border-primary');
                                                    data.classList.add('bg-success');
                                                    data.classList.add('text-white');
                                                    data.classList.add('opacity-75');
                                                }
                                            }}
                                        />
                                    </CardBody>
                                </Card>
                            </div>
                            <EducationDonutChart date={selectedDate}/>
                            <CertificationDonutChart date={selectedDate}/>
                        </Col>
                        <Col xxl={9}>
                            <TableEducation date={selectedDate} />
                            <TableCertification date={selectedDate} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default MainEducation;