import React, {FC, useEffect, useState} from 'react';
import {Form, Formik} from "formik";
import {Card, CardBody, Col, Row, Spinner} from "reactstrap";
import DataTable from "react-data-table-component";
import {useAppDispatch} from "../../../../hooks/redux";
import {setIsSend, setShowNotification} from "../../../../store/reducers/widgets/WidgetsSlice";
import AxiosClient from "../../../../api/AxiosClient";
import SubmitWithLoading from "../../../layouts/UI/SubmitWithLoading";
import TableListClass from "./TableListClass";

interface ITableConfirmationExportClassNutritionClildren {
    listImport: {
        listNutritionShift: any[],
        dateStart: string,
        dateEnd: string,
        organization_nutrition: string,
        school: string,
        build: string,
        class: string,
        shift: string,
        typePlanMenu: any[]
    }
}

const TableConfirmationExportClassNutritionClildren: FC<ITableConfirmationExportClassNutritionClildren> = ({listImport}) => {
    const dispatch = useAppDispatch();
    const [listTypePlanMenu, setListTypePlanMenu] = useState<any>(listImport.typePlanMenu);


    const columns =
        listTypePlanMenu.map((typePlanMenu: any) => ({
            name: <span className='font-weight-bold accessibility_size_table mx-2'>{typePlanMenu.name}</span>,
            selector: (row: any) => <span className="accessibility_size_table">{row.nutrition[typePlanMenu.name]}</span>,
            compact: true,
            width: '100px',
            center: true,
            wrap: false
        }));

    columns.unshift({
            name: <span className='font-weight-bold accessibility_size_table'>Смена</span>,
            selector: (row: any) => <span className="accessibility_size_table">{row.name}</span>,
            compact: true,
            wrap: true,
            center: false,
            width: '12rem'
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Всего</span>,
            selector: (row: any) => <span className="accessibility_size_table">{row.count_nutrition}</span>,
            compact: true,
            wrap: true,
            center: true,
            width: '70px'
        },
    );

    useEffect(() => {
        if (listImport) setListTypePlanMenu(listImport.typePlanMenu)
    }, [listImport])

    return (
        <Formik
            enableReinitialize
            initialValues={{}}
            onSubmit={() => {
                dispatch(setIsSend(true))
                const params = {
                    listNutritionShift: listImport.listNutritionShift,
                    dateStart: listImport.dateStart,
                    dateEnd: listImport.dateEnd,
                    organization_nutrition: listImport.organization_nutrition,
                    school: listImport.school,
                    build: listImport.build,
                    class: listImport.class,
                    shift: listImport.shift,
                    typePlanMenu: listImport.typePlanMenu,
                }
                AxiosClient.get('request_for_nutrition/export/file/report_nutrition_children_shift', {responseType: 'blob', params})
                    .then((r: any) => {
                        dispatch(setIsSend(false))
                        const url = window.URL.createObjectURL(new Blob([r.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Табель питания по сменам.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    })
                    .catch(() => {
                        console.log('error')
                        dispatch(setIsSend(false))
                        dispatch(setShowNotification({type: "error", message: 'Ошибка, не удалось экспортировать!', show: true}))
                    })
            }}>
            {
                () => (
                    <Form>
                        <Row>
                            <Col>
                                <Card className="mt-3">
                                    <CardBody>
                                        <DataTable
                                            title="Табель питания по сменам"
                                            columns={columns}
                                            data={Object.values(listImport.listNutritionShift)}
                                            noDataComponent={'Нет данных'}
                                            progressComponent={<Spinner></Spinner>}
                                            pagination
                                            paginationRowsPerPageOptions={[100, 500, 1000]}
                                            paginationPerPage={100}
                                            paginationComponentOptions={
                                                {
                                                    rowsPerPageText: 'Видно классов:',
                                                    rangeSeparatorText: 'из'
                                                }
                                            }
                                            highlightOnHover
                                            customStyles={{
                                                headCells: {
                                                    style: {
                                                        borderRight: '1px solid #ccc',
                                                    },
                                                },
                                                rows: {
                                                    highlightOnHoverStyle: {
                                                        backgroundColor: "#ffebb5"
                                                    },
                                                    style:{
                                                        backgroundColor: "#ebebfc",
                                                    }
                                                }
                                            }}
                                            fixedHeader={true}
                                            expandableRowDisabled={(row: any) => row.name === 'Итого'}
                                            expandableRows
                                            expandOnRowClicked
                                            expandableRowsComponent={(row: any) => {
                                                return <TableListClass
                                                    listClass={row.data.listClass}
                                                    listTypePlanMenu={listTypePlanMenu}
                                                />
                                            }}
                                        />
                                        <div className="hstack gap-2" >
                                            <SubmitWithLoading text={"Скачать отчет"}/>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                )
            }
        </Formik>
    );
};

export default TableConfirmationExportClassNutritionClildren;
