import React, {FC, useEffect, useState} from 'react';
import AxiosClient from "../../../api/AxiosClient";
import {Col, Input, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {Field, Form, Formik, useFormikContext} from "formik";
import * as Yup from "yup";
import ListUpdateBuilds from "./ListUpdateBuilds";
import classNames from "classnames";
import ModalAddBuilding from "./ModalAddBuilding";
import ModalAddClass from "./ModalAddClass";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../hooks/redux";
import {ISchool} from "../../../models/KSHP/ISchool";
import ModalConfirmExit from "./ModalConfirmExit";
import _ from 'lodash';
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface IModalUpdateSchool {
    updateSchool: boolean,
    setUpdateSchool: (value: boolean) => void,
    school: ISchool | null,
    setSchools: (value: any) => void
}

const ModalUpdateSchool: FC<IModalUpdateSchool> = ({updateSchool, setUpdateSchool, school, setSchools}) => {
    const idButtonSave: string = 'buttonSaveModalUpdateSchool';
    const [addClass, setAddClass] = useState(false);
    const [addBuild, setAddBuild] = useState(false);
    const [builds, setBuilds] = useState<any[]>([]);
    const [withoutChangesBuilds, setWithoutChangesBuilds] = useState<any[]>([]);
    const [newBuilds, setNewBuilds] = useState<any[]>([]);
    const [confirmExit, setConfirmExit] = useState(false);
    const [newObject, setNewObject] = useState<any>();
    const dispatch = useAppDispatch();

    const [activeTab, setActiveTab] = useState("1");
    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const confirmOrExit = () => {
        if( !_.isEqual(newObject, school) || !_.isEqual(builds, withoutChangesBuilds)){
            setConfirmExit(true);
        }else{
            setUpdateSchool(false);
        }
    }

    useEffect(() => {
        if (school){
            AxiosClient.get(`/schools/${school?.id}/buildings/classes`)
                .then((r: any) => {
                    setBuilds(r.data)
                    setWithoutChangesBuilds(r.data);
                })
        }
        setNewObject(school)
    }, [school]);
    useEscapeKey(() => dispatch(setModalAdd(false)), updateSchool, "updateSchool");

    return (
        <Modal id="updateSchool" isOpen={updateSchool}
               toggle={() => confirmOrExit()}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => confirmOrExit()}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Обновление школы
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={
                        {
                            name: school?.name,
                            address: school?.address,
                            max_credit_nutrition: school?.max_credit_nutrition,
                            max_deposit: school?.max_deposit,
                            isSaturday: !!school?.isSaturday,
                            builds: [{}]
                        }
                    }
                    validationSchema={
                        Yup.object({
                            name: Yup.string().required('Обязательное поле'),
                            address: Yup.string().required('Обязательное поле'),
                            max_credit_nutrition: Yup.number().required('Обязательное поле'),
                            max_deposit: Yup.number().required('Обязательное поле'),
                        })
                    }
                    onSubmit={(values) => {
                        dispatch(setIsSend(true))
                        AxiosClient.post(`/schools/update/${school?.id}`, {...values, builds})
                            .then(() => {
                                dispatch(setIsSend(false))
                                setUpdateSchool(false)
                                AxiosClient.get('/schools', {params: {page: 1, per_page: 10}})
                                    .then((r) => setSchools(r.data))
                                    .catch(() => console.log('error'))
                            })
                            .catch(() => {
                                dispatch(setIsSend(false))
                                console.log('error')
                            })

                    }}>
                    {
                        ({setFieldValue, errors, touched}) => (
                            <Form>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                     role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "1", "p-2": true})}
                                            onClick={() => {
                                                tabChange("1");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Общая информация
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "2", "p-2": true})}
                                            onClick={() => {
                                                tabChange("2");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Корпуса, классы
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="mt-2">
                                    <TabPane tabId="1" className="align-items-center">
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">Название школы:
                                                <Field name="name" className={`form-control ${errors.name && touched.name && "border border-2 border-danger"}`}
                                                       onChange={(e: any) => {
                                                           setNewObject({...newObject, 'name': e.target.value});
                                                           setFieldValue('name', e.target.value);
                                                       }}
                                                />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">Адрес:
                                                <Field name="address" className={`form-control ${errors.address && touched.address && "border border-2 border-danger"}`}
                                                       onChange={(e: any) => {
                                                           setNewObject({...newObject, 'address': e.target.value});
                                                           setFieldValue('address', e.target.value);
                                                       }}
                                                />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">Макс. кредит питания:
                                                <Field name="max_credit_nutrition" className={`form-control ${errors.max_credit_nutrition && touched.max_credit_nutrition && "border border-2 border-danger"}`}
                                                       onChange={(e: any) => {
                                                           setNewObject({...newObject, 'max_credit_nutrition': e.target.value});
                                                           setFieldValue('max_credit_nutrition', e.target.value);
                                                       }}
                                                />
                                                </label>
                                            </Col>
                                            <Col>
                                                <label className="w-100">Макс. сумма пополнения:
                                                <Field name="max_deposit" className={`form-control ${errors.max_deposit && touched.max_deposit && "border border-2 border-danger"}`}
                                                       onChange={(e: any) => {
                                                           setNewObject({...newObject, 'max_deposit': e.target.value});
                                                           setFieldValue('max_deposit', e.target.value);
                                                       }}
                                                />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col className="d-flex align-items-center mt-1">
                                                <label className="m-0 w-100">Учитывать субботу:
                                                <Field name="isSaturday" type="checkbox">
                                                    {({
                                                          field,
                                                      }: any) => (
                                                            <Input name="isSaturday" type="checkbox" {...field} className="mx-2 my-0" style={{fontSize: 18}}
                                                                   onChange={(e: any) => {
                                                                       setNewObject({...newObject, 'isSaturday': e.target.checked});
                                                                       setFieldValue('isSaturday', e.target.checked);
                                                                   }}
                                                            />
                                                    )}
                                                </Field>
                                                </label>
                                            </Col>
                                        </Row>
                                        <div className="hstack gap-2">
                                            <SubmitWithLoading id={idButtonSave} text={"Сохранить"}/>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="2" className="align-items-center">
                                        <Row>
                                            <Col>
                                                <ListUpdateBuilds setNewBuilds={value => setNewBuilds(value)} setBuilds={value => setBuilds(value)} newBuilds={newBuilds} builds={builds}/>
                                            </Col>
                                        </Row>
                                        <div className="hstack gap-2 mt-2">
                                            <button className="btn btn-primary" type="button" id="addNewProject"
                                                    onClick={() => setAddBuild(true)}>Добавить корпус
                                            </button>
                                            <button className="btn btn-primary" type="button" id="addNewProject"
                                                    onClick={() => setAddClass(true)}>Добавить класс
                                            </button>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
            <ModalConfirmExit idButtonSave={idButtonSave} setUpdate={value => setUpdateSchool(value)} confirmExit={confirmExit} setConfirmExit={value => setConfirmExit(value)} />
            <ModalAddBuilding setBuilds={(value) => {
                setBuilds([...builds, value])
                setNewBuilds([...newBuilds, value])
            }} addClass={addBuild} setAddClass={(value) => setAddBuild(value)}/>
            <ModalAddClass newBuilds={newBuilds} setNewBuilds={value => setNewBuilds(value)} builds={builds} setBuilds={(value) => setBuilds(value)} addClass={addClass} setAddClass={value => setAddClass(value)}/>
        </Modal>
    );
};

export default ModalUpdateSchool;
