import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import classNames from "classnames";
import ListBenefit from "./ListBenefit";
import ListViewMenu from "./ListViewMenu";
import {Russian} from "flatpickr/dist/l10n/ru";
import Flatpickr from "react-flatpickr";
import AxiosClient from "../../../api/AxiosClient";
import moment from "moment";
import ListHomeEducation from "./ListHomeEducation";
import ListStatuses from "./ListStatuses";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../hooks/redux";
import _ from "lodash";
import ModalConfirmExit from "../Schools/ModalConfirmExit";
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface IModalUpdatePersonalAccount {
    updatePersonalAccount: boolean,
    setUpdatePersonalAccount: (value: boolean) => void
    selectPersonalAccount: any
    setStudents: (value: any) => void
}

const ModalUpdatePersonalAccount: FC<IModalUpdatePersonalAccount> = ({
                                                                         selectPersonalAccount,
                                                                         setUpdatePersonalAccount,
                                                                         updatePersonalAccount,
                                                                         setStudents
                                                                     }) => {
    const idButtonSave: string = 'buttonSaveModalUpdatePersonalAccount';
    const [dataBegin, setDataBegin] = useState(new Date());
    const [dateEnd, setDateEnd] = useState<Date | string>('');
    const [activeTab, setActiveTab] = useState("1");
    const [selectCateringOrganizer, setSelectCateringOrganizer] = useState('');
    const [listCateringOrganizer, setListCateringOrganizer] = useState<any[]>([]);
    const [listBenefit, setListBenefit] = useState<any[]>([]);
    const [schools, setSchools] = useState<any[]>([]);
    const [typeMenu, setTypeMenu] = useState<any[]>([]);
    const [selectSchool, setSelectSchool] = useState<string>("0");
    const [selectBuild, setSelectBuild] = useState<string>("0");
    const [selectClass, setSelectClass] = useState<string>("0");
    const [builds, setBuilds] = useState<any[]>([]);
    const [classes, setClasses] = useState<any[]>([]);
    const [listStatuses, setListStatuses] = useState<any[]>([]);
    const [listViewMenu, setListViewMenu] = useState<any[]>([]);
    const [listMenu, setListMenu] = useState<any[]>([]);
    const [listHomeEducation, setListHomeEducation] = useState<any[]>([]);
    const [ageCategories, setAgeCategories] = useState<any[]>([]);
    const [withoutChanges, setWithoutChanges] = useState<any[]>([]);
    const [confirmExit, setConfirmExit] = useState(false);
    const [newObject, setNewObject] = useState<any>();
    const [isChanges, setIsChanges] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (updatePersonalAccount) {
            AxiosClient.get('/age_categories')
                .then((r) => setAgeCategories(r.data))
                .catch(() => console.log('error'))
            AxiosClient.get('/schools')
                .then((r) => {
                    setSchools(r.data.schools)
                })
                .catch(() => console.log('error'))
            AxiosClient.get('/type_menu')
                .then((r) => {
                    setTypeMenu(r.data)
                })
                .catch(() => console.log('error'))
        }
    }, [updatePersonalAccount]);

    useEffect(() => {
        setSelectSchool(selectPersonalAccount?.school_id)
        setSelectBuild(selectPersonalAccount?.building_id)
        setSelectClass(selectPersonalAccount?.class_id)
        if (selectPersonalAccount) {
            setSelectCateringOrganizer(selectPersonalAccount.catering_organizer?.id)
            AxiosClient.get(`/schools/${selectPersonalAccount?.school_id}/catering_organizers/type_planned_menu`)
                .then((r) => setListMenu(r.data.catering_organizer?.catering_organizer_type_planned_menu?.map((v: any) => ({
                    ...v.type_planned_menu,
                    idPlan: v.id,
                    pricePlan: v.price
                }))))
            AxiosClient.get(`/schools/${selectPersonalAccount?.school_id}/catering_organizers/all`)
                .then((r) => setListCateringOrganizer(r.data))
            fetchBuildings(selectSchool)
                .then(r => {
                    setBuilds(r)
                    setClasses(r.find((s: any) => s.id === selectPersonalAccount?.building_id)?.class)
                })
            setDataBegin(moment(selectPersonalAccount.date_begin).toDate())
            setDateEnd(selectPersonalAccount.date_end ? moment(selectPersonalAccount.date_end).toDate() : '')
            setListBenefit(selectPersonalAccount.benefits.map((b: any) => {
                return {
                    id: b.id,
                    name: b.name,
                    benefit_id: b.pivot.benefit_id,
                    date_begin: b.pivot.date_begin,
                    date_end: b.pivot.date_end,
                    sum: b.pivot.sum
                }
            }))
            setListStatuses(selectPersonalAccount.statuses.map((b: any) => {
                return {
                    id: b.id,
                    name: b.name,
                    status_id: b.pivot.status_id,
                    date_begin: b.pivot.date_begin,
                    date_end: b.pivot.date_end,
                }
            }))
            setListViewMenu(selectPersonalAccount.catering_organizer_type_planned_menu.map((b: any) => {
                return {
                    idPlan: b.id,
                    name: b.type_planned_menu.name,
                    date_begin: b.pivot.date_begin,
                    date_end: b.pivot.date_end,
                    pricePlan: b.price
                }
            }))
            setListHomeEducation(selectPersonalAccount.home_educations.map((he: any) => (
                {
                    id: he.id,
                    date_begin: new Date(he.date_begin),
                    date_end: he.date_end ? new Date(he.date_begin) : '',
                    date_receive: he.date_receive ? new Date(he.date_receive) : '',
                    count_day: he.count_day,
                    price_in_day: he.price_in_day,
                    result_price: he.result_price,
                    menu: {
                        idPlan: he.catering_organizer_type_planned_menu.id,
                        id: he.catering_organizer_type_planned_menu.type_planned_menu.id,
                        name: he.catering_organizer_type_planned_menu.type_planned_menu.name
                    },
                    type_menu: {
                        id: he.type_menu.id,
                        name: he.type_menu.name
                    }
                }
            )))
        }
        setNewObject(selectPersonalAccount);
    }, [selectPersonalAccount, schools]);

    const onChangeSchool = (selectSchool: string) => {
        setSelectSchool(selectSchool)
        fetchBuildings(selectSchool)
            .then(r => {
                setBuilds(r)
                setClasses(r[0].class)
                setSelectBuild(r[0] ? r[0].id : '')
                setSelectClass(r[0].class[0] ? r[0].class[0].id : '')
            })
        if (selectSchool) {
            AxiosClient.get(`/schools/${selectSchool}/catering_organizers/type_planned_menu`)
                .then((r) => setListMenu(r.data.catering_organizer?.catering_organizer_type_planned_menu?.map((v: any) => ({
                    ...v.type_planned_menu,
                    idPlan: v.id,
                    pricePlan: v.price
                }))))
            AxiosClient.get(`/schools/${selectSchool}/catering_organizers/all`)
                .then((r) => setListCateringOrganizer(r.data))
        }
    }
    const fetchBuildings = async (selectSchool: string) => {
        if (selectSchool) {
            const response = await AxiosClient.get(`/schools/${selectSchool}/buildings/classes`)
            return response?.data
        }
        return []
    };

    const onChangeBuild = (selectBuild: string) => {
        setSelectBuild(selectBuild)
        setClasses(builds.find(s => s.id.toString() === selectBuild)?.class)
    }


    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const confirmOrExit = () => {
        if (!_.isEqual(selectPersonalAccount, newObject) || !_.isEqual(listBenefit, withoutChanges) || !_.isEqual(listStatuses, withoutChanges) || !_.isEqual(listMenu, withoutChanges)) {
            setConfirmExit(true);
        } else {
            setUpdatePersonalAccount(false);
        }
    }
    useEscapeKey(() => dispatch(setModalAdd(false)), updatePersonalAccount, "updatePersonalAccount");

    return (
        <Modal id="updatePersonalAccount" isOpen={updatePersonalAccount}
               toggle={() => confirmOrExit()}
               modalClassName="zoomIn" tabIndex={-1} centered size={'lg'}>
            <ModalHeader toggle={() => confirmOrExit()}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Лицевой счет
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            personalAccount: '',
                            magnetic_card: selectPersonalAccount?.magnetic_card ?? '',
                            name: selectPersonalAccount?.name,
                            last_name: selectPersonalAccount?.last_name,
                            middle_name: selectPersonalAccount?.middle_name,
                            type_menu_id: selectPersonalAccount?.type_menu_id,
                            age_category_id: selectPersonalAccount?.age_category_id,
                            catering_organizer_id: selectCateringOrganizer,
                            school_id: '',
                            building_id: '',
                            class_id: '',
                            date_begin: '',
                            date_end: '',
                            listBenefit: [{}],
                            listMenu: [{}],
                            listHomeEducation: [{}],
                            listStatuses: [{}],
                        }
                    }
                    validationSchema={
                        Yup.object({
                            name: Yup.string().required('Обязательное поле'),
                            last_name: Yup.string().required('Обязательное поле'),
                            middle_name: Yup.string().required('Обязательное поле'),
                            catering_organizer_id: Yup.string().required('Обязательное поле')
                        })
                    }
                    onSubmit={(values) => {
                        dispatch(setIsSend(true))
                        values.date_begin = moment(dataBegin).format("YYYY-MM-DD").toString()
                        values.date_end = dateEnd ? moment(dateEnd).format("YYYY-MM-DD").toString() : ''
                        values.school_id = selectSchool
                        values.building_id = selectBuild
                        values.class_id = selectClass
                        values.listBenefit = listBenefit
                        values.listMenu = listViewMenu
                        values.listHomeEducation = listHomeEducation
                        values.listStatuses = listStatuses
                        AxiosClient.post(`/students/update/${selectPersonalAccount.id.toString()}/${selectPersonalAccount.student_id.toString()}`, values)
                            .then(() => {
                                dispatch(setIsSend(false))
                                setUpdatePersonalAccount(false)
                                AxiosClient.get('/students', {params: {page: 1, per_page: 10}})
                                    .then(r => setStudents(r.data))
                                    .catch(() => console.log('error'))
                            })
                            .catch(() => {
                                dispatch(setIsSend(false))
                                console.log('error')
                            })
                    }}>
                    {
                        ({setFieldValue, errors, touched}) => (
                            <Form>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                     role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "1", "p-2": true})}
                                            onClick={() => {
                                                tabChange("1");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Основное
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "2", "p-2": true})}
                                            onClick={() => {
                                                tabChange("2");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Льгота
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "3", "p-2": true})}
                                            onClick={() => {
                                                tabChange("3");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Плановое меню
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "4", "p-2": true})}
                                            onClick={() => {
                                                tabChange("4");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Домашнее обучение
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "5", "p-2": true})}
                                            onClick={() => {
                                                tabChange("5");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Категория льгот
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="mt-2">
                                    <TabPane tabId="1" className="align-items-center">
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label  className="w-100">Фамилия:
                                                    <Field name="last_name"
                                                           className={`form-control ${errors.last_name && touched.last_name && "border border-2 border-danger"}`}
                                                           onChange={(e: any) => {
                                                               setNewObject({...newObject, 'last_name': e.target.value});
                                                               setFieldValue('last_name', e.target.value);
                                                           }}
                                                    />
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Имя:
                                                    <Field name="name"
                                                           className={`form-control ${errors.name && touched.name && "border border-2 border-danger"}`}
                                                           onChange={(e: any) => {
                                                               setNewObject({...newObject, 'name': e.target.value});
                                                               setFieldValue('name', e.target.value);
                                                           }}
                                                    />
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Отчество:
                                                    <Field name="middle_name"
                                                           className={`form-control ${errors.middle_name && touched.middle_name && "border border-2 border-danger"}`}
                                                           onChange={(e: any) => {
                                                               setNewObject({...newObject, 'middle_name': e.target.value});
                                                               setFieldValue('middle_name', e.target.value);
                                                           }}
                                                    />
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Магнитная карта:
                                                    <Field name="magnetic_card"
                                                           className={`form-control ${errors.magnetic_card && touched.magnetic_card && "border border-2 border-danger"}`}
                                                           onChange={(e: any) => {
                                                               setNewObject({...newObject, 'magnetic_card': e.target.value});
                                                               setFieldValue('magnetic_card', e.target.value);
                                                           }}
                                                    />
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Школа:
                                                    <select name="school_id" className="form-select"
                                                            value={selectSchool}
                                                            onChange={(e) => {
                                                                onChangeSchool(e.target.value)
                                                                setNewObject({...newObject, 'school_id': e.target.value});
                                                            }}>
                                                        {
                                                            schools.map(school => (<option value={school.id}
                                                                                           key={school.id}>{school.name}</option>))
                                                        }
                                                    </select>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Организатор питания:
                                                    <select name="catering_organizer_id" className={`form-select ${errors.catering_organizer_id && 'border border-danger'}`}
                                                            value={selectCateringOrganizer}
                                                            onChange={(e) => {
                                                                setSelectCateringOrganizer(e.target.value)
                                                                setFieldValue('catering_organizer_id', e.target.value)
                                                                setNewObject({...newObject, 'catering_organizer_id': e.target.value});
                                                            }}>
                                                        <option value={""}>Не выбрано</option>
                                                        {
                                                            listCateringOrganizer.map((school: any, index) => (
                                                                <option value={school.id}
                                                                        key={index}>{school.name}</option>))
                                                        }
                                                    </select>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Корпус:
                                                    <select name="building_id" className="form-select"
                                                            value={selectBuild}
                                                            onChange={(e) => {
                                                                onChangeBuild(e.target.value)
                                                                setNewObject({...newObject, 'building_id': e.target.value});
                                                            }}>
                                                        {
                                                            builds?.map(build => (
                                                                <option value={build.id}
                                                                        key={build.id}>{build.name}</option>))
                                                        }
                                                    </select>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Класс:
                                                    <select name="class_id" className="form-select" value={selectClass}
                                                            onChange={(e) => {
                                                                setSelectClass(e.target.value)
                                                                setNewObject({...newObject, 'class_id': e.target.value});
                                                            }}>
                                                        {
                                                            classes?.map(c => (
                                                                <option value={c.id} key={c.id}>{c.name}</option>))
                                                        }
                                                    </select>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Комплекс:
                                                    <Field name="type_menu_id" as="select" className="form-select"
                                                           onChange={(e: any) => {
                                                               setNewObject({...newObject, 'type_menu_id': e.target.value});
                                                               setFieldValue('type_menu_id', e.target.value);
                                                           }}>
                                                        {
                                                            typeMenu.map(tm => <option value={tm.id}
                                                                                       key={tm.id}>{tm.name}</option>)
                                                        }
                                                    </Field>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Возрастная категория:
                                                    <Field name="age_category_id" as="select" className="form-select"
                                                           onChange={(e: any) => {
                                                               setNewObject({...newObject, 'age_category_id': e.target.value});
                                                               setFieldValue('age_category_id', e.target.value);
                                                           }}>
                                                        {
                                                            ageCategories.map(ageCategory => (
                                                                <option key={ageCategory.id}
                                                                        value={ageCategory.id}>{ageCategory.name}</option>))
                                                        }
                                                    </Field>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Дата с:
                                                    <Flatpickr
                                                        className="form-control border-0 dash-filter-picker shadow"
                                                        value={dataBegin}
                                                        options={{
                                                            locale: Russian, dateFormat: "d M Y"
                                                        }}
                                                        onClose={(date) => {
                                                            setNewObject({...newObject, 'date_begin': date[0]});
                                                            setDataBegin(date[0])
                                                        }}
                                                    />
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Дата по:
                                                    <Flatpickr
                                                        className="form-control border-0 dash-filter-picker shadow"
                                                        value={dateEnd}
                                                        options={{
                                                            locale: Russian, dateFormat: "d M Y"
                                                        }}
                                                        onClose={(date) => {
                                                            setDateEnd(date[0])
                                                            setNewObject({...newObject, 'date_end': date[0]});
                                                        }}
                                                    />
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="hstack gap-2 mt-3">
                                            <SubmitWithLoading id={idButtonSave} text={"Сохранить"}/>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="2" className="align-items-center">
                                        <ListBenefit listBenefit={listBenefit}
                                                     setListBenefit={value => setListBenefit(value)}/>
                                    </TabPane>
                                    <TabPane tabId="3" className="align-items-center">
                                        <ListViewMenu listViewMenu={listViewMenu} listMenu={listMenu}
                                                      setListViewMenu={value => setListViewMenu(value)}/>
                                    </TabPane>
                                    <TabPane tabId="4" className="align-items-center">
                                        <ListHomeEducation typeMenu={typeMenu} listMenu={listMenu}
                                                           listHomeEducation={listHomeEducation}
                                                           setListHomeEducation={value => setListHomeEducation(value)}/>
                                    </TabPane>
                                    <TabPane tabId="5" className="align-items-center">
                                        <ListStatuses listStatuses={listStatuses}
                                                      setListStatuses={value => setListStatuses(value)}/>
                                    </TabPane>
                                </TabContent>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
            <ModalConfirmExit idButtonSave={idButtonSave} setUpdate={value => setUpdatePersonalAccount(value)} confirmExit={confirmExit}
                              setConfirmExit={value => setConfirmExit(value)}/>
        </Modal>
    );
};

export default ModalUpdatePersonalAccount;
