import React, {FC, useState} from 'react';
import DataTable from "react-data-table-component";
import ModalUpdateContactWithBank from "./ModalUpdateContactWithBank";

interface ITableContactWithBank {
    banksNew: any[]
    setBanksNew: (value: any[]) => void
}
const TableContactWithBank: FC<ITableContactWithBank> = ({banksNew, setBanksNew}) => {
    const [selectBank, setSelectBank] = useState(null);
    const [updateContactWithBank, setUpdateContactWithBank] = useState(false);
    const columns = [
        {
            id: "date",
            name: <span className='font-weight-bold accessibility_size_table'>Наименование</span>,
            selector: (row: any) => row.bank.name,
            cell: (row: any) => <span className="accessibility_size_table">{row.bank.name}</span>,
            sortable: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Расчетный счет</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.paymentAccount}</span>,
            wrap: true,
            hide: 959
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Авто - квитовка</span>,
            cell: (row: any) => row.autoCvit ? <i className="bx bx-check fs-3"></i> : <i className="bx bx-x fs-3"></i>,
            hide: 1280
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Онлайн - платежи</span>,
            cell: (row: any) => row.onlinePay ? <i className="bx bx-check fs-3"></i> : <i className="bx bx-x fs-3"></i>,
            hide: 1280
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Авто - платежи</span>,
            cell: (row: any) => row.autoPay ? <i className="bx bx-check fs-3"></i> : <i className="bx bx-x fs-3"></i>,
            hide: 1280
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Активен</span>,
            cell: (row: any) => row.active ? <i className="bx bx-check fs-3"></i> : <i className="bx bx-x fs-3"></i>,
            hide: 1280
        }
    ]

    return (
        <>
            <DataTable
                columns={columns}
                data={banksNew}
                noDataComponent={'Нет банков'}
                defaultSortFieldId={"date"}
                defaultSortAsc={true}
                pagination
                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                paginationComponentOptions={
                    {
                        rowsPerPageText: 'Видно банков:',
                        rangeSeparatorText: 'из'
                    }
                }
                highlightOnHover
                customStyles={{
                    rows: {
                        highlightOnHoverStyle: {
                            backgroundColor: "#ffebb5"
                        }
                    }
                }}
                onRowDoubleClicked={(row) => {
                    setSelectBank(row)
                    setUpdateContactWithBank(true)
                }}
                onRowClicked={() => {}}
            />
            {
                updateContactWithBank &&
                <ModalUpdateContactWithBank banksNew={banksNew} setBanksNew={value => setBanksNew(value)} updateContactWithBank={updateContactWithBank} setUpdateContactWithBank={value => setUpdateContactWithBank(value)} selectBank={selectBank}/>
            }
        </>
    );
};

export default TableContactWithBank;
