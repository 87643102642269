import React, {useState} from 'react';
import {Col, Row} from "reactstrap";
import ModalImportFileTeacherClass from "./ModalImportFileTeacherClass";
import TableConfirmationImportTeacher from "./TableConfirmationImportTeacher";
import ModalImportFileSchool from "./ImportSchools/ModalImportFileSchool";
import ModalImportFileParentsStudents from "./ModalImportFileParentsStudents";
import ModalImportFileStudentsCashFlow from "./ModalImportFileStudentsCashFlow";
import TableConfirmationImportParentsStudents from "./TableConfirmationImportParentsStudents";
import TableConfirmationImportStudentsCashFlow from "./TableConfirmationImportStudentsCashFlow";
import ModalImportFileStudents from "./ModalImportFileStudents";
import TableConfirmationImportStudents from "./TableConfirmationImportStudents";
import TableConfirmationImportSchool from './ImportSchools/TableConfirmationImportSchool';

const Imports = () => {

    const [importFileTeacher, setImportFileTeacher] = useState(false);
    const [importFileSchool, setImportFileSchool] = useState(false);
    const [importFileStudents, setImportFileStudents] = useState(false);
    const [importFileStudentsCashFlow, setImportFileStudentsCashFlow] = useState(false);
    const [importFileParentsStudents, setImportFileParentsStudents] = useState(false);
    const [tableConfirmationImport, setTableConfirmationImport] = useState<any>();
    const [listImport, setListImport] = useState<any[]>([]);
    const [modalData, setModalData]=useState<any[]>([]);


    function selectTable(name :string) {
        const tables = {
            "null": <></>,
            "student": <TableConfirmationImportStudents listImport={listImport} setListImport={value => setListImport(value)}/>,
            "studentCashFlow": <TableConfirmationImportStudentsCashFlow listImport={listImport} setListImport={value => setListImport(value)}/>,
            "school": <TableConfirmationImportSchool listImport={listImport} setListImport={value => setListImport(value)} modalData={modalData}/>,
            "teacherClass": <TableConfirmationImportTeacher listTeacherImport={listImport} setListTeacherImport={value => setListImport(value)}/>,
            "parentStudents": <TableConfirmationImportParentsStudents listImport={listImport} setListImport={value => setListImport(value)}/>
        }
        return tables[name as keyof typeof tables];
    }

    return (
        <>
            <Row>
                <Col xxl={3} xl={3} lg={3} sm={6}>
                    <button className="btn btn-primary w-100 mt-1"
                            onClick={() => {
                                setTableConfirmationImport("null");
                                setImportFileStudentsCashFlow(true);
                            }}
                    >Импорт ДС</button>
                </Col>
                <Col xxl={3} xl={3} lg={3} sm={6}>
                    <button className="btn btn-primary w-100 mt-1"
                            onClick={() => {
                                setTableConfirmationImport("null");
                                setImportFileSchool(true);
                            }}
                    >Импорт школ</button>
                </Col>
                <Col xxl={3} xl={3} lg={3} sm={6}>
                    <button className="btn btn-primary w-100 mt-1"
                            onClick={() => {
                                setTableConfirmationImport("null");
                                setImportFileStudents(true);
                            }}
                    >Импорт учеников</button>
                </Col>
                <Col xxl={3} xl={3} lg={3} sm={6}>
                    <button className="btn btn-primary w-100 mt-1"
                            onClick={() => {
                                setTableConfirmationImport("null");
                                setImportFileTeacher(true);
                            }}
                    >Импорт учителей</button>
                </Col>
                <Col xxl={3} xl={3} lg={3} sm={6}>
                    <button className="btn btn-primary w-100 mt-1"
                            onClick={() => {
                                setTableConfirmationImport("null");
                                setImportFileParentsStudents(true);
                            }}
                    >Импорт родителей</button>
                </Col>
            </Row>
            {selectTable(tableConfirmationImport)}

            <ModalImportFileStudentsCashFlow importFileStudents={importFileStudentsCashFlow} setImportFileStudents={(value: any) => setImportFileStudentsCashFlow(value)}
                                             setTableConfirmationImport={(value: any) => setTableConfirmationImport(value)}
                                             setListImport={(value: any) => setListImport(value)}
            />
            <ModalImportFileSchool importFileSchool={importFileSchool} setImportFileSchool={(value: any) => setImportFileSchool(value)}
                                        setTableConfirmationImport={(value: any) => setTableConfirmationImport(value)}
                                        setListImport={(value: any) => setListImport(value)}
                                    setModalData={(value: any) => setModalData(value)}
            />
            <ModalImportFileStudents importFile={importFileStudents} setImportFile={(value: any) => setImportFileStudents(value)}
                                     setTableConfirmationImport={(value: any) => setTableConfirmationImport(value)}
                                     setListImport={(value: any) => setListImport(value)} />

            <ModalImportFileTeacherClass importFileTeacher={importFileTeacher} setImportFileTeacher={(value: any) => setImportFileTeacher(value)}
                                         setTableConfirmationImport={(value: any) => setTableConfirmationImport(value)}
                                         setListTeacherImport={(value: any) => setListImport(value)}
            />
            <ModalImportFileParentsStudents importFileParentsStudents={importFileParentsStudents} setImportFileParentsStudents={(value: any) => setImportFileParentsStudents(value)}
                                            setTableConfirmationImport={(value: any) => setTableConfirmationImport(value)}
                                            setListImport={(value: any) => setListImport(value)}
            />
        </>
    );
};

export default Imports;
