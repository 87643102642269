import React, {useEffect, useState} from 'react';
import { Formik, Field, Form } from 'formik';
import {useParams} from "react-router-dom";
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';
import {Container, Button, Card, CardBody, Col, Row, Spinner} from "reactstrap";
import {Helmet} from "react-helmet";
import DateTimeSubmissionDocuments from "../components/submissionDocuments/DateTimeSubmissionDocuments";
import AxiosClient from "../api/AxiosClient";
import SubmissionDocumentsSuccess from "../components/submissionDocuments/SubmissionDocumentsSuccess";
import 'react-dadata/dist/react-dadata.css';
import SubmitWithLoading from "../components/layouts/UI/SubmitWithLoading";
import {useAppDispatch} from "../hooks/redux";
import {setIsSend, setShowNotification} from "../store/reducers/widgets/WidgetsSlice";
import HelpWindows from "../components/submissionDocuments/HelpWindows";
import OtherChildrenForm from "../components/submissionDocuments/OtherChildrenForm";
import UserAgreement from "../components/submissionDocuments/UserAgreement";
import FatherField from "../components/submissionDocuments/FatherField";
import MatherField from "../components/submissionDocuments/MatherField";
import StringAsDataProtection from "../components/submissionDocuments/StringAsDataProtection";
import NotificationShow from "../components/widgets/NotificationShow";

const SubmissionDocuments = () => {

    const {id, number} = useParams();
    const dispatch = useAppDispatch()
    const [toggle, setToggle] = useState(false);
    const [date, setDate] = useState({dateRecord: '', reservationId: '',  time: {id: '', time: '', human_time: '', title:'', open_place:''}});
    const [modalActive, setModalActive] = useState(false);
    const [period, setPeriod] = useState<any[]>([]);
    const [success, setSuccess] = useState(false);
    const [time, setTime] = useState<any[]>([]);
    const [nameOrg, setNameOrg] = useState('');
    const [address, setAddress] = useState<any>('');
    const [addressFull, setAddressFull] = useState<any>({});
    const [addressFather, setAddressFather] = useState<any>('');
    const [addressFullFather, setAddressFullFather] = useState<any>({});
    const [send, setSend] = useState(false);
    const [helpWindow, setHelpWindows] = useState(false);
    const [citizenships, setСitizenships] = useState<any[]>([]);

    const [listChildrenInfo, setListChildrenInfo] = useState<any[]>([]);
    const [fieldErrors, setFieldErrors] = React.useState<{ [index: number]: { [field: string]: boolean } }>({});



    useEffect(() => {
        AxiosClient.get('/getPeriodsSubmissionDocuments', {params: {idOrg: id}})
            .then((r) => {
                setPeriod(r.data['periods']);
            })
            .catch((e) => {
                dispatch(setShowNotification({type: "error", message: "Ошибка при загрузке периода!", show: true}))
            })
        AxiosClient.get('/getOrgName', {params: {idOrg: id, number: number}})
            .then(r => setNameOrg(r.data))
            .catch(() => {
                dispatch(setShowNotification({type: "error", message: "Ошибка при загрузке организации!", show: true}))
            })
        AxiosClient.get('/citizenship', {params: {idOrg: id}})
            .then((r) => {
                setСitizenships(r.data)
                }
            )
            .catch(() => {
                dispatch(setShowNotification({type: "error", message: "Ошибка при загрузке гражданств!", show: true}))
            })
    }, [id, number]);

    const validateFields = () => {
        const newErrors: { [index: number]: { [field: string]: boolean } } = {};

        listChildrenInfo.forEach((child, index) => {
            const fieldErrors: { [field: string]: boolean } = {};

            Object.entries(child).forEach(([key, value]) => {
                if (!value && key !== 'typeListChildrenInfo') {
                    fieldErrors[key] = true;
                }
            });

            if (Object.keys(fieldErrors).length > 0) {
                newErrors[index] = fieldErrors;
            }
        });

        setFieldErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const checkFieldsFilled = (values: any, errors: any) => {
        const requiredChildFields = [
            'fullName',
            'birthdate',
            'certificateBirth',
            'dateCertificate',
            'whoCertificate',
            'snils',
            'citizenship'
        ];
        const areChildFieldsValid = requiredChildFields.every((field) => {
            return values[field] && !errors[field];
        });

        const requiredMotherFields = [
            'matherFullName',
            'matherBirthdate',
            'matherCitizenship',
            'matherSeriesPassport',
            'matherNumberPassport',
            'matherDateIssuePass',
            'matherWhoIssuePass',
            'matherPassportCodePodr',
            'matherSnils',
            'matherPhone',
            'matherPlaceWork',
            'matherPosition',
            'email'
        ];
        const isMotherFilled = values['matherFullName'] && values['matherFullName'].length > 0;
        const areMotherFieldsValid = isMotherFilled && requiredMotherFields.every((field) => {
            return values[field] && !errors[field];
        });
        const isMotherPartiallyFilled = isMotherFilled && !areMotherFieldsValid;

        const requiredFatherFields = [
            'fatherFullName',
            'fatherBirthdate',
            'fatherCitizenship',
            'fatherSeriesPassport',
            'fatherNumberPassport',
            'fatherDateIssuePass',
            'fatherWhoIssuePass',
            'fatherPassportCodePodr',
            'fatherSnils',
            'fatherPhone',
            'fatherPlaceWork',
            'fatherPosition',
            'fatherEmail'
        ];
        const isFatherFilled = values['fatherFullName'] && values['fatherFullName'].length > 0;
        const areFatherFieldsValid = isFatherFilled && requiredFatherFields.every((field) => {
            return values[field] && !errors[field];
        });
        const isFatherPartiallyFilled = isFatherFilled && !areFatherFieldsValid;

        // Условие валидности
        const atLeastOneParentValid = areMotherFieldsValid || areFatherFieldsValid;
        const noPartialFilling = !(isMotherPartiallyFilled || isFatherPartiallyFilled);
        const isValid = areChildFieldsValid && atLeastOneParentValid && noPartialFilling;

        let errorMessage = '';
        if (!areChildFieldsValid) {
            errorMessage = '*Заполните все обязательные поля ребенка';
        } else if (!atLeastOneParentValid) {
            errorMessage = '*Заполните данные хотя бы одного родителя полностью';
        } else if (!noPartialFilling) {
            if (isMotherPartiallyFilled) {
                errorMessage = '*Данные матери заполнены частично. Заполните все поля или оставьте их пустыми';
            } else if (isFatherPartiallyFilled) {
                errorMessage = '*Данные отца заполнены частично. Заполните все поля или оставьте их пустыми';
            }
        }

        return { isValid, errorMessage };
    };

    const DisplayingErrorMessagesSchema = Yup.object({
        'fullName': Yup.string().required(),
        'birthdate': Yup.string().required(),
        'certificateBirth': Yup.string().required(),
        'dateCertificate': Yup.string().required(),
        'whoCertificate': Yup.string().required(),
        'snils': Yup.string().required(),
        'citizenship': Yup.string().required(),

        'matherFullName': Yup.string(),
        'matherBirthdate': Yup.string()
            .when('matherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'matherCitizenship': Yup.string().when('matherFullName', {
            is: (value: any) => value && value.length > 0,
            then: (schema: any) => schema.required() }),
        'matherSeriesPassport': Yup.string()
            .when('matherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'matherNumberPassport': Yup.string()
            .when('matherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'matherDateIssuePass': Yup.string()
            .when('matherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'matherWhoIssuePass': Yup.string()
            .when('matherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'matherPassportCodePodr': Yup.string()
            .when('matherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'matherSnils': Yup.string().when('matherFullName', {
            is: (value:any) => value && value.length > 0,
            then: (schema:any) => schema.required()
        }),
        'matherPhone': Yup.string()
            .when('matherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'matherPlaceWork': Yup.string()
            .when('matherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'matherPosition': Yup.string()
            .when('matherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'email': Yup.string()
            .when('matherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),

        'fatherFullName': Yup.string(),
        'fatherBirthdate': Yup.string()
            .when('fatherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'fatherCitizenship': Yup.string().when('fatherFullName', {
            is: (value: any) => value && value.length > 0,
            then: (schema: any) => schema.required() }),
        'fatherSeriesPassport': Yup.string()
            .when('fatherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'fatherNumberPassport': Yup.string()
            .when('fatherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'fatherDateIssuePass': Yup.string()
            .when('fatherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'fatherWhoIssuePass': Yup.string()
            .when('fatherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'fatherPassportCodePodr': Yup.string()
            .when('fatherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'fatherSnils': Yup.string()
            .when('fatherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'fatherPhone': Yup.string()
            .when('fatherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'fatherPlaceWork': Yup.string()
            .when('fatherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'fatherPosition': Yup.string()
            .when('fatherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'fatherEmail': Yup.string()
            .when('fatherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'code': Yup.string().required()
    });

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Форма подачи документов</title>
            </Helmet>
            <Container fluid>
                <Formik
                    enableReinitialize
                    initialValues={{
                        fullName: '',
                        birthdate: '',
                        gender: 'male',
                        certificateBirth: '',
                        dateCertificate: '',
                        whoCertificate: '',
                        snils: '',
                        citizenship: '',
                        address: '',
                        email: '',
                        matherFullName: '',
                        matherBirthdate: '',
                        matherCitizenship: '',
                        matherSeriesPassport: '',
                        matherNumberPassport: '',
                        matherDateIssuePass: '',
                        matherWhoIssuePass: '',
                        matherPassportCodePodr: '',
                        matherSnils:'',
                        matherPhone: '',
                        matherPlaceWork: '',
                        matherPosition: '',
                        fatherFullName: '',
                        fatherBirthdate: '',
                        fatherCitizenship: '',
                        fatherSeriesPassport: '',
                        fatherNumberPassport: '',
                        fatherDateIssuePass: '',
                        fatherWhoIssuePass: '',
                        fatherPassportCodePodr: '',
                        fatherSnils:'',
                        fatherPhone: '',
                        fatherPlaceWork: '',
                        fatherPosition: '',
                        fatherAddress: '',
                        fatherEmail: '',
                        institution: '',
                        reservationId: '',
                        dateTimeRecord: '',
                        toggle: false,
                        code: ''
                    }}
                    validationSchema={DisplayingErrorMessagesSchema}
                    onSubmit={(values, {resetForm}) => {
                        const isValid = validateFields();

                        if (isValid) {
                            dispatch(setIsSend(true))
                            values.reservationId = date.reservationId;
                            values.dateTimeRecord = date.time['time'] ? new Intl.DateTimeFormat('ru', {day: '2-digit',  month: '2-digit', year: 'numeric'}).format(new Date(date.dateRecord)) + " "+ date.time['human_time'][0].split(' ')[1].slice(0, -3) : ''

                            AxiosClient.post('/addRegistrationAtInstitution', {...values, address, idOrg: id, addressFull, addressFather, addressFullFather, listChildrenInfo})
                                .then((r) => {
                                    setTime(r.data)
                                    dispatch(setIsSend(false))
                                    //resetForm()
                                    setDate({dateRecord: '', reservationId: '',  time: {id: '', time: '', human_time: '', title:'', open_place:''}})
                                    setSuccess(true)
                                })
                                .catch((e) => {
                                    dispatch(setIsSend(false))
                                    dispatch(setShowNotification({type: "error", message: 'Ошибка! ' + e.response.data, show: true}))
                                    setHelpWindows(true);
                                })
                        } else {
                            dispatch(setShowNotification({type: "error", message: 'Ошибка! Не все поля заполнены.' , show: true}))
                        }
                    }}
                >
                    {({errors, touched, values}) => {
                        const { isValid, errorMessage } = checkFieldsFilled(values, errors);

                        return(
                        <Form>
                            <Row>
                                <Col xxl={12}>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <h4 className="text-center mt-sm-3 mt-lg-3 mt-xl-3 mt-xll-3">Данные по ребенку для зачисления</h4>
                                                <Row>
                                                    <Col xxl={6}>
                                                        <Field
                                                            name='fullName'
                                                            placeholder="ФИО ребенка"
                                                            className={touched.fullName && errors.fullName ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Field name="birthdate">
                                                            {({
                                                                  field,
                                                                  form: {touched, errors},
                                                              }:any) => (
                                                                <MaskedInput
                                                                    name="birthdate"
                                                                    mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                                    placeholder="Дата рождения"
                                                                    {...field}
                                                                    className={touched.birthdate && errors.birthdate ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                                />
                                                            )}
                                                        </Field>
                                                    </Col>
                                                    <Col>
                                                        <Field
                                                            as="select"
                                                            name="gender"
                                                            className="mt-2 form-select mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0"
                                                        >
                                                            <option value="male">Мужской</option>
                                                            <option value="female">Женский</option>
                                                        </Field>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Field
                                                            as="select"
                                                            name="citizenship"
                                                            className={touched.citizenship && errors.citizenship ? "mt-2 form-select mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-select mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        >
                                                            <option value="" disabled hidden>Выберите гражданство</option>
                                                            {citizenships.map((citizenship) => (
                                                                <option key={citizenship.id} value={citizenship.id}>
                                                                    {citizenship.name}
                                                                </option>
                                                            ))}
                                                        </Field>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Field
                                                            name="certificateBirth"
                                                            placeholder="Св-во о рождении"
                                                            className={touched.certificateBirth && errors.certificateBirth ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Field name="dateCertificate">
                                                            {({
                                                                  field,
                                                                  form: {touched, errors},
                                                              }:any) => (
                                                                <MaskedInput
                                                                    name="dateCertificate"
                                                                    mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                                    placeholder="Дата выдачи"
                                                                    {...field}
                                                                    className={touched.dateCertificate && errors.dateCertificate ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                                />
                                                            )}
                                                        </Field>
                                                    </Col>
                                                    <Col xxl={6}>
                                                        <Field
                                                            name="whoCertificate"
                                                            placeholder="Кем выдано"
                                                            className={touched.whoCertificate && errors.whoCertificate ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Field
                                                            name="snils"
                                                            placeholder="СНИЛС"
                                                            className={touched.snils && errors.snils ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                </Row>
                                                <OtherChildrenForm
                                                    listChildrenInfo={listChildrenInfo}
                                                    setListChildrenInfo={(value: any) => setListChildrenInfo(value)}
                                                    fieldErrors={fieldErrors}
                                                    citizenships={citizenships}
                                                />
                                                <MatherField touched={touched} errors={errors} address={address} setAddress={(value) => setAddress(value)} setAddressFull={(value) => setAddressFull(value)} citizenships={citizenships}/>
                                                <FatherField touched={touched} errors={errors} addressFather={addressFather} setAddressFather={(value) => setAddressFather(value)} setAddressFullFather={(value) => setAddressFullFather(value)} citizenships={citizenships}/>
                                                <h4 className="text-center mt-sm-3 mt-lg-3 mt-xl-3 mt-xll-3">Контрольные данные</h4>
                                                <Row>
                                                    <Col>
                                                        <Field
                                                            name='code'
                                                            placeholder="PIN код"
                                                            type="password"
                                                            className={touched.code && errors.code ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Field
                                                            name="institution"
                                                            placeholder={nameOrg}
                                                            className='mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2'
                                                            disabled
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <input
                                                            name="dateTimeRecord"
                                                            defaultValue={date.time['time'] && date.time['human_time'].length > 0 ? new Intl.DateTimeFormat('ru', {day: '2-digit',  month: '2-digit', year: 'numeric'}).format(new Date(date.dateRecord)) + " "+ date.time['human_time'][0].split(' ')[1].slice(0, -3) : ''}
                                                            placeholder="Время и дата приема"
                                                            className='mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2'
                                                            disabled
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <UserAgreement toggle={toggle} setToggle={(value) => setToggle(value)} nameMather={values.matherFullName} nameFather={values.fatherFullName} nameOrganization={nameOrg}/>
                                                </Row>
                                                <Row>
                                                    <StringAsDataProtection/>
                                                </Row>
                                                <Row>
                                                    {!isValid && errorMessage && <p className="text-danger fs-14">{errorMessage}</p>}
                                                </Row>
                                                <Row >
                                                    <Col xxl={3}>
                                                        <Button type="button" color="primary"  className="mt-1 w-100 btn  bg-primary"
                                                                onClick={() =>
                                                                    {
                                                                        setSend(true)
                                                                        AxiosClient.get('/getDatesSubmissionDocuments', {params: {periods: period, idOrg: id}})
                                                                            .then((r) => {
                                                                                setSend(false)
                                                                                setPeriod(r.data)
                                                                                setModalActive(true)
                                                                            })
                                                                            .catch(() => {
                                                                                setSend(false)
                                                                                console.log('error')
                                                                            })
                                                                    }
                                                                }
                                                                disabled={!toggle || send || !isValid}
                                                                >
                                                                    {
                                                                        send ?
                                                                            <Spinner size={'sm'}>
                                                                                Loading...
                                                                            </Spinner>
                                                                            :
                                                                            "Получить талон"
                                                                    }
                                                        </Button>
                                                    </Col>
                                                    <Col xxl={3}>
                                                        <SubmitWithLoading text={"Отправить"} noActive={!toggle || date.time.id === ''} className="mt-1 btn btn-primary w-100"/>
                                                    </Col>
                                                </Row>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    )}}

                </Formik>
                <NotificationShow />
            </Container>
            <DateTimeSubmissionDocuments active={modalActive} setActive={setModalActive} date={date} setDate={(values)=>setDate(values)} period={period}></DateTimeSubmissionDocuments>
            <SubmissionDocumentsSuccess success={success} setSuccess={value => setSuccess(value)} time={time}/>
            <HelpWindows helpWindow={helpWindow} setHelpWindows={value => setHelpWindows(value)}/>
        </div>
    );
};

export default SubmissionDocuments;