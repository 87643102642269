import React, {FC, useEffect, useState} from 'react';
import AxiosClient from "../../../api/AxiosClient";
import {Col, Input, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Select from "react-select";
import {customStyles} from "../../settings";
import {ISchool} from "../../../models/KSHP/ISchool";
import * as Yup from "yup";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../hooks/redux";
import {setShowNotification} from "../../../store/reducers/widgets/WidgetsSlice";
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface IModalImportFileParentsStudents {
    importFileParentsStudents: boolean,
    setImportFileParentsStudents(value: boolean) : any,
    setTableConfirmationImport(value: any) : any,
    setListImport(value: any[]) : any
}
const ModalImportFileParentsStudents: FC<IModalImportFileParentsStudents> = ({importFileParentsStudents, setImportFileParentsStudents, setTableConfirmationImport, setListImport}) => {
    const dispatch = useAppDispatch();
    const [schools, setSchools] = useState<ISchool[]>([]);
    const [builds, setBuilds] = useState<any[]>([]);
    const [selectSchool, setSelectSchool] = useState<{ value: string, label: string }>({ value: '', label: 'Не выбрано' })
    const [selectBuild, setSelectBuild] = useState<any>({ value: '', label: 'Не выбрано' });
    const [file, setFile] = useState<any>();
    const [schoolsOption, setSchoolsOption] = useState<ISchool[]>([])
    const [pageOption, setPageOption] = useState(1);
    const [totalOptions, setTotalOptions] = useState(0);
    const [loadingOptions, setLoadingOptions] = useState(false);


    useEffect(() => {
        fetchMoreSchools(1)
            .then((r) => {
                setPageOption(state => state + 1)
                setSchoolsOption(r)
            })
        setSelectBuild({ value: '', label: 'Не выбрано' })
    }, [])

    useEffect(() => {
        if (selectSchool?.value){
            AxiosClient.get(`/schools/${selectSchool.value}/buildings`)
                .then((r) => {
                    setBuilds(r.data)
                })
        }
    }, [selectSchool.value, schools])


    const fetchMoreSchools = async (page: number) => {
        setLoadingOptions(true)
        const response = await AxiosClient.get(`/schools`, {params: {page: page, per_page: 10}})
        setLoadingOptions(false)
        setTotalOptions(response?.data.total)
        return response?.data.schools
    };

    const loadMoreData = async () => {
        const newData = await fetchMoreSchools(pageOption); // функция, которая загружает дополнительные данные
        setSchoolsOption(prevOptions => [...prevOptions, ...newData]);
    };

    const handleMenuScroll = async (event: any) => {
        const target = event.target;
        if (totalOptions !== schoolsOption.length && target.scrollHeight - target.scrollTop === target.clientHeight) {
            await loadMoreData();
        }
    };
    useEscapeKey(() => dispatch(setModalAdd(false)), importFileParentsStudents, "importFileParentsStudents");

    return (
        <>
        <Modal id="importFileParentsStudents" isOpen={importFileParentsStudents}
               toggle={() => setImportFileParentsStudents(!importFileParentsStudents)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setImportFileParentsStudents(!importFileParentsStudents)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Импорт файла родителей
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            school: selectSchool.value,
                            build: selectBuild.value,
                        }
                    }
                    validationSchema={
                        Yup.object({
                            school: Yup.string().required('Обязательное поле'),
                            build: Yup.string().required('Обязательное поле'),
                        })
                    }
                    onSubmit={() => {
                        dispatch(setIsSend(true));
                        const formData = new FormData();
                        formData.append('file', file);
                        formData.append('school', selectSchool.value);
                        formData.append('build', selectBuild.value);

                            AxiosClient.post('/users/file_import_parents_students', formData)
                            .then((r) => {
                                setImportFileParentsStudents(false);
                                setTableConfirmationImport("parentStudents");
                                setListImport(r.data);
                                dispatch(setIsSend(false));
                            })
                            .catch(() => {
                                console.log('error');
                                dispatch(setShowNotification({ show: true, type: "error", message: 'Ошибка, проверьте корректность данных!' }));
                                dispatch(setIsSend(false));
                            })
                    }}>
                    {
                        ({errors, touched}) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Школа:
                                        <Field name="school">
                                            {() => (
                                                <Select
                                                    options={schoolsOption?.length > 0 ? [{
                                                        value: '',
                                                        label: 'Не выбрано'
                                                    }, ...schoolsOption.map(v => ({ value: v.id.toString(), label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                                                    value={selectSchool}
                                                    onChange={(change: any) => {
                                                        setSelectSchool({ value: change.value, label: change.label })
                                                    }}
                                                    styles={customStyles}
                                                    className={`${errors.school && touched.school && "border rounded border-danger"}`}
                                                    isLoading={loadingOptions}
                                                    placeholder="Поиск"
                                                    name="school"
                                                    onMenuScrollToBottom={handleMenuScroll}
                                                />
                                            )}
                                        </Field>
                                        </label>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Корпус:
                                        <Field name="build">
                                            {() => (
                                                <Select
                                                    options={builds?.length > 0 ? [{
                                                        value: '',
                                                        label: 'Не выбрано'
                                                    }, ...builds?.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                                                    value={selectBuild}
                                                    onChange={(change: any) => {
                                                        setSelectBuild({ value: change.value, label: change.label })
                                                    }}
                                                    styles={customStyles}
                                                    className={`${errors.build && touched.build && "border rounded border-danger"}`}
                                                    placeholder="Поиск"
                                                    name="build"
                                                />
                                            )}
                                        </Field>
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="w-100">Файл (.csv, .xlsx)
                                        <Input
                                            name="file"
                                            type="file"
                                            className="mb-2"
                                            accept=".csv, .xlsx"
                                            onChange={(e: any)=>setFile(e.target.files[0])}
                                        ></Input>
                                        </label>
                                    </Col>
                                </Row>
                                <div className="hstack gap-2">
                                    <SubmitWithLoading text={'Готово'}/>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
};

export default ModalImportFileParentsStudents;
