import React, {FC, useEffect, useState} from 'react';
import {Form, Formik} from "formik";
import * as Yup from "yup";
import AxiosClient from "../../../api/AxiosClient";
import {Card, CardBody, CardHeader, Col, Input, Nav, NavItem, NavLink, Row} from "reactstrap";
import Notification from "../../widgets/Notification";
import DataTable from "react-data-table-component";

interface IAddPhotoPatternPortfolio {
    selectPattern: any
}

const AddPhotoPatternPortfolio: FC <IAddPhotoPatternPortfolio> = ({selectPattern}) => {
    // const {pages} = useAppSelector(state => state.AdminReducer)
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<string>('');
    const [notificationMessage, setNotificationMessage] = useState<string>('');
    const [themes, setThemes] = useState<any[]>([]);

    useEffect(() => {
        AxiosClient.get(`type_pattern_all/parent/portfolio`, {params: {idPattern: selectPattern.id}})
            .then(r => {
                setThemes(r.data)
            })
            .catch(() => console.log('error'))
    }, [selectPattern])

    const columns = [
        {
            id: "typePage",
            name: <span className='font-weight-bold accessibility_size_table'>Тип страницы</span>,
            selector: (row: any) => row.name,
            cell: (row: any) => <span className="accessibility_size_table">{row.name}</span>
        },
        {
            id: "isDounload",
            name: <span className='font-weight-bold accessibility_size_table'>Загружен шаблон</span>,
            selector: (row: any) => row.id,
            cell: (row: any, index: number) => row.patternPagePortfolios?.[0]?.pivot.photo ? <i
                id={'editPagePort' + index.toString()}
                className="bx bx-check fs-3 text-success"
            ></i> : <i
                id={'editPagePort' + index.toString()}
                className="bx bx-x fs-3"
            ></i>,
            center: true,
            width: '100px'
        },{
            id: "isDounload",
            name: <span className='font-weight-bold accessibility_size_table'>Путь</span>,
            selector: (row: any) => row.id,
            cell: (row: any) => <span className="accessibility_size_table">{row.patternPagePortfolios?.[0]?.pivot.photo}</span>,
            center: true
        },
        {
            id: "fileUpdate",
            name: <span className='font-weight-bold accessibility_size_table'>Загрузка</span>,
            selector: (row: any) => row.id,
            cell: (row: any) =>  <Input
                name="photo"
                type="file"
                id="inputForFileMyFamily"
                className="form-control"
                accept=".png, .jpg"
                onChange={(e: any) => {
                    const file = e.target.files[0];

                    if (file) {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            const blob = new Blob([reader.result as ArrayBuffer], { type: file.type });

                            const formData = new FormData();
                            formData.append('type', row.id)
                            formData.append('idPattern', selectPattern.id);
                            formData.append('namePattern', selectPattern.name);
                            formData.append('path_photo', row.patternPagePortfolios?.[0]?.pivot.photo);
                            formData.append('photo', blob);
                                AxiosClient.post(`create/typePattern/parent/portfolio`, formData, { headers: { "Content-Type": 'multipart/form-data' } })
                                    .then(() => {
                                        setNotificationType('success');
                                        setNotificationMessage('Готово, изображение загружено!');
                                        setShowNotification(true);
                                    })
                                    .catch(() => {
                                        console.log('error');
                                        setNotificationType('error');
                                        setNotificationMessage('Ошибка при загрузке изображения');
                                        setShowNotification(true);
                                    })
                        };
                        reader.readAsArrayBuffer(file);
                    }
                }}
            />,
            center: true
        }
    ]

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={
                    {
                        name: selectPattern.name,
                        themeId: selectPattern.theme_page_portfolio_id,
                    }
                }
                validationSchema={
                    Yup.object(
                        {
                            name: Yup.string().required('Введите название темы'),
                        }
                    )
                }
                onSubmit={
                    (value) => {
                        if(selectPattern.id) {
                            AxiosClient.post(`update/name/pattern/parent/portfolio/${selectPattern.id}`, { name: value.name})
                                .then(() => {
                                    setNotificationType('success');
                                    setNotificationMessage('Готово, тип страницы изменен!');
                                    setShowNotification(true);
                                })
                                .catch(() => {
                                    console.log('error');
                                    setNotificationType('error');
                                    setNotificationMessage('Ошибка, проверьте корректность данных!');
                                    setShowNotification(true);
                                });
                        }else {
                            AxiosClient.post('create/pattern/parent/portfolio', { name: value.name})
                                .then(() => {
                                    setNotificationType('success');
                                    setNotificationMessage('Готово, тип страницы добавлен!');
                                    setShowNotification(true);
                                })
                                .catch(() => {
                                    console.log('error');
                                    setNotificationType('error');
                                    setNotificationMessage('Ошибка, проверьте корректность данных!');
                                    setShowNotification(true);
                                });
                        }
                    }
                }
            >
                {({}) => (
                    <Form encType="multipart/form-data">
                        <Card className="mx-n3">
                            <CardHeader>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                     role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className="active">
                                            <i className="fas fa-home"></i>
                                            Картинки шаблона
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <CardBody className="p-4">
                                <Row>
                                    <Col>
                                       <DataTable
                                           columns={columns}
                                           data={themes}
                                           noDataComponent={'нет данных'}
                                           highlightOnHover
                                           customStyles={{
                                               rows: {
                                                   highlightOnHoverStyle: {
                                                       backgroundColor: "#ffebb5",
                                                   },
                                               },
                                           }}
                                       />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Form>
                )}
            </Formik>
            {showNotification && <Notification type={notificationType} message={notificationMessage} setShowNotification={(value: boolean) => setShowNotification(value)}/>}
        </>
    );
};

export default AddPhotoPatternPortfolio;
