import React, {useEffect, useState} from 'react';

import FullCalendar from "@fullcalendar/react";
import BootstrapTheme from "@fullcalendar/bootstrap5"
import interactionPlugin from "@fullcalendar/interaction";
import timeGrid from "@fullcalendar/timegrid"
import ItemsEvent from "../components/calendar/ItemsEvent";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import ItemEventCalendar from "../components/calendar/ItemEventCalendar";
import {EventContentArg} from "@fullcalendar/core";
import ruLocale from '@fullcalendar/core/locales/ru';
import Teachers from "../components/calendar/Teachers";
import ItemNews from "../components/calendar/ItemNews";
import DailyReport from "../components/calendar/DailyReport";
import {Russian} from "flatpickr/dist/l10n/ru";
import Flatpickr from "react-flatpickr";
import {useAppDispatch, useAppSelector} from "../hooks/redux";
import {ParentSlice} from "../store/reducers/parent/ParentSlice";
import {getChildren} from "../store/reducers/parent/ActionCreator";
import {Helmet} from "react-helmet";
import AxiosClient from "../api/AxiosClient";
import moment from "moment";
import {convertDateFull} from "../functions/ConvertDate";
import useWidth from "../hooks/useWidth";
import ModalAllInfoEvent from "../components/calendar/ModalAllInfoEvent";
import ThemesWeek from "../components/calendar/ThemesWeek";
const Calendar = () => {

    const dispatch = useAppDispatch();
    const [date, setDate] = useState<Date>(new Date());
    const [dateCalendar, setDateCalendar] = useState(getStartAndEndOfWeek(new Date()));
    const {children, child} = useAppSelector(state => state.ParentReducer)
    const [events, setEvents] = useState<{temy_zanyatiya: any[], calendar : any[]}>({temy_zanyatiya: [], calendar: []}); 
    const [selectEvent, setSelectEvent] = useState<null | any>(null);
    const [getAllInfo, setGetAllInfo] = useState(false);
    const {width} = useWidth();

    useEffect(() => {
        dispatch(getChildren()).then()
        const elems = document.getElementsByClassName('fc-timegrid-slot-label-frame');
        Array.from(elems).forEach(elem => {
            if (elem.textContent?.search(/^(0\d|1\d|2[0-3])$/) === 0){
                elem.classList.add.apply(elem.classList, ['fw-bold', 'd-flex', 'justify-content-start']);
            }
        })
    }, [dispatch]);

    useEffect(() => {
        if(child){
            const params = {
                children_id: child,
                date: dateCalendar
            }
            AxiosClient.get('/get_calendar_events', {params})
                .then(r => setEvents(r.data))
                .catch((reason) => reason)
        }
    }, [dateCalendar, child]);

    function getStartAndEndOfWeek(date: any) {
        const startOfWeek = new Date(date);
        const endOfWeek = new Date(date);

        startOfWeek.setDate(date.getDate() - (date.getDay() === 0 ? 6 : date.getDay() - 1));
        endOfWeek.setDate(date.getDate() - date.getDay() + 7);

        startOfWeek.setHours(0, 0, 0, 0);
        endOfWeek.setHours(23, 59, 59, 999);

        return [startOfWeek, endOfWeek];
    }

    return (
        <div className="page-content">
            <style>
                {   `
                    .fc .fc-timegrid-col.fc-day-today {
                        background-color: #919da9;
                    }
                    .fc-event{
                        cursor: pointer;
                    }
                    `
                }
            </style>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Календарь</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col>
                        <Flatpickr
                            value={date}
                            name='date'
                            className="form-control dash-filter-picker shadow"
                            options={{
                                mode: "single",
                                dateFormat: "d m Y",
                                defaultDate: new Date(),
                                maxDate: new Date(),
                                locale: Russian,
                                disableMobile: true
                            }}
                            onClose={(date) => {
                                setDate(date[0])
                                setDateCalendar(getStartAndEndOfWeek(date[0]))
                            }}
                        />
                    </Col>
                    <Col>
                        <select className="form-select fs-14 shadow" value={child} onChange={(event) => dispatch(ParentSlice.actions.setChild(event.target.value))}>
                            {
                                children.map(child =>
                                    (
                                        <option key={child.account_uid} value={child.account_uid} className="fs-14">{child.account_name}</option>
                                    )
                                )
                            }
                        </select>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <ThemesWeek date={date}/>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col xxl={3}>
                        <DailyReport date={date}/>
                    </Col>
                    <Col xxl={3}>
                        <ItemsEvent events={events?.temy_zanyatiya}/>
                    </Col>
                    <Col xxl={3}>
                        <ItemNews/>
                    </Col>
                    <Col xxl={3}>
                        <Teachers date={date}/>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} xxl={12}>
                        <Card>
                            <CardBody>
                                {
                                    width > 1150 ?
                                        <FullCalendar
                                            slotMinTime={'08:00'}
                                            slotMaxTime={'20:00'}
                                            slotDuration="00:05:00"
                                            eventClick={(e) => {
                                                setSelectEvent({
                                                    start: e.event.start,
                                                    end: e.event.end,
                                                    title: e.event.title,
                                                    description: e.event.extendedProps.description
                                                })
                                                setGetAllInfo(true);
                                            }}
                                            eventResizableFromStart={false}
                                            expandRows={true}
                                            datesSet={(dateInfo) => {
                                                setDateCalendar([dateInfo.start, dateInfo.end])
                                            }}
                                            plugins={[BootstrapTheme, interactionPlugin, timeGrid]}
                                            handleWindowResize={true}
                                            themeSystem="bootstrap5"
                                            headerToolbar={{
                                                left: "prev,next today",
                                                center: "title",
                                                right: "timeGridWeek,timeGridDay",
                                            }}

                                            locale={ruLocale}
                                            allDaySlot={false}
                                            displayEventTime={true}
                                            initialView='timeGridWeek'
                                            eventContent={(e: EventContentArg) => <ItemEventCalendar eventInfo={e}/>}
                                            events={events?.calendar.map(event => (
                                                {
                                                    title: event.event,
                                                    start: moment((convertDateFull(event.data) + " " + event.vremya_begin.split(' ')[1])).toDate(),
                                                    end: moment((convertDateFull(event.data) + " " + event.vremya_end.split(' ')[1])).toDate(),
                                                    description: event.event_full,
                                                    color: 'rgba(229,240,241,0.99)'
                                                })
                                            )}
                                            buttonText={
                                                {
                                                    today: "Сегодня",
                                                    week: "Неделя",
                                                    day: "День"
                                                }
                                            }
                                            height={'80vh'}
                                        />
                                        :
                                        <FullCalendar
                                            slotMinTime={'08:00'}
                                            slotMaxTime={'19:00'}
                                            slotDuration="00:05:00"
                                            eventClick={(e) => {
                                                setSelectEvent({
                                                    start: e.event.start,
                                                    end: e.event.end,
                                                    title: e.event.title,
                                                    description: e.event.extendedProps.description
                                                })
                                                setGetAllInfo(true);
                                            }}
                                            editable={false}
                                            expandRows={true}
                                            plugins={[BootstrapTheme, interactionPlugin, timeGrid]}
                                            handleWindowResize={true}
                                            themeSystem="bootstrap5"
                                            headerToolbar={{
                                                left: "prev,next today",
                                                center: "title",
                                                right: "timeGridDay",
                                            }}
                                            locale={ruLocale}
                                            allDaySlot={false}
                                            displayEventTime={true}
                                            initialView='timeGridDay'
                                            events={events.calendar.map(event => (
                                                {
                                                    title: event.event,
                                                    start: moment((convertDateFull(event.data) + " " + event.vremya_begin.split(' ')[1])).toDate(),
                                                    end: moment((convertDateFull(event.data) + " " + event.vremya_end.split(' ')[1])).toDate(),
                                                    description: event.event_full,
                                                    color: 'rgba(229,240,241,0.99)'
                                                })
                                            )}
                                            eventContent={(e: EventContentArg) => <ItemEventCalendar eventInfo={e}/>}
                                            buttonText={
                                                {
                                                    today: "Сегодня",
                                                    day: "День"
                                                }
                                            }
                                        />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalAllInfoEvent setGetAllInfo={(value) => setGetAllInfo(value)} getAllInfo={getAllInfo} info={selectEvent}/>
        </div>
    );
};

export default Calendar;
