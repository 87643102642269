import React from 'react';
import { Card, CardBody, Col, Row } from "reactstrap";

// Интерфейс для одной карточки
interface IStat {
    title: string;
    value: number;
}

// Интерфейс для пропсов компонента
interface IStatsCardsProps {
    stats: IStat[];
}

const StatsCards: React.FC<IStatsCardsProps> = ({ stats }) => {
    return (
        <Row className="mb-2">
            {stats.map((stat, index) => (
                <Col md={4} key={index}>
                    <Card className="mini-stats-wid">
                        <CardBody className="d-flex flex-column align-items-center justify-content-center">
                            <h1 className="display-4 mb-2 text-primary" style={{ fontSize: '3rem', lineHeight: 1 }}>
                                {stat.value}
                            </h1>
                            <p className="text-muted fw-medium mb-0">{stat.title}</p>
                        </CardBody>
                    </Card>
                </Col>
            ))}
        </Row>
    );
};

export default StatsCards;