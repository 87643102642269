import React, {useEffect, useState} from 'react';
import AxiosClient from "../../../api/AxiosClient";
import {Card, CardBody, CardFooter, Col, Row} from "reactstrap";
import DataTable from "react-data-table-component";
import ModalAddBenefit from "./ModalAddBenefit";
import ModalUpdateBenefit from "./ModalUpdateBenefit";

const ListBenefit = () => {
    const [search, setSearch] = useState('')
    const [selectBenefit, setSelectBenefit] = useState<any | null>(null)
    const [addBenefit, setAddBenefit] = useState(false);
    const [updateBenefit, setUpdateBenefit] = useState(false);
    const [dataFiltering, setDataFiltering] = useState<any[]>([]);
    const [benefits, setBenefits] = useState<any[]>([]);

    useEffect(() => {
        AxiosClient.get('/benefits')
            .then(r => {
                setDataFiltering(r.data)
                setBenefits(r.data)
            })
    }, []);

    useEffect(() => {
        setDataFiltering(filtering())
    }, [search, benefits])

    const filtering = () => {
        let data = benefits;

        if(search) {
            data = data.filter((e) => e.name.toLowerCase().includes(search.toLowerCase()))
        }

        return data;
    }

    const columns: any = [
        {
            id: "date",
            name: <span className='font-weight-bold accessibility_size_table'>Наименование</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.name}</span>,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'></span>,
            cell: (row: any) => <i className="bx bx-archive-in fs-3 cursor-pointer" title="Добавить в архив" onClick={() => {
                AxiosClient.post(`/benefits/archive/${row.id}`)
                    .then(() => {
                        AxiosClient.get('/benefits')
                            .then(r => setBenefits(r.data))
                    })
            }}></i>,
            width: '60px',
        }
    ]
    return (
        <>
            <Row>
                <Col xxl={3} xl={3} lg={6}>
                    <label className="w-100">Наименование:
                        <input type="text" className='form-control' value={search} onChange={(e) => setSearch(e.target.value)} />
                    </label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mt-2">
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={dataFiltering}
                                noDataComponent={'Нет данных'}
                                pagination
                                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно льгот:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        },
                                    },
                                    expanderRow: {
                                        style: {
                                            backgroundColor: "#ffebb5",
                                            paddingTop: '5px',
                                            paddingBottom: '5px'
                                        },
                                    },
                                }}
                                onRowClicked={
                                    (row) => {
                                        if(window.innerWidth <= 768){
                                            setSelectBenefit(row)
                                            setUpdateBenefit(true)
                                        }
                                    }
                                }
                                onRowDoubleClicked={
                                    (row) => {
                                        if(window.innerWidth > 768){
                                            setSelectBenefit(row)
                                            setUpdateBenefit(true)
                                        }
                                    }
                                }
                            />
                        </CardBody>
                        <CardFooter>
                            <button className="btn btn-primary" onClick={() => setAddBenefit(true)}>Добавить</button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            <ModalAddBenefit setBenefits={value => setBenefits(value)} addBenefit={addBenefit} setAddBenefit={value => setAddBenefit(value)}/>
            <ModalUpdateBenefit setBenefits={value => setBenefits(value)} updateBenefit={updateBenefit} setUpdateBenefit={value => setUpdateBenefit(value)} selectBenefit={selectBenefit}/>
        </>
    );
};

export default ListBenefit;
