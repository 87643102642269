import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    setDateAdd,
    setIsSend,
    setModalAdd,
    WidgetsSlice
} from "../../../store/reducers/widgets/WidgetsSlice";
import {Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import moment from "moment/moment";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import AxiosClient from "../../../api/AxiosClient";
import {getJournalHaccpReplacementBedLinen} from "../../../store/reducers/widgets/ActionCreator";
import classNames from "classnames";
import TableLinen from "./TableLinen";
import * as Yup from "yup";
import ModalConfirmExitHaccp from "../ModalConfirmExitHaccp";
import {useModalWithConfirm} from "../../../hooks/useModalWithConfirm";

interface IModalAddRepplacementBedLinen{
    updateData: any
}

const ModalAddReplacementBedLinen: FC<IModalAddRepplacementBedLinen> = ({updateData}) => {
    const dispatch = useAppDispatch();
    const {modalAdd, groupAllDeviations, date, groupModalAdd, groups, dateAdd, successUpdate} = useAppSelector(state => state.WidgetReducer);
    const [activeTab, setActiveTab] = useState("1");
    const [clothesSetting, setClothesSetting] = useState([]);
    const [selectClothesSetting, setSelectClothesSetting] = useState<any>('');
    const [linen, setLinen] = useState([])

    const {
        isFormChange,
        showConfirmExit,
        handleClose,
        handleFormChange,
        handleConfirmClose,
        handleStay,
    } = useModalWithConfirm("addReplacementBedLinen");

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(WidgetsSlice.actions.successUpdate(""))
        }, 5000)
        return () => clearTimeout(timer)
    }, [successUpdate, dispatch]);

    useEffect(() => {
        if(modalAdd){
            AxiosClient.get('haccp/getClothesSettingList')
                .then((r: any) => {
                    setClothesSetting(r.data)
                    r.data && setSelectClothesSetting(r.data[0].uid)
                })
                .catch((error) => console.log(error))
        }
    }, [modalAdd])

    useEffect(() => {
        if(updateData){
            dispatch(setDateAdd(moment(updateData.data).toDate()))
            setLinen(updateData.linen)
        }
    }, [updateData])

    useEffect(() => {
        if (modalAdd && !updateData) {
            dispatch(setDateAdd(new Date()));
        }
    }, [modalAdd]);
    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <>
        <Modal id="ModalAddReplacementBedLinen" isOpen={modalAdd} toggle={handleClose}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={handleClose} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление замены постельного белья
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        date: moment(dateAdd).format("DD-MM-YYYY"),
                        clothes_setting: `${groupModalAdd}`,
                        group: groupAllDeviations ? groups.find((value: any) => value.group_id === groupAllDeviations)?.group_name : '',
                        linen: linen
                    }}
                    validationSchema={
                        Yup.object({
                            linen: Yup.array().min(1, 'Требуется добавить постельное белье!')
                        })}
                    onSubmit={(value) => {
                        dispatch(setIsSend(true));

                        let prop = {
                            uid: updateData? updateData.uid : '',
                            data: moment(dateAdd).format("DD-MM-YYYY"),
                            clothes_setting: selectClothesSetting,
                            linen: linen,
                        }

                        AxiosClient.post('haccp/saveJournalReplacementBedLinen', prop)
                            .then((r: any) => {
                                dispatch(getJournalHaccpReplacementBedLinen(date, groupAllDeviations)).then();
                                dispatch(setIsSend(false));
                                dispatch(setModalAdd(false));
                                handleConfirmClose();
                            })
                            .catch((error) => {
                                dispatch(setIsSend(false))
                            })
                    }}
                >
                    {({ errors, touched, setFieldValue }) => (
                        <Form onChange={handleFormChange}>
                        <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                             role="tablist">
                            <NavItem>
                                <NavLink
                                    className={classNames({active: activeTab === "1", "p-2": true})}
                                    onClick={() => {
                                        tabChange("1");
                                    }}
                                    type="button">
                                    <i className="fas fa-home"></i>
                                    Основное
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classNames({active: activeTab === "2", "p-2": true})}
                                    onClick={() => {
                                        tabChange("2");
                                    }}
                                    type="button">
                                    <i className="fas fa-home"></i>
                                    Список белья
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab} className="mt-2">
                            <TabPane tabId="1" className="align-items-center">
                                <div className="col-auto">
                                    <label className="w-100">Дата:
                                    <div className="col mb-3">
                                        <div className="input-group col-auto">
                                            <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                value={dateAdd}
                                                options={{
                                                    locale: Russian, dateFormat: "d M Y", disableMobile: true, mode: 'single'
                                                }}
                                                onChange={(date) => {
                                                    dispatch(setDateAdd(moment(date[0]).toDate()))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label className="w-100">Заполнить на основании:
                                    <Field as="select" className="form-select shadow" value={selectClothesSetting}
                                           onChange={(e: any) => setSelectClothesSetting(e.target.value)} name="clothes_setting"
                                        >
                                        {clothesSetting.map((obj: any, index: number) => {
                                            return <option key={index}
                                                           value={obj.uid}>{obj.name}</option>
                                        })}
                                    </Field>
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label className="w-100">Группа:
                                        <Field name="group" className="form-control" disabled={true}/>
                                    </label>
                                </div>
                            </TabPane>
                            <TabPane tabId="2" className="align-items-center">
                                <TableLinen linen={linen} setLinen={(value: any) => setLinen(value)} selectClothesSetting={selectClothesSetting}/>
                            </TabPane>
                        </TabContent>
                        {errors.linen && (
                            <div style={{ color: 'red', marginTop: '5px' }}>Требуется добавить постельное белье!</div>
                        )}
                        <div className="hstack gap-2 justify-content-end mt-1">
                            <SubmitWithLoading text={"Сохранить"}/>
                        </div>
                    </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
            <ModalConfirmExitHaccp
                isOpen={showConfirmExit}
                onClose={handleConfirmClose}
                onStay={handleStay}
                hasUnsavedChanges={isFormChange}
            />
        </>
    );
};

export default ModalAddReplacementBedLinen;
