import ReactApexChart from "react-apexcharts";
import moment from "moment";
import {useAccessibility} from "../../context/AccessibilityContext";


const getChartColorsArray = (colors) => {
    colors = JSON.parse(colors);
    return colors.map(function (value) {
        const newValue = value.replace(" ", "");
        if (newValue.indexOf(",") === -1) {
            let color = getComputedStyle(document.documentElement).getPropertyValue(newValue);

            if (color.indexOf("#") !== -1)
                color = color.replace(" ", "");
            if (color) return color;
            else return newValue;
        } else {
            let val = value.split(',');
            if (val.length === 2) {
                let rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
                rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
                return rgbaColor;
            } else {
                return newValue;
            }
        }
    });
};

const ProjectStatusCharts = ({series}) => {
    let options = {
        labels: (series || []).map((dev) => dev.deviation_name),
        chart: {
            type: "donut",
            height: 230,
        },
        plotOptions: {
            pie: {
                size: 100,
                offsetX: 0,
                offsetY: 0,
                donut: {
                    size: "80%",
                    labels: {
                        show: false,
                    }
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        stroke: {
            lineCap: "round",
            width: 0
        },
    }

    return (
        <>
            <ReactApexChart
                options={options}
                series={(series || []).map((dev) => dev.count)}
                type="donut"
                height="150"
                className="apex-charts"
            />
        </>
    );
}

const ProjectStatusChartsEmployee = ({series}) => {
    let options = {
        labels: (series || []).map((dev) => dev.name),
        chart: {
            type: "donut",
            height: 230,
        },
        plotOptions: {
            pie: {
                size: 100,
                offsetX: 0,
                offsetY: 0,
                donut: {
                    size: "80%",
                    labels: {
                        show: false,
                    }
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        stroke: {
            lineCap: "round",
            width: 0
        }
    }

    return (
        <>
            <ReactApexChart
                options={options}
                series={(series || []).map((dev) => dev.count)}
                type="donut"
                height="150"
                className="apex-charts"
            />
        </>
    );
}

const ProjectOverviewChartRegisterDefectEquipment = ({series}) => {
    const {fontSizeGraph} = useAccessibility();
    const categories = ['Всего'];

    let options = {
        chart: {
            height: '500px',
            type: 'line',
            toolbar: {
                show: false,
            }
        },
        stroke: {
            curve: 'smooth',
            dashArray: [0, 0, 3],
            width: [0, 0, 2],
        },
        fill: {
            opacity: [1, 1, 0.3]
        },
        markers: {
            size: [0, 0, 4],
            strokeWidth: 2,
            hover: {
                size: 3,
            }
        },
        xaxis: {
            categories: categories,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '100%',
                barHeight: '40%'
            }
        },
        tooltip: {
            shared: true,
            style: {
                fontSize: fontSizeGraph
            },
            y: [{
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }]
        }
    };
    return (
        <>
            <ReactApexChart
                options={options}
                series={series}
                type="line"
                height="350"
                className="apex-charts"
            />
        </>
    );
}

const ProjectOverviewChartsActEquipmentInspectionCertificate = ({data}) => {
    const {fontSizeGraph} = useAccessibility();
    const categories = ['Всего'];
    const options = {
        chart: {
            height: '500px',
            type: 'line',
            toolbar: {
                show: false,
            }
        },
        stroke: {
            curve: 'smooth',
            dashArray: [0, 0, 3],
            width: [0, 0, 2],
        },
        fill: {
            opacity: [1, 1, 0.3]
        },
        markers: {
            size: [0, 0, 4],
            strokeWidth: 2,
            hover: {
                size: 4,
            }
        },
        xaxis: {
            categories: categories,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '30%',
                barHeight: '70%'
            }
        },
        tooltip: {
            shared: true,
            style: {
                fontSize: fontSizeGraph
            },
            y: [{
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }]
        }
    };
    return (
        <>
            <ReactApexChart
                options={options}
                series={data}
                type="line"
                height="400"
                className="apex-charts"
            />
        </>
    );
}

const ProjectsOverviewCharts = ({dataColors, series}) => {
    const {fontSizeGraph} = useAccessibility();
    let lineChartCustomerColors = getChartColorsArray(dataColors);

    let categories = series.map((i) => {
        return i.mounth.slice(3)
    })

    let present = {
        name: 'Присутствует',
        type: 'bar',
        data: series.map((i) => {
            return i.present
        })
    }

    let noPresent = {
        name: 'Отсутствует',
        type: 'bar',
        data: series.map((i) => {
            return i.no_present
        })
    }
    let zabolevanie = {
        name: 'Болеет',
        type: 'area',
        data: series.map((i) => {
            return i.zabolevanie
        })
    }

    const options = {
        chart: {
            height: '500px',
            type: 'line',
            toolbar: {
                show: false,
            }
        },
        stroke: {
            curve: 'smooth',
            dashArray: [0, 0, 3],
            width: [0, 0, 2],
        },
        fill: {
            opacity: [1, 1, 0.3]
        },
        markers: {
            size: [0, 0, 4],
            strokeWidth: 2,
            hover: {
                size: 4,
            }
        },
        xaxis: {
            categories: categories,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '30%',
                barHeight: '70%'
            }
        },
        colors: lineChartCustomerColors,
        tooltip: {
            shared: true,
            style: {
                fontSize: fontSizeGraph
            },
            y: [{
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }]
        }
    };
    return (
        <>
            <ReactApexChart
                options={options}
                series={[present, noPresent, zabolevanie]}
                type="line"
                height="400"
                className="apex-charts"
            />
        </>
    );
};

const ProjectsOverviewChartsOperationalControl = ({dataColors, series}) => {
    let lineChartCustomerColors = getChartColorsArray(dataColors);

    let categories = series.map((i) => {
        return i.month
    })

    let hard = {
        name: 'Грубые',
        type: 'bar',
        data: series.map((i) => {
            return i.hard
        })
    }

    let light = {
        name: 'Незначительные',
        type: 'bar',
        data: series.map((i) => {
            return i.light
        })
    }

    let none = {
        name: 'Отсутствуют',
        type: 'bar',
        data: series.map((i) => {
            return i.none
        })
    }

    let all = {
        name: 'Всего',
        type: 'bar',
        data: series.map((i) => {
            return i.all
        })
    }

    const options = {
        chart: {
            height: '500px',
            type: 'line',
            toolbar: {
                show: false,
            }
        },
        stroke: {
            curve: 'smooth',
            dashArray: [0, 0, 0],
            width: [0, 0, 0],
        },
        fill: {
            opacity: [1, 1, 0.3]
        },
        markers: {
            size: [0, 0, 4],
            strokeWidth: 2,
            hover: {
                size: 4,
            }
        },
        xaxis: {
            categories: categories,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '30%',
                barHeight: '70%'
            }
        },
        colors: lineChartCustomerColors,
    };

    return (
        <>
            <ReactApexChart
                options={options}
                series={[hard, light, none, all]}
                type="line"
                height="400"
                className="apex-charts"
            />
        </>
    );
};

const ProjectStatusChartsDonutOperationalControl = ({series}) => {

    let options = {
        labels: (series || []).map((dev) => dev.name),
        chart: {
            type: "donut",
            height: 230,
        },
        plotOptions: {
            pie: {
                size: 100,
                offsetX: 0,
                offsetY: 0,
                donut: {
                    size: "80%",
                    labels: {
                        show: false,
                    }
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        stroke: {
            lineCap: "round",
            width: 0
        },
    }

    return (
        <>
            <ReactApexChart
                options={options}
                series={(series || []).map((dev) => dev.count)}
                type="donut"
                height="150"
                className="apex-charts"
            />
        </>
    );
}

const ProjectsJournalHaccp = ({dataColors, series}) => {
    const {fontSizeGraph} = useAccessibility();
    let lineChartCustomerColors = getChartColorsArray(dataColors);

    const monthsInRussian = {
        '01': 'Январь',
        '02': 'Февраль',
        '03': 'Март',
        '04': 'Апрель',
        '05': 'Май',
        '06': 'Июнь',
        '07': 'Июль',
        '08': 'Август',
        '09': 'Сентябрь',
        '10': 'Октябрь',
        '11': 'Ноябрь',
        '12': 'Декабрь',
    };

    const dates = series ? series.dates.map((value) => value.date) : [];
    const days = dates.map(date => moment(date, "DD.MM.YYYY H:mm:ss").date());
    const firstDate = moment(dates[0], "DD.MM.YYYY H:mm:ss");
    const month = monthsInRussian[firstDate.format("MM")];
    const year = firstDate.format("YYYY");

    const test = series.type_group.map((value) => {
        return {
            name: value,
            type: 'bar',
            data: series.dates.map((date) =>
                date.data.find((el) => el.name === value)?.temperature ?? 0
            ),
        };
    });

    const options = {
        chart: {
            height: '500px',
            type: 'line',
            toolbar: {
                show: false,
            }
        },
        stroke: {
            curve: 'smooth',
            dashArray: [0, 0, 0],
            width: [0, 0, 0],
        },
        fill: {
            opacity: [1, 1, 1]
        },
        markers: {
            size: [0, 0, 4],
            strokeWidth: 2,
            hover: {
                size: 4,
            }
        },
        xaxis: {
            categories: days,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            },
            labels: {
                style: {
                    fontSize: '12px',
                }
            },
            title: {
                text: `${month} ${year}`,
                // offsetY: 20,
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold'
                }
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '30%',
                barHeight: '70%'
            }
        },
        colors: lineChartCustomerColors,
        tooltip: {
            shared: true,
            style: {
                fontSize: fontSizeGraph
            },
            y: [{
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }]
        }
    };
    return (
        <>
            <ReactApexChart
                options={options}
                series={test}
                type="line"
                height="400"
                className="apex-charts"
            />
        </>
    );
};

const ProjectsJournalHaccpVentilationRoom = ({dataColors, series}) => {
    let lineChartCustomerColors = getChartColorsArray(dataColors);

    const monthsInRussian = {
        '01': 'Январь',
        '02': 'Февраль',
        '03': 'Март',
        '04': 'Апрель',
        '05': 'Май',
        '06': 'Июнь',
        '07': 'Июль',
        '08': 'Август',
        '09': 'Сентябрь',
        '10': 'Октябрь',
        '11': 'Ноябрь',
        '12': 'Декабрь',
    };

    const dates = series.map((value) => value.data);
    const days = dates.map(date =>moment(date, "DD.MM.YYYY").format("D"));
    const firstDate = dates[0];
    const month = monthsInRussian[moment(firstDate, "DD.MM.YYYY").format("MM")];
    const year = moment(firstDate, "DD.MM.YYYY").format("YYYY")

    const temperatureSeries = [{
        name: 'Температура',
        type: 'bar',
        data: series.map((date) => date.temperature ?? 0)
    }];

    const options = {
        chart: {
            height: '500px',
            type: 'line',
            toolbar: {
                show: false,
            }
        },
        stroke: {
            curve: 'smooth',
            dashArray: [0, 0, 0],
            width: [0, 0, 0],
        },
        fill: {
            opacity: [1, 1, 1]
        },
        markers: {
            size: [0, 0, 4],
            strokeWidth: 2,
            hover: {
                size: 4,
            }
        },
        xaxis: {
            categories: days,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            },
            labels: {
                style: {
                    fontSize: '12px',
                }
            },
            title: {
                text: `${month} ${year}`,
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold'
                }
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '30%',
                barHeight: '70%'
            }
        },
        colors: lineChartCustomerColors,
        tooltip: {
            shared: true,
            y: [{
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }]
        }
    };
    return (
        <>
            <ReactApexChart
                options={options}
                series={temperatureSeries}
                type="line"
                height="400"
                className="apex-charts"
            />
        </>
    );
};

const ProjectsJournalHaccpMorningFilterEmployee= ({ dataColors, series }) => {
    let lineChartCustomerColors = getChartColorsArray(dataColors);

    const monthsInRussian = {
        '01': 'Январь',
        '02': 'Февраль',
        '03': 'Март',
        '04': 'Апрель',
        '05': 'Май',
        '06': 'Июнь',
        '07': 'Июль',
        '08': 'Август',
        '09': 'Сентябрь',
        '10': 'Октябрь',
        '11': 'Ноябрь',
        '12': 'Декабрь',
    };

    const dates = series.map((value) => value.data);
    const days = dates.map(date => moment(date, "DD.MM.YYYY").format("D"));
    const firstDate = dates[0];
    const month = monthsInRussian[moment(firstDate, "DD.MM.YYYY").format("MM")];
    const year = moment(firstDate, "DD.MM.YYYY").format("YYYY");

    // Подсчёт данных для каждого состояния
    const healthyData = series.map((date) =>
        date.employees.filter(emp => emp.rls === "Отсутствуют" && emp.orvi === "Отсутствуют").length
    );
    const orviData = series.map((date) =>
        date.employees.filter(emp => emp.orvi === "Есть").length
    );
    const rlsData = series.map((date) =>
        date.employees.filter(emp => emp.rls === "Есть").length
    );

    const dataSeries = [
        {
            name: 'Здоровые',
            type: 'bar',
            data: healthyData,
            color: lineChartCustomerColors[0]
        },
        {
            name: 'ОРВИ',
            type: 'bar',
            data: orviData,
            color: lineChartCustomerColors[1]
        },
        {
            name: 'РЛС',
            type: 'bar',
            data: rlsData,
            color: lineChartCustomerColors[2]
        }
    ];

    const options = {
        chart: {
            height: '500px',
            type: 'bar',
            stacked: true,
            toolbar: {
                show: false,
            }
        },
        xaxis: {
            categories: days,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            },
            labels: {
                style: {
                    fontSize: '12px',
                }
            },
            title: {
                text: `${month} ${year}`,
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold'
                }
            }
        },
        colors: lineChartCustomerColors,
        plotOptions: {
            bar: {
                columnWidth: '50%',
                barHeight: '100%',
                distributed: false
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        tooltip: {
            shared: true,
            intersect: false
        }
    };

    return (
        <>
            <ReactApexChart
                options={options}
                series={dataSeries}
                type="bar"
                height="400"
                className="apex-charts"
            />
        </>
    );
};



const ProjectsJournalHaccpMorningFilterChildren = ({dataColors, series}) => {
    const {fontSizeGraph} = useAccessibility();
    let lineChartCustomerColors = getChartColorsArray(dataColors);

    const monthsInRussian = {
        '01': 'Январь',
        '02': 'Февраль',
        '03': 'Март',
        '04': 'Апрель',
        '05': 'Май',
        '06': 'Июнь',
        '07': 'Июль',
        '08': 'Август',
        '09': 'Сентябрь',
        '10': 'Октябрь',
        '11': 'Ноябрь',
        '12': 'Декабрь',
    };

    const dates = series.map((value) => value.data);
    const days = dates.map(date => moment(date, "DD.MM.YYYY").format("D"));
    const firstDate = dates[0];
    const month = monthsInRussian[moment(firstDate, "DD.MM.YYYY").format("MM")];
    const year = moment(firstDate, "DD.MM.YYYY").format("YYYY");

    const healthyData = series.map((date) =>
        date.deti.filter(child => child.conclusion === "Допущен").length
    );
    const sickData = series.map((date) =>
        date.deti.filter(child => child.conclusion === "Не допущен").length
    );

    const dataSeries = [
        {
            name: 'Здоровые',
            type: 'bar',
            data: healthyData
        },
        {
            name: 'Заболевшие',
            type: 'bar',
            data: sickData
        }
    ];

    const options = {
        chart: {
            height: '500px',
            type: 'line',
            toolbar: {
                show: false,
            }
        },
        stroke: {
            curve: 'smooth',
            dashArray: [0, 0, 0],
            width: [0, 0, 0],
        },
        fill: {
            opacity: [1, 1, 1]
        },
        markers: {
            size: [0, 0, 4],
            strokeWidth: 2,
            hover: {
                size: 4,
            }
        },
        xaxis: {
            categories: days,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            },
            labels: {
                style: {
                    fontSize: '12px',
                }
            },
            title: {
                text: `${month} ${year}`,
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold'
                }
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '30%',
                barHeight: '70%'
            }
        },
        colors: lineChartCustomerColors,
        tooltip: {
            shared: true,
            style: {
                fontSize: fontSizeGraph
            },
            y: [{
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }]
        }
    };
    return (
        <>
            <ReactApexChart
                options={options}
                series={dataSeries}
                type="line"
                height="400"
                className="apex-charts"
            />
        </>
    );
};
const ProjectsJournalHaccpAntisepticSurface= ({dataColors, series}) => {
    const {fontSizeGraph} = useAccessibility();
    let lineChartCustomerColors = getChartColorsArray(dataColors);

    const monthsInRussian = {
        '01': 'Январь',
        '02': 'Февраль',
        '03': 'Март',
        '04': 'Апрель',
        '05': 'Май',
        '06': 'Июнь',
        '07': 'Июль',
        '08': 'Август',
        '09': 'Сентябрь',
        '10': 'Октябрь',
        '11': 'Ноябрь',
        '12': 'Декабрь',
    };

    // Получаем даты для оси X
    const dates = series.map(item => item.data);
    const days = dates.map(date => moment(date, "DD.MM.YYYY").format("DD.MM"));

    // Берем дату для отображения месяца и года
    const firstDate = dates[0];
    const month = monthsInRussian[moment(firstDate, "DD.MM.YYYY").format("MM")];
    const year = moment(firstDate, "DD.MM.YYYY").format("YYYY");

    // Вычисляем общую площадь обработки
    const surfaceAreas = series.map(item => {
        const totalArea = item.surfaces.reduce((total, surface) => total + parseFloat(surface.area.replace(',', '.')), 0);
        return parseFloat(totalArea.toFixed(1));
    });

    const dataSeries = [
        {
            name: 'Площадь обработки',
            type: 'bar',
            data: surfaceAreas
        }
    ];

    const options = {
        chart: {
            height: '500px',
            type: 'bar',
            toolbar: {
                show: false,
            }
        },
        xaxis: {
            categories: days,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            },
            labels: {
                style: {
                    fontSize: '12px',
                }
            },
            title: {
                text: `${month} ${year}`,
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold'
                }
            }
        },
        yaxis: {
            title: {
                text: 'Общая площадь обработки ',
            },
            labels: {
                style: {
                    fontSize: '12px',
                }
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '30%',
                barHeight: '70%'
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        colors: lineChartCustomerColors,
        tooltip: {
            shared: true,
            style: {
                fontSize: fontSizeGraph
            },
            intersect: false,
            y: [{
                formatter: function (value) {
                    return typeof value !== "undefined" ? value : value;
                }
            }]
        }
    };
    return (
        <>
            <ReactApexChart
                options={options}
                series={dataSeries}
                type="bar"
                height="400"
                className="apex-charts"
            />
        </>
    );
}
const ProjectsJournalHaccpAntisepticRoom = ({dataColors, series}) => {
    const {fontSizeGraph} = useAccessibility();
    let lineChartCustomerColors = getChartColorsArray(dataColors);

    const monthsInRussian = {
        '01': 'Январь',
        '02': 'Февраль',
        '03': 'Март',
        '04': 'Апрель',
        '05': 'Май',
        '06': 'Июнь',
        '07': 'Июль',
        '08': 'Август',
        '09': 'Сентябрь',
        '10': 'Октябрь',
        '11': 'Ноябрь',
        '12': 'Декабрь',
    };

    // Получаем даты для оси X и преобразуем их в формат для отображения
    const dates = series.map(item => item.data);
    const days = dates.map(date => moment(date, "DD.MM.YYYY").format("DD.MM.YYYY"));

    // Берем первую дату для отображения месяца и года
    const firstDate = dates[0];
    const month = monthsInRussian[moment(firstDate, "DD.MM.YYYY").format("MM")];
    const year = moment(firstDate, "DD.MM.YYYY").format("YYYY");

    // Получаем время для плановой и фактической уборки
    const plannedTimes = series.map(item =>
        moment(`${item.data} ${item.timePlan}`, "DD.MM.YYYY HH:mm").format("HH:mm")
    );
    const actualTimes = series.map(item =>
        moment(`${item.data} ${item.timeOfDisinfection}`, "DD.MM.YYYY HH:mm").format("HH:mm")
    );

    const dataSeries = [
        {
            name: 'Плановая уборка',
            type: 'bar',
            data: plannedTimes
        },
        {
            name: 'Фактическая уборка',
            type: 'bar',
            data: actualTimes
        }
    ];

    const options = {
        chart: {
            height: '500px',
            type: 'bar',
            toolbar: {
                show: false,
            }
        },
        xaxis: {
            categories: days,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            },
            labels: {
                style: {
                    fontSize: '12px',
                }
            },
            title: {
                text: `${month} ${year}`,
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold'
                }
            }
        },
        yaxis: {
            title: {
                text: 'Время уборки (часы)',
            },
            labels: {
                style: {
                    fontSize: '12px',
                }
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '30%',
                barHeight: '70%'
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        colors: lineChartCustomerColors,
        tooltip: {
            shared: true,
            style: {
                fontSize: fontSizeGraph
            },
            intersect: false,
            y: [{
                formatter: function (value) {
                    if (typeof value !== "undefined") {
                        return value;
                    }
                    return value;
                }
            }, {
                formatter: function (value) {
                    if (typeof value !== "undefined") {
                        return value;
                    }
                    return value;
                }
            }]
        }
    };

    return (
        <>
            <ReactApexChart
                options={options}
                series={dataSeries}
                type="bar"
                height="400"
                className="apex-charts"
            />
        </>
    );
};

const ProjectsJournalHaccpGeneralCleaning = ({dataColors, series, dataPlan}) => {
    const {fontSizeGraph} = useAccessibility();
    let lineChartCustomerColors = getChartColorsArray(dataColors);

    const monthsInRussian = {
        '01': 'Январь',
        '02': 'Февраль',
        '03': 'Март',
        '04': 'Апрель',
        '05': 'Май',
        '06': 'Июнь',
        '07': 'Июль',
        '08': 'Август',
        '09': 'Сентябрь',
        '10': 'Октябрь',
        '11': 'Ноябрь',
        '12': 'Декабрь',
    };

    const uniqueDates = Array.from(new Set([...dataPlan, ...series.map(item => item.data)]))
        .sort((a, b) => moment(a, "DD.MM.YYYY") - moment(b, "DD.MM.YYYY"));

    const planData = uniqueDates.map(date => dataPlan.includes(date) ? 1 : 0);
    const factData = uniqueDates.map(date => {
        const count = series.filter(item => item.data === date).length;
        return count;
    });

    const firstDate = dataPlan[0];
    const month = monthsInRussian[moment(firstDate, "DD.MM.YYYY").format("MM")];
    const year = moment(firstDate, "DD.MM.YYYY").format("YYYY")

    const cleaningSeries = [
        {
            name: 'Уборка план',
            type: 'bar',
            data: planData
        },
        {
            name: 'Уборка факт',
            type: 'bar',
            data: factData
        }
    ];

    const options = {
        chart: {
            height: '500px',
            type: 'line',
            toolbar: {
                show: false,
            }
        },
        stroke: {
            curve: 'smooth',
            dashArray: [0, 0, 0],
            width: [0, 0, 0],
        },
        fill: {
            opacity: [1, 1, 1]
        },
        markers: {
            size: [0, 0, 4],
            strokeWidth: 2,
            hover: {
                size: 4,
            }
        },
        xaxis: {
            categories: uniqueDates,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            },
            labels: {
                style: {
                    fontSize: '12px',
                }
            },
            title: {
                text: `${month} ${year}`,
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold'
                }
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '30%',
                barHeight: '70%'
            }
        },
        colors: lineChartCustomerColors,
        tooltip: {
            shared: true,
            style: {
                fontSize: fontSizeGraph
            },
            y: [{
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }]
        }
    };
    return (
        <>
            <ReactApexChart
                options={options}
                series={cleaningSeries}
                type="line"
                height="400"
                className="apex-charts"
            />
        </>
    );
};

const ProjectsJournalHaccpReplacementBedLinen = ({dataColors, series}) => {
    const {fontSizeGraph} = useAccessibility();
    let lineChartCustomerColors = getChartColorsArray(dataColors);

    const monthsInRussian = {
        '01': 'Январь',
        '02': 'Февраль',
        '03': 'Март',
        '04': 'Апрель',
        '05': 'Май',
        '06': 'Июнь',
        '07': 'Июль',
        '08': 'Август',
        '09': 'Сентябрь',
        '10': 'Октябрь',
        '11': 'Ноябрь',
        '12': 'Декабрь',
    };

    const uniqueDates = Array.from(new Set([...series.map(item => item.data)]))
        .sort((a, b) => moment(a, "DD.MM.YYYY") - moment(b, "DD.MM.YYYY"));

    const days = uniqueDates.map(date => moment(date, "DD.MM.YYYY").date());
    const firstDate = uniqueDates[0];
    const month = monthsInRussian[moment(firstDate, "DD.MM.YYYY").format("MM")];
    const year = moment(firstDate, "DD.MM.YYYY").format("YYYY")

    const typeLinen = Array.from(new Set(series.flatMap(item => item.linen.map(linenItem => linenItem.name)))
    ).map(name => {
        const linenItem = series.flatMap(item => item.linen).find(l => l.name === name);
        return { name, uid: linenItem.uid };
    });

    const groupedData = uniqueDates.map(date => {
        const itemsForDate = series.filter(item => item.data === date);
        const dateData = typeLinen.map(linenType => {
            return itemsForDate.reduce((total, item) => {
                const linenItem = item.linen.find(l => l.uid === linenType.uid);
                return total + (linenItem ? parseInt(linenItem.units) : 0);
            }, 0);
        });
        return dateData;
    });

    const cleaningSeries = typeLinen.map((linenType, index) => ({
        name: linenType.name,
        type: 'bar',
        data: groupedData.map(dayData => dayData[index])
    }));

    const options = {
        chart: {
            height: '500px',
            type: 'bar',
            stacked: true,
            toolbar: { show: false }
        },

        stroke: {
            curve: 'smooth',
            dashArray: [0, 0, 0],
            width: [0, 0, 0],
        },
        fill: {
            opacity: [1, 1, 1]
        },
        markers: {
            size: [0, 0, 4],
            strokeWidth: 2,
            hover: {
                size: 4,
            }
        },
        xaxis: {
            categories: days,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            },
            labels: {
                style: {
                    fontSize: '12px',
                }
            },
            title: {
                text: `${month} ${year}`,
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold'
                }
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '30%',
                barHeight: '70%'
            }
        },
        colors: lineChartCustomerColors,
        tooltip: {
            shared: true,
            style: {
                fontSize: fontSizeGraph
            },
            y: [{
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }]
        }
    };
    return (
        <>
            <ReactApexChart
                options={options}
                series={cleaningSeries}
                type="line"
                height="400"
                className="apex-charts"
            />
        </>
    );
};

const  ProjectsJournalHaccpMeasuringTemperetureBody = ({dataColors, series}) => {
    const {fontSizeGraph} = useAccessibility();
    let lineChartCustomerColors = getChartColorsArray(dataColors);

    const monthsInRussian = {
        '01': 'Январь',
        '02': 'Февраль',
        '03': 'Март',
        '04': 'Апрель',
        '05': 'Май',
        '06': 'Июнь',
        '07': 'Июль',
        '08': 'Август',
        '09': 'Сентябрь',
        '10': 'Октябрь',
        '11': 'Ноябрь',
        '12': 'Декабрь',
    };

    const groupedData = series.reduce((acc, record) => {
        const date = record.data;
        if (!acc[date]) {
            acc[date] = { healthy: 0, sick: 0 };
        }
        if (record.temperature >= 37) {
            acc[date].sick += 1;
        } else {
            acc[date].healthy += 1;
        }
        return acc;
    }, {});

    const dates = Object.keys(groupedData);
    const days = dates.map(date => moment(date, "DD.MM.YYYY").format("D"));
    const firstDate = dates[0];
    const month = monthsInRussian[moment(firstDate, "DD.MM.YYYY").format("MM")];
    const year = moment(firstDate, "DD.MM.YYYY").format("YYYY");

    const healthyData = dates.map(date => groupedData[date].healthy);
    const sickData = dates.map(date => groupedData[date].sick);

    const dataSeries = [
        {
            name: 'Здоровые',
            type: 'bar',
            data: healthyData
        },
        {
            name: 'Заболевшие',
            type: 'bar',
            data: sickData
        }
    ];

    const options = {
        chart: {
            height: '500px',
            type: 'line',
            toolbar: {
                show: false,
            }
        },
        stroke: {
            curve: 'smooth',
            dashArray: [0, 0, 0],
            width: [0, 0, 0],
        },
        fill: {
            opacity: [1, 1, 1]
        },
        markers: {
            size: [0, 0, 4],
            strokeWidth: 2,
            hover: {
                size: 4,
            }
        },
        xaxis: {
            categories: days,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            },
            labels: {
                style: {
                    fontSize: '12px',
                }
            },
            title: {
                text: `${month} ${year}`,
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold'
                }
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '30%',
                barHeight: '70%'
            }
        },
        colors: lineChartCustomerColors,
        tooltip: {
            shared: true,
            style: {
                fontSize: fontSizeGraph
            },
            y: [{
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }, {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }]
        }
    };
    return (
        <>
            <ReactApexChart
                options={options}
                series={dataSeries}
                type="line"
                height="400"
                className="apex-charts"
            />
        </>
    );
};

const ProjectsJournalPurchaseMaterials = ({series, dataColors}) => {
    let lineChartCustomerColors = getChartColorsArray(dataColors);

    const monthsInRussian = {
        '01': 'Январь',
        '02': 'Февраль',
        '03': 'Март',
        '04': 'Апрель',
        '05': 'Май',
        '06': 'Июнь',
        '07': 'Июль',
        '08': 'Август',
        '09': 'Сентябрь',
        '10': 'Октябрь',
        '11': 'Ноябрь',
        '12': 'Декабрь',
    };

    const uniqueDates = Array.from(new Set(series.map(item => item.data))).sort(
        (a, b) => moment(a, "DD.MM.YYYY") - moment(b, "DD.MM.YYYY")
    );

    const materialsPerDay = uniqueDates.map(date => {
        return series
            .filter(item => item.data === date)
            .reduce((sum, currentItem) => {
                return sum + currentItem.materials.reduce((materialSum, material) => materialSum + material.count, 0);
            }, 0);
    });

    const firstDate = uniqueDates[0];
    const month = monthsInRussian[moment(firstDate, "DD.MM.YYYY").format("MM")];
    const year = moment(firstDate, "DD.MM.YYYY").format("YYYY");

    const chartSeries = [
        {
            name: 'Материалы за день',
            type: 'bar',
            data: materialsPerDay
        }
    ];

    const options = {
        chart: {
            height: '500px',
            type: 'line',
            toolbar: {
                show: false,
            }
        },
        stroke: {
            curve: 'smooth',
            dashArray: [0, 0, 0],
            width: [0, 0, 0],
        },
        fill: {
            opacity: [1, 1, 1]
        },
        markers: {
            size: [0, 0, 4],
            strokeWidth: 2,
            hover: {
                size: 4,
            }
        },
        xaxis: {
            categories: uniqueDates,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            },
            labels: {
                style: {
                    fontSize: '12px',
                }
            },
            title: {
                text: `${month} ${year}`,
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold'
                }
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '30%',
                barHeight: '70%'
            }
        },
        colors: lineChartCustomerColors
    };
    return (
        <>
            <ReactApexChart
                options={options}
                series={chartSeries}
                type="line"
                height="400"
                className="apex-charts"
            />
        </>
    );
};

const ProjectsJournalHaccpAntisepticTableware = ({ dataColors, series }) => {
    const {fontSizeGraph} = useAccessibility();
    let lineChartCustomerColors = getChartColorsArray(dataColors);

    const monthsInRussian = {
        '01': 'Январь',
        '02': 'Февраль',
        '03': 'Март',
        '04': 'Апрель',
        '05': 'Май',
        '06': 'Июнь',
        '07': 'Июль',
        '08': 'Август',
        '09': 'Сентябрь',
        '10': 'Октябрь',
        '11': 'Ноябрь',
        '12': 'Декабрь',
    };

    const uniqueDates = Array.from(new Set(series.map(item => item.data)))
        .sort((a, b) => moment(a, "DD.MM.YYYY") - moment(b, "DD.MM.YYYY"));

    const days = uniqueDates.map(date => moment(date, "DD.MM.YYYY").date());
    const firstDate = uniqueDates[0];
    const month = monthsInRussian[moment(firstDate, "DD.MM.YYYY").format("MM")];
    const year = moment(firstDate, "DD.MM.YYYY").format("YYYY");

    const typeDishes = Array.from(new Set(series.flatMap(item => item.dishes.map(dish => dish.name))))
        .map(name => {
            const dish = series.flatMap(item => item.dishes).find(d => d.name === name);
            return { name, uid: dish.uid };
        });

    const groupedData = uniqueDates.map(date => {
        const itemsForDate = series.filter(item => item.data === date);
        return typeDishes.map(dishType => {
            return itemsForDate.reduce((total, item) => {
                const dish = item.dishes.find(d => d.uid === dishType.uid);
                return total + (dish ? parseInt(dish.units) : 0);
            }, 0);
        });
    });

    const dishSeries = typeDishes.map((dishType, index) => ({
        name: dishType.name,
        type: 'bar',
        data: groupedData.map(dayData => dayData[index]),
    }));

    const options = {
        chart: {
            height: 500,
            type: 'bar',
            stacked: true,
            toolbar: { show: false },
        },
        xaxis: {
            categories: days,
            labels: {
                style: {
                    fontSize: '12px',
                },
            },
            title: {
                text: `${month} ${year}`,
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                },
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            markers: {
                width: 10,
                height: 10,
                radius: 6,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '30%',
            },
        },
        colors: lineChartCustomerColors,
        tooltip: {
            shared: true,
            style: {
                fontSize: fontSizeGraph
            },
            intersect: false,
            y: {
                formatter: function (value) {
                    return value !== undefined ? `${value.toFixed(0)} шт.` : value;
                },
            },
        },
    };

    return (
        <ReactApexChart
            options={options}
            series={dishSeries}
            type="bar"
            height={400}
            className="apex-charts"
        />
    );
};


export {ProjectStatusCharts, ProjectStatusChartsDonutOperationalControl, ProjectsOverviewChartsOperationalControl, ProjectsOverviewCharts, ProjectOverviewChartRegisterDefectEquipment, ProjectOverviewChartsActEquipmentInspectionCertificate, ProjectStatusChartsEmployee,
    ProjectsJournalHaccp, ProjectsJournalHaccpVentilationRoom, ProjectsJournalHaccpAntisepticRoom, ProjectsJournalHaccpMorningFilterChildren, ProjectsJournalHaccpAntisepticSurface, ProjectsJournalHaccpGeneralCleaning, ProjectsJournalHaccpReplacementBedLinen, ProjectsJournalHaccpMorningFilterEmployee, ProjectsJournalPurchaseMaterials, ProjectsJournalHaccpMeasuringTemperetureBody, ProjectsJournalHaccpAntisepticTableware}