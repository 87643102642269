import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import ModalFromMyTask from "../modal/ModalFromMyTask";
import ModalFromMyTaskInfo from "../modal/ModalFromMyTaskInfo";
import TableLoaders from "../../../layouts/SceletonLoaders/TableLoaders";
import FromMyTaskService from "../../../../api/services/documentsFlowService/FromMyTaskService";
import {convertDateLineInDot} from "../../../../functions/ConvertDate";

const TableFromMyTask = () => {

    const [openModalFromMyTask, setOpenModalFromMyTask] = useState<boolean>(false);
    const [openModalFromMyTaskInfo, setOpenModalFromMyTaskInfo] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const [infoMyTask, setInfoMyTask] = useState<any | null>(null);

    useEffect(() => {
        setLoading(true)
        FromMyTaskService.getFromMyTask()
            .then(r => {
                setData(r.data)
                setLoading(false)
            })
    }, []);


    const columns: any = [
        {
            id: "date",
            name: <span className='font-weight-bold accessibility_size_table'>Дата</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{convertDateLineInDot(row.data)}</span>,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Наименование</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.name}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Контрагент</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.partner}</span>,
        },
    ];

    return (
        <>
            {
                loading ?
                    <TableLoaders/>
                    :
                    <Card>
                        <CardHeader>
                            Список задач от меня
                        </CardHeader>
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={data}
                                noDataComponent={'Нет задач от меня'}
                                defaultSortFieldId={"date"}
                                defaultSortAsc={false}
                                pagination
                                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно актов:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        }
                                    }
                                }}
                                onRowClicked={
                                    (row) => {
                                        if(window.innerWidth <= 768){
                                            setInfoMyTask(row)
                                            setOpenModalFromMyTaskInfo(true)
                                        }
                                    }
                                }
                                onRowDoubleClicked={
                                    (row) => {
                                        if(window.innerWidth > 768){
                                            setInfoMyTask(row)
                                            setOpenModalFromMyTaskInfo(true)
                                        }
                                    }
                                }
                            />
                        </CardBody>
                        <CardFooter className='d-flex justify-content-between'>
                            <div className="button_modal mx-1">
                                <button className="btn btn-primary w-100" onClick={() => setOpenModalFromMyTask(true)}>
                                    Создать задачу
                                </button>
                            </div>
                        </CardFooter>
                        {
                            openModalFromMyTask &&
                            <ModalFromMyTask setOpenModalFromMyTask={(value) => setOpenModalFromMyTask(value)} openModalFromMyTask={openModalFromMyTask}/>

                        }
                        {
                            openModalFromMyTaskInfo &&
                            <ModalFromMyTaskInfo infoMyTask={infoMyTask} setOpenModalFromMyTaskInfo={(value) => setOpenModalFromMyTaskInfo(value)} openModalFromMyTaskInfo={openModalFromMyTaskInfo}/>
                        }
                    </Card>
            }
        </>
    );
};

export default TableFromMyTask;
