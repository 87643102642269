import React, {FC, useEffect} from 'react';
import DataTable from "react-data-table-component";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {IAllIPFCFoodRation} from "../../../models/parent/IAllIPFCFoodRation";
import {getAllPFCFoodRation} from "../../../store/reducers/parent/ActionCreator";

interface ITablePfcFoodRation {
    date: Date
}
const TableAllPfcFoodRation: FC<ITablePfcFoodRation> = ({date}) => {

    const dispatch = useAppDispatch()
    const {child, allPfcFoodRation} = useAppSelector(state => state.ParentReducer)
    useEffect(() => {
        dispatch(getAllPFCFoodRation(date, child)).then()
    }, [child, date, dispatch]);


    const columnsPFC: any = [
        {
            name: <span className='font-weight-bold accessibility_size_table'>Прием пищи</span>,
            selector: (row: IAllIPFCFoodRation, i: number) => {
                if (i === allPfcFoodRation.length - 1)
                    return <span className="accessibility_size_table" style={{fontWeight: 700}}>Итого</span>

                return <span className="accessibility_size_table">{row.time}</span>
            },
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Вес(гр.)</span>,
            selector: (row: IAllIPFCFoodRation, i:number) => {
                if (i === allPfcFoodRation.length - 1)
                    return <span style={{fontWeight: 700}} className="accessibility_size_table">{allPfcFoodRation.map(i => i.weight).reduce((a, b) => a + b)}</span>
                return row.weight.toFixed(2)
            },
            center: true
        }
    ];


    return (
        <DataTable
            columns={columnsPFC}
            data={allPfcFoodRation}
            noDataComponent={'Нет данных'}
            highlightOnHover
            customStyles={{
                rows: {
                    highlightOnHoverStyle: {
                        backgroundColor: "#ffebb5"
                    }
                }
            }}
        />
    );
};

export default TableAllPfcFoodRation;
