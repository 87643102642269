import React from "react";
import {ButtonGroup, Col, Row} from "reactstrap";
import { useAccessibility } from "../../../context/AccessibilityContext";

const AccessibilityPanel: React.FC = () => {
    const { fontSize, setFontSize, imagesVisible, toggleImages, kerning, setKerning, toggleGrayscale,  grayscaleMode} =
        useAccessibility();

    return (
        <div className="accessibility-panel rounded-3">
            <Row className="ms-3">
                <Col sm={12} md={6} xl={3}>
                    <label>Шрифт:
                        <ButtonGroup className="mt-1 ms-1">
                            <button onClick={() => setFontSize(90)} className={`btn btn-primary fs-5 ${fontSize === 90 ? 'active' : ''}`}>-<i className=" bx bx-font-size"></i></button>
                            <button onClick={() => setFontSize(100)} className={`btn btn-primary fs-5 ${fontSize === 100 ? 'active' : ''}`}><i className=" bx bx-font-size"></i></button>
                            <button onClick={() => setFontSize(110)} className={`btn btn-primary fs-5 ${fontSize === 110 ? 'active' : ''}`}>+<i className=" bx bx-font-size"></i></button>
                        </ButtonGroup>

                    </label>
                </Col>
                <Col sm={12} md={6} xl={3}>
                    <label>Кернинг:
                        <ButtonGroup className="mt-1 ms-1">
                            <button onClick={() => setKerning(1)} className={`btn btn-primary fs-5 ${kerning === 1 ? 'active' : ''}`}>1x</button>
                            <button onClick={() => setKerning(2)} className={`btn btn-primary fs-5 ${kerning === 2 ? 'active' : ''}`}>2x</button>
                            <button onClick={() => setKerning(3)} className={`btn btn-primary fs-5 ${kerning === 3 ? 'active' : ''}`}>3x</button>
                        </ButtonGroup>
                    </label>
                </Col>
                <Col className="accessibility-option" sm={12} md={6} xl={2}>
                    <label>Цвет:
                        <button onClick={toggleGrayscale} className={`btn btn-primary mt-1 ms-1 fs-5 ${grayscaleMode ? 'active' : ''}`}>
                            <i className=" bx bx-adjust"></i>
                        </button>
                    </label>
                </Col>
                <Col sm={12} md={6} xl={3}>
                    <label>Изображения:
                        <button onClick={toggleImages} className={`btn btn-primary mt-1 ms-1 fs-5 ${imagesVisible ? 'active' : ''}`}>
                            <i className={`bx ${imagesVisible ? 'bx-block' : 'bx-image-alt' }`}></i>
                        </button>
                    </label>
                </Col>
            </Row>
        </div>
    );
};

export default AccessibilityPanel;
