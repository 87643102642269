import React, {useEffect, useState} from 'react';
import {
    setIsSend, setModalAdd,
    setModalAddRegisterDefectEquipment,
} from "../../../store/reducers/widgets/WidgetsSlice";
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {Field, Form, Formik} from "formik";
import Select from "react-select";
import WidgetService from "../../../api/services/widgetService/WidgetService";
import {convertDateTimeFull1C} from "../../../functions/ConvertDate";
import AxiosClient from "../../../api/AxiosClient";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {useEscapeKey} from "../../../hooks/useEscapeKey";

const ModalAddRegisterDefectEquipment = () => {
    const dispatch = useAppDispatch();
    const {modalAddRegisterDefectEquipment, date} = useAppSelector(state => state.WidgetReducer)
    const [dateTime, setDateTime] = useState(new Date());
    const [initiator, setInitiator] = useState<any[]>([]);
    const [warehouses, setWarehouses] = useState<any[]>([]);
    const [repairTeam, setRepairTeam] = useState<any[]>([]);
    const [selectWarehouse, setSelectWarehouse] = useState<any>({value:'', label:''});
    const [objectRepair, setObjectRepair] = useState<any[]>([]);
    const [typeDefect, setTypeDefect] = useState<any[]>([]);
    const [criticalDefect, setCriticalDefect] = useState<any[]>([]);
    const [defectCauses, setDefectCauses] = useState<any[]>([]);
    const [organizations, setOrganizations] = useState<any[]>([]);
    const [selectOrg, setSelectOrg] = useState<any | null>(null);

    useEffect(() => {
        WidgetService.getListTypeDefect().then(r => setTypeDefect(r.data))
        WidgetService.getListCriticalDefect().then(r => setCriticalDefect(r.data))
        WidgetService.getListDefectCauses().then(r => setDefectCauses(r.data))
        WidgetService.getInfoOrganization().then(r => {
            setOrganizations(r.data)
            setSelectOrg({value: r.data[0]?.uid, label: r.data[0]?.name})
        })
    }, []);

    useEffect(() => {
        WidgetService.getListPerson(date).then(r => setInitiator(r.data))
        WidgetService.getListObjectWarehouse(date).then(r => {
            let dataWarehouses: any = r.data;
            setWarehouses(dataWarehouses)
            setSelectWarehouse({value: dataWarehouses[0]?.uid, label: dataWarehouses[0]?.name})
        })
        WidgetService.getListRepairTeam(date).then(r => setRepairTeam(r.data))
    }, [date]);

    useEffect(() => {
        selectWarehouse?.value &&
            WidgetService.getListObjectRepair(dateTime, selectWarehouse.value).then(r => setObjectRepair(r.data))
    }, [selectWarehouse, dateTime]);

    const customStyles = {
        control: (ds: any) => ({
            ...ds,
            backgroundColor: "#fff",
        }),
        option: (ds: any, state: any) => ({
            ...ds,
            backgroundColor: state.isFocused ? "#4b38b3" : "#fff",
            color: state.isFocused ? "#fff" : "#000"
        })
    }
    useEscapeKey(() => dispatch(setModalAdd(false)), modalAddRegisterDefectEquipment, "modalAddRegisterDefectEquipment");

    return (
        <Modal id="modalAddRegisterDefectEquipment" isOpen={modalAddRegisterDefectEquipment}
               toggle={() => dispatch(setModalAddRegisterDefectEquipment(!modalAddRegisterDefectEquipment))}
               modalClassName="zoomIn" tabIndex={-1} centered size='lg'>
            <ModalHeader toggle={() => dispatch(setModalAddRegisterDefectEquipment(!modalAddRegisterDefectEquipment))}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавить заявку диспетчерской службы
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            organization: '1',
                            user: initiator[0]?.uid || '',
                            warehouse: warehouses[0]?.uid || '',
                            repair_service: repairTeam[0]?.uid || '',
                            element: objectRepair.length > 0 ? objectRepair[0].uid : '',
                            defect: typeDefect[0]?.uid || '',
                            status: criticalDefect[0]?.uid || '',
                            cause: defectCauses[0]?.uid || '',
                            description: '',
                            phone: ''
                        }
                    }
                    onSubmit={(values) => {
                        const data = {
                            nomer: '',
                            org_uid: selectOrg ? selectOrg.value : '',
                            date: convertDateTimeFull1C(dateTime),
                            sklad_id: selectWarehouse.value,
                            repair_obj_id: values.element,
                            repair_id: objectRepair.find(obj => obj.uid === values.element).os_uid,
                            person_id: values.user,
                            repair_podr_id: values.repair_service,
                            vid_defect_id: values.defect,
                            defect_criticality_id: values.status,
                            defect_causes_id: values.cause,
                            defect_opisanie: values.description,
                            phone: values.phone
                        }
                        dispatch(setIsSend(true));
                        AxiosClient.post('/save_dispatch_service_request', {doc_id: '', data: data})
                            .then(() => {
                                dispatch(setModalAddRegisterDefectEquipment(false));
                                dispatch(setIsSend(false));
                            })
                            .catch(() => dispatch(setIsSend(false)))
                    }}>
                    {({ values, setFieldValue, handleChange }) => (
                        <Form>
                            <Row>
                                <Col className="mb-3">
                                    <label>Дата:</label>
                                    <div className="col">
                                        <div className="input-group">
                                            <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                value={dateTime}
                                                options={{
                                                    locale: Russian,
                                                    mode: "single",
                                                    dateFormat: "d.m.Y H:i",
                                                    disableMobile: true
                                                }}
                                                onChange={(date) => setDateTime(date[0])}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mb-3">
                                    <label>Организация:</label>
                                    <Select options={organizations.map(org => ({value: org.uid, label: org.name}))}
                                            name="organization"
                                            value={selectOrg}
                                            onChange={(change: any) => {
                                                setSelectOrg({value: change.value, label: change.label})
                                            }}
                                            styles={customStyles}
                                            placeholder="Поиск"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" md="6" className="mb-3">
                                    <label className="w-100">Инициатор:
                                    <Select options={initiator.map(org => ({value: org.uid, label: org.FIO}))}
                                            name="user"
                                            // value={initiator.find(i => i.uid === values.user).uid}
                                            onChange={(change: any) => handleChange('user')(change.value.toString())}
                                            styles={customStyles}
                                            placeholder="Поиск"
                                    /></label>
                                </Col>
                                <Col xs="12" md="6" className="mb-3">
                                    <label>Номер телефона:</label>
                                    <Field
                                        className="form-control"
                                        name="phone"
                                        placeholder="Введите номер телефона"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mb-3">
                                    <label className="w-100">Где находится оборудование:
                                        <Select options={warehouses.map(warehouse => ({value: warehouse.uid, label: warehouse.name}))}
                                                name="warehouse"
                                                value={selectWarehouse}
                                                onChange={(change: any) => {
                                                    setSelectWarehouse({value: change.value, label: change.label})
                                                }}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                        />
                                    </label>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mb-3">
                                    <label>Ремонтная служба:</label>
                                    <Field as="select" className="form-select" name="repair_service">
                                        {
                                            repairTeam.map((repair) => (
                                                <option key={repair.uid}
                                                        value={repair.uid}>{repair.name}</option>
                                            ))
                                        }
                                    </Field>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" md="6" className="mb-3">
                                    <label>Отказавшее оборудование:</label>
                                    <Select options={objectRepair.map(object => ({value: object.uid, label: object.repair_name}))}
                                            name="element"
                                            onChange={(change: any) => handleChange('element')(change.value)}
                                            styles={customStyles}
                                            placeholder="Поиск"
                                    />
                                </Col>
                                <Col xs="12" md="6" className="mb-3">
                                    <label>Вид дефекта:</label>
                                    <Select options={typeDefect.map(defect => ({value: defect.uid, label: defect.name}))}
                                            name="defect"
                                            onChange={(change: any) => handleChange('defect')(change.value)}
                                            styles={customStyles}
                                            placeholder="Поиск"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" md="6" className="mb-3">
                                    <label>Критичность дефекта:</label>
                                    <Select options={criticalDefect.map(defect => ({value: defect.uid, label: defect.name}))}
                                            name="status"
                                            onChange={(change: any) => handleChange('status')(change.value)}
                                            styles={customStyles}
                                            placeholder="Поиск"
                                    />
                                </Col>
                                <Col xs="12" md="6" className="mb-3">
                                    <label>Причина дефекта:</label>
                                    <Select options={defectCauses.map(defect => ({value: defect.uid, label: defect.name}))}
                                            name="cause"
                                            onChange={(change: any) => handleChange('cause')(change.value)}
                                            styles={customStyles}
                                            placeholder="Поиск"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mb-3">
                                    <label>Описание дефекта:</label>
                                    <div className="input-group">
                                        <Field as="textarea" className="form-control" name="description"/>
                                    </div>
                                </Col>
                            </Row>
                            <div className="hstack gap-2 justify-content-end">
                                <SubmitWithLoading text={"Сохранить"}/>
                            </div>
                        </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddRegisterDefectEquipment;
