import React, {FC, useEffect, useState} from 'react';
import AxiosClient from "../../../api/AxiosClient";
import {Alert, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {string} from "yup";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../hooks/redux";

interface IModalTransferMoneyPersonalAccounts {
    openTransferMoney: boolean,
    setOpenTransferMoney: (value: boolean) => void,
    selectChildren: string
}

const ModalTransferMoneyPersonalAccounts: FC<IModalTransferMoneyPersonalAccounts> = ({
                                                                                         setOpenTransferMoney,
                                                                                         openTransferMoney,
                                                                                         selectChildren
                                                                                     }) => {
    const [personalAccounts, setPersonalAccounts] = useState<any[]>([]);
    const [error, setError] = useState('');
    useEffect(() => {
        AxiosClient.get('/students/pesonal_accounts', {params: {id: selectChildren}})
            .then(r => {
                setPersonalAccounts(r.data)
            })
            .catch(() => {
                console.log('error')
            })
    }, [selectChildren]);

    const dispatch = useAppDispatch();
    useEscapeKey(() => dispatch(setModalAdd(false)), openTransferMoney, "openTransferMoney");


    return (
        <Modal id="openTransferMoney" isOpen={openTransferMoney}
               toggle={() => setOpenTransferMoney(!openTransferMoney)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setOpenTransferMoney(!openTransferMoney)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Перевод денежных средств
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={
                        {
                            from: '',
                            to: '',
                            close: true
                        }
                    }
                    validationSchema={Yup.object().shape({
                        from: Yup.string().required('Обязательное поле'),
                        to: Yup.string()
                            .when('from', {
                                is: (val: any) => val !== '',
                                then: (schema) => schema.notOneOf([Yup.ref('from')], 'Счета не должны совпадать')
                            })
                            .required('Обязательное поле'),
                    })}
                    onSubmit={(values) => {
                        AxiosClient.post('/students/personal_accounts/transfer_money', {from: values.from, to: values.to, close: values.close, studId: selectChildren})
                            .then(() => {
                                setOpenTransferMoney(false)
                            })
                            .catch((reason) => {
                                setError(reason.response.data)
                            })
                    }}>
                    {
                        ({errors, touched}) => (
                            <Form>
                                {
                                    error &&
                                    <Alert color="danger">{error}</Alert>
                                }
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">С какого:
                                        <Field name="from" as="select"
                                               className={`form-select ${errors.from && touched.from && "border border-2 border-danger"}`}>
                                            <option value="">Выберите лиц. счет</option>
                                            {
                                                personalAccounts.map(personalAccount => (
                                                    <option key={personalAccount.id}
                                                            value={personalAccount.id}>{personalAccount.personal_account} (Баланс: {personalAccount.balance})</option>))
                                            }
                                        </Field>
                                        </label>
                                        <ErrorMessage name="from">{msg => <div className="text-danger">{msg}</div>}</ErrorMessage>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">На какой:
                                        <Field name="to" as="select"
                                               className={`form-select ${errors.to && touched.to && "border border-2 border-danger"}`}>
                                            <option value="">Выберите лиц. счет</option>
                                            {
                                                personalAccounts.map(personalAccount => (
                                                    <option key={personalAccount.id}
                                                            value={personalAccount.id}>{personalAccount.personal_account} (Баланс: {personalAccount.balance})</option>))
                                            }
                                        </Field>
                                        </label>
                                        <ErrorMessage name="to">{msg => <div className="text-danger">{msg}</div>}</ErrorMessage>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="d-flex align-items-center">
                                            <Field name="close" type="checkbox" className="form-check-input fs-18 m-0"/>
                                            <p className="m-0 mx-2">
                                                Закрыть счет с которого перевод
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                                <button type="submit" className="btn btn-primary mt-2" id="addNewProject">Добавить
                                </button>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalTransferMoneyPersonalAccounts;
