import React, {FC, useState} from 'react';
import DataTable, {ConditionalStyles} from "react-data-table-component";
import {Card, CardBody, CardFooter, Col, Row, Spinner} from "reactstrap";
import AxiosClient from "../../../api/AxiosClient";
import ListPersonalAccountForExport from "../Students/ListPersonalAccountForExport";
import {setIsSend} from "../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {useAppDispatch} from "../../../hooks/redux";

interface ITableConfirmationExportStudents {
    schoolId: string,
    buildingId: string,
    classId: string,
    students: any[],
    totalRowsStudents: number,
    setStudents: (value: any[]) => void,
    groupBy: string,
}

const TableConfirmationExportStudents:FC<ITableConfirmationExportStudents> = ({groupBy ,setStudents, students, totalRowsStudents, schoolId, buildingId, classId}) => {
    const [perPage, setPerPage] = useState(100);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState(false);
    const dispatch = useAppDispatch();
    const conditionalRowStyles: ConditionalStyles<any>[] = [
        {
            when: (row: any) => !row.personal_accounts.find((pa: any) => pa.date_end === null),
            style: {
                color: 'gray'
            },
        },
    ]


    const columns: any = [
        {
            id: "date",
            name: <span className='font-weight-bold accessibility_size_table'>Класс</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.name}</span>,
            wrap: true
        },
    ]

    const fetchStudents = async (page: number, size = perPage, schoolId = '', buildingId = '', classId = '') => {
        setSearch(true)
        await AxiosClient.get(`/students/export`, {params: {page: page, per_page: size, schoolId, buildingId, classId, groupBy}})
            .then((response) => {
                setSearch(false)
                setStudents(response.data.students);
            })
            .catch(() => {
                console.log('error')
                setSearch(false)
            });

    };

    const handlePageChange = (page: number) => {
        fetchStudents(page, perPage, schoolId, buildingId, classId);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        await fetchStudents(page, newPerPage, schoolId, buildingId, classId);
        setPerPage(newPerPage);
    };

    const getFile = () => {
        const params = {
            schoolId,
            buildingId,
            classId,
            groupBy
        }
        dispatch(setIsSend(true))
        AxiosClient.get('/students/export/file', {responseType: 'blob', params})
            .then(response => {
                dispatch(setIsSend(false))
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Ученики(без группировки).xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(() => {
                console.log('error')
                dispatch(setIsSend(false))
            })
    }

    return (
        <>
            <Row>
                <Col>
                    <Card className="mt-3">
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={students}
                                noDataComponent={'Нет данных'}
                                progressComponent={<Spinner></Spinner>}
                                progressPending={search}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRowsStudents}
                                paginationDefaultPage={currentPage}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                paginationRowsPerPageOptions={[100, 500, 1000]}
                                paginationPerPage={100}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно учеников:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        },
                                    },
                                    expanderRow: {
                                        style: {
                                            backgroundColor: "#ffebb5",
                                            paddingTop: '5px',
                                            paddingBottom: '5px'
                                        },
                                    },
                                }}
                                conditionalRowStyles={conditionalRowStyles}
                                expandableRows
                                expandOnRowClicked
                                expandableRowsComponent={(row) => {
                                    return <ListPersonalAccountForExport
                                        setStudents={value => {}}
                                        listPersonalAccount={row.data.personal_accounts.map((v: any) => ({
                                            ...v,
                                            name: v.student.name,
                                            last_name: v.student.last_name,
                                            middle_name: v.student.middle_name,
                                            type_menu_id: v.student.type_menu_id
                                        }))}/>
                                }}
                            />
                        </CardBody>
                        <CardFooter>
                            <SubmitWithLoading  onClick={() => getFile()} text="Скачать отчет" />
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default TableConfirmationExportStudents;
