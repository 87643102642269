import React, {FC, useState} from 'react';
import {useAppDispatch} from "../../../hooks/redux";
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import {setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import AxiosClient from "../../../api/AxiosClient";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import Notification from "../../widgets/Notification";
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface ModalUpdateAgeCategoryProps {
    updateAgeCategory: boolean
    setUpdateAgeCategory: (value: boolean) => void
    setAgeCategories: (value: any[]) => void
    selectAgeCategory: any
}
const ModalUpdateAgeCategory: FC<ModalUpdateAgeCategoryProps> = ({setUpdateAgeCategory, updateAgeCategory, setAgeCategories, selectAgeCategory}) => {
    const dispatch = useAppDispatch();
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<string>('');
    const [notificationMessage, setNotificationMessage] = useState<string>('');
    useEscapeKey(() => dispatch(setModalAdd(false)), updateAgeCategory, "ModalUpdateAgeCategory");

    return (
        <>
            <Modal id="ModalUpdateAgeCategory" isOpen={updateAgeCategory}
                   toggle={() => setUpdateAgeCategory(!updateAgeCategory)}
                   modalClassName="zoomIn" tabIndex={-1} centered>
                <ModalHeader toggle={() => setUpdateAgeCategory(!updateAgeCategory)}
                             className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Добавление возрастной категории
                </ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={{name: selectAgeCategory?.name}}
                        onSubmit={(values) => {
                            dispatch(setIsSend(true))
                            AxiosClient.post(`/age_categories/update/${selectAgeCategory.id}`, {name: values.name})
                                .then(() => {
                                    dispatch(setIsSend(false))
                                    AxiosClient.get('/age_categories')
                                        .then(r => {
                                            setAgeCategories(r.data)
                                        })
                                    setUpdateAgeCategory(false)
                                    setNotificationType('success');
                                    setNotificationMessage('Возрастная категория обновлена');
                                    setShowNotification(true);
                                })
                                .catch(() => {
                                    dispatch(setIsSend(false))
                                    setNotificationType('error');
                                    setNotificationMessage('Ошибка при обновлении возрастной категории');
                                    setShowNotification(true);
                                })
                        }}
                    >
                        {
                            ({errors, touched}) => (
                                <Form>
                                    <Row className={"mb-2"}>
                                        <Col>
                                            <label className="w-100">Наименование:
                                            <Field name="name"
                                                   className={`form-control ${errors.name && touched.name && "border border-2 border-danger"}`}/>
                                            </label>
                                        </Col>
                                    </Row>
                                    <SubmitWithLoading text={'Обновить'}/>
                                </Form>
                            )
                        }
                    </Formik>
                </ModalBody>
            </Modal>
            {showNotification && <Notification type={notificationType} message={notificationMessage} setShowNotification={(value: boolean) => setShowNotification(value)}/>}
        </>
    );
};

export default ModalUpdateAgeCategory;
