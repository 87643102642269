import React, {FC, useEffect, useState} from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import {useAppSelector} from "../../hooks/redux";
import AxiosClient from "../../api/AxiosClient";

interface IThemesWeek{
    date: Date
}
const ThemesWeek: FC<IThemesWeek> = ({date}) => {
    const {children, child} = useAppSelector(state => state.ParentReducer)
    const [themesWeek, setThemesWeek] = useState<any[]>([]);

    useEffect(() => {
        if(child){
            AxiosClient.get('/get_calendar_theme_week', {params: {date, child}})
                .then(r => setThemesWeek(r.data))
                .catch((reason) => reason)
        }
    }, [date, child]);

    return (
        <Card>
            <CardHeader>
                <h4 className="card-title mb-0 flex-grow-1">Темы недели</h4>
            </CardHeader>
            <CardBody>
                {themesWeek.map((theme: any, index: number) => (
                    <div className="mt-1">
                        {theme.thema} <br />
                    </div>
                ))}
            </CardBody>
        </Card>
    );
};

export default ThemesWeek;
