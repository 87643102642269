import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    setDateAdd, setGroupAllDeviations, setGroupModalAdd,
    setIsSend,
    setModalAdd, setShowNotification,
    WidgetsSlice
} from "../../../store/reducers/widgets/WidgetsSlice";
import {Col, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import moment from "moment/moment";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import AxiosClient from "../../../api/AxiosClient";
import {getJournalHaccpBactericidalLamps} from "../../../store/reducers/widgets/ActionCreator";
import classNames from "classnames";
import * as Yup from "yup";
import ModalConfirmExitHaccp from "../ModalConfirmExitHaccp";
import {useModalWithConfirm} from "../../../hooks/useModalWithConfirm";
import UpdateDesinfectionGeneralCleaning from "../GeneralCleaning/UpdateDesinfectionGeneralCleaning";
import UpdateLamps from "./UpdateLamps";

interface IModalAddBactericidalLamps {
    updateData: any
}

const ModalAddJournalBactericidalLamps: FC<IModalAddBactericidalLamps> = ({updateData}) => {
    const dispatch = useAppDispatch();
    const {
        modalAdd,
        groupAllDeviations,
        date,
        groupModalAdd,
        groups,
        dateAdd,
        successUpdate,
        journalHaccpBactericidalLamps
    } = useAppSelector(state => state.WidgetReducer);
    const [activeTab, setActiveTab] = useState("1");

    const [uidGroup, setUidGroup] = useState("");
    const [addLamp, setAddLamp] = useState<any>();

    const {
        isFormChange,
        showConfirmExit,
        handleClose,
        handleFormChange,
        handleConfirmClose,
        handleStay,
    } = useModalWithConfirm("addReportJournalVentilationRoom");

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(WidgetsSlice.actions.successUpdate(""))
        }, 5000)
        return () => clearTimeout(timer)
    }, [successUpdate, dispatch]);

    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        if (modalAdd && !updateData) {
            dispatch(setDateAdd(new Date()));
        }
    }, [modalAdd]);

    return (
        <>
            <Modal id="ModalAddMorningFilterChildren" isOpen={modalAdd}  toggle={handleClose}
                   modalClassName="zoomIn" tabIndex={-1} centered>
                <ModalHeader  toggle={handleClose} className="p-3 bg-soft-primary"
                              id="createProjectModalLabel">
                    Добавление бактерицидных ламп
                </ModalHeader>
                <ModalBody>

                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            date: moment(dateAdd).format("DD.MM.YYYY"),
                            lamps: addLamp,
                        }}
                        validationSchema={
                            Yup.object({
                                linen: Yup.array().min(1, '')
                            })}
                        onSubmit={(value) => {
                            // dispatch(setIsSend(true));

                            let prop = {
                                uid: updateData ? updateData.uid : uidGroup !== '' ? uidGroup : '',
                                data: moment(dateAdd).format('DD.MM.YYYY'),
                                group_uid: groupAllDeviations,
                                lamps: addLamp,
                            };

                        }}
                    >
                        {({errors, touched, setFieldValue}) => (
                            <Form onChange={handleFormChange}>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                     role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "1", "p-2": true})}
                                            onClick={() => {
                                                tabChange("1");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Основное
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "2", "p-2": true})}
                                            onClick={() => {
                                                tabChange("2");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Ресурс работы
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="mt-2">
                                    <TabPane tabId="1" className="align-items-center">
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">Дата:
                                                    <Flatpickr
                                                        className="form-control border-0 dash-filter-picker shadow"
                                                        value={dateAdd}
                                                        options={{
                                                            locale: Russian, dateFormat: "d M Y", disableMobile: true, mode: 'single'
                                                        }}
                                                        onChange={(date) => {
                                                            dispatch(setDateAdd(moment(date[0]).toDate()))
                                                        }}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <label className="w-100">Группа:
                                                <Field as="select" className="form-select shadow" value={groupModalAdd}
                                                       onChange={(e: any) => {dispatch(setGroupAllDeviations(e.target.value))
                                                           dispatch(setGroupModalAdd(e.target.value))}} name="group">
                                                    {groups.map((group) => {
                                                        return <option key={group.group_id}
                                                                       value={group.group_id}>{group.group_name}</option>
                                                    })}
                                                </Field>
                                            </label>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">Название лампы:
                                                    <Field name="name" className={`form-control`}/>
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className='w-100'>Основные средства:
                                                    <Field as="select" className="form-select shadow" name="resources_uid">
                                                        <option value="">Не выбрано</option>
                                                        {/*{disinfectants.map(el => (*/}
                                                        {/*    <option key={el.uid} value={el.uid}>{el.name}</option>*/}
                                                        {/*))}*/}
                                                    </Field>
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">Сертификат:
                                                    <Field name="сertificate" className={`form-control`}/>
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                        <Col>
                                            <label className='w-100'>Дата подключения:
                                                <Flatpickr
                                                    className="form-control border-0 dash-filter-picker shadow"
                                                    // value={dateAdd}
                                                    options={{
                                                        locale: Russian, dateFormat: "d M Y", disableMobile: true, mode: 'single'
                                                    }}
                                                    onChange={(date) => {

                                                    }}
                                                    name="dateConnection"
                                                />
                                            </label>
                                        </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">Ресурс работы лампы:
                                                    <Field name="resource_work" className={`form-control`}/>
                                                </label>
                                            </Col>
                                            <Col>
                                                <label className="w-100">Плановый ресурс на месяц:
                                                    <Field name="resource_plan" className={`form-control`}/>
                                                </label>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2" className="align-items-center">
                                        <p>...</p>
                                        <UpdateLamps addLamp={addLamp} setAddLamp={(value:any) => setAddLamp(value)}/>
                                    </TabPane>
                                </TabContent>
                                {/*{errors.deti && (*/}
                                {/*    <div style={{color: 'red', marginTop: '5px'}}>Требуется добавить данные о детях!</div>*/}
                                {/*)}*/}
                                <div className="hstack gap-2 justify-content-end mt-1">
                                    <SubmitWithLoading text={"Сохранить"}/>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
            <ModalConfirmExitHaccp
                isOpen={showConfirmExit}
                onClose={handleConfirmClose}
                onStay={handleStay}
                hasUnsavedChanges={isFormChange}
            />
        </>
    );
};

export default ModalAddJournalBactericidalLamps;
