import React, {FC, useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import {Card, CardBody, CardHeader} from "reactstrap";
import AxiosClient from "../../../api/AxiosClient";
import moment from "moment";

interface WidgetTableScoreEmployeeProps {
    date: Date
}
const WidgetTableScoreEmployee: FC<WidgetTableScoreEmployeeProps> = ({date}) => {

    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        AxiosClient.get('/get_static_employee', {params: {date: [moment(date).startOf('month').toDate(), moment(date).endOf('month').toDate()]}})
            .then(r => setData(r.data))
    }, [date]);

    const columns = [
        {
            name: <span className='font-weight-bold accessibility_size_table'>Наименование</span>,
            selector: (row: any) => row.name,
            cell: (row: any) => <span className='accessibility_size_table'>{row.name}</span>,
            sortable: true,
            wrap: true,
            width: "60%"
        },
        {
            id: "count",
            name: <span className='font-weight-bold accessibility_size_table'>Баллы</span>,
            selector: (row: any) => row.count,
            cell: (row: any) => <span className='accessibility_size_table'>{row.count}</span>,
            sortable: true,
            center: true,
            width: "40%"
        }
    ];


    return (
        <Card>
            <CardHeader>
                Cтатистика сотрудника
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={data}
                    noDataComponent={'Нет данных'}
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default WidgetTableScoreEmployee;
