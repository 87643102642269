import React, {useEffect, useState} from 'react';
import { Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getDailyReportSleep} from "../../../store/reducers/widgets/ActionCreator";
import {setDateAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {convertDateFull} from "../../../functions/ConvertDate";
import ModalAddDayliReportSleep from "../modal/ModalAddDayliReportSleep";
import {IAllDailyReportsSleep} from "../../../models/widgets/IAllDailyReportsSleep";
import ModalDeleteDayliReportSleep from "../modal/ModalDeleteDayliReportSleep";
import moment from "moment";
import {IAllDailyReportsMood} from "../../../models/widgets/IAllDailyReportsMood";

const WidgetTableDayliReportSleep = () => {

    const dispatch = useAppDispatch();

    const {groupAllDeviations, date, allDailyReportSleep} = useAppSelector(state => state.WidgetReducer)

    const [modalAdd, setModalAdd] = useState<boolean>(false);
    const [modalDelete, setModalDelete] = useState<boolean>(false);

    useEffect(() => {
        groupAllDeviations &&
        dispatch(getDailyReportSleep(date, groupAllDeviations)).then()
    }, [date, dispatch, groupAllDeviations])


    const columns = [
        {
            id: 'date',
            name: <span className='font-weight-bold accessibility_size_table'>Дата</span>,
            selector: (row: IAllDailyReportsSleep) => row.date,
            cell: (row: IAllDailyReportsSleep) => <span className='accessibility_size_table'>{row.date}</span>,
            sortable: true,
            width: '120px'
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Спал</span>,
            selector: (row: IAllDailyReportsSleep) => row.sleep,
            cell: (row: IAllDailyReportsSleep) => <span className='accessibility_size_table'>{row.sleep}</span>,
            sortable: true,
            center: true,
            width: "27%"
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Частично спал</span>,
            selector: (row: IAllDailyReportsSleep) => row.sleep_middle,
            cell: (row: IAllDailyReportsSleep) => <span className='accessibility_size_table'>{row.sleep_middle}</span>,
            sortable: true,
            center: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Не спал</span>,
            selector: (row: IAllDailyReportsSleep) => row.not_sleep,
            cell: (row: IAllDailyReportsSleep) => <span className='accessibility_size_table'>{row.not_sleep}</span>,
            sortable: true,
            center: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Не был</span>,
            selector: (row: IAllDailyReportsSleep) => row.null_sleep,
            cell: (row: IAllDailyReportsSleep) => <span className='accessibility_size_table'>{row.null_sleep}</span>,
            sortable: true,
            center: true,
        }
    ];

    return (
        <>
            <Card>
                <CardHeader>
                    Отчет по сну ребенка
                </CardHeader>
                <CardBody>
                    <DataTable
                        columns={columns}
                        data={allDailyReportSleep}
                        noDataComponent={'Нет данных'}
                        highlightOnHover
                        pagination
                        paginationRowsPerPageOptions={[6]}
                        paginationComponentOptions={
                            {
                                rowsPerPageText: 'Видно отчетов:',
                                rangeSeparatorText: 'из'
                            }
                        }
                        paginationPerPage={6}
                        defaultSortFieldId={'date'}
                        defaultSortAsc={false}
                        pointerOnHover
                        customStyles={{
                            rows: {
                                highlightOnHoverStyle: {
                                    backgroundColor: "#ffebb5"
                                }
                            }
                        }}
                        onRowClicked={
                            (row) => {
                                if(window.innerWidth <= 768){
                                    dispatch(setDateAdd(moment(row.date, "DD.MM.YYYY").toDate()));
                                    setModalAdd(true)
                                }
                            }
                        }
                        onRowDoubleClicked={
                            (row) => {
                                if(window.innerWidth > 768){
                                    dispatch(setDateAdd(moment(row.date, "DD.MM.YYYY").toDate()));
                                    setModalAdd(true)
                                }
                            }
                        }
                    />
                </CardBody>
                <CardFooter className='d-flex gap-2'>
                    <div>
                        <button className="btn btn-primary" onClick={() => setModalAdd(true)}>Добавить данные</button>
                    </div>
                    <div>
                        <button className="btn btn-primary" onClick={() => setModalDelete(true)}>Удалить</button>
                    </div>
                </CardFooter>
            </Card>
            {
                modalDelete &&
                <ModalDeleteDayliReportSleep modalDelete={modalDelete} setModalDelete={(value) => setModalDelete(value)}/>
            }
            {
                modalAdd &&
                <ModalAddDayliReportSleep modalAdd={modalAdd} setModalAdd={(modalAdd) => setModalAdd(modalAdd)}/>
            }
        </>
    );
};

export default WidgetTableDayliReportSleep;
