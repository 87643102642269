import React, {FC, useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import ModalAddLevelEvolutionChildren from "../ModalAddLevelEvolutionChildren";
import {Card, CardBody, CardFooter} from "reactstrap";
import AxiosClient from "../../../../api/AxiosClient";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import moment from "moment";
import ModalUpdateEvolutionChildren from "../ModalUpdateEvolutionChildren";
import {ToastContainer} from "react-toastify";
import {setDateAdd} from "../../../../store/reducers/widgets/WidgetsSlice";
interface ListLevelEvolutionChildrenProps {
    date: Date
}
const ListLevelEvolutionChildren: FC<ListLevelEvolutionChildrenProps> = ({date}) => {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [data, setData] = useState<any | null>(null);
    const {groupAllDeviations} = useAppSelector(state => state.WidgetReducer);

    const [listLevelEvolutionChildren, setListLevelEvolutionChildren] = useState<any[]>([]);

    useEffect(() => {
        AxiosClient.get('/list_level_evolution_children', {params:
                {
                    date: [moment(date).startOf('month').format('DD.MM.YYYY'), moment(date).endOf('month').format('DD.MM.YYYY')],
                    uid_group: groupAllDeviations
                }
        })
            .then(r => setListLevelEvolutionChildren([].concat(...r.data)))
        setListLevelEvolutionChildren(listLevelEvolutionChildren.filter((el: any) => el.group_uid === groupAllDeviations))
    }, [groupAllDeviations, date]);

    const columns: any = [
        {
            name: <span className='font-weight-bold accessibility_size_table'>Дата</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.data}</span>,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Направление</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.direction}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Группа</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.group_name}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Период</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.period}</span>,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Программа</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.program}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Тип</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.type_name}</span>,
        }
    ];

    return (
        <>
            <Card>
                <CardBody>
                    <DataTable
                        columns={columns}
                        data={listLevelEvolutionChildren}
                        className="custom-scroll-news"
                        fixedHeader={true}
                        noDataComponent={'Нет даных'}
                        paginationComponentOptions={
                            {
                                rowsPerPageText: 'Видно документов:',
                                rangeSeparatorText: 'из'
                            }
                        }
                        pagination
                        paginationRowsPerPageOptions={[10, 20, 30]}
                        paginationPerPage={10}
                        highlightOnHover
                        customStyles={{
                            rows: {
                                highlightOnHoverStyle: {
                                    backgroundColor: "#ffebb5"
                                }
                            }
                        }}
                        onRowClicked={
                            (row) => {
                                if(window.innerWidth <= 768){
                                    setData(row)
                                    dispatch(setDateAdd(moment(row.data, "DD.MM.YYYY").toDate()));
                                    setOpenUpdate(true)
                                }
                            }
                        }
                        onRowDoubleClicked={
                            (row) => {
                                if(window.innerWidth > 768){
                                    setData(row)
                                    dispatch(setDateAdd(moment(row.data, "DD.MM.YYYY").toDate()));
                                    setOpenUpdate(true)
                                }
                            }
                        }
                    />
                </CardBody>
                <CardFooter>
                    <button className="btn btn-primary" onClick={() => {
                        setOpen(true)
                    }}>Создать</button>
                </CardFooter>
            </Card>
            <ModalUpdateEvolutionChildren updateList={() => {
                AxiosClient.get('/list_level_evolution_children', {params: {date: [moment(date).startOf('month').format('DD.MM.YYYY'), moment(date).endOf('month').format('DD.MM.YYYY')],uid_group: groupAllDeviations}})
                    .then(r => setListLevelEvolutionChildren([].concat(...r.data)))
            }} open={openUpdate} setOpen={value => setOpenUpdate(value)} data={data} setData={value => setData(value)}/>
            <ModalAddLevelEvolutionChildren listLevelEvolutionChildren={listLevelEvolutionChildren} dateMonth={date} updateList={() => {
                AxiosClient.get('/list_level_evolution_children', {params: {date: [moment(date).startOf('month').format('DD.MM.YYYY'), moment(date).endOf('month').format('DD.MM.YYYY')], uid_group: groupAllDeviations}})
                    .then(r => setListLevelEvolutionChildren([].concat(...r.data)))
            }} open={open} setOpen={value => setOpen(value)}/>
            <ToastContainer/>
        </>
    );
};

export default ListLevelEvolutionChildren;
