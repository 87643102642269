import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Card, CardHeader, Col, Container, Row} from "reactstrap";
import StatisticTable from "../../components/StatisticsLogin/StatisticTable";
import AxiosClient from "../../api/AxiosClient";

const StatisticLoginKshp = () => {

    const [classes, setClasses] = useState<any[]>([]);
    const [selectClass, setSelectClass] = useState('');

    useEffect(() => {
        AxiosClient.get('/users/classes')
            .then(r => {
                setClasses(r.data)
            })
            .catch(() => console.log('error'))
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Статистика входа</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="col">
                                <Card>
                                    <CardHeader className="card_header_size_text shadow">
                                        Класс:
                                        <select className="form-select mt-1 shadow" value={selectClass} onChange={event => setSelectClass(event.target.value)}>
                                            <option value={""}>Не выбрано</option>
                                            {
                                                classes.map(c => <option key={c.id} value={c.id}>{c.name}</option>)
                                            }
                                        </select>
                                    </CardHeader>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={12}>
                            <StatisticTable type="kshp" classId={selectClass}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default StatisticLoginKshp;
