import React, {FC, useEffect, useRef, useState} from 'react';
import ProfileDropdown from "./ProfileDropdown";
import {Col} from "reactstrap";
import AccessibilityPanel from "./AccessibilityPanel";

const Header: FC = () => {
    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
    const panelRef = useRef<HTMLDivElement | null>(null);
    const iconRef = useRef<HTMLElement | null>(null);

    document.documentElement.setAttribute('data-sidebar-size', 'lg')
    document.documentElement.setAttribute('data-layout', 'vertical')
    document.documentElement.setAttribute('data-sidebar', 'dark')

    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth;

        if (windowSize > 767){
            (document.querySelector(".hamburger-icon") as HTMLElement).classList.toggle('open');
        }

        if (windowSize < 1025 && windowSize > 767) {
            document.body.classList.remove('vertical-sidebar-enable');
            (document.documentElement.getAttribute('data-sidebar-size') === 'sm') ? document.documentElement.setAttribute('data-sidebar-size', '') : document.documentElement.setAttribute('data-sidebar-size', 'sm');
        } else if (windowSize > 1025) {
            document.body.classList.remove('vertical-sidebar-enable');
            (document.documentElement.getAttribute('data-sidebar-size') === 'lg') ? document.documentElement.setAttribute('data-sidebar-size', 'sm') : document.documentElement.setAttribute('data-sidebar-size', 'lg');
        } else if (windowSize <= 767) {
            document.body.classList.add('vertical-sidebar-enable');
            document.documentElement.setAttribute('data-sidebar-size', 'lg');
        }
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                panelRef.current &&
                !panelRef.current.contains(event.target as Node) &&
                iconRef.current !== event.target
            ) {
                setIsPanelOpen(false);
            }
        }

        if (isPanelOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isPanelOpen]);

    return (
        <header id="page-topbar">
            <div className="layout-width">
                <div className="navbar-header">
                    <div className="d-flex">

                        <button
                            onClick={toogleMenuBtn}
                            type="button"
                            className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none"
                            id="topnav-hamburger-icon">
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                        </button>
                        {/* <SearchOption /> */}
                    </div>

                    <div className="d-flex align-items-center">
                        <i
                            ref={iconRef}
                            className={`bx ${isPanelOpen ? "bx-hide" : "bx-show-alt"} fs-3`}
                            onClick={(e) => setIsPanelOpen(!isPanelOpen)}
                            style={{ cursor: "pointer" }}
                        ></i>
                        <Col>
                            <ProfileDropdown />
                        </Col>
                    </div>
                </div>
                {isPanelOpen && (
                    <div ref={panelRef}>
                        <AccessibilityPanel />
                    </div>
                )}
            </div>
        </header>
    );
};

export default Header;
