import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import TableFeedback from "../components/Feedback/TableFeedback";
import AxiosClient from "../api/AxiosClient";
import FormAddFeedback from "../components/Feedback/FormAddFeedback";
import {useStateContext} from "../hooks/useStateContext";
import FormMoreFeedback from "../components/Feedback/FormMoreFeedback";
import NotificationShow from "../components/widgets/NotificationShow";

const Feedback = () => {
    const {user} = useStateContext();
    const [listFeedback, setListFeedback] = useState<any>();
    const [addFeedback, setAddFeedback] = useState<boolean>(false);
    const [selectFeedback, setSelectFeedback] = useState<any>();
    const [showFeedback, setShowFeedback] = useState<boolean>(false);

    useEffect(() => {
        AxiosClient.get('/feedback/getListFeedback', {params: {uid: user.user1c, name: user.term.name}})
            .then(r => {
                setListFeedback(r.data)
            })
            .catch(() => console.log('error'))
    }, [addFeedback, showFeedback])

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Обратная связь </title>
            </Helmet>
            <Container fluid>
                {(user.term.name === 'parent') &&
                <Row>
                    <Col xxl={3} xl={3} lg={3} sm={6}>
                        <button className="btn btn-primary w-100 mt-1"
                                id="buttonAddFeedback"
                                onClick={() => {
                                    setAddFeedback(true)
                                }}
                        >Добавить обращение</button>
                    </Col>
                </Row>}
                <Row>
                    <TableFeedback listFeedback={listFeedback} setSelectFeedback={(value) => setSelectFeedback(value)} setShowFeedback={(value) => setShowFeedback(value)}/>
                </Row>
            </Container>
            {addFeedback && <FormAddFeedback addFeedback={addFeedback} setAddFeedback={(value) => setAddFeedback(value)}/>}
            {showFeedback && <FormMoreFeedback selectFeedback={selectFeedback} showFeedback={showFeedback} setShowFeedback={(value: boolean) => setShowFeedback(value)}/>}
            <NotificationShow />
        </div>
    );
};

export default Feedback;
