import React, { FC } from "react";
import { useAppDispatch, useAppSelector} from "../../../hooks/redux";
import { setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import { Modal, ModalBody, ModalHeader, Label, Row, Col } from "reactstrap";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import AxiosClient from "../../../api/AxiosClient";

interface IModalAddPeriodicityEvents {
    updateData: any;
    onSuccess: () => void;
}

const ModalAddPeriodicityEvents: FC<IModalAddPeriodicityEvents> = ({ updateData, onSuccess }) => {
    const dispatch = useAppDispatch();
    const { modalAdd } = useAppSelector(state => state.WidgetReducer);

    const validationSchema = Yup.object({
        name: Yup.string()
            .required('Введите наименование')
            .min(2, 'Наименование должно содержать минимум 2 символа')
    });

    const handleSubmit = (values: { name: string }, { resetForm }: any) => {
        dispatch(setIsSend(true));

        const request = updateData
            ? AxiosClient.post(`/periodicity-events/update/${updateData.id}`, { name: values.name })
            : AxiosClient.post("/periodicity-events/add", { name: values.name });

        request
            .then((response) => {
                if (response.status === 200) {
                    dispatch(setModalAdd(false));
                    onSuccess();
                    if (!updateData) resetForm();
                }
            })
            .catch((error) => {
                console.error('Ошибка при сохранении периодичности событий:', error);
            })
            .finally(() => {
                dispatch(setIsSend(false));
            });
    };

    return (
        <Modal
            id="ModalAddPeriodicityEvents"
            isOpen={modalAdd}
            toggle={() => dispatch(setModalAdd(false))}
            modalClassName="zoomIn"
            tabIndex={-1}
            centered
        >
            <ModalHeader
                toggle={() => dispatch(setModalAdd(false))}
                className="p-3 bg-soft-primary"
            >
                {updateData ? 'Редактирование периодичности событий' : 'Добавление периодичности событий'}
            </ModalHeader>
            <ModalBody className="p-4">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        name: updateData?.name || ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <Row>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="nameInput" className="form-label">
                                            Наименование
                                        </Label>
                                        <Field
                                            type="text"
                                            className="form-control"
                                            id="nameInput"
                                            name="name"
                                            placeholder="Введите наименование"
                                        />
                                        <ErrorMessage
                                            name="name"
                                            component="div"
                                            className="text-danger mt-1"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className="hstack gap-2 justify-content-end">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting
                                                ? 'Сохранение...'
                                                : updateData
                                                    ? 'Сохранить изменения'
                                                    : 'Добавить'
                                            }
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddPeriodicityEvents;