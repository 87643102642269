import React from 'react';
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from "formik";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {Col, Input, Label, Row} from "reactstrap";
import {updateOrganizations} from "../../../store/reducers/admin/ActionCreator";
const UpdateDataOrganizationForm = () => {

    const dispatch = useAppDispatch();
    const {selectOrganization, organizations, regions, cities} = useAppSelector(state => state.AdminReducer)

    return (
        <Formik
            enableReinitialize
            initialValues={
                {
                    id: `${selectOrganization?.id}`,
                    code: `${selectOrganization?.code}`,
                    org_number: `${selectOrganization?.org_number}`,
                    name: `${selectOrganization?.name}`,
                    host: `${selectOrganization?.host}`,
                    port: `${selectOrganization?.port}`,
                    login_prefix: `${selectOrganization?.login_prefix}`,
                    login: `${selectOrganization?.login}`,
                    publication_name: `${selectOrganization?.publication_name}`,
                    help_center: `${selectOrganization?.help_center}` === "" ? 'false' : `${selectOrganization?.help_center}`,
                    consultative_center: `${selectOrganization?.consultative_center}` === "" ? 'false' : `${selectOrganization?.consultative_center}`,
                    parent_organization_id: `${selectOrganization?.parent_organization_id}` === null ? 1 : `${selectOrganization?.parent_organization_id}`,
                    region_id: `${selectOrganization?.region_id}`,
                    use_database: `${selectOrganization?.use_database}` === "" ? 'null' : `${selectOrganization?.use_database}`,
                    city_id: `${selectOrganization?.city_id}`,
                    type_game_location: selectOrganization?.type_game_location || false,
                    payment_sber: selectOrganization?.payment_sber === "" ? 'https://online.sberbank.ru/' : selectOrganization?.payment_sber,
                    payment_vtb: selectOrganization?.payment_vtb === "" ? 'http://online.vtb.ru/i/pay' : selectOrganization?.payment_vtb,
                }
            }
            validationSchema={
                Yup.object(
                    {
                        code: Yup.string().required('Введите код организации'),
                        org_number: Yup.string().required('Введите номер огранизации'),
                        name: Yup.string().required('Введите название организации'),
                        host: Yup.string().required('Введите хост организации'),
                        port: Yup.string().required('Введите порт организации'),
                        login_prefix: Yup.string().required('Введиет префикс логина организации'),
                        login: Yup.string().required('Введите логин организации'),
                        publication_name: Yup.string().required('Укажите имя публикации')
                    }
                )
            }
            onSubmit={
                (value) => {
                    (value as any).organizationId = selectOrganization?.id;
                    dispatch(updateOrganizations(value, selectOrganization!.id))
                }
            }
        >
            {({setFieldValue, values}) => (
            <Form>
                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="id"
                                   className="form-label">ID</Label>
                            <Field type="text" className="form-control" id="id"
                                   name="id" disabled />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="type_game_location"
                                   className="form-label">Тип игры “Локация”</Label>
                            <div style={{ marginTop: '10px'}}>
                            <Input
                                id="type_game_location"
                                name="type_game_location"
                                type="checkbox"
                                className="border-primary m-0"
                                style={{ width: '20px', height: '20px'}}
                                checked={values.type_game_location}
                                onChange={(e) => setFieldValue('type_game_location', e.target.checked)}
                            />
                        </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="code"
                                   className="form-label">Код</Label>
                            <Field type="text" className="form-control" id="code"
                                   name="code"
                                   placeholder="Введите код организации"/>
                            <ErrorMessage name="code">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="org_number" className="form-label">Номер
                                организации</Label>
                            <Field type="text" className="form-control" id="org_number"
                                   name="org_number"
                                   placeholder="Введите номер организации"/>
                            <ErrorMessage name="org_number">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="name"
                                   className="form-label">Название</Label>
                            <Field type="text" className="form-control" id="name"
                                   name="name"
                                   placeholder="Введите название организации"/>
                            <ErrorMessage name="name">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="host"
                                   className="form-label">Хост</Label>
                            <Field type="text" className="form-control" id="host"
                                   name="host"
                                   placeholder="Введите хост организации"/>
                            <ErrorMessage name="host">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="port"
                                   className="form-label">Порт</Label>
                            <Field type="text" className="form-control" id="port"
                                   name="port"
                                   placeholder="Введите порт организации"/>
                            <ErrorMessage name="port">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="login_prefix" className="form-label">Префикс
                                логина</Label>
                            <Field type="text" className="form-control" id="login_prefix"
                                   name="login_prefix"
                                   placeholder="Введите префикс логина организации"/>
                            <ErrorMessage name="login_prefix">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="publication_name" className="form-label">Имя
                                публикации</Label>
                            <Field type="text" className="form-control" id="publication_name"
                                   name="publication_name"
                                   placeholder="Введите имя публикации организации"/>
                            <ErrorMessage name="publication_name">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="text"
                                   className="form-label">Логин</Label>
                            <Field type="text" className="form-control" id="text"
                                   name="login"
                                   placeholder="Введите логин организации"/>
                            <ErrorMessage name="login">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="help_center"
                                   className="form-label">Центр ранней помощи</Label>
                            <Field as="select" className="form-select" id="help_center"
                                   name="help_center">
                                <option value='true'>Центр ранней помощи</option>
                                <option value='false'>Не центр ранней помощи</option>
                            </Field>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="consultative_center"
                                   className="form-label">Консультативный центр</Label>
                            <Field as="select" className="form-select" id="consultative_center"
                                   name="consultative_center">
                                <option value='true'>Консультативный центр</option>
                                <option value='false'>Не консультативный центр</option>
                            </Field>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="parent_organization_id"
                                   className="form-label">Вышестоящая
                                организация</Label>
                            <Field as="select" className="form-select" id="parent_organization_id"
                                   name="parent_organization_id">
                                <option value="null">Нет</option>
                                {

                                    organizations.map(org =>
                                        (<option key={org.id}
                                                 value={org.id}>{org.name}</option>)
                                    )
                                }
                            </Field>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="region_id"
                                   className="form-label">Регион</Label>
                            <Field as="select" className="form-select" id="region_id"
                                   name="region_id">
                                {
                                    regions.map(org =>
                                        (<option key={org.id}
                                                 value={org.id}>{org.name}</option>)
                                    )
                                }
                            </Field>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="use_databaseInput"
                                   className="form-label">
                                Использовать Базу Данных
                            </Label>
                            <Field as="select" className="form-select"
                                   id="use_databaseInput"
                                   name="use_database">
                                <option value="true">Да</option>
                                <option value="false">Нет</option>
                                <option value="null">Не выбрано</option>
                            </Field>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="city_id"
                                   className="form-label">Город</Label>
                            <Field as="select" className="form-select" id="city_id"
                                   name="city_id">
                                {
                                    cities.map(city =>
                                        (<option key={city.id}
                                                 value={city.id}>{city.name}</option>)
                                    )
                                }
                            </Field>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="payment_sber"
                                   className="form-label">Платежная ссылка СБЕР</Label>
                            <Field type="text" className="form-control" id="payment_sber"
                                   name="payment_sber"
                                   placeholder="Введите ссылку для СБЕР"/>
                            <ErrorMessage name="payment_sber">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="payment_vtb" className="form-label">Платежная ссылка ВТБ</Label>
                            <Field type="text" className="form-control" id="payment_vtb"
                                   name="payment_vtb"
                                   placeholder="Введите ссылку для ВТБ"/>
                            <ErrorMessage name="payment_vtb">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="mt-3">
                        <div className="hstack gap-2 justify-content-end">
                            <button type="submit"
                                    className="btn btn-primary">Обновить
                            </button>
                        </div>
                    </Col>
                </Row>
            </Form>
            )}
        </Formik>
    );
};

export default UpdateDataOrganizationForm;
