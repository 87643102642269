import React, {FC, useEffect, useState} from 'react';
import AxiosClient from "../../../api/AxiosClient";
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import Select from "react-select";
import {customStyles} from "../../settings";
import {areIntervalsOverlapping} from "../../../functions/ConvertDate";
import moment from "moment";
import {useAppDispatch} from "../../../hooks/redux";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";

interface IModalAddStatus {
    addStatus: boolean,
    setAddStatus: (value: boolean) => void,
    listStatuses: any[]
    setListStatuses: (value: any[]) => void
}

const ModalAddStatus: FC<IModalAddStatus> = ({setAddStatus, setListStatuses, listStatuses, addStatus}) => {
    const [dataBegin, setDataBegin] = useState(new Date());
    const [dateEnd, setDateEnd] = useState<Date>(new Date());
    const [statuses, setStatuses] = useState<any[]>([]);
    const [selectStatus, setSelectStatus] = useState<any>({value: '', label: 'Не выбрано'});

    useEffect(() => {
        addStatus &&
        AxiosClient.get('/statuses')
            .then(r => setStatuses(r.data))
            .catch(() => console.log('error'))
    }, [addStatus]);
    const dispatch = useAppDispatch();
    useEscapeKey(() => dispatch(setModalAdd(false)), addStatus, "addStatus");

    return (
        <Modal id="addStatus" isOpen={addStatus}
               toggle={() => setAddStatus(!addStatus)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setAddStatus(!addStatus)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление статуса
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={{
                        date_begin: new Date(),
                        date_end: '',
                        status_id: statuses[0] && statuses[0].id,
                    }}
                    validationSchema={
                        Yup.object({
                            status_id: Yup.number().required('Обязательное поле'),
                        })
                    }
                    onSubmit={(values, {setErrors}) => {
                        const isDuplicate = listStatuses.some(m => (m.id.toString() === values.status_id.toString()) && areIntervalsOverlapping(m, {date_begin: dataBegin,
                            date_end: dateEnd}));
                        if (isDuplicate) {
                            setErrors({status_id: 'Такая категория льгот уже существует или совпадают периоды'});
                        }else {
                            setListStatuses([...listStatuses, {
                                ...statuses.find(b => b.id.toString() === values.status_id.toString()),
                                date_begin: moment(dataBegin).format('YYYY-MM-DD'),
                                date_end: dateEnd ? moment(dateEnd).format('YYYY-MM-DD') : ""
                            }])
                            setAddStatus(false)
                        }
                    }}
                >
                    {
                        ({errors, touched, setFieldValue}) => (
                            <Form>
                                <Row>
                                    <Col>
                                        <div>
                                            <label className="w-100">Статус:
                                            <Select
                                                options={statuses?.length > 0 ? [{
                                                    value: '',
                                                    label: 'Не выбрано'
                                                }, ...statuses?.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                                                value={selectStatus}
                                                onChange={(change: any) => {
                                                    setFieldValue('status_id', change.value)
                                                    setSelectStatus({value: change.value, label: change.label})
                                                }}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                                name="status_id"
                                            />
                                            </label>
                                            {errors.status_id && touched.status_id ? (
                                                <div className="text-danger">{errors.status_id.toString()}</div>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <label className="w-100">Дата с:
                                            <Flatpickr
                                                className={`form-control dash-filter-picker shadow ${errors.date_begin ? 'border-1 border-danger' : 'border-0'}`}
                                                value={dataBegin}
                                                options={{
                                                    locale: Russian, dateFormat: "d M Y"
                                                }}
                                                onClose={(date) => {
                                                    setDataBegin(date[0])
                                                }}
                                            />
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <label className="w-100">Дата по:
                                            <Flatpickr
                                                className={`form-control dash-filter-picker shadow ${errors.date_end ? 'border-1 border-danger' : 'border-0'}`}
                                                value={dateEnd}
                                                options={{
                                                    locale: Russian, dateFormat: "d M Y"
                                                }}
                                                onClose={(date) => {
                                                    setDateEnd(date[0])
                                                }}
                                            />
                                            </label>
                                            {errors.date_end && touched.date_end ? (
                                                <div className="text-danger">{errors.date_end.toString()}</div>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <div>
                                    <div className="hstack gap-2">
                                        <button type="submit" className="btn btn-primary mt-1" id="addNewProject">Добавить</button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddStatus;
