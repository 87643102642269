import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Button,
    FormGroup,
    Label,
    Input
} from "reactstrap";
import {Field, Form, Formik} from "formik";
import classNames from "classnames";
import * as Yup from "yup";
import { setShowNotification} from "../../../../store/reducers/widgets/WidgetsSlice";

interface IModalCreateTestProps {
    isOpen: boolean;
    toggle: () => void;
    onSave: (data: {
        title: string;
        description: string;
        gradingScale: string;
        timeLimit: boolean;
        timeLimitMinutes: number;
        attemptLimit: boolean;
        attemptLimitCount: number;
        taskOrder: string;
        allowSkip: boolean
    }) => void;
}

const ModalCreateTest: FC<IModalCreateTestProps> = ({isOpen, toggle, onSave}) => {
    const dispatch = useAppDispatch();
    // const { gradingScales } = useAppSelector(state => state.EducationReducer); // Предполагаем, что шкалы оценок хранятся в состоянии

    const [activeTab, setActiveTab] = useState("1");

    // Загружаем шкалы оценок при открытии модального окна
    useEffect(() => {
        if (isOpen) {
            // добавить запрос к API для получения шкал оценок, если они еще не загружены
        }
    }, [isOpen]);

    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    // Пример списка шкал оценок (если они не загружаются из API)
    const gradingScalesList = [
        {id: 12815, systemName: "низкая_средняя_высокая", description: "уровень"},
    ];

    return (
        <Modal
            id="ModalCreateTest"
            isOpen={isOpen}
            toggle={toggle}
            modalClassName="zoomIn"
            tabIndex={-1}
            centered
        >
            <ModalHeader toggle={toggle} className="p-3 bg-soft-primary" id="createTestModalLabel">
                Создать тест
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={{
                        title: '',
                        description: '',
                        gradingScale: gradingScalesList[0]?.id.toString() || '',
                        timeLimit: false,
                        timeLimitMinutes: 0,
                        attemptLimit: false,
                        attemptLimitCount: 0,
                        taskOrder: 'sequential', // по порядку
                        allowSkip: false,
                    }}
                    validationSchema={Yup.object({
                        title: Yup.string().required('Название теста обязательно'),
                        description: Yup.string().required('Описание теста обязательно'),
                        timeLimitMinutes: Yup.number().when('timeLimit', {
                            is: true,
                            then: (schema) => schema.min(1, 'Время теста должно быть больше 0 минут').required('Укажите время теста'),
                            otherwise: (schema) => schema.notRequired(),
                        }),
                        attemptLimitCount: Yup.number().when('attemptLimit', {
                            is: true,
                            then: (schema) => schema.min(1, 'Число попыток должно быть больше 0').required('Укажите число попыток'),
                            otherwise: (schema) => schema.notRequired(),
                        }),
                    })}
                    onSubmit={(values) => {
                        onSave({
                            title: values.title,
                            description: values.description,
                            gradingScale: values.gradingScale,
                            timeLimit: values.timeLimit,
                            timeLimitMinutes: values.timeLimit ? values.timeLimitMinutes : 0,
                            attemptLimit: values.attemptLimit,
                            attemptLimitCount: values.attemptLimit ? values.attemptLimitCount : 0,
                            taskOrder: values.taskOrder,
                            allowSkip: values.allowSkip,
                        });
                        dispatch(setShowNotification({ type: "success", message: 'Тест успешно создан!', show: true }));
                        toggle();
                    }}
                >
                    {({errors, touched, values, setFieldValue}) => (
                        <Form>
                            <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                <NavItem>
                                    <NavLink
                                        className={classNames({active: activeTab === "1", "p-2": true})}
                                        onClick={() => tabChange("1")}
                                        type="button"
                                    >
                                        <i className="fas fa-home"></i>
                                        Название
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classNames({active: activeTab === "2", "p-2": true})}
                                        onClick={() => tabChange("2")}
                                        type="button"
                                    >
                                        <i className="fas fa-cog"></i>
                                        Настройки
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab} className="mt-2">
                                {/* Первый таб: Название */}
                                <TabPane tabId="1" className="align-items-center">
                                    <FormGroup className="mb-3">
                                        <Label for="title">Название теста</Label>
                                        <Field
                                            name="title"
                                            type="text"
                                            className={`form-control ${errors.title && touched.title ? 'is-invalid' : ''}`}
                                            placeholder="Введите название теста"
                                        />
                                        {errors.title && touched.title && (
                                            <div className="invalid-feedback">{errors.title}</div>
                                        )}
                                    </FormGroup>

                                    <FormGroup className="mb-3">
                                        <Label for="description">Описание теста</Label>
                                        <Field
                                            name="description"
                                            as="textarea"
                                            className={`form-control ${errors.description && touched.description ? 'is-invalid' : ''}`}
                                            placeholder="Введите описание теста"
                                            rows="3"
                                        />
                                        {errors.description && touched.description && (
                                            <div className="invalid-feedback">{errors.description}</div>
                                        )}
                                    </FormGroup>


                                </TabPane>

                                {/* Второй таб: Настройки */}
                                <TabPane tabId="2" className="align-items-center">
                                    <FormGroup className="mb-3">
                                        <Label for="gradingScale">Шкала оценок</Label>
                                        <Field
                                            as="select"
                                            name="gradingScale"
                                            className="form-select shadow"
                                        >
                                            {gradingScalesList.map((scale: any) => (
                                                <option key={scale.id} value={scale.id}>
                                                    {scale.description} ({scale.systemName})
                                                </option>
                                            ))}
                                        </Field>
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label for="taskOrder">Порядок выполнения заданий</Label>
                                        <Field
                                            as="select"
                                            name="taskOrder"
                                            className="form-select shadow"
                                        >
                                            <option value="sequential">По порядку</option>
                                            <option value="random">Произвольный</option>
                                        </Field>
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label className="w-100">
                                            Ограничить время теста
                                            <Field
                                                type="checkbox"
                                                name="timeLimit"
                                                className="form-check-input shadow ms-2"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    setFieldValue('timeLimit', e.target.checked);
                                                    if (!e.target.checked) {
                                                        setFieldValue('timeLimitMinutes', 0);
                                                    }
                                                }}
                                            />
                                        </Label>
                                        {values.timeLimit && (
                                            <div className="mt-2">
                                                <Label for="timeLimitMinutes">Количество минут</Label>
                                                <Field
                                                    name="timeLimitMinutes"
                                                    type="number"
                                                    className={`form-control ${errors.timeLimitMinutes && touched.timeLimitMinutes ? 'is-invalid' : ''}`}
                                                    placeholder="Введите количество минут"
                                                />
                                                {errors.timeLimitMinutes && touched.timeLimitMinutes && (
                                                    <div className="invalid-feedback">{errors.timeLimitMinutes}</div>
                                                )}
                                            </div>
                                        )}
                                    </FormGroup>

                                    <FormGroup className="mb-3">
                                        <Label className="w-100">
                                            Ограничить число попыток
                                            <Field
                                                type="checkbox"
                                                name="attemptLimit"
                                                className="form-check-input shadow ms-2"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    setFieldValue('attemptLimit', e.target.checked);
                                                    if (!e.target.checked) {
                                                        setFieldValue('attemptLimitCount', 0);
                                                    }
                                                }}
                                            />
                                        </Label>
                                        {values.attemptLimit && (
                                            <div className="mt-2">
                                                <Label for="attemptLimitCount">Число попыток</Label>
                                                <Field
                                                    name="attemptLimitCount"
                                                    type="number"
                                                    className={`form-control ${errors.attemptLimitCount && touched.attemptLimitCount ? 'is-invalid' : ''}`}
                                                    placeholder="Введите число попыток"
                                                />
                                                {errors.attemptLimitCount && touched.attemptLimitCount && (
                                                    <div className="invalid-feedback">{errors.attemptLimitCount}</div>
                                                )}
                                            </div>
                                        )}
                                    </FormGroup>

                                    <FormGroup className="mb-3">
                                        <Label className="w-100">
                                            Разрешить пропуск задания
                                            <Field
                                                type="checkbox"
                                                name="allowSkip"
                                                className="form-check-input shadow ms-2"
                                            />
                                        </Label>
                                    </FormGroup>
                                </TabPane>
                            </TabContent>

                            <div className="hstack gap-2 justify-content-end mt-1">
                                <Button color="primary" type="submit">
                                    Создать
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>


        </Modal>
    );
};

export default ModalCreateTest;