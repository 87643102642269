import React, {useEffect, useState} from 'react';
import {Alert, Modal, ModalBody, ModalHeader} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import TableAdd from "./tables/TableAdd";
import {
    setDateAdd,
    setGroupModalAdd, setIsSend,
    setModalAdd, setToUpdateGraphMainPage, WidgetsSlice
} from "../../../store/reducers/widgets/WidgetsSlice";
import {Russian} from 'flatpickr/dist/l10n/ru'
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getAllInfoGroup, setAttendance} from "../../../store/reducers/widgets/ActionCreator";
import {convertDate} from "../../../functions/ConvertDate";
import ModalSelectDeviationAll from "./ModalSelectDeviationAll";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import moment from "moment";
import {useModalWithConfirm} from "../../../hooks/useModalWithConfirm";
import ModalConfirmExitHaccp from "../../journalHaccp/ModalConfirmExitHaccp";


const ModalAddTableAllInfoGroup = () => {

    const dispatch = useAppDispatch();
    const {modalAdd, date, groupModalAdd, students, groups, dateAdd, isError, successUpdate} = useAppSelector(state => state.WidgetReducer);
    const [present, setPresent] = useState(0);
    const [noPresent, setNoPresent] = useState(0);
    const [modalSelectDeviationAll, setModalSelectDeviationAll] = useState(false);
    const [send, setSend] = useState(false);
    const [data, setData] = useState<any>(null);
    const {
        isFormChange,
        showConfirmExit,
        handleClose,
        handleFormChange,
        handleConfirmClose,
        handleStay,
    } = useModalWithConfirm("addTableAllInfoGroup");

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(WidgetsSlice.actions.successUpdate(""))
        }, 5000)
        return () => clearTimeout(timer)
    }, [successUpdate, dispatch]);

    const handleAttendanceUpdate = (presentCount: number, noPresentCount: number) => {
        setPresent(presentCount);
        setNoPresent(noPresentCount);
    };

    return (
        <>
        <Modal id="ModalAddTableAllInfoGroup" isOpen={modalAdd}  toggle={handleClose}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader  toggle={handleClose} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Посещаемость детей
            </ModalHeader>
            <ModalBody>
                {successUpdate && <Alert>{successUpdate}</Alert>}
                {isError && <Alert color="danger">{isError}</Alert>}
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        group: `${groupModalAdd}`,
                        deviations: (students || []).map(item => {
                            return {
                                [item.code]: item.deviation_code
                            };
                        })
                    }}
                    onSubmit={(value) => {
                        if (send) {
                            let data = {
                                data: '',
                                class_code: '',
                                do: '',
                                theme: '',
                                students: [{}]
                            };
                            let arr: object[] = []
                            data.data = convertDate(dateAdd);
                            data.class_code = value.group;

                            value.deviations.forEach(element => {
                                if (typeof (element) === 'object') {
                                    let student = students.find(s => s.code === Object.keys(element)[0]);
                                    if (Object.values(element)[0] === '') {
                                        arr.push({
                                            code: Object.keys(element)[0],
                                            attend: "1",
                                            deviation_code: null,
                                            deviation_data: moment(student?.deviation_data, "YYYY-MM-DD").format("DD-MM-YYYY") || null,
                                            deviation_data_prev: student?.deviation_data_prev || null,
                                            time_c: student?.time_c || null,
                                            time_po: student?.time_po || null
                                        })
                                    } else {
                                        arr.push({
                                            code: Object.keys(element)[0],
                                            attend: "0",
                                            deviation_code: Object.values(element)[0],
                                            deviation_data: moment(student?.deviation_data, "YYYY-MM-DD").format("DD-MM-YYYY") || null,
                                            deviation_data_prev: student?.deviation_data_prev || null,
                                            time_c: student?.time_c || null,
                                            time_po: student?.time_po || null
                                        })
                                    }
                                }
                            });

                            data.students = arr

                            setData(data)
                        } else {
                            dispatch(setIsSend(true))
                            let data = {
                                data: '',
                                class_code: '',
                                do: '',
                                theme: '',
                                students: [{}]
                            };
                            let arr: object[] = []
                            data.data = convertDate(dateAdd);
                            data.class_code = value.group;

                            value.deviations.forEach(element => {
                                if (typeof (element) === 'object') {
                                    let student = students.find(s => s.code === Object.keys(element)[0]);
                                    if (Object.values(element)[0] === '') {
                                        arr.push({
                                            code: Object.keys(element)[0],
                                            attend: "1",
                                            deviation_code: null,
                                            deviation_data: moment(student?.deviation_data, "YYYY-MM-DD").format("DD-MM-YYYY") || null,
                                            deviation_data_prev: student?.deviation_data_prev || null,
                                            time_c: student?.time_c || null,
                                            time_po: student?.time_po || null
                                        })
                                    } else {
                                        arr.push({
                                            code: Object.keys(element)[0],
                                            attend: "0",
                                            deviation_code: Object.values(element)[0],
                                            deviation_data: moment(student?.deviation_data, "YYYY-MM-DD").format("DD-MM-YYYY") || null,
                                            deviation_data_prev: student?.deviation_data_prev || null,
                                            time_c: student?.time_c || null,
                                            time_po: student?.time_po || null
                                        })
                                    }
                                }
                            });

                            data.students = arr

                            dispatch(setAttendance(data, groupModalAdd, dateAdd)).then(
                                () => {
                                    dispatch(setIsSend(false))
                                    dispatch(setModalAdd(false))
                                    dispatch(getAllInfoGroup(date)).then()
                                    dispatch(setToUpdateGraphMainPage(true))
                                    handleConfirmClose();
                                }
                            )
                                .catch(() => dispatch(setIsSend(false)))
                        }


                    }}
                >{({values}) => (
                    <Form onChange={handleFormChange}>
                        <div className="col-auto">
                            <label>Дата:</label>
                            <div className="col mb-3">
                                <div className="input-group col-auto">
                                    <Flatpickr
                                        className="form-control border-0 dash-filter-picker shadow"
                                        value={dateAdd}
                                        options={{
                                            locale: Russian, dateFormat: "d M Y", disableMobile: true, mode: 'single'
                                        }}
                                        onChange={(date) => {
                                            dispatch(setDateAdd(date[0]))
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label>Группа:</label>
                            <Field as="select" className="form-select shadow" value={groupModalAdd}
                                   onChange={(e: any) => dispatch(setGroupModalAdd(e.target.value))} name="group">
                                {groups.map((group) => {
                                    return <option key={group.group_id}
                                                   value={group.group_id}>{group.group_name}</option>
                                })}
                            </Field>
                        </div>
                        <div>
                            <div className="table-responsive shadow mb-3">
                                <table className="table mb-0">
                                    <tbody>
                                        <tr>
                                            <th scope="row" className="text-success">
                                                Присутствует
                                            </th>
                                            <td>
                                                {present}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-danger">
                                                Отстутствует
                                            </th>
                                            <td>
                                                {noPresent}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>                        
                        <div style={{height: 400}}>
                            <TableAdd presentAndNoPresent={handleAttendanceUpdate} values={values.deviations} />
                        </div>
                        <div className="d-flex gap-5 justify-content-between">
                            <div className="hstack gap-2 justify-content-end mt-1">
                                <button type="button" className="btn btn-primary" id="addNewProject" onClick={() => setModalSelectDeviationAll(true)}>Ввод для всех</button>
                            </div>
                            {/*<div className="hstack gap-2 justify-content-end mt-1">*/}
                            {/*    <button type="submit" className="btn btn-primary" id="addNewProject" onClick={() => setSend(true)}>Посмотреть массив</button>*/}
                            {/*</div>*/}
                            <div className="hstack gap-2 justify-content-end mt-1">
                                <SubmitWithLoading text={"Сохранить"}/>
                            </div>
                        </div>
                    </Form>
                )}
                </Formik>
                <ModalSelectDeviationAll isOpen={modalSelectDeviationAll} setIsOpen={(value) => setModalSelectDeviationAll(value)}/>
                <Modal isOpen={send} toggle={() => setSend(!send)} centered>
                    <ModalHeader toggle={() => setSend(!send)} className="p-3 bg-soft-primary">Текстуры для копирования настроек</ModalHeader>
                    <ModalBody>
                        <pre>
                            {JSON.stringify(data, null, 2)}
                        </pre>
                    </ModalBody>
                </Modal>
            </ModalBody>
        </Modal>
            <ModalConfirmExitHaccp
                isOpen={showConfirmExit}
                onClose={handleConfirmClose}
                onStay={handleStay}
                hasUnsavedChanges={isFormChange}
            />
        </>
    );
};

export default ModalAddTableAllInfoGroup;
