import React, {FC, useEffect, useRef, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import AxiosClient from "../../../api/AxiosClient";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {useAppDispatch} from "../../../hooks/redux";
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";

interface IModelAddSchool {
    addClass: boolean,
    setAddClass: (value: boolean) => void,
    builds: any[],
    setBuilds: (value: any[]) => void
    newBuilds?: any[],
    setNewBuilds?: (value: any[]) => void
}

const ModalAddClass: FC<IModelAddSchool> = ({setAddClass, addClass, builds, setBuilds, newBuilds, setNewBuilds}) => {

    const [parallelClasses, setParallelClasses] = useState<any[]>([]);
    const [shifts, setShifts] = useState<any[]>([]);

    useEffect(() => {
        AxiosClient.get('/parallel_classes')
            .then(r => setParallelClasses(r.data))
            .catch(() => console.log('error'))
        AxiosClient.get('/shift')
            .then(r => {
                setShifts(r.data)
            })
    }, []);

    useEscapeKey(() => setAddClass(false), addClass, "createSchoolKSHPCreateClass");

    const onCheckParallel = (name: string) => {
        const parallel = parallelClasses.find(el => name.replace(/[^+\d]/g, '') === el.slug)
        if (parallel)
            return parallel.id
        return ''
    }
    const dispatch = useAppDispatch();
    useEscapeKey(() => dispatch(setModalAdd(false)), addClass, "addClass");


    return (
        <Modal id="addClass" isOpen={addClass}
               toggle={() => setAddClass(!addClass)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setAddClass(!addClass)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление класса
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            build: builds[0] && builds[0].name,
                            name: '',
                            parallel_classes: '',
                            shift: ''
                        }
                    }
                    validationSchema={
                        Yup.object({
                            name: Yup.string().required('Обязательное поле'),
                        })
                    }
                    onSubmit={(values) => {
                        setBuilds(builds.map(b => {
                            if(b.name === values.build){
                                return {
                                    ...b,
                                    class: [
                                        ...b.class,
                                        {
                                            name: values.name,
                                            parallel_class_id: values.parallel_classes,
                                            shift_id: values.shift
                                        }
                                    ]
                                }
                            }
                            return b
                        }))

                        setNewBuilds!(newBuilds!.map(b => {
                            if(b.name === values.build){
                                return {
                                    ...b,
                                    class: [
                                        ...b.class,
                                        {
                                            name: values.name,
                                            parallel_class_id: values.parallel_classes,
                                            shift_id: values.shift
                                        }
                                    ]
                                }
                            }
                            return b
                        }))
                    }}>
                    {
                        ({errors, touched, setFieldValue}) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Корпус:
                                        <Field name="build" as="select" className="form-select">
                                            {
                                                builds.map(b => (<option key={b.name} value={b.name}>{b.name}</option>))
                                            }
                                        </Field>
                                        </label>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Название:
                                        <Field name="name" className={`form-control ${errors.name && touched.name && "border border-2 border-danger"}`} onChange={
                                            (e: any) => {
                                                setFieldValue('name', e.target.value)
                                                !e.target.value ? setFieldValue('parallel_classes', '') :
                                                setFieldValue('parallel_classes', onCheckParallel(e.target.value))
                                            }
                                        }/>
                                        </label>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Параллель:
                                        <Field name="parallel_classes" as="select" className="form-select">
                                            <option value={''}>Не выбрано</option>
                                            {
                                                parallelClasses.map(b => (<option key={b.id} value={b.id}>{b.name}</option>))
                                            }
                                        </Field>
                                        </label>
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Смена:
                                        <Field name="shift" as="select" className="form-select">
                                            <option value={''}>Не выбрано</option>
                                            {
                                                shifts.map(b => (<option key={b.id} value={b.id}>{b.name}</option>))
                                            }
                                        </Field>
                                        </label>
                                    </Col>
                                </Row>
                                <div className="hstack gap-2">
                                    <button type="submit" className="btn btn-primary" id="addNewProject" onClick={() => setAddClass(false)}>Добавить</button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddClass;
