import React, {FC, useEffect, useState} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {
    Alert,
    Button,
    Card,
    CardBody, CardHeader,
    CardImg,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle, Input,
    Row, Spinner,
    UncontrolledDropdown
} from "reactstrap";
import bgImage from "../../assets/images/small/img-5.jpg";
import Flatpickr from "react-flatpickr";
import AddOtherImage from "./AddOtherImage";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getGroups, getStudents} from "../../store/reducers/widgets/ActionCreator";
import TableChildrenParty from "./TableChildrenParty";
import AxiosClient from "../../api/AxiosClient";
import {useNavigate} from "react-router-dom";
import {Russian} from "flatpickr/dist/l10n/ru";
import {useStateContext} from "../../hooks/useStateContext";
import {setShowNotification} from "../../store/reducers/widgets/WidgetsSlice";

const FormAddPortfolio: FC = () => {

    const redirect = useNavigate()
    const {user} = useStateContext();
    const dispatch = useAppDispatch()
    const {groups, students} = useAppSelector(state => state.WidgetReducer)
    const [image, setImage] = useState('');
    const [formImage, setFormImage] = useState('');
    const [formOtherImages, setFormOtherImages] = useState<Blob[]>([]);
    const [otherImage, setOtherImage] = useState<any[]>([]);
    const [date, setDate] = useState<Date>(new Date());
    const [group, setGroup] = useState('');
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [send, setSend] = useState(false);

    useEffect(() => {
        dispatch(getGroups([date, date])).then()
    }, [date, dispatch])

    useEffect(() => {
        dispatch(getStudents(date, group)).then()
    }, [date, group, dispatch])

    useEffect(() => {
        setGroup(groups[0]?.group_id)
    }, [groups])

    const uploadMainImage = () => {
        document.getElementById('mainImageInput')?.click();
    }

    const uploadOtherImage = () => {
        document.getElementById('otherImageInput')?.click();
    }

    const showMainImage = (e: any) => {
        if (e.target.files) {
            const [file] = e.target.files;
            setImage(URL.createObjectURL(file))
            setFormImage(file);
        }
    }
    const showOtherImage = (e: any) => {
        if (e.target.files) {
            const files: File[] = [...e.target.files];
            setOtherImage(otherImage.concat(files.map((i) => URL.createObjectURL(i))))
            setFormOtherImages(formOtherImages.concat(files))
        }
    }
    return (
        <Formik
            enableReinitialize
            initialValues={{
                mainImage: '',
                theme: '',
                description: '',
                checkbox: checkAll ? ([...students] || []).sort((a, b) => a.fio.localeCompare(b.fio)).map(i => ({[i.code]: true})) : ([...students] || []).sort((a, b) => a.fio.localeCompare(b.fio)).map(i => ({[i.code]: false})),
                date: new Date(),
                place: '',
                type: '1',
                level: '1',
                employee: user.id,
                group: groups[0]?.group_id,
                rang: '',
                otherFiles: '',
                group_name: ''
            }}
            onSubmit={(values) => {
                setSend(true)
                values.date = date
                values.mainImage = formImage
                values.group_name = groups.find(group => group.group_id === values.group)?.group_name as string
                const data = new FormData();
                data.append('mainImage', values.mainImage)
                formOtherImages.forEach(file => {
                    data.append('otherImage[]', file)
                });
                const stud = ([...students] || []).sort((a: any, b: any) => a.fio.localeCompare(b.fio));
                values.checkbox  = values.checkbox.map(i => {
                    const key = Object.keys(i)[0];
                    let rez = {};
                    stud.forEach(k => {
                        if (k.code === key){
                            rez = {
                                ...i,
                                fio: k.fio
                            }
                        }
                    })
                    return rez;
                })
                data.append('values', JSON.stringify(values))
                const formattedChildren = values.checkbox
                    .flatMap(obj =>
                        Object.entries(obj)
                            .filter(([key, value]) => key !== "fio" && value)
                            .map(([key]) => ({ child_uid: key }))
                    );
                AxiosClient.post('/portfolio/add', data, {headers: {"Content-Type": 'multipart/form-data'}})
                    .then(() => {
                        dispatch(setShowNotification({type: "success", message: "Готово, достижение сохранено!", show: true}));
                        AxiosClient.post('/send/sendParentPortfolio', {children: formattedChildren})
                            .then()
                            .catch(() => dispatch(setShowNotification({type: "error", message: "Уведомления отправлены не всем пользователям!", show: true})))
                        setSend(false)
                        redirect("/children/portfolio")
                    })
                    .catch(() => {
                        setSend(false)
                        dispatch(setShowNotification({type: "error", message: "Ошибка, проверьте корректность данных!", show: true}));
                    })
            }}
        >
            {
                ({setFieldValue}) => (
                    <Form>
                        <Row>
                            <Col xxl={8}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col lg={6} xl={5} xxl={5}>
                                                <Card className="card-height-100">
                                                    <CardBody className="d-flex align-items-center">
                                                        <UncontrolledDropdown direction='start'
                                                                              className="col text-end settings-image">
                                                            <DropdownToggle tag="a" id="dropdownMenuLink2"
                                                                            role="button">
                                                                <i className="ri-more-fill fs-21 text-light"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem className="dropdown-item edit-list"
                                                                              onClick={() => uploadMainImage()}>
                                                                    <i className="ri-add-fill me-1 align-bottom"></i>Добавить
                                                                    главное фото
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                        <CardImg id="mainImage" className="image-hover"
                                                                 src={image ? image : bgImage}/>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col>
                                                <Card className="card-height-100">
                                                    <CardBody>
                                                        <Input name="theme"
                                                               className="mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"
                                                               placeholder="Тема"
                                                               onChange={(e) => setFieldValue("theme", e.target.value)}/>
                                                        <ErrorMessage name='theme'>{(msg) => <Alert className="mt-1"
                                                                                                    color="danger">{msg}</Alert>}</ErrorMessage>
                                                        <textarea name="description"
                                                                  className="mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0"
                                                                  placeholder="Описание"
                                                                  rows={10}
                                                                  style={{resize: "none"}}
                                                                  onChange={(e) => setFieldValue("description", e.target.value)}/>
                                                        <ErrorMessage name='description'>{(msg) => <Alert
                                                            className="mt-1"
                                                            color="danger">{msg}</Alert>}</ErrorMessage>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <h4 className="text-center mt-sm-3 mt-lg-3 mt-xl-3 mt-xll-3">Информация по
                                                мероприятию</h4>
                                            <Col>
                                                <Row className="fs-5">
                                                    <div className="mt-1"><span
                                                        className="fw-medium">Дата:</span>
                                                        <Flatpickr
                                                            value={date}
                                                            name='date'
                                                            className="form-control border-1"
                                                            options={{
                                                                mode: "single",
                                                                dateFormat: "d m Y",
                                                                defaultDate: new Date(),
                                                                locale: Russian,
                                                            }}
                                                            onClose={(date) => setDate(date[0])}
                                                        />
                                                    </div>
                                                    <Input
                                                        color="success"
                                                        id="mainImageInput"
                                                        name="mainImage"
                                                        accept="image/*"
                                                        defaultValue={image}
                                                        className="mt-1" type="file" hidden
                                                        onChange={(e: any) => showMainImage(e)}
                                                    />
                                                    <div className="mt-1"><span
                                                        className="fw-medium">Место проведения:</span>
                                                        <Input name="place"
                                                               className="mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"
                                                               placeholder="Место проведения"
                                                               onChange={(e) => setFieldValue("place", e.target.value)}/>
                                                    </div>
                                                    <div className="mt-1"><span
                                                        className="fw-medium">Тип мероприятия:</span>
                                                        <Field as="select" name="type" id="" className="form-select ">
                                                            <option value="1">Классический</option>
                                                        </Field>
                                                    </div>
                                                    <div className="mt-1 "><span
                                                        className="fw-medium">Уровень организации:</span>
                                                        <Field as="select" name="level" id="" className="form-select ">
                                                            <option value="1">Высокий</option>
                                                        </Field>
                                                    </div>
                                                    <div className="mt-1"><span
                                                        className="fw-medium">Сотрудник:</span>
                                                        <Field as="select" name="employee"
                                                               className="form-control" disabled>
                                                            {
                                                                <option
                                                                        value={user.id}>{user.name}</option>
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className="mt-1"><span
                                                        className="fw-medium">Группа:</span>
                                                        <Input type="select" name="group" value={group}
                                                               className="form-select"
                                                               onChange={(e) => setGroup(e.target.value)}>
                                                            {
                                                                groups.map(group => (
                                                                    <option key={group.group_id}
                                                                            value={group.group_id}>{group.group_name}</option>
                                                                ))
                                                            }
                                                        </Input>
                                                    </div>
                                                    <div className="mt-1 mb-2"><span
                                                        className="fw-medium">Занял место:</span>
                                                        <Input name="rang"
                                                               className="mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"
                                                               placeholder="Занятое место"
                                                               onChange={(e) => setFieldValue("rang", e.target.value)}/>
                                                        <ErrorMessage name='rang'>{(msg) => <Alert className="mt-1 fs-6"
                                                                                                   color="danger">{msg}</Alert>}</ErrorMessage>
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <TableChildrenParty students={students} setCheckAll={(check) => setCheckAll(check)}/>
                            </Col>
                        </Row>
                        <Row className="mt-3 mt-sm-3 mt-lg-3 mt-xl-3 mt-xxl-0">
                            <Col>
                                <Card>
                                    <CardHeader>
                                        <Input color="success" id="otherImageInput" name="otherFiles" accept="image/*"
                                               multiple
                                               className="mt-1" type="file" hidden onChange={(e) => showOtherImage(e)}/>
                                        <Row>
                                            <Col xxl={3} lg={4}>
                                                <Button color="primary" className="mt-1 w-100" onClick={() => uploadOtherImage()}><i
                                                    className="ri-add-fill me-1 align-bottom"></i>Добавить новые фото</Button>
                                            </Col>
                                            <Col xxl={3} lg={4}>
                                                <Button color="primary" className="mt-1 w-100" type="submit" disabled={send}><i
                                                    className="ri-add-fill me-1 align-bottom"></i>{send ? <Spinner size={'sm'}>
                                                    Loading...
                                                </Spinner> : "Сохранить портфолио"}</Button>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            {
                                                otherImage && otherImage.map(url => (
                                                    <Col lg={6} xl={4} xxl={3} className="mt-1" key={url}>
                                                        <AddOtherImage url={url}/>
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                )
            }
        </Formik>
    );
};

export default FormAddPortfolio;
