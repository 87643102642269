import React, {FC, useState} from 'react';
import DataTable from "react-data-table-component";
import AddMaterials from "../modal/AddMaterials";

interface  IWidgetTableMaterials{
    addMyMaterials: any,
    setAddMyMaterials(value: any): void
}

const WidgetTableMaterials: FC <IWidgetTableMaterials> = ({addMyMaterials, setAddMyMaterials}) => {
    const [update, setUpdate] = useState(false);
    const [selectMaterials, setselectMaterials] = useState<any | null>(null);

    const columns : any = [
        {
            id: "name_material",
            name: <span className='font-weight-bold fs-13'>Наименование</span>,
            selector: (row: any) => row.name_material,
            wrap: true,
        },
        {
            id: "count",
            name: <span className='font-weight-bold fs-13'>Количество</span>,
            selector: (row: any) => row.count,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold fs-13'></span>,
            selector: (row: any) => <i className="bx bx-trash fs-24 cursor-pointer" onClick={() => {removeDisinfect(row.uid_material)}}></i>,
            width: '60px'
        }
    ]
    const removeDisinfect = (uid: string) => {
        setAddMyMaterials(addMyMaterials.filter((item: any) => item.uid_material !== uid));
    }

    return (
        <>
            <DataTable
                columns={columns}
                data={addMyMaterials}
                noDataComponent={'Нет данных'}
                pagination
                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                paginationComponentOptions={
                    {
                        rowsPerPageText: 'Видно льгот:',
                        rangeSeparatorText: 'из'
                    }
                }
                highlightOnHover
                customStyles={{
                    rows: {
                        highlightOnHoverStyle: {
                            backgroundColor: "#ffebb5"
                        },
                    },
                    expanderRow: {
                        style: {
                            backgroundColor: "#ffebb5",
                            paddingTop: '5px',
                            paddingBottom: '5px'
                        },
                    },
                }}
                onRowClicked={(row) => {
                    setselectMaterials(row);
                    setUpdate(true);
                }}
            />
            <button className="btn btn-primary" type="button"
                    onClick={() => {
                        setselectMaterials(null)
                        setUpdate(true)}}>Добавить
            </button>
            <AddMaterials update={update}
                                            setUpdate={(value) => setUpdate(value)}
                                            addMyMaterials={addMyMaterials}
                                            setAddMyMaterials={(value: any) => setAddMyMaterials(value)}
                                            selectMaterials={selectMaterials}/>
        </>
    );
};

export default WidgetTableMaterials;
