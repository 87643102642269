import React, {FC} from 'react';
import {Col, Row} from "reactstrap";
import {Field} from "formik";
import MaskedInput from "react-text-mask";
import {AddressSuggestions, DaDataAddress, DaDataSuggestion} from "react-dadata";

interface IMatherField {
    touched: any,
    errors: any,
    address: any,
    setAddress: (value: any) => void,
    setAddressFull: (value: any) => void,
    citizenships: any[];
}

const MatherField: FC <IMatherField> = ({touched, errors, address, setAddress, setAddressFull,citizenships}) => {

    const handleSelect = (suggestion: DaDataSuggestion<DaDataAddress> | undefined) => {
        if (suggestion) {
            setAddress(suggestion.unrestricted_value);
            setAddressFull(suggestion.data);
        }
    }

    return (
        <>
            <h4 className="text-center mt-sm-3 mt-lg-3 mt-xl-3 mt-xll-3">Данные мамы ребенка</h4>
            <Row>
                <Col xxl={8}>
                    <Field
                        name="matherFullName"
                        placeholder="ФИО мамы"
                        className={touched.matherFullName && errors.matherFullName ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                    />
                </Col>
                <Col>
                    <Field name="matherBirthdate">
                        {({
                              field
                          }:any) => (
                            <MaskedInput
                                name="matherBirthdate"
                                mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                placeholder="Дата рождения"
                                {...field}
                                className={touched.matherBirthdate && errors.matherBirthdate ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                            />
                        )}
                    </Field>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field
                        as="select"
                        name="matherCitizenship"
                        className={touched.matherCitizenship && errors.matherCitizenship ? "mt-2 form-select mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-select mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                    >
                        <option value="" disabled hidden>Выберите гражданство</option>
                        {citizenships.map((citizenship) => (
                            <option key={citizenship.id} value={citizenship.id}>
                                {citizenship.name}
                            </option>
                        ))}
                    </Field>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field
                        name="matherSeriesPassport"
                        placeholder="Серия паспорта"
                        className={touched.matherSeriesPassport && errors.matherSeriesPassport ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                    />
                </Col>
                <Col>
                    <Field
                        name="matherNumberPassport"
                        placeholder="Номер паспорта"
                        className={touched.matherNumberPassport && errors.matherNumberPassport ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                    />
                </Col>
                <Col>
                    <Field name="matherDateIssuePass">
                        {({
                              field
                          }:any) => (
                            <MaskedInput
                                name="matherDateIssuePass"
                                mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                placeholder="Дата выдачи"
                                {...field}
                                className={touched.matherDateIssuePass && errors.matherDateIssuePass ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                            />
                        )}
                    </Field>
                </Col>
            </Row>
            <Row>
                <Col >
                    <Field
                        name="matherWhoIssuePass"
                        placeholder="Кем выдан"
                        className={touched.matherWhoIssuePass && errors.matherWhoIssuePass ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                    />
                </Col>
                <Col>
                    <Field
                        name="matherPassportCodePodr"
                        placeholder="Код подразделения"
                        className={touched.matherPassportCodePodr && errors.matherPassportCodePodr ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field
                        name="matherSnils"
                        placeholder="СНИЛС"
                        className={touched.matherSnils && errors.matherSnils ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field
                        type="phone"
                        name="matherPhone"
                        placeholder="Мобильный телефон"
                        className={touched.matherPhone && errors.matherPhone ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field
                        name="matherPlaceWork"
                        placeholder="Место работы"
                        className={touched.matherPlaceWork && errors.matherPlaceWork ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                    />
                </Col>
                <Col>
                    <Field
                        name="matherPosition"
                        placeholder="Должность"
                        className={touched.matherPosition && errors.matherPosition ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field name="address">
                        {({
                              field
                          }:any) => (
                            <AddressSuggestions
                                token="ef14be653a9a2c47d5cb2e29d1b6b140161a6d4b"
                                value={address}
                                inputProps={{
                                    'placeholder': 'Адрес прописки (проживания)',
                                    'className' : 'mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2',
                                }}
                                {...field}
                                onChange={(address)=>handleSelect(address)}
                            />
                        )}
                    </Field>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field
                        name="email"
                        placeholder="Электронная почта"
                        className={touched.email && errors.email ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                    />
                </Col>
            </Row>
        </>
    );
};

export default MatherField;
