import React, {FC, useState} from 'react';
import {useStateContext} from "../../../hooks/useStateContext";
import AuthService from "../../../api/services/authService/AuthService";
import {IUser} from "../../../models/IUser";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {NavLink} from "react-router-dom";
import userDummayImage from "../../../assets/images/users/user-dummy-img.jpg";
import {useAccessibility} from "../../../context/AccessibilityContext";

const ProfileDropdown: FC = () => {
    const { fontSize, kerning } = useAccessibility();
    const [isProfileDropdown, setIsProfileDropdown] = useState<boolean>(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    const {user, setUser, setToken, setTokenSession, setLockscreen} = useStateContext();

    const logout = (): void => {
        AuthService.logout()
            .then(() => {
                setUser({} as IUser)
                setToken(null)
                setTokenSession(null)
            })

        //return <Navigate to={'/login'} />
    }

    const lockscreen = (): void => {
        setLockscreen("true");
    }

    return (
        <>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn shadow-none">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={!user.photo ? userDummayImage : `https://api.mydou.ru${user.photo}`}
                             alt="Header Avatar" />
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text" style={{ fontSize: `${fontSize}%`, letterSpacing: `${kerning===1 ? "" : `${kerning}px`}`}}>{user.name}</span>
                            <span className={`d-none d-xl-block ms-1  text-muted user-name-sub-text ${fontSize=== 90 ? "fs-12" : ''}`} style={{ fontSize: `${fontSize}%`, letterSpacing: `${kerning===1 ? "" : `${kerning}px`}`}}>{user.position ? user.position.name : null}</span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">

                    <h6 className="dropdown-header">Добро пожаловать {user.name} !</h6>
                    {
                            <>
                                <NavLink to={process.env.PUBLIC_URL + "/profile"}>
                                    <DropdownItem><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                                        <span className="align-middle">Профиль</span></DropdownItem>
                                </NavLink>
                                <NavLink to={process.env.PUBLIC_URL + "/help"}>
                                    <DropdownItem><i
                                        className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span
                                        className="align-middle">Помощь</span></DropdownItem>
                                    <div className="dropdown-divider"></div>
                                </NavLink>
                                <NavLink to={process.env.PUBLIC_URL + "/settings"}>
                                    <DropdownItem><i
                                        className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span
                                        className="align-middle">Настройки</span></DropdownItem>
                                </NavLink>
                                <DropdownItem onClick={() => lockscreen()} ><i
                                    className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Заблокировать экран</span></DropdownItem>
                                <DropdownItem onClick={() => logout()}><i
                                    className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                                    className="align-middle" data-key="t-logout">Выход</span></DropdownItem>
                            </>
                    }
                </DropdownMenu>
            </Dropdown>
        </>
    );
};

export default ProfileDropdown;
