import React, {FC} from 'react';
import {Col, Row} from "reactstrap";
import CardPortfolioAllEvents from "./CardPortfolioAllEvents";
import {IPortfolio} from "../../models/kindergarten/IPortfolio";

interface IPortfolioEvents {
    portfolios: IPortfolio[]
}
const PortfolioEvents: FC<IPortfolioEvents> = ({portfolios}) => {
    return (
        <Row>
            {
                portfolios.map(i => {
                    return (
                        <div className="mt-4" key={i.year}>
                            <Col>
                                <h4 className="card_header_size_text">{i.year}</h4>
                                <CardPortfolioAllEvents months={i.months}/>
                            </Col>
                        </div>
                    )

                })
            }
        </Row>
    );
};

export default PortfolioEvents;
