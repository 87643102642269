import React, {FC, useState} from 'react';
import { Input, Modal, ModalBody, ModalHeader} from "reactstrap";
import {Field, Form, Formik} from "formik";
import AxiosClient from "../../api/AxiosClient";
import {useStateContext} from "../../hooks/useStateContext";
import {useAppDispatch} from "../../hooks/redux";
import {useEscapeKey} from "../../hooks/useEscapeKey";
import {setModalAdd} from "../../store/reducers/widgets/WidgetsSlice";

interface IModalRegister {
    registerModal: boolean,
    setRegisterModal: (value: boolean) => void
    setRegister: (values : boolean) => void
    id: number
}
const ModelRegister: FC<IModalRegister> = ({setRegisterModal, registerModal, setRegister, id}) => {
    const {user} = useStateContext()

    const [isAgree, setIsAgree] = useState(false);

    const dispatch = useAppDispatch();
    useEscapeKey(() => dispatch(setModalAdd(false)), registerModal, "ModelRegister");

    return (
        <Modal id="ModelRegister" isOpen={registerModal}
               toggle={() => {
                   setRegisterModal(!registerModal)
               }}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => {
                setRegisterModal(!registerModal)
            }} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Запись
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={{
                    fio: '',
                    user_id: user.id,
                    organization: '',
                    position: '',
                    region: 'Центральный',
                    city: '',
                    phone: '',
                    email: '',
                    seminar_id: id,
                    status: 'Записан'
                }}
                onSubmit={(values) => {
                        AxiosClient.post('/add_user_seminar', values)
                            .then(() => {
                                setRegister(true)
                                setRegisterModal(false)
                            })
                }}
                >
                    <Form>
                        <div>
                            <div className="col-auto mb-2">
                                <label>ФИО:</label>
                                <div className="col mb-2">
                                    <Field type="text" name="fio" className="form-control"/>
                                </div>
                            </div>
                            <div className="col-auto mb-2">
                                <label>Организация:</label>
                                <div className="col mb-2">
                                    <Field type="text" name="organization" className="form-control"/>
                                </div>
                            </div>
                            <div className="col-auto mb-2">
                                <label>Должность:</label>
                                <div className="col mb-2">
                                    <Field type="text" name="position" className="form-control"/>
                                </div>
                            </div>
                            <div className="col-auto mb-2">
                                <label>Регион:</label>
                                <div className="col mb-2">
                                    <Field as="select" name="region" id="" className="form-select">
                                        <option value="Центральный">Центральный</option>
                                        <option value="Северо-Западный">Северо-Западный</option>
                                        <option value="Южный">Южный</option>
                                        <option value="Приволжский">Приволжский</option>
                                        <option value="Уральский">Уральский</option>
                                        <option value="Сибирский">Сибирский</option>
                                        <option value="Дальневосточный">Дальневосточный</option>
                                        <option value="Северо-Кавказский">Северо-Кавказский</option>
                                    </Field>
                                </div>
                            </div>
                            <div className="col-auto mb-2">
                                <label>Город:</label>
                                <div className="col mb-2">
                                    <Field type="text" name="city" className="form-control"/>
                                </div>
                            </div>
                            <div className="col-auto mb-2">
                                <label>Телефон:</label>
                                <div className="col mb-2">
                                    <Field type="text" name="phone" className="form-control"/>
                                </div>
                            </div>
                            <div className="col-auto mb-2">
                                <label>Почта:</label>
                                <div className="col mb-2">
                                    <Field type="text" name="email" className="form-control"/>
                                </div>
                            </div>
                            <div className="col-auto mb-2">
                                <div className="col mb-2">
                                    <Input className="form-check-input" type="checkbox" onClick={() => setIsAgree(!isAgree)}/><label className="mx-2">Согласие на обработку персональных данных</label>
                                </div>
                            </div>
                        </div>
                        <div className="hstack gap-2 justify-content-end mt-2">
                            <button type="submit" className="btn btn-primary" id="addNewProject" disabled={!isAgree}>
                                    Записаться
                            </button>
                        </div>
                    </Form>
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModelRegister;
