import React, {FC} from 'react';
import {IStudent} from "../../models/widgets/IStudent";
import {Field} from "formik";
import {Card, CardBody, Input} from "reactstrap";
import DataTable from "react-data-table-component";

interface ITableChildrenParty {
    students: IStudent[],
    setCheckAll: (check: boolean) => void
}
const TableChildrenParty: FC<ITableChildrenParty> = ({students, setCheckAll}) => {

    const columns: any = [
        {
            name: <Input type="checkbox" name="checkAll" className="border-primary m-0" style={{width: '20px', height: '20px'}} onChange={(e) => setCheckAll(e.target.checked)}/>,
            cell: (row: any, i: number) => (
                <Field type="checkbox" className="border-primary m-0" style={{width: '20px', height: '20px'}} name={`checkbox[${i}].${row.code}`}/>
            ),
            style: {
                fontSize: '14px'
            },
            width: '20%'
        },
        {
            id: "name",
            name: <span className='font-weight-bold accessibility_size_table'>ФИО Ребенка</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.fio}</span>,
            wrap: true,
            sortable: true
        }
    ]

    return (
        <Card>
            <CardBody>
                <DataTable columns={columns}
                           data={students ? [...students].sort((a, b) => a.fio.localeCompare(b.fio)) : []}
                           noDataComponent={'Нет детей'}
                           fixedHeaderScrollHeight={"56rem"}
                           fixedHeader={true}/>
            </CardBody>
        </Card>
    );
};

export default TableChildrenParty;
