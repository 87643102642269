import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import WidgetRequiredFlatpikerPeriod from "../components/widgets/requireds/WidgetRequiredFlatpikerPeriod";
import WidgetTableAllInfoOperationalControl from "../components/widgets/tables/WidgetTableAllInfoOperationalControl";
import WidgetGraphProjectOverviewOperationalControl
    from "../components/widgets/graphs/WidgetGraphProjectOverviewOperationalControl";
import WidgetGraphRoundOperationalControl from "../components/widgets/graphs/WidgetGraphRoundOperationalControl";
import {useAppSelector} from "../hooks/redux";
import moment from "moment/moment";
import AxiosClient from "../api/AxiosClient";

const OperationalControl = () => {
    const {date} = useAppSelector(state => state.WidgetReducer)
    const [data, setData] = useState<any[]>([]);
    const [dateGraph, setDateGraph] = useState<any[]>([]);
    const [dataGraphRound, setDataGraphRound] = useState<any[]>([]);
    useEffect(() => {
        queryJournal()
    }, [date]);

    const queryJournal = () => {
        const params = {
            date: [moment(date[0]).format('DD.MM.YYYY'), moment(date[1]).format('DD.MM.YYYY')]
        }
        AxiosClient.get('/get_journal_operate_list', {params})
            .then((r) => {
                setData(r.data)
                setDateGraph(convertDateGraph(r.data))
                setDataGraphRound(convertDateGraphDonut(r.data))
            })
            .catch(() => console.log('error'))
    }

    const convertDateGraph = (data: any[]) => {
        let groupedData: any[] = [];

        data.forEach((item: any) => {
            const monthName = moment(item.data).format('MMMM');
            const existingMonth = groupedData.find(entry => entry.month === monthName);
            if (existingMonth) {
                existingMonth.hard += item.remarks.filter((el: any) => el.mark === 'Грубые нарушения').length
                existingMonth.light += item.remarks.filter((el: any) => el.mark === 'Незначительные замечания').length
                existingMonth.none += item.remarks.filter((el: any) => el.mark === 'Замечаний нет').length
                existingMonth.all += item.remarks.length
            } else {
                groupedData.push({
                    month: monthName,
                    hard: item.remarks.filter((el: any) => el.mark === 'Грубые нарушения').length,
                    light: item.remarks.filter((el: any) => el.mark === 'Незначительные замечания').length,
                    none: item.remarks.filter((el: any) => el.mark === 'Замечаний нет').length,
                    all: item.remarks.length
                });
            }
        });

        return groupedData;
    }

    const convertDateGraphDonut = (data: any[]) => {
        let groupedData: any[] = [];

        data.forEach((item: any) => {
            item.remarks.forEach((elem: any) => {
                const existingMonth = groupedData.find(entry => entry.name === elem.mark);
                if (existingMonth) {
                    existingMonth.count += 1
                } else {
                    groupedData.push({
                        name: elem.mark,
                        count: 1
                    });
                }
            })
        });

        return groupedData;
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Журнал оперативного контроля</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xxl={3}>
                            <WidgetRequiredFlatpikerPeriod/>
                            <WidgetGraphRoundOperationalControl data={dataGraphRound}/>
                        </Col>
                        <Col xxl={9}>
                            <WidgetGraphProjectOverviewOperationalControl data={dateGraph}/>
                            <WidgetTableAllInfoOperationalControl updateAllData={() => queryJournal()} data={data}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default OperationalControl;
