import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import WidgetService from "../../../api/services/widgetService/WidgetService";
import {setToUpdateGraphMainPage} from "../../../store/reducers/widgets/WidgetsSlice";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {ProjectsJournalHaccpAntisepticRoom} from "../../widgets/ProjectStatusCharts";
import {getJournalHaccpAntisepticRoom} from "../../../store/reducers/widgets/ActionCreator";

const WidgetGraphAntisepticRoom = () => {
    const { toUpdateGraphMainPage, date, journalHaccpAntisepticRoom} = useAppSelector(state => state.WidgetReducer)
    const dispatch = useAppDispatch();

    useEffect(() => {
        toUpdateGraphMainPage &&
        WidgetService.getDeviationGroupMonth(null, date)
            .then(res => {
                dispatch(setToUpdateGraphMainPage(false))
            })
    }, [toUpdateGraphMainPage, date, dispatch])


    return (
        <>
            <Card>
                <CardHeader className="border-0 align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Журнал обработки помещений</h4>
                </CardHeader>
                <CardHeader className="p-0 border-0 bg-soft-light">
                    <Row className="g-0 text-center">
                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                <span className="counter-value">
                                    {journalHaccpAntisepticRoom.length > 0 ? (
                                        Math.round(journalHaccpAntisepticRoom.reduce((totalTimePlan, entry) => {
                                            const [hours, minutes] = entry.timePlan.split(":").map(Number);
                                            return totalTimePlan + (hours * 60 + minutes);
                                        }, 0) / journalHaccpAntisepticRoom.length / 60)
                                    ) : 0}
                                </span>
                                </h5>
                                <p className="text-muted mb-0">Среднее плановое время</p>
                            </div>
                        </Col>
                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                <span className="counter-value">
                                    {journalHaccpAntisepticRoom.length > 0 ? (
                                        Math.round(journalHaccpAntisepticRoom.reduce((totalTimeFact, entry) => {
                                            const [hours, minutes] = entry.timeOfDisinfection.split(":").map(Number);
                                            return totalTimeFact + (hours * 60 + minutes);
                                        }, 0) / journalHaccpAntisepticRoom.length / 60)
                                    ) : 0}
                                </span>
                                </h5>
                                <p className="text-muted mb-0">Среднее фактическое время</p>
                            </div>
                        </Col>
                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                <span className="counter-value">
                                    {journalHaccpAntisepticRoom.length > 0 ? (
                                        journalHaccpAntisepticRoom.filter(entry => entry.timeOfDisinfection === entry.timePlan).length
                                    ) : 0}
                                </span>
                                </h5>
                                <p className="text-muted mb-0">Выполнено в срок</p>
                            </div>
                        </Col>
                    </Row>

                </CardHeader>

                <CardBody className="p-0 pb-2">
                    <div>
                        {
                            journalHaccpAntisepticRoom && <ProjectsJournalHaccpAntisepticRoom series={journalHaccpAntisepticRoom}
                                                                                                            dataColors='["--vz-green", "--vz-warning", "--vz-danger"]'/>
                        }
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default WidgetGraphAntisepticRoom;
