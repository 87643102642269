import React, {FC, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import {setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../hooks/redux";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import AxiosClient from "../../../api/AxiosClient";
import Notification from "../../widgets/Notification";
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface ModalAddAgeCategoryProps {
    addAgeCategory: boolean,
    setAddAgeCategory: (value: boolean) => void,
    setAgeCategories: (value: any[]) => void
}

const ModalAddAgeCategory: FC<ModalAddAgeCategoryProps> = ({setAgeCategories, addAgeCategory, setAddAgeCategory}) => {
    const dispatch = useAppDispatch();
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<string>('');
    const [notificationMessage, setNotificationMessage] = useState<string>('');
    useEscapeKey(() => dispatch(setModalAdd(false)), addAgeCategory, "ModalAddAgeCategory");

    return (
        <>
            <Modal id="ModalAddAgeCategory" isOpen={addAgeCategory}
                   toggle={() => setAddAgeCategory(!addAgeCategory)}
                   modalClassName="zoomIn" tabIndex={-1} centered>
                <ModalHeader toggle={() => setAddAgeCategory(!addAgeCategory)}
                             className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Добавление возрастной категории
                </ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={{name: ''}}
                        onSubmit={(values) => {
                            dispatch(setIsSend(true))
                            AxiosClient.post('/age_categories/create', {name: values.name})
                                .then(() => {
                                    dispatch(setIsSend(false))
                                    AxiosClient.get('/age_categories')
                                        .then(r => {
                                            setAgeCategories(r.data)
                                        })
                                    setAddAgeCategory(false)
                                    setNotificationType('success');
                                    setNotificationMessage('Возрастная категория добавлена');
                                    setShowNotification(true);
                                })
                                .catch(() => {
                                    dispatch(setIsSend(false))
                                    setNotificationType('error');
                                    setNotificationMessage('Ошибка при добавлении возрастной категории');
                                    setShowNotification(true);
                                })
                        }}
                    >
                        {
                            ({errors, touched}) => (
                                <Form>
                                    <Row className={"mb-2"}>
                                        <Col>
                                            <label className="w-100">Наименование:
                                            <Field name="name"
                                                   className={`form-control ${errors.name && touched.name && "border border-2 border-danger"}`}/>
                                            </label>
                                        </Col>
                                    </Row>
                                    <SubmitWithLoading text={'Добавить'}/>
                                </Form>
                            )
                        }
                    </Formik>
                </ModalBody>
            </Modal>
            {showNotification && <Notification type={notificationType} message={notificationMessage} setShowNotification={(value: boolean) => setShowNotification(value)}/>}
        </>
    );
};

export default ModalAddAgeCategory;
