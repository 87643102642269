import React, {FC} from 'react';
import {Col, Row} from "reactstrap";
import {Field} from "formik";
import MaskedInput from "react-text-mask";
import {AddressSuggestions, DaDataAddress, DaDataSuggestion} from "react-dadata";

interface IFatherField {
    touched: any,
    errors: any,
    addressFather: any,
    setAddressFather: (value: any) => void,
    setAddressFullFather: (value: any) => void,
    citizenships: any[];
}

const FatherField: FC <IFatherField> = ({touched, errors, addressFather, setAddressFather, setAddressFullFather, citizenships}) => {

    const handleSelectFather = (suggestion: DaDataSuggestion<DaDataAddress> | undefined) => {
        if (suggestion) {
            setAddressFather(suggestion.unrestricted_value);
            setAddressFullFather(suggestion.data);
        }
    }

    return (
        <>
            <h4 className="text-center mt-sm-3 mt-lg-3 mt-xl-3 mt-xll-3">Данные папы ребенка</h4>
            <Row>
                <Col xxl={8}>
                    <Field
                        name="fatherFullName"
                        placeholder="ФИО папы"
                        className={touched.fatherFullName && errors.fatherFullName ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                    />
                </Col>
                <Col>
                    <Field name="fatherBirthdate">
                        {({
                              field
                          }:any) => (
                            <MaskedInput
                                name="fatherBirthdate"
                                mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                placeholder="Дата рождения"
                                {...field}
                                className={touched.fatherBirthdate && errors.fatherBirthdate ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                            />
                        )}
                    </Field>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field
                        as="select"
                        name="fatherCitizenship"
                        className={touched.fatherCitizenship && errors.fatherCitizenship ? "mt-2 form-select mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-select mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                    >
                        <option value="" disabled hidden>Выберите гражданство</option>
                        {citizenships.map((citizenship) => (
                            <option key={citizenship.id} value={citizenship.id}>
                                {citizenship.name}
                            </option>
                        ))}
                    </Field>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field
                        name="fatherSeriesPassport"
                        placeholder="Серия паспорта"
                        className={touched.fatherSeriesPassport && errors.fatherSeriesPassport ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                    />
                </Col>
                <Col>
                    <Field
                        name="fatherNumberPassport"
                        placeholder="Номер паспорта"
                        className={touched.fatherNumberPassport && errors.fatherNumberPassport ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                    />
                </Col>
                <Col>
                    <Field name="fatherDateIssuePass">
                        {({
                              field
                          }:any) => (
                            <MaskedInput
                                name="fatherDateIssuePass"
                                mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                placeholder="Дата выдачи"
                                {...field}
                                className={touched.fatherDateIssuePass && errors.fatherDateIssuePass ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                            />
                        )}
                    </Field>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field
                        name="fatherWhoIssuePass"
                        placeholder="Кем выдан"
                        className={touched.fatherWhoIssuePass && errors.fatherWhoIssuePass ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                    />
                </Col>
                <Col>
                    <Field
                        name="fatherPassportCodePodr"
                        placeholder="Код подразделения"
                        className={touched.fatherPassportCodePodr && errors.fatherPassportCodePodr ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field
                        name="fatherSnils"
                        placeholder="СНИЛС"
                        className={touched.fatherSnils && errors.fatherSnils ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field
                        type="phone"
                        name="fatherPhone"
                        placeholder="Мобильный телефон"
                        className={touched.fatherPhone && errors.fatherPhone ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field
                        name="fatherPlaceWork"
                        placeholder="Место работы"
                        className={touched.fatherPlaceWork && errors.fatherPlaceWork ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                    />
                </Col>
                <Col>
                    <Field
                        name="fatherPosition"
                        placeholder="Должность"
                        className={touched.fatherPosition && errors.fatherPosition ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field name="fatherAddress">
                        {({
                              field
                          }:any) => (
                            <AddressSuggestions
                                token="ef14be653a9a2c47d5cb2e29d1b6b140161a6d4b"
                                value={addressFather}
                                inputProps={{
                                    'placeholder': 'Адрес прописки (проживания)',
                                    'className' : 'mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2',
                                }}
                                {...field}
                                onChange={(address)=>handleSelectFather(address)}
                            />
                        )}
                    </Field>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field
                        name="fatherEmail"
                        placeholder="Электронная почта"
                        className={touched.fatherEmail && errors.fatherEmail ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 is-invalid" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                    />
                </Col>
            </Row>
        </>
    );
};

export default FatherField;
