import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IWidget} from "../../../models/IWidget";
import {IChildren} from "../../../models/IChildren";
import {ICategoryOvz} from "../../../models/ICategoryOvz";
import {ILimitHealth} from "../../../models/ILimitHealth";
import {IGroupHealth} from "../../../models/IGroupHealth";
import {IRecommendedActive} from "../../../models/IRecommendedActive";
import {IStudent} from "../../../models/widgets/IStudent";
import {IGroup} from "../../../models/widgets/IGroup";
import {IChildrenAttendances} from "../../../models/widgets/IChildrenAttendances";
import {IPortfolio} from "../../../models/kindergarten/IPortfolio";
import {IAllDailyReportsNutrition} from "../../../models/widgets/IAllDailyReportsNutrition";
import {IAllDailyReportsSleep} from "../../../models/widgets/IAllDailyReportsSleep";
import {IAllDailyReportsMood} from "../../../models/widgets/IAllDailyReportsMood";
import {ITableAllInfoGroup} from "../../../models/widgets/ITableAllInfoGroup";
import {ISelectChat} from "../../../models/widgets/ISelectChat";
import moment from "moment";
import {ITableJournalHaccpTemperature} from "../../../models/journalHaccp/ITableJournalHaccpTemperature";
import {ITableJournalHaccpVentilationRoom} from "../../../models/journalHaccp/ITableJournalHaccpVentilationRoom";
import {IPost} from "../../../models/widgets/IPost";
import {INotification} from "../../../models/INotification";

interface IWidgetState {
    isLoading: boolean,
    isError: string,
    successUpdate: string,
    isSend: boolean,
    showNotification: INotification | null,

    widgets: IWidget[]
    modalAdd: boolean,
    dateAdd: Date,
    date: Date[],
    groupModalAdd: string,
    groupModalSign: string,
    students: IStudent[],
    groups: IGroup[],
    groupAllDeviations: string,
    posts: IPost[],
    postSelect: string,
    modalSign: boolean,
    dateSign: Date[],
    toUpdateGraphMainPage: boolean
    toUpdateGraphMainPageDo: boolean

    modalAddDO: boolean,
    groupModalAddDO: string,
    groupModalSignDO: string,
    studentsDO: IStudent[],
    groupsDO: IGroup[],
    groupAllDeviationsDO: string,
    modalSignDO: boolean,

    groupsHaccp: IGroup[],
    groupAllDeviationsHaccp: string,

    modalAddEmployee: boolean,
    modalUpdateEmployee: boolean,

    modalAddDispansaryObservation: boolean,
    modalUpdateDispansaryObservation: boolean,
    children: IChildren[],
    categoryOvzs: ICategoryOvz[],
    limitHealth: ILimitHealth[],
    groupHealth: IGroupHealth[],
    recomendedActives: IRecommendedActive[],
    dispansaryObservations: [],
    selectDispansaryObservation: {},
    //
    modalAddRegisterDefectEquipment: boolean,
    modalAddActEquipmentInspectionCertificate: boolean,
    journalPurchaseMaterials: any[],

    childrenAttendance: IChildrenAttendances[],
    portfolios: IPortfolio[],
    successDeletePortfolio: boolean,
    selectDeletePortfolio: number | null,
    allDailyReportNutritional: IAllDailyReportsNutrition[],
    allDailyReportSleep: IAllDailyReportsSleep[],
    allDailyReportsMood: IAllDailyReportsMood[],

    allInfoGroup: ITableAllInfoGroup[],
    allInfoGroupDo: ITableAllInfoGroup[],

    selectChat: ISelectChat | null,
    selectDeviationAll: string,
    journalHaccpTemperature: ITableJournalHaccpTemperature | null,
    journalHaccpVentilationRoom: any[],
    journalHaccpGeneralCleaning: any[],
    journalHaccpMorningFilterEmployee: any[],
    journalHaccpMorningFilterChildren: any[],
    journalHaccpReplacementBedLinen: any[],
    journalHaccpMeasuringTemperatureBody: any[],
    journalHaccpAntisepticTableware: any[],
    journalHaccpAntisepticSurface: any[],
    journalHaccpAntisepticRoom: any[],
    journalHaccpBactericidalLamps: any[],
    journalCleaningDisinfection: any[],
}

const initialState: IWidgetState = {
    isLoading: false,
    isError: "",
    successUpdate: "",
    isSend: false,
    showNotification: null,

    widgets: [],
    modalAdd: false,
    dateAdd: new Date(),
    date: [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
    groupModalAdd: '',
    groupModalSign: '',
    students: [],
    groups: [],
    groupAllDeviations: '',
    posts: [],
    postSelect: '',
    modalSign: false,
    dateSign: [new Date(new Date().setDate(1)), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)],
    toUpdateGraphMainPage: false,
    toUpdateGraphMainPageDo: false,
    ///
    modalAddDO: false,
    groupModalAddDO: '',
    groupModalSignDO: '',
    studentsDO: [],
    groupsDO: [],
    groupAllDeviationsDO: '',
    modalSignDO: false,

    groupsHaccp: [],
    groupAllDeviationsHaccp: '',
    //
    modalAddEmployee: false,
    modalUpdateEmployee: false,
    //
    modalAddDispansaryObservation: false,
    modalUpdateDispansaryObservation: false,
    children: [],
    categoryOvzs: [],
    limitHealth: [],
    groupHealth: [],
    recomendedActives: [],
    dispansaryObservations: [],
    selectDispansaryObservation: {},
    //
    modalAddRegisterDefectEquipment: false,
    modalAddActEquipmentInspectionCertificate: false,
    journalPurchaseMaterials: [],
    childrenAttendance: [],
    portfolios: [],
    successDeletePortfolio: false,
    selectDeletePortfolio: null,
    allDailyReportNutritional: [],
    allDailyReportSleep: [],
    allDailyReportsMood: [],
    allInfoGroup: [],
    allInfoGroupDo: [],
    selectChat: null,
    selectDeviationAll: '',
    //
    journalHaccpTemperature: null,
    journalHaccpVentilationRoom: [],
    journalHaccpGeneralCleaning: [],
    journalHaccpMorningFilterEmployee: [],
    journalHaccpMorningFilterChildren: [],
    journalHaccpReplacementBedLinen: [],
    journalHaccpMeasuringTemperatureBody: [],
    journalHaccpAntisepticTableware: [],
    journalHaccpAntisepticSurface: [],
    journalHaccpAntisepticRoom: [],
    journalHaccpBactericidalLamps: [],
    journalCleaningDisinfection: [],
}

export const WidgetsSlice = createSlice({
    name: "widgets",
    initialState,
    reducers: {
        setIsSend(state, {payload}){
            state.isSend = payload;
        },
        dataLoading (state){
            state.isLoading = true;
        },
        dataLoadingError(state, action: PayloadAction<string> ){
            state.isLoading = false;
            state.isError = action.payload
        },
        setToUpdateGraphMainPage(state, action: PayloadAction<boolean> ){
            state.toUpdateGraphMainPage = action.payload;
        },
        setToUpdateGraphMainPageDo(state, action: PayloadAction<boolean> ){
            state.toUpdateGraphMainPageDo = action.payload;
        },
        successUpdate(state, action: PayloadAction<string>){
          state.successUpdate = action.payload;
        },
        setShowNotification(state, action: PayloadAction<INotification>){
            state.showNotification = action.payload;
        },
        setLoadingWidgets: (state, action: PayloadAction<IWidget[]>) => {
            state.isLoading = false;
            state.isError = "";
            state.widgets = action.payload;
        },
        setWidgets: (state, action: PayloadAction<IWidget[]>) => {
            state.widgets = action.payload;
        },
        setDate: (state, action: PayloadAction<Date[]>) => {
            state.date = action.payload;
        },
        setDateAdd: (state, action: PayloadAction<Date>) => {
            state.dateAdd = action.payload;
        },
        setModalAdd: (state, action: PayloadAction<boolean>) => {
            state.modalAdd = action.payload;
        },
        setGroupModalAdd: (state, action: PayloadAction<string>) => {
            state.groupModalAdd = action.payload;
        },
        setGroupModalSign: (state, action: PayloadAction<string>) => {
            state.groupModalSign = action.payload;
        },
        setLoadingStudents: (state, action: PayloadAction<IStudent[]>) => {
            state.isLoading = false;
            state.isError = "";
            state.students = action.payload;
        },
        setLoadingGroups: (state, action: PayloadAction<IGroup[]>) => {
            state.isLoading = false;
            state.isError = "";
            state.groups = action.payload;
        },
        setGroupAllDeviations: (state, action: PayloadAction<string>) => {
            state.groupAllDeviations = action.payload;
        },
        setLoadingPosts: (state, action: PayloadAction<IPost[]>) => {
            state.isLoading = false;
            state.isError = "";
            state.posts = action.payload;
        },
        setPostSelect: (state, action: PayloadAction<string>) => {
            state.postSelect = action.payload;
        },
        setModalSign: (state, action: PayloadAction<boolean>) => {
            state.modalSign = action.payload;
        },
        setDateSign: (state, action: PayloadAction<Date[]>) => {
            state.dateSign = action.payload;
        },
        setGroupModalAddDO: (state, action: PayloadAction<string>) => {
            state.groupModalAddDO = action.payload;
        },
        setGroupModalSignDO: (state, action) => {
            state.groupModalSignDO = action.payload;
        },
        setGroupAllDeviationsDO: (state, action) => {
            state.groupAllDeviationsDO = action.payload;
        },
        setLoadingGroupsDO: (state, action: PayloadAction<IGroup[]>) => {
            state.isLoading = false;
            state.isError = "";
            state.groupsDO = action.payload;
        },
        setModalAddDO: (state, action) => {
            state.modalAddDO = action.payload;
        },
        setModalSignDO: (state, action) => {
            state.modalSignDO = action.payload;
        },
        setLoadingStudentsDO: (state, action: PayloadAction<IStudent[]>) => {
            state.isLoading = false;
            state.isError = "";
            state.studentsDO = action.payload;
        },
        setLoadingGroupsHaccp: (state, action: PayloadAction<IGroup[]>) => {
            state.isLoading = false;
            state.isError = "";
            state.groupsHaccp = action.payload;
        },
        setGroupAllDeviationsHaccp: (state, action: PayloadAction<string>) => {
            state.groupAllDeviationsHaccp = action.payload;
        },
        setModalAddEmployee: (state, action) => {
            state.modalAddEmployee = action.payload;
        },
        setModalUpdateEmployee: (state, action: PayloadAction<boolean>) => {
            state.modalUpdateEmployee = action.payload
        },
        setModalAddDispansaryObservation: (state, action) => {
            state.modalAddDispansaryObservation = action.payload;
        },
        setModalUpdateDispansaryObservation: (state, action) => {
            state.modalUpdateDispansaryObservation = action.payload;
        },
        setChildren: (state, action) => {
            state.children = action.payload;
        },
        setCategoryOvzs: (state, action) => {
            state.categoryOvzs = action.payload;
        },
        setLimitHealth: (state, action) => {
            state.limitHealth = action.payload;
        },
        setGroupHealth: (state, action) => {
            state.groupHealth = action.payload;
        },
        setRecomendedActives: (state, action) => {
            state.recomendedActives = action.payload;
        },
        setDispansaryObservation: (state, action) => {
            state.dispansaryObservations = action.payload;
        },
        setSelectDispansaryObservation: (state, action) => {
            state.selectDispansaryObservation = action.payload;
        },
        setModalAddRegisterDefectEquipment: (state, action: PayloadAction<boolean>) => {
            state.modalAddRegisterDefectEquipment = action.payload;
        },
        setModalAddActEquipmentInspectionCertificate: (state, action: PayloadAction<boolean>) => {
            state.modalAddActEquipmentInspectionCertificate = action.payload;
        },
        setSuccessJournalPurchaseMaterials(state, action: PayloadAction<any[]>){
            state.isLoading = false;
            state.isError = '';
            state.journalPurchaseMaterials = action.payload
        },
        setSuccessChildrenAttendance(state, action: PayloadAction<IChildrenAttendances[]>){
            state.isLoading = false;
            state.isError = "";
            state.childrenAttendance = action.payload;
        },
        setSuccessPortfolios(state, action: PayloadAction<IPortfolio[]>){
            state.portfolios = action.payload
        },
        setSuccessDeletePortfolio(state, action: PayloadAction<boolean>){
            state.successDeletePortfolio = action.payload;
        },
        setSelectDeletePortfolio(state, action: PayloadAction<number | null>){
            state.selectDeletePortfolio = action.payload;
        },
        setSuccessAllDailyReportNutrition(state, action: PayloadAction<IAllDailyReportsNutrition[]>){
            state.isLoading = false;
            state.isError = "";
            state.allDailyReportNutritional = action.payload;
        },
        setSuccessAllDailyReportSleep(state, action: PayloadAction<IAllDailyReportsSleep[]>){
            state.isLoading = false;
            state.isError = "";
            state.allDailyReportSleep = action.payload;
        },
        setSuccessAllDailyReportMood(state, action: PayloadAction<IAllDailyReportsMood[]>){
            state.isLoading = false;
            state.isError = "";
            state.allDailyReportsMood = action.payload;
        },
        setSuccessAllInfoGroup(state, action: PayloadAction<ITableAllInfoGroup[]>){
            state.isLoading = false;
            state.isError = "";
            state.allInfoGroup = action.payload;
        },
        setSuccessAllInfoGroupDo(state, action: PayloadAction<ITableAllInfoGroup[]>){
            state.isLoading = false;
            state.isError = "";
            state.allInfoGroupDo = action.payload;
        },
        setSelectChat(state, action: PayloadAction<ISelectChat | null>){
            state.isLoading = false;
            state.isError = "";
            state.selectChat = action.payload;
        },
        setSelectDeviationAll(state, action: PayloadAction<string>){
            state.selectDeviationAll = action.payload;
        },
        setSuccessJournalHaccpTemperature(state, action: PayloadAction<ITableJournalHaccpTemperature>){
            state.isLoading = false;
            state.isError = '';
            state.journalHaccpTemperature = action.payload
        },
        setSuccessJournalHaccpVentilationRoom(state, action: PayloadAction<ITableJournalHaccpVentilationRoom[]>){
            state.isLoading = false;
            state.isError = '';
            state.journalHaccpVentilationRoom = action.payload
        },
        setSuccessJournalHaccpGeneralCleaning(state, action: PayloadAction<any[]>){
            state.isLoading = false;
            state.isError = '';
            state.journalHaccpGeneralCleaning = action.payload
        },
        setSuccessJournalHaccpMorningFilterEmployee(state, action: PayloadAction<any[]>){
            state.isLoading = false;
            state.isError = '';
            state.journalHaccpMorningFilterEmployee = action.payload
        },
        setSuccessJournalHaccpMorningFilterChildren(state, action: PayloadAction<any[]>){
            state.isLoading = false;
            state.isError = '';
            state.journalHaccpMorningFilterChildren = action.payload
        },
        setSuccessJournalHaccpReplacementBedLinen(state, action: PayloadAction<any[]>){
            state.isLoading = false;
            state.isError = '';
            state.journalHaccpReplacementBedLinen = action.payload
        },
        setSuccessJournalHaccpMeasuringTemperatureBody(state, action: PayloadAction<any[]>){
            state.isLoading = false;
            state.isError = '';
            state.journalHaccpMeasuringTemperatureBody = action.payload
        },
        setSuccessJournalHaccpAntisepticTableware(state, action: PayloadAction<any[]>){
            state.isLoading = false;
            state.isError = '';
            state.journalHaccpAntisepticTableware = action.payload
        },
        setSuccessJournalHaccpAntisepticSurface(state, action: PayloadAction<any[]>){
            state.isLoading = false;
            state.isError = '';
            state.journalHaccpAntisepticSurface = action.payload
        },
        setSuccessJournalHaccpAntisepticRoom(state, action: PayloadAction<any[]>){
            state.isLoading = false;
            state.isError = '';
            state.journalHaccpAntisepticRoom = action.payload
        },
        setSuccessJournalHaccpBactericidalLamps(state, action: PayloadAction<any[]>){
            state.isLoading = false;
            state.isError = '';
            state.journalHaccpBactericidalLamps = action.payload
        },
        setSuccessJournalHaccpCleaningDisinfection(state, action: PayloadAction<any[]>){
            state.isLoading = false;
            state.isError = '';
            state.journalCleaningDisinfection = action.payload
        }

    }
})

export const {
    setIsSend,
    setShowNotification,
    setLoadingStudentsDO,
    setSelectDeviationAll,
    setSelectDeletePortfolio,
    setSuccessPortfolios,
    setSuccessDeletePortfolio,
    setWidgets,
    setModalAdd,
    setGroupModalAdd,
    setGroupModalSign,
    setGroupAllDeviations,
    setLoadingGroups,
    setLoadingGroupsHaccp,
    setGroupAllDeviationsHaccp,
    setPostSelect,
    setLoadingStudents,
    setDate,
    setDateAdd,
    setModalSign,
    setDateSign,
    setGroupModalAddDO,
    setGroupModalSignDO,
    setGroupAllDeviationsDO,
    setModalAddDO,
    setModalSignDO,
    setModalAddEmployee,
    setModalUpdateEmployee,
    setModalAddDispansaryObservation,
    setChildren,
    setCategoryOvzs,
    setGroupHealth,
    setLimitHealth,
    setRecomendedActives,
    setDispansaryObservation,
    setSelectDispansaryObservation,
    setModalUpdateDispansaryObservation,
    setModalAddRegisterDefectEquipment,
    setModalAddActEquipmentInspectionCertificate,
    setSelectChat,
    setToUpdateGraphMainPage,
    setToUpdateGraphMainPageDo,
} = WidgetsSlice.actions;

export default WidgetsSlice.reducer