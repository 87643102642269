import React, {FC} from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";
import DataTable from "react-data-table-component";

interface ITableListClass {
    listClass: any[],
    listTypePlanMenu: any[]
}

const TableListClass: FC<ITableListClass> = ({listClass, listTypePlanMenu}) => {

    const columns =
        listTypePlanMenu.map((typePlanMenu: any) => ({
            name: <span className='font-weight-bold accessibility_size_table mx-2'>{typePlanMenu.name}</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.nutrition[typePlanMenu.name]}</span>,
            compact: true,
            width: '100px',
            center: true,
            wrap: false
        }));

    columns.unshift({
            name: <span className='font-weight-bold accessibility_size_table'>Класс</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.name}</span>,
            compact: true,
            wrap: true,
            center: false,
            width: '14rem'
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Всего</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.count_nutrition}</span>,
            compact: true,
            wrap: true,
            center: true,
            width: '70px'
        },
    );


    return (
        <Row>
            <Col>
                <Card className="my-0">
                    <CardBody className='py-0'>
                        <DataTable
                            columns={columns}
                            data={listClass}
                            noDataComponent={'Нет данных'}
                            noTableHead
                            highlightOnHover
                            customStyles={{
                                headCells: {
                                    style: {
                                        borderRight: '1px solid #ccc',
                                    },
                                },
                                rows: {
                                    highlightOnHoverStyle: {
                                        backgroundColor: "#ffebb5"
                                    },

                                },
                                cells:{
                                    style: {
                                        borderRight: '1px solid #ccc'
                                    },
                                },
                                expanderRow: {
                                    style: {
                                        backgroundColor: "#ffebb5",
                                        paddingTop: '5px',
                                        paddingBottom: '5px'
                                    },
                                },
                            }}
                            className='py-0'
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default TableListClass;
