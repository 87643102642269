import React, {FC, useEffect, useState} from 'react';
import AxiosClient from "../../../api/AxiosClient";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../hooks/redux";

interface IAddTableware {
    modalUpdateTableware: boolean;
    setModalUpdateTableware: (value: boolean) => void;
    tableware: any[]; //tableware
    setTableware: (value: any[]) => void;
    selectTableware: any;
    selectClothesSetting: any
}

const AddTableware: FC <IAddTableware> = ({modalUpdateTableware, setModalUpdateTableware, tableware, setTableware, selectTableware, selectClothesSetting}) => {
    const [clothesSettingList, setClothesSettingList] = useState<any[]>([]);

    useEffect(() => {
        if(modalUpdateTableware){
            AxiosClient.get(`haccp/getCookwareSetting/${selectClothesSetting}`)
                .then((r: any) => setClothesSettingList(r.data))
                .catch((error) => console.log(error));
        }
    }, [modalUpdateTableware]);
    const dispatch  =useAppDispatch()
    useEscapeKey(() => dispatch(setModalAdd(false)), modalUpdateTableware, "AddTableware");

    return (
        <Modal
            id="AddTableware"
            isOpen={modalUpdateTableware}
            toggle={() => setModalUpdateTableware(!modalUpdateTableware)}
            modalClassName="zoomIn"
            tabIndex={-1}
            centered
        >
            <ModalHeader
                toggle={() => setModalUpdateTableware(!modalUpdateTableware)}
                className="p-3 bg-soft-primary"
                id="createProjectModalLabel"
            >
                Обновление списка посуды
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={{
                        uid: selectTableware?.uid || '',
                        units: selectTableware?.units || ''
                    }}
                    validationSchema={
                        Yup.object({
                            uid: Yup.string().required('Требуется выбрать посуду'),
                            units: Yup.string().required('Требуется указать количество')
                        })}
                    onSubmit={(values) => {
                        const newObject = {
                            uid: values.uid,
                            name: clothesSettingList.find(value => value.uid === values.uid)?.name,
                            units: values.units +"",
                            remove: selectTableware ? selectTableware.remove : "0"
                        };

                        setTableware(
                            Array.isArray(tableware)
                                ? tableware.some(item => item.uid === values.uid)
                                    ? tableware.map(item =>
                                        item.uid === values.uid ? { ...item, ...newObject } : item
                                    )
                                    : [...tableware, newObject]
                                : [newObject]
                        );

                        setModalUpdateTableware(false);
                    }}
                >
                    {({ errors, touched , setFieldValue}) => (
                        <Form>
                            <div className="mb-3">
                                <label className="w-100">
                                    Название:
                                    <Field
                                        as="select"
                                        name="uid"
                                        className={`form-select ${errors.uid && touched.uid && "border border-2 border-danger"}`}
                                        onChange={(e: { target: { value: any; checked: any; }; }) => {
                                            setFieldValue('uid', e.target.value);
                                            setFieldValue('units', clothesSettingList.find(value => value.uid == e.target.value)?.units);
                                        }}
                                    >
                                        <option value="">Не выбрано</option>
                                        {clothesSettingList.map(el => (
                                            <option key={el.uid} value={el.uid}>{el.name}</option>
                                        ))}
                                    </Field>
                                </label>
                            </div>
                            <div className="mb-3">
                                <label className="w-100">
                                    Количество:
                                    <Field name="units" className="form-control" />
                                </label>
                            </div>
                            <div className="hstack gap-2 justify-content-end mt-1">
                                <SubmitWithLoading text={"Сохранить"}/>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default AddTableware;
