import React, {FC, useEffect, useState} from 'react';
import {Card, CardBody} from "reactstrap";
import DataTable from "react-data-table-component";
import AxiosClient from "../../../api/AxiosClient";

interface IMenuThemePortfolio {
    idPortfolio: any,
    selectThemePortfolio: any,
    setSelectThemePortfolio(value: any) : any
}

const MenuThemePortfolio :FC <IMenuThemePortfolio> = ({idPortfolio, setSelectThemePortfolio, selectThemePortfolio}) => {
    const [date, setDate] = useState<any>([]);

    useEffect(() => {
        AxiosClient.get(`theme_all/parent/portfolio/${idPortfolio}`, {params: {}})
            .then(r => {
                setDate(r.data)
            })
            .catch(() => console.log('error'))
    }, [])

    const columns = [
        {
            name: <span className='font-weight-bold accessibility_size_table'>Название темы</span>,
            cell: (row: any) => <span >{row.name}</span>,
            sortable: false,
            wrap: true
        },
        {
            id: "count",
            name: <span className='font-weight-bold accessibility_size_table'> </span>,
            cell: (row: any) => <span className="accessibility_size_table">{ row.name === 'Титульник' ? 1 : row.countPage ? row.countPage : ''}</span>,
            sortable: false,
            center: true,
            width: '50px',
            wrap: true
        }
    ];

    return (
        <div>
            <Card className="team-box">
                <CardBody className="p-4">
                    <DataTable
                        columns={columns}
                        data={date}
                        noDataComponent={'Нет тем'}
                        defaultSortAsc={false}
                        highlightOnHover
                        customStyles={{
                            rows: {
                                highlightOnHoverStyle: {
                                    backgroundColor: "#ffebb5"
                                },
                                // style: (row: any) => ({
                                //     backgroundColor: row.id === selectThemePortfolio ? '#ffebb5' : '', // Условная подсветка выбранной строки
                                // }),
                            }
                        }}
                        onRowClicked={(row) => {
                            setSelectThemePortfolio({value: row.id, label: row.name})
                        }}
                    />
                </CardBody>
            </Card>
        </div>
    );
};

export default MenuThemePortfolio;
