import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getGroupsHaccp, getJournalHaccpMorningFilterChildren} from "../../store/reducers/widgets/ActionCreator";
import {Helmet} from "react-helmet";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import WidgetRequiredFlatpikerPeriod from "../../components/widgets/requireds/WidgetRequiredFlatpikerPeriod";
import Flatpickr from "react-flatpickr";
import moment from "moment/moment";
import {Russian} from "flatpickr/dist/l10n/ru";
import {ParentSlice} from "../../store/reducers/parent/ParentSlice";
import WidgetTableMorningFilterChildren
    from "../../components/journalHaccp/MorningFilterChildren/WidgetTableMorningFilterChildren";
import WidgetGraphMorningFilterChildren
    from "../../components/journalHaccp/MorningFilterChildren/WidgetGraphMorningFilterChildren";
import AxiosClient from "../../api/AxiosClient";
import {
    setGroupAllDeviationsHaccp,
    setGroupModalAdd
} from "../../store/reducers/widgets/WidgetsSlice";
import NotificationShow from "../../components/widgets/NotificationShow";
import WidgetRequiredSelectGroup from "../../components/widgets/requireds/WidgetRequiredSelectGroup";
import WidgetGraphRound from "../../components/widgets/graphs/WidgetGraphRound";
import WidgetTableAllDeviationsGroup from "../../components/widgets/tables/WidgetTableAllDeviationsGroup";
import WidgetGraphProjectOverview from "../../components/widgets/graphs/WidgetGraphProjectOverview";
import WidgetTableAllInfoGroup from "../../components/widgets/tables/WidgetTableAllInfoGroup";

const JournalMorningFilterChildren = () => {
    const dispatch = useAppDispatch()
    const {journalHaccpMorningFilterChildren, date,groupsHaccp, groupAllDeviationsHaccp} = useAppSelector(state => state.WidgetReducer);
    const [workDays, setWorkDays] = useState<any>([]);

    useEffect(() => {
        dispatch(getGroupsHaccp(date)).then()
    }, [date, dispatch]);

    useEffect(() => {
        if(groupAllDeviationsHaccp){
            dispatch(getJournalHaccpMorningFilterChildren(date, groupAllDeviationsHaccp)).then()
            AxiosClient.get('haccp/getWorkDays', {params: {date}})
                .then((r: any) => {
                    setWorkDays(r.data)
                })
                .catch((error) => console.log(error))
        }
    }, [dispatch, date, groupAllDeviationsHaccp]);

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Журнал утреннего фильтра детей</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col xxl={3}>
                        <WidgetRequiredFlatpikerPeriod/>
                        <div className="col">
                            <Card>
                                <CardHeader className="card_header_size_text shadow">
                                    Группа:
                                    <select className="form-select mt-1 shadow" value={groupAllDeviationsHaccp}
                                            onChange={(event) => {
                                                dispatch(setGroupAllDeviationsHaccp(event.target.value))
                                                dispatch(setGroupModalAdd(event.target.value))
                                            }}
                                    >
                                        {
                                            groupsHaccp.map((group) => {
                                                return <option key={group.group_id}
                                                               value={group.group_id}>{group.group_name}</option>
                                            })
                                        }
                                    </select>
                                </CardHeader>
                            </Card>
                        </div>
                        <div className="col">
                            <Card>
                                <CardBody>
                                    <Flatpickr
                                        className="form-control border-0 dash-filter-picker d-none"
                                        value={[moment(date[0]).toDate(), moment(date[1]).toDate()]}
                                        options={{
                                            locale: Russian,
                                            disableMobile: true,
                                            inline: true,
                                            maxDate: new Date(),
                                        }}
                                        onMonthChange={(dates, currentDateString, self, data) => {
                                            dispatch(ParentSlice.actions.setDate(moment().month(self.currentMonth).year(self.currentYear).startOf('month').toDate()));
                                        }}
                                        onDayCreate={(dates, currentDateString, self, data) => {
                                            const dateStr = moment(data.dateObj).format('DD.MM.YYYY');
                                            const hasTemperatureData = journalHaccpMorningFilterChildren && journalHaccpMorningFilterChildren.some((el) => el.data === dateStr);

                                            const isWorkDay = workDays.includes(dateStr);
                                            const isPastDate = data.dateObj < new Date();

                                            data.classList.add('border-2', 'border-white', 'rounded-1', 'shadow-none');

                                            if (hasTemperatureData) {
                                                data.style.backgroundColor = 'rgba(75, 56, 179, 0.5)';
                                                data.classList.add('text-white');
                                            } else if (isWorkDay && isPastDate) {
                                                data.style.backgroundColor = 'rgba(255, 0, 0, 0.5)';
                                                data.classList.add('text-white');
                                            }
                                        }}
                                    />
                                </CardBody>
                            </Card>
                        </div>

                    </Col>
                    <Col xs={12} sm={10} md={12} xxl={9}>
                        <WidgetGraphMorningFilterChildren />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="card-height-100">
                            <CardBody >
                                <WidgetTableMorningFilterChildren />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <NotificationShow />
            </Container>
        </div>
    );
};

export default JournalMorningFilterChildren;

