import React, {FC, useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {setShowNotification} from "../../store/reducers/widgets/WidgetsSlice";


const NotificationShow = () => {
    const dispatch = useAppDispatch();
    const {showNotification} = useAppSelector(state => state.WidgetReducer);

    useEffect(() => {
        if(showNotification?.show){
            const notify = () => {
                if (showNotification?.type === 'success') {
                    toast.success(showNotification?.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                    });
                }
                else if (showNotification?.type === 'info') {
                    toast.info(showNotification?.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                    });
                } else {
                    toast.error(showNotification?.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                    });
                }
                setTimeout(() => {
                    dispatch(setShowNotification({type: "", message: "", show: false}))
                }, 5000);
            };
            notify();
        }
    }, [showNotification])

    return (
        <ToastContainer/>
    );
};

export default NotificationShow;
