import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import WidgetService from "../../../api/services/widgetService/WidgetService";
import {setToUpdateGraphMainPage} from "../../../store/reducers/widgets/WidgetsSlice";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {ProjectsJournalHaccpAntisepticSurface} from "../../widgets/ProjectStatusCharts";

const WidgetGraphAntisepticSurface = () => {
    const { toUpdateGraphMainPage, date, journalHaccpAntisepticSurface} = useAppSelector(state => state.WidgetReducer)
    const dispatch = useAppDispatch();

    useEffect(() => {
        toUpdateGraphMainPage &&
        WidgetService.getDeviationGroupMonth(null, date)
            .then(res => {
                dispatch(setToUpdateGraphMainPage(false))
            })
    }, [toUpdateGraphMainPage, date, dispatch])
return(
    <>
        <Card>
            <CardHeader className="border-0 align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Журнал обработки поверхностей</h4>
            </CardHeader>
            <CardHeader className="p-0 border-0 bg-soft-light">
                <Row className="g-0 text-center">
                    <Col xs={12} sm={12}>
                        <div className="p-3 border border-dashed border-start-0">
                            <h5 className="mb-1">
                    <span className="counter-value">
                        {journalHaccpAntisepticSurface.length > 0 ?
                            parseFloat(journalHaccpAntisepticSurface.reduce((totalArea, entry) => {
                                const areaSum = entry.surfaces.reduce((sum: any, surface: any) => {
                                    return sum + Number(surface.area.replace(',', '.'));
                                }, 0);
                                return totalArea + areaSum;
                            }, 0).toFixed(2))
                            : 0}
                    </span>
                            </h5>
                            <p className="text-muted mb-0">Общая площадь обработки</p>
                        </div>
                    </Col>

                </Row>

            </CardHeader>

            <CardBody className="p-0 pb-2">
                <div>
                    {
                        journalHaccpAntisepticSurface && <ProjectsJournalHaccpAntisepticSurface series={journalHaccpAntisepticSurface}
                                                                                          dataColors='["--vz-green", "--vz-warning", "--vz-danger"]'/>
                    }
                </div>
            </CardBody>
        </Card>
    </>
);
};

export default WidgetGraphAntisepticSurface;