import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import ListShift from "../../components/KShP/Shift/ListShift";
import NotificationShow from "../../components/widgets/NotificationShow";

const Shift = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Смены</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <ListShift/>
                        </Col>
                    </Row>
                    <NotificationShow />
                </Container>
            </div>
        </>
    );
};

export default Shift;
