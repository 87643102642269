import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    setDateAdd,
    setIsSend,
    setModalAdd
} from "../../../store/reducers/widgets/WidgetsSlice";
import {
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Col,
    Row,
} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import moment from "moment/moment";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import classNames from "classnames";
import * as Yup from "yup";
import AxiosClient from "../../../api/AxiosClient";
import TableChildrenGroupDo from "./tables/TableChildrenGroupDo";
import ModalAddChildGroupDo from "./ModalAddChildGroupDo";
import {getGroups} from "../../../store/reducers/widgets/ActionCreator";

interface IModalUpdateGroupDo {
}

const ModalUpdateGroupDo: FC<IModalUpdateGroupDo> = () => {
    const dispatch = useAppDispatch();
    const {
        modalAdd,
        date,
        groupModalAddDO,
    } = useAppSelector(state => state.WidgetReducer);
    const [activeTab, setActiveTab] = useState("1");
    const [expandedRow, setExpandedRow] = useState<string | null>(null);
    const [children, setChildren] = useState<any[]>([]);

    const [groupInfo, setGroupInfo] = useState<any>({
        group_name: '',
        group_id: '',
        group_code: '',
        date_begin: '',
        date_end: '',
        category: '',
        category_id: '',
        period: '',
        rejum: '',
        rejum_id: ''
    });

    const [isAddChildModalOpen, setIsAddChildModalOpen] = useState(false);

    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const openAddChildModal = () => {
        setIsAddChildModalOpen(true);
    };

    const closeAddChildModal = () => {
        setIsAddChildModalOpen(false);
    };

    const handleAddChild = (newChildren: any[]) => {
        const currentChildrenIds = new Set(children.map(child => child.id));
        const childrenToAdd = newChildren
            .filter(child => !currentChildrenIds.has(child.id))
            .map(child => ({
                id: child.id,
                fio: child.fio || "",
                groupName: child.groupName || "",
                groupId: child.groupId || "",
                dataBegin: child.dataBegin || "",
                dataEnd: child.dataEnd || ""
            }));
        if (childrenToAdd.length > 0) {
            setChildren(prev => [...prev, ...childrenToAdd]);
        } else {
            //console.log('Все выбранные дети уже есть в списке для указанных групп');
        }
        setIsAddChildModalOpen(false);
    };

    useEffect(() => {
        if (modalAdd) {
            setActiveTab("1");
            setExpandedRow(null);
            dispatch(setDateAdd(moment().toDate()))

            AxiosClient.get(`/get_info_group_do/${groupModalAddDO}`)
                .then((response) => {
                    setGroupInfo(response.data[0])
                })
                .catch((error) => {
                    console.error('Ошибка при запросе данных о группе:', error);
                });
            AxiosClient.get(`/get_child_group_do/${groupModalAddDO}`)
                .then((response) => {
                    const formattedChildren = response.data.map((child: any, index: number) => ({
                        id: child.children_id,
                        fio: child.fio,
                        groupName: child.group_name,
                        groupId: child.group_id,
                        dataBegin: child.data_begin ? moment(child.data_begin, "YYYY-MM-DD").format("DD MMMM YYYY") : '',
                        dataEnd: child.data_end ? moment(child.data_end, "YYYY-MM-DD").format("DD MMMM YYYY") : ''
                    }));
                    setChildren(formattedChildren);
                })
                .catch((error) => {
                    console.error('Ошибка при запросе данных о детях:', error);
                });
        }
    }, [modalAdd]);
    return (
        <Modal
            id="ModalUpdateGroupDo"
            isOpen={modalAdd}
            toggle={() => dispatch(setModalAdd(false))}
            modalClassName="zoomIn"
            tabIndex={-1}
            centered
        >
            <ModalHeader
                toggle={() => dispatch(setModalAdd(false))}
                className="p-3 bg-soft-primary"
                id="createProjectModalLabel"
            >
                Редактирование группы ДО
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize={true}
                    initialValues={{}}
                    validationSchema={Yup.object({})}
                    onSubmit={(value) => {
                        dispatch(setIsSend(true));
                        const prop = {
                            ...groupInfo,
                            children: children.map(child => ({
                                id: child.id,
                                fio: child.fio,
                                group_name: child.groupName,
                                group_id: child.groupId,
                                data_begin: child.dataBegin ? moment(child.dataBegin, "DD MMMM YYYY").format("DD.MM.YYYY") : "",
                                data_end: child.dataEnd ? moment(child.dataEnd, "DD MMMM YYYY").format("DD.MM.YYYY") : "",
                            }))
                        };

                        AxiosClient.post(`/update_group_do/${groupInfo.group_id}`, prop)
                            .then(response => {
                                dispatch(setIsSend(false));
                                dispatch(setModalAdd(false));
                                dispatch(getGroups(date, 'True'))
                            })
                            .catch(error => {
                                dispatch(setIsSend(false));
                                console.error('Ошибка при отправке данных:', error);
                            });
                    }}
                >
                    {({errors, touched, setFieldValue}) => (
                        <Form>
                            <Nav
                                className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                role="tablist"
                            >
                                <NavItem>
                                    <NavLink
                                        className={classNames({active: activeTab === "1", "p-2": true})}
                                        onClick={() => tabChange("1")}
                                        type="button"
                                    >
                                        <i className="fas fa-home"></i>
                                        Реквизиты группы ДО
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classNames({active: activeTab === "2", "p-2": true})}
                                        onClick={() => tabChange("2")}
                                        type="button"
                                    >
                                        <i className="fas fa-home"></i>
                                        Список детей
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab} className="mt-2">
                                <TabPane tabId="1" className="align-items-center">
                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">
                                                Код
                                                <input type="text" className="form-control custom-input" name="code"
                                                       value={groupInfo.group_code} disabled={true}
                                                />
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">
                                                Группа:
                                                <Field
                                                    as="input"
                                                    type="text"
                                                    className="form-control"
                                                    value={groupInfo.group_name}
                                                    onChange={(e: any) => {
                                                        setGroupInfo({...groupInfo, group_name: e.target.value});
                                                    }}
                                                    name="group"
                                                />
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>
                                            <label className='w-100'>
                                                Категория:
                                                <Field as="select" className="form-select" name="category"
                                                       disabled={true}>
                                                    <option key={0}
                                                            value={groupInfo.category_id}>{groupInfo.category}</option>
                                                </Field>
                                                {/*Категория:*/}
                                                {/*<Field*/}
                                                {/*    as="input"*/}
                                                {/*    type="text"*/}
                                                {/*    className="form-control"*/}
                                                {/*    value={groupInfo[0].category || ''}*/}
                                                {/*    onChange={(e: any) => {*/}
                                                {/*        setGroupInfo({ ...groupInfo, category: e.target.value });*/}
                                                {/*    }}*/}
                                                {/*    name="category"*/}
                                                {/*/>*/}
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>
                                            <label className='w-100'>Режим:
                                                <Field as="select" className="form-select" name="rejum" disabled={true}>
                                                    <option key={0}
                                                            value={groupInfo.rejum_id}>{groupInfo.rejum}</option>
                                                </Field>
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">
                                                Дата с
                                                <Flatpickr
                                                    className="form-control"
                                                    value={groupInfo.date_begin ? moment(groupInfo.date_begin, "DD.MM.YYYY").toDate() : ''}
                                                    options={{
                                                        locale: Russian,
                                                        dateFormat: "d M Y",
                                                        disableMobile: true,
                                                        mode: 'single'
                                                    }}
                                                    onChange={(date) => {
                                                        setGroupInfo({
                                                            ...groupInfo,
                                                            date_begin: moment(date[0]).format("DD.MM.YYYY")
                                                        });
                                                    }}
                                                />
                                            </label>
                                        </Col>
                                        <Col>
                                            <label className="w-100">
                                                Дата по
                                                <Flatpickr
                                                    className="form-control"
                                                    value={groupInfo.date_end ? moment(groupInfo.date_end, "DD.MM.YYYY").toDate() : ''}
                                                    options={{
                                                        locale: Russian,
                                                        dateFormat: "d M Y",
                                                        disableMobile: true,
                                                        mode: 'single'
                                                    }}
                                                    onChange={(date) => {
                                                        setGroupInfo({
                                                            ...groupInfo,
                                                            date_end: moment(date[0]).format("DD.MM.YYYY")
                                                        });
                                                    }}
                                                />
                                            </label>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2" className="align-items-center">
                                    <TableChildrenGroupDo children={children} setChildren={setChildren}/>
                                </TabPane>
                            </TabContent>
                            <div
                                className={classNames("d-flex", "mt-3",
                                    {
                                        "justify-content-between": activeTab === "2",
                                        "justify-content-end": activeTab === "1"
                                    }
                                )}
                            >
                                {activeTab === "2" && (
                                    <div className="button_modal mx-1">
                                        <button type="button" className="btn btn-primary" onClick={openAddChildModal}>
                                            Добавить
                                        </button>
                                    </div>
                                )}
                                <SubmitWithLoading text={"Сохранить"}/>
                            </div>
                        </Form>
                    )}
                </Formik>
                <ModalAddChildGroupDo
                    isOpen={isAddChildModalOpen}
                    onClose={closeAddChildModal}
                    onSave={handleAddChild}
                    dateBegin={groupInfo.date_begin}
                    dateEnd={groupInfo.date_end}
                />
            </ModalBody>
        </Modal>
    );
};

export default ModalUpdateGroupDo;