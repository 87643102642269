import React, {FC} from 'react';
import {Col, Modal, ModalBody, ModalHeader} from "reactstrap";
import {useAppDispatch} from "../../hooks/redux";
import {useEscapeKey} from "../../hooks/useEscapeKey";
import {setModalAdd} from "../../store/reducers/widgets/WidgetsSlice";

interface IModalDataProtection{
    modalProtection: boolean,
    setModalProtection: (value: boolean) => void,
}

const ModalDataProtection: FC <IModalDataProtection> = ({modalProtection, setModalProtection}) => {
    const dispatch = useAppDispatch();
    useEscapeKey(() => dispatch(setModalAdd(false)), modalProtection, "showModalDataProtection");

    return (
        <Modal id="showModalDataProtection" isOpen={modalProtection}
            toggle={() => setModalProtection(!setModalProtection)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setModalProtection(!setModalProtection)}
                         className="p-3 bg-soft-primary"
                         id="showModalDataProtectionLabel">
                Как мы защищаем ваши данные?
            </ModalHeader>
            <ModalBody>
                <Col>
                    <p className="fw-bold">Ваши данные защищены!</p>
                    <p>
                        При отправке формы ваши данные передаются в зашифрованном виде. Это значит, что даже если злоумышленники попытаются перехватить данные, они увидят только зашифрованные строки, которые невозможно прочитать или использовать.
                    </p>
                    <p className="fw-bold">Пример:</p>
                    <p>
                        Введенное значение:<br />
                        ФИО: Иванов Иван Иванович<br />
                        Дата рождения: 1990-01-01
                    </p>
                    <p>
                        Передаваемое значение:<br />
                        ФИО: 80b2a51a94a721528d0c4c77a597f933bab6f1...<br />
                        Дата рождения: c5cc05628d64ef65fa81b1fb6ed9...
                    </p>
                    <p>
                        Как это работает? <br />
                        Мы используем алгоритмы хэширования, которые преобразуют ваши данные в уникальную последовательность символов. Этот процесс необратим — никто не сможет восстановить исходное значение из зашифрованного.
                    </p>
                    <div className="d-flex align-items-center">
                        <i className="bx bx bx-check-shield fs-3 me-2 cursor-pointer" />
                        <span>Мы заботимся о вашей безопасности и конфиденциальности!</span>
                    </div>
                </Col>
            </ModalBody>
            
        </Modal>
    );
};

export default ModalDataProtection;
