import React, {FC, useEffect, useState} from 'react';
import {Button, Col, Modal, ModalBody, ModalHeader, Row, Spinner} from "reactstrap";
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import AxiosClient from "../../api/AxiosClient";
import {useParams} from "react-router-dom";
import moment from "moment";
import {useAppDispatch} from "../../hooks/redux";
import {useEscapeKey} from "../../hooks/useEscapeKey";
import {setModalAdd, setShowNotification} from "../../store/reducers/widgets/WidgetsSlice";

interface IDateTimeSubmissionDocuments {
    active: boolean,
    setActive: (value: boolean) => void,
    date: any,
    setDate: (values: {dateRecord: string, reservationId: string, time: {id: '', time: '', human_time: '', title:'', open_place:''}}) => void,
    period: any[]
}

const DateTimeSubmissionDocuments: FC<IDateTimeSubmissionDocuments> = ({active, setActive, date, setDate, period}) => {
    const {id} = useParams()
    const [dateRecord, setDateRecord] = useState(moment().startOf('day').toDate());
    const [timeRecord, setTimeRecord] = useState({id: '', time: '', human_time: '', title:'', open_place:''});
    const [dateList , setDateList] = useState<any[]>([]);
    const [arrTime, setArrTime] = useState<any[]>([]);
    const [send, setSend] = useState(false);
    useEffect(() => {
        let dates: any[] = []
        period.forEach((employees, index) => {
            dates.push(...employees?.date)
        });
        setDateList(dates)
    }, [active]);

    useEffect(() => {
        if(period){
            const kod = period.find((p) => (p.date || []).find((d: any) => d === dateRecord.getTime()/1000))?.id
            kod && period.length > 0 &&
            AxiosClient.get('/getTimesSubmissionDocuments', {params: {time: dateRecord.getTime()/1000, kod: kod, idOrg: id}})
                .then((r) => {
                    setArrTime(r.data['intervals']);
                })
                .catch(() => console.log('error'))
        }
    }, [period]);

    useEffect(() => {
        if(period){
            const kod = period.find((p) => p.date.find((d: any) => d === dateRecord.getTime()/1000))?.id
            kod &&
            AxiosClient.get('/getTimesSubmissionDocuments', {params: {time: dateRecord.getTime()/1000, kod: kod, idOrg: id}})
                .then((r) => {
                    setArrTime(r.data['intervals']);
                })
                .catch(() => console.log('error'))
        }
    }, [dateRecord]);
    const dispatch = useAppDispatch();

    const checkTimeAvailability = (timeArray: any, onSuccess: () => void) => {
        const kod = period.find((p) => (p.date || []).find((d: any) => d === dateRecord.getTime() / 1000))?.id;
        if (!kod) return;

        AxiosClient.get('/getTimesSubmissionDocuments', { params: { time: dateRecord.getTime() / 1000, kod: kod, idOrg: id } })
            .then((r) => {
                const updatedIntervals = r.data['intervals'];
                const selectedInterval = updatedIntervals.find((interval: any) => interval.id === timeArray.id);

                if (selectedInterval && selectedInterval.title === 'Свободно') {
                    onSuccess();
                } else {
                    dispatch(setShowNotification({ type: "error", message: "Извините, выбранное время уже занято!", show: true }));
                    setArrTime(updatedIntervals);
                }
            })
            .catch(() => {
                dispatch(setShowNotification({ type: "error", message: "Ошибка при проверке времени!", show: true }));
            });
    };

    useEscapeKey(() => dispatch(setModalAdd(false)), active, "active");


    return (
        <div>
            <Modal id="active" isOpen={active}
                   modalClassName="zoomIn" tabIndex={-1} centered size={'xl'}>
                <ModalHeader
                            toggle={() => setActive(!active)}
                             className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Выбор даты и времени приема
                </ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={
                            {
                                dateRecord: '',
                                time: timeRecord ? timeRecord['human_time'][0] : '',
                            }
                        }
                        validationSchema={
                            Yup.object().shape({
                                time: Yup.string().required('Обязательное поле'),
                            })
                        }
                        onSubmit={() => {
                            if (!timeRecord.id) {
                                dispatch(setShowNotification({ type: "error", message: "Выберите время приема!", show: true }));
                                return;
                            }
                            setSend(true);
                            checkTimeAvailability(timeRecord, () => {
                                AxiosClient.get('/getTimesReservationSubmissionDocuments', { params: { id: timeRecord['id'], idOrg: id } })
                                    .then((r) => {
                                        if (r.data['status']) {
                                            setDate({
                                                ...date,
                                                dateRecord: dateRecord,
                                                time: timeRecord,
                                                reservationId: r.data['id']
                                            });
                                            setActive(false);
                                            setSend(false);
                                        }
                                    })
                                    .catch(() => {
                                        console.log('error');
                                        setSend(false);
                                    });
                            });
                            setSend(false);
                        }

                    }>
                        <Form>
                            <Row className="mb-2">
                                <Col>
                                    <Col>
                                        <label>Дата приема:</label>
                                        <Flatpickr
                                            name="dateRecord"
                                            className="form-control border-0 dash-filter-picker shadow"
                                            value={dateRecord}
                                            options={{
                                                inline: true,
                                                locale: Russian,
                                                dateFormat: "d.m.Y",
                                                minDate: 'today',
                                                enable:
                                                    dateList.map((date)=> new Date(date * 1000).toLocaleDateString("ru-RU") )
                                            }}
                                            onChange={ (date) => setDateRecord(date[0])}
                                        />
                                        <ErrorMessage name="dateRecord"/>
                                    </Col>
                                </Col>
                                <Col>
                                    <Row className="mb-2">
                                        <Col>
                                            <label>Время приема:</label>
                                            <Row>
                                                <Col>
                                                    <Field
                                                        name="time"
                                                        placeholder="Время приема"
                                                        className='mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2'
                                                        disabled
                                                    />
                                                    <ErrorMessage name="time"/>
                                                </Col>
                                            </Row>
                                            <Row className="mb-2">
                                                {arrTime?.map((value) => {
                                                    let timeArray = value;
                                                    let date = (value['human_time'][0].split(' ')[1]).slice(0, -3);
                                                    if(timeArray.title === 'Свободно'){
                                                        return (
                                                            <Col key={timeArray['id']}>
                                                                <Button type="button" className="mt-1 w-100 btn"
                                                                        onClick={() => {
                                                                            setTimeRecord(timeArray)
                                                                        }
                                                                }>{date.toString()}</Button>
                                                            </Col>
                                                        );
                                                    }
                                                })}
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <button type="submit" className="btn btn-primary mt-1 w-100" disabled={send} id="addNewProject">{
                                        send ?
                                            <Spinner size={'sm'}>
                                                Loading...
                                            </Spinner>
                                            :
                                            "Получить талон"
                                    }</button>
                                </Col>
                            </Row>
                        </Form>
                    </Formik>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default DateTimeSubmissionDocuments;