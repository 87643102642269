import React, {useEffect} from 'react';
import {Card, CardHeader, Col} from "reactstrap";
import {setGroupAllDeviationsDO, setGroupModalAddDO, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {getGroups} from "../../../store/reducers/widgets/ActionCreator";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import ModalUpdateGroupDo from "../modal/ModalUpdateGroupDo";

const WidgetRequiredSelectGroupDO = () => {

    const dispatch = useAppDispatch()

    const {groupsDO, groupAllDeviationsDO, date} = useAppSelector(state => state.WidgetReducer);

    useEffect(() => {
        dispatch(getGroups(date, 'True')).then()
    }, [date, dispatch]);

    return (
        <Col xxl={12} id='WidgetRequiredSelectGroup'>
            <div className="col">
                <Card>
                    <CardHeader className="card_header_size_text shadow">
                        <div className="w-100">
                            Группа ДО:
                            <div className="input-group mt-1">
                                <select
                                    className="form-select shadow"
                                    value={groupAllDeviationsDO}
                                    onChange={(event) => {
                                        dispatch(setGroupAllDeviationsDO(event.target.value));
                                        dispatch(setGroupModalAddDO(event.target.value));
                                    }}
                                >
                                    {groupsDO.map((group) => (
                                        <option
                                            key={group.group_id}
                                            value={group.group_id}
                                        >
                                            {group.group_name}
                                        </option>
                                    ))}
                                </select>
                                <button
                                    className="btn"
                                    style={{borderColor: 'lightgray', zIndex: "0"}}
                                    onClick={() => dispatch(setModalAdd(true))}
                                    title="Редактировать"
                                >
                                    <i className="bi bi-pencil fs-5 text-black"/>
                                </button>
                            </div>
                        </div>
                    </CardHeader>
                </Card>
                <ModalUpdateGroupDo/>
            </div>
        </Col>
    );
};

export default WidgetRequiredSelectGroupDO;
