import React, {FC} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import moment from "moment";
import {useAppDispatch} from "../../hooks/redux";
import {useEscapeKey} from "../../hooks/useEscapeKey";
import {setModalAdd} from "../../store/reducers/widgets/WidgetsSlice";

interface IModalAllInfoEvent {
    setGetAllInfo: (values: boolean) => void,
    getAllInfo: boolean,
    info: {
        start: Date | null,
        end: Date | null,
        title: string,
        description: string
    }
}

const ModalAllInfoEvent: FC<IModalAllInfoEvent> = ({setGetAllInfo, info, getAllInfo}) => {

    const dispatch  =useAppDispatch()
    useEscapeKey(() => dispatch(setModalAdd(false)), getAllInfo, "ModalAllInfoEvent");
    return (
        <Modal id="ModalAllInfoEvent" isOpen={getAllInfo}
               toggle={() => setGetAllInfo(!getAllInfo)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setGetAllInfo(!getAllInfo)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Подробная информация
            </ModalHeader>
            <ModalBody>
                <div className="flex-grow-1">
                    <h4>{info?.title}</h4>
                </div>
                <div className="mt-4 d-flex gap-5">
                    <div>
                        <h5 className="fs-14">Начало:</h5>
                        <div className="d-flex flex-wrap gap-2">
                            {
                                moment(info?.start).format('D.M.Y HH:mm').toString()
                            }
                        </div>
                    </div>
                    <div>
                        <h5 className="fs-14">Конец:</h5>
                        <div className="d-flex flex-wrap gap-2">
                            {
                                moment(info?.end).format('D.M.Y HH:mm').toString()
                            }
                        </div>
                    </div>
                </div>
                <div className="mt-4 text-muted">
                    <h5 className="fs-14">Описание:</h5>
                    <p>
                        {
                            info?.description
                        }
                    </p>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ModalAllInfoEvent;
