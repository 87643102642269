import React, { FC } from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import {useEscapeKey} from "../../hooks/useEscapeKey";

interface IModalConfirmExitHaccp {
    isOpen: boolean;
    onClose: () => void;
    onStay: () => void;
    hasUnsavedChanges: boolean;
}

const ModalConfirmExitHaccp: FC<IModalConfirmExitHaccp> = ({ isOpen, onClose, onStay, hasUnsavedChanges }) => {

    useEscapeKey(() => !hasUnsavedChanges ? onClose() : undefined, isOpen, "confirmExit");

    if (!hasUnsavedChanges) {
        return null; // Не показываем модал, если нет изменений
    }

    return (
        <Modal
            id="confirmExit"
            isOpen={isOpen}
            toggle={onStay}
            modalClassName="zoomIn"
            tabIndex={-1}
            centered
        >
            <ModalHeader
                toggle={onStay}
                className="p-3 bg-soft-primary"
            >
                Подтверждение закрытия
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <p>Есть несохранённые измененияя. Вы уверены, что хотите закрыть окно?</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="hstack gap-2 justify-content-end">
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={onClose}
                            >
                                Да
                            </button>
                            <button
                                className="btn btn-secondary"
                                type="button"
                                onClick={onStay}
                            >
                                Нет
                            </button>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

export default ModalConfirmExitHaccp;