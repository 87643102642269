import React, {FC, useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import bgImage from '../../assets/images/small/img-5.jpg';
import {Slide} from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css';
import {useAppSelector} from "../../hooks/redux";
import AxiosClient from "../../api/AxiosClient";

interface ITeachers {
    date: Date
}

const Teachers: FC<ITeachers> = ({date}) => {

    const {child} = useAppSelector(state => state.ParentReducer)
    const [items, setItems] = useState<any[]>([]);
    useEffect(() => {
        AxiosClient.get('/get_teachers_child', {params: {date, child}})
            .then(r => setItems(r.data))
    }, [date, child]);

    const responsiveSettings = [
        {
            breakpoint: 962,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 762,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }
    ];

    return (
        <Card>
            <CardHeader>
                <h4 className="card-title mb-0 flex-grow-1">Воспитатели</h4>
            </CardHeader>
            {
                items.length > 0
                    ?
                    <Slide responsive={responsiveSettings} autoplay={false} transitionDuration={400}>
                        {
                            items.map(i => (
                                <Row className="m-1" style={{height: "24rem"}} key={i.src}>
                                    <Col lg={12}>
                                        <Row className="team-list grid-view-filter">
                                            <Col lg={12}>
                                                <Card className="team-box shadow-none">
                                                    <div className="team-cover">
                                                        <img src={bgImage} alt="" className="img-fluid"/>
                                                    </div>
                                                    <CardBody className="p-4">
                                                        <Row className="align-items-center team-row">
                                                            <Col className="col">
                                                                <div className="team-profile-img">                                                                   
                                                                    <div
                                                                        className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
                                                                        <img src={i.base64} alt=""
                                                                             className="img-fluid d-block rounded-circle" 
                                                                             style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                                                    </div>
                                                                    <div className="team-content">
                                                                        <h5 className="card-title mb-1">{i.fio}</h5>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={4} className="col">
                                                                <Row className="text-muted text-center">
                                                                    <Col xs={6}
                                                                         className="border-end border-end-dashed">
                                                                        <h5 className="mb-1">Основной</h5>
                                                                        <p className="text-muted mb-0">{i.job}</p>
                                                                    </Col>
                                                                    <Col xs={6}>
                                                                        <h6 className="mb-1">{i.phone}</h6>
                                                                        <p className="text-muted mb-0">Телефон</p>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            ))
                        }
                    </Slide>
                    :
                    <div style={{height: "24.5rem"}} className="d-flex justify-content-center align-items-center">
                        <div className="fs-4">
                            Нет данных
                        </div>
                    </div>
            }
        </Card>
    );
};

export default Teachers;
