import React, {FC} from 'react';
import {Card, CardBody} from "reactstrap";
import DataTable from "react-data-table-component";
import moment from "moment";
import AxiosClient from "../../../api/AxiosClient";

interface IListPagesPortfolio {
    foundAccount: any,
    setPortfolio(value: any): any,
    idPortfolio: any,
    sortPagePortfolio: any,
    setSelectPagePortfolio(value: any): any,
    setSelectShowPagePortfolio(value: any): any
    setSelectEditPagePortfolio(value: any): any
}

const ListPagesPortfolio: FC <IListPagesPortfolio> = ({foundAccount, setPortfolio, idPortfolio, sortPagePortfolio, setSelectPagePortfolio, setSelectShowPagePortfolio, setSelectEditPagePortfolio}) => {

    const columns = [
        {
            id: "index",
            name: <span className='font-weight-bold text-center accessibility_size_table'>№</span>,
            cell: (row: any, index: any) => <span className="accessibility_size_table">{index+1}</span>,
            sortable: false,
            center: true,
            width: '60px'
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Название страницы</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.type_page_portfolio?.name}</span>,
            sortable: false,
        },
        {
            name: <span className='font-weight-bold text-center accessibility_size_table'>Дата создания</span>,
            cell: (row: any) => <span className="accessibility_size_table">{moment(row.created_at).format('DD.MM.YYYY')}</span>,
            sortable: false,
            width: '150px'
        },
        ...(foundAccount ? [
            {
                id: "edit",
                name: <span className='font-weight-bold text-center accessibility_size_table'>Изменить</span>,
                cell: (row: any, index: any) => <i
                    id={'editPagePort' + index.toString()}
                    className="bx bx-edit fs-3"
                    onClick={() => {
                        setSelectPagePortfolio(row)
                        setSelectShowPagePortfolio(false)
                        setSelectEditPagePortfolio(true)
                    }}
                ></i> ,
                sortable: false,
                center: true,
                width: '96px'
            },
            {
                id: "block",
                name: <span className='font-weight-bold text-center accessibility_size_table'>Доступ</span>,
                cell: (row: any) =>
                    <i className={row.public ? 'bx bx-lock-open fs-3' : 'bx bx-lock fs-3'}
                       onClick={() => {
                           AxiosClient.post(`update/private/page/parent/portfolio/${row.id}`, {id_portfolio: idPortfolio, public: row.public})
                               .then(r => {
                                   setPortfolio(r.data);
                               })
                               .catch(error => {
                                   console.error("Ошибка при удалении страницы:", error);
                               });
                       }}
                    ></i>,
                sortable: false,
                center: true,
                width: '81px'
            },
            {
                id: "delete",
                name: <span className='font-weight-bold text-center accessibility_size_table'>Удалить</span>,
                cell: (row: any) => row.type_page_portfolio?.name === 'Титульник' ? '' :
                    <i className="bx bx-trash fs-3"
                       onClick={() => {
                           AxiosClient.post(`delete/page/parent/portfolio/${row.id}`, {id_portfolio: idPortfolio, path_page_photo: row.photo_page, path_photo: row.photo})
                               .then(r => {
                                   setPortfolio(r.data);
                                   // console.log(r.data)
                               })
                               .catch(error => {
                                   console.error("Ошибка при удалении страницы:", error);
                               });
                       }}
                    ></i>,
                sortable: false,
                center: true,
                width: '85px'
            }
        ] : [])
    ];
    return (
        <div>
            <Card className="team-box">
                <CardBody className="p-4">
                    <DataTable
                        columns={columns}
                        data={sortPagePortfolio}
                        noDataComponent={'Нет страниц'}
                        defaultSortFieldId={"count"}
                        defaultSortAsc={false}
                        highlightOnHover
                        customStyles={{
                            rows: {
                                highlightOnHoverStyle: {
                                    backgroundColor: "#ffebb5"
                                }
                            }
                        }}
                        onRowClicked={(row) => {
                            if(foundAccount) {
                                setSelectPagePortfolio(row)
                                setSelectShowPagePortfolio(true)
                                setSelectEditPagePortfolio(false)
                            }
                        }}
                    />
                </CardBody>
            </Card>
        </div>
    );
};

export default ListPagesPortfolio;
