import React, {FC, useEffect, useState} from 'react';
import {Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import AddAttendanceChildren from "./AddAttendanceChildren";
import AxiosClient from "../../../api/AxiosClient";
import moment from "moment";
import {useAppSelector} from "../../../hooks/redux";
import UpdateAttendanceChildren from "./UpdateAttendanceChildren";

interface IAllAttendanceChildren {
    classes: any[],
    selectClass: string | number
}

const AllAttendanceChildren: FC<IAllAttendanceChildren> = ({classes, selectClass}) => {

    const [allInfoAttendance, setAllInfoAttendance] = useState<any[]>([]);
    const [addAttendance, setAddAttendance] = useState(false);
    const [updateAttendance, setUpdateAttendance] = useState(false);
    const [selectDate, setSelectDate] = useState(new Date());
    const [selectUpdateClass, setSelectUpdateClass] = useState('');

    const {date} = useAppSelector(state => state.WidgetReducer)
    useEffect(() => {
        selectClass &&
        AxiosClient.get('/attendance_school', {params: {dateBegin: moment(date[0]).format('YYYY-MM-DD').toString(), dateEnd: moment(date[1]).format('YYYY-MM-DD').toString(), class_id: selectClass}})
            .then(r => setAllInfoAttendance(r.data))
    }, [selectClass, date]);

    const columns: any = [
        {
            id: "date",
            name: <span className='font-weight-bold accessibility_size_table mb-0'>Дата</span>,
            selector: (row: any) => moment(row.date).format('DD.MM.Y'),
            cell: (row: any) => <span className="accessibility_size_table">{moment(row.date).format('DD.MM.Y')}</span>,
            sortable: true,
            center: true,
            width: '120px',
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Класс</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.class.name}</span>,
            center: true,
            compact: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Был</span>,
            selector: (row: any) => row.present,
            cell: (row: any) => <span className="accessibility_size_table">{row.present}</span>,
            center: true,
            compact: true,
            sortable: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Не был</span>,
            selector: (row: any) => row.no_present,
            cell: (row: any) => <span className="accessibility_size_table">{row.no_present}</span>,
            center: true,
            compact: true,
            sortable: true
        }
    ];

    return (
        <>
            <Card>
                <CardHeader>
                    Таблица посещения в классе
                </CardHeader>
                <CardBody className="py-0">
                    <DataTable
                        columns={columns}
                        data={allInfoAttendance}
                        pagination
                        paginationRowsPerPageOptions={[5, 10, 20, 30]}
                        paginationComponentOptions={
                            {
                                rowsPerPageText: 'Видно дней:',
                                rangeSeparatorText: 'из'
                            }
                        }
                        noDataComponent={'Нет посещаемости'}
                        defaultSortFieldId={"date"}
                        defaultSortAsc={false}
                        highlightOnHover
                        pointerOnHover
                        customStyles={{
                            rows: {
                                highlightOnHoverStyle: {
                                    backgroundColor: "#ffebb5"
                                }
                            }
                        }}
                        onRowClicked={
                            (row) => {
                                if(window.innerWidth <= 768){
                                    setSelectDate(moment(row.date).toDate())
                                    setUpdateAttendance(true)
                                    setSelectUpdateClass(row.class.id)
                                }
                            }
                        }
                        onRowDoubleClicked={
                            (row) => {
                                if(window.innerWidth > 768){
                                    setSelectDate(moment(row.date).toDate())
                                    setUpdateAttendance(true)
                                    setSelectUpdateClass(row.class.id)
                                }
                            }
                        }
                    />
                </CardBody>
                <CardFooter className="d-flex justify-content-between">
                    <div className='d-flex'>
                        <div className="button_modal mx-1">
                            <button className="btn btn-primary w-100" onClick={() => setAddAttendance(true)}>Добавить</button>
                        </div>
                    </div>
                </CardFooter>
            </Card>
            {
                addAttendance &&
                <AddAttendanceChildren setAllInfoAttendance={value => setAllInfoAttendance(value)} classes={classes} addAttendance={addAttendance} setAddAttendance={value => setAddAttendance(value)}/>
            }
            {
                updateAttendance &&
                <UpdateAttendanceChildren selectDate={selectDate} setAllInfoAttendance={value => setAllInfoAttendance(value)} selectUpdateClass={selectUpdateClass} updateAttendance={updateAttendance} setUpdateAttendance={value => setUpdateAttendance(value)} classes={classes}/>
            }
        </>
    );
};

export default AllAttendanceChildren;
