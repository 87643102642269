import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import { Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {customStyles} from "../../settings";
import Select from "react-select";
import AxiosClient from "../../../api/AxiosClient";
import ListMealtime from "./ListMealtime";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../hooks/redux";
import {IAgeCategory} from "../../../models/KSHP/IAgeCategory";
import {ITypePlannedMenu} from "../../../models/KSHP/ITypePlannedMenu";
import _ from "lodash";
import ModalConfirmExit from "../Schools/ModalConfirmExit";
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface IModalUpdateTypeNutrition {
    addUpdateTypeNutrition: boolean,
    setUpdateAddTypeNutrition: (value: boolean) => void
    data: any | null,
    updateData: () => void
    setShowNotification: (value: boolean) => void,
    setNotificationType: (value: any) => void,
    setNotificationMessage: (value: any) => void
}
const ModalUpdateTypeNutrition: FC<IModalUpdateTypeNutrition> = ({setUpdateAddTypeNutrition, addUpdateTypeNutrition, data, updateData, setNotificationType, setShowNotification, setNotificationMessage}) => {
    const idButtonSave: string = 'buttonSaveModalUpdateTypeNutrition';
    const [ageCategories, setAgeCategories] = useState<IAgeCategory[]>([]);
    const [typePlannedMenu, setTypePlannedMenu] = useState<{value: string, label: string}[]>([]);
    const [selectTypePlannedMenu, setSelectTypePlannedMenu] = useState<{value: string, label: string}>({value: '', label: ''});
    const [selectAgeCategories, setSelectAgeCategories] = useState<{value: string, label: string}>({value: '', label: ''});
    const [listMealtime, setListMealtime] = useState<any[]>([]);
    const [withoutChangesListMealtime, setWithoutChangesListMealtime] = useState<any[]>([]);
    const [confirmExit, setConfirmExit] = useState(false);
    const [newObject, setNewObject] = useState<any>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        AxiosClient.get<IAgeCategory[]>('/age_categories')
            .then((r) => setAgeCategories(r.data))
            .catch(() => console.log('error'))
        AxiosClient.get<ITypePlannedMenu[]>('/type_planned_menu')
            .then((r) => {
                setTypePlannedMenu(r.data.map((el: any) => ({value: el.id, label: el.name})))
            })
            .catch(() => console.log('error'))
    }, []);

    useEffect(() => {
        setListMealtime(data?.mealtime)
        setWithoutChangesListMealtime(data?.mealtime)
        setSelectTypePlannedMenu({value: data?.type_planned_menu.id, label:  data?.type_planned_menu.name})
        setSelectAgeCategories({value: data?.age_category.id, label:  data?.age_category.name})
        setNewObject(data)
    }, [data]);

    const confirmOrExit = () => {
        if(!_.isEqual(data, newObject) || !_.isEqual(listMealtime, withoutChangesListMealtime)){
            setConfirmExit(true);
        }else{
            setUpdateAddTypeNutrition(false);
        }
    }
    useEscapeKey(() => dispatch(setModalAdd(false)), addUpdateTypeNutrition, "addUpdateTypeNutrition");

    return (
        <Modal id="addUpdateTypeNutrition" isOpen={addUpdateTypeNutrition}
               toggle={() => confirmOrExit()}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => confirmOrExit()}
                         className="p-3 bg-soft-primary">
                Обновление типа
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            type_planned_menu_id: selectTypePlannedMenu.value,
                            age_category_id: selectAgeCategories.value,
                            price: listMealtime?.map(r => r.price).reduce((a: any, b: any) => +a + +b, 0),
                        }
                    }
                    validationSchema={
                        Yup.object({
                            price: Yup.number().required('Обязательное поле'),
                        })
                    }
                    onSubmit={(values) => {
                        dispatch(setIsSend(true))
                        values.type_planned_menu_id = selectTypePlannedMenu.value
                        AxiosClient.post(`/catering_organizer_type_planned_menu/update/${data.id}`, {...values, listMealtimes: listMealtime})
                            .then(() => {
                                dispatch(setIsSend(false))
                                setUpdateAddTypeNutrition(false)
                                updateData()
                            })
                            .catch(() => {
                                dispatch(setIsSend(false))
                                console.log('error')
                            })
                    }}>
                    {
                        ({setFieldValue, errors, touched}) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Тип планового меню:
                                        <Select
                                            options={typePlannedMenu}
                                            value={selectTypePlannedMenu}
                                            onChange={(change: any) => {
                                                setSelectTypePlannedMenu({value: change.value, label: change.label});
                                                setNewObject({...newObject, 'type_planned_menu_id': change.value});
                                            }}
                                            styles={customStyles}
                                            placeholder="Поиск"
                                            name="name"
                                        />
                                        </label>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Возврастная категория:
                                            <Select
                                                options={ageCategories.map(ageCategory => ({value: ageCategory.id.toString(), label: ageCategory.name}))}
                                                value={selectAgeCategories}
                                                onChange={(change: any) => {
                                                    setSelectAgeCategories({value: change.value, label: change.label});
                                                    setNewObject({...newObject, 'age_category_id': change.value});
                                                    setFieldValue('age_category_id', change.value);
                                                }}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                                name="age_category_id"
                                            />
                                        </label>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Прием пищи:
                                        <ListMealtime listMealtime={listMealtime} setListMealtime={values => setListMealtime(values)}/>
                                        </label>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label  className="w-100">Цена (руб.):
                                        <Field name="price" className={`form-control ${errors.price && touched.price && "border border-2 border-danger"}`}
                                               disabled
                                               onChange={(change: any) => {
                                                   setNewObject({...newObject, 'price': change.target.value});
                                                   setFieldValue('price', change.target.value);
                                               }}
                                        />
                                        </label>
                                    </Col>
                                </Row>
                                <div className="hstack gap-2">
                                    <SubmitWithLoading id={idButtonSave} text={"Сохранить"}/>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
            <ModalConfirmExit idButtonSave={idButtonSave} setUpdate={value => setUpdateAddTypeNutrition(value)} confirmExit={confirmExit} setConfirmExit={value => setConfirmExit(value)} />
        </Modal>
    );
};

export default ModalUpdateTypeNutrition;
