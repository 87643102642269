import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardFooter, Col, Row, Spinner} from "reactstrap";
import DataTable from "react-data-table-component";
import AddComplexMenu from "./AddComplexMenu";
import UpdateComplexMenu from "./UpdateComplexMenu";
import AxiosClient from "../../../api/AxiosClient";
import moment from "moment";
import Select from "react-select";
import {customStyles} from "../../settings";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";

const ListComplexMenu = () => {

    const [dateBegin, setDateBegin] = useState(new Date());
    const [addComplexMenu, setAddComplexMenu] = useState(false);
    const [updateComplexMenu, setUpdateComplexMenu] = useState(false);
    const [listComplexMenu, setListComplexMenu] = useState<any[]>([]);
    const [selectComplexMenu, setSelectComplexMenu] = useState<any | null>(null);
    const [schools, setSchools] = useState<any[]>([])
    const [selectSchool, setSelectSchool] = useState<any>({ value: '', label: 'Не выбрано' })
    const [selectBuild, setSelectBuild] = useState<any>({ value: '', label: 'Не выбрано' })
    const [builds, setBuilds] = useState<any[]>([])
    const [ageCategories, setAgeCategories] = useState<any[]>([])
    const [selectAgeCategory, setSelectAgeCategory] = useState<any>({ value: '', label: 'Не выбрано' })
    const [typePlannedMenu, setTypePlannedMenu] = useState<any[]>([])
    const [selectTypePlannedMenu, setSelectTypePlannedMenu] = useState<any>({value: '', label: 'Не выбрано'})
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [loadingOptionsBuildings, setLoadingOptionsBuildings] = useState(false);
    const [pageOption, setPageOption] = useState(1);
    const [totalOptions, setTotalOptions] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState(false);
    const [listCateringOrganizer, setListCateringOrganizer] = useState<any[]>([]);
    const [selectCateringOrganizer, setSelectCateringOrganizer] = useState({value: '', label: 'Не выбрано'});

    useEffect(() => {
        fetchMoreSchools(1)
            .then((r) => {
                setPageOption(state => state + 1)
                setSchools(r)
            })
        fetchComplexMenus(1, perPage, selectSchool.value, selectBuild.value, selectTypePlannedMenu.value, selectAgeCategory.value, dateBegin, selectCateringOrganizer.value)
        AxiosClient.get('/age_categories')
            .then(r => setAgeCategories(r.data))
            .catch(() => console.log('error'))
        AxiosClient.get('/type_planned_menu')
            .then((r) => {
                setTypePlannedMenu([{value: '', label: 'Не выбрано'} ,...r.data.map((el: any) => ({ value: el.id, label: el.name }))])
            })
            .catch(() => console.log('error'))
        AxiosClient.get(`/users/catering_organizers`)
            .then((r) => setListCateringOrganizer(r.data))
    }, []);

    const onChangeDate = (date: Date) => {
        fetchComplexMenus(1, perPage, selectSchool.value, selectBuild.value, selectTypePlannedMenu.value, selectAgeCategory.value, date, selectCateringOrganizer.value)
        setDateBegin(date)
    }

    const onChangeSchool = (data: { value: string, label: string }) => {
        setSelectBuild({value: '', label: 'Не выбрано'})
        fetchBuildings(data.value)
            .then(r => setBuilds(r))
        fetchComplexMenus(1, perPage, data.value, selectBuild.value, selectTypePlannedMenu.value, selectAgeCategory.value, dateBegin, selectCateringOrganizer.value)
        setSelectSchool(data)
    }

    const onChangeBuilding = (data: { value: string, label: string }) => {
        fetchComplexMenus(1, perPage, selectSchool.value, data.value, selectTypePlannedMenu.value, selectAgeCategory.value, dateBegin, selectCateringOrganizer.value)
        setSelectBuild(data)
    }

    const onChangeTypePlannedMenu = (data: { value: string, label: string }) => {
        fetchComplexMenus(1, perPage, selectSchool.value, selectBuild.value, data.value, selectAgeCategory.value, dateBegin, selectCateringOrganizer.value)
        setSelectTypePlannedMenu(data)
    }

    const onChangeAgeCategory = (data: { value: string, label: string }) => {
        fetchComplexMenus(1, perPage, selectSchool.value, selectBuild.value, selectTypePlannedMenu.value, data.value, dateBegin, selectCateringOrganizer.value)
        setSelectAgeCategory(data)
    }

    const fetchComplexMenus = async (page: number, size = perPage, schoolId = '', buildingId = '', typePlannedMenuId: string, ageCategoryId: string, date: Date, cateringOrganizerId = '') => {
        setSearch(true)
        await AxiosClient.get(`/complex_menus`, {params: {page: page, per_page: size, schoolId, cateringOrganizerId , buildingId, typePlannedMenuId, ageCategoryId, date: moment(date).format('YYYY-MM-DD').toString()}})
            .then((response) => {
                setSearch(false)
                setListComplexMenu(response.data.complexMenus);
                setTotalRows(response.data.total);
            })
            .catch(() => {
                console.log('error')
                setSearch(false)
            });

    };

    const handlePageChange = (page: number) => {
        fetchComplexMenus(page, perPage, selectSchool.value, selectBuild.value, selectTypePlannedMenu.value, selectAgeCategory.value, dateBegin, selectCateringOrganizer.value);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        await fetchComplexMenus(page, newPerPage, selectSchool.value, selectBuild.value, selectTypePlannedMenu.value, selectAgeCategory.value, dateBegin, selectCateringOrganizer.value);
        setPerPage(newPerPage);
    };

    const  onChangeSaveUpdate = async () => {
        await fetchComplexMenus(currentPage, perPage, selectSchool.value, selectBuild.value, selectTypePlannedMenu.value, selectAgeCategory.value, dateBegin, selectCateringOrganizer.value);
    }

    const fetchBuildings = async (selectSchool: string) => {
        if(selectSchool) {
            setLoadingOptionsBuildings(true)
            const response = await AxiosClient.get(`/schools/${selectSchool}/buildings/classes`)
            setLoadingOptionsBuildings(false)
            return response?.data
        }
        return []
    };

    const fetchMoreSchools = async (page: number) => {
        setLoadingOptions(true)
        const response = await AxiosClient.get(`/schools`, {params: {page: page, per_page: 10}})
        setLoadingOptions(false)
        setTotalOptions(response?.data.total)
        return response?.data.schools
    };

    const onChangeCateringOrganizer = (data: { value: string, label: string }) => {
        fetchComplexMenus(1, perPage, selectSchool.value, selectBuild.value, selectTypePlannedMenu.value, selectAgeCategory.value, dateBegin, data.value)
        setSelectCateringOrganizer(data)
    }

    const loadMoreData = async () => {
        const newData = await fetchMoreSchools(pageOption); // функция, которая загружает дополнительные данные
        setSchools(prevOptions => [...prevOptions, ...newData]);
    };

    const handleMenuScroll = async (event: any) => {
        const target = event.target;
        if (totalOptions !== schools.length && target.scrollHeight - target.scrollTop === target.clientHeight) {
            await loadMoreData();
        }
    };

    const columns = [
        {
            id: 'date',
            name: <span className='font-weight-bold accessibility_size_table'>Дата</span>,
            cell: (row: any) => <span className="accessibility_size_table">{moment(new Date(row.date)).format("DD.MM.Y")}</span>,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Школа</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.school.name}</span>,
            hide: 599,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Корпус</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.school_building.name}</span>,
            hide: 599,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Наименование</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.name}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Тип план. меню</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.catering_organizer_type_planned_menu.type_planned_menu.name}</span>,
            hide: 959,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Категория</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.age_category.name}</span>,
            hide: 959,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Сумма</span>,
            cell: (row: any) => <span className="accessibility_size_table">{`${row.price_fix} р`}</span>,
            hide: 950
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Доп</span>,
            cell: (row: any) => row.additional_menu ? <i className="bx bx-check fs-3"></i> : '',
            hide: 1280,
            width: '90px'
        },
        {
            name: <span className='font-weight-bold accessibility_size_table' title='По умолчанию'>Авто</span>,
            cell: (row: any) => row.default ? <i className="bx bx-check fs-3"></i> : '',
            hide: 1280,
            width: '90px'
        },
    ]
    return (
        <>
            <Row>
                <Col xxl={3} xl={3} lg={6}>
                    <label htmlFor="">Дата</label>
                    <Flatpickr
                        className="form-control border-0 dash-filter-picker shadow"
                        value={dateBegin}
                        options={{
                            locale: Russian, dateFormat: "d M Y"
                        }}
                        onChange={(date) => onChangeDate(date[0])}
                    />
                </Col>
                <Col xxl={3} xl={3} lg={6}>
                    <label>Организатор питания:</label>
                    <Select
                        options={listCateringOrganizer?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...listCateringOrganizer.map(v => ({value: v.id, label: v.name}))] : [{
                            value: '',
                            label: 'Не выбрано'
                        }]}
                        value={selectCateringOrganizer}
                        onChange={(change: any) => {
                            onChangeCateringOrganizer({value: change.value, label: change.label})
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                </Col>
                <Col xxl={3} xl={3} lg={6}>
                    <label htmlFor="">Школа</label>
                    <Select
                        options={schools?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...schools.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                        value={selectSchool}
                        onChange={(change: any) => {
                            onChangeSchool({ value: change.value, label: change.label })
                        }}
                        isLoading={loadingOptions}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                        onMenuScrollToBottom={handleMenuScroll}
                    />
                </Col>
                <Col xxl={3} xl={3} lg={6}>
                    <label htmlFor="">Корпус</label>
                    <Select
                        options={builds?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...builds?.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                        value={selectBuild}
                        onChange={(change: any) => {
                            onChangeBuilding({ value: change.value, label: change.label })
                        }}
                        isLoading={loadingOptionsBuildings}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                </Col>
                <Col xxl={3} xl={3} lg={6}>
                    <label>Тип план. меню:</label>
                    <Select
                        options={typePlannedMenu}
                        value={selectTypePlannedMenu}
                        onChange={(change: any) => {
                            onChangeTypePlannedMenu({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                </Col>
                <Col xxl={3} xl={3} lg={6}>
                    <label htmlFor="">Категория</label>
                    <Select
                        options={ageCategories?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...ageCategories.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                        value={selectAgeCategory}
                        onChange={(change: any) => {
                            onChangeAgeCategory({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mt-2">
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={listComplexMenu}
                                noDataComponent={'Нет данных'}
                                progressComponent={<Spinner></Spinner>}
                                progressPending={search}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                paginationDefaultPage={currentPage}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно меню:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        },
                                    },
                                    expanderRow: {
                                        style: {
                                            backgroundColor: "#ffebb5",
                                            paddingTop: '5px',
                                            paddingBottom: '5px'
                                        },
                                    },
                                }}
                                onRowClicked={
                                    (row) => {
                                        if(window.innerWidth <= 768){
                                            setUpdateComplexMenu(true)
                                            setSelectComplexMenu(row)
                                        }
                                    }
                                }
                                onRowDoubleClicked={
                                    (row) => {
                                        if(window.innerWidth > 768){
                                            setUpdateComplexMenu(true)
                                            setSelectComplexMenu(row)
                                        }
                                    }
                                }
                            />
                        </CardBody>
                        <CardFooter>
                            <button className="btn btn-primary" onClick={() => setAddComplexMenu(true)}>Добавить</button>
                        </CardFooter>
                    </Card>
                    {
                        updateComplexMenu &&
                        <UpdateComplexMenu onChangeSaveUpdate={() => onChangeSaveUpdate()} selectComplexMenu={selectComplexMenu} updateComplexMenu={updateComplexMenu} setUpdateComplexMenu={value => setUpdateComplexMenu(value)}/>
                    }
                    {
                        addComplexMenu &&
                        <AddComplexMenu onChangeSaveUpdate={() => onChangeSaveUpdate()} addComplexMenu={addComplexMenu} setAddComplexMenu={value => setAddComplexMenu(value)}
                            infoFilter={{selectCateringOrganizer, selectSchool, selectBuild, dateBegin}}
                        />
                    }
                </Col>
            </Row>
        </>
    );
};

export default ListComplexMenu;
