import React, {FC, useCallback, useEffect, useState} from 'react';
import {useAppDispatch} from "../../../../hooks/redux";
import userDummayImage from "../../../../assets/images/users/user-dummy-img.jpg";
import ReactCrop, {Crop, PixelCrop} from "react-image-crop";
import {Col, Input, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {setIsSend, setModalAdd} from "../../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../../layouts/UI/SubmitWithLoading";
import MyFamily from "../portfolioPage/MyFamily";
import MyKindergarten from "../portfolioPage/MyKindergarten";
import ModalCropPhoto from "../ModalCropPhoto";
import {useEscapeKey} from "../../../../hooks/useEscapeKey";

interface IEditMyKindergarten{
    portfolio: any,
    setPortfolio(value: any): any,
    selectPagePortfolio: any,
    selectEditPagePortfolio: boolean,
    setSelectEditPagePortfolio(value: boolean): any
}

const EditMyKindergarten: FC<IEditMyKindergarten> = ({ portfolio, setPortfolio, selectEditPagePortfolio, setSelectEditPagePortfolio, selectPagePortfolio }) => {
    const dispatch = useAppDispatch();
    const [selectModalCropPhoto, setSelectModalCropPhoto]  = useState<boolean>(false);
    const [page, setPage] = useState<any>();
    const [content, setContent] = useState(JSON.parse(selectPagePortfolio?.content));
    const [photoPathList, setPhotoPathList] = useState<any[]>(JSON.parse(selectPagePortfolio.photo.replace(/'/g, '"')));
    const [photoListBase64, setPhotoListBase64] = useState<any[]>(selectPagePortfolio.photoBase64);

    const [pathPhoto, setPathPhoto] = useState(photoPathList?.[0] ? process.env.REACT_APP_API_BASE_URL + photoPathList?.[0] : userDummayImage); //filePhoto, setFilePhoto
    const [downloadPhoto, setDownloadPhoto] = useState<Blob | null>(); //fileForm, setFileForm
    const [downloadPhoto64, setDownloadPhoto64] = useState<string | ArrayBuffer | null>(); //fileForm64, setFileForm64

    const [isDownloadPortfolioPage, setIsDownloadPortfolioPage] = useState<boolean>(false);
    const [isEditPortfolioPage, setIsEditPortfolioPage] = useState<boolean>(false);
    const [crop, setCrop] = useState<Crop>();
    const [cropPath, setCropPath] = useState<any>();
    const [croppedImage, setCroppedImage] = useState<string | null>(null);
    const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);

    const handleImageClick = () => {
        if (isDownloadPortfolioPage) {
            document.getElementById('inputForFileMyFamily')?.click();
            setIsDownloadPortfolioPage(false);
        }
    };

    useEffect(() => {
        if (isEditPortfolioPage) {
            document.getElementById('handleCombineImagesMyKindergarten')?.click();
            setIsEditPortfolioPage(false);
        }
    }, [isEditPortfolioPage]);
    useEscapeKey(() => dispatch(setModalAdd(false)), selectEditPagePortfolio, "EditMyKindergarten");

    return (
        <>
            <Modal id="EditMyKindergarten" isOpen={selectEditPagePortfolio}
                   toggle={() => setSelectEditPagePortfolio(!selectEditPagePortfolio)}
                   modalClassName="zoomIn" tabIndex={-1} centered >
                <ModalHeader toggle={() => setSelectEditPagePortfolio(!selectEditPagePortfolio)}
                             className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Изменение страницы «{selectPagePortfolio?.type_page_portfolio.name}»
                </ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            description: content?.description,
                            photo_download: downloadPhoto64 ? downloadPhoto64 : `data:image/png;base64,${portfolio?.photoBase64}`,
                        }}
                        validationSchema={
                            Yup.object({
                                description: Yup.string().required('Обязательное поле'),
                            })
                        }
                        onSubmit={(values) => {
                            dispatch(setIsSend(true));
                            setPage({
                                description: values.description,
                                uid: portfolio?.child_uid,
                                selectPage: selectPagePortfolio,
                                id_pattern: portfolio?.pattern_page_portfolio_id,
                                photo: downloadPhoto,
                                photo_download_base64: photoListBase64,
                                path_photo: photoPathList,
                                path_photo_page: selectPagePortfolio?.photo_page,
                            });
                            setIsEditPortfolioPage(true);
                        }}
                    >
                        {({ errors, touched, setFieldValue }) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">
                                            Описание:
                                            <Field
                                                name="description"
                                                as="textarea"
                                                className={`form-control ${touched.description && errors.description ? 'is-invalid' : ''}`}
                                                rows={6}
                                                onChange={(e: any) => {
                                                    setFieldValue('description', e.target.value);
                                                }}
                                            />
                                        </label>
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col>
                                        <label className="w-50">Загрузить фото:
                                            <img
                                                src={cropPath ? cropPath : pathPhoto}
                                                alt="Загрузить фото"
                                                className="user-profile-image w-100 h-auto"
                                                onClick={handleImageClick}
                                                style={{ cursor: 'pointer' }}
                                            />
                                            <Input
                                                name="photo"
                                                type="file"
                                                id="inputForFileMyFamily"
                                                className={`form-control d-none`}
                                                accept=".png, .jpg"
                                                onChange={(e: any) => {
                                                    const file = e.target.files[0];

                                                    if (file) {
                                                        const reader = new FileReader();
                                                        reader.onloadend = () => {
                                                            setDownloadPhoto64(reader.result);
                                                            setPhotoListBase64((prevArrList: any) => {
                                                                const newArrList : any = [...prevArrList];
                                                                newArrList[0] = reader.result;
                                                                return newArrList;
                                                            });
                                                            setPathPhoto(reader.result as string);
                                                        };
                                                        reader.readAsDataURL(file);
                                                        setSelectModalCropPhoto(true)
                                                    }
                                                }}
                                            />
                                        </label>
                                    </Col>
                                </Row>
                                <div className="hstack gap-2">
                                    <SubmitWithLoading text={'Готово'} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>

            <MyKindergarten
                dispatch={(value) => dispatch(value)}
                page={page}
                setPortfolio={(value) => setPortfolio(value)}
                setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}
            />

            <ModalCropPhoto portfolio={portfolio} setPortfolio={(value: any) => setPortfolio(value)}
                            selectPagePortfolio={selectPagePortfolio}
                            selectModalCropPhoto={selectModalCropPhoto} setSelectModalCropPhoto={(value: boolean) => setSelectModalCropPhoto(value)}
                            photoPathList={photoPathList} setPhotoListBase64={(value: any) => setPhotoListBase64(value)}
                            pathPhoto={pathPhoto} setDownloadPhoto={(value: any) => setDownloadPhoto(value)}
                            downloadPhoto64={downloadPhoto64} setDownloadPhoto64={(value: any) => setDownloadPhoto64(value)}
                            cropPath={cropPath} setCropPath={(value: any) => setCropPath(value)} indexPhoto={0}
                            relationsParties={4 / 5} croppedImage={croppedImage} />

        </>
    );
};

export default EditMyKindergarten;
