import React, {useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row, Spinner} from "reactstrap";
import FullShot from "./constructor/FullShot";
import {IGame} from "../../models/game/IGame";
import {IShot} from "../../models/game/IShot";
import AxiosClient from "../../api/AxiosClient";
import {useNavigate} from "react-router-dom";
import Notification from "../widgets/Notification";
import {validateGame} from "./validateGame";
import './style.scss'
import RightMenu from "./RightMenu/RightMenu";
import LeftMenu from "./LeftMenu/LeftMenu";
import {TypeSettingsGenderVoice} from "../../models/game/typesSettings/TypeSettingsGenderVoice";
import {v4 as uuidv4} from 'uuid';

//const RightMenu = lazy(() => import("./RightMenu/RightMenu"));
//const LeftMenu = lazy(() => import("./LeftMenu/LeftMenu"));
//const FullShot = lazy(() => import("./constructor/FullShot"));

const Constructor = () => {
    const [modalGameName, setModalGameName] = useState(false);
    const [update, setUpdate] = useState(false);
    const [openFullShot, setOpenFullShot] = useState(true);
    const [modalAddShot, setModalAddShot] = useState(false);
    const [isOpenRightMenu, setIsOpenRightMenu] = useState(true);
    const [isOpenLeftMenu, setIsOpenLeftMenu] = useState(false);
    const [selectSettingsShot, setSelectSettingsShot] = useState<IShot | null>(null);
    const [game, setGame] = useState<Omit<IGame, 'id'> | null>(null);
    const [savingGame, setSavingGame] = useState(false);
    const [error, setError] = useState('');
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [isOpenCopy, setIsOpenCopy] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate()

    const toggleSave = (game: Omit<IGame, 'id'>) => {
        setGame(game)
        setModalGameName(!modalGameName)
    };

    const handleOpenSettings = () => {
        setOpenFullShot(true)
        setIsOpenLeftMenu(true)
    }

    const toggleAddShotSave = () => {
            setGame(game => {
                if (game) {
                        const shot: IShot = {
                            id: game.shots.length + 1,
                            index: game.shots.length,
                            uid: uuidv4(),
                            useSoundBack: false,
                            widthTextures: 250,
                            heightTextures: 250,
                            name: `Кадр ${game.shots.length}`,
                            pathHint: '',
                            startText: '',
                            hint: '',
                            personages: [],
                            textures: [],
                            backgroundImage: null,
                            backgroundSound: null,
                            isGPTChat: false,
                            NextShot: null,
                            settings: {"type": 4, "settings": []},
                            voice: TypeSettingsGenderVoice.WOMAN,
                            defaultAnimate: 'none',
                            settingsAutoPlay: {
                                play: false,
                                time: 1
                            }
                        }
                        setSelectSettingsShot(shot)
                        return {
                            ...game,
                            shots: [...game.shots, shot]
                        }
                }
                else{
                    setError('Сохраните "Общие настройки игры"')
                    setShowNotification(true)
                }

                return null
    })
        setModalAddShot(!modalAddShot)
        setOpenFullShot(true)
        setIsOpenLeftMenu(true)
    };

    const saveSettingsShot = (shot: IShot) => {
        setUpdate(true)
        setSelectSettingsShot(shot)
    }

    const saveShotInGame = () => {
        if (selectSettingsShot) {
            setGame((game) => {
                if (game) {
                    return {
                        ...game,
                        shots: game.shots.map((oldShot) => {
                            if (oldShot.id === selectSettingsShot.id) {
                                return selectSettingsShot
                            }
                            return oldShot
                        })
                    }
                }
                return null
            })
            setUpdate(false)
        }
        console.log(game)
    }

    const saveGame = () => {
        if (game) {
            const validError = validateGame(game)
            if (validError) {
                setError(validError)
                setShowNotification(true)
            } else {
                setSavingGame(true)
                const formData = new FormData()
                if (game?.file) {
                    formData.append('file', game.file)
                    formData.append('data', JSON.stringify(game))
                } else {
                    formData.append('data', JSON.stringify(game))
                }
                AxiosClient.post('/game/create', formData, {headers: {"Content-Type": "multipart/form-data"}})
                    .then(r => {
                        setSavingGame(false)
                        navigate('/games/teacher')
                    })
                    .catch((reason) => {
                        setError(reason.response.data.message ?? 'Ошибка создания игры')
                        setShowNotification(true)
                        setSavingGame(false)
                    })
            }
        }
        console.log(game)
    }

    const copyShot = () => {
        setIsOpenCopy(true)
    }

    const copy = () => {
        if (selectSettingsShot) {
            setGame(game => {
                if (game) {
                    return {
                        ...game,
                        shots: [...game.shots, {
                            ...selectSettingsShot,
                            uid: uuidv4(),
                            id: game.shots.length + 1,
                            index: game.shots.length,
                            name: selectSettingsShot.name + ' - копия'
                        }]
                    }
                }
                return null
            })
            setIsOpenCopy(false)
        }
    }

    const deleteShot = () => {
        setIsOpen(true)
    }

    const removeShot = () => {
        selectSettingsShot &&
        setGame((game) => {
            if (game) {
                return {
                    ...game,
                    shots: game.shots.filter((oldShot) => oldShot.id !== selectSettingsShot.id)
                }
            }
            return null
        })
        setUpdate(false)
        setSelectSettingsShot(null)
        setIsOpen(false)
    }

    const toggleDelete = () => {
        setIsOpen((value) => !value)
    }

    const toggleCopy = () => {
        setIsOpenCopy((value) => !value)
    }

    return (
        <div className="h-100 w-100 pb-3 position-relative" id="containerMainAllMenu">
            <RightMenu saveShot={saveShotInGame} isOpen={isOpenRightMenu} setIsOpen={setIsOpenRightMenu} dataGame={game} toggleSave={toggleSave}
                       toggleAddShotSave={toggleAddShotSave} handleOpenSettings={handleOpenSettings}
                       setSelectSettingsShot={(shot) => {
                           if (update) {
                               setError('Сохраните текущий кадр')
                               setShowNotification(true)
                           } else {
                               setSelectSettingsShot(shot)
                               //setIsOpenRightMenu(false)
                           }
                       }} copyShot={copyShot} deleteShot={deleteShot}/>
            {
                selectSettingsShot && game &&
                <LeftMenu isOpen={isOpenLeftMenu} setIsOpen={setIsOpenLeftMenu} game={game}
                          setGame={game => setGame(game)} selectedShot={selectSettingsShot}
                          saveSettingsShot={(shot) => saveSettingsShot(shot)}/>
            }
            <Row>
                <Col sm={12}>
                    <div className="bg-white fs-1 d-flex align-items-center gap-2 rounded shadow-lg">
                        <i className='bx bx-save cursor-pointer menu'
                           onClick={() => saveGame()}
                        ></i>
                    </div>
                </Col>
            </Row>
            <div className="h-100 w-100 bg-white position-relative mt-3" id="window">
                <button className="text-black btn bg-white position-absolute top-50 border"
                        disabled={!(!!selectSettingsShot)} style={{marginLeft: -20, zIndex: 20}}
                        onClick={() => setIsOpenLeftMenu(!isOpenLeftMenu)}>
                    <i className="bx bxs-right-arrow"></i>
                </button>
                <button className="text-black btn bg-white position-absolute end-0 top-50 border"
                        style={{marginRight: -20, zIndex: 20}} onClick={() => setIsOpenRightMenu(!isOpenRightMenu)}>
                    <i className="bx bxs-left-arrow"></i>
                </button>
                {
                    savingGame && <div
                        className="d-flex justify-content-center align-items-center bg-dark bg-opacity-50 position-absolute top-0 start-0 w-100 h-100"
                        style={{zIndex: 9999}}>
                        <div className="text-center">
                            <div className="text-light fs-3 mb-3">Сохранение</div>
                            <Spinner className="text-light" style={{width: '3rem', height: '3rem'}}/>
                        </div>
                    </div>
                }
                {
                    selectSettingsShot &&
                    <FullShot shot={selectSettingsShot} openFullShot={openFullShot} saveSettingsShot={saveSettingsShot} game={game}/>
                }
                {showNotification && <Notification type={'error'} message={error}
                                                   setShowNotification={(value: boolean) => setShowNotification(value)}/>}
                <Modal isOpen={isOpenCopy} toggle={toggleCopy} centered>
                    <ModalHeader toggle={toggleCopy} className="p-3 bg-soft-primary">Копирование кадра</ModalHeader>
                    <ModalBody>
                        Вы уверены, что хотите скопировать кадр?
                        <div className="mt-3 gap-2 d-flex">
                            <button className="btn btn-primary" type="button" onClick={() => copy()}>
                                Копировать
                            </button>
                            <button className="btn btn-primary" type="button" onClick={toggleCopy}>
                                Отмена
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={isOpen} toggle={toggleDelete} centered>
                    <ModalHeader toggle={toggleDelete} className="p-3 bg-soft-primary">Удаление кадра</ModalHeader>
                    <ModalBody>
                        Вы уверены, что хотите удалить кадр?
                        <div className="mt-3 gap-2 d-flex">
                            <button className="btn btn-primary" type="button" onClick={() => removeShot()}>
                                Удалить
                            </button>
                            <button className="btn btn-primary" type="button" onClick={toggleDelete}>
                                Отмена
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </div>
    );
};

export default Constructor;
