import React, {FC, useEffect, useState} from "react";
import { useAppDispatch, useAppSelector} from "../../../hooks/redux";
import { setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import { Modal, ModalBody, ModalHeader, Label, Row, Col } from "reactstrap";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import AxiosClient from "../../../api/AxiosClient";

interface IModalAddTypeWorkPermits {
    updateData: any;
    onSuccess: () => void;
}

const ModalAddTypeWorkPermits: FC<IModalAddTypeWorkPermits> = ({ updateData, onSuccess }) => {
    const dispatch = useAppDispatch();
    const { modalAdd } = useAppSelector(state => state.WidgetReducer);
    const [periodicities, setPeriodicities] = useState<any[]>([]);

    useEffect(() => {
        // Загружаем данные из periodicity_events для селекта
        AxiosClient.post('/periodicity-events')
            .then(r => {
                setPeriodicities(r.data);
            })
            .catch(error => {
                console.log('Ошибка загрузки периодичности событий:', error);
            });
    }, []);

    const validationSchema = Yup.object({
        name: Yup.string()
            .required('Введите наименование')
            .min(2, 'Наименование должно содержать минимум 2 символа'),
        periodicity_id: Yup.number()
            .required('Выберите период действия')
            .min(1, 'Выберите действительный период')
    });

    const handleSubmit = (values: { name: string, periodicity_id: string }, { resetForm }: any) => {
        dispatch(setIsSend(true));

        const request = updateData
            ? AxiosClient.post(`/type-work-permits/update/${updateData.id}`, {
                name: values.name,
                periodicity_id: Number(values.periodicity_id)
            })
            : AxiosClient.post("/type-work-permits/add", {
                name: values.name,
                periodicity_id: Number(values.periodicity_id)
            });

        request
            .then((response) => {
                if (response.status === 200) {
                    dispatch(setModalAdd(false));
                    onSuccess();
                    if (!updateData) resetForm();
                }
            })
            .catch((error) => {
                console.error('Ошибка при сохранении вида допуска работ:', error);
            })
            .finally(() => {
                dispatch(setIsSend(false));
            });
    };

    return (
        <Modal
            id="ModalAddTypeWorkPermits"
            isOpen={modalAdd}
            toggle={() => dispatch(setModalAdd(false))}
            modalClassName="zoomIn"
            tabIndex={-1}
            centered
        >
            <ModalHeader
                toggle={() => dispatch(setModalAdd(false))}
                className="p-3 bg-soft-primary"
            >
                {updateData ? 'Редактирование вида допуска работ' : 'Добавление вида допуска работ'}
            </ModalHeader>
            <ModalBody className="p-4">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        name: updateData?.name || '',
                        periodicity_id: updateData?.periodicity_id || ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <Row>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="nameInput" className="form-label">
                                            Наименование
                                        </Label>
                                        <Field
                                            type="text"
                                            className="form-control"
                                            id="nameInput"
                                            name="name"
                                            placeholder="Введите наименование"
                                        />
                                        <ErrorMessage
                                            name="name"
                                            component="div"
                                            className="text-danger mt-1"
                                        />
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="periodicityInput" className="form-label">
                                            Период действия
                                        </Label>
                                        <Field
                                            as="select"
                                            className="form-select"
                                            id="periodicityInput"
                                            name="periodicity_id"
                                        >
                                            <option value="">Выберите период</option>
                                            {periodicities.map(periodicity => (
                                                <option key={periodicity.id} value={periodicity.id}>
                                                    {periodicity.name}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage
                                            name="periodicity_id"
                                            component="div"
                                            className="text-danger mt-1"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className="hstack gap-2 justify-content-end">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting
                                                ? 'Сохранение...'
                                                : updateData
                                                    ? 'Сохранить изменения'
                                                    : 'Добавить'
                                            }
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddTypeWorkPermits;