import React, { FC } from 'react';
import AxiosClient from "../../../api/AxiosClient";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Field, Form, Formik } from "formik";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {setIsSend} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../hooks/redux";

interface IAddGroupPage {
    addGroup: boolean;
    setAddGroup: (value: boolean) => void;
}

const AddGroupPage: FC<IAddGroupPage> = ({ addGroup, setAddGroup}) => {
    const dispatch = useAppDispatch();

    return (
        <Modal
            id="createProjectModal"
            isOpen={addGroup}
            toggle={() => setAddGroup(!addGroup)}
            modalClassName="zoomIn"
            tabIndex={-1}
            centered
        >
            <ModalHeader
                toggle={() => setAddGroup(!addGroup)}
                className="p-3 bg-soft-primary"
                id="createProjectModalLabel"
            >
                Добавление группы страниц
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={{
                        name: '',
                    }}
                    onSubmit={(values) => {
                        dispatch(setIsSend(true))
                        AxiosClient.post('group_pages/create', {name: values.name})
                            .then((r: any) => {
                                setAddGroup(false);
                                dispatch(setIsSend(false));
                            })
                            .catch((error) => {
                                dispatch(setIsSend(false))
                            })
                    }}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <div className="mb-3">
                                <label className="w-100"> Название:
                                    <Field name="name" className={`form-control`}/>
                                </label>
                            </div>
                            <div className="hstack gap-2 justify-content-end mt-1">
                                <SubmitWithLoading text={"Сохранить"}/>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default AddGroupPage;
