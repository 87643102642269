import React, {FC, useState} from 'react';
import {Col, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Russian} from "flatpickr/dist/l10n/ru";
import Flatpickr from "react-flatpickr";
import {Field, Form, Formik} from "formik";
import Select from "react-select";
import MyEventService from "../../../../api/services/documentsFlowService/MyEventService";
import {useStateContext} from "../../../../hooks/useStateContext";
import {useEscapeKey} from "../../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../../hooks/redux";

interface ModalAddEvent {
    openModalAddEvent: boolean,
    setOpenModalAddEvent: (value: boolean) => void
}
const ModalAddEvent: FC<ModalAddEvent> = ({openModalAddEvent, setOpenModalAddEvent}) => {

    const dispatch  =useAppDispatch()
    const {user} = useStateContext()

    const [dateBegin, setDateBegin] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());

    const customStyles = {
        control: (ds: any) => ({
            ...ds,
            backgroundColor: "#fff",
        }),
        option: (ds: any, state: any) => ({
            ...ds,
            backgroundColor: state.isFocused ? "#4b38b3" : "#fff",
            color: state.isFocused ? "#fff" : "#000"
        })
    }
    useEscapeKey(() => dispatch(setModalAdd(false)), openModalAddEvent, "ModalAddEvent");
    return (
        <Modal id="ModalAddEvent" isOpen={openModalAddEvent}
               toggle={() => setOpenModalAddEvent(!openModalAddEvent)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setOpenModalAddEvent(!openModalAddEvent)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Информация о событии
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={
                        {
                            name: '',
                            description: ''
                        }
                    }
                    onSubmit={(values) => {
                        MyEventService.addEvent({startdate: dateBegin, enddate: dateEnd, name: values.name, type: "", person: "", description: values.description, author: user.user1c})
                            .then()
                    }}
                >
                    <Form>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <Label>
                                            Дата начала
                                        </Label>
                                        <Flatpickr
                                            className="form-control border-0 dash-filter-picker shadow"
                                            value={dateBegin}
                                            options={{
                                                locale: Russian,
                                                mode: "single",
                                                dateFormat: "d.m.Y H:i",
                                                enableTime: true
                                            }}
                                            onChange={(date) => setDateBegin(date[0])}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <Label>
                                            Дата конец
                                        </Label>
                                        <Flatpickr
                                            className="form-control border-0 dash-filter-picker shadow"
                                            value={dateEnd}
                                            options={{
                                                locale: Russian,
                                                mode: "single",
                                                dateFormat: "d.m.Y H:i",
                                                enableTime: true
                                            }}
                                            onChange={(date) => setDateEnd(date[0])}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <Label>
                                            Срочность события
                                        </Label>
                                        <Select
                                            options={[{value: 1, label: 'Salle'}, {value: 2, label: 'Fast salle'}]}
                                            styles={customStyles}
                                            placeholder="Поиск"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <Label>
                                            Название
                                        </Label>
                                        <Field name="name" placeholdre="Название" className="form-control"/>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <Label>
                                            Описание
                                        </Label>
                                        <Field as="textarea" name="description" placeholdre="Описание" className="form-control"/>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <button className="btn btn-primary" type="submit">Добавить событие</button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddEvent;
