import React, {FC, useState} from 'react';
import DataTable, {ConditionalStyles} from "react-data-table-component";
import ModalUpdatePersonalAccount from "./ModalUpdatePersonalAccount";
interface IListPersonalAccount {
    listPersonalAccount: any[],
    setStudents: (value: any) => void
}
const ListPersonalAccount: FC<IListPersonalAccount> = ({listPersonalAccount, setStudents}) => {

    const [updatePersonalAccount, setUpdatePersonalAccount] = useState(false);
    const [selectPersonalAccount, setSelectPersonalAccount] = useState<any | null>(null);
    const columns = [
        {
            name: <span className='font-weight-bold accessibility_size_table'></span>,
            cell: () => <i className="fs-3"></i>,
            width: '48px',
            style: {padding: '0.4rem'}
        },
        {
            id: "date",
            cell: (row: any) => <span className='accessibility_size_table'> {row.personal_account}</span>,
            wrap: true
        },
        {
            cell: (row: any) => <span className='accessibility_size_table'> {row.school.name}</span>,
            hide: 599
        },
        {
            cell: (row: any) => <span className='accessibility_size_table'> {row.building.name}</span>,
            hide: 959
        },
        {
            cell: (row: any) => <span className='accessibility_size_table'> {row.class.name}</span>,
            hide: 959
        },
        {
            selector: (row: any) => `${row.benefits.map((b: any) => b.pivot.sum ? +b.pivot.sum : 0).reduce((a: any, b: any) => a + b, 0)} р`,
            hide: 1280
        },
        {
            selector: (row: any) => `${row.catering_organizer_type_planned_menu.map((b: any) => b.price ? +b.price : 0).reduce((a: any, b: any) => a + b, 0)} р`,
            hide: 1280
        },
        {
            cell: () => '',
            width: '50px'
        },
    ]

    const conditionalRowStyles: ConditionalStyles<any>[] = [
        {
            when: (row: any) => row.date_end !== null,
            style: {
                color: 'gray'
            },
        },
    ];

    return (
        <>
            <DataTable
                noTableHead
                columns={columns}
                data={listPersonalAccount}
                noDataComponent={'Нет данных'}
                highlightOnHover
                customStyles={{
                    rows: {
                        highlightOnHoverStyle: {
                            backgroundColor: "#ffebb5"
                        }
                    }
                }}
                conditionalRowStyles={conditionalRowStyles}
                onRowClicked={
                    (row) => {
                        if(window.innerWidth <= 768){
                            setUpdatePersonalAccount(true)
                            setSelectPersonalAccount(row)
                        }
                    }
                }
                onRowDoubleClicked={
                    (row) => {
                        if(window.innerWidth > 768){
                            setUpdatePersonalAccount(true)
                            setSelectPersonalAccount(row)
                        }
                    }
                }
            />
            <ModalUpdatePersonalAccount setStudents={value => setStudents(value)} selectPersonalAccount={selectPersonalAccount} updatePersonalAccount={updatePersonalAccount} setUpdatePersonalAccount={(value) => setUpdatePersonalAccount(value)}/>
        </>
    );
};

export default ListPersonalAccount;
