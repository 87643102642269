import React from 'react';
import { Card, CardBody, CardHeader } from "reactstrap";
import { Link } from 'react-router-dom';
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";

// Интерфейс для центра тестирования
interface ITestingCenter {
    id: number;
    name: string;
    link: string;
}

const TestingCenters: React.FC = () => {
    // Статические данные для центров тестирования
    const centers: ITestingCenter[] = [
        {
            id: 1,
            name: "Центр тестирования 1",
            link: "https://testcenter1.com",
        },
        {
            id: 2,
            name: "Центр тестирования 2",
            link: "https://testcenter2.com",
        },
        {
            id: 3,
            name: "Центр тестирования 3",
            link: "https://testcenter3.com",
        },
    ];

    return (
        <Card>
            <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Центры тестирования</h4>
            </CardHeader>
            <CardBody>
                {centers.map(center => (
                    <div key={center.id} className="d-flex align-items-center mb-3">
                        <div className="flex-grow-1">
                            <a href={'#'}> <h5 className="mb-1">{center.name}</h5></a>
                            <a href={center.link} className="text-muted" target="_blank" rel="noopener noreferrer">
                                {center.link}
                            </a>
                        </div>
                        <button
                            className="btn"
                            title="Редактировать"
                        >
                            <i className="bi bi-pencil fs-5 text-black" />
                        </button>
                    </div>
                ))}
            </CardBody>
        </Card>
    );
};

export default TestingCenters;