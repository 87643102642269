import {Field} from "formik";
import {FC, useEffect, useState} from "react";
import DataTable from "react-data-table-component";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {IDeviation} from "../../../../models/widgets/IDeviation";
import {IStudent} from "../../../../models/widgets/IStudent";
import WidgetService from "../../../../api/services/widgetService/WidgetService";
import {getStudents} from "../../../../store/reducers/widgets/ActionCreator";
import {setLoadingStudents} from "../../../../store/reducers/widgets/WidgetsSlice";
import TableChildInfo from "./TableChildInfo";

interface ITableAdd {
    presentAndNoPresent: (present: number, noPresent: number) => void;
    values: any;
}

const TableAdd: FC<ITableAdd> = ({ presentAndNoPresent, values }) => {

    const {students, groupModalAdd, dateAdd, selectDeviationAll} = useAppSelector(state => state.WidgetReducer)
    const dispatch = useAppDispatch();
    const [expandedRow, setExpandedRow] = useState<any | null>(null);

    const [deviations, setDeviations] = useState<IDeviation[]>([])

    useEffect(() => {
        WidgetService.getDeviations()
            .then(res => setDeviations(res.data))
    }, [])

    useEffect(() => {
        if (groupModalAdd !== undefined) {
            dispatch(getStudents(dateAdd, groupModalAdd)).then()
        }
    }, [dateAdd, groupModalAdd, dispatch])

    useEffect(() => {
        dispatch(setLoadingStudents(
            students.map(s => ({...s, deviation_code: selectDeviationAll}))
        ))
    }, [selectDeviationAll]);

    const calculateAttendance = (attendanceValues: any) => {
        let present = 0;
        let noPresent = 0;

        attendanceValues.forEach((deviation: any) => {
            const studentCode = Object.keys(deviation)[0];
            const deviationValue = deviation[studentCode];
            if (!deviationValue || deviationValue === "") {
                present += 1;
            } else {
                noPresent += 1;
            }
        });

        presentAndNoPresent(present, noPresent);
    };

    const clearDate = (student: IStudent, deviation: string, updatedValues: any) => {
        if (deviation === "") {
            const newDeviationDataPrev = student.deviation_data_prev || student.deviation_data;
            const updatedStudent = {
                ...student,
                deviation_data: "",
                deviation_data_prev: newDeviationDataPrev,
            };
            const updatedStudents = students.map((s) =>
                s.code === updatedStudent.code ? updatedStudent : s
            );
            dispatch(setLoadingStudents(updatedStudents));
        } else {
            const updatedStudent = {
                ...student,
                time_c: "",
                time_po: "",
            };
            const updatedStudents = students.map((s) =>
                s.code === updatedStudent.code ? updatedStudent : s
            );
            dispatch(setLoadingStudents(updatedStudents));
        }
        calculateAttendance(updatedValues);
    };

    useEffect(() => {
        if (students.length > 0 && values.length > 0) {
            calculateAttendance(values);
        }
    }, [students, values]);


    const columns: any = [
        {
            name: <span className='font-weight-bold accessibility_size_table'>ФИО ребенка</span>,
            cell: (row: IStudent) => <span className="accessibility_size_table">{row.fio}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Отсутствие</span>,
            selector: (row: IStudent, i: number) => (
                <Field as="select" className="form-select table-add-ceil selectStudentsDev" name={`deviations[${i}].${row.code}`}
                       onClick={(e: any) => {
                           const newValue = e.target.value;
                           const updatedValues = values.map((deviation: any, index: number) =>
                               index === i ? { [row.code]: newValue } : deviation
                           );
                           clearDate(row, newValue, updatedValues);
                       }}>
                    <option value={''}>Явка</option>
                    {
                        (deviations || []).map((item) => (
                            <option key={item.code} value={item.code}>{item.symbol + " (" + item.name + ")"}</option>
                        ))
                    }
                </Field>
            ),
            center: true,
            style: {padding: '0'},
            wrap: true
        }
    ];

    return (
        <>
            <DataTable
                columns={columns}
                data={students}
                className="custom-scroll-news"
                fixedHeader={true}
                fixedHeaderScrollHeight={'400px'}
                noDataComponent={'В данной группе нет детей'}
                highlightOnHover
                customStyles={{
                    rows: {
                        highlightOnHoverStyle: {
                            backgroundColor: "#ffebb5"
                        }
                    }
                }}
                expandableRows
                expandableRowExpanded={(row: IStudent) => expandedRow === row.code}
                onRowClicked={(row: IStudent) => setExpandedRow(expandedRow === row.code ? null : row.code)}
                expandableRowsComponent={(row: any) =>
                    row.data && (row.data.hasOwnProperty('base64') || row.data.hasOwnProperty('deviation_data')) ? (
                        <TableChildInfo student={[row.data]} values={values}/>
                    ) : null
                }
                conditionalRowStyles={[
                    {
                        when: (row: IStudent) => row?.is_taboo === "1",
                        style: {
                            backgroundColor: "rgba(236,234,247, 0.5)",
                            borderLeft: "5px solid #4a37b2",
                        }
                    }
                ]}
            />
        </>

    );
}

export default TableAdd;