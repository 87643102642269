import React from 'react';
import { Card, CardBody } from "reactstrap";

// Интерфейс для блока действия
interface IActionBlockProps {
    icon: string; // Путь к иконке
    title: string;
    description: string;
    onClick: () => void;
}

const ActionBlock: React.FC<IActionBlockProps> = ({ icon, title, description, onClick }) => {
    return (
        <Card
            className="cursor-pointer"
            onClick={onClick}
            style={{ transition: 'all 0.3s', border: '1px solid #e0e0e0' }}
            onMouseEnter={(e) => (e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)')}
            onMouseLeave={(e) => (e.currentTarget.style.boxShadow = 'none')}
        >
            <CardBody
                className="d-flex align-items-center"
                style={{ minHeight: '120px' }}
            >
                <i
                    className={icon}
                    style={{ fontSize: '2.5rem', marginRight: '15px', color: '#4e3bb8' }} // Цвет как у text-primary
                />
                <div>
                    <h5 className="mb-1 text-primary">{title}</h5>
                    <p className="text-muted mb-0">{description}</p>
                </div>
            </CardBody>
        </Card>
    );
};

export default ActionBlock;