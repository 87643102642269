import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    setDateAdd, setGroupAllDeviations, setGroupModalAdd,
    setIsSend,
    setModalAdd,
    WidgetsSlice
} from "../../../store/reducers/widgets/WidgetsSlice";
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import moment from "moment/moment";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import AxiosClient from "../../../api/AxiosClient";
import {getJournalHaccpAntisepticRoom} from "../../../store/reducers/widgets/ActionCreator";
import ModalConfirmExitHaccp from "../ModalConfirmExitHaccp";
import {useModalWithConfirm} from "../../../hooks/useModalWithConfirm";

interface IModalAddAntisepticRoom{
    updateData: any
}

const ModalAddAntisepticRoom: FC<IModalAddAntisepticRoom> = ({updateData}) => {
    const dispatch = useAppDispatch();
    const {modalAdd, groupAllDeviations, date, groupModalAdd, groups, dateAdd, successUpdate} = useAppSelector(state => state.WidgetReducer);

    const [schedule, setSchedule] = useState<any>([]);
    const [selectScheduleUid, setSelectScheduleUid] = useState<any>( '');

    const [dateSchedule, setDateSchedule] = useState<any>([]);
    const [selectScheduleDateTime, setSelectScheduleDateTime] = useState<{data: string, time: string}>(updateData ? {data: updateData.data_plan, time: updateData.time_plan} : {data: '', time: ''});

    const [disinfectants, setDisinfectants] = useState<any[]>([]);

    const {
        isFormChange,
        showConfirmExit,
        handleClose,
        handleFormChange,
        handleConfirmClose,
        handleStay,
    } = useModalWithConfirm("addAntisepticRoom");

    useEffect(() => {
        AxiosClient.get('haccp/getDisinfectantsist')
            .then((r: any) => setDisinfectants(r.data))
            .catch((error) => console.log(error));
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(WidgetsSlice.actions.successUpdate(""))
        }, 5000)
        return () => clearTimeout(timer)
    }, [successUpdate, dispatch]);

    useEffect(() => {
        if(modalAdd){
            AxiosClient.get(`haccp/getScheduleDisinfectionList/${groupAllDeviations}`)
                .then((r: any) => {
                    setSchedule(r.data)
                    if (r.data.length) {
                        setSelectScheduleUid(r.data[0].uid)
                        setDateSchedule(r.data[0].data_list)
                        setSelectScheduleDateTime(r.data[0].data_list[0])
                    }
                })
                .catch((error) => console.log(error))
        }
    }, [modalAdd])

    useEffect(() => {
        if (modalAdd && !updateData) {
            dispatch(setDateAdd(new Date()));
        }
    }, [dispatch, modalAdd]);

    return (
        <>
        <Modal id="addAntisepticRoom" isOpen={modalAdd} toggle={handleClose}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={handleClose} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление параметров обработки помещений
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        group: `${groupAllDeviations}`,
                        date: moment(dateAdd).format("DD-MM-YYYY"),
                        setting: updateData? updateData.setting : selectScheduleUid,
                        disinfectant_uid: updateData? updateData.disinfectant_uid : '',
                        typeOfDisinfection: updateData? updateData.typeOfDisinfection : 'Обработка',
                        datePlan: updateData? updateData.datePlan : selectScheduleDateTime.data,
                        timePlan: updateData? updateData.timePlan : selectScheduleDateTime.time,
                        timeOfDisinfection: updateData? updateData.timeOfDisinfection : moment().format('HH:mm'),
                        concentration:   updateData? updateData.concentration : ''
                    }}
                    onSubmit={(values) => {
                        dispatch(setIsSend(true));

                        let prop = {
                            uid: updateData? updateData.uid : '',
                            data: moment(dateAdd).format("DD-MM-YYYY"),
                            setting: values.setting,
                            disinfectant_uid: disinfectants.find((val) => val.uid === values.disinfectant_uid).uid,
                            typeOfDisinfection: values.typeOfDisinfection,
                            datePlan: values.datePlan,
                            timePlan: values.timePlan,
                            timeOfDisinfection: values.timeOfDisinfection,
                            concentration: values.concentration,
                        }

                        AxiosClient.post('haccp/saveJournalAntisepticRoom', prop)
                            .then((r: any) => {
                                dispatch(getJournalHaccpAntisepticRoom(date,groupAllDeviations)).then()
                                dispatch(setIsSend(false));
                                dispatch(setModalAdd(false));
                                handleConfirmClose();
                            })
                            .catch((error) => {
                                dispatch(setIsSend(false))
                            })
                    }}
                >
                    {({ errors, touched, setFieldValue ,values}) => (
                    <Form onChange={handleFormChange}>
                        <Row>
                            <Col>
                                <label className="w-100">Дата:
                                    <Flatpickr
                                        className="form-control border-0 dash-filter-picker shadow"
                                        value={dateAdd}
                                        options={{
                                            locale: Russian, dateFormat: "d M Y", disableMobile: true, mode: 'single'
                                        }}
                                        onChange={(date) => {
                                            dispatch(setDateAdd(moment(date[0]).toDate()))
                                        }}
                                    />
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <label className="w-100">Группа:
                                <Field as="select" className="form-select shadow" value={groupModalAdd}
                                       onChange={(e: any) => {dispatch(setGroupAllDeviations(e.target.value))
                                       dispatch(setGroupModalAdd(e.target.value))}} name="group">
                                    {groups.map((group) => {
                                        return <option key={group.group_id}
                                                       value={group.group_id}>{group.group_name}</option>
                                    })}
                                </Field>
                            </label>
                        </Row>
                        <Row>
                            <Col>
                                <label className='w-100'>График:
                                    <Field as="select" className="form-select shadow"
                                           onChange={(e: any) => {
                                               setSelectScheduleUid(e.target.value);
                                               const selectedSchedule = schedule.find((item: any) => item.uid === e.target.value);
                                               if (selectedSchedule) {
                                                   setDateSchedule(selectedSchedule.data_list);
                                                   if (selectedSchedule.data_list.length) {
                                                       setSelectScheduleDateTime(selectedSchedule.data[0].data_list[0]);
                                                   }
                                               }
                                           }} name="schedule">
                                        {schedule.map((value: any) => {
                                            return <option key={value.uid}
                                                           value={value.uid}>{value.name}</option>
                                        })}
                                    </Field>
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label className='w-100'>Дата план:
                                    <Field as="select" className="form-select shadow"
                                           onChange={(e: any) => {
                                               const selectedDate = e.target.value;
                                               const selectedDateObj = dateSchedule.find((item: any) => item.data === selectedDate);
                                               if (selectedDateObj) {
                                                   setSelectScheduleDateTime({
                                                       data: selectedDateObj.data,
                                                       time: selectedDateObj.time,
                                                   });
                                               }

                                           }} name="datePlan">
                                        {dateSchedule.map((value: any, index: number) => {
                                            return <option key={index}
                                                           value={value.data}>{value.data}</option>
                                        })}
                                    </Field>
                                </label>
                            </Col>

                            <Col>
                                <label className="w-100">Время план:
                                    <Field name="timePlan" className={`form-control`} disabled={true} value={selectScheduleDateTime.time}/>
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label className='w-100'>Вид обработки:
                                    <Field as="select" className="form-select shadow" name="typeOfDisinfection">
                                        <option key={0} value="Обработка">Обработка</option>
                                        <option key={1} value="Дезинфекция">Дезинфекция</option>
                                    </Field>
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label className='w-100'>Дез средство:
                                    <Field as="select" className="form-select shadow" name="disinfectant_uid">
                                        <option value="">Не выбрано</option>
                                        {disinfectants.map(el => (
                                            <option key={el.uid} value={el.uid}>{el.name}</option>
                                        ))}
                                    </Field>
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label className="w-100">Концентрация:
                                    <Field name="concentration" className={`form-control`}/>
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label className='w-100'>Время обработки:
                                        <div className="input-group col-auto">
                                            <Flatpickr
                                                name="timeOfDisinfection"
                                                className="form-control border-0 dash-filter-picker shadow"
                                                value={values.timeOfDisinfection}
                                                options={{
                                                    locale: Russian,
                                                    enableTime: true,
                                                    noCalendar: true,
                                                    dateFormat: "H:i",
                                                    time_24hr: true,
                                                    disableMobile: true,
                                                    mode: 'single'
                                                }}
                                                onChange={(time) => {
                                                    const formattedTime = moment(time[0]).format('HH:mm');
                                                    setFieldValue('timeOfDisinfection', formattedTime);
                                                }}
                                            />
                                        </div>
                                </label>
                            </Col>
                        </Row>
                        <div className="d-flex gap-5 justify-content-between">
                            <div></div>
                            <div className="hstack gap-2 justify-content-end mt-1">
                                <SubmitWithLoading text={"Сохранить"}/>
                            </div>
                        </div>
                    </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
            <ModalConfirmExitHaccp
                isOpen={showConfirmExit}
                onClose={handleConfirmClose}
                onStay={handleStay}
                hasUnsavedChanges={isFormChange}
            />
        </>
    );
};

export default ModalAddAntisepticRoom;
