import React, {FC} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {ModalFooter, Button } from 'reactstrap';
import {useAppDispatch} from "../../../hooks/redux";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";

interface ModalConfirmChangeOrganizer {
    isOpen: boolean,
    toggle: () => void,
    onConfirm: () => void,
    onCancel:() => void,
}

const ModalConfirmChangeOrganizer: FC<ModalConfirmChangeOrganizer> = ({ isOpen, toggle, onConfirm, onCancel }) => {
    const dispatch = useAppDispatch();
    useEscapeKey(() => dispatch(setModalAdd(false)), isOpen, "ModalConfirmChangeOrganizer");

    return (
        <Modal id="ModalConfirmChangeOrganizer" isOpen={isOpen} toggle={toggle}
            modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={toggle} className="p-3 bg-soft-primary" id="createProjectModalLabel">Подтверждение изменения</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <p>Вы уверены, что хотите сменить действующего организатора питания?</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="hstack gap-2">
                            <button className="btn btn-primary " type="button" id="addNewProject"
                                    onClick={onConfirm
                            }>Да
                            </button>
                            <button className="btn btn-primary" type="button" id="addNewProject"
                                    onClick={onCancel
                            }>Нет
                            </button>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

export default ModalConfirmChangeOrganizer;