import React, {useEffect, useState} from 'react';
import {
    Button,
    Col,
    Container,
    Row
} from "reactstrap";
import {useNavigate} from "react-router-dom";
import AxiosClient from "../../api/AxiosClient";
import DesctopNews from "../../components/news/DesctopNews";
import MobileNews from "../../components/news/MobileNews";
import {Helmet} from "react-helmet";
import {getGroups} from "../../store/reducers/widgets/ActionCreator";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import NotificationShow from "../../components/widgets/NotificationShow";

const News = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const [news, setNews] = useState<any[]>([]);
    const {date, groups} = useAppSelector(state => state.WidgetReducer);
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = (event: any) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        dispatch(getGroups(date)).then()
    }, [date, dispatch]);

    useEffect(() => {
        groups.length > 0 &&
        AxiosClient.get('/news', {params: {groups}})
            .then(r => setNews(r.data))
    }, [groups])

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Новости</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col className="col-auto">
                        <Button color="primary" onClick={() => navigate("/news/add")}>Добавить новость</Button>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <div>
                            <h5 className="mb-4">Новости</h5>
                            {
                                width > 450 ?
                                    <DesctopNews news={news} setNews={(news) => setNews(news)}/>
                                    :
                                    <MobileNews news={news} setNews={(news) => setNews(news)}/>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
            <NotificationShow />
        </div>
    );
};

export default News;
