import React, {FC, useCallback, useEffect, useState} from 'react';
import {useAppDispatch} from "../../../../hooks/redux";
import userDummayImage from "../../../../assets/images/users/user-dummy-img.jpg";
import ReactCrop, {Crop, PixelCrop} from "react-image-crop";
import {Col, Input, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {setIsSend, setModalAdd} from "../../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../../layouts/UI/SubmitWithLoading";
import TreeFamily from "../portfolioPage/TreeFamily";
import ModalCropPhoto from "../ModalCropPhoto";
import {useEscapeKey} from "../../../../hooks/useEscapeKey";

interface IEditTreeFamily{
    portfolio: any,
    setPortfolio(value: any): any,
    selectPagePortfolio: any,
    selectEditPagePortfolio: boolean,
    setSelectEditPagePortfolio(value: boolean): any
}

const EditTreeFamily: FC<IEditTreeFamily> = ({ portfolio, setPortfolio, selectEditPagePortfolio, setSelectEditPagePortfolio, selectPagePortfolio }) => {
    const dispatch = useAppDispatch();
    const [selectModalCropPhoto, setSelectModalCropPhoto]  = useState<boolean>(false);
    const [isDownloadPortfolioPage, setIsDownloadPortfolioPage] = useState<boolean>(false);
    const [isSelectNumberDownloadPortfolioPage, setIsSelectNumberDownloadPortfolioPage] = useState<number>(0);

    const [page, setPage] = useState<any>();
    const [content, setContent] = useState(JSON.parse(selectPagePortfolio?.content));
    const [photoPathList, setPhotoPathList] = useState<any[]>(JSON.parse(selectPagePortfolio.photo.replace(/'/g, '"')));
    const [photoListBase64, setPhotoListBase64] = useState<any[]>(selectPagePortfolio.photoBase64);

    const [pathPhoto, setPathPhoto] = useState(photoPathList?.[0] ? process.env.REACT_APP_API_BASE_URL + photoPathList?.[0] : userDummayImage); //filePhoto, setFilePhoto
    const [downloadPhoto, setDownloadPhoto] = useState<Blob | null>(); //fileForm, setFileForm
    const [downloadPhoto64, setDownloadPhoto64] = useState<string | ArrayBuffer | null>(); //fileForm64, setFileForm64
    const [crop, setCrop] = useState<Crop>();
    const [cropPath, setCropPath] = useState<any>();
    const [croppedImage, setCroppedImage] = useState<string | null>(null);

    const [pathPhotoFather, setPathPhotoFather] = useState(photoPathList?.[1] ? process.env.REACT_APP_API_BASE_URL + photoPathList?.[1] : userDummayImage); //filePhoto, setFilePhoto
    const [downloadPhotoFather, setDownloadPhotoFather] = useState<Blob | null>(); //fileForm, setFileForm
    const [downloadPhoto64Father, setDownloadPhoto64Father] = useState<string | ArrayBuffer | null>(); //fileForm64, setFileForm64
    const [cropFather, setCropFather] = useState<Crop>();
    const [cropPathFather, setCropPathFather] = useState<any>();
    const [croppedImageFather, setCroppedImageFather] = useState<string | null>(null);

    const [pathPhotoMather, setPathPhotoMather] = useState(photoPathList?.[2] ? process.env.REACT_APP_API_BASE_URL + photoPathList?.[2] : userDummayImage); //filePhoto, setFilePhoto
    const [downloadPhotoMather, setDownloadPhotoMather] = useState<Blob | null>(); //fileForm, setFileForm
    const [downloadPhoto64Mather, setDownloadPhoto64Mather] = useState<string | ArrayBuffer | null>(); //fileForm64, setFileForm64
    const [cropMather, setCropMather] = useState<Crop>();
    const [cropPathMather, setCropPathMather] = useState<any>();
    const [croppedImageMather, setCroppedImageMather] = useState<string | null>(null);

    const [pathPhotoGrandpaFather, setPathPhotoGrandpaFather] = useState(photoPathList?.[3] ? process.env.REACT_APP_API_BASE_URL + photoPathList?.[3] : userDummayImage); //filePhoto, setFilePhoto
    const [downloadPhotoGrandpaFather, setDownloadPhotoGrandpaFather] = useState<Blob | null>(); //fileForm, setFileForm
    const [downloadPhoto64GrandpaFather, setDownloadPhoto64GrandpaFather] = useState<string | ArrayBuffer | null>(); //fileForm64, setFileForm64
    const [cropGrandpaFather, setCropGrandpaFather] = useState<Crop>();
    const [cropPathGrandpaFather, setCropPathGrandpaFather] = useState<any>();
    const [croppedImageGrandpaFather, setCroppedImageGrandpaFather] = useState<string | null>(null);

    const [pathPhotoGrandmaFather, setPathPhotoGrandmaFather] = useState(photoPathList?.[4] ? process.env.REACT_APP_API_BASE_URL + photoPathList?.[4] : userDummayImage); //filePhoto, setFilePhoto
    const [downloadPhotoGrandmaFather, setDownloadPhotoGrandmaFather] = useState<Blob | null>(); //fileForm, setFileForm
    const [downloadPhoto64GrandmaFather, setDownloadPhoto64GrandmaFather] = useState<string | ArrayBuffer | null>(); //fileForm64, setFileForm64
    const [cropGrandmaFather, setCropGrandmaFather] = useState<Crop>();
    const [cropPathGrandmaFather, setCropPathGrandmaFather] = useState<any>();
    const [croppedImageGrandmaFather, setCroppedImageGrandmaFather] = useState<string | null>(null);

    const [pathPhotoGrandpaMather, setPathPhotoGrandpaMather] = useState(photoPathList?.[5] ? process.env.REACT_APP_API_BASE_URL + photoPathList?.[5] : userDummayImage); //filePhoto, setFilePhoto
    const [downloadPhotoGrandpaMather, setDownloadPhotoGrandpaMather] = useState<Blob | null>(); //fileForm, setFileForm
    const [downloadPhoto64GrandpaMather, setDownloadPhoto64GrandpaMather] = useState<string | ArrayBuffer | null>(); //fileForm64, setFileForm64
    const [cropGrandpaMather, setCropGrandpaMather] = useState<Crop>();
    const [cropPathGrandpaMather, setCropPathGrandpaMather] = useState<any>();
    const [croppedImageGrandpaMather, setCroppedImageGrandpaMather] = useState<string | null>(null);

    const [pathPhotoGrandmaMather, setPathPhotoGrandmaMather] = useState(photoPathList?.[6] ? process.env.REACT_APP_API_BASE_URL + photoPathList?.[6] : userDummayImage); //filePhoto, setFilePhoto
    const [downloadPhotoGrandmaMather, setDownloadPhotoGrandmaMather] = useState<Blob | null>(); //fileForm, setFileForm
    const [downloadPhoto64GrandmaMather, setDownloadPhoto64GrandmaMather] = useState<string | ArrayBuffer | null>(); //fileForm64, setFileForm64
    const [cropGrandmaMather, setCropGrandmaMather] = useState<Crop>();
    const [cropPathGrandmaMather, setCropPathGrandmaMather] = useState<any>();
    const [croppedImageGrandmaMather, setCroppedImageGrandmaMather] = useState<string | null>(null);


    const [isEditPortfolioPage, setIsEditPortfolioPage] = useState<boolean>(false);

    const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);

    const handleImageClick = (numberPhoto : number) => {
        if (isDownloadPortfolioPage) {
            if (numberPhoto === 1 ) document.getElementById('inputForFileTreeFamily1')?.click();
            else if (numberPhoto === 2 ) document.getElementById('inputForFileTreeFamily2')?.click();
            else if (numberPhoto === 3 ) document.getElementById('inputForFileTreeFamily3')?.click();
            else if (numberPhoto === 4 ) document.getElementById('inputForFileTreeFamily4')?.click();
            else if (numberPhoto === 5 ) document.getElementById('inputForFileTreeFamily5')?.click();
            else if (numberPhoto === 6 ) document.getElementById('inputForFileTreeFamily6')?.click();
            else if (numberPhoto === 7 ) document.getElementById('inputForFileTreeFamily7')?.click();
            setIsDownloadPortfolioPage(false);
        }
    };

    useEffect(() => {
        if (isEditPortfolioPage) {
            document.getElementById('handleCombineImagesTreeFamily')?.click();
            setIsEditPortfolioPage(false);
        }
    }, [isEditPortfolioPage]);
    useEscapeKey(() => dispatch(setModalAdd(false)), selectEditPagePortfolio, "EditTreeFamily");

    return (
        <>
            <Modal id="EditTreeFamily" isOpen={selectEditPagePortfolio}
                   toggle={() => setSelectEditPagePortfolio(!selectEditPagePortfolio)}
                   modalClassName="zoomIn"  tabIndex={-1} centered >
                <ModalHeader toggle={() => setSelectEditPagePortfolio(!selectEditPagePortfolio)}
                             className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Изменение страницы «{selectPagePortfolio?.type_page_portfolio.name}»
                </ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            photo_download: downloadPhoto64 ? downloadPhoto64 : `data:image/png;base64,${portfolio?.photoBase64}`,
                        }}
                        onSubmit={(values) => {
                            dispatch(setIsSend(true));
                            setPage({
                                uid: portfolio?.child_uid,
                                selectPage: selectPagePortfolio,
                                id_pattern: portfolio?.pattern_page_portfolio_id,
                                photo: downloadPhoto,
                                photo2: downloadPhotoFather,
                                photo3: downloadPhotoMather,
                                photo4: downloadPhotoGrandpaFather,
                                photo5: downloadPhotoGrandmaFather,
                                photo6: downloadPhotoGrandpaMather,
                                photo7: downloadPhotoGrandmaMather,
                                photo_download_base64: photoListBase64,
                                path_photo: photoPathList,
                                path_photo_page: selectPagePortfolio?.photo_page,
                            });
                            setIsEditPortfolioPage(true);
                        }}
                    >
                        {({ errors, touched, setFieldValue }) => (
                            <Form>
                                <Row>
                                    <Col>
                                        <Row className="mb-3">
                                            <Col>
                                                <label className="w-100">Фото ребенка (.jpg, .png):
                                                    <img
                                                        src={cropPath ? cropPath : pathPhoto}
                                                        alt="Загрузить фото"
                                                        className="user-profile-image w-100 h-auto"
                                                        onClick={() => {
                                                            handleImageClick(1)
                                                            setIsSelectNumberDownloadPortfolioPage(1)
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                    <Input
                                                        name="photo"
                                                        type="file"
                                                        id="inputForFileTreeFamily1"
                                                        className={`form-control d-none`}
                                                        accept=".png, .jpg"
                                                        onChange={(e: any) => {
                                                            const file = e.target.files[0];

                                                            if (file) {
                                                                const reader = new FileReader();
                                                                reader.onloadend = () => {
                                                                    setDownloadPhoto64(reader.result);
                                                                    setPhotoListBase64((prevArrList: any) => {
                                                                        const newArrList : any = [...prevArrList];
                                                                        newArrList[0] = reader.result;
                                                                        return newArrList;
                                                                    });
                                                                    setPathPhoto(reader.result as string);
                                                                };
                                                                reader.readAsDataURL(file);
                                                                setSelectModalCropPhoto(true)
                                                            }
                                                        }}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row className="mb-3">
                                            <Col>
                                                <label className="w-100">Фото папы (.jpg, .png):
                                                    <img
                                                        src={cropPathFather ? cropPathFather : pathPhotoFather}
                                                        alt="Загрузить фото"
                                                        className="user-profile-image w-100 h-auto"
                                                        onClick={() => {
                                                            handleImageClick(2)
                                                            setIsSelectNumberDownloadPortfolioPage(2)
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                    <Input
                                                        name="photo"
                                                        type="file"
                                                        id="inputForFileTreeFamily2"
                                                        className={`form-control d-none`}
                                                        accept=".png, .jpg"
                                                        onChange={(e: any) => {
                                                            const file = e.target.files[0];

                                                            if (file) {
                                                                const reader = new FileReader();
                                                                reader.onloadend = () => {
                                                                    setDownloadPhoto64Father(reader.result);
                                                                    setPhotoListBase64((prevArrList: any) => {
                                                                        const newArrList : any = [...prevArrList];
                                                                        newArrList[1] = reader.result;
                                                                        return newArrList;
                                                                    });
                                                                    setPathPhotoFather(reader.result as string);
                                                                };
                                                                reader.readAsDataURL(file);
                                                                setSelectModalCropPhoto(true)
                                                            }
                                                        }}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row className="mb-3">
                                            <Col>
                                                <label className="w-100">Фото мамы (.jpg, .png):
                                                    <img
                                                        src={cropPathMather ? cropPathMather : pathPhotoMather}
                                                        alt="Загрузить фото"
                                                        className="user-profile-image w-100 h-auto"
                                                        onClick={() => {
                                                            handleImageClick(3)
                                                            setIsSelectNumberDownloadPortfolioPage(3)
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                    <Input
                                                        name="photo"
                                                        type="file"
                                                        id="inputForFileTreeFamily3"
                                                        className={`form-control d-none`}
                                                        accept=".png, .jpg"
                                                        onChange={(e: any) => {
                                                            const file = e.target.files[0];

                                                            if (file) {
                                                                const reader = new FileReader();
                                                                reader.onloadend = () => {
                                                                    setDownloadPhoto64Mather(reader.result);
                                                                    setPhotoListBase64((prevArrList: any) => {
                                                                        const newArrList : any = [...prevArrList];
                                                                        newArrList[2] = reader.result;
                                                                        return newArrList;
                                                                    });
                                                                    setPathPhotoMather(reader.result as string);
                                                                };
                                                                reader.readAsDataURL(file);
                                                                setSelectModalCropPhoto(true)
                                                            }
                                                        }}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row className="mb-3">
                                            <Col>
                                                <label className="w-100">Фото дедушки со стороны папы (.jpg, .png):
                                                    <img
                                                        src={cropPathGrandpaFather ? cropPathGrandpaFather : pathPhotoGrandpaFather}
                                                        alt="Загрузить фото"
                                                        className="user-profile-image w-100 h-auto"
                                                        onClick={() => {
                                                            handleImageClick(4)
                                                            setIsSelectNumberDownloadPortfolioPage(4)
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                    <Input
                                                        name="photo"
                                                        type="file"
                                                        id="inputForFileTreeFamily4"
                                                        className={`form-control d-none`}
                                                        accept=".png, .jpg"
                                                        onChange={(e: any) => {
                                                            const file = e.target.files[0];

                                                            if (file) {
                                                                const reader = new FileReader();
                                                                reader.onloadend = () => {
                                                                    setDownloadPhoto64GrandpaFather(reader.result);
                                                                    setPhotoListBase64((prevArrList: any) => {
                                                                        const newArrList : any = [...prevArrList];
                                                                        newArrList[3] = reader.result;
                                                                        return newArrList;
                                                                    });
                                                                    setPathPhotoGrandpaFather(reader.result as string);
                                                                };
                                                                reader.readAsDataURL(file);
                                                                setSelectModalCropPhoto(true)
                                                            }
                                                        }}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row className="mb-3">
                                            <Col>
                                                <label className="w-100">Фото бабушки со стороны папы (.jpg, .png):
                                                    <img
                                                        src={cropPathGrandmaFather ? cropPathGrandmaFather : pathPhotoGrandmaFather}
                                                        alt="Загрузить фото"
                                                        className="user-profile-image w-100 h-auto"
                                                        onClick={() => {
                                                            handleImageClick(5)
                                                            setIsSelectNumberDownloadPortfolioPage(5)
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                    <Input
                                                        name="photo"
                                                        type="file"
                                                        id="inputForFileTreeFamily5"
                                                        className={`form-control d-none`}
                                                        accept=".png, .jpg"
                                                        onChange={(e: any) => {
                                                            const file = e.target.files[0];

                                                            if (file) {
                                                                const reader = new FileReader();
                                                                reader.onloadend = () => {
                                                                    setDownloadPhoto64GrandmaFather(reader.result);
                                                                    setPhotoListBase64((prevArrList: any) => {
                                                                        const newArrList : any = [...prevArrList];
                                                                        newArrList[4] = reader.result;
                                                                        return newArrList;
                                                                    });
                                                                    setPathPhotoGrandmaFather(reader.result as string);
                                                                };
                                                                reader.readAsDataURL(file);
                                                                setSelectModalCropPhoto(true)
                                                            }
                                                        }}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row className="mb-3">
                                            <Col>
                                                <label className="w-100">Фото дедушки со стороны мамы (.jpg, .png):
                                                    <img
                                                        src={cropPathGrandpaMather ? cropPathGrandpaMather : pathPhotoGrandpaMather}
                                                        alt="Загрузить фото"
                                                        className="user-profile-image w-100 h-auto"
                                                        onClick={() => {
                                                            handleImageClick(6)
                                                            setIsSelectNumberDownloadPortfolioPage(6)
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                    <Input
                                                        name="photo"
                                                        type="file"
                                                        id="inputForFileTreeFamily6"
                                                        className={`form-control d-none`}
                                                        accept=".png, .jpg"
                                                        onChange={(e: any) => {
                                                            const file = e.target.files[0];

                                                            if (file) {
                                                                const reader = new FileReader();
                                                                reader.onloadend = () => {
                                                                    setDownloadPhoto64GrandpaMather(reader.result);
                                                                    setPhotoListBase64((prevArrList: any) => {
                                                                        const newArrList : any = [...prevArrList];
                                                                        newArrList[5] = reader.result;
                                                                        return newArrList;
                                                                    });
                                                                    setPathPhotoGrandpaMather(reader.result as string);
                                                                };
                                                                reader.readAsDataURL(file);
                                                                setSelectModalCropPhoto(true)
                                                            }
                                                        }}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row className="mb-3">
                                            <Col>
                                                <label className="w-100">Фото быбушки со стороны мамы (.jpg, .png):
                                                    <img
                                                        src={cropPathGrandmaMather ? cropPathGrandmaMather : pathPhotoGrandmaMather}
                                                        alt="Загрузить фото"
                                                        className="user-profile-image w-100 h-auto"
                                                        onClick={() => {
                                                            handleImageClick(7)
                                                            setIsSelectNumberDownloadPortfolioPage(7)
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                    <Input
                                                        name="photo"
                                                        type="file"
                                                        id="inputForFileTreeFamily7"
                                                        className={`form-control d-none`}
                                                        accept=".png, .jpg"
                                                        onChange={(e: any) => {
                                                            const file = e.target.files[0];

                                                            if (file) {
                                                                const reader = new FileReader();
                                                                reader.onloadend = () => {
                                                                    setDownloadPhoto64GrandmaMather(reader.result);
                                                                    setPhotoListBase64((prevArrList: any) => {
                                                                        const newArrList : any = [...prevArrList];
                                                                        newArrList[6] = reader.result;
                                                                        return newArrList;
                                                                    });
                                                                    setPathPhotoGrandmaMather(reader.result as string);
                                                                };
                                                                reader.readAsDataURL(file);
                                                                setSelectModalCropPhoto(true)
                                                            }
                                                        }}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <div className="hstack gap-2">
                                    <SubmitWithLoading text={'Готово'} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
            <TreeFamily
                dispatch={(value) => dispatch(value)}
                page={page}
                setPortfolio={(value) => setPortfolio(value)}
                setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}
            />

            { isSelectNumberDownloadPortfolioPage === 1 &&
                <ModalCropPhoto portfolio={portfolio} setPortfolio={(value: any) => setPortfolio(value)}
                                selectPagePortfolio={selectPagePortfolio}
                                selectModalCropPhoto={selectModalCropPhoto} setSelectModalCropPhoto={(value: boolean) => setSelectModalCropPhoto(value)}
                                photoPathList={photoPathList} setPhotoListBase64={(value: any) => setPhotoListBase64(value)}
                                pathPhoto={pathPhoto} setDownloadPhoto={(value: any) => setDownloadPhoto(value)}
                                downloadPhoto64={downloadPhoto64} setDownloadPhoto64={(value: any) => setDownloadPhoto64(value)}
                                cropPath={cropPath} setCropPath={(value: any) => setCropPath(value)} indexPhoto={0}
                                relationsParties={1/1} croppedImage={croppedImage} />
            }
            { isSelectNumberDownloadPortfolioPage === 2 &&
                <ModalCropPhoto portfolio={portfolio} setPortfolio={(value: any) => setPortfolio(value)}
                                selectPagePortfolio={selectPagePortfolio}
                                selectModalCropPhoto={selectModalCropPhoto} setSelectModalCropPhoto={(value: boolean) => setSelectModalCropPhoto(value)}
                                photoPathList={photoPathList} setPhotoListBase64={(value: any) => setPhotoListBase64(value)}
                                pathPhoto={pathPhotoFather} setDownloadPhoto={(value: any) => setDownloadPhotoFather(value)}
                                downloadPhoto64={downloadPhoto64Father} setDownloadPhoto64={(value: any) => setDownloadPhoto64Father(value)}
                                cropPath={cropPathFather} setCropPath={(value: any) => setCropPathFather(value)} indexPhoto={1}
                                relationsParties={1/1} croppedImage={croppedImage} />
            }
            { isSelectNumberDownloadPortfolioPage === 3 &&
                <ModalCropPhoto portfolio={portfolio} setPortfolio={(value: any) => setPortfolio(value)}
                                selectPagePortfolio={selectPagePortfolio}
                                selectModalCropPhoto={selectModalCropPhoto} setSelectModalCropPhoto={(value: boolean) => setSelectModalCropPhoto(value)}
                                photoPathList={photoPathList} setPhotoListBase64={(value: any) => setPhotoListBase64(value)}
                                pathPhoto={pathPhotoMather} setDownloadPhoto={(value: any) => setDownloadPhotoMather(value)}
                                downloadPhoto64={downloadPhoto64Mather} setDownloadPhoto64={(value: any) => setDownloadPhoto64Mather(value)}
                                cropPath={cropPathMather} setCropPath={(value: any) => setCropPathMather(value)} indexPhoto={2}
                                relationsParties={1/1} croppedImage={croppedImage} />
            }
            { isSelectNumberDownloadPortfolioPage === 4 &&
                <ModalCropPhoto portfolio={portfolio} setPortfolio={(value: any) => setPortfolio(value)}
                                selectPagePortfolio={selectPagePortfolio}
                                selectModalCropPhoto={selectModalCropPhoto} setSelectModalCropPhoto={(value: boolean) => setSelectModalCropPhoto(value)}
                                photoPathList={photoPathList} setPhotoListBase64={(value: any) => setPhotoListBase64(value)}
                                pathPhoto={pathPhotoGrandpaFather} setDownloadPhoto={(value: any) => setDownloadPhotoGrandpaFather(value)}
                                downloadPhoto64={downloadPhoto64GrandpaFather} setDownloadPhoto64={(value: any) => setDownloadPhoto64GrandpaFather(value)}
                                cropPath={cropPathGrandpaFather} setCropPath={(value: any) => setCropPathGrandpaFather(value)} indexPhoto={3}
                                relationsParties={1/1} croppedImage={croppedImage} />
            }
            { isSelectNumberDownloadPortfolioPage === 5 &&
                <ModalCropPhoto portfolio={portfolio} setPortfolio={(value: any) => setPortfolio(value)}
                                selectPagePortfolio={selectPagePortfolio}
                                selectModalCropPhoto={selectModalCropPhoto} setSelectModalCropPhoto={(value: boolean) => setSelectModalCropPhoto(value)}
                                photoPathList={photoPathList} setPhotoListBase64={(value: any) => setPhotoListBase64(value)}
                                pathPhoto={pathPhotoGrandmaFather} setDownloadPhoto={(value: any) => setDownloadPhotoGrandmaFather(value)}
                                downloadPhoto64={downloadPhoto64GrandmaFather} setDownloadPhoto64={(value: any) => setDownloadPhoto64GrandmaFather(value)}
                                cropPath={cropPathGrandmaFather} setCropPath={(value: any) => setCropPathGrandmaFather(value)} indexPhoto={4}
                                relationsParties={1/1} croppedImage={croppedImage} />
            }
            { isSelectNumberDownloadPortfolioPage === 6 &&
                <ModalCropPhoto portfolio={portfolio} setPortfolio={(value: any) => setPortfolio(value)}
                                selectPagePortfolio={selectPagePortfolio}
                                selectModalCropPhoto={selectModalCropPhoto} setSelectModalCropPhoto={(value: boolean) => setSelectModalCropPhoto(value)}
                                photoPathList={photoPathList} setPhotoListBase64={(value: any) => setPhotoListBase64(value)}
                                pathPhoto={pathPhotoGrandpaMather} setDownloadPhoto={(value: any) => setDownloadPhotoGrandpaMather(value)}
                                downloadPhoto64={downloadPhoto64GrandpaMather} setDownloadPhoto64={(value: any) => setDownloadPhoto64GrandpaMather(value)}
                                cropPath={cropPathGrandpaMather} setCropPath={(value: any) => setCropPathGrandpaMather(value)} indexPhoto={5}
                                relationsParties={1/1} croppedImage={croppedImage} />
            }
            { isSelectNumberDownloadPortfolioPage === 7 &&
                <ModalCropPhoto portfolio={portfolio} setPortfolio={(value: any) => setPortfolio(value)}
                                selectPagePortfolio={selectPagePortfolio}
                                selectModalCropPhoto={selectModalCropPhoto} setSelectModalCropPhoto={(value: boolean) => setSelectModalCropPhoto(value)}
                                photoPathList={photoPathList} setPhotoListBase64={(value: any) => setPhotoListBase64(value)}
                                pathPhoto={pathPhotoGrandmaMather} setDownloadPhoto={(value: any) => setDownloadPhotoGrandmaMather(value)}
                                downloadPhoto64={downloadPhoto64GrandmaMather} setDownloadPhoto64={(value: any) => setDownloadPhoto64GrandmaMather(value)}
                                cropPath={cropPathGrandmaMather} setCropPath={(value: any) => setCropPathGrandmaMather(value)} indexPhoto={6}
                                relationsParties={1/1} croppedImage={croppedImage} />
            }
        </>
    );
};

export default EditTreeFamily;
