import { useEffect, useState } from "react";
import AxiosClient from "../../../api/AxiosClient";
import { Card, CardBody, Col, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import { customStyles } from "../../settings";
import Select from "react-select";
import ListParent from "./ListParent";
import ModalAddParent from "./ModalAddParent";
import useDebounce from "../../../hooks/useDebounce";
const ListChildren = () => {

    const [students, setStudents] = useState<any[]>([]);
    const [schools, setSchools] = useState<any[]>([]);
    const [selectSchool, setSelectSchool] = useState<any>({ value: '', label: 'Не выбрано' });
    const [selectBuild, setSelectBuild] = useState<any>({ value: '', label: 'Не выбрано' });
    const [selectClass, setSelectClass] = useState<any>({ value: '', label: 'Не выбрано' });
    const [builds, setBuilds] = useState<any[]>([]);
    const [classes, setClasses] = useState<any[]>([]);
    const [personalAccount, setPersonalAccount] = useState('');
    const [addParent, setAddParent] = useState(false);
    const [studentId, setStudentId] = useState('');
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [parents, setParents] = useState<any[]>([]);
    const fio = useDebounce(personalAccount, 1000)
    useEffect(() => {
        fetchStudentsAndParent(1)
        AxiosClient.get('/schools')
            .then((r) => {
                setSchools(r.data.schools)
            })
    }, []);


    useEffect(() => {
        fetchStudentsAndParent(currentPage, perPage, selectSchool.value, selectBuild.value, selectClass.value, fio)
    }, [fio]);

    const fetchStudentsAndParent = async (page: number, perPage = 10, schoolId = '', buildId = '', classId = '', fio = '') => {
        await AxiosClient.get('/students/all_arm_ap_op', {params: {page, perPage, schoolId, buildId, classId, fio}})
            .then(r => {
                setStudents(r.data.students)
                setTotalRows(r.data.count)
            })
            .catch(() => console.log('error'))
    }



    const fetchBuildings = async (selectSchool: string) => {
        if(selectSchool) {
            const response = await AxiosClient.get(`/schools/${selectSchool}/buildings/classes`)
            return response?.data
        }
        return []
    };

    const onChangeSchool = (data: {value: string, label: string}) => {
        fetchStudentsAndParent(currentPage, perPage, data.value, '', '', personalAccount)
        setSelectSchool(data)
        fetchBuildings(data.value)
            .then(r => setBuilds(r))
        setSelectBuild({ value: '', label: 'Не выбрано' })
        setSelectClass({ value: '', label: 'Не выбрано' })
    }

    const onChangeBuild = (data: {value: string, label: string}) => {
        fetchStudentsAndParent(currentPage, perPage, selectSchool.value, data.value, selectClass.value, personalAccount)
        const sortClass = builds?.find(s => s.id.toString() === data.value.toString())?.class
        setClasses(sortClass)
        setSelectClass({ value: '', label: 'Не выбрано' })
        setSelectBuild(data)
    }

    const onChangeClass = (data: {value: string, label: string}) => {
        fetchStudentsAndParent(currentPage, perPage, selectSchool.value, selectBuild.value, data.value, personalAccount)
        setSelectClass(data)
    }

    const handlePageChange = async (page: number) => {
        fetchStudentsAndParent(page, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount)
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        fetchStudentsAndParent(page, newPerPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount)
        setPerPage(newPerPage);
    };


    const columns = [
        {
            id: "date",
            name: <span className='font-weight-bold accessibility_size_table'>ФИО ребенка/родителя</span>,
            cell: (row: any) => <span className="accessibility_size_table">{`${row.last_name} ${row.name} ${row.middle_name}`}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Школа</span>,
            cell: (row: any) => {
                if (row.school)
                    return <span className="accessibility_size_table">{row.school.name}</span>
                else
                    return <i className="bx bx-minus"></i>
            },
            hide: 599,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Корпус</span>,
            cell: (row: any) => {
                if (row.building)
                    return <span className="accessibility_size_table">{row.building.name}</span>
                else
                    return <i className="bx bx-minus"></i>
            },
            hide: 959,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Класс</span>,
            cell: (row: any) => {
                if (row.class)
                    return <span className="accessibility_size_table">{row.class.name}</span>
                else
                    return <i className="bx bx-minus"></i>
            },
            hide: 959,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Родителей</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.parents.length}</span>,
            hide: 959
        },
        {
            cell: (row: any) => <i className="bx bx-plus fs-3" title="Новый родитель" onClick={() => {
                setAddParent(true)
                setStudentId(row.id)
                setParents(row.parents)
            }}></i>,
            width: '50px'
        },
    ]
    return (
        <>
            <Row>
                <Col sm={12} lg={6} xl={3} xxl={3}>
                    <label htmlFor="">Школа</label>
                    <Select
                        options={schools?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...schools.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                        value={selectSchool}
                        onChange={(change: any) => {
                            onChangeSchool({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                    />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={3}>
                    <label htmlFor="">Корпус</label>
                    <Select
                        options={builds?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...builds?.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                        value={selectBuild}
                        onChange={(change: any) => {
                            onChangeBuild({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                    />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={3}>
                    <label htmlFor="">Класс</label>
                    <Select
                        options={classes?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...classes?.map(v => ({ value: v.id, label: v.name }))] : [{
                            value: '',
                            label: 'Не выбрано'
                        }]}
                        value={selectClass}
                        onChange={(change: any) => {
                            onChangeClass({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                    />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={3}>
                    <label htmlFor="">ФИО ребенка</label>
                    <input type="text" className="form-control" value={personalAccount} onChange={event => setPersonalAccount(event.target.value)} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mt-3">
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={students}
                                noDataComponent={'Нет данных'}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                paginationDefaultPage={currentPage}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно учеников:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        },
                                    },
                                    expanderRow: {
                                        style: {
                                            backgroundColor: "#ffebb5",
                                            paddingTop: '5px',
                                            paddingBottom: '5px'
                                        },
                                    },
                                }}
                                expandableRows
                                expandOnRowClicked
                                expandableIcon={{
                                    collapsed: <i className="bx bx-user fs-3"></i>,
                                    expanded: <i className="bx bx-user fs-3"></i>
                                }}
                                expandableRowsComponent={(row) => {
                                    return <ListParent students={students} setStudents={value => setStudents(value)} studentId={row.data.id} parents={row.data.parents} />
                                }}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                addParent &&
                <ModalAddParent studentId={studentId} setStudents={value => setStudents(value)} students={students} setAddParent={value => setAddParent(value)} addParent={addParent} parents={parents}/>
            }
        </>
    )
}

export default ListChildren;