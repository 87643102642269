import React, {FC, useEffect, useState} from 'react';
import {Col, Input, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import classNames from "classnames";
import ListDishMenu from "./ListDishMenu";
import {customStyles, customStylesImport} from "../../settings";
import Select from "react-select";
import AxiosClient from "../../../api/AxiosClient";
import moment from "moment";
import {useAppDispatch} from "../../../hooks/redux";
import {setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import ModalConfirmExit from "../Schools/ModalConfirmExit";
import _ from "lodash";
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface IAddComplexMenu {
    addComplexMenu: boolean,
    setAddComplexMenu: (value: boolean) => void,
    onChangeSaveUpdate: () => void
    infoFilter: {
        selectCateringOrganizer: {value: string, label: string},
        selectSchool: {value: string, label: string},
        selectBuild: {value: string, label: string},        
        dateBegin: Date,
    }
}
const AddComplexMenu: FC<IAddComplexMenu> = ({setAddComplexMenu, addComplexMenu, onChangeSaveUpdate, infoFilter}) => {
    const idButtonSave: string = 'buttonSaveAddComplexMenu';
    const [dataBegin, setDataBegin] = useState(new Date());
    const [confirmExit, setConfirmExit] = useState(false);
    const [withoutChangesListDishMenu, setWithoutChangesListDishMenu] = useState<any[]>([]);
    const [currentObject, setCurrentObject] = useState<any>({ name: '', date: moment(dataBegin).format("YYYY-MM-DD").toString(),
        school_id: '', school_building_id: '', catering_organizer_type_planned_menu_id: '', type_menu_id: '', catering_organizer_id: '', day_cyclic_menu_id: '',
        age_category_id: '', mealtime_id: '', price_fix: '0', price_estimated: '', additional_menu: false, default: false, other_info: ""});
    const [newObject, setNewObject] = useState<any>({ name: '', date: moment(dataBegin).format("YYYY-MM-DD").toString(),
        school_id: '', school_building_id: '', catering_organizer_type_planned_menu_id: '', type_menu_id: '', catering_organizer_id: '', day_cyclic_menu_id: '',
        age_category_id: '', mealtime_id: '', price_fix: '0', price_estimated: '', additional_menu: false, default: false, other_info: ""});
    const [activeTab, setActiveTab] = useState("1");
    const [listMenu, setListMenu] = useState<any[]>([]);
    const [schools, setSchools] = useState<any[]>([]);
    const [selectSchool, setSelectSchool] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectBuild, setSelectBuild] = useState<any>({value: '', label: 'Не выбрано'});
    const [builds, setBuilds] = useState<any[]>([]);
    const [typeMenu, setTypeMenu] = useState<any[]>([]);
    const [selectListMenu, setSelectListMenu] = useState({value: '', label: ''});
    const [ageCategories, setAgeCategories] = useState<any[]>([]);
    const [dayCyclicMenu, setDayCyclicMenu] = useState<any[]>([]);
    const [listDishMenu, setListDishMenu] = useState<any[]>([]);
    const [mealtime, setMealtime] = useState<any[]>([]);
    const [listCateringOrganizer, setListCateringOrganizer] = useState<any[]>([]);
    const dispatch = useAppDispatch()
    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const confirmOrExit = () => {
        if(!_.isEqual(currentObject, newObject) || !_.isEqual(listDishMenu, withoutChangesListDishMenu)){
            setConfirmExit(true);
        }else{
            setAddComplexMenu(false);
        }
    }

    useEffect(() => {
        AxiosClient.get(`/users/catering_organizers`)
            .then((r) => setListCateringOrganizer(r.data))
        AxiosClient.get('/mealtimes')
            .then((r) => setMealtime(r.data))
            .catch(() => console.log('error'))
        AxiosClient.get('/age_categories')
            .then((r) => setAgeCategories(r.data))
            .catch(() => console.log('error'))
        AxiosClient.get('/schools')
            .then((r) => {
                setSchools(r.data.schools)
            })
            .catch(() => console.log('error'))
        AxiosClient.get('/type_menu')
            .then((r) => {
                setTypeMenu(r.data)
            })
            .catch(() => console.log('error'))
        AxiosClient.get('/day_cyclic_menus')
            .then((r) => {
                setDayCyclicMenu(r.data)
            })
            .catch(() => console.log('error'))
    }, []);

    useEffect(() => {
        if (addComplexMenu && (infoFilter.selectSchool.value!=="")){
            setSelectSchool(infoFilter.selectSchool);
        }
    },[addComplexMenu])

    useEffect(() => {
        if (dataBegin && infoFilter.dateBegin) {
            setDataBegin(moment(infoFilter.dateBegin).toDate());          
        }
    }, [infoFilter.dateBegin]);

    const fetchBuildings = async (selectSchool: string) => {
        if(selectSchool) {
            const response = await AxiosClient.get(`/schools/${selectSchool}/buildings/classes`)
            return response?.data
        }
        return []
    };
    
    useEffect(() => {
        fetchBuildings(selectSchool.value)
            .then(r => {
                setBuilds(r)
                if (infoFilter.selectBuild.value!==""  && r && r.find((value: any) => value.id === infoFilter.selectBuild.value)){
                    setSelectBuild(infoFilter.selectBuild);
                }
                else{
                    setSelectBuild({value: '', label: 'Не выбрано'})                    
                }
            })
        selectSchool.value &&
        AxiosClient.get(`/schools/${selectSchool.value}/catering_organizers/type_planned_menu`)
            .then((r) => setListMenu(r.data.catering_organizer?.catering_organizer_type_planned_menu?.map((v: any) => ({
                ...v.type_planned_menu,
                idPlan: v.id,
                pricePlan: v.price
            }))))
    }, [selectSchool, schools])

    useEffect(() => {
        listMenu?.length > 0 &&
        setSelectListMenu({value: listMenu[0]?.id, label: listMenu[0]?.name})
    }, [listMenu])
    useEscapeKey(() => dispatch(setModalAdd(false)), addComplexMenu, "addComplexMenu");


    return (
        <Modal id="addComplexMenu" isOpen={addComplexMenu}
               toggle={() => confirmOrExit()}
               modalClassName="zoomIn" tabIndex={-1} centered size={'lg'} >
            <ModalHeader toggle={() => confirmOrExit()}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление комплексного меню
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            name: newObject.name,
                            date: moment(dataBegin).format("YYYY-MM-DD").toString(),
                            school_id: selectSchool?.value,
                            school_building_id: selectBuild?.value,
                            catering_organizer_type_planned_menu_id: listMenu?.find(v => v.id.toString() === selectListMenu.value.toString())?.idPlan,
                            type_menu_id: typeMenu[0] && typeMenu[0].id,
                            catering_organizer_id: newObject.catering_organizer_id,
                            day_cyclic_menu_id: dayCyclicMenu[0] && dayCyclicMenu[0].id,
                            age_category_id: ageCategories[0] && ageCategories[0].id,
                            mealtime_id: newObject.mealtime_id,
                            price_fix: newObject.price_fix,
                            price_estimated: listDishMenu.map<number>(e => +e.price).reduce((a, b) => a + b, 0),
                            additional_menu: false,
                            default: false,
                            other_info: "",
                            assortments: listDishMenu
                        }
                    }
                    validationSchema={
                        Yup.object({
                            name: Yup.string().required('Обязательное поле'),
                            price_fix: Yup.number().required('Обязательное поле'),
                            catering_organizer_id: Yup.string().required('Обязательное поле'),
                            mealtime_id: Yup.string().required('Обязательное поле'),
                            school_id: Yup.string().required('Обязательное поле'),
                            school_building_id: Yup.string().required('Обязательное поле'),
                            catering_organizer_type_planned_menu_id: Yup.string().required('Обязательное поле'),
                            type_menu_id: Yup.string().required('Обязательное поле'),
                            day_cyclic_menu_id: Yup.string().required('Обязательное поле'),
                            age_category_id: Yup.string().required('Обязательное поле'),
                        })
                    }
                    onSubmit={(values) => {                                       
                        dispatch(setIsSend(true))
                        AxiosClient.post('/complex_menus/create', values)
                            .then(() => {
                                dispatch(setIsSend(false))
                                setAddComplexMenu(false)
                                onChangeSaveUpdate()
                            })
                            .catch(() => {
                                dispatch(setIsSend(false))
                                console.log('error')
                            })
                    }}>
                    {
                        ({setFieldValue, errors, touched}) => (
                            <Form>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                     role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "1", "p-2": true})}
                                            onClick={() => {
                                                tabChange("1");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Основное
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "2", "p-2": true})}
                                            onClick={() => {
                                                tabChange("2");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Блюда
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "3", "p-2": true})}
                                            onClick={() => {
                                                tabChange("3");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Доп. информация
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="mt-2">
                                    <TabPane tabId="1" className="align-items-center">
                                        <Row className="mb-2">
                                            <Col>
                                                <label>Дата:</label>
                                                <Flatpickr
                                                    value={dataBegin}
                                                    className="form-control border-0 dash-filter-picker shadow"
                                                    options={{
                                                        locale: Russian, dateFormat: "d M Y"
                                                    }}
                                                    onClose={(dates) => {
                                                        setDataBegin(dates[0])
                                                        setNewObject({...newObject, 'date': dates[0]});
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label>Организатор питания:</label>
                                                <Field name="catering_organizer_id" as="select" className={`form-select ${errors.catering_organizer_id && touched.catering_organizer_id && "border border-2 border-danger"}`}
                                                       onChange={(e: any) => {
                                                           setFieldValue('catering_organizer_id', e.target.value);
                                                           setNewObject({...newObject, 'catering_organizer_id': e.target.value});
                                                       }}
                                                >
                                                    <option value="">Не выбрано</option>
                                                    {
                                                        listCateringOrganizer.map((ca) => (<option key={ca.id} value={ca.id}>{ca.name}</option>))
                                                    }
                                                </Field>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <Col>
                                                    <label>Наименование*:</label>
                                                    <Field name="name" className={`form-control ${errors.name && touched.name && "border border-2 border-danger"}`}
                                                           onChange={(e: any) => {
                                                               setNewObject({...newObject, 'name': e.target.value});
                                                               setFieldValue('name', e.target.value);
                                                           }}
                                                    />
                                                </Col>
                                            </Col>
                                            <Col>
                                                <label>Прием пищи*:</label>
                                                <Field name="mealtime_id" as="select" className={`form-select ${errors.mealtime_id && touched.mealtime_id && "border border-2 border-danger"}`}
                                                       onChange={(e: any) => {
                                                           setNewObject({...newObject, 'mealtime_id': e.target.value});
                                                           setFieldValue('mealtime_id', e.target.value);
                                                       }}
                                                >
                                                    <option value="">Не выбрано</option>
                                                    {
                                                        mealtime.map(m => (<option key={m.id} value={m.id}>{m.name}</option>))
                                                    }
                                                </Field>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label>Школа:</label>
                                                <Field name="school_id">
                                                    {() => (
                                                        <Select
                                                            options={schools?.length > 0 ? [{
                                                                value: '',
                                                                label: 'Не выбрано'
                                                            }, ...schools.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                                                            value={selectSchool}
                                                            onChange={(change: any) => {
                                                                setNewObject({...newObject, 'school_id': change.value});
                                                                setSelectSchool({value: change.value, label: change.label})
                                                                setFieldValue('school_id', change.value)
                                                            }}                                                            
                                                            styles={customStyles}
                                                            placeholder="Поиск"
                                                            className={`${errors.school_id && touched.school_id && "border border-2 border-danger rounded-3"}`}
                                                        />
                                                    )}
                                                </Field>
                                            </Col>
                                            <Col>
                                                <label>Корпус:</label>
                                                <Field name="school_building_id">
                                                    {() => (
                                                        <Select
                                                            options={builds?.length > 0 ? [{
                                                                value: '',
                                                                label: 'Не выбрано'
                                                            }, ...builds?.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                                                            value={selectBuild}
                                                            onChange={(change: any) => {
                                                                setNewObject({...newObject, 'school_building_id': change.value});
                                                                setSelectBuild({value: change.value, label: change.label})
                                                                setFieldValue('school_building_id', change.value)
                                                            }}
                                                            styles={customStyles}
                                                            className={`${errors.school_building_id && touched.school_building_id && "border border-2 border-danger rounded-3"}`}
                                                            placeholder="Поиск"
                                                        />
                                                    )}
                                                </Field>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label>Комплекс:</label>
                                                <Field name="type_menu_id" as="select" className={`form-select ${errors.type_menu_id && touched.type_menu_id && "border border-2 border-danger"}`}
                                                       onChange={(e: any) => {
                                                           setNewObject({...newObject, 'type_menu_id': e.target.value});
                                                           setFieldValue('type_menu_id', e.target.value);
                                                       }}
                                                >
                                                    {
                                                        typeMenu.map(tm => <option value={tm.id} key={tm.id}>{tm.name}</option>)
                                                    }
                                                </Field>
                                            </Col>
                                            <Col>
                                                <label>Тип планового меню:</label>
                                                <Field name="catering_organizer_type_planned_menu_id">
                                                    {() => (
                                                        <Select
                                                            options={listMenu?.map(v => ({value: v.id, label: v.name, idPlan: v.idPlan}))}
                                                            value={selectListMenu}
                                                            onChange={(change: any) => {
                                                                setNewObject({...newObject, 'catering_organizer_type_planned_menu_id': change.idPlan});
                                                                setSelectListMenu({value: change.value, label: change.label})
                                                                setFieldValue('catering_organizer_type_planned_menu_id', change.idPlan)
                                                            }}
                                                            styles={customStyles}
                                                            placeholder="Поиск"
                                                            className={`${errors.catering_organizer_type_planned_menu_id && touched.catering_organizer_type_planned_menu_id && "border border-2 border-danger rounded-3"}`}
                                                        />
                                                    )}
                                                </Field>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label>День в циклическом меню:</label>
                                                <Field name="day_cyclic_menu_id" as="select"className={`form-select ${errors.day_cyclic_menu_id && touched.day_cyclic_menu_id && "border border-2 border-danger"}`}
                                                       onChange={(e: any) => {
                                                           setNewObject({...newObject, 'day_cyclic_menu_id': e.target.value});
                                                           setFieldValue('day_cyclic_menu_id', e.target.value);
                                                       }}
                                                >
                                                    {
                                                        dayCyclicMenu.map(tm => <option value={tm.id} key={tm.id}>{tm.name}</option>)
                                                    }
                                                </Field>
                                            </Col>
                                            <Col>
                                                <label>Возрастная категория:</label>
                                                <Field name="age_category_id" as="select" className={`form-select ${errors.age_category_id && touched.age_category_id && "border border-2 border-danger"}`}
                                                       onChange={(e: any) => {
                                                           setNewObject({...newObject, 'age_category_id': e.target.value});
                                                           setFieldValue('age_category_id', e.target.value);
                                                       }}
                                                >
                                                    {
                                                        ageCategories.map(ageCategory => (<option key={ageCategory.id}
                                                                                                  value={ageCategory.id}>{ageCategory.name}</option>))
                                                    }
                                                </Field>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col sm={12} lg={6} xl={6}>
                                                <label>Стоимость фиксированная*:</label>
                                                <Field name="price_fix" className={`form-control ${errors.price_fix && touched.price_fix && "border border-2 border-danger"}`} type="number"
                                                       onChange={(e: any) => {
                                                           setNewObject({...newObject, 'price_fix': e.target.value});
                                                           setFieldValue('price_fix', e.target.value);
                                                       }}
                                                />
                                            </Col>
                                            <Col sm={12} lg={6} xl={6}>
                                                <label>Стоимость расчетная:</label>
                                                <Field name="price_estimated" className="form-control" disabled
                                                       onChange={(e: any) => {
                                                           setNewObject({...newObject, 'price_estimated': e.target.value});
                                                           setFieldValue('price_estimated', e.target.value);
                                                       }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col className="d-flex align-items-center" lg={3}>
                                                <label className="m-0">Доп. меню:</label>
                                                <Field name="additional_menu" type="checkbox">
                                                    {({
                                                          field,
                                                      }: any) => (
                                                        <div>
                                                            <Input name="additional_menu" type="checkbox" {...field} className="mx-2 my-0 fs-18"
                                                                   onChange={(e: any) => {
                                                                       setNewObject({...newObject, 'additional_menu': e.target.checked});
                                                                       setFieldValue('additional_menu', e.target.checked);
                                                                   }}
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                            </Col>
                                            <Col className="d-flex align-items-center" lg={3}>
                                                <label className="m-0">По умолчанию:</label>
                                                <Field name="default" type="checkbox">
                                                    {({
                                                          field,
                                                      }: any) => (
                                                        <div>
                                                            <Input name="default" type="checkbox" {...field} className="mx-2 my-0 fs-18"
                                                                   onChange={(e: any) => {
                                                                       setNewObject({...newObject, 'default': e.target.checked}); 
                                                                       setFieldValue('default', e.target.checked);
                                                                   }}

                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                            </Col>
                                        </Row>
                                        <SubmitWithLoading id={idButtonSave} text={"Добавить"}/>
                                    </TabPane>
                                    <TabPane tabId="2" className="align-items-center">
                                        <ListDishMenu setListDishMenu={value => setListDishMenu(value)} listDishMenu={listDishMenu}/>
                                    </TabPane>
                                    <TabPane tabId="3" className="align-items-center">
                                        <Row className="mb-2">
                                            <Col>
                                                <Field name="other_info" as="textarea" rows={10} className="form-control"
                                                       onChange={(e: any) => {
                                                           setNewObject({...newObject, 'other_info': e.target.value});
                                                           setFieldValue('other_info', e.target.value);
                                                       }}
                                                />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
            <ModalConfirmExit idButtonSave={idButtonSave} setUpdate={value => setAddComplexMenu(value)} confirmExit={confirmExit} setConfirmExit={value => setConfirmExit(value)} />
        </Modal> 
    );
};

export default AddComplexMenu;
