import React, { FC, useEffect, useState } from 'react';
import AxiosClient from "../../../api/AxiosClient";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import moment from "moment";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../hooks/redux";

interface IAddVentilation {
    updateVentilation: boolean;
    setUpdateVentilation: (value: boolean) => void;
    addMyVentilation: any[];
    setAddMyVentilation: (value: any[]) => void;
    selectVentilation: any;
}

const AddVentilation: FC<IAddVentilation> = ({updateVentilation,
                                                 setUpdateVentilation,
                                                 addMyVentilation,
                                                 setAddMyVentilation,
                                                 selectVentilation
                                                 }) => {
    const [ventilations, setVentilations] = useState<any[]>([]);
    const [time, setTime] = useState(moment().format('HH:mm'));
    const [endtime, setEndtime] = useState(moment().format('HH:mm'));
    const dispatch  =useAppDispatch()

    const generateUid = () => {
        return `uid_${Date.now()}_${Math.floor(Math.random() * 1000)}`;
    };
    useEscapeKey(() => dispatch(setModalAdd(false)), updateVentilation, "addVentilationModal");

    return (
        <Modal
            id="addVentilationModal"
            isOpen={updateVentilation}
            toggle={() => setUpdateVentilation(!updateVentilation)}
            modalClassName="zoomIn"
            tabIndex={-1}
            centered
        >
            <ModalHeader
                toggle={() => setUpdateVentilation(!updateVentilation)}
                className="p-3 bg-soft-primary"
                id="createProjectModalLabel"
            >
                Добавление проветривания
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={{
                        uid: selectVentilation?.uid || generateUid(),
                        time: selectVentilation?.time || time,
                        endtime: selectVentilation?.endtime || endtime,
                        temperature: selectVentilation?.temperature || '',
                        vid_ventilation: selectVentilation?.vid_ventilation || 'Одностороннее',
                    }}
                    onSubmit={(values) => {
                        const newObject = {
                            uid: values.uid,
                            time: values.time,
                            endtime: values.endtime,
                            temperature: values.temperature,
                            vid_ventilation: values.vid_ventilation,
                            remove: selectVentilation ? selectVentilation.remove : "0"
                        };

                        setAddMyVentilation(
                            Array.isArray(addMyVentilation)
                                ? addMyVentilation.some(item => item.uid === values.uid)
                                    ? addMyVentilation.map(item =>
                                        item.uid === values.uid ? { ...item, ...newObject } : item
                                    )
                                    : [...addMyVentilation, newObject]
                                : [newObject]
                        );
                        setUpdateVentilation(false);
                    }}
                >
                    {({ errors, touched , setFieldValue}) => (
                        <Form>
                            <div className="mb-3">
                                <label className="w-100">
                                    Вид проветривания:
                                    <Field as="select" className="form-select shadow"
                                           name="vid_ventilation">
                                        <option key={0} value="Одностороннее">Одностороннее</option>
                                        <option key={1} value="Сквозное">Сквозное</option>
                                        <option key={2} value="Угловое">Угловое</option>
                                    </Field>
                                </label>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="me-3">
                                    <label>
                                        Время начала проветривания:
                                        <div className="input-group ">
                                            <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                value={time}
                                                name="time"
                                                options={{
                                                    locale: Russian,
                                                    enableTime: true,
                                                    noCalendar: true,
                                                    dateFormat: "H:i",
                                                    time_24hr: true,
                                                    disableMobile: true,
                                                    mode: 'single'
                                                }}
                                                onChange={(time) => {
                                                    setTime(moment(time[0]).format('HH:mm'));
                                                    setFieldValue('time', moment(time[0]).format('HH:mm'));
                                                }}
                                            />
                                        </div></label>
                                </div>
                                <div>
                                    <label>
                                        Время окончания проветривания:
                                        <div className="input-group ">
                                            <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                value={endtime}
                                                name="endtime"
                                                options={{
                                                    locale: Russian,
                                                    enableTime: true,
                                                    noCalendar: true,
                                                    dateFormat: "H:i",
                                                    time_24hr: true,
                                                    disableMobile: true,
                                                    mode: 'single'
                                                }}
                                                onChange={(time) => {
                                                    setEndtime(moment(time[0]).format('HH:mm'));
                                                    setFieldValue('endtime', moment(time[0]).format('HH:mm'));
                                                }}
                                            />
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="w-100">
                                    Наружная температура:
                                    <Field name="temperature" className={`form-control`}/>
                                </label>
                            </div>
                            <div className="hstack gap-2 justify-content-end mt-1">
                                <SubmitWithLoading text={"Сохранить"}/>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default AddVentilation;