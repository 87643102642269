import React, {FC, useEffect, useState} from 'react';
import Cookie from "../../../Cookie";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import CheckPayInfo from "./CheckPayInfo";
import SuccessPayInfo from "./SuccessPayInfo";
import CancelPayInfo from "./CancelPayInfo";
import AxiosClient from "../../../api/AxiosClient";
import NoTimePayInfo from "./NoTimePayInfo";
import {useAppDispatch} from "../../../hooks/redux";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
interface IPropsModalCheckPay {
    show: boolean,
    setShow: (value: boolean) => void,
    orderId: string,
    updateData: () => void
    pa: string
    updateElemPay: () => void
}
const ModalCheckPay: FC<IPropsModalCheckPay> = ({show, setShow, orderId, updateData, pa, updateElemPay}) => {

    const [typePay, setTypePay] = useState<'idle' | 'pending' | 'success' | 'cancel' | 'noTime'>('idle');

    const statusPayment = {
        pending: <CheckPayInfo/>,
        success: <SuccessPayInfo/>,
        cancel: <CancelPayInfo/>,
        noTime: <NoTimePayInfo/>,
        idle: <></>
    }

    useEffect(() => {
        show &&
        setTypePay("pending")
    }, [show]);


    useEffect(() => {
        if (orderId && typePay === "pending") {
            let count = 0

            const checkPaymentStatus = () => {
                AxiosClient.post('/sber/check_pay', { orderId: orderId, personalAccount: pa})
                    .then((r) => {
                        if (r.data.orderStatus === 2) {
                            setTypePay('success');
                            updateData()
                        }
                        if (r.data.orderStatus === 6) {
                            setTypePay('noTime');
                            updateElemPay()
                        }
                        else {
                            if (count < 4){
                                count += 1;
                            } else {
                                setTypePay('cancel');
                                updateElemPay()
                            }
                        }
                    })
                    .catch(() => console.log('error checking payment status'));
            };

            const timer = setInterval(checkPaymentStatus, 10000);

            // Функция очистки для остановки интервала, когда компонент размонтируется или изменятся зависимости
            return () => clearInterval(timer);
        }
    }, [orderId, typePay])

    const dispatch = useAppDispatch();
    useEscapeKey(() => dispatch(setModalAdd(false)), show, "ModalCheckPay");

    return (
        <Modal id="ModalCheckPay" isOpen={show}
               toggle={() => setShow(!show)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setShow(!show)}
                         className="p-3 bg-soft-primary">
                Статус оплаты
            </ModalHeader>
            <ModalBody>
                {
                    statusPayment[typePay]
                }
            </ModalBody>
        </Modal>
    );
};

export default ModalCheckPay;
