import React, {useState} from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import TableShowSubjectCheck from "./TableShowSubjectCheck";

const ControlRatingSubjectCheckOrganizationEmployee = () => {
    const [data, setData] = useState([
        {
            org: "Организация 2",
            name: 'Отсутствует',
            all: '12',
            zav: '12',
            pros: '3',
            vip: '23',
            vipzad: '23',
        },
        {
            org: "Организация 3",
            name: 'Незначительные',
            all: '12',
            zav: '12',
            pros: '3',
            vip: '23',
            vipzad: '23',
        },
        {
            org: "Организация 4",
            name: 'Грубые',
            all: '12',
            zav: '12',
            pros: '3',
            vip: '23',
            vipzad: '23',
        }
    ]);

    const columns: any[] = [
        {
            id: "org",
            name: <span className='font-weight-bold accessibility_size_table'>Нарушение</span>,
            cell: (row: any) =><span className='accessibility_size_table'>{row.name}</span>,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table d-flex'>Всего</span>,
            cell: (row: any) => <span className="mx-4 accessibility_size_table">{row.all}</span>,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table d-flex'>Завершено</span>,
            cell: (row: any) => <span className="mx-2 accessibility_size_table">{row.zav}</span>,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table d-flex'>Просрочено</span>,
            cell: (row: any) => <span className="mx-4 accessibility_size_table">{row.pros}</span>,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table d-flex'>Выполнено</span>,
            cell: (row: any) => <span className="mx-4 accessibility_size_table">{row.vip}</span>,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table d-flex'>Вып. с задержкой</span>,
            cell: (row: any) => <span className="mx-4 accessibility_size_table">{row.vipzad}</span>,
        }
    ]

    return (
        <Card>
            <CardHeader>
                Нарушения в разрезе оценок сотрудников
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    expandableRows
                    expandOnRowClicked
                    expandableRowsComponent={
                        () => {
                            return <TableShowSubjectCheck/>
                        }
                    }
                    data={data}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно нарушений:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        },
                        expanderRow:{
                            style:{
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default ControlRatingSubjectCheckOrganizationEmployee;
