import React, {memo, useEffect} from 'react';
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import GraphDonutCash from "../../components/parent/graph/GraphDonutCash";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import 'flatpickr/dist/plugins/monthSelect/style.css'
import MonthSelectPlugin from "flatpickr/dist/plugins/monthSelect/index"
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getChildren} from "../../store/reducers/parent/ActionCreator";
import {ParentSlice} from "../../store/reducers/parent/ParentSlice";
import GraphAttendanceChildrenPaid from "../../components/parent/graph/GraphAttendanceChildrenPaid";
import TableAllAttendanceChildrenPaid from "../../components/parent/table/TableAllAttendanceChildrenPaid";
import TableAttendanceChildrenPaid from "../../components/parent/table/TableAttendanceChildrenPaid";
import {Helmet} from "react-helmet";
import moment from "moment";

const ChildrenAttendancePaid = () => {

    //const [date, setDate] = useState(new Date());
    const dispatch = useAppDispatch()
    const {children, child, dateMonth, allDeviationChildPaid} = useAppSelector(state => state.ParentReducer)

    useEffect(() => {
        dispatch(getChildren()).then()
    }, [dispatch]);

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Посещение платных услуг</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col xxl={3}>
                        <div className="mb-3">
                            <div className="input-group">
                                <Flatpickr
                                    className="form-control border-0 dash-filter-picker shadow"
                                    value={dateMonth}
                                    options={{
                                        locale: Russian,
                                        disableMobile: true,
                                        dateFormat: "m Y",
                                        maxDate: new Date(),
                                        plugins: [ MonthSelectPlugin({
                                            shorthand: true, //defaults to false
                                            dateFormat: "M y", //defaults to "F Y"
                                            altFormat: "F Y", //defaults to "F Y"
                                        })],
                                    }}
                                    onClose={(date) => {
                                        dispatch(ParentSlice.actions.setDate(date[0]))
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col">
                            <Card>
                                <CardHeader className="card_header_size_text">
                                    Ребенок:
                                    <select className="form-select mt-1 fs-14" value={child} onChange={(event) => dispatch(ParentSlice.actions.setChild(event.target.value))}>
                                        {
                                            children.map(child =>
                                                (
                                                    <option key={child.account_uid} value={child.account_uid} className="fs-14">{child.account_name}</option>
                                                )
                                            )
                                        }
                                    </select>
                                </CardHeader>
                                <CardBody>
                                    <Flatpickr
                                        className="form-control border-0 dash-filter-picker d-none"
                                        value={[moment(dateMonth).startOf("month").toDate(), moment(dateMonth).endOf("month").toDate()]}
                                        options={{
                                            locale: Russian,
                                            disableMobile: true,
                                            inline: true,
                                            maxDate: new Date(),
                                        }}
                                        onMonthChange={(dates, currentDateString, self, data) => {
                                            dispatch(ParentSlice.actions.setDate(moment().month(self.currentMonth).year(self.currentYear).startOf('month').toDate()))
                                        }}
                                        onDayCreate={(dates, currentDateString, self, data) => {
                                            const thisDate = moment(data.dateObj).format('DD.MM.YYYY') === moment(dates[0]).format('DD.MM.YYYY') || moment(data.dateObj).format('DD.MM.YYYY') === moment(dates[1]).format('DD.MM.YYYY')
                                            const date = allDeviationChildPaid.find(el => el.data === moment(data.dateObj).format('DD.MM.YYYY').toString())

                                            data.classList.add('border-1')
                                            data.classList.add('rounded-1')
                                            data.classList.add('shadow-none')
                                            data.style.margin='1px 0'
                                            if (date && !thisDate) {
                                                if (date.no_present === 1) {
                                                    data.classList.add('bg-danger') //+= ' bg-danger'
                                                    data.classList.add('text-white')
                                                }
                                                if (date.present === 1) {
                                                    data.classList.add('bg-success') //+= ' bg-danger'
                                                    data.classList.add('text-white')

                                                }
                                            }
                                            if (date && thisDate) {
                                                if (date.no_present === 1) {
                                                    data.classList.add('bg-danger') //+= ' bg-danger'
                                                    data.classList.add('text-white')
                                                }
                                                if (date.present === 1) {
                                                    data.classList.add('bg-success') //+= ' bg-danger'
                                                    data.classList.add('text-white')

                                                }
                                            }
                                            if (!date && thisDate) {
                                                data.classList.add('bg-white')
                                                data.classList.add('text-black')
                                            }
                                        }}
                                    />
                                    <TableAllAttendanceChildrenPaid date={dateMonth}/>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                    <GraphAttendanceChildrenPaid child={child && child}/>
                </Row>
                <Row>
                    <GraphDonutCash child={child && child}/>
                    <Col xxl="9">
                        <Card className="card-height-100">
                            <CardBody>
                                <TableAttendanceChildrenPaid date={dateMonth} child={child}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default memo(ChildrenAttendancePaid);
