import React, {FC, useEffect, useState} from 'react';
import {Col, Input, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {Field, Form, Formik} from "formik";
import AxiosClient from "../../../../api/AxiosClient";
import * as Yup from "yup";
import {ICateringOrganizer} from "../../../../models/KSHP/ICateringOrganizer";
import classNames from "classnames";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import moment from "moment/moment";
import Select from "react-select";
import {customStyles} from "../../../settings";
import {useAppDispatch} from "../../../../hooks/redux";
import {setModalAdd, setShowNotification} from "../../../../store/reducers/widgets/WidgetsSlice";
import {useEscapeKey} from "../../../../hooks/useEscapeKey";

interface IModalImportFileSchool {
    importFileSchool: boolean,
    setImportFileSchool(value: boolean) : any,
    setTableConfirmationImport(value: any) : any,
    setListImport(value: any[]) : any,
    setModalData(value: any[]) : any,
}

const ModalImportFileSchool: FC<IModalImportFileSchool> = ({importFileSchool, setImportFileSchool, setTableConfirmationImport, setListImport, setModalData}) => {
    const dispatch = useAppDispatch()

    const [file, setFile] = useState<any>();
    const [cateringOrganizers, setCateringOrganizers] = useState<ICateringOrganizer[]>([]);
    const [selectCateringOrganizers, setSelectCateringOrganizers] = useState<{ value: string, label: string }>({ value: '', label: 'Не выбрано' });
    const [activeTab, setActiveTab] = useState("1");
    const [dateContract, setDateContract] = useState(new Date());
    const [dateBegin, setDateBegin] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());

    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    useEffect(() => {
        AxiosClient.get<ICateringOrganizer[]>('/users/catering_organizers')
            .then((r) => setCateringOrganizers(r.data))
            .catch(() => console.log('error'))
    }, []);

    useEscapeKey(() => dispatch(setModalAdd(false)), importFileSchool, "importFileSchool");

    return (
        <Modal id="importFileSchool" isOpen={importFileSchool}
               toggle={() => setImportFileSchool(!importFileSchool)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setImportFileSchool(!importFileSchool)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Импорт файла школ
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            catering_organizer: selectCateringOrganizers.value,
                            address: '',
                            max_credit_nutrition: '',
                            max_deposit: '',
                            contract: '',
                            isSaturday: false,
                            date_contract: '',
                            date_begin: '',
                            date_end: '',
                            discount_price: 0.0
                        }
                    }
                    validationSchema={
                        Yup.object({
                            address: Yup.string().required('Обязательное поле'),
                            max_credit_nutrition: Yup.number().required('Обязательное поле'),
                            max_deposit: Yup.number().required('Обязательное поле'),
                            catering_organizer: Yup.string(),
                            discount_price: Yup.number(),
                            contract: Yup.string()
                                .when('catering_organizer', {
                                    is: (value:any) => value && value.length > 0,
                                    then: (schema:any) => schema.matches(/^[a-zA-Zа-яА-Я0-9]+$/, 'Можно использовать только буквы и цифры').required()
                                }),
                        })
                    }
                    onSubmit={(values) => {
                        const formData = new FormData();
                        formData.append('file', file);
                        formData.append('address', values.address);
                        formData.append('catering_organizer', values.catering_organizer);
                        formData.append('max_credit_nutrition', values.max_credit_nutrition);
                        formData.append('max_deposit', values.max_deposit);
                        formData.append('isSaturday', values.isSaturday.toString());
                        formData.append('contract', values.contract);
                        formData.append('discount_price', values.discount_price.toString());
                        formData.append('date_contract', moment(dateContract).format('YYYY-MM-DD'));
                        formData.append('date_begin', moment(dateBegin).format('YYYY-MM-DD'));
                        formData.append('date_end', moment(dateEnd).format('YYYY-MM-DD'));
                        
                        setModalData([{
                            address: values.address,
                            catering_organizer: values.catering_organizer,
                            max_credit_nutrition: values.max_credit_nutrition,
                            max_deposit: values.max_deposit,
                            isSaturday: values.isSaturday.toString(),
                            contract: values.contract,
                            discount_price: values.discount_price.toString(),
                            date_contract: moment(dateContract).format('YYYY-MM-DD'),
                            date_begin: moment(dateBegin).format('YYYY-MM-DD'),
                            date_end: moment(dateEnd).format('YYYY-MM-DD'),
                        }]);

                        AxiosClient.post('/schools/file_import', formData)
                            .then((r) => {
                                setImportFileSchool(false);
                                setTableConfirmationImport("school");
                                setListImport(r.data);                                
                            })
                            .catch((error) => {
                                if (error.response.status === 423){
                                    dispatch(setShowNotification({ show: true, type: "error", message: error.response.data }));
                                }
                                else {
                                    console.log('error');
                                    dispatch(setShowNotification({ show: true, type: "error", message: "Ошибка, данные не загружены!" }));
                                }
                            })
                    }}>
                    {
                        ({errors, touched}) => (
                            <Form>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                     role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "1", "p-2": true})}
                                            onClick={() => {
                                                tabChange("1");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Общая информация
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "2", "p-2": true})}
                                            onClick={() => {
                                                tabChange("2");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Организатор питания
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="mt-2">
                                    <TabPane tabId="1" className="align-items-center">
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">Адрес:
                                                <Field name="address"
                                                       className={`form-control ${errors.address && touched.address && "border border-2 border-danger"}`}/>
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">Макс. кредит питания:
                                                <Field name="max_credit_nutrition"
                                                       className={`form-control ${errors.max_credit_nutrition && touched.max_credit_nutrition && "border border-2 border-danger"}`}/>
                                                </label>
                                            </Col>
                                            <Col>
                                                <label  className="w-100">Макс. сумма пополнения:
                                                <Field name="max_deposit"
                                                       className={`form-control ${errors.max_deposit && touched.max_deposit && "border border-2 border-danger"}`}/>
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <label className="w-100">Файл (.csv):
                                                <Input
                                                    name="file"
                                                    type="file"
                                                    className="mb-2"
                                                    accept=".csv"
                                                    onChange={(e: any)=>setFile(e.target.files[0])}
                                                ></Input>
                                                </label>
                                            </Col>
                                        </Row>

                                        <Row className="mb-2">
                                            <Col className="d-flex align-items-center mt-1">
                                                <label className="m-0 w-100">Учитывать субботу:
                                                <Field name="isSaturday" type="checkbox">
                                                    {({
                                                          field,
                                                      }: any) => (
                                                            <Input name="isSaturday" type="checkbox" {...field}
                                                                   className="mx-2 my-0" style={{fontSize: 18}}/>
                                                    )}
                                                </Field>
                                                </label>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2" className="align-items-center">
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">Организатор питания:
                                                    <Field name="catering_organizer">
                                                        {() => (
                                                            <Select
                                                                options={cateringOrganizers?.length > 0 ? [{
                                                                    value: '',
                                                                    label: 'Не выбрано'
                                                                }, ...cateringOrganizers.map(v => ({ value: v.id.toString(), label: v.name}))] : [{ value: '', label: 'Не выбрано' }]}
                                                                value={selectCateringOrganizers}
                                                                onChange={(change: any) => {
                                                                    setSelectCateringOrganizers({ value: change.value, label: change.label })
                                                                }}
                                                                styles={customStyles}
                                                                placeholder="Поиск"
                                                                name="catering_organizer"
                                                            />
                                                        )}
                                                    </Field>
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">Номер договора с ОП:
                                                    <Field name="contract" className={`form-control ${errors.contract && touched.contract && "border border-2 border-danger"}`}/>
                                                </label>
                                            </Col>
                                            <Col>
                                                <label className="w-100">Дата договора с ОП:
                                                <Flatpickr
                                                    className="form-control border-0 dash-filter-picker shadow"
                                                    value={dateContract}
                                                    options={{
                                                        locale: Russian, dateFormat: "d M Y"
                                                    }}
                                                    onClose={(date) => setDateContract(date[0])}
                                                />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">Дата с:
                                                <Flatpickr
                                                    className="form-control border-0 dash-filter-picker shadow"
                                                    value={dateBegin}
                                                    options={{
                                                        locale: Russian, dateFormat: "d M Y"
                                                    }}
                                                    onClose={(date) => setDateBegin(date[0])}
                                                />
                                                </label>
                                            </Col>
                                            <Col>
                                                <label className="w-100">Дата по:
                                                <Flatpickr
                                                    className="form-control border-0 dash-filter-picker shadow"
                                                    value={dateEnd}
                                                    options={{
                                                        locale: Russian, dateFormat: "d M Y"
                                                    }}
                                                    onClose={(date) => setDateEnd(date[0])}
                                                />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <label className="w-100">Скидка:
                                                    <Field name="discount_price" className={`form-control ${errors.discount_price && touched.discount_price && "border border-2 border-danger"}`}/>
                                                </label>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                                <div className="hstack gap-2 mt-2">
                                    <button type="submit" className="btn btn-primary" id="addNewProject">Готово</button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalImportFileSchool;
