import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    setDateAdd, setGroupAllDeviations, setGroupModalAdd,
    setIsSend,
    setModalAdd,
    WidgetsSlice
} from "../../../store/reducers/widgets/WidgetsSlice";
import {Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import moment from "moment/moment";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import AxiosClient from "../../../api/AxiosClient";
import {getJournalHaccpMorningFilterEmployee} from "../../../store/reducers/widgets/ActionCreator";
import classNames from "classnames";
import * as Yup from "yup";
import TableEmployee from "./TableEmployee";
import ModalConfirmExitHaccp from "../ModalConfirmExitHaccp";
import {useModalWithConfirm} from "../../../hooks/useModalWithConfirm";

interface IModalAddMorningFilterEmpoyee{
    updateData: any
}

const ModalAddMorningFilterEmpoyee: FC<IModalAddMorningFilterEmpoyee> = ({updateData}) => {
    const dispatch = useAppDispatch();
    const {modalAdd, groupAllDeviations, date, groupModalAdd, groups, dateAdd, successUpdate} = useAppSelector(state => state.WidgetReducer);
    const [activeTab, setActiveTab] = useState("1");
    const [employees, setEmployees] = useState([])

    const {
        isFormChange,
        showConfirmExit,
        handleClose,
        handleFormChange,
        handleConfirmClose,
        handleStay,
    } = useModalWithConfirm("addMorningFilterEmpoyee");

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(WidgetsSlice.actions.successUpdate(""))
        }, 5000)
        return () => clearTimeout(timer)
    }, [successUpdate, dispatch]);
    useEffect(() => {
        if (modalAdd && !updateData) {
            dispatch(setDateAdd(new Date()));
        }
    }, [modalAdd]);
    useEffect(() => {
        if (modalAdd) {
            if(updateData){
                setEmployees(updateData.employees)
            }
            else {
                AxiosClient.get(`haccp/getEmployeesist`, {params: {date: moment(dateAdd), uid: groupAllDeviations}})
                    .then((r: any) => {
                        setEmployees(r.data.map((child : any) => {
                            return {
                                uid: child.code,
                                name: child.fio,
                                temperature: "36.6",
                                remove: "0",
                                rls: "Отсутствуют",
                                orvi: "Отсутствуют",
                                symptoms: "Нет симптомов",
                            }
                        }))
                    })
                    .catch((error) => console.log(error))
            }
        }
    }, [modalAdd,groupAllDeviations])

    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <>
        <Modal id="ModalAddMorningFilterEmpoyee" isOpen={modalAdd} toggle={handleClose}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={handleClose} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление утреннего фильтра сотрудников
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        date: moment(dateAdd).format("DD-MM-YYYY"),
                        employees: employees
                    }}
                    validationSchema={
                        Yup.object({
                            linen: Yup.array().min(1, '')
                        })}
                    onSubmit={(value) => {
                        dispatch(setIsSend(true));

                        let prop = {
                            uid: updateData? updateData.uid : '',
                            data: moment(dateAdd).format("DD-MM-YYYY"),
                            group_uid: groupAllDeviations,
                            employees: employees,
                        }

                        AxiosClient.post('haccp/saveJournalMorningFilterEmployee', prop)
                            .then((r: any) => {
                                dispatch(getJournalHaccpMorningFilterEmployee(date, groupAllDeviations)).then();
                                dispatch(setIsSend(false));
                                dispatch(setModalAdd(false));
                                handleConfirmClose();
                            })
                            .catch((error) => {
                                dispatch(setIsSend(false))
                            })
                    }}
                >
                    {({ errors, touched, setFieldValue }) => (
                        <Form onChange={handleFormChange}>
                        <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                             role="tablist">
                            <NavItem>
                                <NavLink
                                    className={classNames({active: activeTab === "1", "p-2": true})}
                                    onClick={() => {
                                        tabChange("1");
                                    }}
                                    type="button">
                                    <i className="fas fa-home"></i>
                                    Основное
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classNames({active: activeTab === "2", "p-2": true})}
                                    onClick={() => {
                                        tabChange("2");
                                    }}
                                    type="button">
                                    <i className="fas fa-home"></i>
                                    Список сотрудников
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab} className="mt-2">
                            <TabPane tabId="1" className="align-items-center">
                                <div className="col-auto">
                                    <label className="w-100">Дата:
                                    <div className="col mb-3">
                                        <div className="input-group col-auto">
                                            <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                value={dateAdd}
                                                options={{
                                                    locale: Russian, dateFormat: "d M Y", disableMobile: true, mode: 'single'
                                                }}
                                                onChange={(date) => {
                                                    dispatch(setDateAdd(moment(date[0]).toDate()))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label className="w-100">Группа:
                                        <Field as="select" className="form-select shadow" value={groupModalAdd} 
                                               onChange={(e:any) => {
                                                dispatch(setGroupAllDeviations(e.target.value))
                                                dispatch(setGroupModalAdd(e.target.value))
                                                }} name="group"> 
                                            {groups.map((group) => {
                                                return <option key={group.group_id}
                                                               value={group.group_id}>{group.group_name}</option>
                                            })}
                                        </Field>
                                    </label>
                                </div>
                            </TabPane>
                            <TabPane tabId="2" className="align-items-center">
                                <TableEmployee employees={employees} setEmployees={value => setEmployees(value)}/>
                            </TabPane>
                        </TabContent>
                        {errors.employees && (
                            <div style={{ color: 'red', marginTop: '5px' }}>Требуется добавить данные о детях!</div>
                        )}
                        <div className="hstack gap-2 justify-content-end mt-1">
                            <SubmitWithLoading text={"Сохранить"}/>
                        </div>
                    </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
            <ModalConfirmExitHaccp
                isOpen={showConfirmExit}
                onClose={handleConfirmClose}
                onStay={handleStay}
                hasUnsavedChanges={isFormChange}
            />
        </>
    );
};

export default ModalAddMorningFilterEmpoyee;
