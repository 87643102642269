import React, {FC, useEffect, useState} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import AxiosClient from "../../../api/AxiosClient";
import {useAppDispatch} from "../../../hooks/redux";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";

interface IModalSelectDeviationAll {
    isOpen: boolean,
    setIsOpen: (value: boolean) => void
    deviationChangeAll: (deviation: string | number) => void
}
const ModalSelectDeviationAll: FC<IModalSelectDeviationAll> = ({isOpen, setIsOpen, deviationChangeAll}) => {
    const [deviations, setDeviations] = useState<any[]>([])
    const [selectedDeviation, setSelectedDeviation] = useState('');

    useEffect(() => {
        AxiosClient.get('/deviation_school')
            .then(r => {
                setDeviations(r.data)
                setSelectedDeviation(r.data[0].id)
            })
    }, [])

    const dispatch = useAppDispatch();
    useEscapeKey(() => dispatch(setModalAdd(false)), isOpen, "ModalSelectDeviationAll");

    return(
        <Modal id="ModalSelectDeviationAll" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setIsOpen(!isOpen)} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Установить отклонение у всех
            </ModalHeader>
            <ModalBody>
                <div className="mb-3">
                    <label htmlFor="select">Отклонения:</label>
                    <select name="select" id="select" className="form-select" value={selectedDeviation} onChange={(event) => setSelectedDeviation(event.target.value)}>
                        {
                            deviations.map(d => (<option key={d.id} value={d.id}>{`${d.name}`}</option>))
                        }
                    </select>
                </div>
                <div>
                    <button type="button" className="btn btn-primary" id="addNewProject" onClick={() => {
                        setIsOpen(false)
                        deviationChangeAll(selectedDeviation)
                    }}>Сохранить</button>
                </div>
            </ModalBody>
        </Modal>
    )
};

export default ModalSelectDeviationAll;
