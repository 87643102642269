import React, {FC, useEffect, useState} from 'react';
import {
    Modal as ReactstrapModal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button, FormGroup, Label, Input, ListGroup, ListGroupItem
} from "reactstrap";
import { useAppSelector} from "../../../hooks/redux";
import AxiosClient from "../../../api/AxiosClient";
import moment from "moment/moment";

interface IAddChildModal {
    isOpen: boolean;
    onClose: () => void;
    onSave: (newChildren: any[]) => void;
    dateBegin: string;
    dateEnd: string;
}

const ModalAddChildGroupDo: FC<IAddChildModal> = ({ isOpen, onClose, onSave,dateBegin,dateEnd }) => {
    const [selectedGroup, setSelectedGroup] = useState<string>('');
    const [children, setChildren] = useState<any[]>([]);
    const [selectedChildren, setSelectedChildren] = useState<string[]>([])
    const [groups, setGroups] = useState<any[]>([]);
    const {date} = useAppSelector(state => state.WidgetReducer);

    const fetchGroups = () => {
        AxiosClient.get('/user/get-group', {params: {date}})
            .then((response) => {
                setGroups(response.data);
            })
            .catch((error) => {
                console.error('Ошибка при запросе списка групп:', error);
                setGroups([]);
            });
    };
    const fetchChildren = (groupId: string) => {
        AxiosClient.get('/user/list-students', {
            params: {
                date: moment(Date()).format('DD.MM.YYYY'),
                group: groupId
            }
        })
            .then((response) => {
                setChildren(response.data)
                setSelectedChildren([]);
            })
            .catch((error) => {
                console.error('Ошибка при запросе списка групп:', error);
                setChildren([]);
            });
    };

    useEffect(() => {
        if (isOpen) {
            fetchGroups();
            setSelectedChildren([]);
        }
    }, [isOpen]);

    useEffect(() => {
        if (isOpen && groups.length > 0 && !selectedGroup) {
            const firstGroupId = groups[0].group_id;
            setSelectedGroup(firstGroupId);
            fetchChildren(firstGroupId);
        }
    }, [isOpen, groups]);

    const handleGroupChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const groupId = e.target.value;
        setSelectedGroup(groupId);
        fetchChildren(groupId);
    };
    const handleChildToggle = (childCode: string) => {
        setSelectedChildren((prev) =>
            prev.includes(childCode)
                ? prev.filter((code) => code !== childCode)
                : [...prev, childCode]
        );
    };

    const handleSave = () => {
        const groupId = selectedGroup;
        const groupName = groups.find(g => g.group_id === selectedGroup)?.group_name || "";
        const newChildren = children
            .filter(child => selectedChildren.includes(child.code))
            .map(child => ({
                id: child.code,
                fio: child.fio || "",
                groupName: groupName,
                groupId: groupId,
                dataBegin: dateBegin ? moment(dateBegin, "DD.MM.YYYY").format("DD MMMM YYYY") : "",
                dataEnd: dateEnd ? moment(dateEnd, "DD.MM.YYYY").format("DD MMMM YYYY") : ""
            }));
        onSave(newChildren);
        onClose();
    };

    return (
        <ReactstrapModal
            isOpen={isOpen}
            toggle={onClose}
            centered
        >
            <ModalHeader toggle={onClose} className="p-3 bg-soft-primary">
                Добавить ребенка
            </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="groupSelect">Группа:</Label>
                    <select
                        id="groupSelect"
                        className="form-select"
                        value={selectedGroup}
                        onChange={handleGroupChange}
                    >
                        {groups.map((group) => (
                            <option key={group.group_id} value={group.group_id}>
                                {group.group_name}
                            </option>
                        ))}
                    </select>
                </FormGroup>
                <ListGroup style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {children.length > 0 ? (
                        children.map((child) => (
                            <ListGroupItem
                                key={child.code}
                                className="d-flex justify-content-between align-items-center py-2"
                            >
                                <span >{child.fio}</span>
                                <Input
                                    type="checkbox"
                                    checked={selectedChildren.includes(child.code)}
                                    onChange={() => handleChildToggle(child.code)}
                                    className="form-check-input fs-18"
                                />
                            </ListGroupItem>
                        ))
                    ) : (
                        <ListGroupItem>
                            <span>Нет детей в выбранной группе.</span>
                        </ListGroupItem>
                    )}
                </ListGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSave}>
                    Сохранить
                </Button>
            </ModalFooter>
        </ReactstrapModal>
    );
};

export default ModalAddChildGroupDo;