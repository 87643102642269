import React, {FC} from 'react';
import AxiosClient from "../../../api/AxiosClient";
import {setIsSend} from "../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {useAppDispatch} from "../../../hooks/redux";
import {Link} from "react-router-dom";

interface IImageToPDF {
    idPortfolio: number,
    pathPage: any;
}

const ImageToPDF: FC<IImageToPDF> = ({idPortfolio, pathPage }) => {
    const dispatch = useAppDispatch();

    const downloadPDF = () => {
        dispatch(setIsSend(true));
        const params = {
            pathPage
        }
        AxiosClient.get(`/download/document/portfolio/${idPortfolio}`, {responseType: 'blob'})
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'portfolio.pdf');
                document.body.appendChild(link);
                link.click();
                link.remove();
                dispatch(setIsSend(false));
            })
            .catch(() => {
                console.log('error')
                dispatch(setIsSend(false))
            })
    }

    return (
        <Link to={process.env.REACT_APP_API_BASE_URL + `/api/download/document/portfolio/${idPortfolio}`} target="_blank">
            <SubmitWithLoading text={'Скачать PDF'}  className="btn btn-light view-btn"/>
        </Link>
    );
};

export default ImageToPDF;
