import React, { useEffect, useState } from 'react'
import {Card, CardBody, CardFooter, Col, Row, Spinner} from "reactstrap"
import DataTable from "react-data-table-component"
import AddDish from "./AddDish"
import UpdateDish from "./UpdateDish"
import AxiosClient from "../../../api/AxiosClient"
import moment from "moment"
import Select from "react-select"
import { customStyles } from "../../settings"
import useDebounce from "../../../hooks/useDebounce";

const ListAssortment = () => {

    const [name, setName] = useState('')
    const [addDish, setAddDish] = useState(false)
    const [updateDish, setUpdateDish] = useState(false)
    const [assortments, setAssortments] = useState<any[]>([])
    const [selectDish, setSelectDish] = useState<null | any>(null)
    const [ageCategories, setAgeCategories] = useState<any[]>([])
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState(false);
    const debounceName = useDebounce(name, 1000)
    const [selectAgeCategory, setSelectAgeCategory] = useState<any>({ value: '', label: 'Не выбрано' })
    const [listCateringOrganizer, setListCateringOrganizer] = useState<any[]>([]);
    const [selectCateringOrganizer, setSelectCateringOrganizer] = useState({value: '', label: 'Не выбрано'});

    useEffect(() => {
        fetchCateringOrganizer()
        fetchAssortments(1)
        AxiosClient.get('/age_categories')
            .then(r => setAgeCategories(r.data))
            .catch(() => console.log('error'))
    }, [])

    useEffect(() => {
        if (search){
            fetchAssortments(1, perPage, name, selectAgeCategory.value, selectCateringOrganizer.value)
        }
    }, [debounceName])



    const fetchCateringOrganizer = () => {
        AxiosClient.get(`/users/catering_organizers`)
            .then((r) => setListCateringOrganizer(r.data))
    }

    const onChangeCateringOrganizer = (data: { value: string, label: string }) => {
        fetchAssortments(currentPage, perPage, name, selectAgeCategory.value, data.value)
        setSelectCateringOrganizer(data)
    }

    const fetchAssortments = async (page: number, size = perPage, name = '', ageCategoryId = '', cateringOrganizerId = '') => {
        setSearch(true)
        await AxiosClient.get(`/assortments`, {params: {page: page, per_page: size, name, ageCategoryId, cateringOrganizerId}})
            .then((response) => {
                setSearch(false)
                setAssortments(response.data.assortments);
                setTotalRows(response.data.total);
            })
            .catch(() => {
                console.log('error')
                setSearch(false)
            });
    };

    const handlePageChange = (page: number) => {
        fetchAssortments(page, perPage, name, selectAgeCategory.value, selectCateringOrganizer.value);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        await fetchAssortments(page, newPerPage, name, selectAgeCategory.value, selectCateringOrganizer.value);
        setPerPage(newPerPage);
    };

    const onChangeName = (name: string) => {
        setName(name)
        setSearch(true)
    }

    const onChangeAgeCategory = (data: {value: string, label: string}) => {
        fetchAssortments(currentPage, perPage, name, data.value, selectCateringOrganizer.value)
        setSelectAgeCategory(data)
    }

    const columns = [
        {
            id: "date",
            name: <span className='font-weight-bold accessibility_size_table'>Блюдо</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.name}</span>,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Выход</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.out_dish}</span>,
            hide: 959,
            width: '90px'
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Категория</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.age_category.name}</span>,
            hide: 599,
            width: '130px'
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>ККал</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.kcal_kJ}</span>,
            hide: 1280,
            width: '90px'
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Б</span>,
            cell: (row: any) => <span className="accessibility_size_table">{Math.round(+row.proteins)}</span>,
            hide: 1280,
            width: '70px'
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Ж</span>,
            cell: (row: any) => <span className="accessibility_size_table">{Math.round(+row.fats)}</span>,
            hide: 1280,
            width: '70px'
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>У</span>,
            cell: (row: any) => <span className="accessibility_size_table">{Math.round(+row.carbohydrates)}</span>,
            hide: 1280,
            width: '70px'
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Дата с</span>,
            cell: (row: any) => <span className="accessibility_size_table">{moment(row.startAction).format('DD.MM.YYYY')}</span>,
            hide: 599,
            width: '120px'
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Дата по</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.endAction ? moment(row.endAction).format('DD.MM.YYYY') : ''}</span>,
            hide: 599,
            width: '120px'
        },
        {
            cell: (row: any) => <i className="bx bx-archive-in fs-3 cursor-pointer" title="Добавить в архив" onClick={() => {
                AxiosClient.post(`/assortments/archive/${row.id}`)
                    .then(() => {
                        fetchAssortments(currentPage, perPage, name, selectAgeCategory.value, selectCateringOrganizer.value);
                    })
            }}></i>,
            center: true,
            width: '60px'
        },
    ]

    return (
        <>
            <Row>
                <Col xxl={3} xl={3} lg={6}>
                    <label htmlFor="">Наименование</label>
                    <input type="text" className='form-control' value={name} onChange={(e) => onChangeName(e.target.value)} />
                </Col>
                <Col xxl={3} xl={3} lg={6}>
                    <label>Организатор питания:</label>
                    <Select
                        options={listCateringOrganizer?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...listCateringOrganizer.map(v => ({value: v.id, label: v.name}))] : [{
                            value: '',
                            label: 'Не выбрано'
                        }]}
                        value={selectCateringOrganizer}
                        onChange={(change: any) => {
                            onChangeCateringOrganizer({value: change.value, label: change.label})
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                </Col>
                <Col xxl={3} xl={3} lg={6}>
                    <label htmlFor="">Возрастная категория</label>
                    <Select
                        options={ageCategories?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...ageCategories.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                        value={selectAgeCategory}
                        onChange={(change: any) => {
                            onChangeAgeCategory({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mt-2">
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={assortments}
                                noDataComponent={'Нет данных'}
                                progressComponent={<Spinner></Spinner>}
                                progressPending={search}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                paginationDefaultPage={currentPage}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно блюд:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        },
                                    },
                                    expanderRow: {
                                        style: {
                                            backgroundColor: "#ffebb5",
                                            paddingTop: '5px',
                                            paddingBottom: '5px'
                                        },
                                    },
                                }}
                                onRowClicked={
                                    (row) => {
                                        if(window.innerWidth <= 768){
                                            setSelectDish(row)
                                            setUpdateDish(true)
                                        }
                                    }
                                }
                                onRowDoubleClicked={
                                    (row) => {
                                        if(window.innerWidth > 768){
                                            setSelectDish(row)
                                            setUpdateDish(true)
                                        }
                                    }
                                }
                            />
                        </CardBody>
                        <CardFooter>
                            <button className="btn btn-primary" onClick={() => setAddDish(true)}>Добавить</button>
                        </CardFooter>
                    </Card>
                    {
                        addDish &&
                        <AddDish fetchAssortment={() => fetchAssortments(currentPage, perPage, name, selectAgeCategory.value, selectCateringOrganizer.value)} setAssortments={value => setAssortments(value)} addDish={addDish} setAddDish={(value) => setAddDish(value)} />
                    }
                    {
                        updateDish &&
                        <UpdateDish fetchAssortment={() => fetchAssortments(currentPage, perPage, name, selectAgeCategory.value, selectCateringOrganizer.value)} setAssortments={value => setAssortments(value)} selectDish={selectDish} updateDish={updateDish} setUpdateDish={value => setUpdateDish(value)} />
                    }
                </Col>
            </Row>
        </>
    )
}

export default ListAssortment
