import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    setDateAdd, setGroupAllDeviations, setGroupModalAdd,
    setIsSend,
    setModalAdd,
    WidgetsSlice
} from "../../../store/reducers/widgets/WidgetsSlice";
import {Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane, Collapse} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import moment from "moment/moment";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import AxiosClient from "../../../api/AxiosClient";
import { getJournalHaccpVentilationRoom} from "../../../store/reducers/widgets/ActionCreator";
import classNames from "classnames";
import UpdateVentilations from "./UpdateVentilations";
import ModalConfirmExitHaccp from "../ModalConfirmExitHaccp";
import {useModalWithConfirm} from "../../../hooks/useModalWithConfirm";


interface IModalAddReportJournalHaccp{
    updateData: any
}

const ModalAddReportJournalVentilationRoom: FC<IModalAddReportJournalHaccp> = ({updateData}) => {
    const dispatch = useAppDispatch();
    const {modalAdd, groupAllDeviations, date, groupModalAdd, students, groups, dateAdd, successUpdate} = useAppSelector(state => state.WidgetReducer);
    const [timeStart, setTimeStart] = useState(moment().format('HH:mm'));
    const [timeEnd, setTimeEnd] = useState(moment().format('HH:mm'));
    const [activeTab, setActiveTab] = useState("1");
    const [ventilationType, setVentilationType] = useState(updateData ? updateData.tip : "Разовое проветривание");
    const [addMyVentilation, setAddMyVentilation] = useState<any>();

    const {
        isFormChange,
        showConfirmExit,
        handleClose,
        handleFormChange,
        handleConfirmClose,
        handleStay,
    } = useModalWithConfirm("addReportJournalVentilationRoom");

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(WidgetsSlice.actions.successUpdate(""))
        }, 5000)
        return () => clearTimeout(timer)
    }, [successUpdate, dispatch]);

    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        if (updateData) setAddMyVentilation(updateData.data_time_list)
        updateData ? setTimeStart(updateData.time) : setTimeStart(moment().format('HH:mm'))
        updateData ? setTimeEnd(updateData.endtime) : setTimeEnd(moment().format('HH:mm'))
    }, [updateData]);

    useEffect(() => {
        if (modalAdd) {
            setVentilationType( updateData ? updateData.tip : "Разовое проветривание");
            setAddMyVentilation( updateData ? updateData.data_time_list : []);
            setTimeStart(updateData ? updateData.time : moment().format('HH:mm'))
            setTimeEnd(updateData ? updateData.endtime : moment().format('HH:mm'))
            tabChange("1");
        }
        if (modalAdd && !updateData) {
            dispatch(setDateAdd(new Date()));
        }
    }, [modalAdd]);

    return (
        <>
        <Modal id="modalAddReportJournalVentilationRoom" isOpen={modalAdd}toggle={handleClose}
               modalClassName={classNames("zoomIn")}
               tabIndex={-1} centered>
            <ModalHeader toggle={handleClose} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление параметров температуры
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        group: `${groupAllDeviations}`,
                        date: moment(dateAdd).format("DD-MM-YYYY"),
                        timeStart: updateData? updateData.time : timeStart,
                        timeEnd: updateData? updateData.endtime : timeEnd,
                        temperature: updateData? updateData.temperature : '',
                        vid_ventilation: updateData? updateData.vid_ventilation : 'Одностороннее',
                        tip: updateData? updateData.tip : 'Разовое проветривание',
                    }}
                    onSubmit={(value) => {

                        dispatch(setIsSend(true));

                        let prop = {
                            group_id : groupAllDeviations,
                            uid: updateData? updateData.uid : '',
                            number: updateData? updateData.number : '',
                            data: moment(dateAdd).format("DD-MM-YYYY"),
                            time: value.timeStart,
                            endtime: value.timeEnd,
                            temperature: value.temperature,
                            data_time_list: [],
                            vid_ventilation: value.vid_ventilation,
                            tip: value.tip,
                        }
                        if (ventilationType === "Несколько раз в день") {
                            const data_time_list = addMyVentilation.map(({remove, ...rest}: any) => rest);
                            prop.data_time_list = data_time_list;
                        }

                        AxiosClient.post('haccp/saveJournalVentilationRoom', prop)
                            .then((r: any) => {
                                dispatch(getJournalHaccpVentilationRoom(date, groupAllDeviations)).then();
                                dispatch(setIsSend(false));
                                dispatch(setModalAdd(false));
                            })
                            .catch((error) => {
                                dispatch(setIsSend(false))
                            })
                    }}
                >
                    {({ errors, touched, setFieldValue }) => (
                    <Form onChange={handleFormChange}>
                        <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                             role="tablist">
                            <NavItem>
                                <NavLink
                                    className={classNames({active: activeTab === "1", "p-2": true})}
                                    onClick={() => {
                                        tabChange("1");
                                    }}
                                    type="button">
                                    <i className="fas fa-home"></i>
                                    Основное
                                </NavLink>
                            </NavItem>
                            {ventilationType === "Несколько раз в день" && (
                                <NavItem>
                                    <NavLink
                                        className={classNames({active: activeTab === "2", "p-2": true})}
                                        onClick={() => {
                                            tabChange("2");
                                        }}
                                        type="button">
                                        <i className="fas fa-home"></i>
                                        Добавить проветривание
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                        <TabContent activeTab={activeTab} className="mt-2">
                            <TabPane tabId="1" className="align-items-center">
                            <div className="col-auto">
                                <label className="w-100">Дата:
                                <div className="col mb-3">
                                    <div className="input-group col-auto">
                                        <Flatpickr
                                            className="form-control border-0 dash-filter-picker shadow"
                                            value={dateAdd}
                                            options={{
                                                locale: Russian, dateFormat: "d M Y", disableMobile: true, mode: 'single'
                                            }}
                                            onChange={(date) => {
                                                dispatch(setDateAdd(moment(date[0]).toDate()))
                                            }}
                                        />
                                    </div>
                                </div></label>
                            </div>
                            <div className="mb-3">
                                <label className="w-100">
                                    Группа:
                                    <Field as="select" className="form-select shadow"
                                           value={groupModalAdd}
                                           onChange={(e: any) => {
                                            dispatch(setGroupAllDeviations(e.target.value))
                                            dispatch(setGroupModalAdd(e.target.value))
                                           }} name="group">
                                        {groups.map((group) => {
                                            return <option key={group.group_id}
                                                           value={group.group_id}>{group.group_name}</option>
                                        })}
                                    </Field>
                                </label>
                            </div>
                            <div className="mb-3">
                                <label className="w-100">
                                    Тип проветривания:
                                    <Field as="select" className="form-select shadow"
                                           name="tip"
                                           onChange={(e: any) => {
                                               setVentilationType(e.target.value);
                                               setFieldValue('tip', e.target.value);
                                           }}>
                                        <option key={0} value="Разовое проветривание">Разовое проветривание</option>
                                        <option key={1} value="Несколько раз в день">Несколько раз в день</option>
                                    </Field>
                                </label>
                            </div>
                            {ventilationType === "Разовое проветривание" && (
                                <>
                                <div className="mb-3">
                                    <label className="w-100">
                                        Вид проветривания:
                                        <Field as="select" className="form-select shadow"
                                               name="vid_ventilation">
                                            <option key={0} value="Одностороннее">Одностороннее</option>
                                            <option key={1} value="Сквозное">Сквозное</option>
                                            <option key={2} value="Угловое">Угловое</option>
                                        </Field>
                                    </label>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="me-3">
                                        <label>
                                            Время начала проветривания:
                                        <div className="input-group ">
                                            <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                value={timeStart}
                                                name="timeStart"
                                                options={{
                                                    locale: Russian,
                                                    enableTime: true,
                                                    noCalendar: true,
                                                    dateFormat: "H:i",
                                                    time_24hr: true,
                                                    disableMobile: true,
                                                    mode: 'single'
                                                }}
                                                onChange={(time) => {
                                                    setTimeStart(moment(time[0]).format('HH:mm'));
                                                    setFieldValue('timeStart', moment(time[0]).format('HH:mm'));
                                                }}
                                            />
                                        </div></label>
                                    </div>
                                    <div>
                                        <label>
                                            Время окончания проветривания:
                                        <div className="input-group ">
                                            <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                value={timeEnd}
                                                name="timeEnd"
                                                options={{
                                                    locale: Russian,
                                                    enableTime: true,
                                                    noCalendar: true,
                                                    dateFormat: "H:i",
                                                    time_24hr: true,
                                                    disableMobile: true,
                                                    mode: 'single'
                                                }}
                                                onChange={(time) => {
                                                    setTimeEnd(moment(time[0]).format('HH:mm'));
                                                    setFieldValue('timeEnd', moment(time[0]).format('HH:mm'));
                                                }}
                                            />
                                        </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="w-100">
                                        Наружная температура:
                                        <Field name="temperature" className={`form-control`}/>
                                    </label>
                                </div>
                            </>
                            )}
                            </TabPane>
                            {ventilationType === "Несколько раз в день" && (
                                <TabPane tabId="2" className="align-items-center">
                                    <UpdateVentilations addMyVentilation={addMyVentilation} setAddMyVentilation={(value) => setAddMyVentilation(value)}/>
                                </TabPane>
                            )}
                        </TabContent>
                        <div className="d-flex gap-5 justify-content-between">
                            <div></div>
                            <div className="hstack gap-2 justify-content-end mt-1">
                                <SubmitWithLoading text={"Сохранить"}/>
                            </div>
                        </div>
                    </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
            <ModalConfirmExitHaccp
                isOpen={showConfirmExit}
                onClose={handleConfirmClose}
                onStay={handleStay}
                hasUnsavedChanges={isFormChange}
            />
        </>
    );
};

export default ModalAddReportJournalVentilationRoom;
