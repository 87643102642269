import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import DataTable from "react-data-table-component";
import ModalUpdateBenefit from "./ModalUpdateBenefit";
import ModalAddBenefit from "./ModalAddBenefit";
import AxiosClient from "../../../api/AxiosClient";
import {useAppDispatch} from "../../../hooks/redux";
import {setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import Notification from "../../widgets/Notification";
import _ from "lodash";
import ModalConfirmExit from "../Schools/ModalConfirmExit";
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface IModalUpdateTypePlannedMenu {
    updateTypePlannedMenu: boolean,
    setUpdateTypePlannedMenu: (value: boolean) => void
    selectTypePlannedMenu: any,
    setTypePlannedMenus: (value: any[]) => void
}

const ModalUpdateTypePlannedMenu: FC<IModalUpdateTypePlannedMenu> = ({setUpdateTypePlannedMenu, setTypePlannedMenus, selectTypePlannedMenu, updateTypePlannedMenu}) => {
    const idButtonSave: string = 'buttonSaveModalUpdateTypePlannedMenu';
    const [activeTab, setActiveTab] = useState("1");
    const [addBenefit, setAddBenefit] = useState(false);
    const [updateBenefit, setUpdateBenefit] = useState(false);
    const [addMyBenefits, setAddMyBenefits] = useState<any[]>([]);
    const [withoutChangesAddMyBenefits, setWithoutChangesAddMyBenefits] = useState<any[]>([]);
    const [selectBenefit, setSelectBenefit] = useState<any | null>(null);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<string>('');
    const [notificationMessage, setNotificationMessage] = useState<string>('');
    const [confirmExit, setConfirmExit] = useState(false);
    const [isChanges, setIsChanges] = useState(false);
    const dispatch = useAppDispatch()

    useEffect(() => {
        setAddMyBenefits(selectTypePlannedMenu?.benefits)
        setWithoutChangesAddMyBenefits(selectTypePlannedMenu?.benefits)
    }, [selectTypePlannedMenu]);

    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const removeBenefitTypePlannedMenu = (id: any) => {
        AxiosClient.post(`/type_planned_menu/delete_benefit/${id}`, {benefitId: id, typePlannedMenu: selectTypePlannedMenu})
            .then((request) => {
                setNotificationType('success');
                setNotificationMessage('Готово, льгота удалена!');
                setShowNotification(true);
                AxiosClient.get('/type_planned_menu')
                    .then(r => {
                        setTypePlannedMenus(r.data)
                        r.data.map((v: any) => {
                            if(v['id']===selectTypePlannedMenu['id'])  setAddMyBenefits(v.benefits);
                        });
                    })
            })
            .catch((error) => {
                console.log(error);
                setNotificationType('error');
                setNotificationMessage('Ошибка, не удалось удалить льготу!');
                setShowNotification(true);
            })
    }

    const confirmOrExit = () => {
        if(isChanges || !_.isEqual(addMyBenefits, withoutChangesAddMyBenefits)){
            setConfirmExit(true);
        }else{
            setUpdateTypePlannedMenu(false);
        }
    }

    const columns : any = [
        {
            id: "date",
            name: <span className='font-weight-bold accessibility_size_table'>Наименование</span>,
            cell: (row: any) => <span className='accessibility_size_table'> {row.name}</span>,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'></span>,
            selector: (row: any) => <i className="bx bx-trash fs-24 cursor-pointer" onClick={() => {removeBenefitTypePlannedMenu(row.id)}}></i>,
            width: '60px'
        }
    ]
    useEscapeKey(() => dispatch(setModalAdd(false)), updateTypePlannedMenu, "updateTypePlannedMenu");

    return (
        <Modal id="updateTypePlannedMenu" isOpen={updateTypePlannedMenu}
               toggle={() => confirmOrExit()}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => confirmOrExit()}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Обновление типа планового меню
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={{
                        name: selectTypePlannedMenu?.name
                    }}
                    validationSchema={
                        Yup.object({
                            name: Yup.string().required('Require')
                        })
                    }
                    onSubmit={(values) => {
                        dispatch(setIsSend(true))
                        AxiosClient.post(`/type_planned_menu/update/${selectTypePlannedMenu?.id}`, {name: values.name, benefits: addMyBenefits})
                            .then(() => {
                                dispatch(setIsSend(false))
                                AxiosClient.get('/type_planned_menu')
                                    .then(r => {
                                        setTypePlannedMenus(r.data)
                                    })
                                setUpdateTypePlannedMenu(false)
                            })
                            .catch(() => {
                                dispatch(setIsSend(false))
                                console.log('error')
                            })
                    }}
                >
                    {
                        ({setFieldValue, errors, touched}) => (
                            <Form>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                     role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "1", "p-2": true})}
                                            onClick={() => {
                                                tabChange("1");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Основное
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "2", "p-2": true})}
                                            onClick={() => {
                                                tabChange("2");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Льготы
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="mt-2">
                                    <TabPane tabId="1" className="align-items-center">
                                        <Row className="mb-2">
                                            <Col>
                                                <Col>
                                                    <label className="w-100">Наименование *:
                                                    <Field name="name"
                                                           className={`form-control ${errors.name && touched.name && "border border-2 border-danger"}`}
                                                           placeholder="Наименование"
                                                           onChange={(e: any) => {
                                                               setIsChanges(true);
                                                               setFieldValue('name', e.target.value);
                                                           }}
                                                    />
                                                    </label>
                                                </Col>
                                            </Col>
                                        </Row>
                                        <SubmitWithLoading id={idButtonSave} text={"Сохранить"}/>
                                    </TabPane>
                                    <TabPane tabId="2" className="align-items-center">
                                        <DataTable
                                            columns={columns}
                                            data={addMyBenefits}
                                            noDataComponent={'Нет данных'}
                                            pagination
                                            paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                            paginationComponentOptions={
                                                {
                                                    rowsPerPageText: 'Видно льгот:',
                                                    rangeSeparatorText: 'из'
                                                }
                                            }
                                            highlightOnHover
                                            customStyles={{
                                                rows: {
                                                    highlightOnHoverStyle: {
                                                        backgroundColor: "#ffebb5"
                                                    },
                                                },
                                                expanderRow: {
                                                    style: {
                                                        backgroundColor: "#ffebb5",
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px'
                                                    },
                                                },
                                            }}
                                            onRowClicked={(row) => {
                                                setSelectBenefit(row)
                                                setUpdateBenefit(true)
                                            }}
                                        />
                                        <button className="btn btn-primary" type="button"
                                                onClick={() => setAddBenefit(true)}>Добавить
                                        </button>
                                    </TabPane>
                                </TabContent>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
            <ModalConfirmExit idButtonSave={idButtonSave} setUpdate={value => setUpdateTypePlannedMenu(value)} confirmExit={confirmExit} setConfirmExit={value => setConfirmExit(value)} />
            <ModalUpdateBenefit updateBenefit={updateBenefit} setUpdateBenefit={value => setUpdateBenefit(value)}
                                addMyBenefits={addMyBenefits} setAddMyBenefits={value => setAddMyBenefits(value)}
                                selectBenefit={selectBenefit}/>
            <ModalAddBenefit addBenefit={addBenefit} setAddBenefit={value => setAddBenefit(value)}
                             addMyBenefits={addMyBenefits} setAddMyBenefits={value => setAddMyBenefits(value)}/>
            {showNotification && <Notification type={notificationType} message={notificationMessage} setShowNotification={(value: boolean) => setShowNotification(value)}/>}
        </Modal>
    );
};

export default ModalUpdateTypePlannedMenu;
