import React, { FC, useState } from 'react';
import {
    Collapse,
    Col,
    Row,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru";
import moment from "moment/moment";
import classNames from "classnames";
import ModalConfirmDeleteChild from "../ModalConfirmDeleteChild";


interface ITableChildrenGroupDo {
    children: any[];
    setChildren: (children: any[]) => void;
}

const TableChildrenGroupDo: FC<ITableChildrenGroupDo> = ({ children, setChildren }) => {
    const [expandedRow, setExpandedRow] = useState<string | null>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [childToDelete, setChildToDelete] = useState<any | null>(null);

    const toggleRow = (id: string) => {
        setExpandedRow(expandedRow === id ? null : id);
    };

    const handleChange = (id: string, field: string, value: any) => {
        const updatedChildren = children.map(child =>
            child.id === id ? { ...child, [field]: value } : child
        );
        setChildren(updatedChildren);
    };
    const openDeleteModal = (child: any) => {
        setChildToDelete(child);
        setIsDeleteModalOpen(true);
    };

    const confirmDelete = () => {
        if (childToDelete) {
            const updatedChildren = children.filter(child => child.id !== childToDelete.id);
            setChildren(updatedChildren);
        }
        setIsDeleteModalOpen(false);
        setChildToDelete(null);
    };

    const cancelDelete = () => {
        setIsDeleteModalOpen(false);
        setChildToDelete(null);
    };

    return (
        <>
        <div
            className="accordion accordion-border-box custom-scroll-news"
            style={{ height: '38rem', overflow: 'auto' }}
        >
            {children.map((child) => (
                <div
                    className="accordion-item shadow position-relative"
                    key={child.id}
                >
                    <div
                        className={classNames(
                            'position-absolute rounded-start h-100',
                            { 'bg-primary': expandedRow === child.id }
                        )}
                        style={{ width: 5 }}
                    ></div>
                    <h2
                        className={classNames(
                            "accordion-header",
                            "d-flex",
                            "align-items-center",
                            "justify-content-between"
                        )}
                    >
                        <button
                            className={classNames(
                                "accordion-button",
                                "fw-medium",
                                { collapsed: expandedRow !== child.id }
                            )}
                            type="button"
                            onClick={() => toggleRow(child.id)}
                            style={{ cursor: "pointer", flex: 1, background: 'transparent' }}
                        >
                            <span className="accessibility_size_table">{child.fio || "Новый ребенок"}</span>
                        </button>
                        <i className="bx bx-trash fs-20 cursor-pointer me-2"  onClick={() => openDeleteModal(child)}></i>
                    </h2>
                    <Collapse
                        isOpen={expandedRow === child.id}
                        className="accordion-collapse"
                    >
                        <Col className="mx-3 py-3">
                            <Row className="mb-3">
                                <Col>
                                    <label className="form-label w-100">
                                        ФИО ребенка
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={child.fio || ''}
                                            onChange={(e) => handleChange(child.id, 'fio', e.target.value)}
                                            disabled={true}
                                        />
                                    </label>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <label className="form-label w-100">
                                        Группа
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={child.groupName || ''}
                                            onChange={(e) => handleChange(child.id, 'groupName', e.target.value)}
                                            disabled={true}
                                        />
                                    </label>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <label className="form-label">
                                        Дата зачисления
                                        <Flatpickr
                                            className="form-control"
                                            value={child.dataBegin ? moment(child.dataBegin, "DD MMMM YYYY").toDate() : ''}
                                            options={{
                                                locale: Russian,
                                                dateFormat: "d M Y",
                                                disableMobile: true,
                                                mode: 'single'
                                            }}
                                            onChange={(date) => {
                                                handleChange(child.id, 'dataBegin', moment(date[0]).format("DD MMMM YYYY"));
                                            }}
                                        />
                                    </label>
                                </Col>
                                <Col>
                                    <label className="form-label">
                                        Дата отчисления
                                        <Flatpickr
                                            className="form-control"
                                            value={child.dataEnd ? moment(child.dataEnd, "DD MMMM YYYY").toDate() : ''}
                                            options={{
                                                locale: Russian,
                                                dateFormat: "d M Y",
                                                disableMobile: true,
                                                mode: 'single'
                                            }}
                                            onChange={(date) => {
                                                handleChange(child.id, 'dataEnd', moment(date[0]).format("DD MMMM YYYY"));
                                            }}
                                        />
                                    </label>
                                </Col>
                            </Row>
                        </Col>
                    </Collapse>
                </div>
            ))}
        </div>
            <ModalConfirmDeleteChild
                isOpen={isDeleteModalOpen}
                childToDelete={childToDelete}
                onConfirm={confirmDelete}
                onCancel={cancelDelete}
            />
        </>
    );
};

export default TableChildrenGroupDo;