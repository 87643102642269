import React, { FC, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import DataTable from "react-data-table-component";
import ModalAddGradingScale from "./ModalAddGradingScale";
import {setShowNotification} from "../../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../../hooks/redux";

// Интерфейс для пропсов модального окна
interface IModalGradingScaleProps {
    isOpen: boolean;
    toggle: () => void;
}

// Интерфейс для записи в таблице
interface IGradingScale {
    id: number;
    systemName: string;
    description: string;
}

const ModalGradingScale: FC<IModalGradingScaleProps> = ({ isOpen, toggle }) => {
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const dispatch = useAppDispatch();

    const toggleAddModal = () => setModalAddOpen(!modalAddOpen);

    // Статические данные для таблицы (пока заглушка)
    const [gradingScales, setGradingScales] = useState<IGradingScale[]>([
        {
            id: 12815,
            systemName: "низкая_средняя_высокая",
            description: "уровень",
        },
    ]);

    // Обработчик сохранения новой шкалы
    const handleSave = (data: { systemName: string; description: string; ranges: any[] }) => {
        const newScale: IGradingScale = {
            id: gradingScales.length + 1, // Генерируем новый ID (временное решение)
            systemName: data.systemName,
            description: data.description,
        };
        setGradingScales([...gradingScales, newScale]);
        console.log("Сохраненные данные:", data);
    };

    // Обработчик удаления строки
    const handleDelete = (id: number) => {
        if (gradingScales.length === 1) {
            dispatch(setShowNotification({ type: "error", message: `Нельзя удалить последнюю шкалу. Должна быть хотя бы одна строка`, show: true }));

            return;
        }
        const updatedScales = gradingScales.filter((scale) => scale.id !== id);
        setGradingScales(updatedScales);
    };

    // Определение колонок для DataTable
    const columns = [
        {
            name: <span className="font-weight-bold">ID</span>,
            selector: (row: IGradingScale) => row.id,
            cell: (row: IGradingScale) => <span>{row.id}</span>,
            center: true,
            width: '100px',
        },
        {
            name: <span className="font-weight-bold">Системное название</span>,
            selector: (row: IGradingScale) => row.systemName,
            cell: (row: IGradingScale) => <span>{row.systemName}</span>,
            center: true,
            wrap: true,
        },
        {
            name: <span className="font-weight-bold">Что измеряется</span>,
            selector: (row: IGradingScale) => row.description,
            cell: (row: IGradingScale) => <span>{row.description}</span>,
            center: true,
            wrap: true,
        },
        {
            name: <span className="font-weight-bold">Изменить</span>,
            cell: () => (
                <Button color="outline-primary" size="sm" className="d-block mx-auto">
                    <i className="bi bi-pencil" />
                </Button>
            ),
            center: true,
            width: '100px',
        },
        {
            name: <span className="font-weight-bold">Удалить</span>,
            cell: (row: IGradingScale) => (
                <Button
                    color="outline-danger"
                    size="sm"
                    className="d-block mx-auto"
                    onClick={() => handleDelete(row.id)}
                >
                    <i className="bi bi-trash" />
                </Button>
            ),
            center: true,
            width: '100px',
        },
    ];

    return (
        <>
            <Modal
                id="ModalGradingScale"
                isOpen={isOpen}
                toggle={toggle}
                modalClassName="zoomIn"
                tabIndex={-1}
                centered
                size="lg"
            >
                <ModalHeader toggle={toggle} className="p-3 bg-soft-primary" id="gradingScaleModalLabel">
                    Шкала оценок
                </ModalHeader>
                <ModalBody>
                    <DataTable
                        columns={columns}
                        data={gradingScales}
                        noDataComponent="Нет данных о шкале оценок"
                        highlightOnHover
                        pointerOnHover
                        customStyles={{
                            rows: {
                                highlightOnHoverStyle: {
                                    backgroundColor: "#ffebb5",
                                },
                            },
                            headCells: {
                                style: {
                                    backgroundColor: '#f8f9fa',
                                    fontWeight: 'bold',
                                },
                            },
                            cells: {
                                style: {
                                    padding: '8px',
                                },
                            },
                        }}
                    />
                    <div className="hstack gap-2 justify-content-end mt-3">
                        <Button color="primary" onClick={toggleAddModal}>
                            Добавить шкалу
                        </Button>
                    </div>
                </ModalBody>
            </Modal>

            {/* Модальное окно для добавления новой записи */}
            <ModalAddGradingScale
                isOpen={modalAddOpen}
                toggle={toggleAddModal}
                onSave={handleSave}
            />
        </>
    );
};

export default ModalGradingScale;