import React, {FC, Fragment, useEffect, useState} from 'react';
import {Badge, Col, Collapse, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row} from "reactstrap";
import classNames from "classnames";
import {ITexture} from "../../../models/game/ITexture";
import {IShot} from "../../../models/game/IShot";
import {TypeSettings} from "../../../models/game/typesSettings/TypeSettings";
import {TypeSettingsSelectAnswer} from "../../../models/game/typesSettings/TypeSettingsSelectAnswer";
import {
    ISettingsAppearance,
    ISettingsBattle,
    ISettingsCollect,
    ISettingsSearch,
    ISettingsSelectAnswer,
    ISettingsSwitchShot,
    ISettingsTake,
    ISettingsTexture
} from "../../../models/game/ISettingsTexture";
import {TypeSettingsGenderVoice} from "../../../models/game/typesSettings/TypeSettingsGenderVoice";
import {TypeSettingsTake} from "../../../models/game/typesSettings/TypeSettingsTake";
import {IGame} from "../../../models/game/IGame";
import {TypeSettingsItemTypePotion} from "../../../models/game/typesSettings/TypeSettingsItemTypePotion";

interface IProps {
    openSettingsTexture: boolean,
    handleOpenSettingsTexture: () => void,
    selectTexture: ITexture
    saveSettingsTexture: (texture: ITexture) => void,
    shot: IShot,
    game: Omit<IGame, "id"> | null
}

const SettingsTexture: FC<IProps> = ({
                                         openSettingsTexture,
                                         handleOpenSettingsTexture,
                                         selectTexture,
                                         saveSettingsTexture,
                                         shot,
                                         game
                                     }) => {
    const [activeTabSettings, setActiveTabSettings] = useState('0');
    const [texture, setTexture] = useState(selectTexture);
    const [open, setOpen] = useState("2");
    const [oldSettings, setOldSettings] = useState<ISettingsTexture | null>(null);

    useEffect(() => {
        setTexture(selectTexture)
        setOldSettings(selectTexture.pivot.settings)
        if (selectTexture.pivot.settings.type === TypeSettings.NO_SETTINGS) {
            setActiveTabSettings("0")
        }
        if (selectTexture.pivot.settings.type === TypeSettings.SELECT_ANSWER) {
            setActiveTabSettings("1")
        }
        if (selectTexture.pivot.settings.type === TypeSettings.COLLECT) {
            setActiveTabSettings("2")
        }
        if (selectTexture.pivot.settings.type === TypeSettings.APPEARANCE) {
            setActiveTabSettings("3")
        }
        if (selectTexture.pivot.settings.type === TypeSettings.SEARCH) {
            setActiveTabSettings("4")
        }
        if (selectTexture.pivot.settings.type === TypeSettings.SWITCH_SHOT) {
            setActiveTabSettings("5")
        }
        if (selectTexture.pivot.settings.type === TypeSettings.BATTLE) {
            setActiveTabSettings("6")
        }
        if (selectTexture.pivot.settings.type === TypeSettings.TAKE) {
            setActiveTabSettings("7")
        }
    }, [selectTexture]);

    const handleChange = (event: any) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        select: +event.target.value
                    }
                }
            }
        }))
    };

    const handleActiveTabSettings = (id: string) => {
        const defaultSettingsCollect = {
            type: TypeSettings.COLLECT,
            settings: {
                id: texture.id,
                name: texture.pivot.name,
                path: texture.path,
                show: 'up',
                textTrue: '',
                textFalse: '',
                pathTextTrue: '',
                pathTextFalse: '',
                highlight: false,
                showTexture: false,
                color: 'black',
                textures: []
            },
            width: texture.pivot.settings.width,
            height: texture.pivot.settings.height,
        }

        const defaultSettingsSelectAnswer = {
            type: TypeSettings.SELECT_ANSWER,
            settings: {
                select: TypeSettingsSelectAnswer.TRUE,
                text: '',
                path: ''
            },
            width: texture.pivot.settings.width,
            height: texture.pivot.settings.height,
        }

        const defaultSettingsSearch = {
            type: TypeSettings.SEARCH,
            settings: {
                isHideIfSearchTexture: true,
                closeTexture: 'rectangle',
                color: 'black'
            } as ISettingsSearch,
            width: texture.pivot.settings.width,
            height: texture.pivot.settings.height,
        }

        const defaultSettingsAppearance = {
            type: TypeSettings.APPEARANCE,
            settings: {
                text: '',
                path: '',
                showAfterVoice: false,
                voice: TypeSettingsGenderVoice.WOMAN,
                timeShow: 0,
                hide: false,
                timeHide: 0
            },
            width: texture.pivot.settings.width,
            height: texture.pivot.settings.height,
        }

        const defaultSettingsNoSettings = {
            type: TypeSettings.NO_SETTINGS,
            settings: {
                select: TypeSettingsSelectAnswer.TRUE,
                text: '',
                path: ''
            },
            width: texture.pivot.settings.width,
            height: texture.pivot.settings.height,
        }

        const defaultSettingsSwitchShot = {
            type: TypeSettings.SWITCH_SHOT,
            settings: {
                shot: (game as IGame).shots[0]
            },
            width: texture.pivot.settings.width,
            height: texture.pivot.settings.height,
        }

        const defaultSettingsBattle = {
            type: TypeSettings.BATTLE,
            settings: {
                textstart: '',
                soundstart: '',
                textwin: '',
                soundwin: '',
                shotwin: null,
                textloss: '',
                soundloss: '',
                shotloss: null,
                textattack: '',
                soundattack: '',
                voicebossa: TypeSettingsGenderVoice.MAN
            },
            width: texture.pivot.settings.width,
            height: texture.pivot.settings.height,
        }
        const defaultSettingsTake = {
            type: TypeSettings.TAKE,
            settings: {
                Description: '', // описание предмета при нажатии осмотреть
                ItemType: TypeSettingsTake.USAGE, //тип предмета зелье, для применения, для соединения
                ItemTypePotion: TypeSettingsItemTypePotion.HEALTH, //вид зелья
                ItemAttach: [] // массив предметов с которыми можно соединить предмет
            },
            width: texture.pivot.settings.width,
            height: texture.pivot.settings.height,
        }

        if (id === '0') {
            if (oldSettings?.type === TypeSettings.NO_SETTINGS) {
                setTexture((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: oldSettings
                    }
                }))
            } else {
                setTexture((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: {...defaultSettingsNoSettings, typeAnimation: texture.pivot.settings.typeAnimation}
                    }
                }))
            }

        }
        if (id === '1') {
            if (oldSettings?.type === TypeSettings.SELECT_ANSWER) {
                setTexture((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: oldSettings
                    }
                }))
            } else {
                setTexture((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: {...defaultSettingsSelectAnswer, typeAnimation: texture.pivot.settings.typeAnimation}
                    }
                }))
            }

        }
        if (id === '2') {
            if (oldSettings?.type === TypeSettings.COLLECT) {
                setTexture((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: oldSettings
                    }
                }))
            } else {
                setTexture((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: {...defaultSettingsCollect, typeAnimation: texture.pivot.settings.typeAnimation}
                    }
                }))
            }
        }
        if (id === '3') {
            if (oldSettings?.type === TypeSettings.COLLECT) {
                setTexture((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: oldSettings
                    }
                }))
            } else {
                setTexture((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: {...defaultSettingsAppearance, typeAnimation: texture.pivot.settings.typeAnimation}
                    }
                }))
            }
        }
        if (id === '4') {
            if (oldSettings?.type === TypeSettings.SEARCH) {
                setTexture((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: oldSettings
                    }
                }))
            } else {
                setTexture((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: {...defaultSettingsSearch, typeAnimation: texture.pivot.settings.typeAnimation}
                    }
                }))
            }
        }
        if (id === '5') {
            if (oldSettings?.type === TypeSettings.SWITCH_SHOT) {
                setTexture((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: oldSettings
                    }
                }))
            } else {
                setTexture((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: {...defaultSettingsSwitchShot, typeAnimation: texture.pivot.settings.typeAnimation}
                    }
                }))
            }
        }
        if (id === '6') {
            if (oldSettings?.type === TypeSettings.BATTLE) {
                setTexture((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: oldSettings
                    }
                }))
            } else {
                setTexture((texture) => ({ // ошибка возникает из-за присвоения начального значение параметрам в ISettingTexture
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: {...defaultSettingsBattle, typeAnimation: texture.pivot.settings.typeAnimation}
                    }
                }))
            }
        }
        if (id === '7') {
            if (oldSettings?.type === TypeSettings.TAKE) {
                setTexture((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: oldSettings
                    }
                }))
            } else {
                setTexture((texture) => ({ // ошибка возникает из-за присвоения начального значение параметрам в ISettingTexture
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: {...defaultSettingsTake, typeAnimation: texture.pivot.settings.typeAnimation}
                    }
                }))
            }
        }

        setActiveTabSettings(id)
    }

    const save = () => {
        saveSettingsTexture(texture)
        handleOpenSettingsTexture()
    }

    const BadgeShow = (textureForShow: ITexture) => {
        if (texture.pivot.settings.type === TypeSettings.COLLECT) {
            if ((texture.pivot.settings.settings as ISettingsCollect).textures.find((el) => el.id === textureForShow.id)) {
                return <Badge color="success" style={{width: 30, height: 30}}
                              className="rounded-circle d-flex justify-content-center align-items-center position-absolute top-0 start-100 translate-middle"><i
                    className="bx bx-check fs-3"></i></Badge>
            }
        }

        return <></>
    }

    const BadgeTakeConnection = (textureForShow: ITexture) => {
        if (texture.pivot.settings.type === TypeSettings.TAKE) {
            if ((texture.pivot.settings.settings as ISettingsTake).ItemType === TypeSettingsTake.CONNECTION) {
                if ((texture.pivot.settings.settings as ISettingsTake).ItemAttach.find((el) => el.id === textureForShow.id)) {
                    return <Badge color="success" style={{width: 30, height: 30}}
                                  className="rounded-circle d-flex justify-content-center align-items-center position-absolute top-0 start-100 translate-middle"><i
                        className="bx bx-check fs-3"></i></Badge>
                }
            }

            return <></>
        }
    }
    const handleSelectCollectTexture = (texture1: ITexture) => {
        if ((texture.pivot.settings.settings as ISettingsCollect).textures.find(el => el.id === texture1.id)) {
            setTexture((texture) => ({
                ...texture,
                pivot: {
                    ...texture.pivot,
                    settings: {
                        ...texture.pivot.settings,
                        settings: {
                            ...texture.pivot.settings?.settings,
                            textures: (texture.pivot.settings.settings as ISettingsCollect).textures.filter(el => el.id !== texture1.id)
                        }
                    }
                }
            }))
        } else {
            setTexture((texture) => ({
                ...texture,
                pivot: {
                    ...texture.pivot,
                    settings: {
                        ...texture.pivot.settings,
                        settings: {
                            ...texture.pivot.settings.settings,
                            textures: [...(texture.pivot.settings.settings as
                                ISettingsCollect).textures, texture1]
                        }
                    }
                }
            }))
        }
    }

    const handleTakeConnection = (texture1: ITexture) => {
        if ((texture.pivot.settings.settings as ISettingsTake).ItemAttach.find(el => el.id === texture1.id)) {
            setTexture((texture) => ({
                ...texture,
                pivot: {
                    ...texture.pivot,
                    settings: {
                        ...texture.pivot.settings,
                        settings: {
                            ...texture.pivot.settings?.settings,
                            textures: (texture.pivot.settings.settings as ISettingsTake).ItemAttach.filter(el => el.id !== texture1.id)
                        }
                    }
                }
            }))
        } else {
            setTexture((texture) => ({
                ...texture,
                pivot: {
                    ...texture.pivot,
                    settings: {
                        ...texture.pivot.settings,
                        settings: {
                            ...texture.pivot.settings.settings,
                            textures: [...(texture.pivot.settings.settings as ISettingsTake).ItemAttach, texture1]
                        }
                    }
                }
            }))
        }
    }

    const onChangeTextTrueCollect = (value: string) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        textTrue: value
                    }
                }
            }
        }))
    }

    const onChangeDescriptionTake = (value: string) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        Description: value
                    }
                }
            }
        }))
    }


    const onChangeShowTextureCollect = (value: boolean) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        showTexture: value
                    }
                }
            }
        }))
    }

    const onChangeHighlightTextureCollect = (value: boolean) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        highlight: value
                    }
                }
            }
        }))
    }

    const onChangeColorTextureCollect = (value: string) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        color: value
                    }
                }
            }
        }))
    }

    const onChangeTextFalseCollect = (value: string) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        textFalse: value
                    }
                }
            }
        }))
    }

    const onChangeTypeAnimate = (value: 'shadow' | 'pulse' | 'none') => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    typeAnimation: value
                }
            }
        }))
    }

    const onChangeShow = (value: 'up' | 'down') => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        show: value
                    }
                }
            }
        }))
    }

    const onChangeTextShow = (value: string) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        text: value
                    }
                }
            }
        }))
    }

    const onChangeTextStart = (value: string) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        textstart: value
                    }
                }
            }
        }))
    }
    const onChangeTextAttack = (value: string) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        textattack: value
                    }
                }
            }
        }))
    }
    const onChangeTextLoss = (value: string) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        textloss: value
                    }
                }
            }
        }))
    }
    const onChangeTextWin = (value: string) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        textwin: value
                    }
                }
            }
        }))
    }

    const onChangeBattle = (voicebossa: TypeSettingsGenderVoice) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        voicebossa: voicebossa
                    }
                }
            }
        }))
    }

    const onChangeItemTypeTake = (ItemType: TypeSettingsTake) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        ItemType: ItemType
                    }
                }
            }
        }))
    }

    const onChangeItemTypePotion = (ItemTypePotion: TypeSettingsItemTypePotion) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        ItemTypePotion: ItemTypePotion
                    }
                }
            }
        }))
    }

    const onChangeAppearance = (voice: TypeSettingsGenderVoice) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        voice: voice
                    }
                }
            }
        }))
    }

    const onChangeTimeShow = (time: string) => {
        if (!isNaN(+time))
            setTexture((texture) => ({
                ...texture,
                pivot: {
                    ...texture.pivot,
                    settings: {
                        ...texture.pivot.settings,
                        settings: {
                            ...texture.pivot.settings.settings,
                            timeShow: +time
                        }
                    }
                }
            }))
    }

    const onChangeTimeHide = (time: string) => {
        if (!isNaN(+time))
            setTexture((texture) => ({
                ...texture,
                pivot: {
                    ...texture.pivot,
                    settings: {
                        ...texture.pivot.settings,
                        settings: {
                            ...texture.pivot.settings.settings,
                            timeHide: +time
                        }
                    }
                }
            }))
    }

    const onChangeHide = (hide: boolean) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        hide: hide
                    }
                }
            }
        }))
    }

    const onChangeHideIfSearch = (hide: boolean) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        isHideIfSearchTexture: hide
                    }
                }
            }
        }))
    }

    const onChangeColor = (value: string) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        color: value
                    }
                }
            }
        }))
    }

    const onChangeCloseTextureSearch = (value: "circle" | "rectangle") => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        closeTexture: value
                    }
                }
            }
        }))
    }

    const onChangeShowAfterVoice = (showAfterVoice: boolean) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        showAfterVoice: showAfterVoice
                    }
                }
            }
        }))
    }

    const toggle = (id: any) => {
        if (open !== id) {
            setOpen(id);
        } else {
            setOpen('')
        }
    };

    return (
        openSettingsTexture ?
            <Offcanvas
                autoFocus
                isOpen={openSettingsTexture}
                backdrop={false}
                scrollable
                direction='start'
            >
                <OffcanvasHeader toggle={handleOpenSettingsTexture}>
                    Настройка текстуры
                </OffcanvasHeader>
                <OffcanvasBody>
                    <div className="accordion" id="genques-accordion">
                        <div className="accordion-item border-0 border-bottom ">
                            <h2 className="accordion-header" id="genques-headingTwo">
                                <button
                                    className={classNames(
                                        'accordion-button fw-medium bg-white text-black',
                                        {
                                            collapsed: open !== '1'
                                        }
                                    )}
                                    type="button"
                                    onClick={() => toggle('1')}
                                    style={{cursor: "pointer"}}
                                >
                                    Основная информация
                                </button>
                            </h2>
                            <Collapse isOpen={open === '1'} className="accordion-collapse">
                                <div className="accordion-body p-0 text-black fw-normal py-2">
                                    <Row>
                                        <Col sm={12}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label htmlFor="name" className="form-label">Название
                                                        текстуры</label>
                                                    <input type="text" id="name" disabled value={texture.pivot.name}
                                                           onChange={(event) => setTexture((texture) => ({
                                                               ...texture,
                                                               pivot: {...texture.pivot, name: event.target.value}
                                                           }))} className="form-control"/>
                                                </Col>
                                                <Col sm={12}>
                                                    <label htmlFor="description" className="form-label">Описание</label>
                                                    <input type="text" id="description"
                                                           value={texture.pivot.description}
                                                           onChange={(event) => setTexture((texture) => ({
                                                               ...texture,
                                                               pivot: {
                                                                   ...texture.pivot,
                                                                   description: event.target.value
                                                               }
                                                           }))} className="form-control"/>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <div className={classNames({
                                        "mb-4": open === "1"
                                    })}></div>
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item border-0 border-bottom ">
                            <h2 className="accordion-header" id="genques-headingTwo">
                                <button
                                    className={classNames(
                                        'accordion-button fw-medium bg-white text-black',
                                        {
                                            collapsed: open !== '2'
                                        }
                                    )}
                                    type="button"
                                    onClick={() => toggle('2')}
                                    style={{cursor: "pointer"}}
                                >
                                    Настройки
                                </button>
                            </h2>
                            <Collapse isOpen={open === '2'} className="accordion-collapse">
                                <div className="accordion-body p-0 text-black fw-normal py-2">
                                    <Row>
                                        <Col sm={12}>
                                            <div className="mt-2">
                                                <Label check className="w-100">
                                                    Настройки
                                                    <select className="form-select w-100" value={activeTabSettings}
                                                            onChange={(e) => handleActiveTabSettings(e.target.value as any)}>
                                                        <option value="0">
                                                            Нет настроек
                                                        </option>
                                                        <option value="1">
                                                            Выбор ответа
                                                        </option>
                                                        <option value="2">
                                                            Собрать в кучку
                                                        </option>
                                                        <option value="3">
                                                            Появление
                                                        </option>
                                                        <option value="4">
                                                            Найти одинаковые картинки
                                                        </option>
                                                        <option value="5">
                                                            Перенаправление на другой кадр
                                                        </option>
                                                        <option value="6">
                                                            Сражение
                                                        </option>
                                                        <option value="7">
                                                            Взять в инвентарь
                                                        </option>
                                                    </select>
                                                </Label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            {
                                                activeTabSettings === "1" &&
                                                <>
                                                    <div className="d-flex gap-5 mt-3">
                                                        <Label check className="w-100">
                                                            Тип ответа
                                                            <select className="form-select w-100"
                                                                    value={(texture.pivot.settings.settings as ISettingsSelectAnswer).select}
                                                                    onChange={(e) => handleChange(e)}>
                                                                <option value="0">
                                                                    Правильный ответ
                                                                </option>
                                                                <option value="1">
                                                                    Не правильный ответ
                                                                </option>
                                                                <option value="2">
                                                                    Не активный
                                                                </option>
                                                            </select>
                                                        </Label>
                                                    </div>
                                                    <div className="mt-2">
                                                        <label htmlFor="text" className="form-label">Текст</label>
                                                        <textarea rows={5} id="text"
                                                                  value={(texture.pivot.settings.settings as ISettingsSelectAnswer).text}
                                                                  onChange={(event) => {
                                                                      setTexture((texture) => (
                                                                          {
                                                                              ...texture,
                                                                              pivot: {
                                                                                  ...texture.pivot,
                                                                                  settings: {
                                                                                      ...texture.pivot.settings,
                                                                                      settings: {
                                                                                          ...texture.pivot.settings.settings,
                                                                                          text: event.target.value
                                                                                      }
                                                                                  }
                                                                              }
                                                                          }
                                                                      ))
                                                                  }}
                                                                  className="form-control"/>
                                                    </div>
                                                </>
                                            }
                                        </Col>
                                        <Col sm={12}>
                                            {
                                                activeTabSettings === "2" &&
                                                <>
                                                    <h5 className="mt-3">Выберите объекты, которые нужно соотнести с
                                                        настраеваемым объектом</h5>
                                                    <div>
                                                        <Row>
                                                            <Col>
                                                                <Row>
                                                                    <Col sm={12}>
                                                                        <label htmlFor="trueOverlap"
                                                                               className="form-label">Текст
                                                                            при верном перемещении</label>
                                                                        <input type="text" id="trueOverlap"
                                                                               value={(texture.pivot.settings.settings as ISettingsCollect).textTrue}
                                                                               onChange={(event) => onChangeTextTrueCollect(event.target.value)}
                                                                               className="form-control"/>
                                                                    </Col>
                                                                    <Col sm={12}>
                                                                        <label htmlFor="falseOverlap"
                                                                               className="form-label">Текст
                                                                            при не верном перемещении</label>
                                                                        <input type="text" id="falseOverlap"
                                                                               value={(texture.pivot.settings.settings as ISettingsCollect).textFalse}
                                                                               onChange={(event) => onChangeTextFalseCollect(event.target.value)}
                                                                               className="form-control"/>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <div className="mt-2">
                                                                    <div className="d-flex gap-5 fs-16">
                                                                        <Label check className="w-100">
                                                                            Расположение текстуры
                                                                            <select className="form-select w-100"
                                                                                    value={(texture.pivot.settings.settings as ISettingsCollect).show}
                                                                                    onChange={(e) => onChangeShow(e.target.value as any)}>
                                                                                <option value="up">
                                                                                    Текстуры над
                                                                                </option>
                                                                                <option value="down">
                                                                                    Текстуры под
                                                                                </option>
                                                                            </select>
                                                                        </Label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Row>
                                                                    <Col sm={12}>
                                                                        <div className="d-flex align-items-center">
                                                                            <label htmlFor="showTexture"
                                                                                   className="form-label m-0">Показывать
                                                                                текстуру</label>
                                                                            <input type="checkbox" id="showTexture"
                                                                                   checked={(texture.pivot.settings.settings as ISettingsCollect).showTexture}
                                                                                   onChange={(event) => onChangeShowTextureCollect(event.target.checked)}
                                                                                   className="form-check-input fs-3 mx-2"/>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm={12} className="mt-2">
                                                                        <div className="d-flex align-items-center">
                                                                            <label htmlFor="highlightTexture"
                                                                                   className="form-label m-0">Выделять
                                                                                при передвижении к ней других
                                                                                текстур</label>
                                                                            <input type="checkbox" id="highlightTexture"
                                                                                   checked={(texture.pivot.settings.settings as ISettingsCollect).highlight}
                                                                                   onChange={(event) => onChangeHighlightTextureCollect(event.target.checked)}
                                                                                   className="form-check-input fs-3 mx-2"/>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm={12} className="mt-2">
                                                                        <div className="d-flex align-items-center">
                                                                            <label htmlFor="colorTexture"
                                                                                   className="form-label m-0">Цвет</label>
                                                                            <input type="color" id="colorTexture"
                                                                                   value={(texture.pivot.settings.settings as ISettingsCollect).color}
                                                                                   onChange={(event) => onChangeColorTextureCollect(event.target.value)}
                                                                                   className="form-control fs-3 mx-2"/>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mt-2 overflow-auto" style={{maxHeight: 400}}>
                                                            {
                                                                shot.textures.map((texture1) => {
                                                                    if (texture1.id === texture.id) {
                                                                        return <Fragment key={texture1.id}></Fragment>
                                                                    }
                                                                    return <Col sm={6} key={texture1.id}
                                                                                className="position-relative d-flex justify-content-center">
                                                                        <div className="position-relative"
                                                                             onClick={() => handleSelectCollectTexture(texture1)}>
                                                                            {
                                                                                BadgeShow(texture1)
                                                                            }
                                                                            <img
                                                                                src={process.env.REACT_APP_API_BASE_URL + texture1.path}
                                                                                style={{height: 75, width: 75}}
                                                                                alt="user"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                })
                                                            }
                                                        </Row>
                                                    </div>
                                                </>
                                            }
                                        </Col>
                                        <Col sm={12}>
                                            {
                                                activeTabSettings === "3" &&
                                                <Row>
                                                    <Col sm={12} className="mt-2">
                                                        <label htmlFor="textShow" className="form-label">Текст после
                                                            появления текстуры</label>
                                                        <input type="text"
                                                               value={(texture.pivot.settings.settings as ISettingsAppearance).text}
                                                               onChange={(e) => onChangeTextShow(e.target.value)}
                                                               id="textShow" className="form-control"/>
                                                    </Col>
                                                    <Col sm={12} className="mt-2">
                                                        <div>
                                                            <Label check className="w-100">
                                                                Голос озвучки
                                                                <select className="form-select w-100"
                                                                        value={(texture.pivot.settings.settings as ISettingsAppearance).voice}
                                                                        onChange={(e) => onChangeAppearance(+e.target.value as TypeSettingsGenderVoice)}>
                                                                    <option value={TypeSettingsGenderVoice.MAN}>
                                                                        Мужской
                                                                    </option>
                                                                    <option value={TypeSettingsGenderVoice.WOMAN}>
                                                                        Женский
                                                                    </option>
                                                                </select>
                                                            </Label>
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} className="mt-2">
                                                        <div className="d-flex align-items-center">
                                                            <label htmlFor="showAfterVoice" className="form-label m-0">Появление
                                                                после озвучки основного текста</label>
                                                            <input type="checkbox"
                                                                   checked={(texture.pivot.settings.settings as ISettingsAppearance).showAfterVoice}
                                                                   id="showAfterVoice"
                                                                   className="form-check-input fs-21 ms-3"
                                                                   onChange={(e) => onChangeShowAfterVoice(e.target.checked)}
                                                            />
                                                        </div>
                                                    </Col>
                                                    {
                                                        !(texture.pivot.settings.settings as ISettingsAppearance).showAfterVoice &&
                                                        <Col sm={12} className="mt-2">
                                                            <label htmlFor="showTime" className="form-label">Время до
                                                                появления (0 - появится сразу после открытия
                                                                кадра)</label>
                                                            <input type="text" id="showTime"
                                                                   onChange={(e) => onChangeTimeShow(e.target.value)}
                                                                   value={(texture.pivot.settings.settings as ISettingsAppearance).timeShow}
                                                                   placeholder="0 - появится сразу после озвучки текста кадра"
                                                                   className="form-control"/>
                                                        </Col>
                                                    }
                                                    <Col sm={12} className="mt-2">
                                                        <div className="d-flex align-items-center">
                                                            <label htmlFor="hide"
                                                                   className="form-label m-0">Исчезновение</label>
                                                            <input type="checkbox"
                                                                   checked={(texture.pivot.settings.settings as ISettingsAppearance).hide}
                                                                   id="hide" className="form-check-input fs-21 ms-3"
                                                                   onChange={(e) => onChangeHide(e.target.checked)}
                                                            />
                                                        </div>
                                                    </Col>
                                                    {
                                                        (texture.pivot.settings.settings as ISettingsAppearance).hide &&
                                                        <Col sm={12} className="mt-2">
                                                            <label htmlFor="hideTime" className="form-label">Время
                                                                исчезновения (0 - исчезнет сразу после озвучки своего
                                                                текста)</label>
                                                            <input type="text"
                                                                   onChange={(e) => onChangeTimeHide(e.target.value)}
                                                                   value={(texture.pivot.settings.settings as ISettingsAppearance).timeHide}
                                                                   placeholder="0 - исчезнет сразу после озвучки своего текста"
                                                                   id="hideTime" className="form-control"/>
                                                        </Col>
                                                    }
                                                </Row>
                                            }
                                        </Col>
                                        <Col sm={12}>
                                            {
                                                activeTabSettings === "4" &&
                                                <Row>
                                                    <Col sm={12} className="mt-2">
                                                        <div>
                                                            <label htmlFor="closeTexture" className="form-label m-0">Форма
                                                                покрывающей текстуры</label>
                                                            <select name="closeTexture" id="closeTexture"
                                                                    className="form-select w-100"
                                                                    value={(texture.pivot.settings.settings as ISettingsSearch).closeTexture}
                                                                    onChange={(e) => onChangeCloseTextureSearch(e.target.value as any)}>
                                                                <option value="circle">Круг</option>
                                                                <option value="rectangle">Прямоугольник</option>
                                                            </select>
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} className="mt-2">
                                                        <div className="d-flex align-items-center">
                                                            <label htmlFor="hideIfSearch" className="form-label m-0">Исчезновение
                                                                после отгадывания</label>
                                                            <input type="checkbox"
                                                                   checked={(texture.pivot.settings.settings as ISettingsSearch).isHideIfSearchTexture}
                                                                   id="hideIfSearch"
                                                                   className="form-check-input fs-21 ms-3"
                                                                   onChange={(e) => onChangeHideIfSearch(e.target.checked)}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} className="mt-2">
                                                        <div className="d-flex align-items-center">
                                                            <label htmlFor="color"
                                                                   className="form-label m-0">Цвет</label>
                                                            <input type="color"
                                                                   value={(texture.pivot.settings.settings as ISettingsSearch).color}
                                                                   id="color" className="form-control fs-21 ms-3"
                                                                   onChange={(e) => onChangeColor(e.target.value)}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                        </Col>
                                        <Col sm={12}>
                                            {
                                                activeTabSettings === "5" &&
                                                <Row>
                                                    <Col sm={12} className="mt-2">
                                                        <label htmlFor="selectShotSwitch"
                                                               className="form-label m-0">Кадр в который перейти</label>
                                                        <select name="selectShotSwitch" id="selectShotSwitch"
                                                                value={(texture.pivot.settings.settings as ISettingsSwitchShot).shot?.uid ?? ''}
                                                                className="form-select" onChange={(event) => {
                                                            const shot = game?.shots.find((el) => el.uid === event.target.value)
                                                            if (shot) {
                                                                setTexture((texture) => ({
                                                                    ...texture,
                                                                    pivot: {
                                                                        ...texture.pivot,
                                                                        settings: {
                                                                            ...texture.pivot.settings,
                                                                            settings: {
                                                                                ...texture.pivot.settings.settings,
                                                                                shot: shot
                                                                            }
                                                                        }
                                                                    }
                                                                }))
                                                            }
                                                        }}>
                                                            {
                                                                game?.shots.filter(el => el.uid !== shot.uid).map((shot) => (
                                                                    <option value={shot.uid}>{shot.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </Col>
                                                </Row>
                                            }
                                        </Col>
                                        <Col sm={12}>
                                            {
                                                activeTabSettings === "6" &&
                                                <Row>
                                                    <Col sm={12} className="mt-2">
                                                        <div>
                                                            <Label check className="w-100">
                                                                Голос босса
                                                                <select className="form-select w-100"
                                                                        value={(texture.pivot.settings.settings as ISettingsBattle).voicebossa}
                                                                        onChange={(e) => onChangeBattle(+e.target.value as TypeSettingsGenderVoice)}>
                                                                    <option value={TypeSettingsGenderVoice.MAN}>
                                                                        Мужской
                                                                    </option>
                                                                    <option value={TypeSettingsGenderVoice.WOMAN}>
                                                                        Женский
                                                                    </option>
                                                                </select>
                                                            </Label>
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} className="mt-2">
                                                        <header style={{fontSize: '1vw'}} className="w-100">
                                                            Настройки в начале боя
                                                        </header>
                                                        <label htmlFor="textstart" className="form-label">Текст</label>
                                                        <input type="text"
                                                               value={(texture.pivot.settings.settings as ISettingsBattle).textstart}
                                                               onChange={(e) => onChangeTextStart(e.target.value)}
                                                               id="textstart" className="form-control"/>
                                                        <Row>
                                                        </Row>
                                                    </Col>
                                                    <Col sm={12} className="mt-2">
                                                        <header style={{fontSize: '1vw'}} className="w-100">
                                                            Настройки во время удара
                                                        </header>
                                                        <label htmlFor="textattack" className="form-label">Текст</label>
                                                        <input type="text"
                                                               value={(texture.pivot.settings.settings as ISettingsBattle).textattack}
                                                               onChange={(e) => onChangeTextAttack(e.target.value)}
                                                               id="textattack" className="form-control"/>
                                                        <Row>
                                                        </Row>
                                                    </Col>
                                                    <Col sm={12} className="mt-2">
                                                        <header style={{fontSize: '1vw'}} className="w-100">
                                                            Настройки при поражении босса
                                                        </header>
                                                        <label htmlFor="textwin" className="form-label">Текст</label>
                                                        <input type="text"
                                                               value={(texture.pivot.settings.settings as ISettingsBattle).textwin}
                                                               onChange={(e) => onChangeTextWin(e.target.value)}
                                                               id="textwin" className="form-control"/>
                                                        <Col>
                                                            <Col sm={12} className="mt-2">
                                                                <label htmlFor="selectShotSwitch"
                                                                       className="form-label m-0">Кадр в который перейти</label>
                                                                <select name="selectShotSwitch" id="selectShotSwitch"
                                                                        value={(texture.pivot.settings.settings as ISettingsBattle).shotwin ?
                                                                            (texture.pivot.settings.settings as ISettingsBattle).shotwin?.uid : ''}
                                                                        className="form-select" onChange={(event) => {
                                                                    const shot = game?.shots.find((el) => el.uid === event.target.value)
                                                                    if (shot) {
                                                                        setTexture((texture) => ({
                                                                            ...texture,
                                                                            pivot: {
                                                                                ...texture.pivot,
                                                                                settings: {
                                                                                    ...texture.pivot.settings,
                                                                                    settings: {
                                                                                        ...texture.pivot.settings.settings,
                                                                                        shotwin: shot
                                                                                    }
                                                                                }
                                                                            }
                                                                        }))
                                                                    }
                                                                    else{
                                                                        setTexture((texture) => ({
                                                                            ...texture,
                                                                            pivot: {
                                                                                ...texture.pivot,
                                                                                settings: {
                                                                                    ...texture.pivot.settings,
                                                                                    settings: {
                                                                                        ...texture.pivot.settings.settings,
                                                                                        shotwin: null
                                                                                    }
                                                                                }
                                                                            }
                                                                        }))
                                                                    }
                                                                }}>
                                                                    <option value={''}>Кадр не выбран</option>
                                                                    {
                                                                        game?.shots.filter(el => el.uid !== shot.uid).map((shot) => (
                                                                            <option value={shot.uid}>{shot.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </Col>
                                                            <Row>
                                                                </Row>
                                                        </Col>
                                                    </Col>
                                                    <Col sm={12} className="mt-2">
                                                        <header style={{fontSize: '1vw'}} className="w-100">
                                                            Настройки при победе босса
                                                        </header>
                                                        <label htmlFor="textloss" className="form-label">Текст</label>
                                                        <input type="text"
                                                               value={(texture.pivot.settings.settings as ISettingsBattle).textloss}
                                                               onChange={(e) => onChangeTextLoss(e.target.value)}
                                                               id="textloss" className="form-control"/>
                                                        </Col>
                                                    <Col sm={12} className="mt-2">
                                                        <label htmlFor="selectShotSwitch"
                                                               className="form-label m-0">Кадр в который перейти</label>
                                                        <select name="selectShotSwitch" id="selectShotSwitch"
                                                                value={(texture.pivot.settings.settings as ISettingsBattle).shotloss ?
                                                                    (texture.pivot.settings.settings as ISettingsBattle).shotloss?.uid : ''}
                                                                className="form-select" onChange={(event) => {
                                                            const shot = game?.shots.find((el) => el.uid === event.target.value)
                                                            if (shot) {
                                                                setTexture((texture) => ({
                                                                    ...texture,
                                                                    pivot: {
                                                                        ...texture.pivot,
                                                                        settings: {
                                                                            ...texture.pivot.settings,
                                                                            settings: {
                                                                                ...texture.pivot.settings.settings,
                                                                                shotloss: shot
                                                                            }
                                                                        }
                                                                    }
                                                                }))
                                                            }
                                                            else{
                                                                setTexture((texture) => ({
                                                                    ...texture,
                                                                    pivot: {
                                                                        ...texture.pivot,
                                                                        settings: {
                                                                            ...texture.pivot.settings,
                                                                            settings: {
                                                                                ...texture.pivot.settings.settings,
                                                                                shotloss: null
                                                                            }
                                                                        }
                                                                    }
                                                                }))
                                                            }
                                                        }}>
                                                            <option value={''}>Кадр не выбран</option>
                                                            {
                                                                game?.shots.filter(el => el.uid !== shot.uid).map((shot) => (
                                                                    <option value={shot.uid}>{shot.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </Col>
                                                </Row>
                                            }
                                        </Col>
                                        <Col sm={12}>
                                            {
                                                activeTabSettings === "7" &&
                                                <Row>
                                                    <Col sm={12} className="mt-2">
                                                        <div>
                                                            <label htmlFor="descriptionPredmeta"
                                                                   className="form-label">Описание предмета</label>
                                                            <input type="text" id='descriptionPredmeta'
                                                                   value={(texture.pivot.settings.settings as ISettingsTake).Description}
                                                                   onChange={(event) => onChangeDescriptionTake(event.target.value)}
                                                                   className="form-control"/>
                                                            <Label check className="w-100">
                                                                Вид применения предмета
                                                                <select className="form-select w-100"
                                                                        value={(texture.pivot.settings.settings as ISettingsTake).ItemType}
                                                                        onChange={(e) => onChangeItemTypeTake(+e.target.value as TypeSettingsTake)}>
                                                                    <option id='usage' value={TypeSettingsTake.USAGE}>
                                                                        Использование
                                                                    </option>
                                                                    <option value={TypeSettingsTake.POTION}>
                                                                        Зелье
                                                                    </option>
                                                                    <option value={TypeSettingsTake.CONNECTION}>
                                                                        Соединение
                                                                    </option>
                                                                </select>
                                                            </Label>
                                                            {(texture.pivot.settings.settings as ISettingsTake).ItemType === TypeSettingsTake.CONNECTION &&
                                                                shot.textures.map((texture1) => {
                                                                    if (texture1.id === texture.id) {
                                                                        return <Fragment key={texture1.id}></Fragment>
                                                                    }
                                                                    return <Col sm={6} key={texture1.id}
                                                                                className="position-relative d-flex justify-content-center">
                                                                        <div className="position-relative"
                                                                             onClick={() => handleTakeConnection(texture1)}>
                                                                            {
                                                                                BadgeTakeConnection(texture1)
                                                                            }
                                                                            <img
                                                                                src={process.env.REACT_APP_API_BASE_URL + texture1.path}
                                                                                style={{height: 75, width: 75}}
                                                                                alt="user"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                })
                                                            }
                                                            {(texture.pivot.settings.settings as ISettingsTake).ItemType === TypeSettingsTake.POTION &&
                                                                <Label check className="w-100">
                                                                    Что повышает зелье
                                                                    <select className="form-select w-100"
                                                                            value={(texture.pivot.settings.settings as ISettingsTake).ItemTypePotion}
                                                                            onChange={(e) => onChangeItemTypePotion(+e.target.value as TypeSettingsItemTypePotion)}>
                                                                        <option value={TypeSettingsItemTypePotion.POWER}>
                                                                            Сила
                                                                        </option>
                                                                        <option value={TypeSettingsItemTypePotion.HEALTH}>
                                                                            Здоровье
                                                                        </option>
                                                                    </select>
                                                                </Label>
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item border-0 border-bottom ">
                            <h2 className="accordion-header" id="genques-headingTwo">
                                <button
                                    className={classNames(
                                        'accordion-button fw-medium bg-white text-black',
                                        {
                                            collapsed: open !== '3'
                                        }
                                    )}
                                    type="button"
                                    onClick={() => toggle('3')}
                                    style={{cursor: "pointer"}}
                                >
                                    Анимация
                                </button>
                            </h2>
                            <Collapse isOpen={open === '3'} className="accordion-collapse">
                                <div className="accordion-body p-0 text-black fw-normal py-2">
                                    <Row>
                                        <Col sm={12}>
                                            <div className="mt-2">
                                                <Label check className="w-100">
                                                    Тип анимации
                                                    <select className="form-select w-100"
                                                            value={texture.pivot.settings.typeAnimation}
                                                            onChange={(e) => onChangeTypeAnimate(e.target.value as any)}>
                                                        <option value="none">
                                                            Без анимации
                                                        </option>
                                                        <option value="shadow">
                                                            Тень
                                                        </option>
                                                        <option value="pulse">
                                                            Пульсация
                                                        </option>
                                                    </select>
                                                </Label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className={classNames({
                                        "mb-4": open === "3"
                                    })}></div>
                                </div>
                            </Collapse>
                        </div>
                    <div className="mt-2">
                        <button className="btn btn-light w-100" onClick={save}>Сохранить</button>
                    </div>
                    </div>
                </OffcanvasBody>
            </Offcanvas> : <></>
    )
};

export default SettingsTexture;
