import React, {FC, useEffect, useState} from 'react';
import {Alert, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import AxiosClient from "../../../api/AxiosClient";
import {customStyles} from "../../settings";
import Select from "react-select";
import {useAppDispatch} from "../../../hooks/redux";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";

interface IModalAddClass {
    addClass: boolean,
    setAddClass: (value: boolean) => void
    setClasses: (value: any[]) => void
    schoolId: number | string
    classes: any[]
}

const ModalAddClass: FC<IModalAddClass> = ({setAddClass, addClass, setClasses, schoolId, classes}) => {

    const [builds, setBuilds] = useState<any[]>([]);
    const [selectBuild, setSelectBuild] = useState({value: '', label: ''});
    const [classesBuild, setClassesBuild] = useState<any[]>([]);
    const [selectClass, setSelectClass] = useState({value: '', label: ''});
    const [error, setError] = useState(false);

    const dispatch = useAppDispatch();
    useEffect(() => {
        schoolId &&
        AxiosClient.get('school_building/school_builds', {params: {id: schoolId}})
            .then(r => {
                setBuilds(r.data)
                if(r.data?.length > 0) {
                    setSelectBuild({value: r.data[0].id, label: r.data[0].name})
                    setClassesBuild(r.data[0].class)
                }

                r.data.class?.length > 0 &&
                setSelectClass({value: r.data.class[0].id, label: r.data.class[0].name})
            })
    }, [schoolId]);

    const onChangeBuild = (data: {value: string, label: string}) => {
        const classes = builds.find(b => b.id.toString() === data.value.toString()).class
        setClassesBuild(classes)
        classes.length > 0 ?
        setSelectClass({value: classes[0].id, label: classes[0].name}) : setSelectClass({value: '', label: ''})
        setSelectBuild(data)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setError(false)
        }, 3000)

        return () => clearTimeout(timer)
    }, [error]);


    const saveClass = () => {
        const find = classes.find(c => c.id.toString() === selectClass.value.toString())
        if (!find && selectClass.value){
            setClasses([...classes, {id: selectClass.value, name: selectClass.label, school_building: {name: selectBuild.label}}])
            setAddClass(false)
        } else {
            setError(true)
        }

    }
    useEscapeKey(() => dispatch(setModalAdd(false)), addClass, "addClass");


    return (
        <Modal id="addClass" isOpen={addClass}
               toggle={() => setAddClass(!addClass)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setAddClass(!addClass)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Классы
            </ModalHeader>
            <ModalBody>
                {error && <Alert color="danger">Такой класс уже добавлен</Alert>}
                <div>
                    <Select
                        options={builds?.map(v => ({value: v.id, label: v.name}))}
                        value={selectBuild}
                        onChange={(change: any) => {
                            onChangeBuild({value: change.value, label: change.label})
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                        noOptionsMessage={() => "Нет корпусов"}
                    />
                    <Select
                        options={classesBuild?.map(v => ({value: v.id, label: v.name}))}
                        value={selectClass}
                        onChange={(change: any) => {
                            setSelectClass({value: change.value, label: change.label})
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                        className="mt-3"
                        noOptionsMessage={() => "Нет классов"}
                    />
                </div>
            </ModalBody>
            <ModalFooter className="justify-content-start">
                <div className="hstack gap-2">
                    <button type="submit" className="btn btn-primary" id="addNewProject" onClick={() => saveClass()}>Добавить</button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default ModalAddClass;
