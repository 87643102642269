import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import ListBenefit from "./ListBenefit";
import ListViewMenu from "./ListViewMenu";
import AxiosClient from "../../../api/AxiosClient";
import moment from "moment/moment";
import {customStyles, customStylesImport} from "../../settings";
import Select from "react-select";
import ListHomeEducation from "./ListHomeEducation";
import ListStatuses from "./ListStatuses";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../hooks/redux";
import _ from "lodash";
import ModalConfirmExit from "../Schools/ModalConfirmExit";
import ModalConfirmChangeOrganizer from './ModalConfirmChangeOrganizer';
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface IModalAddStudent {
    addStudent: boolean,
    setAddStudent: (value: boolean) => void,
    setStudents: (value: any) => void,
    infoFilter: {
        selectCateringOrganizer: {value: string, label: string},
        selectSchool: {value: string, label: string},
        selectBuild: {value: string, label: string},
        selectClass: {value: string, label: string},
    }
}

const ModalAddStudent: FC<IModalAddStudent> = ({setAddStudent, addStudent, setStudents, infoFilter}) => {
    const idButtonSave: string = 'buttonSaveModalAddStudent';
    const [dataBegin, setDataBegin] = useState(new Date());
    const [dateEnd, setDateEnd] = useState<Date | string>('');
    const [activeTab, setActiveTab] = useState("1");
    const [schools, setSchools] = useState<any[]>([]);
    const [selectCateringOrganizer, setSelectCateringOrganizer] = useState({value: '', label: 'Не выбрано'});
    const [listCateringOrganizer, setListCateringOrganizer] = useState<any[]>([]);
    const [selectSchool, setSelectSchool] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectBuild, setSelectBuild] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectClass, setSelectClass] = useState<any>({value: '', label: 'Не выбрано'});
    const [builds, setBuilds] = useState<any[]>([]);
    const [classes, setClasses] = useState<any[]>([])
    const [listBenefit, setListBenefit] = useState<any[]>([]);
    const [listStatuses, setListStatuses] = useState<any[]>([]);
    const [typeMenu, setTypeMenu] = useState<any[]>([]);
    const [listViewMenu, setListViewMenu] = useState<any[]>([]);
    const [listMenu, setListMenu] = useState<any[]>([]);
    const [listHomeEducation, setListHomeEducation] = useState<any[]>([]);
    const [ageCategories, setAgeCategories] = useState<any[]>([]);
    const [withoutChanges, setWithoutChanges] = useState<any[]>([]);
    const [confirmExit, setConfirmExit] = useState(false);
    const [currentObject, setCurrentObject] = useState<any>({magnetic_card: '', name: '', last_name: '', middle_name: '', date_begin: '', date_end: '',
        school_id: '', catering_organizer_id: '', building_id: '', class_id: '', type_menu_id: '',  age_category_id: ''});
    const [newObject, setNewObject] = useState<any>({magnetic_card: '', name: '', last_name: '', middle_name: '', date_begin: '', date_end: '',
        school_id: '', catering_organizer_id: '', building_id: '', class_id: '', type_menu_id: '',  age_category_id: ''});
    const [isChanges, setIsChanges] = useState(false);
    const dispatch = useAppDispatch();
    const [contractCateringOrganizer, setContractCateringOrganizer] = useState<any>([]);
    const [contractCateringOrganizerCheck, setContractCateringOrganizerCheck] = useState({value: '', label: 'Не выбрано'});
    const [confirmChangeOrganizer, setConfirmChangeOrganizer]=useState(false);
    const [newCateringOrganizer, setNewCateringOrganizer]=useState({value: '', label: 'Не выбрано'});

    useEffect(() => {
        AxiosClient.get('/age_categories')
            .then((r) => setAgeCategories(r.data))
            .catch(() => console.log('error'))
        AxiosClient.get('/schools')
            .then((r) => {
                setSchools(r.data.schools)
            })
            .catch(() => console.log('error'))
        AxiosClient.get('/type_menu')
            .then((r) => {
                setTypeMenu(r.data)
            })
            .catch(() => console.log('error'))
    }, []);

    useEffect(() => {
        fetchBuildings(selectSchool.value)
            .then(r => {
                setBuilds(r)
                if (infoFilter.selectBuild.value!==""  && r && r.find((value: any) => value.id === infoFilter.selectBuild.value)){
                    setSelectBuild(infoFilter.selectBuild);
                }
                else{
                    setSelectBuild({value: '', label: 'Не выбрано'})
                    setSelectClass({value: '', label: 'Не выбрано'})
                }
            })
        if (selectSchool.value){
            AxiosClient.get(`/schools/${selectSchool.value}/catering_organizers/type_planned_menu`)
                .then((r) => {
                setListMenu(r.data.catering_organizer?.catering_organizer_type_planned_menu?.map((v: any) => ({
                    ...v.type_planned_menu,
                    idPlan: v.id,
                    pricePlan: v.price
                })))
                setContractCateringOrganizer(r.data)
            })
            AxiosClient.get(`/schools/${selectSchool.value}/catering_organizers/all`)
                .then((r) => setListCateringOrganizer(r.data))
        } else {
            setListCateringOrganizer([])
            setSelectCateringOrganizer({value: '', label: 'Не выбрано'})
        }

    }, [selectSchool.value, schools])

    useEffect(()=>{      
        if(contractCateringOrganizer){        
            const currentDate=new Date()
                        const startDate=new Date(contractCateringOrganizer.date_begin)
                        const endDate=new Date(contractCateringOrganizer.date_end)
                        const currentOrganizer=currentDate >= startDate && currentDate <= endDate    
                        
                        if(currentOrganizer){                        
                            setSelectCateringOrganizer(()=>({                            
                                value: contractCateringOrganizer.catering_organizer_id,
                                label: contractCateringOrganizer.catering_organizer.name
                            }))
                            setContractCateringOrganizerCheck(()=>({                            
                                value: contractCateringOrganizer.catering_organizer_id,
                                label: contractCateringOrganizer.catering_organizer.name
                            }))
                        }else{
                            setSelectCateringOrganizer(()=>({value: '', label: 'Не выбрано'}))
                            setContractCateringOrganizerCheck(()=>({value: '', label: 'Не выбрано'}))
                        }
        }else {
           setSelectCateringOrganizer(()=>({value: '', label: 'Не выбрано'})) 
           setContractCateringOrganizerCheck(()=>({value: '', label: 'Не выбрано'}))
        }
    },[contractCateringOrganizer])

    const handleCateringOrganizerChange = (change: any) => {
        if(contractCateringOrganizerCheck.value !== change.value && selectCateringOrganizer.value!=="" && contractCateringOrganizerCheck.value==selectCateringOrganizer.value){            
            setNewCateringOrganizer({value: change.value, label: change.label})
            setConfirmChangeOrganizer(true)
        }else {
            setSelectCateringOrganizer({value: change.value, label: change.label})
        }
    }

    useEffect(() => {
        if (addStudent && (infoFilter.selectSchool.value!=="")){
            setSelectSchool(infoFilter.selectSchool);
        }
    },[addStudent])
    
    const fetchBuildings = async (selectSchool: string) => {
        if(selectSchool) {
            const response = await AxiosClient.get(`/schools/${selectSchool}/buildings/classes`)
            return response?.data
        }
        return []
    };

    useEffect(() => {
        const sortClass = builds?.find(s => s.id.toString() === selectBuild.value.toString())?.class
        setClasses(sortClass)
        if (infoFilter.selectClass.value!=="" && sortClass && sortClass.find((value: any) => value.id === infoFilter.selectClass.value)){
            setSelectClass(infoFilter.selectClass);
        }
        else{
            setSelectClass({value: '', label: 'Не выбрано'})
        }
    }, [builds, selectBuild.value])

    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const confirmOrExit = () => {
        if(!_.isEqual(currentObject, newObject) || !_.isEqual(listBenefit, withoutChanges) || !_.isEqual(listStatuses, withoutChanges) || !_.isEqual(listMenu, withoutChanges) || !_.isEqual(listHomeEducation, withoutChanges)){
            setConfirmExit(true);
        }else{
            setAddStudent(false);
        }
    }
    useEscapeKey(() => dispatch(setModalAdd(false)), addStudent, "ModalAddStudent");

    return (
        <Modal id="ModalAddStudent" isOpen={addStudent}
               toggle={() => confirmOrExit()}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => confirmOrExit()}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление ученика
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            personalAccount: '',
                            name: newObject.name,
                            last_name: newObject.last_name,
                            middle_name: newObject.middle_name,
                            magnetic_card: newObject.magnetic_card,
                            date_begin: '',
                            date_end: '',
                            school_id: selectSchool.value,
                            catering_organizer_id: selectCateringOrganizer.value,
                            building_id: selectBuild.value,
                            class_id: selectClass.value,
                            type_menu_id: newObject.type_menu_id,
                            age_category_id: ageCategories[0] && ageCategories[0].id,
                            listBenefit: [{}],
                            listMenu: [{}],
                            listHomeEducation: [{}],
                            listStatuses: [{}],
                        }
                    }
                    validationSchema={
                        Yup.object({
                            name: Yup.string().required('Обязательное поле'),
                            last_name: Yup.string().required('Обязательное поле'),
                            middle_name: Yup.string().required('Обязательное поле'),
                            school_id: Yup.string().required('Обязательное поле'),
                            catering_organizer_id: Yup.string().required('Обязательное поле'),
                            building_id: Yup.string().required('Обязательное поле'),
                            class_id: Yup.string().required('Обязательное поле'),
                            type_menu_id: Yup.string().required('Обязательное поле'),
                            age_category_id: Yup.string().required('Обязательное поле'),
                        })
                    }
                    onSubmit={(values) => {
                        dispatch(setIsSend(true))
                        values.date_begin = moment(dataBegin).format("YYYY-MM-DD").toString()
                        values.date_end = dateEnd ? moment(dateEnd).format("YYYY-MM-DD").toString() : ''
                        values.school_id = selectSchool.value
                        values.building_id = selectBuild.value
                        values.class_id = selectClass.value
                        values.listBenefit = listBenefit
                        values.listMenu = listViewMenu
                        values.listHomeEducation = listHomeEducation
                        values.listStatuses = listStatuses
                        AxiosClient.post('/students/create', values)
                            .then(() => {
                                dispatch(setIsSend(false))
                                setAddStudent(false)
                                AxiosClient.get('/students', {params: {page: 1, per_page: 10}})
                                    .then(r => setStudents(r.data))
                                    .catch(() => console.log('error'))
                            })
                            .catch(() => dispatch(setIsSend(false)))
                    }}>
                    {
                        ({setFieldValue, errors, touched}) => (
                            <Form>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                     role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "1", "p-2": true})}
                                            onClick={() => {
                                                tabChange("1");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Основное
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "2", "p-2": true})}
                                            onClick={() => {
                                                tabChange("2");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Льгота
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "3", "p-2": true})}
                                            onClick={() => {
                                                tabChange("3");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Плановое меню
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "4", "p-2": true})}
                                            onClick={() => {
                                                tabChange("4");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Домашнее обучение
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "5", "p-2": true})}
                                            onClick={() => {
                                                tabChange("5");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Категория льгот
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="mt-2">
                                    <TabPane tabId="1" className="align-items-center">
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Фамилия:
                                                    <Field name="last_name" className={`form-control ${errors.last_name && touched.last_name && "border border-2 border-danger"}`}
                                                           onChange={(e: any) => {
                                                               setNewObject({...newObject, 'last_name': e.target.value});
                                                               setFieldValue('last_name', e.target.value);
                                                           }}
                                                    />
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100"> Имя:
                                                    <Field name="name" className={`form-control ${errors.name && touched.name && "border border-2 border-danger"}`}
                                                           onChange={(e: any) => {
                                                               setNewObject({...newObject, 'name': e.target.value});
                                                               setFieldValue('name', e.target.value);
                                                           }}
                                                    />
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Отчество:
                                                    <Field name="middle_name" className={`form-control ${errors.middle_name && touched.middle_name && "border border-2 border-danger"}`}
                                                           onChange={(e: any) => {
                                                               setNewObject({...newObject, 'middle_name': e.target.value});
                                                               setFieldValue('middle_name', e.target.value);
                                                           }}
                                                    /></label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Магнитная карта:
                                                    <Field name="magnetic_card" className={`form-control ${errors.magnetic_card && touched.magnetic_card && "border border-2 border-danger"}`}
                                                           onChange={(e: any) => {
                                                               setNewObject({...newObject, 'magnetic_card': e.target.value});
                                                               setFieldValue('magnetic_card', e.target.value);
                                                           }}
                                                    />
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Дата с:
                                                    <Flatpickr
                                                        className="form-control border-0 dash-filter-picker shadow"
                                                        value={dataBegin}
                                                        options={{
                                                            locale: Russian, dateFormat: "d M Y"
                                                        }}
                                                        onClose={(date) => {
                                                            setDataBegin(date[0])
                                                            setNewObject({...newObject, 'date_begin': date[0]});
                                                        }}
                                                    />
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Дата по:
                                                    <Flatpickr
                                                        className="form-control border-0 dash-filter-picker shadow"
                                                        value={dateEnd}
                                                        options={{
                                                            locale: Russian, dateFormat: "d M Y"
                                                        }}
                                                        onClose={(date) => {
                                                            setDateEnd(date[0])
                                                            setNewObject({...newObject, 'date_end': date[0]});
                                                        }}
                                                    />
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Школа:
                                                    <Select
                                                        options={schools?.length > 0 ? [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }, ...schools.map(v => ({value: v.id, label: v.name}))] : [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }]}
                                                        value={selectSchool}
                                                        onChange={(change: any) => {
                                                            setSelectSchool({value: change.value, label: change.label})
                                                            setNewObject({...newObject, 'school_id': change.value});
                                                            setFieldValue('school_id', change.value);
                                                        }}
                                                        styles={(errors.school_id && touched.school_id) ? customStylesImport() : customStyles}
                                                        placeholder="Поиск"
                                                        name="school_id"
                                                    />
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Организатор питания:
                                                    <Select
                                                        options={listCateringOrganizer?.length > 0 ? [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }, ...listCateringOrganizer.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                                                        value={selectCateringOrganizer}
                                                        onChange={handleCateringOrganizerChange}
                                                        styles={customStyles}
                                                        className={`${errors.catering_organizer_id && 'border border-2 border-danger rounded-3'}`}
                                                        placeholder="Поиск"
                                                        name="name"
                                                    />
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Корпус:
                                                    <Select
                                                        options={builds?.length > 0 ? [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }, ...builds?.map(v => ({value: v.id, label: v.name}))] : [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }]}
                                                        value={selectBuild}
                                                        onChange={(change: any) => {
                                                            setSelectBuild({value: change.value, label: change.label})
                                                            setNewObject({...newObject, 'building_id': change.value});
                                                            setFieldValue('building_id', change.value);
                                                        }}
                                                        styles={(errors.building_id && touched.building_id) ? customStylesImport() : customStyles}
                                                        placeholder="Поиск"
                                                        name="building_id"
                                                    /></label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Класс:
                                                    <Select
                                                        options={classes?.length > 0 ? [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }, ...classes?.map(v => ({value: v.id, label: v.name}))] : [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }]}
                                                        value={selectClass}
                                                        onChange={(change: any) => {
                                                            setSelectClass({value: change.value, label: change.label})
                                                            setNewObject({...newObject, 'class_id': change.value});
                                                            setFieldValue('class_id', change.value);
                                                        }}
                                                        styles={(errors.class_id && touched.class_id) ? customStylesImport() : customStyles}
                                                        placeholder="Поиск"
                                                        name="class_id"
                                                    /></label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Тип меню:
                                                    <Select
                                                        options={typeMenu?.length > 0 ? [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }, ...typeMenu?.map(v => ({value: v.id, label: v.name}))] : [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }]}
                                                        onChange={(change: any) => {
                                                            setNewObject({...newObject, 'type_menu_id': change.value});
                                                            setFieldValue('type_menu_id', change.value);
                                                        }}
                                                        styles={(errors.type_menu_id && touched.type_menu_id) ? customStylesImport() : customStyles}
                                                        placeholder="Поиск"
                                                        name="type_menu_id"
                                                    />
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <label className="w-100">Возрастная категория:
                                                    <Select
                                                        options={ageCategories?.length > 0 ? [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }, ...ageCategories?.map(v => ({value: v.id, label: v.name}))] : [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }]}
                                                        onChange={(change: any) => {
                                                            setNewObject({...newObject, 'age_category_id': change.value});
                                                            setFieldValue('age_category_id', change.value);
                                                        }}
                                                        styles={(errors.age_category_id && touched.age_category_id) ? customStylesImport() : customStyles}
                                                        placeholder="Поиск"
                                                        name="age_category_id"
                                                    />
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="hstack gap-2 mt-3">
                                            <SubmitWithLoading id={idButtonSave} text={"Добавить"}/>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="2" className="align-items-center">
                                        <ListBenefit setListBenefit={value => setListBenefit(value)} listBenefit={listBenefit}/>
                                    </TabPane>
                                    <TabPane tabId="3" className="align-items-center">
                                        <ListViewMenu listViewMenu={listViewMenu} listMenu={listMenu}
                                                      setListViewMenu={value => setListViewMenu(value)}/>
                                    </TabPane>
                                    <TabPane tabId="4" className="align-items-center">
                                        <ListHomeEducation typeMenu={typeMenu} listMenu={listMenu}
                                                           listHomeEducation={listHomeEducation}
                                                           setListHomeEducation={value => setListHomeEducation(value)}/>
                                    </TabPane>
                                    <TabPane tabId="5" className="align-items-center">
                                        <ListStatuses listStatuses={listStatuses} setListStatuses={value => setListStatuses(value)}/>
                                    </TabPane>
                                </TabContent>
                            </Form>
                        )
                    }
                </Formik>
                <ModalConfirmChangeOrganizer 
                    isOpen={confirmChangeOrganizer}
                    toggle={()=>setConfirmChangeOrganizer(false)}
                    onConfirm={()=>{
                        setSelectCateringOrganizer(newCateringOrganizer)
                        setConfirmChangeOrganizer(false)
                    }}
                    onCancel={()=>setConfirmChangeOrganizer(false)}
                />
            </ModalBody>
            <ModalConfirmExit idButtonSave={idButtonSave} setUpdate={value => setAddStudent(value)} confirmExit={confirmExit} setConfirmExit={value => setConfirmExit(value)} />
        </Modal>
    );
};

export default ModalAddStudent;
