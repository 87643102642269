import React, {useEffect, useState} from 'react';
import { Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getDailyReportMood} from "../../../store/reducers/widgets/ActionCreator";
import {setDateAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {IAllDailyReportsMood} from "../../../models/widgets/IAllDailyReportsMood";
import ModalAddDayliReportMood from "../modal/ModalAddDayliReportMood";
import ModalDeleteDayliReportMood from "../modal/ModalDeleteDayliReportMood";
import moment from "moment/moment";

const WidgetTableDayliReportMood = () => {

    const dispatch = useAppDispatch();

    const {groupAllDeviations, date, allDailyReportsMood} = useAppSelector(state => state.WidgetReducer)

    const [modalAdd, setModalAdd] = useState<boolean>(false);
    const [modalDelete, setModalDelete] = useState<boolean>(false);

    useEffect(() => {
        groupAllDeviations &&
        dispatch(getDailyReportMood(date, groupAllDeviations)).then()
    }, [date, dispatch, groupAllDeviations])


    const columns = [
        {
            id: 'date',
            name: <span className='font-weight-bold accessibility_size_table'>Дата</span>,
            selector: (row: IAllDailyReportsMood) => row.date,
            cell: (row: IAllDailyReportsMood) => <span className='accessibility_size_table'>{row.date}</span>,
            sortable: true,
            width: '120px'
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Отличное</span>,
            selector: (row: IAllDailyReportsMood) => row.mood,
            cell: (row: IAllDailyReportsMood) => <span className='accessibility_size_table'>{row.mood}</span>,
            sortable: true,
            center: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Не веселый</span>,
            selector: (row: IAllDailyReportsMood) => row.mood_middle,
            cell: (row: IAllDailyReportsMood) => <span className='accessibility_size_table'>{row.mood_middle}</span>,
            sortable: true,
            center: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Плохое</span>,
            selector: (row: IAllDailyReportsMood) => row.not_mood,
            cell: (row: IAllDailyReportsMood) => <span className='accessibility_size_table'>{row.not_mood}</span>,
            sortable: true,
            center: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Не был</span>,
            selector: (row: IAllDailyReportsMood) => row.null_mood,
            cell: (row: IAllDailyReportsMood) => <span className='accessibility_size_table'>{row.null_mood}</span>,
            sortable: true,
            center: true,
        }
    ];

    return (
        <>
            <Card>
                <CardHeader>
                    Отчет по настроению ребенка
                </CardHeader>
                <CardBody>
                    <DataTable
                        columns={columns}
                        data={allDailyReportsMood}
                        noDataComponent={'Нет данных'}
                        highlightOnHover
                        pagination
                        paginationRowsPerPageOptions={[6]}
                        paginationPerPage={6}
                        paginationComponentOptions={
                            {
                                rowsPerPageText: 'Видно отчетов:',
                                rangeSeparatorText: 'из'
                            }
                        }
                        defaultSortFieldId={'date'}
                        defaultSortAsc={false}
                        pointerOnHover
                        customStyles={{
                            rows: {
                                highlightOnHoverStyle: {
                                    backgroundColor: "#ffebb5"
                                }
                            }
                        }}
                        onRowClicked={
                            (row) => {
                                if(window.innerWidth <= 768){
                                    dispatch(setDateAdd(moment(row.date, "DD.MM.YYYY").toDate()));
                                    setModalAdd(true)
                                }
                            }
                        }
                        onRowDoubleClicked={
                            (row) => {
                                if(window.innerWidth > 768){
                                    dispatch(setDateAdd(moment(row.date, "DD.MM.YYYY").toDate()));
                                    setModalAdd(true)
                                }
                            }
                        }
                    />
                </CardBody>
                <CardFooter className='d-flex gap-2'>
                    <div>
                        <button className="btn btn-primary" onClick={() => setModalAdd(true)}>Добавить данные</button>
                    </div>
                    <div>
                        <button className="btn btn-primary" onClick={() => setModalDelete(true)}>Удалить</button>
                    </div>
                </CardFooter>
            </Card>
            {
                modalDelete &&
                <ModalDeleteDayliReportMood modalDelete={modalDelete} setModalDelete={(value) => setModalDelete(value)}/>
            }
            {
                modalAdd &&
                <ModalAddDayliReportMood modalAdd={modalAdd} setModalAdd={(modalAdd) => setModalAdd(modalAdd)}/>
            }
        </>
    );
};

export default WidgetTableDayliReportMood;
