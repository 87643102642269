import React, {FC, useEffect, useState} from 'react';
import AxiosClient from "../../../api/AxiosClient";
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import moment from "moment";
import {useAppDispatch} from "../../../hooks/redux";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";

interface IModalUpdateStatus {
    updateStatus: boolean,
    setUpdateStatus: (value: boolean) => void,
    listStatuses: any[]
    setListStatuses: (value: any[]) => void
    selectStatus: any

}
const ModalUpdateStatus:FC<IModalUpdateStatus> = ({setUpdateStatus, selectStatus, updateStatus, listStatuses, setListStatuses}) => {
    const [dataBegin, setDataBegin] = useState(new Date());
    const [dateEnd, setDateEnd] = useState<Date>(new Date());
    const [statuses, setStatuses] = useState<any[]>([]);
    const [errorDateBegin, setErrorDateBegin] = useState(false);
    const [errorDateEnd, setErrorDateEnd] = useState(false);

    useEffect(() => {
        updateStatus &&
        AxiosClient.get('/statuses')
            .then(r => setStatuses(r.data))
            .catch(() => console.log('error'))
    }, [updateStatus]);

    useEffect(() => {
        if (selectStatus){
            setDataBegin(moment(selectStatus?.date_begin).toDate())
            setDateEnd(moment(selectStatus?.date_end).toDate())
        }
    }, [selectStatus]);

    const dispatch = useAppDispatch();
    useEscapeKey(() => dispatch(setModalAdd(false)), updateStatus, "updateStatus");

    return (
        <Modal id="updateStatus" isOpen={updateStatus}
               toggle={() => setUpdateStatus(!updateStatus)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setUpdateStatus(!updateStatus)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Обновление статуса
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={{
                        date_begin: new Date(),
                        date_end: '',
                        status_id: selectStatus?.id,
                    }}
                    validationSchema={
                        Yup.object({
                            status_id: Yup.number().required('Обязательное поле'),
                        })
                    }
                    onSubmit={(values) => {
                        setListStatuses(listStatuses.map((b, index) => {
                            if (index === selectStatus.index){
                                return {
                                    ...statuses.find(b => b.id.toString() === values.status_id.toString()),
                                    date_begin: moment(dataBegin).format('YYYY-MM-DD'),
                                    date_end: dateEnd ? moment(dateEnd).format('YYYY-MM-DD') : ""
                                }
                            }
                            return b
                        }))
                        setUpdateStatus(false)
                    }}
                >
                    {
                        () => (
                            <Form>
                                <Row>
                                    <Col>
                                        <div>
                                            <label className="w-100">Статус:
                                            <Field as='select' name="status_id" className="form-select" >
                                                {
                                                    statuses.map(b => <option value={b.id} key={b.id}>{b.name}</option>)
                                                }
                                            </Field>
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <label className="w-100">Дата с:
                                            <Flatpickr
                                                className={`form-control dash-filter-picker shadow ${errorDateBegin ? 'border-1 border-danger' : 'border-0'}`}
                                                value={dataBegin}
                                                options={{
                                                    locale: Russian, dateFormat: "d M Y"
                                                }}
                                                onClose={(date) => {
                                                    setDataBegin(date[0])
                                                }}
                                            />
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <label className="w-100">Дата по:
                                            <Flatpickr
                                                className={`form-control dash-filter-picker shadow ${errorDateEnd ? 'border-1 border-danger' : 'border-0'}`}
                                                value={dateEnd}
                                                options={{
                                                    locale: Russian, dateFormat: "d M Y"
                                                }}
                                                onClose={(date) => {
                                                    setDateEnd(date[0])
                                                }}
                                            />
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                                <div>
                                    <div className="hstack gap-2">
                                        <button type="submit" className="btn btn-primary mt-1" id="addNewProject">Сохранить</button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalUpdateStatus;
