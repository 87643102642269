import React, {FC, useEffect, useState} from 'react';
import moment from "moment";
import AxiosClient from "../../../api/AxiosClient";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import TableStudent from "./TableStudent";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import Notification from "../../widgets/Notification";
import _ from "lodash";
import ModalConfirmExit from "../Schools/ModalConfirmExit";
import {customStyles} from "../../settings";
import Select from "react-select";
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface IUpdateAttendanceChildren {
    updateAttendance: boolean,
    setUpdateAttendance: (value: boolean) => void,
    classes: any[]
    selectDate: Date,
    selectUpdateClass: any,
    setAllInfoAttendance: (value: any[]) => void
}
const UpdateAttendanceChildren: FC<IUpdateAttendanceChildren> = ({setUpdateAttendance, updateAttendance, classes, selectDate, selectUpdateClass, setAllInfoAttendance}) => {
    const idButtonSave: string = 'buttonSaveUpdateAttendanceChildren';
    const [dateAdd, setDateAdd] = useState(selectDate);
    const [selectClass, setSelectClass] = useState({value: '', label: ''});
    const [students, setStudents] = useState<any[]>([]);
    const [withoutChangesStudents, setWithoutChangesStudents] = useState<any[]>([]);
    const [isActive, setIsActive] = useState(false);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<string>('');
    const [notificationMessage, setNotificationMessage] = useState<string>('');
    const {date} = useAppSelector(state => state.WidgetReducer)
    const [confirmExit, setConfirmExit] = useState(false);
    const [isChanges, setIsChanges] = useState(false);
    const dispatch = useAppDispatch()

    useEffect(() => {
        setDateAdd(selectDate)
    }, [selectDate]);

    useEffect(() => {
        setSelectClass(selectUpdateClass)
    }, [selectUpdateClass]);

    useEffect(() => {
        if (classes && classes.length > 0) {
            setSelectClass({ value: classes[0].id, label: classes[0].name });
        }
    }, [classes]);

    useEffect(() => {
        const convDate = moment(dateAdd).format('YYYY-MM-DD').toString()
        selectClass &&
        AxiosClient.get('/students/students_in_class', {params: {date: convDate, class_id: selectClass.value}})
            .then(r => {
                setStudents(r.data)
                setWithoutChangesStudents(r.data)
            })
            .catch((reason) => {
                setIsActive(true)
                setNotificationType('error');
                setNotificationMessage(reason.response.data.message);
                if (reason.response.data.childrens){
                    setStudents(reason.response.data.childrens)
                }
                setShowNotification(true);
            })
    }, [selectClass, dateAdd])

    const confirmOrExit = () => {
        if(!_.isEqual(dateAdd, selectDate)|| selectClass.value!=selectUpdateClass || !_.isEqual(students, withoutChangesStudents)){
            setConfirmExit(true);
        }else{
            setUpdateAttendance(false);
        }
    }
    useEscapeKey(() => dispatch(setModalAdd(false)), updateAttendance, "updateAttendance");

    return (
        <>
            <Modal id="updateAttendance" isOpen={updateAttendance} toggle={() => confirmOrExit()}
                   modalClassName="zoomIn" tabIndex={-1} centered>
                <ModalHeader toggle={() => confirmOrExit()} className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Посещаемость детей
                </ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            group: '1',
                        }}
                        onSubmit={() => {
                            dispatch(setIsSend(true))
                            const convDate = moment(dateAdd).format('YYYY-MM-DD').toString()
                            AxiosClient.post('/students/set_attendance', {students, date: convDate})
                                .then(() => {
                                    dispatch(setIsSend(false))
                                    AxiosClient.get('/attendance_school', {params: {dateBegin: moment(date[0]).format('YYYY-MM-DD').toString(), dateEnd: moment(date[1]).format('YYYY-MM-DD').toString(), class_id: selectClass.value}})
                                        .then(r => setAllInfoAttendance(r.data))
                                    setUpdateAttendance(false)
                                })
                                .catch(() => {
                                    dispatch(setIsSend(false))
                                    console.log('error')
                                })
                        }}
                    >
                        <Form>
                            <div className="col-auto">
                                <label className="w-100">Дата:
                                <div className="col mb-3">
                                    <div className="input-group col-auto">
                                        <Flatpickr
                                            className="form-control border-0 dash-filter-picker shadow"
                                            value={dateAdd}
                                            options={{
                                                locale: Russian, dateFormat: "d M Y", disableMobile: true,
                                            }}
                                            onChange={(date) => {
                                                setDateAdd(date[0])
                                            }}
                                        />
                                    </div>
                                </div>
                                </label>
                            </div>
                            <div className="mb-3">
                                <label className="w-100">Класс:
                                <Select
                                    className="mt-3 shadow w-100"
                                    value={selectClass}
                                    onChange={(selectedOption: any) => setSelectClass(selectedOption)}
                                    options={classes?.map(v => ({value: v.id, label: v.name }))}
                                    noOptionsMessage={() => "Нет классов"}
                                    styles={customStyles}
                                    placeholder="Поиск"
                                    name="name"
                                />
                                </label>
                            </div>
                            <div>
                                <div className="table-responsive shadow mb-3">
                                    <table className="table mb-0">
                                        <tbody>
                                        <tr>
                                            <th scope="row" className="text-success">
                                                Присутствует
                                            </th>
                                            <td>
                                                0
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-danger">
                                                Отстутствует
                                            </th>
                                            <td>
                                                0
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div style={{height: 400}}>
                                <TableStudent setStudents={value => setStudents(value)} students={students}/>
                            </div>
                            <div className="d-flex gap-5 justify-content-between">
                                <div className="hstack gap-2 justify-content-end mt-1">
                                    <button type="button" className="btn btn-primary" id="all">Ввод для всех</button>
                                </div>
                                <div className="hstack gap-2 justify-content-end mt-1">
                                    <SubmitWithLoading text={"Сохранить"} noActive={isActive} id={idButtonSave}/>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                    {/*<ModalSelectDeviationAll isOpen={modalSelectDeviationAll} setIsOpen={(value) => setModalSelectDeviationAll(value)}/>*/}
                </ModalBody>
                <ModalConfirmExit idButtonSave={idButtonSave} setUpdate={value => setUpdateAttendance(value)} confirmExit={confirmExit} setConfirmExit={value => setConfirmExit(value)} />
            </Modal>
            {showNotification && <Notification type={notificationType} message={notificationMessage} setShowNotification={(value: boolean) => setShowNotification(value)}/>}
        </>
    );
};

export default UpdateAttendanceChildren;
