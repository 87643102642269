import {Field} from "formik";
import {FC, useEffect, useState} from "react";
import DataTable from "react-data-table-component";
import AxiosClient from "../../../../api/AxiosClient";
import { getStudents } from "../../../../store/reducers/widgets/ActionCreator";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";

interface ITableAddDayliReportNutration {
    studentsDayliReport: any[],
    date: any,
    group: any,
}
const TableAddDayliReportMood: FC<ITableAddDayliReportNutration> = ({studentsDayliReport, date, group}) => {
    const dispatch = useAppDispatch();

    const {students} = useAppSelector(state => state.WidgetReducer)

    const [moods, setMoods] = useState<any[]>([]);

    useEffect(() => {
        AxiosClient.get('/mood').then(
            r => setMoods(r.data)
        )        
    }, []);
    
    useEffect(() => {
            dispatch(getStudents(date, group)).then()
        }, [date, group, dispatch])

    const columns: any = [
        {
            name: <span className='font-weight-bold fs-13'>ФИО ребенка</span>,
            selector: (row: any) => row.name,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Настроение</span>,
            selector: (row: any, i: number) => 
                (row.mood_id && !students[i].deviation_code) ? 
                    <Field as="select" className="form-select table-add-ceil" name={`mood[${i}].${row.children_id}`}> 
                        {
                            moods.map(mood => <option key={mood.id} value={mood.id}>{mood.name}</option>)
                        }
                    </Field>
                    : <><span className="text-danger mt-1 mb-0">Отсутствует</span>
                        <Field as="select" className="form-select table-add-ceil" name={`mood[${i}].${row.children_id}`} style={{ display: 'none' }}> 
                            {
                            <option key={null} value={''}></option>
                            }
                        </Field>
                    </>
            ,
            center: true,
            style: {padding: '0'}
        }
    ];

    return (
        <DataTable
            columns={columns}
            data={studentsDayliReport}
            fixedHeader
            fixedHeaderScrollHeight={'400px'}
            className="custom-scroll-news"
            noDataComponent={'В данной группе нет детей'}
            highlightOnHover
            customStyles={{
                rows: {
                    highlightOnHoverStyle: {
                        backgroundColor: "#ffebb5"
                    }
                }
            }}
        />
    );
}

export default TableAddDayliReportMood;