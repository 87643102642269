import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Card, CardBody, Col, Container, Row, Table} from "reactstrap";
import OtherDoc from "../../components/parent/OtherDoc";
import OtherImage from "../../components/parent/OtherImage";
import AxiosClient from "../../api/AxiosClient";
import {convertDateTimeFull} from "../../functions/ConvertDate";
import {Helmet} from "react-helmet";

const ParentNewsMore = () => {

    const {id} = useParams();
    const [news, setNews] = useState<any>(undefined);

    useEffect(() => {
        AxiosClient.get('/parent/more/news', {params: {id}})
            .then(r => setNews(r.data))
    }, [id]);

    return (
        <div className="page-content">
            <Container fluid>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{news?.theme}</title>
                </Helmet>
                <Row>
                    <Col xxl={12}>
                        <Card>
                            <CardBody>
                                <div className="table-responsive">
                                    <Table className="table-borderless mb-0">
                                        <tbody>
                                        <tr>
                                            <th className="ps-0 card_header_size_text" scope="row">Тема :</th>
                                            <td className="text-muted card_header_size_text">{news?.theme}</td>
                                        </tr>
                                        <tr>
                                            <th className="ps-0 card_header_size_text" scope="row">Описание :</th>
                                            <td className="text-muted card_header_size_text">{news?.description}</td>
                                        </tr>
                                        <tr>
                                            <th className="ps-0 card_header_size_text" scope="row">Дата :</th>
                                            <td className="text-muted card_header_size_text">{convertDateTimeFull((news?.date as Date))}</td>
                                        </tr>
                                        <tr>
                                            <th className="ps-0 card_header_size_text" scope="row">Сотрудник :</th>
                                            <td className="text-muted card_header_size_text">{news?.user?.name} {news?.user?.middle_name} {news?.user?.last_name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="ps-0 card_header_size_text" scope="row">Срочность :</th>
                                            <td className="text-muted card_header_size_text">
                                                <select name="rang"
                                                        className="mt-2 form-select card_header_size_text mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"
                                                        disabled
                                                        defaultValue={news?.rang}>
                                                    <option value={1}>Не срочно</option>
                                                    <option value={2}>Требует внимание</option>
                                                    <option value={3}>Срочно</option>
                                                </select>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-3 mt-sm-3 mt-lg-3 mt-xl-3 mt-xxl-0">
                    <Col>
                        <Card>
                            <CardBody>
                                <Row>
                                    {
                                        news?.documents &&
                                        JSON.parse(news.documents)?.map((url: any) => (
                                            <Col lg={6} xl={4} xxl={3} className="mt-1" key={url.url}>
                                                <OtherDoc url={{
                                                    url: `${process.env.REACT_APP_API_BASE_URL}/api/download?file=${url.url}&nameFile=${url.name}`,
                                                    name: url.name,
                                                    origUrl: url.url
                                                }}/>
                                            </Col>
                                        ))
                                    }
                                </Row>
                                <Row>
                                    {
                                        news?.otherImage &&
                                        JSON.parse(news.otherImage)?.map((url: any) => (
                                            <Col lg={6} xl={4} xxl={3} className="mt-1" key={url}>
                                                <OtherImage url={{
                                                    url: process.env.REACT_APP_API_BASE_URL + url,
                                                    baseUrl: url
                                                }}/>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ParentNewsMore;
