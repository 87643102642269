import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardFooter, Col, Row} from "reactstrap";
import DataTable from "react-data-table-component";
import AxiosClient from "../../../api/AxiosClient";
import AddStatusBenefit from "./AddStatusBenefit";
import UpdateStatusBenefit from "./UpdateStatusBenefit";

const ListStatusBenefit = () => {
    const [search, setSearch] = useState('')
    const [dataFiltering, setDataFiltering] = useState<any[]>([]);
    const [statuses, setStatuses] = useState<any[]>([]);
    const [selectStatus, setSelectStatus] = useState<any | null>(null);
    const [updateStatus, setUpdateStatus] = useState(false);
    const [addStatus, setAddStatus] = useState(false);

    useEffect(() => {
        AxiosClient.get('/statuses')
            .then(r => {
                setDataFiltering(r.data)
                setStatuses(r.data)
            })
    }, []);

    useEffect(() => {
        setDataFiltering(filtering())
    }, [search, statuses])

    const filtering = () => {
        let data = statuses;

        if(search) {
            data = data.filter((e) => e.name.toLowerCase().includes(search.toLowerCase()))
        }

        return data;
    }

    const columns = [
        {
            id: "date",
            name: <span className='font-weight-bold accessibility_size_table'>Наименование</span>,
            cell: (row: any) => <span className='accessibility_size_table'> {row.name}</span>,
            wrap: true,
        },
    ]
    return (
        <>
            <Row>
                <Col xxl={3} xl={3} lg={6}>
                    <label htmlFor="">Наименование</label>
                    <input type="text" className='form-control' value={search} onChange={(e) => setSearch(e.target.value)} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mt-2">
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={dataFiltering}
                                noDataComponent={'Нет данных'}
                                pagination
                                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно статусов льгот:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        },
                                    },
                                    expanderRow: {
                                        style: {
                                            backgroundColor: "#ffebb5",
                                            paddingTop: '5px',
                                            paddingBottom: '5px'
                                        },
                                    },
                                }}
                                onRowClicked={
                                    (row) => {
                                        if(window.innerWidth <= 768){
                                            setSelectStatus(row)
                                            setUpdateStatus(true)
                                        }
                                    }
                                }
                                onRowDoubleClicked={
                                    (row) => {
                                        if(window.innerWidth > 768){
                                            setSelectStatus(row)
                                            setUpdateStatus(true)
                                        }
                                    }
                                }
                            />
                        </CardBody>
                        <CardFooter>
                            <button className="btn btn-primary" onClick={() => setAddStatus(true)}>Добавить</button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            <AddStatusBenefit addStatus={addStatus} setAddStatus={value => setAddStatus(value)} setStatuses={value => setStatuses(value)}/>
            <UpdateStatusBenefit updateStatus={updateStatus} setUpdateStatus={value => setUpdateStatus(value)} setStatuses={value => setStatuses(value)} selectStatus={selectStatus}/>
        </>
    );
};

export default ListStatusBenefit;
