import React from 'react';
import {Col, Container, Row} from "reactstrap";
import {Helmet} from "react-helmet";
import WidgetTableRegisterDefectEquipment from "../components/widgets/tables/WidgetTableRegisterDefectEquipment";
import WidgetGraphRegisterDefectEquipment from "../components/widgets/graphs/WidgetGraphRegisterDefectEquipment";
import WidgetGraphActEquipmentInspectionCertificate
    from "../components/widgets/graphs/WidgetGraphActEquipmentInspectionCertificate";
import WidgetTableActEquipmentInspectionCertificate
    from "../components/widgets/tables/WidgetTableActEquipmentInspectionCertificate";
import WidgetRequiredFlatpikerPeriod from "../components/widgets/requireds/WidgetRequiredFlatpikerPeriod";

const RegisterDefectEquipment = () => {
    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Регистрация дефекта оборудования</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col xxl={4}>
                        <WidgetRequiredFlatpikerPeriod/>
                        <WidgetGraphActEquipmentInspectionCertificate/>
                        <WidgetGraphRegisterDefectEquipment/>
                    </Col>
                    <Col xxl={8}>
                        <WidgetTableRegisterDefectEquipment/>
                        <WidgetTableActEquipmentInspectionCertificate/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default RegisterDefectEquipment;
