import React, {FC, useCallback, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Form, Formik} from "formik";
import {setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import ReactCrop, {Crop, PixelCrop} from "react-image-crop";
import AxiosClient from "../../../api/AxiosClient";
import {getAllInfoGroup, getStudents} from "../../../store/reducers/widgets/ActionCreator";
import Notification from "../Notification";
import imageCompression from 'browser-image-compression';
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface IModalCropPhotoChildInfo {
    uid: string,
    selectModalCropPhoto: boolean,
    setSelectModalCropPhoto(value: boolean): any,

    pathPhoto: any,

    downloadPhoto64: any,
    setDownloadPhoto64(value: any): any,
}

const ModalCropPhotoChildInfo: FC<IModalCropPhotoChildInfo> = ({ uid, selectModalCropPhoto, setSelectModalCropPhoto,
                                                                   pathPhoto,
                                                                   downloadPhoto64, setDownloadPhoto64}) => {
    const dispatch = useAppDispatch();

    const {groupModalAdd, dateAdd, date} = useAppSelector(state => state.WidgetReducer)
    const [crop, setCrop] = useState<Crop>();
    const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);

    const [cropPath, setCropPath] = useState<any>();
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<string>('');
    const [notificationMessage, setNotificationMessage] = useState<string>('');

    // Преобразуем Blob в File
    const blobToFile = (blob: Blob, fileName: string): File => {
        return new File([blob], fileName, { type: blob.type });
    };

    const onCropComplete = useCallback((newCrop: PixelCrop) => {
        if (imageRef && newCrop.width && newCrop.height) {
            const canvas = document.createElement('canvas');
            const scaleX = imageRef.naturalWidth / imageRef.width;
            const scaleY = imageRef.naturalHeight / imageRef.height;
            canvas.width = newCrop.width;
            canvas.height = newCrop.height;
            const ctx = canvas.getContext('2d');

            if (ctx) {
                ctx.drawImage(
                    imageRef,
                    newCrop.x * scaleX,
                    newCrop.y * scaleY,
                    newCrop.width * scaleX,
                    newCrop.height * scaleY,
                    0,
                    0,
                    newCrop.width,
                    newCrop.height
                );
            }
            // canvas.toBlob((blob) => {
            //     if (blob) {
            //         const reader = new FileReader();
            //         reader.onloadend = () => {
            //             setDownloadPhoto64(reader.result);
            //             setCropPath(reader.result as string);
            //         };
            //         reader.readAsDataURL(blob);
            //     }
            // });

            canvas.toBlob(async (blob) => {
                if (blob) {
                    const file = blobToFile(blob, 'cropped-image.jpg');
                    // Сжимаем изображение с помощью imageCompression
                    const compressedBlob = await imageCompression(file, {
                        maxSizeMB: 0.1,  // Сжимаем до 100 KB (0.1 MB)
                        maxWidthOrHeight: 300, // Максимальная ширина или высота 300x300
                        useWebWorker: true, // Использование web worker для улучшения производительности
                    });

                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setDownloadPhoto64(reader.result);
                        setCropPath(reader.result as string);
                    };
                    reader.readAsDataURL(compressedBlob);
                }
            });
        }
    }, [imageRef]);

    const onImageLoad = useCallback(
        (event: React.SyntheticEvent<HTMLImageElement>) => {
            const img = event.currentTarget as HTMLImageElement;
            setImageRef(img);

            const initialCrop = {
                unit: '%',
                width: 50,
                aspect: 3/4,
            };

            const canvas = document.createElement('canvas');
            const scaleX = img.naturalWidth / img.width;
            const scaleY = img.naturalHeight / img.height;
            const x = (img.width - img.width * initialCrop.width / 100) / 2;
            const y = (img.height - img.height * initialCrop.width / (100 * initialCrop.aspect)) / 2;
            canvas.width = img.naturalWidth * initialCrop.width / 100;
            canvas.height = canvas.width / initialCrop.aspect;

            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.drawImage(
                    img,
                    x * scaleX,
                    y * scaleY,
                    canvas.width,
                    canvas.height,
                    0,
                    0,
                    canvas.width,
                    canvas.height
                );
            }

            canvas.toBlob(async (blob) => {
                if (blob) {
                    const file = blobToFile(blob, 'cropped-image.jpg');

                    const compressedBlob = await imageCompression(file, {
                        maxSizeMB: 0.1,
                        maxWidthOrHeight: 300,
                        useWebWorker: true,
                    });

                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setDownloadPhoto64(reader.result);
                        setCropPath(reader.result as string);
                    };
                    reader.readAsDataURL(compressedBlob);
                }
            });
        },
        []
    );
    useEscapeKey(() => dispatch(setModalAdd(false)), selectModalCropPhoto, "selectModalCropPhoto");


    return (
        <>
            <Modal id="selectModalCropPhoto" isOpen={selectModalCropPhoto}
                   toggle={() => setSelectModalCropPhoto(!selectModalCropPhoto)}
                   modalClassName="zoomIn"  size="xs" tabIndex={-1} centered >
                <ModalHeader toggle={() => setSelectModalCropPhoto(!selectModalCropPhoto)}
                             className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Обрезать фото
                </ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            header: ''
                        }}
                        onSubmit={(values) => {
                            dispatch(setIsSend(true))
                            AxiosClient.post('save_photo_student', {uid: uid, base64: cropPath})
                                .then((r) => {
                                    dispatch(getAllInfoGroup(date)).then();
                                    dispatch(setIsSend(false));
                                    setNotificationType('success');
                                    setNotificationMessage('Готово, фотография сохранена!');
                                    setShowNotification(true);
                                    if (groupModalAdd !== undefined) {
                                        dispatch(getStudents(dateAdd, groupModalAdd)).then()
                                    }

                                    setSelectModalCropPhoto(false);
                                })
                                .catch(() => {
                                    dispatch(setIsSend(false));
                                    setNotificationType('error');
                                    setNotificationMessage('Ошибка, проверьте корректность данных!');
                                    setShowNotification(true);
                                })

                        }}
                    >
                        {
                            ({ errors, touched, setFieldValue }) => (
                                <Form>
                                    <Row className="my-2">
                                        {downloadPhoto64 && (
                                            <Col>
                                                <label className="w-100">Выберите область для обрезки:
                                                    <ReactCrop
                                                        crop={crop}
                                                        aspect={3/4}
                                                        onChange={(newCrop: any) => {
                                                            setCrop(newCrop)
                                                        }}
                                                        onComplete={onCropComplete}
                                                    >
                                                        <img src={pathPhoto} alt="Crop" onLoad={onImageLoad} />
                                                    </ReactCrop>
                                                </label>
                                            </Col>
                                        )}
                                    </Row>
                                    <div className="hstack gap-2">
                                        <SubmitWithLoading text={'Обрезать'} />
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>
                </ModalBody>
            </Modal>
            {showNotification && <Notification type={notificationType} message={notificationMessage} setShowNotification={(value: boolean) => setShowNotification(value)}/>}
        </>
    );
};

export default ModalCropPhotoChildInfo;
