import { useState, useCallback } from 'react';
import {setModalAdd} from "../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "./redux";
import {useEscapeKey} from "./useEscapeKey";

interface UseModalWithConfirmResult {
    isFormChange: boolean;
    showConfirmExit: boolean;
    handleClose: () => void;
    handleFormChange: () => void;
    handleConfirmClose: () => void;
    handleStay: () => void;
}

export const useModalWithConfirm = (modalId: string): UseModalWithConfirmResult => {
    const dispatch = useAppDispatch();
    const [isFormChange, setIsFormChange] = useState(false);
    const [showConfirmExit, setShowConfirmExit] = useState(false);

    const handleClose = useCallback(() => {
        if (isFormChange) {
            setShowConfirmExit(true);
        } else {
            dispatch(setModalAdd(false));
        }
    }, [isFormChange, dispatch]);

    const handleFormChange = useCallback(() => {
        setIsFormChange(true);
    }, []);

    const handleConfirmClose = useCallback(() => {
        setShowConfirmExit(false);
        dispatch(setModalAdd(false));
        setIsFormChange(false);
    }, [dispatch]);

    const handleStay = useCallback(() => {
        setShowConfirmExit(false);
    }, []);

    useEscapeKey(handleClose, true, modalId);

    return {
        isFormChange,
        showConfirmExit,
        handleClose,
        handleFormChange,
        handleConfirmClose,
        handleStay,
    };
};