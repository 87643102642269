import React, { FC, memo, useEffect, useState} from 'react';
import {Button, Nav, NavItem, TabContent, TabPane, UncontrolledTooltip} from "reactstrap";
import {Link, NavLink} from "react-router-dom";
import SimpleBar from "simplebar-react";
import searchUsersAndOrg from "../../../functions/SearchUsersAndOrg";
import classNames from "classnames";
import {IUser} from "../../../models/IUser";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getUsers, getUsersPage, setSelectUser} from "../../../store/reducers/admin/ActionCreator";
import useDebounce from "../../../hooks/useDebounce";

interface ISelectToUpdateUser {
    setAddUser: (val: boolean) => void
}

const SelectToUpdateUser: FC<ISelectToUpdateUser> = ({setAddUser}) => {
    const dispatch = useAppDispatch();
    const {organizations, users} = useAppSelector(state => state.AdminReducer)
    const [currentRoomId, setCurrentRoomId] = useState<number>(0);
    const [customActiveTab, setCustomActiveTab] = useState<string>("1");
    const [selOrg, setSelOrg] = useState('');
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const debounceSearch = useDebounce(search, 1000)

    useEffect(() => {
        dispatch(getUsersPage(page, debounceSearch, '', '', '', search ? 'name' : 'page'));
    }, [dispatch, page, debounceSearch, search]);

    const toggleCustom = (tab: string) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };

    const userUpdate = (user: IUser) => {
        setCurrentRoomId(user.id)
        dispatch(setSelectUser(user))
    }

    useEffect(() => {
        setCurrentRoomId(users[0]?.id)
        dispatch(setSelectUser(users[0]))
    }, [])

    useEffect(() => {
        searchUsersAndOrg(selOrg, users)
    }, [users, selOrg]);

    return (
        <div className="chat-leftsidebar ">
            <div className="px-4 pt-4 mb-4">
                <div className="d-flex align-items-start">
                    <div className="flex-grow-1">
                        <h5 className="mb-4">Настройки</h5>
                    </div>
                    <div className="flex-shrink-0">
                        <UncontrolledTooltip placement="bottom" target="addcontact">
                            Добавить пользователя
                        </UncontrolledTooltip>

                        <Button
                            color=""
                            id="addcontact"
                            className="btn btn-soft-primary btn-sm"
                            onClick={() => setAddUser(true)}
                        >
                            <i className="ri-add-line align-bottom"></i>
                        </Button>
                    </div>
                </div>
                <div className="search-box">
                    <input
                        onChange={(e) => {
                            setSearch(e.target.value)
                            setPage(1);
                        }}
                        value={search}
                        id="search-user"
                        type="text"
                        className="form-control bg-light border-light"
                        placeholder="Поиск пользователя"
                    />
                    <i className="ri-search-2-line search-icon"></i>
                </div>
                <div className="search-box mt-1">
                    <select className="form-select" onChange={(e) => {
                        setSelOrg(e.target.value)
                    }}>
                        <option value="">Выберите организацию</option>
                        {
                            organizations.map((o) => <option key={o.id} value={o.name}>{o.name}</option>)
                        }
                    </select>
                </div>
            </div>

            <Nav tabs className="nav nav-tabs nav-tabs-custom nav-primary nav-justified mb-3">
                <NavItem>
                    <NavLink
                        to="#"
                        style={{cursor: "pointer"}}
                        className={classNames({
                            active: customActiveTab === "1",
                        })}
                        onClick={() => {
                            toggleCustom("1");
                        }}
                    >
                        Пользователи
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent
                activeTab={customActiveTab}
                className="text-muted"
            >
                <TabPane tabId="1" id="chats">
                    <SimpleBar
                        className="chat-room-list pt-3"
                        style={{margin: "-16px 0px 0px"}}
                    >
                        <div className="d-flex align-items-center px-4 mb-2">
                            <div className="flex-grow-1">
                                <h4 className="mb-0 fs-11 text-muted text-uppercase">
                                    Выберите для изменения данных
                                </h4>
                            </div>
                        </div>

                        <div className="chat-message-list">
                            <ul
                                className="list-unstyled chat-list chat-user-list users-list"
                                id="userList"
                            >
                                {(users || []).map((user, index) => (
                                    <li
                                        key={`${user.id}-${index}`}
                                        className={currentRoomId === user.id ? "active" : ""}
                                    >
                                        <Link to="#"
                                              onClick={() => {
                                                  userUpdate(user)
                                                  setAddUser(false);
                                              }}
                                        >
                                            <div className="d-flex align-items-center">
                                                <div
                                                    className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                                    <div className="avatar-xxs">
                                                        {user.photo !== null ? (
                                                            <img
                                                                src={`http://127.0.0.1:8000${user.photo}`}
                                                                className="rounded-circle img-fluid userprofile"
                                                                alt="img user"
                                                            />
                                                        ) : (
                                                            <div
                                                                className="avatar-title rounded-circle bg-primary  userprofile">
                                                                {user.name && user.name.charAt(0)}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 overflow-hidden">
                                                    <p className="text-truncate mb-0">{user.middle_name} {user.name && user.name} {user?.organization?.name}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                ))}
                                <li>
                                    <div className="d-flex gap-2">
                                        <button
                                            type="button"
                                            className="btn w-100 btn-primary mt-3"
                                            onClick={() => {
                                                if (page > 1) {setPage(page - 1);}
                                            }}
                                            disabled={page <= 1}
                                        >Назад
                                        </button>
                                        <button
                                            type="button"
                                            className="btn w-100 btn-primary mt-3"
                                            onClick={() => {setPage(page + 1);}}
                                            disabled={users.length === 0 || users.length<200}
                                        > Вперед
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </SimpleBar>
                </TabPane>
            </TabContent>
        </div>
    );
};

export default memo(SelectToUpdateUser);
