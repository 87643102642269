import React, { useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import {Card, CardBody,  CardHeader} from "reactstrap";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import WidgetService from "../../../api/services/widgetService/WidgetService";
import {IAllDeviationGroup} from "../../../models/widgets/IAllDeviationGroup";
import {setToUpdateGraphMainPage} from "../../../store/reducers/widgets/WidgetsSlice";

const WidgetTableAllDeviationsGroup = () => {
    const {groupAllDeviations, date, toUpdateGraphMainPage} = useAppSelector(state => state.WidgetReducer)
    const dispatch = useAppDispatch();
    const [data, setData] = useState<IAllDeviationGroup[]>([]);

    useEffect(() => {
        toUpdateGraphMainPage &&
        WidgetService.getAllDeviationGroup(date)
            .then(res => {
                setData(res.data)
                dispatch(setToUpdateGraphMainPage(false))
            })
    }, [toUpdateGraphMainPage])

    useEffect(() => {
        WidgetService.getAllDeviationGroup(date)
            .then(res => setData(res.data))
    }, [date])

    const columns: any = [
        {
            name: <span className='font-weight-bold accessibility_size_table'>Отклонение</span>,
            cell: (row: IAllDeviationGroup) =>  <span className="accessibility_size_table">{row.deviation_name}</span>,
            sortable: true,
            wrap: true
        },
        {
            id: "count",
            name: <span className='font-weight-bold accessibility_size_table'>Количество</span>,
            cell: (row: IAllDeviationGroup) =>  <span className="accessibility_size_table">{row.count}</span>,
            sortable: true,
            center: true,
            wrap: true
        }
    ];

    return (
        <>
            <Card >
                <CardHeader>
                    Таблица всех отклонений
                </CardHeader>
                <CardBody className='d-flex align-items-center'>
                    <DataTable
                        columns={columns}
                        data={data.length > 0 ? data.filter(item => item.group_id === groupAllDeviations) : []}
                        noDataComponent={'В данной группе нет отклонений'}
                        defaultSortFieldId={"count"}
                        defaultSortAsc={false}
                        highlightOnHover
                        customStyles={{
                            rows: {
                                highlightOnHoverStyle: {
                                    backgroundColor: "#ffebb5"
                                }
                            }
                        }}
                    />
                </CardBody>
            </Card>
        </>
    );
};

export default WidgetTableAllDeviationsGroup;
