import React, { FC, useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, Table } from "reactstrap";
import { setShowNotification } from "../../../../store/reducers/widgets/WidgetsSlice";
import { useAppDispatch } from "../../../../hooks/redux";

// Интерфейс для пропсов модального окна
interface IModalAddGradingScaleProps {
    isOpen: boolean;
    toggle: () => void;
    onSave: (data: { systemName: string; description: string; ranges: IRange[] }) => void;
}

// Интерфейс для диапазона в таблице формы
interface IRange {
    from: number;
    to: number;
    level: string;
    description: string;
}

const ModalAddGradingScale: FC<IModalAddGradingScaleProps> = ({ isOpen, toggle, onSave }) => {
    const dispatch = useAppDispatch();

    // Состояние для формы
    const [systemName, setSystemName] = useState('');
    const [description, setDescription] = useState('');
    const [ranges, setRanges] = useState<IRange[]>([
        { from: 0, to: 33, level: 'низкий', description: 'Удачный' },
        { from: 34, to: 66, level: 'средний', description: 'Удачный' },
        { from: 67, to: 100, level: 'высокий', description: 'Удачный' },
    ]);

    // Сбрасываем состояние формы при открытии модального окна
    useEffect(() => {
        if (isOpen) {
            setSystemName(''); // Сбрасываем системное название
            setDescription(''); // Сбрасываем описание
            setRanges([
                { from: 0, to: 33, level: 'низкий', description: 'Удачный' },
                { from: 34, to: 66, level: 'средний', description: 'Удачный' },
                { from: 67, to: 100, level: 'высокий', description: 'Удачный' },
            ]); // Сбрасываем диапазоны до начального состояния
        }
    }, [isOpen]); // Эффект срабатывает при изменении isOpen

    // Обработчик изменения диапазона
    const handleRangeChange = (index: number, field: keyof IRange, value: string | number) => {
        const updatedRanges = [...ranges];
        updatedRanges[index] = { ...updatedRanges[index], [field]: value };
        setRanges(updatedRanges);
    };

    // Обработчик добавления нового уровня
    const handleAddRange = () => {
        const newRange: IRange = {
            from: 0,
            to: 0,
            level: '',
            description: '',
        };
        setRanges([...ranges, newRange]);
    };

    // Обработчик удаления уровня
    const handleDeleteRange = (index: number) => {
        if (ranges.length === 1) {
            dispatch(setShowNotification({ type: "error", message: `Нельзя удалить последний уровень. Должна быть хотя бы одна строка.`, show: true }));
            return;
        }
        const updatedRanges = ranges.filter((_, i) => i !== index);
        setRanges(updatedRanges);
    };

    // Проверка суммы диапазонов (для валидации)
    const validateRanges = () => {
        const totalRange = ranges.reduce((sum, range) => sum + (range.to - range.from + 1), 0);
        return totalRange === 101; // Сумма должна быть от 0 до 100 включительно
    };

    // Обработчик сохранения
    const handleSave = () => {
        if (!validateRanges()) {
            dispatch(setShowNotification({ type: "error", message: `В сумме процентной шкалы диапазон должен составить в сумме от 0% до 100%.`, show: true }));
            return;
        }
        onSave({
            systemName,
            description,
            ranges,
        });
        toggle();
    };

    return (
        <Modal
            id="ModalAddGradingScale"
            isOpen={isOpen}
            toggle={toggle}
            modalClassName="zoomIn"
            tabIndex={-1}
            centered
            size="lg"
        >
            <ModalHeader toggle={toggle} className="p-3 bg-soft-primary" id="addGradingScaleModalLabel">
                Добавить шкалу оценок
            </ModalHeader>
            <ModalBody>
                <FormGroup className="mb-3">
                    <Label for="systemName">Системное название шкалы</Label>
                    <Input
                        type="text"
                        id="systemName"
                        placeholder="Шкала оценок"
                        value={systemName}
                        onChange={(e) => setSystemName(e.target.value)}
                        className="form-control"
                    />
                    <small className="form-text text-muted">
                        Укажите системное название показателя, который будет видеть администратор
                    </small>
                </FormGroup>

                <FormGroup className="mb-3">
                    <Label for="description">Название измеряемого показателя</Label>
                    <Input
                        type="text"
                        id="description"
                        placeholder="Уровень"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="form-control"
                    />
                </FormGroup>

                <FormGroup className="mb-3">
                    <Label>Задайте уровень измерения показателя в процентах</Label>
                    <Table className="table-bordered table-nowrap">
                        <thead className="bg-light">
                        <tr>
                            <th className="text-center align-middle">Балл*</th>
                            <th className="text-center align-middle">Уровень</th>
                            <th className="text-center align-middle">Описание**</th>
                            <th className="text-center align-middle">Действие</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ranges.map((range, index) => (
                            <tr key={index}>
                                <td className="text-center align-middle">
                                    <div className="d-flex align-items-center">
                                        <Input
                                            type="number"
                                            value={range.from}
                                            onChange={(e) => handleRangeChange(index, 'from', parseInt(e.target.value))}
                                            className="form-control me-2"
                                            style={{ width: '60px' }}
                                        />
                                        -
                                        <Input
                                            type="number"
                                            value={range.to}
                                            onChange={(e) => handleRangeChange(index, 'to', parseInt(e.target.value))}
                                            className="form-control ms-2"
                                            style={{ width: '60px' }}
                                        />
                                    </div>
                                </td>
                                <td className="text-center align-middle">
                                    <Input
                                        type="text"
                                        value={range.level}
                                        onChange={(e) => handleRangeChange(index, 'level', e.target.value)}
                                        className="form-control"
                                    />
                                </td>
                                <td className="text-center align-middle">
                                    <Input
                                        type="text"
                                        value={range.description}
                                        onChange={(e) => handleRangeChange(index, 'description', e.target.value)}
                                        className="form-control"
                                    />
                                </td>
                                <td className="text-center align-middle">
                                    <Button
                                        color="outline-danger"
                                        size="sm"
                                        className="d-block mx-auto"
                                        onClick={() => handleDeleteRange(index)}
                                    >
                                        <i className="bi bi-trash" />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <div className="hstack gap-2 justify-content-end mt-2">
                        <Button color="primary"  onClick={handleAddRange}>
                            Добавить
                        </Button>
                    </div>
                    <small className="form-text text-muted d-block mt-1">
                        *Уровни процентной шкалы должны находиться в диапазоне от 0% до 100%.
                    </small>
                    <small className="form-text text-muted d-block mt-1">
                        **Подробное описание, которое будет видеть участник, после прохождения теста
                    </small>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSave}>
                    Сохранить
                </Button>{' '}
            </ModalFooter>
        </Modal>
    );
};

export default ModalAddGradingScale;