import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import {customStyles, customStylesImport} from "../../settings";
import Select from "react-select";
import * as Yup from 'yup'
import {areIntervalsOverlapping} from "../../../functions/ConvertDate";
import moment from "moment";
import {useAppDispatch} from "../../../hooks/redux";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";

interface IModalHomeEducation {
    addHomeEducation: boolean,
    setAddHomeEducation: (value: boolean) => void,
    listMenu: any[],
    typeMenu: any[],
    listHomeEducation: any[],
    setListHomeEducation: (value: any[]) => void,
}

const ModalAddHomeEducation: FC<IModalHomeEducation> = ({
                                                            setAddHomeEducation,
                                                            addHomeEducation,
                                                            listMenu,
                                                            typeMenu,
                                                            setListHomeEducation,
                                                            listHomeEducation
                                                        }) => {

    const [dateBegin, setDateBegin] = useState(new Date());
    const [dateEnd, setDateEnd] = useState<string | Date>('');
    const [dateReceive, setDateReceive] = useState<string | Date>(new Date());
    const [priceDay, setPriceDay] = useState(0);
    const [selectTypeMenu, setSelectTypeMenu] = useState({value: '', label: ''});
    const [selectListMenu, setSelectListMenu] = useState({value: '', label: ''});
    const [countDay, setCountDay] = useState(0);
    useEffect(() => {
        listMenu &&
        setSelectListMenu({value: listMenu[0]?.id, label: listMenu[0]?.name})
    }, [listMenu]);

    useEffect(() => {
        setPriceDay(listMenu?.find(b => b.id.toString() === selectListMenu.value.toString())?.pricePlan)
    }, [selectListMenu]);

    const dispatch = useAppDispatch();
    useEscapeKey(() => dispatch(setModalAdd(false)), addHomeEducation, "addHomeEducation");

    return (
        <Modal id="addHomeEducation" isOpen={addHomeEducation}
               toggle={() => setAddHomeEducation(!addHomeEducation)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setAddHomeEducation(!addHomeEducation)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Домашнее обучение
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={{
                        date_begin: new Date(),
                        date_end: '',
                        type_menu_id: selectTypeMenu.value,
                        type_plane_menu_id: selectListMenu.value,
                        count_day: countDay,
                        price_in_day: priceDay,
                        result_price: priceDay * countDay
                    }}
                    validationSchema={
                        Yup.object({
                            type_menu_id: Yup.string().required('Обязательное поле'),
                            type_plane_menu_id: Yup.string().required('Обязательное поле'),
                            result_price: Yup.number().required(),
                        })
                    }
                    onSubmit={(values, {setErrors}) => {
                        const isDuplicate = listHomeEducation.some(m => (m.type_menu.id.toString() === values.type_menu_id.toString() || m.menu.id.toString() === values.type_plane_menu_id.toString()) && areIntervalsOverlapping(m, {date_begin: dateBegin,
                            date_end: dateEnd}));

                        if (isDuplicate) {
                            setErrors({type_menu_id: 'Такое домашнее обучение существует. Выберите другой тип меню или тип планового меню или совпадает период даты'});
                            setErrors({type_plane_menu_id: 'Такое домашнее обучение существует. Выберите другой тип меню или тип планового меню или совпадает период даты'});
                        } else {
                            setListHomeEducation([...listHomeEducation,
                                {
                                    menu: listMenu.find(b => b.id.toString() === selectListMenu.value.toString()),
                                    type_menu: typeMenu.find(b => b.id.toString() === values.type_menu_id.toString()),
                                    date_begin: moment(dateBegin).format('YYYY-MM-DD'),
                                    date_end: dateEnd ? moment(dateBegin).format('YYYY-MM-DD') : '',
                                    count_day: countDay,
                                    price_in_day: priceDay,
                                    result_price: values.result_price,
                                    date_receive: moment(dateReceive).format('YYYY-MM-DD')
                                }
                            ])
                            setAddHomeEducation(false)
                        }
                    }}
                >
                    {
                        ({errors, touched}) => (
                            <Form>
                                <Row>
                                    <Col>
                                        <label className="w-100">Дата получения:
                                        <Flatpickr
                                            className="form-control border-0 dash-filter-picker shadow"
                                            value={dateReceive}
                                            options={{
                                                locale: Russian, dateFormat: "d M Y"
                                            }}
                                            onClose={(date) => {
                                                setDateReceive(date[0])
                                            }}
                                        />
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <label className="w-100">Дата с:
                                            <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                value={dateBegin}
                                                options={{
                                                    locale: Russian, dateFormat: "d M Y"
                                                }}
                                                onClose={(date) => {
                                                    setDateBegin(date[0])
                                                }}
                                            />
                                            </label>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div>
                                            <label className="w-100">Дата по:
                                            <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                value={dateEnd}
                                                options={{
                                                    locale: Russian, dateFormat: "d M Y"
                                                }}
                                                onClose={(date) => {
                                                    setDateEnd(date[0])
                                                }}
                                            />
                                            </label>
                                            {errors.date_end && touched.date_end ? (
                                                <div className="text-danger">{errors.date_end.toString()}</div>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <div>
                                            <label className="w-100">Тип меню:
                                            <Select
                                            options={typeMenu?.map(v => ({value: v.id, label: v.name}))}
                                            value={selectTypeMenu}
                                            onChange={(change: any) => {
                                                setSelectTypeMenu({value: change.value, label: change.label})
                                            }}
                                            styles={customStyles}
                                            placeholder="Поиск"
                                            name="type_menu_id"
                                        />
                                            </label>
                                            {errors.type_menu_id && touched.type_menu_id ? (
                                                <div className="text-danger">{errors.type_menu_id.toString()}</div>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <div>
                                            <label className="w-100">Тип планового меню:
                                            <Select
                                                options={listMenu?.map(v => ({value: v.id, label: v.name}))}
                                                value={selectListMenu}
                                                onChange={(change: any) => {
                                                    setSelectListMenu({value: change.value, label: change.label})
                                                }}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                                name="type_plane_menu_id"
                                            />
                                            </label>
                                            {errors.type_plane_menu_id && touched.type_plane_menu_id ? (
                                                <div className="text-danger">{errors.type_plane_menu_id.toString()}</div>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <label className="w-100">Количество дней:
                                        <input name="count_day" className="form-control" value={countDay}
                                               onChange={event => setCountDay(+event.target.value)}/>
                                        </label>
                                    </Col>
                                    <Col>
                                        <label className="w-100">Сумма в день:
                                        <input name="price_in_day" className="form-control" value={priceDay}
                                               onChange={event => setPriceDay(+event.target.value)}/>
                                        </label>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <label className="w-100">Сумма заказа:
                                            <Field name="result_price" className={`form-control ${errors.result_price && touched.result_price && "border border-2 border-danger"}`} type="number"/>
                                        </label>
                                    </Col>
                                </Row>
                                <div>
                                    <div className="hstack gap-2">
                                        <button type="submit" className="btn btn-primary mt-1">Добавить
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddHomeEducation;
