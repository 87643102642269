import React, {FC} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import {setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../hooks/redux";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import AxiosClient from "../../../api/AxiosClient";
import {setShowNotification} from "../../../store/reducers/widgets/WidgetsSlice";
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface ModalAddShiftProps {
    addShift: boolean,
    setAddShift: (value: boolean) => void,
    setShifts: (value: any[]) => void
}

const ModalAddShift: FC<ModalAddShiftProps> = ({setShifts, addShift, setAddShift}) => {
    const dispatch = useAppDispatch();
    useEscapeKey(() => dispatch(setModalAdd(false)), addShift, "ModalAddShift");

    return (
        <>
            <Modal id="ModalAddShift" isOpen={addShift}
                   toggle={() => setAddShift(!addShift)}
                   modalClassName="zoomIn" tabIndex={-1} centered>
                <ModalHeader toggle={() => setAddShift(!addShift)}
                             className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Добавление смены
                </ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={{name: ''}}
                        onSubmit={(values) => {
                            dispatch(setIsSend(true))
                            AxiosClient.post('/shift/create', {name: values.name})
                                .then(() => {
                                    dispatch(setIsSend(false))
                                    AxiosClient.get('/shift')
                                        .then(r => {
                                            setShifts(r.data)
                                        })
                                    setAddShift(false)
                                    dispatch(setShowNotification({type: "success", message: 'Смена добавлена', show: true}))
                                })
                                .catch(() => {
                                    dispatch(setIsSend(false))
                                    dispatch(setShowNotification({type: "error", message: 'Ошибка при добавлении смены', show: true}))
                                })
                        }}
                    >
                        {
                            ({errors, touched}) => (
                                <Form>
                                    <Row className={"mb-2"}>
                                        <Col>
                                            <label className="w-100">Наименование:
                                            <Field name="name"
                                                   className={`form-control ${errors.name && touched.name && "border border-2 border-danger"}`}/>
                                            </label>
                                        </Col>
                                    </Row>
                                    <SubmitWithLoading text={'Добавить'}/>
                                </Form>
                            )
                        }
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    );
};

export default ModalAddShift;
