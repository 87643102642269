import React, {FC, useState} from 'react';
import DataTable from "react-data-table-component";
import {Col, Row} from "reactstrap";
import ModalAddViewMenu from "./ModalAddViewMenu";
import moment from "moment/moment";
import ModalUpdateViewMenu from "./ModalUpdateViewMenu";

interface IListViewMenu {
    listViewMenu: any[],
    setListViewMenu: (value: any[]) => void,
    listMenu: any[],

}

const ListViewMenu: FC<IListViewMenu> = ({setListViewMenu, listViewMenu, listMenu}) => {

    const [addViewMenu, setAddViewMenu] = useState(false);
    const [selectViewMenu, setSelectViewMenu] = useState<any | null>(null);
    const [updateViewMenu, setUpdateViewMenu] = useState(false);

    function removeMenu(removeMenu: any) {
        setListViewMenu(
            listViewMenu.filter((menu: any, index) => index !== removeMenu.index)
        )
    }

    const columns = [
        {
            id: 'index',
            cell: (row: any) => <span className='accessibility_size_table'> {row.index}</span>,
            omit: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Тип планового меню</span>,
            cell: (row: any) => <span className='accessibility_size_table'> {row.name}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Дата с</span>,
            cell: (row: any) => <span className='accessibility_size_table'> {moment(row.date_begin).format('DD.MM.Y')}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Дата по</span>,
            cell: (row: any) => row.date_end ?
                <span className='accessibility_size_table'> {moment(row.date_end).format('DD.MM.Y')}</span> : '',
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'></span>,
            cell: (row: any) => <i className="bx bx-trash cursor-pointer fs-3" onClick={() => removeMenu(row)}></i>,
            width: '50px'
        }
    ]

    return (
        <div>
            <DataTable
                columns={columns}
                data={listViewMenu.map((el: any, index) => ({...el, index}))}
                keyField={'index'}
                noDataComponent={'Нет данных'}
                highlightOnHover
                customStyles={{
                    rows: {
                        highlightOnHoverStyle: {
                            backgroundColor: "#ffebb5"
                        }
                    }
                }}
                onRowClicked={(row) => {
                    setSelectViewMenu(row)
                    setUpdateViewMenu(true)
                }}
            />
            <Row>
                <Col xxl={6} xl={6} lg={6} sm={6}>
                    <div className="hstack gap-2">
                        <button type="button" className="btn btn-primary mt-3" id="addNewProject" onClick={() => setAddViewMenu(true)}>Добавить</button>
                    </div>
                </Col>
            </Row>
            <ModalUpdateViewMenu updateViewMenu={updateViewMenu} setUpdateViewMenu={value => setUpdateViewMenu(value)} selectViewMenu={selectViewMenu} listViewMenu={listViewMenu} setListViewMenu={value => setListViewMenu(value)} listMenu={listMenu}/>
            <ModalAddViewMenu setListViewMenu={value => setListViewMenu(value)} listMenu={listMenu} listViewMenu={listViewMenu} addViewMenu={addViewMenu} setAddViewMenu={value => setAddViewMenu(value)}/>
        </div>
    );
};

export default ListViewMenu;
