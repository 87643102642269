import React, {FC, useState} from 'react';
import {Card, CardBody, Col, Input, Row} from "reactstrap";
import DataTable from "react-data-table-component";
import {Form, Formik} from "formik";
import AxiosClient from "../../../api/AxiosClient";
import {useAppDispatch} from "../../../hooks/redux";
import {setShowNotification} from "../../../store/reducers/widgets/WidgetsSlice";

interface ITableConfirmationImportTeacher {
    listTeacherImport: any[],
    setListTeacherImport(value: any[]) : any
}

const TableConfirmationImportTeacher: FC<ITableConfirmationImportTeacher> = ({listTeacherImport, setListTeacherImport}) => {
    const [isSync, setIsSync] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(100);

    const dispatch = useAppDispatch()
    // Определение обработчиков для изменения страницы и количества строк на странице
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (newRowsPerPage: number) => {
        setRowsPerPage(newRowsPerPage);
    };

    const toggleSelectAll = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        const updatedList = listTeacherImport.map(item => {
            return { ...item, isAdd: newSelectAll };
        });
        setListTeacherImport(updatedList);
    };

    const columns = [
        {
            id: "name",
            name: <span className='font-weight-bold accessibility_size_table'>ФИО</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.nameTeacher}</span>,
            wrap: true,
        },
        {
            id: "listFindClass",
            name: <span className='font-weight-bold accessibility_size_table'>Классы на добавление</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.listFindClass.map((value: any)=>value.name).join(", ")}</span>,
            wrap: true
        },
        {
            id: "listNotClass",
            name: <span className='font-weight-bold accessibility_size_table'>Не найденные классы</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.listNotClass.join(", ")}</span>,
            wrap: true
        },
        {
            id: "annotation",
            name: <span className='font-weight-bold accessibility_size_table'>Примечание</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.annotation}</span>,
            wrap: true,
        },
        {
            id: "isAdd",
            name: <Input type="checkbox" className='fs-18' checked={selectAll} onChange={toggleSelectAll}/>,
            cell: (row: any, index: number) =>
                <Input type="checkbox" checked={row.isAdd} className='fs-18'
                       onChange={()=> {
                           const updatedList = [...listTeacherImport];
                           updatedList[index + (((currentPage - 1) * rowsPerPage))].isAdd =  !updatedList[index + (((currentPage - 1) * rowsPerPage))].isAdd;
                           setListTeacherImport(updatedList);
                       }}
                />,
            'width' : '70px'
        },
    ]
    return (
        <Formik
            enableReinitialize
            initialValues={
                {
                    school: '',
                    build: '',
                }
            }
            onSubmit={() => {
                AxiosClient.post('/schools/import_teacher_class', {listTeacherImport, isSync})
                    .then((r) => {
                        dispatch(setShowNotification({type: "success", message: "Готово, данные загружены!", show: true}))
                    })
                    .catch((error) => {
                        console.log("error");
                        dispatch(setShowNotification({type: "error", message: "Ошибка, данные не загружены!", show: true}))
                    })
            }}>
            {
                () => (
                    <Form>
                        <Row>
                            <Col>
                                <Card className="mt-3">
                                    <CardBody>
                                        <DataTable
                                            columns={columns}
                                            data={listTeacherImport}
                                            noDataComponent={'Нет данных'}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handleRowsPerPageChange}
                                            pagination
                                            paginationRowsPerPageOptions={[100, 500, 1000]}
                                            paginationPerPage={100}
                                            paginationComponentOptions={
                                                {
                                                    rowsPerPageText: 'Видно учеников:',
                                                    rangeSeparatorText: 'из'
                                                }
                                            }
                                            highlightOnHover
                                            customStyles={{
                                                rows: {
                                                    highlightOnHoverStyle: {
                                                        backgroundColor: "#ffebb5"
                                                    },
                                                },
                                                expanderRow: {
                                                    style: {
                                                        backgroundColor: "#ffebb5",
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px'
                                                    },
                                                },
                                            }}
                                        />
                                        <label>
                                        <Input
                                            type="checkbox"
                                            name="toggle"
                                            className="fs-14"
                                            checked={isSync}
                                            onChange={(e) => setIsSync(e.target.checked)}
                                        />
                                        &nbsp;&nbsp;Удалить старые классы&nbsp;
                                        </label>
                                        <div className="hstack gap-2" >
                                            <button type="submit" className="btn btn-primary" id="addNewProject">Добавить</button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                )
            }
        </Formik>
    );
};

export default TableConfirmationImportTeacher;
