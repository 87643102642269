import React, {FC, useEffect, useState} from 'react';
import {Card, CardBody, Col, Input, Row} from "reactstrap";
import {Form, Formik} from "formik";
import DataTable from "react-data-table-component";
import AxiosClient from "../../../api/AxiosClient";
import {customStyles} from "../../settings";
import Select from "react-select";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {setIsSend} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../hooks/redux";
import {setShowNotification} from "../../../store/reducers/widgets/WidgetsSlice";
interface ITableConfirmationImportStudentsCashFlow {
    listImport: any,
    setListImport(value: any) : any
}

const TableConfirmationImportStudentsCashFlow: FC<ITableConfirmationImportStudentsCashFlow>  = ({listImport, setListImport}) => {
    const dispatch = useAppDispatch();
    const [selectAll, setSelectAll] = useState(false);
    const [typeMenu, setTypeMenu] = useState<any[]>([]);
    const [ageCategories, setAgeCategories] = useState<any[]>([]);
    const [listImportTable, setListImportTable] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(100);

    // Определение обработчиков для изменения страницы и количества строк на странице
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (newRowsPerPage: number) => {
        setRowsPerPage(newRowsPerPage);
    };

    const toggleSelectAll = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        const updatedList = listImportTable.map(item => {
            if (!item.idClass){
                return item;
            }
            return { ...item, isAdd: newSelectAll };
        });
        setListImportTable(updatedList);
    };

    useEffect(() => {
        setListImportTable(listImport['listStudentsReturn']);
        AxiosClient.get('/type_menu')
            .then((r) => {
                setTypeMenu(r.data)
            })
            .catch(() => console.log('error'))
        AxiosClient.get('/age_categories')
            .then((r) => {
                setAgeCategories(r.data)
            })
            .catch(() => console.log('error'))
    }, [])

    const columns = [
        {
            id: "FIO",
            name: <span className='font-weight-bold accessibility_size_table'>ФИО ребенка</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.FIO}</span>,
            wrap: true,
        },
        {
            id: "FIOChildrenSelect",
            name: <span className='font-weight-bold accessibility_size_table'>ФИО в базе</span>,
            cell: (row: any, index: number) =>
                <Select
                    options={listImport['studentsAll']?.length > 0 ? [{
                        value: '',
                        label: 'Не выбрано'
                    }, ...listImport['studentsAll'].map((v: any) => ({ value: v.id, label: v.last_name+' '+v.name+' '+v.middle_name }))] : [{ value: '', label: 'Не выбрано' }]}
                value={row.FIOChildrenSelect?.id ? {value: row.FIOChildrenSelect.id, label: row.FIOChildrenSelect.last_name+' '+row.FIOChildrenSelect.name+' '+row.FIOChildrenSelect.middle_name} :  {value: '', label: 'Не выбрано'}}
                    onChange={(v : any)=> {
                        const updatedList = [...listImportTable];
                        updatedList[index + (((currentPage - 1) * rowsPerPage))].FIOChildrenSelect = listImport['studentsAll'].find((e: any) => e.id === v.value);
                        setListImportTable(updatedList);
                    }}
                    styles={customStyles}
                    className='w-100 accessibility_size_table'
                    placeholder="Поиск"
                    name="school"
                />,
            wrap: true,
            'width': '270px'
        },
        {
            id: "class",
            name: <span className='font-weight-bold accessibility_size_table'>Класс</span>,
            cell: (row: any, index: number) =>
                <Select
                    options={listImport['classAll']?.length > 0 ? [{
                        value: '',
                        label: 'Не выбрано'
                    }, ...listImport['classAll'].map((v: any) => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                    value={listImport['classAll'].find((e: any) => e.id === row.idClass) ? {value: listImport['classAll'].find((e: any) => e.id === row.idClass).id, label: listImport['classAll'].find((e: any) => e.id === row.idClass).name} : { value: '', label: 'Не выбрано' }}
                    onChange={(v : any)=> {
                        const updatedList = [...listImportTable];
                        updatedList[index + (((currentPage - 1) * rowsPerPage))].idClass = v.value;
                        updatedList[index + (((currentPage - 1) * rowsPerPage))].class = v.label;
                        updatedList[index + (((currentPage - 1) * rowsPerPage))].isAdd = row.idClass===''? false : true;
                        setListImportTable(updatedList);
                    }}
                    styles={customStyles}
                    className='w-100 accessibility_size_table'
                    placeholder="Поиск"
                    name="school"
                />,
            wrap: true,
        },
        {
            id: "typeMenu",
            name: <span className='font-weight-bold accessibility_size_table'>Тип меню</span>,
            selector: (row: any) => row.typeMenu,
            cell: (row: any, index: number) =>
                <Select
                    options={typeMenu?.length > 0 ? [
                        ...typeMenu.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                    value={typeMenu.find(e => e.id === row.typeMenu) ? {value: typeMenu.find(e => e.id === row.typeMenu).id, label: typeMenu.find(e => e.id === row.typeMenu).name} : { value: '', label: 'Не выбрано' }}
                    onChange={(v : any)=> {
                        const updatedList = [...listImportTable];
                        updatedList[index + (((currentPage - 1) * rowsPerPage))].typeMenu = v.value;
                        setListImportTable(updatedList);
                    }}
                    styles={customStyles}
                    className='w-100 accessibility_size_table'
                    placeholder="Поиск"
                    name="school"
                />,
            wrap: true,
        },
        {
            id: "ageCategory",
            name: <span className='font-weight-bold accessibility_size_table'>Возраст</span>,
            selector: (row: any) => row.ageCategory,
            cell: (row: any, index: number) =>
                <Select
                    options={ageCategories?.length > 0 ? [
                        ...ageCategories.map((v: any) => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                    value={ageCategories.find(e => e.id === row.ageCategory) ? {value: ageCategories.find(e => e.id === row.ageCategory).id, label: ageCategories.find(e => e.id === row.ageCategory).name} : { value: '', label: 'Не выбрано' }}
                    onChange={(v : any)=> {
                        const updatedList = [...listImportTable];
                        updatedList[index + (((currentPage - 1) * rowsPerPage))].ageCategory = v.value;
                        setListImportTable(updatedList);
                    }}
                    styles={customStyles}
                    className='w-100 accessibility_size_table'
                    placeholder="Поиск"
                    name="school"
                />,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Новый</span>,
            selector: (row: any) => row.isAdd,
            cell: (row: any, index: number) =>
                <Input type="checkbox" className='fs-18'
                       checked={row.isNewPersonalAccount}
                       disabled={row.idClass === ''}
                       onChange={()=> {
                           const updatedList = [...listImportTable];
                           updatedList[index + (((currentPage - 1) * rowsPerPage))].isNewPersonalAccount =  !updatedList[index + (((currentPage - 1) * rowsPerPage))].isNewPersonalAccount;
                           setListImportTable(updatedList);
                       }}

                />,
            'width' : '80px'
        },
        {
            id: "isAdd",
            name: <Input type="checkbox" className='fs-18' checked={selectAll} onChange={toggleSelectAll}/>,
            selector: (row: any) => row.isAdd,
            cell: (row: any, index: number) =>
                <Input type="checkbox" className='fs-18'
                       checked={row.isAdd}
                       disabled={row.idClass === ''}
                       onChange={()=> {
                           const updatedList = [...listImportTable];
                           updatedList[index + (((currentPage - 1) * rowsPerPage))].isAdd =  !updatedList[index + (((currentPage - 1) * rowsPerPage))].isAdd;
                           setListImportTable(updatedList);
                       }}

                />,
            'width' : '70px'
        },
    ]

    return (
        <Formik
            enableReinitialize
            initialValues={
                {
                    school: '',
                    build: '',
                }
            }
            onSubmit={() => {
                dispatch(setIsSend(true))
                AxiosClient.post('/students/import_bd_students_cash_flow', {listImportTable, 'school': listImport['school'], 'build': listImport['build'], 'catering_organizer_id': listImport['catering_organizer_id'], 'data_balance': listImport['data_balance']})
                    .then(() => {
                        dispatch(setShowNotification({type: "success", message: "Готово, данные загружены!", show: true}))
                        dispatch(setIsSend(false));
                    })
                    .catch(() => {
                        console.log("error");
                        dispatch(setShowNotification({type: "error", message: "Ошибка, данные не загружены!", show: true}))
                        dispatch(setIsSend(false));
                    })
            }}>
            {
                () => (
                    <Form>
                        <Row>
                            <Col>
                                <Card className="mt-3">
                                    <CardBody>
                                        <DataTable
                                            columns={columns}
                                            data={listImportTable}
                                            noDataComponent={'Нет данных'}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handleRowsPerPageChange}
                                            pagination
                                            paginationRowsPerPageOptions={[100, 500, 1000]}
                                            paginationPerPage={rowsPerPage}
                                            paginationComponentOptions={
                                                {
                                                    rowsPerPageText: 'Видно учеников:',
                                                    rangeSeparatorText: 'из'
                                                }
                                            }
                                            highlightOnHover
                                            customStyles={{
                                                rows: {
                                                    highlightOnHoverStyle: {
                                                        backgroundColor: "#ffebb5"
                                                    },
                                                },
                                                expanderRow: {
                                                    style: {
                                                        backgroundColor: "#ffebb5",
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px'
                                                    },
                                                },
                                            }}
                                        />
                                        <div className="hstack gap-2" >
                                            <SubmitWithLoading text={"Добавить"}/>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                )
            }
        </Formik>
    );
};

export default TableConfirmationImportStudentsCashFlow;
