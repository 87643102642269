import React, {FC, useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import AxiosClient from "../../../api/AxiosClient";
import {Col, Collapse, Row, Table} from "reactstrap";
import classNames from "classnames";
import {setGroupModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {Field} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import moment from "moment/moment";

interface ITableChildren {
    role: any,
    deti: any,
    setDeti(value: any): void;
    handleFormChange: () => void;

    setDeti(value: any): void
}

const TableChildren: FC<ITableChildren> = ({role, deti, setDeti,handleFormChange}) => {
    const [expandedRow, setExpandedRow] = useState<string | null>(null);
    const toggleRow = (uid: string) => {
        setExpandedRow(expandedRow === uid ? null : uid);
    };

    const handleChange = (uid: string, field: string, value: any) => {
        const updatedDeti = deti.map((child: any) =>
            child.uid === uid ? {...child, [field]: value} : child
        );
        if (field === 'temperature') {
            const now = new Date();
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const currentTime = `${hours}:${minutes}`;

            updatedDeti.forEach((child: any) => {
                if (child.uid === uid) {
                    child.time = currentTime;
                }
            });
        }
        setDeti(updatedDeti);
        handleFormChange();
    };

    return (
        <div className="accordion accordion-border-box custom-scroll-news" style={{height: '38rem', overflow: 'auto'}}>
            {deti.map((child: any) => (
                <div className="accordion-item shadow position-relative" key={child.uid}>
                    <div
                        className={classNames('position-absolute rounded-start h-100', 'bg-primary')}
                        style={{width: 5}}
                    ></div>
                    <h2 className="accordion-header">
                        <button
                            className={classNames(
                                "accordion-button",
                                "fw-medium",
                                {"bg-primary bg-opacity-10 ": child.time},
                                {collapsed: expandedRow !== child.uid}
                            )}
                            type="button"
                            onClick={() => toggleRow(child.uid)}
                            style={{cursor: "pointer"}}
                        >
                            <span className="accessibility_size_table">{child.name}</span>
                        </button>
                    </h2>
                    <Collapse isOpen={expandedRow === child.uid} className="accordion-collapse">
                        <Col className="mx-3">
                            <Row>
                                <label>
                                    Кто привел
                                    <Field as="select" className="form-select shadow"
                                           value={child.parent}
                                           onChange={(e: {
                                               target: { value: any; };
                                           }) => handleChange(child.uid, 'parent', e.target.value)}
                                           name="parent">
                                        {role.map((group: any, index: number) => {
                                            return <option key={'role' + index}
                                                           value={group}>{group}</option>
                                        })}
                                    </Field>
                                </label>
                            </Row>
                            <Row>
                                <Col xs="auto" className="me-2">
                                    <label className="form-label ">
                                        Температура
                                        <input type="text" className="form-control custom-input"
                                               value={child.temperature} style={{width: '160px'}}
                                               onChange={(e) => handleChange(child.uid, 'temperature', e.target.value)}/>
                                    </label>
                                </Col>
                                <Col xs="auto" className="me-2">
                                    <label className="form-label ">
                                        Время замера
                                        <div className="input-group">
                                        <Flatpickr
                                            className="form-control border-1  "
                                            value={child.time}
                                            name="time"
                                            options={{
                                                locale: Russian,
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i",
                                                time_24hr: true,
                                                disableMobile: true,
                                                mode: 'single'
                                            }}
                                            onChange={(selectedDates) => {
                                                const selectedTime = selectedDates[0] ? moment(selectedDates[0]).format("HH:mm") : '';
                                                handleChange(child.uid, 'time', selectedTime);
                                            }}
                                            style={{width: '120px'}}
                                        />
                                        <button
                                            type="button"
                                            className="btn "
                                            style={{ borderColor: 'lightgray' }}
                                            onClick={() => {
                                                handleChange(child.uid, 'time', '');
                                            }}
                                            title="Очистить"
                                        >
                                            &times;
                                        </button>
                                        </div>
                                    </label>
                                    <button
                                        type="button"
                                        className="btn btn-primary ms-2"
                                        onClick={() => {
                                            const currentTime = moment().format("HH:mm");
                                            handleChange(child.uid, 'time', currentTime);
                                        }}
                                    >
                                        ТВ
                                    </button>
                                </Col>
                            </Row>
                            <Row>
                                <label className="form-label">
                                    Описание осмотра горла
                                    <input type="text" className="form-control custom-input" name="throat"
                                           value={child.throat}
                                           onChange={(e) => handleChange(child.uid, 'throat', e.target.value)}/>
                                </label>
                            </Row>
                            <Row>
                                <label className="form-label">
                                    Описание осмотра кожи
                                    <input type="text" className="form-control custom-input" name="skin"
                                           value={child.skin}
                                           onChange={(e) => handleChange(child.uid, 'skin', e.target.value)}/>
                                </label>
                            </Row>
                            <Row>
                                <label className="form-label">
                                    Информация от родителей
                                    <textarea rows={3} className="form-control" name="information"
                                              value={child.information}
                                              onChange={(e) => handleChange(child.uid, 'information', e.target.value)}/>
                                </label>
                            </Row>
                            <Row>
                                <label className="form-label">
                                    Симптомы ОРВИ:
                                    <Field as="select" className="form-select shadow" value={child.orvi}
                                           onChange={(e: {
                                               target: { value: any; };
                                           }) => handleChange(child.uid, 'orvi', e.target.value)}
                                           name="orvi">
                                        <option key={0} value="Есть">Есть</option>
                                        <option key={1} value="Отсутствуют">Отсутствуют</option>
                                    </Field>
                                </label>
                            </Row>
                            <Row>
                                <label className="form-label">
                                    Допущен:
                                    <Field as="select" value={child.conclusion}
                                           className={classNames("form-select shadow", child.conclusion === "Допущен" ? "text-success" : "text-danger")}
                                           onChange={(e: {
                                               target: { value: any; };
                                           }) => handleChange(child.uid, 'conclusion', e.target.value)}
                                           name="conclusion">
                                        <option key={0} value="Допущен" className={"text-black"}>Допущен</option>
                                        <option key={1} value="Не допущен" className={"text-black"}>Не допущен</option>
                                    </Field>
                                </label>
                            </Row>
                        </Col>
                    </Collapse>
                </div>
            ))}
        </div>
    );
};

export default TableChildren;
