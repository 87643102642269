import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IUser} from "../../../models/IUser";
import {ICity} from "../../../models/ICity";
import {IOrganization} from "../../../models/IOrganization";
import {ITerm} from "../../../models/ITerm";
import {IPosition} from "../../../models/IPosition";
import {ICountry} from "../../../models/ICountry";
import {IRegion} from "../../../models/IRegion";
import {IPage} from "../../../models/IPage";
import {IGroupPage} from "../../../models/IGroupPage";
import {IHeaderPage} from "../../../models/IHeaderPage";
import {IPrefixTable} from "../../../models/IPrefixTable";

interface IAdminState {
    users: IUser[],
    cities: ICity[],
    organizations: IOrganization[],
    terms: ITerm[],
    positions: IPosition[],
    countries: ICountry[],
    regions: IRegion[],
    pages: IPage[],
    groupPages: IGroupPage[],
    headerPages: IHeaderPage[],
    prefixTable: IPrefixTable[],
    selectUser: IUser | null,
    selectCity: ICity | null,
    selectPage: IPage | null,
    selectCountry: ICountry | null,
    selectOrganization: IOrganization | null,
    selectPosition: IPosition | null,
    selectTerm: ITerm | null,
    isLoading: boolean,
    isError: string
}

const initialState: IAdminState = {
    users: [],
    cities: [],
    organizations: [],
    terms: [],
    positions: [],
    countries: [],
    regions: [],
    pages: [],
    groupPages: [],
    headerPages: [],
    prefixTable: [],
    selectUser: null,
    selectCity: null,
    selectPage: null,
    selectCountry: null,
    selectOrganization: null,
    selectPosition: null,
    selectTerm: null,
    isLoading: false,
    isError: ""
}

export const AdminSlice = createSlice({
    name: "adminSettings",
    initialState,
    reducers: {
        dataLoading (state){
            state.isLoading = true;
        },
        usersLoadingSuccess(state, action: PayloadAction<{response: IUser[], search?: string}>){
            state.isLoading = false;
            state.isError = "";
            if (action.payload.search === "name"){
                state.users = action.payload.response;
            }
            else {
                state.users = [...state.users, ...action.payload.response];
            }
        },
        usersLoadingSuccessPage(state, action: PayloadAction<{ response: IUser[], search?: string }>) {
            state.isLoading = false;
            state.isError = "";
            if (action.payload.search === "name" || action.payload.search === "page") {
                state.users = action.payload.response;
            }
        },
        citiesLoadingSuccess(state, action: PayloadAction<ICity[]>){
            state.isLoading = false;
            state.isError = "";
            state.cities = action.payload;
        },
        organizationsLoadingSuccess(state, action: PayloadAction<IOrganization[]>){
            state.isLoading = false;
            state.isError = "";
            state.organizations = action.payload;
        },
        termsLoadingSuccess(state, action: PayloadAction<ITerm[]>){
            state.isLoading = false;
            state.isError = "";
            state.terms = action.payload;
        },
        positionsLoadingSuccess(state, action: PayloadAction<IPosition[]>){
            state.isLoading = false;
            state.isError = "";
            state.positions = action.payload;
        },
        countriesLoadingSuccess(state, action: PayloadAction<ICountry[]>){
            state.isLoading = false;
            state.isError = "";
            state.countries = action.payload;
        },
        regionsLoadingSuccess(state, action: PayloadAction<IRegion[]>){
            state.isLoading = false;
            state.isError = "";
            state.regions = action.payload;
        },
        pagesLoadingSuccess(state, action: PayloadAction<IPage[]>){
            state.isLoading = false;
            state.isError = "";
            state.pages = action.payload;
        },
        groupPagesLoadingSuccess(state, action: PayloadAction<IGroupPage[]>){
            state.isLoading = false;
            state.isError = "";
            state.groupPages = action.payload;
        },
        headerPagesLoadingSuccess(state, action: PayloadAction<IHeaderPage[]>){
            state.isLoading = false;
            state.isError = "";
            state.headerPages = action.payload;
        },
        prefixTableLoadingSuccess(state, action: PayloadAction<IPrefixTable[]>){
            state.isLoading = false;
            state.isError = "";
            state.prefixTable = action.payload;
        },
        dataLoadingError(state, action: PayloadAction<string> ){
            state.isLoading = false;
            state.isError = action.payload
        },
        setSelectUser (state, action: PayloadAction<IUser>){
            state.isLoading = false;
            state.isError = "";
            state.selectUser = action.payload
        },
        setSelectCity(state, action: PayloadAction<ICity>){
            state.isLoading = false;
            state.isError = "";
            state.selectCity = action.payload;
        },
        setSelectPage(state, action: PayloadAction<IPage>){
            state.isLoading = false;
            state.isError = "";
            state.selectPage = action.payload;
        },
        setSelectCountry(state, action: PayloadAction<ICountry>){
            state.isLoading = false;
            state.isError = "";
            state.selectCountry = action.payload;
        },
        setSelectOrganization(state, action: PayloadAction<IOrganization>){
            state.isLoading = false;
            state.isError = "";
            state.selectOrganization = action.payload;
        },
        setSelectPosition(state, action: PayloadAction<IPosition>){
            state.isLoading = false;
            state.isError = "";
            state.selectPosition = action.payload;
        },
        setSelectTerm(state, action: PayloadAction<ITerm>){
            state.isLoading = false;
            state.isError = "";
            state.selectTerm = action.payload;
        }
    }
})
export default AdminSlice.reducer