import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

interface ITrainingEvent {
    id: number;
    date: Date;
    time?: string;
    topic: string;
    location: string;
    hours: number;
    completed: boolean;
}

interface ICertificationEvent {
    id: number;
    date: Date;
    time: string;
    program: string;
    location: string;
    completed: boolean;
    lastCertification: Date;
}

interface EducationState {
    selectedDate: Date;
    isLoading: boolean;
    error: string | null;
    trainingEvents: ITrainingEvent[];
    certificationEvents: ICertificationEvent[];
}

const initialState: EducationState = {
    selectedDate: moment().startOf('month').toDate(),
    isLoading: false,
    error: null,
    trainingEvents: [
        { id: 1, date: new Date(2025, 3, 10), time: "10:00", topic: "Тест", location: "Кабинет 34", hours: 2, completed: false },
        { id: 2, date: new Date(2025, 3, 14), time: "10:00", topic: "Тест React", location: "Офис 12", hours: 2, completed: false },
        { id: 3, date: new Date(2025, 3, 12), time: "10:00", topic: "Тест TypeScript", location: "Офис", hours: 5, completed: false },
        { id: 4, date: new Date(2025, 3, 11), time: "10:00", topic: "Node.js", location: "Офис 1", hours: 0, completed: false },
        { id: 5, date: new Date(2025, 3, 14), time: "10:00", topic: "Тест 2", location: "Офис 2", hours: 3, completed: false },
        { id: 6, date: new Date(2025, 2, 2), time: "10:00", topic: "ТестDevOps ", location: "Офис 3", hours: 7, completed: false },
        { id: 7, date: new Date(), time: "10:00", topic: "Тест", location: "Офис", hours: 2, completed: false },
    ],
    certificationEvents: [
        { id: 1, date: new Date(2025, 3, 20), program: "Сертификация React", location: "Офис 2", time: "14:00", lastCertification: new Date(2024, 2, 20), completed: false },
        { id: 2, date: new Date(2025, 3, 21), program: "Сертификация 2", location: "Офис 1", time: "14:00", lastCertification: new Date(2024, 2, 21), completed: false },
        { id: 3, date: new Date(2025, 3, 22), program: "Сертификация TypeScript", location: "Офис 3", time: "15:00", lastCertification: new Date(2024, 1, 15), completed: false },
        { id: 4, date: new Date(2025, 3, 25), program: "Сертификация Node.js", location: "Офис 4", time: "09:30", lastCertification: new Date(2024, 0, 10), completed: false },
        { id: 5, date: new Date(2025, 3, 28), program: "Сертификация DevOps", location: "Офис 5", time: "11:00", lastCertification: new Date(2023, 11, 5), completed: false },],
};

export const EducationSlice = createSlice({
    name: 'education',
    initialState,
    reducers: {
        setDate: (state, action: PayloadAction<Date>) => {
            state.selectedDate = action.payload;
        },
        setTrainingEvents: (state, action: PayloadAction<ITrainingEvent[]>) => {
            state.trainingEvents = action.payload;
        },
        toggleTrainingCompleted: (state, action: PayloadAction<number>) => {
            const id = action.payload;
            const event = state.trainingEvents.find(event => event.id === id);
            if (event) {
                event.completed = !event.completed;
            }
        },
        setCertificationEvents: (state, action: PayloadAction<ICertificationEvent[]>) => {
            state.certificationEvents = action.payload;
        },
        toggleCertificationCompleted: (state, action: PayloadAction<number>) => {
            const id = action.payload;
            const event = state.certificationEvents.find(event => event.id === id);
            if (event) {
                event.completed = !event.completed;
            }
        },
    },
});

export const { setDate, setTrainingEvents, toggleTrainingCompleted, setCertificationEvents, toggleCertificationCompleted } = EducationSlice.actions;
export default EducationSlice.reducer;