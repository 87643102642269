// ModalAddPosition.tsx
import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { setIsSend, setModalAdd } from "../../../store/reducers/widgets/WidgetsSlice";
import { Modal, ModalBody, ModalHeader, Label, Row, Col } from "reactstrap";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import AxiosClient from "../../../api/AxiosClient";

interface IModalAddPosition {
    updateData: any;
    onSuccess: () => void;
}

const ModalAddPosition: FC<IModalAddPosition> = ({ updateData, onSuccess }) => {
    const dispatch = useAppDispatch();
    const { modalAdd } = useAppSelector(state => state.WidgetReducer);

    const validationSchema = Yup.object({
        position: Yup.string()
            .required('Введите должность')
            .min(2, 'Должность должна содержать минимум 2 символа')
    });

    const handleSubmit = (values: { position: string }, { resetForm }: any) => {
        dispatch(setIsSend(true));

        const request = updateData
            ? AxiosClient.post(`/positions/update/${updateData.id}`, { position: values.position })
            : AxiosClient.post("/positions/add", { position: values.position });

        request
            .then((response) => {
                if (response.status === 200) {
                    dispatch(setModalAdd(false));
                    onSuccess();
                    if (!updateData) resetForm();
                }
            })
            .catch((error) => {
                console.error('Ошибка при сохранении должности:', error);
            })
            .finally(() => {
                dispatch(setIsSend(false));
            });
    };

    return (
        <Modal
            id="ModalAddPosition"
            isOpen={modalAdd}
            toggle={() => dispatch(setModalAdd(false))}
            modalClassName="zoomIn"
            tabIndex={-1}
            centered
        >
            <ModalHeader
                toggle={() => dispatch(setModalAdd(false))}
                className="p-3 bg-soft-primary"
            >
                {updateData ? 'Редактирование должности' : 'Добавление должности'}
            </ModalHeader>
            <ModalBody className="p-4">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        position: updateData?.name || ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <Row>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Label
                                            htmlFor="positionInput"
                                            className="form-label"
                                        >
                                            Наименование должности
                                        </Label>
                                        <Field
                                            type="text"
                                            className="form-control"
                                            id="positionInput"
                                            name="position"
                                            placeholder="Введите должность"
                                        />
                                        <ErrorMessage
                                            name="position"
                                            component="div"
                                            className="text-danger mt-1"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className="hstack gap-2 justify-content-end">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting
                                                ? 'Сохранение...'
                                                : updateData
                                                    ? 'Сохранить изменения'
                                                    : 'Добавить'
                                            }
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddPosition;