import React, {FC} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {customStyles} from "../../settings";
import Select from "react-select";
import {useAppDispatch} from "../../../hooks/redux";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";

interface IUpdateDishInMenu {
    updateDish: boolean,
    setUpdateDish: (value: boolean) => void
    listDishMenu: any[],
    setListDishMenu: (value: any[]) => void,
    selectDish: any
}

const UpdateDishInMenu: FC<IUpdateDishInMenu> = ({setUpdateDish, updateDish, setListDishMenu, listDishMenu, selectDish}) => {

    const dispatch = useAppDispatch();
    useEscapeKey(() => dispatch(setModalAdd(false)), updateDish, "updateDish");

    return (
        <Modal id="updateDish" isOpen={updateDish}
               toggle={() => setUpdateDish(!updateDish)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setUpdateDish(!updateDish)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Обновление блюда в меню
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            count: selectDish?.count,
                            out_dish: selectDish?.out_dish,
                            price: selectDish?.price
                        }
                    }
                    validationSchema={
                        Yup.object({
                            count: Yup.number().required('Обязательное поле'),
                            out_dish: Yup.number().required('Обязательное поле'),
                            price: Yup.number().required('Обязательное поле'),
                        })
                    }
                    onSubmit={(values) => {
                        setListDishMenu(
                            listDishMenu.map(i => {
                                if (i.id === selectDish.id){
                                    return {
                                        ...i,
                                        count: values.count,
                                        out_dish: values.out_dish,
                                        price: values.price
                                    }
                                }
                                return i
                            })
                        )
                        setUpdateDish(false)
                    }}>
                    {
                        ({errors, touched}) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Блюдо:</label>
                                        <Select
                                            options={[{value: selectDish?.id, label: selectDish?.name}]}
                                            value={{value: selectDish?.id, label: selectDish?.name}}
                                            styles={customStyles}
                                            placeholder="Поиск"
                                            name="name"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Количество:</label>
                                        <Field name="count" className={`form-control ${errors.count && touched.count && "border border-2 border-danger"}`} type="number" />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Выход блюда:</label>
                                        <Field name="out_dish" className={`form-control ${errors.out_dish && touched.out_dish && "border border-2 border-danger"}`} type="number" />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <label>Цена:</label>
                                        <Field name="price" className={`form-control ${errors.price && touched.price && "border border-2 border-danger"}`} type="number" />
                                    </Col>
                                </Row>
                                <button type="submit" className="btn btn-primary mt-1" id="addNewProject" >Сохранить</button>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default UpdateDishInMenu;
