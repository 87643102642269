import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    setDateAdd,
    setIsSend,
    setModalAdd,
    WidgetsSlice
} from "../../../store/reducers/widgets/WidgetsSlice";
import {Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import moment from "moment/moment";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import AxiosClient from "../../../api/AxiosClient";
import * as Yup from "yup";
import classNames from "classnames";
import TableTableware from "./TableTableware";
import {getJournalHaccpAntisepticTableware} from "../../../store/reducers/widgets/ActionCreator";
import ModalConfirmExitHaccp from "../ModalConfirmExitHaccp";
import {useModalWithConfirm} from "../../../hooks/useModalWithConfirm";

interface IModalAddAntisepticTableware{
    updateData: any
}

const ModalAddAntisepticTableware: FC<IModalAddAntisepticTableware> = ({updateData}) => {
    const dispatch = useAppDispatch();
    const {modalAdd, groupAllDeviations, date, groupModalAdd, groups, dateAdd, successUpdate} = useAppSelector(state => state.WidgetReducer);
    const [activeTab, setActiveTab] = useState("1");
    const [clothesSetting, setClothesSetting] = useState([]);
    const [selectClothesSetting, setSelectClothesSetting] = useState<any>('');
    const [tableware, setTableware] = useState([])

    const {
        isFormChange,
        showConfirmExit,
        handleClose,
        handleFormChange,
        handleConfirmClose,
        handleStay,
    } = useModalWithConfirm("addAntisepticTableware");

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(WidgetsSlice.actions.successUpdate(""))
        }, 5000)
        return () => clearTimeout(timer)
    }, [successUpdate, dispatch]);

    useEffect(() => {
        if(modalAdd){
            AxiosClient.get('haccp/getCookwareSettingList')
                .then((r: any) => {
                    setClothesSetting(r.data)
                    r.data && setSelectClothesSetting(r.data[0].uid)
                })
                .catch((error) => console.log(error))
            if(!updateData) setTableware([]);
        }
    }, [modalAdd])

    useEffect(() => {
        if(updateData) setTableware(updateData.dishes)
    }, [updateData])

    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    useEffect(() => {
        if (modalAdd && !updateData) {
            dispatch(setDateAdd(new Date()));
        }
    }, [modalAdd]);
    return (
        <>
        <Modal id="ModalAddAntisepticTableware" isOpen={modalAdd} toggle={handleClose}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={handleClose} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление обработки посуды
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        clothes_setting: `${groupModalAdd}`,
                        group: groupAllDeviations ? groups.find((value: any) => value.group_id === groupAllDeviations)?.group_name : '',
                        tableware: tableware,
                        count: updateData? updateData.count : ''
                    }}
                    validationSchema={
                        Yup.object({
                            tableware: Yup.array().min(1, 'Требуется добавить постельное белье!')
                        })}
                    onSubmit={(value) => {
                        dispatch(setIsSend(true));

                        let prop = {
                            uid: updateData? updateData.uid : '',
                            data: moment(dateAdd).format("DD-MM-YYYY"),
                            cookware_setting: selectClothesSetting,
                            count: value.count,
                            dishes: tableware,
                        }

                        AxiosClient.post('haccp/saveJournalAntisepticTableware', prop)
                            .then((r: any) => {
                                dispatch(getJournalHaccpAntisepticTableware(date, groupAllDeviations)).then();
                                dispatch(setIsSend(false));
                                dispatch(setModalAdd(false));
                                handleConfirmClose();
                            })
                            .catch((error) => {
                                dispatch(setIsSend(false))
                            })
                    }}
                >
                    {({ errors, touched, setFieldValue }) => (
                        <Form onChange={handleFormChange}>
                            <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                 role="tablist">
                                <NavItem>
                                    <NavLink
                                        className={classNames({active: activeTab === "1", "p-2": true})}
                                        onClick={() => {
                                            tabChange("1");
                                        }}
                                        type="button">
                                        <i className="fas fa-home"></i>
                                        Основное
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classNames({active: activeTab === "2", "p-2": true})}
                                        onClick={() => {
                                            tabChange("2");
                                        }}
                                        type="button">
                                        <i className="fas fa-home"></i>
                                        Список посуды
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab} className="mt-2">
                                <TabPane tabId="1" className="align-items-center">
                                    <div className="col-auto">
                                        <label className="w-100">Дата:
                                            <div className="col mb-3">
                                                <div className="input-group col-auto">
                                                    <Flatpickr
                                                        className="form-control border-0 dash-filter-picker shadow"
                                                        value={dateAdd}
                                                        options={{
                                                            locale: Russian, dateFormat: "d M Y", disableMobile: true, mode: 'single'
                                                        }}
                                                        onChange={(date) => {
                                                            dispatch(setDateAdd(moment(date[0]).toDate()))
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="mb-3">
                                        <label className="w-100">Заполнить на основании:
                                            <Field as="select" className="form-select shadow" value={selectClothesSetting}
                                                   onChange={(e: any) => setSelectClothesSetting(e.target.value)} name="clothes_setting"
                                            >
                                                {clothesSetting.map((obj: any, index: number) => {
                                                    return <option key={index}
                                                                   value={obj.uid}>{obj.name}</option>
                                                })}
                                            </Field>
                                        </label>
                                    </div>
                                    <div className="mb-3">
                                        <label className="w-100">Группа:
                                            <Field name="group" className="form-control" disabled={true}/>
                                        </label>
                                    </div>
                                    <div className="mb-3">
                                        <label className="w-100">Фактическое количество питающихся:
                                            <Field name="count" className="form-control"/>
                                        </label>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2" className="align-items-center">
                                    <TableTableware tableware={tableware} setTableware={(value: any) => setTableware(value)} selectClothesSetting={selectClothesSetting} />
                                </TabPane>
                            </TabContent>
                            {errors.tableware && (
                                <div style={{ color: 'red', marginTop: '5px' }}>Требуется добавить посуду!</div>
                            )}
                            <div className="hstack gap-2 justify-content-end mt-1">
                                <SubmitWithLoading text={"Сохранить"}/>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
            <ModalConfirmExitHaccp
                isOpen={showConfirmExit}
                onClose={handleConfirmClose}
                onStay={handleStay}
                hasUnsavedChanges={isFormChange}
            />
        </>
    );
};


export default ModalAddAntisepticTableware;
