import React, {FC, useEffect, useState} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {IDeviation} from "../../../models/widgets/IDeviation";
import WidgetService from "../../../api/services/widgetService/WidgetService";
import {setModalAdd, setSelectDeviationAll} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../hooks/redux";
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface IModalSelectDeviationAll {
    isOpen: boolean,
    setIsOpen: (value: boolean) => void
}
const ModalSelectDeviationAll: FC<IModalSelectDeviationAll> = ({isOpen, setIsOpen}) => {
    const dispatch = useAppDispatch();
    const [deviations, setDeviations] = useState<IDeviation[]>([])
    const [selectedDeviation, setSelectedDeviation] = useState('');

    useEffect(() => {
        WidgetService.getDeviations()
            .then(res => setDeviations(res.data))
    }, [])
    useEscapeKey(() => dispatch(setModalAdd(false)), isOpen, "ModalSelectDeviationAll");

    return(
        <Modal id="ModalSelectDeviationAll" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setIsOpen(!isOpen)} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Установить отклонение у всех
            </ModalHeader>
            <ModalBody>
                <div className="mb-3">
                    <label htmlFor="select">Отклонения:</label>
                    <select name="select" id="select" className="form-select" value={selectedDeviation} onChange={(event) => setSelectedDeviation(event.target.value)}>
                        <option value={''}>Явка</option>
                        {
                            deviations.map(d => (<option key={d.code} value={d.code}>{`${d.name}(${d.symbol})`}</option>))
                        }
                    </select>
                </div>
                <div>
                    <button type="button" className="btn btn-primary" id="addNewProject" onClick={() => {
                        setIsOpen(false)
                        dispatch(setSelectDeviationAll(selectedDeviation))
                    }}>Сохранить</button>
                </div>
            </ModalBody>
        </Modal>
    )
};

export default ModalSelectDeviationAll;
