import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import AxiosClient from "../../../../api/AxiosClient";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import {setDateAdd, setGroupModalAdd, setIsSend} from "../../../../store/reducers/widgets/WidgetsSlice";
import TableAddDayliReportNutrationDinner from "../tables/TableAddDayliReportNutrationDinner";
import {getDailyReportNutritional} from "../../../../store/reducers/widgets/ActionCreator";
import SubmitWithLoading from "../../../layouts/UI/SubmitWithLoading";

interface IDinner {
    setModalAdd: (value: boolean) => void,
    setIsFormDirty: (value: boolean) => void
}

const Dinner: FC<IDinner> = ({setModalAdd, setIsFormDirty}) => {
    const dispatch = useAppDispatch();
    const {groupModalAdd, groups, dateAdd, date, groupAllDeviations} = useAppSelector(state => state.WidgetReducer);
    const [studentsDayliReport, setStudentsDayliReport] = useState<any[]>([]);
    const [send, setSend] = useState(false);
    const handleFormChange = () => {
        setIsFormDirty(true);
    };

    useEffect(() => {
        if (groupModalAdd)
            AxiosClient.get('/children/dayli_report', {params: {date: dateAdd, group: groupModalAdd}})
                .then(r => setStudentsDayliReport(r.data))
    }, [dateAdd, groupModalAdd])


    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                group: `${groupModalAdd}`,
                dinner: (studentsDayliReport || []).map(item => {
                    let s: any = {}
                    s[`${item.children_id}`] = item.dinner_id
                    return s
                })
            }}
            onSubmit={(value) => {
                dispatch(setIsSend(true))
                AxiosClient.post('/dayli_report/dinner/add', {value, date: dateAdd, group: groupModalAdd})
                    .then(() => {
                        dispatch(getDailyReportNutritional(date, groupAllDeviations)).then()
                        setModalAdd(false)
                        dispatch(setIsSend(false))
                        setIsFormDirty(false)
                    })
                    .catch(() => dispatch(setIsSend(false)))
            }}
        >
            <Form onChange={handleFormChange}>
                <div className="col-auto">
                    <label>Дата:</label>
                    <div className="col mb-3">
                        <div className="input-group col-auto">
                            <Flatpickr
                                className="form-control border-0 dash-filter-picker shadow"
                                value={dateAdd}
                                options={{
                                    locale: Russian, dateFormat: "d M Y"
                                }}
                                onChange={(date) => dispatch(setDateAdd(date[0]))}
                            />
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    <label>Группа:</label>
                    <Field as="select" className="form-select shadow" value={groupModalAdd}
                           onChange={(e: any) => dispatch(setGroupModalAdd(e.target.value))} name="group">
                        {groups.map((group) => {
                            return <option key={group.group_id}
                                           value={group.group_id}>{group.group_name}</option>
                        })}
                    </Field>
                </div>
                <div>
                    <TableAddDayliReportNutrationDinner studentsDayliReport={studentsDayliReport} date= {dateAdd} group = {groupModalAdd}/>
                </div>

                <div className="hstack gap-2 justify-content-end">
                    <SubmitWithLoading text={"Сохранить"}/>
                </div>
            </Form>
        </Formik>
    );
};

export default Dinner;
