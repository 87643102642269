import React, { FC, useEffect, useState } from 'react'
import {Card, CardBody, Col, Row, Spinner} from "reactstrap"
import DataTable, { TableColumn } from "react-data-table-component"
import { Russian } from "flatpickr/dist/l10n/ru"
import Flatpickr from "react-flatpickr"
import AddCashMovement from "./AddCashMovement"
import UpdateCashMovement from "./UpdateCashMovement"
import AxiosClient from "../../../api/AxiosClient"
import moment from "moment"
import { customStyles } from "../../settings"
import Select from "react-select"
import useDebounce from "../../../hooks/useDebounce";
import {useAppDispatch} from "../../../hooks/redux";
import {setIsSend} from "../../../store/reducers/widgets/WidgetsSlice";

interface IListCashMovement {
    setSelectSchool: (value: any) => void,
    setSelectBuild: (value: any) => void,
    setSelectClass: (value: any) => void,
    selectSchool: { value: string, label: string },
    selectBuild: { value: string, label: string },
    selectClass: { value: string, label: string },
    dateEnd: Date,
    dateStart: Date,
    setDateEnd: (value: Date) => void,
    setDateStart: (value: Date) => void
}

const ListCashMovement: FC<IListCashMovement> = ({ selectClass, dateEnd, dateStart, setDateStart, setDateEnd, setSelectClass, setSelectBuild, setSelectSchool, selectSchool, selectBuild }) => {
    const [addCashMovement, setAddCashMovement] = useState(false)
    const [updateCashMovement, setUpdateCashMovement] = useState(false)
    const [listCashMovement, setListCashMovement] = useState<any[]>([])
    const [selectCashMovement, setSelectCashMovement] = useState<any | null>(null)
    const [schools, setSchools] = useState<any[]>([])
    const [builds, setBuilds] = useState<any[]>([])
    const [classes, setClasses] = useState<any[]>([])
    const [personalAccount, setPersonalAccount] = useState('')
    const [fio, setFio] = useState('')
    const [numberTranzaction, setNumberTranzaction] = useState('')
    const [paymentMethods, setPaymentMethods] = useState<any[]>([])
    const [selectPaymentMethod, setSelectPaymentMethod] = useState({ value: '', label: 'Не выбрано' })
    const [banks, setBanks] = useState<any[]>([]);
    const [selectBank, setSelectBank] = useState({ value: '', label: 'Не выбрано' })
    const [sum, setSum] = useState('')
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState(false);
    const [selectCateringOrganizer, setSelectCateringOrganizer] = useState<any>({value: '', label: 'Не выбрано'});
    const [listCateringOrganizer, setListCateringOrganizer] = useState<any[]>([]);
    const dispatch = useAppDispatch();

    const debouncePersonalAccount = useDebounce(personalAccount, 1000)
    const debounceNumberTranzaction = useDebounce(numberTranzaction, 1000)
    const debounceSum = useDebounce(sum, 1000)
    const debounceFio = useDebounce(fio, 1000)

    useEffect(() => {
        fetchCashMovements(1)
        AxiosClient.get('/schools')
            .then((r) => {
                setSchools(r.data.schools)
            })
        AxiosClient.get('/payment_methods')
            .then(r => setPaymentMethods(r.data))
        AxiosClient.get('/banks')
            .then(r => setBanks(r.data))
        AxiosClient.get(`/users/catering_organizers`)
            .then((r) => setListCateringOrganizer(r.data))
    }, [])

    useEffect(() => {
        if (search){
            fetchCashMovements(currentPage, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, fio, sum, selectBank.value, numberTranzaction, selectPaymentMethod.value, dateStart, dateEnd, selectCateringOrganizer.value)
        }
    }, [debounceFio, debouncePersonalAccount, debounceSum, debounceNumberTranzaction])

    const onChangeSchool = (data: { value: string, label: string }) => {
        setSelectBuild({value: '', label: 'Не выбрано'})
        setSelectClass({value: '', label: 'Не выбрано'})
        fetchBuildings(data.value)
            .then(r => setBuilds(r))
        fetchCashMovements(currentPage, perPage, data.value, '', '', personalAccount, fio, sum, selectBank.value, numberTranzaction, selectPaymentMethod.value, dateStart, dateEnd, selectCateringOrganizer.value)
        setSelectSchool(data)
    }

    const onChangeBuilding = (data: { value: string, label: string }) => {
        setSelectClass({value: '', label: 'Не выбрано'})
        const sortClass = builds?.find(s => s.id.toString() === data.value.toString())?.class
        fetchCashMovements(currentPage, perPage, selectSchool.value, data.value, '', personalAccount, fio, sum, selectBank.value, numberTranzaction, selectPaymentMethod.value, dateStart, dateEnd, selectCateringOrganizer.value)
        setSelectBuild(data)
        setClasses(sortClass)
    }

    const onChangeClass = (data: { value: string, label: string }) => {
        fetchCashMovements(currentPage, perPage, selectSchool.value, selectBuild.value, data.value, personalAccount, fio, sum, selectBank.value, numberTranzaction, selectPaymentMethod.value, dateStart, dateEnd, selectCateringOrganizer.value)
        setSelectClass(data)
    }

    const onChangeBank = (data: { value: string, label: string }) => {
        fetchCashMovements(currentPage, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, fio, sum, data.value, numberTranzaction, selectPaymentMethod.value, dateStart, dateEnd, selectCateringOrganizer.value)
        setSelectBank(data)
    }

    const onChangePaymentMethod = (data: { value: string, label: string }) => {
        fetchCashMovements(currentPage, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, fio, sum, selectBank.value, numberTranzaction, data.value, dateStart, dateEnd, selectCateringOrganizer.value)
        setSelectPaymentMethod(data)
    }

    const onChangeCateringOrganizer = (data: {value: string, label: string}) => {
        fetchCashMovements(currentPage, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, fio, sum, selectBank.value, numberTranzaction, selectPaymentMethod.value, dateStart, dateEnd, data.value)
        setSelectCateringOrganizer(data)
    }

    const onChangeDateEnd= (date: Date) => {
        fetchCashMovements(currentPage, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, fio, sum, selectBank.value, numberTranzaction, selectPaymentMethod.value, dateStart, date, selectCateringOrganizer.value)
        setDateEnd(date)
    }

    const onChangeDateStart= (date: Date) => {
        fetchCashMovements(currentPage, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, fio, sum, selectBank.value, numberTranzaction, selectPaymentMethod.value, date, dateEnd, selectCateringOrganizer.value)
        setDateStart(date)
    }

    const fetchCashMovements = async (page: number, size = perPage, schoolId = '', buildingId = '', classId = '', personalAccount = '', fio = '', sum = '', bankId = '', numberTranzaction = '', typePaymentId = '', dateBegin = dateStart, dateFinal = dateEnd, cateringOrganizerId = '') => {
        setSearch(true)
        await AxiosClient.get(`/cash_movements`, {params: {page: page, per_page: size, schoolId, buildingId, classId, personalAccount, fio, sum, bankId, cateringOrganizerId, numberTranzaction, typePaymentId, dateBegin: moment(dateBegin).format('YYYY-MM-DD').toString(), dateEnd: moment(dateFinal).format('YYYY-MM-DD').toString()}})
            .then((response) => {
                setSearch(false)
                setListCashMovement(response.data.cashMovements);
                setTotalRows(response.data.total);
            })
            .catch(() => {
                console.log('error')
                setSearch(false)
            });
    };
    const handlePageChange = (page: number) => {
        fetchCashMovements(page, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, fio, sum, selectBank.value, numberTranzaction, selectPaymentMethod.value, dateStart, dateEnd, selectCateringOrganizer.value)
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        fetchCashMovements(page, newPerPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, fio, sum, selectBank.value, numberTranzaction, selectPaymentMethod.value, dateStart, dateEnd, selectCateringOrganizer.value)
        setPerPage(newPerPage);
    };

    const fetchBuildings = async (selectSchool: string) => {
        if(selectSchool) {
            const response = await AxiosClient.get(`/schools/${selectSchool}/buildings/classes`)
            return response?.data
        }
        return []
    };

    const onSaveCashMovement = () => {
        fetchCashMovements(currentPage, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, fio, sum, selectBank.value, numberTranzaction, selectPaymentMethod.value, dateStart, dateEnd, selectCateringOrganizer.value)
    }

    const downloadCashMovement = () => {
        dispatch(setIsSend(true))
        const params = {
            selectCateringOrganizer: selectCateringOrganizer.value,
            selectSchool: selectSchool.value,
            selectBuild: selectBuild.value,
            selectClass: selectClass.value,
            personalAccount: personalAccount,
            fio: fio,
            numberTranzaction: numberTranzaction,
            selectBank: selectBank.value,
            selectPaymentMethod: selectPaymentMethod.value,
            sum: sum,
            dateBegin: moment(dateStart).format('YYYY-MM-DD').toString(),
            dateEnd: moment(dateEnd).format('YYYY-MM-DD').toString()
        }
        AxiosClient.get('cash_movements/export', {responseType: 'blob', params})
            .then((r: any) => {
                dispatch(setIsSend(false))
                const url = window.URL.createObjectURL(new Blob([r.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Движение денежных средств.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(() => {
                dispatch(setIsSend(false))
                console.log('error')
            })
    }

    const columns: TableColumn<any>[] = [
        {
            name: <span className='font-weight-bold accessibility_size_table'></span>,
            cell: (row: any) => +row.sum > 0 ? <i className="bx bx-plus text-success fs-3"></i> : <i className="bx bx-minus text-danger fs-3"></i>,
            width: '30px'
        },
        {
            id: "date",
            name: <span className='font-weight-bold accessibility_size_table'>Дата</span>,
            cell: (row: any) => <span className="accessibility_size_table">{moment(new Date(row.date)).format('DD.MM.Y')}</span>,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Школа</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.school.name}</span>,
            hide: 959,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Корпус</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.building.name}</span>,
            hide: 959,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Класс</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.class.name}</span>,
            hide: 959,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>ЛС</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.personal_account.personal_account}</span>,
            width: '160px',
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>ФИО</span>,
            cell: (row: any) => <span className="accessibility_size_table">{`${row?.student.last_name} ${row?.student.name} ${row?.student.middle_name}`}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Сумма</span>,
            cell: (row: any) => <span className="accessibility_size_table">{+row.sum > 0 ? <p className="text-success m-0 fw-bold">{row.sum}</p> : <p className="text-danger m-0 fw-bold">{row.sum}</p>}</span>,
            hide: 599
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Банк</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.bank.name}</span>,
            hide: 1280,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Способ оплаты</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.payment_method.name}</span>,
            wrap: true,
            hide: 1280
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Номер транзакции</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.number_transaction}</span>,
            hide: 959,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Квитовка</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.cvit}</span>,
            hide: 1280,
            wrap: true,
        }
    ]


    return (
        <>
            <Row>
                <Col sm={12} lg={6} xl={3} xxl={3}>
                    <label>Организатор питания:</label>
                    <Select
                        options={listCateringOrganizer?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...listCateringOrganizer.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                        value={selectCateringOrganizer}
                        onChange={(change: any) => {
                            onChangeCateringOrganizer({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                    />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={3}>
                    <label htmlFor="">Школа</label>
                    <Select
                        options={schools?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...schools.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                        value={selectSchool}
                        onChange={(change: any) => {
                            onChangeSchool({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={3} >
                    <label htmlFor="">Корпус</label>
                    <Select
                        options={builds?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...builds?.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                        value={selectBuild}
                        onChange={(change: any) => {
                            onChangeBuilding({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                </Col>
                <Col sm={12} lg={12} xl={3} xxl={3} >
                    <label htmlFor="">Класс</label>
                    <Select
                        options={classes?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...classes?.map(v => ({ value: v.id, label: v.name }))] : [{
                            value: '',
                            label: 'Не выбрано'
                        }]}
                        value={selectClass}
                        onChange={(change: any) => {
                            onChangeClass({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={3} >
                    <label htmlFor="">Лицевой счет</label>
                    <input type="text" className="form-control" value={personalAccount} onChange={e => {
                        setSearch(true)
                        setPersonalAccount(e.target.value)
                    }} />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={3} >
                    <label htmlFor="">ФИО</label>
                    <input type="text" className="form-control" value={fio} onChange={e => {
                        setSearch(true)
                        setFio(e.target.value)
                    }} />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={3} >
                    <label htmlFor="">Номер транз</label>
                    <input type="text" className="form-control" value={numberTranzaction} onChange={e => {
                        setSearch(true)
                        setNumberTranzaction(e.target.value)
                    }} />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={3} >
                    <label htmlFor="">Банк</label>
                    <Select
                        options={banks?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...banks.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                        value={selectBank}
                        onChange={(change: any) => {
                            onChangeBank({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={3} >
                    <label htmlFor="">Способ оплаты</label>
                    <Select
                        options={paymentMethods?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...paymentMethods?.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                        value={selectPaymentMethod}
                        onChange={(change: any) => {
                            onChangePaymentMethod({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={3} >
                    <label htmlFor="">Сумма</label>
                    <input type="text" className="form-control" value={sum} onChange={e => {
                        setSearch(true)
                        setSum(e.target.value)
                    }} />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={3} >
                    <label htmlFor="">Дата с</label>
                    <Flatpickr
                        className="form-control border-0 dash-filter-picker shadow"
                        value={dateStart}
                        options={{
                            locale: Russian, dateFormat: "d M Y"
                        }}
                        onChange={(dates) => onChangeDateStart(dates[0])}
                    />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={3} >
                    <label htmlFor="">Дата по</label>
                    <Flatpickr
                        className="form-control border-0 dash-filter-picker shadow"
                        value={dateEnd}
                        options={{
                            locale: Russian, dateFormat: "d M Y"
                        }}
                        onChange={(dates) => onChangeDateEnd(dates[0])}
                    />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={3} >
                    <label htmlFor=""></label>
                    <Row>
                        <Col>
                            <button className="btn btn-primary w-100 mt-1 mt-sm-0 mt-lg-0 mt-xl-0 mt-xxl-0" onClick={() => setAddCashMovement(true)}>Добавить</button>
                        </Col>
                        <Col >
                            <button className="btn btn-primary w-100 mt-1 mt-sm-0 mt-lg-0 mt-xl-0 mt-xxl-0" onClick={() => downloadCashMovement()}>Печать</button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mt-2">
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={listCashMovement}
                                noDataComponent={'Нет данных'}
                                progressComponent={<Spinner></Spinner>}
                                progressPending={search}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                paginationDefaultPage={currentPage}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно балансов:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        },
                                    },
                                    expanderRow: {
                                        style: {
                                            backgroundColor: "#ffebb5",
                                            paddingTop: '5px',
                                            paddingBottom: '5px'
                                        },
                                    },
                                }}
                                onRowClicked={
                                    (row) => {
                                        if(window.innerWidth <= 768){
                                            setUpdateCashMovement(true)
                                            setSelectCashMovement(row)
                                        }
                                    }
                                }
                                onRowDoubleClicked={
                                    (row) => {
                                        if(window.innerWidth > 768){
                                            setUpdateCashMovement(true)
                                            setSelectCashMovement(row)
                                        }
                                    }
                                }
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                addCashMovement &&
                <AddCashMovement setListCashMovement={() => onSaveCashMovement()} addCashMovement={addCashMovement} setAddCashMovement={value => setAddCashMovement(value)} />
            }
            {
                updateCashMovement &&
                <UpdateCashMovement setListCashMovement={() => onSaveCashMovement()} selectCashMovement={selectCashMovement} updateCashMovement={updateCashMovement} setUpdateCashMovement={value => setUpdateCashMovement(value)} />
            }
            </>
    )
}

export default ListCashMovement
