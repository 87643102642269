import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import {customStyles} from "../../settings";
import Select from "react-select";
import AxiosClient from "../../../api/AxiosClient";
import {useAppDispatch} from "../../../hooks/redux";
import {setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import ModalConfirmExit from "../Schools/ModalConfirmExit";
import _ from "lodash";
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface IModalAddHomeEducation {
    addHOmeEducation: boolean,
    setAddHomeEducation: (value: boolean) => void,
    onUpdateData: () => void
}

const ModalAddHomeEducation: FC<IModalAddHomeEducation> = ({addHOmeEducation, setAddHomeEducation, onUpdateData}) => {
    const idButtonSave: string = 'buttonSaveModalAddHomeEducation';
    const [date, setDate] = useState(new Date());
    const [dateBegin, setDateBegin] = useState(new Date());
    const [dateEnd, setDateEnd] = useState<Date | string>('');
    const [personalAccounts, setPersonalAccounts] = useState<any[]>([]);
    const [students, setStudents] = useState<any[]>([]);
    const [selectStudent, setSelectStudent] = useState({value: '', label: 'Не выбрано'});
    const [selectPersonalAccount, setSelectPersonalAccount] = useState({value: '', label: 'Не выбрано'});
    const [listMenu, setListMenu] = useState<any[]>([]);
    const [priceDay, setPriceDay] = useState(0);
    const [selectListMenu, setSelectListMenu] = useState({value: '', label: ''});
    const [countDay, setCountDay] = useState(0);
    const [typeMenu, setTypeMenu] = useState<any[]>([]);
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [pageOption, setPageOption] = useState(1);
    const [totalOptions, setTotalOptions] = useState(0);
    const [pageOptionPersonalAccount, setPageOptionPersonalAccount] = useState(1);
    const [totalOptionsPersonalAccount, setTotalOptionsPersonalAccount] = useState(0);
    const [confirmExit, setConfirmExit] = useState(false);
    const [currentObject, setCurrentObject] = useState<any>({ date_begin: "", date_end: '', date_receive: "", personal_account_id: '',
        catering_organizer_type_planned_menu_id: '', type_menu_id: '', count_day: "0", price_in_day: "", result_price: "0"});
    const [newObject, setNewObject] = useState<any>({ date_begin: "", date_end: '', date_receive: "", personal_account_id: '',
        catering_organizer_type_planned_menu_id: '', type_menu_id: '', count_day: "0", price_in_day: "", result_price: "0"});
    const dispatch = useAppDispatch()
    useEffect(() => {
        fetchMoreStudents(1)
            .then(r => {
                setPageOption(state => state + 1)
                setStudents(r)
            })
        fetchMoreStudentsPersonalAccounts(1)
            .then(r => {
                setPageOptionPersonalAccount(state => state + 1)
                setPersonalAccounts(r)
            })
        AxiosClient.get('/type_menu')
            .then((r) => {
                setTypeMenu(r.data)
            })
    }, []);

    useEffect(() => {
        const pa = personalAccounts.find((p: any) => p.id.toString() === selectPersonalAccount.value.toString())
        setListMenu(pa?.catering_organizer_type_planned_menu?.map((v: any) => ({
            ...v.type_planned_menu,
            idPlan: v.id,
            pricePlan: v.price
        })))
        pa &&
        setSelectStudent({value: pa.student.id, label: `${pa.student.last_name} ${pa.student.name} ${pa.student.middle_name}`})
    }, [selectPersonalAccount]);


    useEffect(() => {
        listMenu &&
        setSelectListMenu({value: listMenu[0]?.id, label: listMenu[0]?.name})
    }, [listMenu]);

    useEffect(() => {
        const price = listMenu?.find(b => b.id.toString() === selectListMenu.value.toString())?.pricePlan
        setPriceDay(price ? price : 0)
    }, [selectListMenu]);

    const fetchMoreStudents = async (page: number) => {
        setLoadingOptions(true)
        const response = await AxiosClient.get(`/students`, {params: {page: page, per_page: 10}})
        setLoadingOptions(false)
        setTotalOptions(response?.data.total)
        return response?.data.students
    };

    const loadMoreData = async () => {
        fetchMoreStudents(pageOption).then(r => {
            setStudents(prevOptions => [...prevOptions, ...r]);
            setPageOption(state => state + 1)
        }); // функция, которая загружает дополнительные данные

    };

    const handleMenuScroll = async (event: any) => {
        const target = event.target;
        if (totalOptions !== students.length && target.scrollHeight - target.scrollTop === target.clientHeight) {
            await loadMoreData();
        }
    };

    const fetchMoreStudentsPersonalAccounts = async (page: number) => {
        setLoadingOptions(true)
        const response = await AxiosClient.get(`/personal_accounts`, {params: {page: page, per_page: 10}})
        setLoadingOptions(false)
        setTotalOptionsPersonalAccount(response?.data.total)
        return response?.data.personalAccounts
    };

    const loadMoreDataPersonalAccounts = async () => {
        fetchMoreStudentsPersonalAccounts(pageOptionPersonalAccount).then(r => {
            setPersonalAccounts(prevOptions => [...prevOptions, ...r]);
            setPageOptionPersonalAccount(state => state + 1)
        }); // функция, которая загружает дополнительные данные

    };

    const handleMenuScrollPersonalAccount = async (event: any) => {
        const target = event.target;
        if (totalOptionsPersonalAccount !== personalAccounts.length && target.scrollHeight - target.scrollTop === target.clientHeight) {
            await loadMoreDataPersonalAccounts();
        }
    };

    const confirmOrExit = () => {
        if(!_.isEqual(currentObject, newObject)){
            setConfirmExit(true);
        }else{
            setAddHomeEducation(false);
        }
    }
    useEscapeKey(() => dispatch(setModalAdd(false)), addHOmeEducation, "addHOmeEducation");


    return (
        <Modal id="addHOmeEducation" isOpen={addHOmeEducation}
               toggle={() => confirmOrExit()}
               modalClassName="zoomIn" tabIndex={-1} centered size={'lg'}>
            <ModalHeader toggle={() => confirmOrExit()}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление домашнего обучения
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            school: personalAccounts.find((p: any) => p.id.toString() === selectPersonalAccount.value.toString())?.school?.name ?? '',
                            build: personalAccounts.find((p: any) => p.id.toString() === selectPersonalAccount.value.toString())?.building?.name ?? '',
                            class: personalAccounts.find((p: any) => p.id.toString() === selectPersonalAccount.value.toString())?.class?.name ?? '',
                            count_day: countDay,
                            price_in_day: priceDay,
                            type_menu_id: newObject.type_menu_id ?? '',
                            catering_organizer_id: personalAccounts.find((p: any) => p.id.toString() === selectPersonalAccount.value.toString())?.catering_organizer_id,
                            result_price: priceDay && countDay ? priceDay * countDay : '0',
                            personalAccount: selectPersonalAccount.value,
                            date_begin: new Date(),
                            idPlan: '',
                            selectListMenu: selectListMenu.value
                        }
                    }
                    validationSchema={
                        Yup.object({
                            personalAccount: Yup.string().required(),
                            selectListMenu: Yup.string().required(),
                            type_menu_id: Yup.string().required('Необходимо выбрать тип меню'),
                            result_price: Yup.number().required()
                        })
                    }
                    onSubmit={(values) => {
                        dispatch(setIsSend(true))
                        values.personalAccount = selectPersonalAccount.value
                        values.date_begin = dateBegin
                        values.idPlan = listMenu?.find(b => b.id.toString() === selectListMenu.value.toString())?.idPlan
                        AxiosClient.post('/home_educations/create', {...values, date_receive: date, date_end: dateEnd})
                            .then(() => {
                                dispatch(setIsSend(false))
                                setAddHomeEducation(false)
                                onUpdateData()
                            })
                            .catch(() => dispatch(setIsSend(false)))
                    }}>
                    {
                        ({setFieldValue, errors, touched, values}) => (
                            <Form>
                                <Row>
                                    <Col>
                                        <label htmlFor="">Дата получения</label>
                                        <Flatpickr
                                            value={date}
                                            className="form-control border-0 dash-filter-picker shadow"
                                            options={{
                                                locale: Russian, dateFormat: "d M Y"
                                            }}
                                            onChange={(dates) => {
                                                setDate(dates[0])
                                                setNewObject({...newObject, 'date_receive': dates[0]});
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <label htmlFor="">Дата с</label>
                                        <Flatpickr
                                            value={dateBegin}
                                            className="form-control border-0 dash-filter-picker shadow"
                                            options={{
                                                locale: Russian, dateFormat: "d M Y"
                                            }}
                                            onChange={(dates) => {
                                                setDateBegin(dates[0])
                                                setNewObject({...newObject, 'date_begin': dates[0]});
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <label htmlFor="">Дата по</label>
                                        <Flatpickr
                                            value={dateEnd}
                                            className="form-control border-0 dash-filter-picker shadow"
                                            options={{
                                                locale: Russian, dateFormat: "d M Y"
                                            }}
                                            onChange={(dates) => {
                                                setDateEnd(dates[0])
                                                setNewObject({...newObject, 'date_end': dates[0]});
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>Лицевой счет:</label>
                                        <Field name="personalAccount">
                                            {() => (
                                                <Select
                                                    options={personalAccounts.length > 0 ? [{value: '', label: 'Не выбрано'}, ...personalAccounts?.map(v => ({value: v.id, label: v.personal_account}))] : [{value: '', label: 'Не выбрано'}]}
                                                    value={selectPersonalAccount.value ? selectPersonalAccount : {value: '', label: 'Не выбрано'}}
                                                    onChange={(change: any) => {
                                                        setSelectPersonalAccount({value: change.value, label: change.label});
                                                        setNewObject({...newObject, 'personal_account_id': change.value});
                                                    }}
                                                    styles={customStyles}
                                                    className={`${errors.personalAccount && touched.personalAccount && 'border rounded border-danger'}`}
                                                    onMenuScrollToBottom={handleMenuScrollPersonalAccount}
                                                />
                                            )}
                                        </Field>
                                    </Col>
                                    <Col>
                                        <div>
                                            <label>Комплекс:</label>
                                            <select name="type_menu_id" value={values.type_menu_id} className={`form-select ${errors.type_menu_id && touched.type_menu_id && "border border-danger"}`}
                                                   onChange={(e: any) => {
                                                       setFieldValue('type_menu_id', e.target.value);
                                                       setNewObject( (prev: any) => ({...prev, type_menu_id: e.target.value}));
                                                   }}
                                            >
                                                <option value="">Не выбрано</option>
                                                {
                                                    typeMenu.map(tm => <option value={tm.id}
                                                                               key={tm.id}>{tm.name}</option>)
                                                }
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>Ученик:</label>
                                        <Select
                                            options={students.length > 0 ? [{value: '', label: 'Не выбрано'}, ...students?.map(v => ({value: v.id, label: `${v.last_name} ${v.name} ${v.middle_name}`}))] : [{value: '', label: 'Не выбрано'}]}
                                            value={selectStudent.value ? selectStudent : {value: '', label: 'Не выбрано'}}
                                            onChange={(change: any) => {
                                                setSelectStudent({value: change.value, label: change.label})

                                            }}
                                            styles={customStyles}
                                            onMenuScrollToBottom={handleMenuScroll}
                                        />
                                    </Col>
                                    <Col>
                                        <div>
                                            <label>Тип планового меню:</label>
                                            <Field name="selectListMenu">
                                                {() => (
                                                    <Select
                                                        options={listMenu?.map(v => ({value: v.id, label: v.name}))}
                                                        value={selectListMenu}
                                                        onChange={(change: any) => {
                                                            setSelectListMenu({value: change.value, label: change.label})
                                                            setNewObject({...newObject, 'catering_organizer_type_planned_menu_id': change.value});
                                                        }}
                                                        styles={customStyles}
                                                        className={`${errors.selectListMenu && touched.selectListMenu && 'border rounded border-danger'}`}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>Школа:</label>
                                        <Field name="school" className="form-control" disabled/>
                                    </Col>
                                    <Col>
                                        <label>Количество дней:</label>
                                        <input className="form-control" value={countDay}
                                               onChange={event => {
                                                   setCountDay(+event.target.value)
                                                   setNewObject({...newObject, 'count_day': +event.target.value});
                                               }}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>Корпус:</label>
                                        <Field name="build" className="form-control" disabled/>
                                    </Col>
                                    <Col>
                                        <label>Сумма в день:</label>
                                        <input className="form-control" value={priceDay}
                                               onChange={event => {
                                                   setNewObject({...newObject, 'price_in_day': +event.target.value});
                                                   setPriceDay(+event.target.value)
                                               }}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>Класс:</label>
                                        <Field name="class" className="form-control" disabled />
                                    </Col>
                                    <Col>
                                        <label>Сумма заказа:</label>
                                        <Field name="result_price" className={`form-control ${errors.result_price && touched.result_price && "border border-2 border-danger"}`} type="number"
                                               onChange={(e: any) => {
                                                   setNewObject({...newObject, 'result_price': e.target.value});
                                                   setFieldValue('result_price', e.target.value);
                                               }}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="hstack gap-2 mt-2">
                                        <SubmitWithLoading id={idButtonSave} text={"Добавить"}/>
                                    </div>
                                </Row>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
            <ModalConfirmExit idButtonSave={idButtonSave} setUpdate={value => setAddHomeEducation(value)} confirmExit={confirmExit} setConfirmExit={value => setConfirmExit(value)} />
        </Modal>
    );
};

export default ModalAddHomeEducation;
