import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap"
import Flatpickr from "react-flatpickr"
import { Russian } from "flatpickr/dist/l10n/ru"
import DataTable from "react-data-table-component"
import ModalAddRequestFromNutrition from "./ModalAddRequestFromNutrition"
import AxiosClient from "../../../api/AxiosClient"
import { customStyles } from "../../settings"
import Select from "react-select"
import ModalUpdateRequestForNutrition from "./ModalUpdateRequestForNutrition"
import moment from "moment"
import ModalRecalculateRequestFromNutrition from "./ModalRecalculateRequestFromNutrition";
import useDebounce from "../../../hooks/useDebounce";

const ListRequestFromNutrition = () => {
    const [date, setDate] = useState([moment().startOf('month').toDate(), moment().endOf('month').toDate()])
    const [dateBegin, setDateBegin] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const [addRequestFromNutrition, setAddRequestFromNutrition] = useState(false)
    const [schools, setSchools] = useState<any[]>([])
    const [builds, setBuilds] = useState<any[]>([])
    const [classes, setClasses] = useState<any[]>([])
    const [selectSchool, setSelectSchool] = useState<any>({ value: '', label: 'Не выбрано' })
    const [selectBuild, setSelectBuild] = useState<any>({ value: '', label: 'Не выбрано' })
    const [selectClass, setSelectClass] = useState<any>({ value: '', label: 'Не выбрано' })
    const [personalAccount, setPersonalAccount] = useState('')
    const [selectRequestForNutrition, setSelectRequestForNutrition] = useState<null | any>(null)
    const [updateRequestForNutrition, setUpdateRequestForNutrition] = useState(false)
    const [listRequestForNutrition, setListRequestForNutrition] = useState<any[]>([])
    const [recalculateRequestFromNutrition, setRecalculateRequestFromNutrition] = useState(false);
    const [listCateringOrganizer, setListCateringOrganizer] = useState<any[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectCateringOrganizer, setSelectCateringOrganizer] = useState({value: '', label: 'Не выбрано'});
    const pa = useDebounce(personalAccount, 1000)
    useEffect(() => {
        fetchRequestForNutrition(1, perPage, date)
        AxiosClient.get('/schools')
            .then((r) => {
                setSchools(r.data.schools)
            })
        AxiosClient.get(`/users/catering_organizers`)
            .then((r) => setListCateringOrganizer(r.data))
    }, [])


    useEffect(() => {
        fetchRequestForNutrition(currentPage, perPage, date, selectCateringOrganizer.value, selectSchool.value, selectBuild.value, selectClass.value, pa)
    }, [pa])

    const fetchBuildings = async (selectSchool: string) => {
        if(selectSchool) {
            const response = await AxiosClient.get(`/schools/${selectSchool}/buildings/classes`)
            return response?.data
        }
        return []
    };

    const updateData = () => {
        fetchRequestForNutrition(currentPage, perPage, date, selectCateringOrganizer.value, selectSchool.value, selectBuild.value, selectClass.value, personalAccount)
    }

    const onChangeCateringOrganizer = (data: {value: string, label: string}) => {
        fetchRequestForNutrition(currentPage, perPage, date, data.value, selectSchool.value, selectBuild.value, selectClass.value, personalAccount)
        setSelectCateringOrganizer(data)
    }

    const onChangeSchool = (data: {value: string, label: string}) => {
        fetchRequestForNutrition(currentPage, perPage, date, selectCateringOrganizer.value, data.value, selectBuild.value, selectClass.value, personalAccount)
        fetchBuildings(data.value)
            .then(r => setBuilds(r))
        setSelectSchool(data)
        setSelectBuild({ value: '', label: 'Не выбрано' })
        setSelectClass({ value: '', label: 'Не выбрано' })
    }

    const onChangeBuild = (data: {value: string, label: string}) => {
        fetchRequestForNutrition(currentPage, perPage, date, selectCateringOrganizer.value, selectSchool.value, data.value, selectClass.value, personalAccount)
        const sortClass = builds?.find(s => s.id.toString() === data.value.toString())?.class
        setSelectBuild(data)
        setClasses(sortClass)
        setSelectClass({ value: '', label: 'Не выбрано' })        
    }

    const onChangeClass = (data: {value: string, label: string}) => {
        fetchRequestForNutrition(currentPage, perPage, date, selectCateringOrganizer.value, selectSchool.value, selectBuild.value, data.value, personalAccount)
        setSelectClass(data)
    }

    const onChangeDate = (date: Date[]) => {
        fetchRequestForNutrition(currentPage, perPage, date, selectCateringOrganizer.value, selectSchool.value, selectBuild.value, selectClass.value, personalAccount)
        setDate(date)                
    }
    useEffect(() => {
        setDateBegin(date[0])
        setDateEnd(date[1])
        },[date])

    const fetchRequestForNutrition = async (page = 1, perPage: number, date: Date[], cateringOrganizerId = '', schoolId = '', buildId = '', classId = '', personalAccount = '') => {
        const newDate = [moment(date[0]).format('YYYY-MM-DD'), moment(date[1]).format('YYYY-MM-DD')]
        AxiosClient.get('/request_for_nutrition', {params: {page, perPage, date: newDate, cateringOrganizerId, schoolId, buildId, classId, personalAccount}})
            .then((r) => {
                setListRequestForNutrition(r.data.requestForNutrition);
                setTotalRows(r.data.count)
            })
            .catch(() => console.log('error'))
    }

    const handlePageChange = (page: number) => {
        fetchRequestForNutrition(page, perPage, date, selectCateringOrganizer.value, selectSchool.value, selectBuild.value, selectClass.value, personalAccount)
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        fetchRequestForNutrition(page, newPerPage, date, selectCateringOrganizer.value, selectSchool.value, selectBuild.value, selectClass.value, personalAccount)
        setPerPage(newPerPage);
    };

    const columns = [
        {
            id: "date",
            name: <span className='font-weight-bold accessibility_size_table'>Дата</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{moment(row.date).format('DD.MM.YYYY')}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Лиц. счет</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.personal_account.personal_account}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>ФИО</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{`${row.personal_account.student.last_name} ${row.personal_account.student.name} ${row.personal_account.student.middle_name}`}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Баланс</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.balance + " р"}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Заказ на день</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.order_day + " р"}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Льготы</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.benefits + " р"}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Надбавка</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.premium + " р"}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Сумма</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.sum + " р"}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Табель</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.tabel + " р"}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Явка</span>,
            cell: (row: any) => !!row.attendance ? <i className="bx bx-check fs-3 text-success"></i> : '',
            width: '65px',
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>В кредит</span>,
            cell: (row: any) => !!row.credit ? <i className="bx bx-check fs-3 text-success"></i> : '',
            width: '90px',
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>З</span>,
            cell: (row: any) => !!row.breakfast ? <i className="bx bx-check fs-3 text-success"></i> : '',
            width: '60px',
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>ГЗ</span>,
            cell: (row: any) => !!row.hot_breakfast ? <i className="bx bx-check fs-3 text-success"></i> : '',
            width: '60px',
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>О</span>,
            cell: (row: any) => !!row.dinner ? <i className="bx bx-check fs-3 text-success"></i> : '',
            width: '60px',
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>П</span>,
            cell: (row: any) => !!row.snack ? <i className="bx bx-check fs-3 text-success"></i> : '',
            width: '60px',
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>У</span>,
            cell: (row: any) => !!row.lunch ? <i className="bx bx-check fs-3 text-success"></i> : '',
            width: '60px',
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>С</span>,
            cell: (row: any) => !!row.sleeps ? <i className="bx bx-check fs-3 text-success"></i> : '',
            width: '60px',
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Корр</span>,
            cell: (row: any) => !!row.correction ? <i className="bx bx-check fs-3 text-success"></i> : '',
            width: '65px',
            wrap: true
        },
    ]

    return (
        <>
            <Row>
                <Col sm={12} lg={6} xl={4} xxl={3} >
                    <label className="w-100">Дата:
                    <Flatpickr
                        className="form-control border-0 dash-filter-picker shadow"
                        value={date}
                        options={{
                            locale: Russian, dateFormat: "d M Y", mode: "range"
                        }}
                        onClose={(date) => onChangeDate(date)}
                    />
                    </label>
                </Col>
                <Col sm={12} lg={6} xl={4} xxl={3}>
                    <label className="w-100">Организатор питания:
                    <Select
                        options={listCateringOrganizer?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...listCateringOrganizer.map(v => ({value: v.id, label: v.name}))] : [{
                            value: '',
                            label: 'Не выбрано'
                        }]}
                        value={selectCateringOrganizer}
                        onChange={(change: any) => {
                            onChangeCateringOrganizer({value: change.value, label: change.label})
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                    </label>
                </Col>
                <Col sm={12} lg={6} xl={4} xxl={3} >
                    <label  className="w-100">Школа:
                    <Select
                        options={schools?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...schools.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                        value={selectSchool}
                        onChange={(change: any) => {
                            onChangeSchool({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                    </label>
                </Col>
                <Col sm={12} lg={6} xl={4} xxl={3} >
                    <label className="w-100">Корпус:
                    <Select
                        options={builds?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...builds?.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                        value={selectBuild}
                        onChange={(change: any) => {
                            onChangeBuild({ value: change.value, label: change.label })                            
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                    </label>
                </Col>
                <Col sm={12} lg={6} xl={4} xxl={3} >
                    <label className="w-100">Класс:
                    <Select
                        options={classes?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...classes?.map(v => ({ value: v.id, label: v.name }))] : [{
                            value: '',
                            label: 'Не выбрано'
                        }]}
                        value={selectClass}
                        onChange={(change: any) => {
                            onChangeClass({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                    </label>
                </Col>
                <Col sm={12} lg={6} xl={4} xxl={3} >
                    <label className="w-100">ЛС:
                        <input type="text" id="personalAccount" className="form-control" value={personalAccount}
                            onChange={(event) => setPersonalAccount(event.target.value)} />
                    </label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mt-2">
                        <CardHeader className="d-flex gap-2">
                            {/*<button className="btn btn-primary" onClick={() => setAddRequestFromNutrition(true)}>Сформировать</button>*/}
                            <button className="btn btn-primary" onClick={() => setRecalculateRequestFromNutrition(true)}>Пересчитать</button>
                        </CardHeader>
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={listRequestForNutrition}
                                defaultSortFieldId={'date'}
                                defaultSortAsc={false}
                                noDataComponent={'Нет данных'}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                paginationDefaultPage={currentPage}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно заказов:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        },
                                    },
                                }}
                                onRowClicked={
                                    (row) => {
                                        if(window.innerWidth <= 768){
                                            setUpdateRequestForNutrition(true)
                                            setSelectRequestForNutrition(row)
                                        }
                                    }
                                }
                                onRowDoubleClicked={
                                    (row) => {
                                        if(window.innerWidth > 768){
                                            setUpdateRequestForNutrition(true)
                                            setSelectRequestForNutrition(row)
                                        }
                                    }
                                }
                            />
                        </CardBody>
                    </Card>
                </Col>
                {
                    addRequestFromNutrition &&
                    <ModalAddRequestFromNutrition addRequestFromNutrition={addRequestFromNutrition} setAddRequestFromNutrition={value => setAddRequestFromNutrition(value)} />

                }
                {
                    recalculateRequestFromNutrition &&
                    <ModalRecalculateRequestFromNutrition 
                        updateData={() => updateData()} 
                        recalculateRequestFromNutrition={recalculateRequestFromNutrition} 
                        setRecalculateRequestFromNutrition={(value) => setRecalculateRequestFromNutrition(value) } 
                        infoFilter={{selectSchool, selectBuild, selectClass, dateBegin, dateEnd}}
                    />

                }
                {
                    updateRequestForNutrition &&
                    <ModalUpdateRequestForNutrition 
                        updateData={() => updateData()} 
                        setSelectRequestForNutrition={value => setSelectRequestForNutrition(value)} 
                        setUpdateRequestForNutrition={value => setUpdateRequestForNutrition(value)} 
                        updateRequestForNutrition={updateRequestForNutrition} selectRequestForNutrition={selectRequestForNutrition}                         
                    />
                }
            </Row>
        </>
    )
}

export default ListRequestFromNutrition
