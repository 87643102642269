import React, {FC, useRef, useState} from 'react';
import ReactCrop, {Crop, PixelCrop} from "react-image-crop";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import imageCompression from "browser-image-compression";

interface IProps {
    base64: string,
    isOpenCrop: boolean,
    toggleIsOpenCrop: () => void,
    setGenerateImage: (data: string) => void
}

const ModalCropImage: FC<IProps> = ({base64, isOpenCrop, toggleIsOpenCrop, setGenerateImage}) => {
    const [crop, setCrop] = useState<Crop>();
    const imgRef = useRef<HTMLImageElement | null>(null);
    const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);

    // Преобразуем Blob в File
    const blobToFile = (blob: Blob, fileName: string): File => {
        return new File([blob], fileName, { type: blob.type });
    };
    const getCroppedImage = async () => {
        if (!completedCrop || !imgRef.current) return;

        const canvas = document.createElement("canvas");
        const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
        const scaleY = imgRef.current.naturalHeight / imgRef.current.height;

        canvas.width = completedCrop.width;
        canvas.height = completedCrop.height;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        ctx.drawImage(
            imgRef.current,
            completedCrop.x * scaleX,
            completedCrop.y * scaleY,
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
            0,
            0,
            completedCrop.width,
            completedCrop.height
        );

        return new Promise<string>((resolve) => {
            canvas.toBlob(async (blob) => {
                if (blob) {
                    const file = blobToFile(blob, 'cropped-image.jpg');
                    // Сжимаем изображение с помощью imageCompression
                    const compressedBlob = await imageCompression(file, {
                        maxSizeMB: 0.25,  // Сжимаем до 250 KB (0.25 MB)
                        //maxWidthOrHeight: 2000, // Максимальная ширина или высота 300x300
                        useWebWorker: true, // Использование web worker для улучшения производительности
                    });

                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result as string);
                    reader.readAsDataURL(compressedBlob);
                }
            }, "image/png");
        });
    };

    const handleCropSave = async () => {
        const croppedBase64 = await getCroppedImage();
        setGenerateImage(croppedBase64 ? croppedBase64 : '')
        toggleIsOpenCrop()
    };

    return (
        <Modal isOpen={isOpenCrop} toggle={toggleIsOpenCrop} centered size='lg'>
            <ModalHeader toggle={toggleIsOpenCrop}>Обрезание изображения</ModalHeader>
            <ModalBody>
                {base64 && (
                    <div>
                        <ReactCrop
                            crop={crop}
                            onChange={(newCrop) => setCrop(newCrop)}
                            onComplete={(c) => setCompletedCrop(c)}
                        >
                            <img
                                ref={imgRef}
                                alt="Source"
                                src={base64}
                                style={{ maxWidth: "100%" }}
                            />
                        </ReactCrop>
                    </div>
                )}
                <button onClick={handleCropSave} className="btn btn-primary mt-3">Обрезать</button>
            </ModalBody>
        </Modal>
    );
};

export default ModalCropImage;
