import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import {
    setModalAddActEquipmentInspectionCertificate
} from "../../../store/reducers/widgets/WidgetsSlice";
import ModalAddActEquipmentInspectionCertificate from "../modal/ModalAddActEquipmentInspectionCertificate";
import WidgetService from "../../../api/services/widgetService/WidgetService";
import ModalUpdateActEquipmentInspectionCertificate from "../modal/ModalUpdateActEquipmentInspectionCertificate";
import TableLoaders from "../../layouts/SceletonLoaders/TableLoaders";

const WidgetTableActEquipmentInspectionCertificate = () => {

    const dispatch = useAppDispatch();
    const {date, modalAddActEquipmentInspectionCertificate} = useAppSelector(state => state.WidgetReducer);
    const [modalUpdate, setModalUpdate] = useState(false);

    const [data, setData] = useState<any[]>([]);
    const [id, setId] = useState<string>('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        WidgetService.getInspectionAct(date).then(r => {
            setData(r.data)
            setLoading(false)
        })
    }, [date]);

    const columns: any = [
        {
            id: "date",
            name: <span className='font-weight-bold fs-13'>Дата</span>,
            selector: (row: any) => row.data,
            sortable: true,
            compact: true,
            width: '12%'
        },
        {
            name: <span className='font-weight-bold fs-13'>Номер</span>,
            selector: (row: any) => row.nomer,
            compact: true,
            width: '13%'
        },
        {
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: any) => row.company,
            wrap: true,
            compact: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Объект ремонта</span>,
            selector: (row: any) => row.equipment,
            wrap: true,
            compact: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Склад</span>,
            selector: (row: any) => row.sklad,
            wrap: true,
            compact: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Статус</span>,
            selector: (row: any) => {
                switch (row.type_repair) {
                    case "Диагностика":
                        return <span className="badge bg-info fs-12">{row.type_repair}</span>
                    case "Капитальный ремонт":
                        return <span className="badge bg-warning fs-12">{row.type_repair}</span>
                    case "Текущий ремонт":
                        return <span className="badge bg-warning fs-12">{row.type_repair}</span>
                    case "Техническое обслуживание":
                        return <span className="badge bg-warning fs-12">{row.type_repair}</span>
                    case "Аварийный ремонт":
                        return <span className="badge bg-danger fs-12">{row.type_repair}</span>
                    default:
                        return <span className="badge bg-info fs-12">{row.type_repair}</span>
                }
            },
            compact: true,
            width: '21%'
        }
    ];

    return (
        <>
            {
                loading ?
                    <TableLoaders/>
                    :
                    <Card>
                        <CardHeader>
                            Акты осмотра оборудования
                        </CardHeader>
                        <CardBody className=' align-items-center'>
                            <DataTable
                                columns={columns}
                                data={data}
                                noDataComponent={'Нет осмотренного оборудования'}
                                defaultSortFieldId={"date"}
                                defaultSortAsc={true}
                                pagination
                                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно актов:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        }
                                    }
                                }}
                                onRowClicked={
                                    (row) => {
                                        if(window.innerWidth <= 768){
                                            setId(row.doc_id)
                                            setModalUpdate(true)
                                        }
                                    }
                                }
                                onRowDoubleClicked={
                                    (row) => {
                                        if(window.innerWidth > 768){
                                            setId(row.doc_id)
                                            setModalUpdate(true)
                                        }
                                    }
                                }
                            />
                        </CardBody>
                        <CardFooter className='d-flex justify-content-between'>
                            <div className="button_modal mx-1">
                                <button className="btn btn-primary w-100"
                                        onClick={() => dispatch(setModalAddActEquipmentInspectionCertificate(true))}>Добавить
                                </button>
                            </div>
                        </CardFooter>
                        {
                            modalUpdate &&
                            <ModalUpdateActEquipmentInspectionCertificate id={id} modalUpdate={modalUpdate} setModalUpdate={(value) => setModalUpdate(value)}/>
                        }
                        {
                            modalAddActEquipmentInspectionCertificate &&
                            <ModalAddActEquipmentInspectionCertificate setUpdateIncpectionAct={data => setData(data)}/>
                        }
                    </Card>
            }
        </>
    );
};

export default WidgetTableActEquipmentInspectionCertificate;
