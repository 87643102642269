import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardFooter, Col, Row} from "reactstrap";
import AxiosClient from "../../../api/AxiosClient";
import DataTable from "react-data-table-component";
import {setModalAdd, setShowNotification} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../hooks/redux";
import ModalAddPosition from "./ModalAddPosition";
import {Field} from "formik";
import NotificationShow from "../../widgets/NotificationShow";

const ListPosition = () => {
    const dispatch = useAppDispatch();

    const [search, setSearch] = useState('');
    const [dataFiltering, setDataFiltering] = useState<any[]>([]);
    const [positions, setPositions] = useState<any[]>([]);
    const [updateData, setUpdateData] = useState<any>(null);

    useEffect(() => {
        fetchPositions();
    }, []);

    const fetchPositions = () => {
        AxiosClient.post('/positions')
            .then(r => {
                setDataFiltering(r.data);
                setPositions(r.data);
            })
            .catch(error => {
                console.log('Ошибка загрузки должностей:', error);
            });
    };
    // useEffect(() => {
    //     // AxiosClient.post('/organizations')
    //     //     .then(r => {
    //     //         setOrganizations(r.data);
    //     //     })
    //     //     .catch(error => {
    //     //         console.log('Ошибка загрузки организаций:', error);
    //     //     });
    // }, []);

    useEffect(() => {
        setDataFiltering(filtering())
    }, [search, positions, ]) //selectedOrg

    const filtering = () => {
        let data = positions;

        if(search) {
            data = data.filter((e:any) => e.name.toLowerCase().includes(search.toLowerCase()))
        }
        // if (selectedOrg) {
        //     data = data.filter((e: any) => e.organization_id === selectedOrg);
        // }

        return data;
    }

    const removePosition = (id: any) => {
        AxiosClient.post(`/position/archive/${id}`)
            .then((r) => {
                AxiosClient.post('/positions')
                    .then(r => {
                        setDataFiltering(r.data);
                        setPositions(r.data);
                    })
                    .catch(error => {
                        console.log('Ошибка загрузки должностей:', error);
                    });
                dispatch(setShowNotification({type: "success", message: `Готово, должность в архиве`, show: true}))

            })
            .catch((error) => {
                console.log(error);
                dispatch(setShowNotification({type: "error", message: `Ошибка, не удалось добавить в архив`, show: true}))

            })
    }

    const columns : any = [
        {
            id: "name",
            name: <span className='font-weight-bold accessibility_size_table'>Наименование</span>,
            selector: (row: any) => row.name,
            cell: (row: any) => <span className='accessibility_size_table'>{row.name}</span>,
            wrap: true,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'></span>,
            selector: (row: any) => <i className="bx bx-archive-in fs-24 cursor-pointer" onClick={() => {removePosition(row.id)}}></i>,
            width: '60px'
        }
    ]

    return (
        <>
            <Row>
                <Col xxl={3} xl={3} lg={6}>
                    <label className="w-100">Наименование:
                        <input type="text" className='form-control' value={search} onChange={(e) => setSearch(e.target.value)} />
                    </label>
                </Col>
                {/*<Col xxl={3} xl={3} lg={6}>*/}
                {/*    <label className="w-100">Организация:*/}
                {/*        <select*/}
                {/*            className='form-select shadowl'*/}
                {/*            value={selectedOrg}*/}
                {/*            onChange={(e) => setSelectedOrg(e.target.value)}*/}
                {/*        >*/}
                {/*            <option value="">Все организации</option>*/}
                {/*            {organizations.map(org => (*/}
                {/*                <option key={org.id} value={org.id}>*/}
                {/*                    {org.name}*/}
                {/*                </option>*/}
                {/*            ))}*/}
                {/*        </select>*/}
                {/*    </label>*/}
                {/*</Col>*/}
            </Row>
            <Row>
                <Col>
                    <Card className="mt-2">
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={dataFiltering}
                                noDataComponent={'Нет данных'}
                                pagination
                                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно должностей:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        },
                                    },
                                    expanderRow: {
                                        style: {
                                            backgroundColor: "#ffebb5",
                                            paddingTop: '5px',
                                            paddingBottom: '5px'
                                        },
                                    },
                                }}
                                onRowClicked={
                                    (row) => {
                                        if(window.innerWidth <= 768){
                                            setUpdateData(row);
                                            dispatch(setModalAdd(true));
                                        }
                                    }
                                }
                                onRowDoubleClicked={
                                    (row) => {
                                        if(window.innerWidth > 768){
                                            setUpdateData(row);
                                            dispatch(setModalAdd(true));
                                        }
                                    }
                                }
                            />
                        </CardBody>
                        <CardFooter>
                            <button className="btn btn-primary"
                                    onClick={() => {
                                        setUpdateData(null);
                                        dispatch(setModalAdd(true))
                                    }}>Добавить
                            </button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            <ModalAddPosition
                updateData={updateData}
                onSuccess={fetchPositions}
            />
            <NotificationShow />
        </>
    );
};

export default ListPosition;
