import React, {FC, useState} from 'react';
import DataTable from "react-data-table-component";
import {Col, Row} from "reactstrap";
import ModalAddBenefit from "./ModalAddBenefit";
import moment from "moment";
import ModalUpdateBenefit from "./ModalUpdateBenefit";

interface IListBenefit {
    listBenefit: any[],
    setListBenefit: (value: any[]) => void
}

const ListBenefit: FC<IListBenefit> = ({setListBenefit, listBenefit}) => {

    const [addBenefit, setAddBenefit] = useState(false);
    const [updateBenefit, setUpdateBenefit] = useState(false);
    const [selectBenefit, setSelectBenefit] = useState<any | null>(null);

    function removeBenefit(removeBenefit: any) {
        setListBenefit(
            listBenefit.filter((benefit: any, index) => index !== removeBenefit.index))
    }

    const columns: any = [
        {
            id: 'index',
            cell: (row: any) => <span className='accessibility_size_table'> {row.index}</span>,
            omit: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Льгота</span>,
            cell: (row: any) => <span className='accessibility_size_table'> {row.name}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Дата с</span>,
            cell: (row: any) => <span className='accessibility_size_table'> {moment(row.date_begin).format('DD.MM.Y')}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Дата по</span>,
            cell: (row: any) => row.date_end ?
                <span className='accessibility_size_table'> {moment(row.date_end).format('DD.MM.Y')} </span>: '',
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Сумма</span>,
            cell: (row: any) => <span className='accessibility_size_table'> {row.sum}</span>,
            width: '80px'
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'></span>,
            cell: (row: any) => <i className="bx bx-trash fs-3 cursor-pointer" onClick={() => removeBenefit(row)}></i>,
            width: '50px'
        }
    ]
    return (
        <div>
            <DataTable
                columns={columns}
                data={listBenefit.map((el: any, index) => ({...el, index}))}
                keyField={'index'}
                noDataComponent={'Нет данных'}
                highlightOnHover
                customStyles={{
                    rows: {
                        highlightOnHoverStyle: {
                            backgroundColor: "#ffebb5"
                        }
                    }
                }}
                onRowClicked={(row) => {
                    setSelectBenefit(row)
                    setUpdateBenefit(true)
                }}
            />
            <Row>
                <Col xxl={6} xl={6} lg={6} sm={6}>
                    <div className="hstack gap-2">
                        <button type="button" className="btn btn-primary mt-3"
                                onClick={() => setAddBenefit(true)}>Добавить
                        </button>
                    </div>
                </Col>
            </Row>
            <ModalAddBenefit listBenefit={listBenefit} setListBenefit={value => setListBenefit(value)}
                             addBenefit={addBenefit} setAddBenefit={(value) => setAddBenefit(value)}/>
            <ModalUpdateBenefit updateBenefit={updateBenefit} setUpdateBenefit={value => setUpdateBenefit(value)}
                                listBenefit={listBenefit} setListBenefit={value => setListBenefit(value)}
                                selectBenefit={selectBenefit}/>
        </div>
    );
};

export default ListBenefit;
