import React, {FC} from 'react';
import {Form, Formik} from "formik";
import {setIsSend, setShowNotification} from "../../../store/reducers/widgets/WidgetsSlice";
import {Card, CardBody, Col, Row, Spinner} from "reactstrap";
import DataTable from "react-data-table-component";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {useAppDispatch} from "../../../hooks/redux";
import TableConfirmationExportNutritionClildren from "./TableConfirmationExportNutritionClildren";
import AxiosClient from "../../../api/AxiosClient";

interface ITableConfirmationExportClassNutritionClildren {
    listImport: any
}

const TableConfirmationExportClassNutritionClildren: FC<ITableConfirmationExportClassNutritionClildren> = ({listImport}) => {
    const dispatch = useAppDispatch();
    const daysInMonth = listImport.daysInMonth;


    const columns = [...Array(daysInMonth)].map((_, index) => ({
        name: <span className='font-weight-bold accessibility_size_table mx-2'>{index + 1}</span>,
        cell: (row: any) => <span className='m-auto accessibility_size_table' style={{borderRight: index === 5 ? 'none' : '1px solid #ccc'}} key={index}>{row.nutrition[index] === 0? '-' : row.nutrition[index]}</span>,
        compact: true,
        'width': '30px',
        wrap: false
    }));
    columns.unshift({
            name: <span className='font-weight-bold accessibility_size_table mx-1'>Тип питания \ ФИО ребенка</span>,
            cell: (row: any) => <span className='px-1 accessibility_size_table'>{row.name}</span>,
            compact: true,
            'width': '160px',
            wrap: true

        },
        {
            name: <span className='font-weight-bold accessibility_size_table mx-1'>Сумма</span>,
            cell: (row: any) => <span className='m-auto accessibility_size_table'>{row.nutrition_sum === 0? '-' : row.nutrition_sum}</span>,
            compact: true,
            'width': '45px',
            wrap: true
        });

    const columnsForMenu = [...Array(daysInMonth)].map((_, index) => ({
        name: <span className='font-weight-bold accessibility_size_table mx-2' key={index}>{index + 1}</span>,
        cell: (row: any) => <span className='m-auto accessibility_size_table' key={index}>{row.nutrition[index] === 0? '-' : row.nutrition[index]}</span>,
        compact: true,
        'width': '30px',
        wrap: false
    }));
    columnsForMenu.unshift({
            name: <span className='font-weight-bold accessibility_size_table mx-1 py-3'>Тип меню</span>,
            cell: (row: any) => <span className='px-3 accessibility_size_table py-3'>{row.name}</span>,
            compact: true,
            'width': '160px',
            wrap: true

        },
        {
            name: <span className='font-weight-bold accessibility_size_table mx-1'>Сумма</span>,
            cell: (row: any) => <span className='m-auto accessibility_size_table'>{row.nutrition_sum === 0? '-' : row.nutrition_sum}</span>,
            compact: true,
            'width': '45px',
            wrap: true
        });

    return (
        <Formik
            enableReinitialize
            initialValues={
                {
                }
            }
            onSubmit={() => {
                dispatch(setIsSend(true))
                const params = {
                    date: listImport.date,
                    daysInMonth: listImport.daysInMonth,
                    school: listImport.school,
                    build: listImport.build,
                    class: listImport.class,
                    noBenefits: listImport.noBenefits.toString(),
                    groupBy: listImport.groupBy
                }
                AxiosClient.get('/request_for_nutrition/export/file', {responseType: 'blob', params})
                    .then((r: any) => {
                        dispatch(setIsSend(false))
                        const url = window.URL.createObjectURL(new Blob([r.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Табель питания в разрезе льгот.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    })
                    .catch(() => {
                        console.log('error')
                        dispatch(setIsSend(false))
                        dispatch(setShowNotification({type: "error", message: 'Ошибка, не удалось экспортировать!', show: true}))
                    })
            }}>
            {
                () => (
                    <Form>
                        <Row>
                            <Col>
                                <Card className="mt-3">
                                    <CardBody>
                                        <DataTable
                                            title="Табель питания в разрезе льгот"
                                            columns={columns}
                                            data={listImport.groupedStudents}
                                            noDataComponent={'Нет данных'}
                                            progressComponent={<Spinner></Spinner>}
                                            pagination
                                            paginationRowsPerPageOptions={[100, 500, 1000]}
                                            paginationPerPage={100}
                                            paginationComponentOptions={
                                                {
                                                    rowsPerPageText: 'Видно классов:',
                                                    rangeSeparatorText: 'из'
                                                }
                                            }
                                            highlightOnHover
                                            customStyles={{
                                                headCells: {
                                                    style: {
                                                        borderRight: '1px solid #ccc',
                                                    },
                                                },
                                                rows: {
                                                    highlightOnHoverStyle: {
                                                        backgroundColor: "#ffebb5"
                                                    },
                                                    style:{
                                                        backgroundColor: "#ebebfc",
                                                    }
                                                }
                                            }}
                                            fixedHeader={true}
                                            expandableRowDisabled={(row) => row.name === 'Итого'}
                                            expandableRows
                                            expandOnRowClicked
                                            expandableRowsComponent={(row: any) => {
                                                return listImport.groupBy === 'default' ? <TableConfirmationExportNutritionClildren
                                                    listImport={row.data.personal_accounts}
                                                    daysInMonth={listImport.daysInMonth}
                                                />
                                                    : <Card className='my-0'>
                                                        <CardBody className='p-0'>
                                                            <DataTable
                                                                columns={columnsForMenu}
                                                                data={row.data.personal_accounts}
                                                                highlightOnHover
                                                                responsive={false}
                                                                customStyles={{
                                                                    headCells: {
                                                                        style: {
                                                                            borderRight: '1px solid #ccc',
                                                                        },
                                                                    },
                                                                    rows: {
                                                                        highlightOnHoverStyle: {
                                                                            backgroundColor: "#ffebb5"
                                                                        },
                                                                        style:{
                                                                            backgroundColor: "#f6f5ff", //"#f2ffe6", //"#eeebff",
                                                                        }
                                                                    },
                                                                    cells:{
                                                                        style: {
                                                                            borderRight: '1px solid #ccc',
                                                                        },
                                                                    },
                                                                }}
                                                                className='py-0'
                                                                noTableHead
                                                                expandableRows
                                                                expandOnRowClicked
                                                                expandableRowsComponent={(row: any) => {
                                                                    return <TableConfirmationExportNutritionClildren
                                                                        listImport={row.data.personal_accounts}
                                                                        daysInMonth={listImport.daysInMonth}
                                                                    />
                                                                }}
                                                            />
                                                        </CardBody>
                                                    </Card>
                                            }}
                                        />
                                        <div className="hstack gap-2" >
                                            <SubmitWithLoading text={"Скачать отчет"}/>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                )
            }
        </Formik>
    );
};

export default TableConfirmationExportClassNutritionClildren;
