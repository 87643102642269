import React, {FC, useEffect, useState} from 'react';
import {
    setIsSend, setModalAdd,
    setModalAddActEquipmentInspectionCertificate
} from "../../../store/reducers/widgets/WidgetsSlice";
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import TableDefect from "./tables/TableDefect";
import ModalAddDefect from "./ModalAddDefect";
import Select from "react-select";
import WidgetService from "../../../api/services/widgetService/WidgetService";
import {convertDate, convertDateTimeFull1C} from "../../../functions/ConvertDate";
import AxiosClient from "../../../api/AxiosClient";
import ModalAddCompl from "./ModalAddCompl";
import TableCompl from "./tables/TableCompl";
import TableZamers from "./tables/TableZamers";
import ModalAddZamer from "./ModalAddZamer";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface IModalAddActEquipmentInspectionCertificate {
    setUpdateIncpectionAct: (data: any[]) => void
}
const ModalAddActEquipmentInspectionCertificate: FC<IModalAddActEquipmentInspectionCertificate> = ({setUpdateIncpectionAct}) => {
    const dispatch = useAppDispatch();
    const {modalAddActEquipmentInspectionCertificate, date} = useAppSelector(state => state.WidgetReducer)
    const [dateTime, setDateTime] = useState(new Date());
    const [planRemont, setPlanRemont] = useState(new Date());
    const [factRemont, setFactRemont] = useState<any>('');

    const [defects, setDefects] = useState<object[]>([]);
    const [compl, setCompl] = useState<object[]>([]);
    const [zamers, setZamers] = useState<any[]>([]);
    const [addZamers, setAddZamers] = useState(false);
    const [addDefect, setAddDefect] = useState(false);
    const [addCompl, setAddCompl] = useState(false);
    const [warehouses, setWarehouses] = useState<any[]>([]);
    const [objectRepair, setObjectRepair] = useState<any[]>([]);
    const [selectWarehouse, setSelectWarehouse] = useState<any>('');
    const [selectObjectRepair, setSelectObjectRepair] = useState<any>('');
    const [info, setInfo] = useState<any[]>([]);
    const [organizations, setOrganizations] = useState<any[]>([]);
    const [data, setData] = useState<any[]>([]);
    const [main, setMain] = useState<string>('');
    const [mainInfo, setMainInfo] = useState<any>({});
    const [typeView, setTypeView] = useState<any[]>([]);
    const [selectMainEquipment, setSelectMainEquipment] = useState<any>(null);
    const [selectOrganization, setSelectOrganization] = useState<any>('');

    useEffect(() => {
        WidgetService.getListObjectWarehouse(date).then(r => {
            setWarehouses(r.data)
            if (r.data[0])
                setSelectWarehouse({value: r.data[0]?.uid, label: r.data[0]?.name})
        })
        WidgetService.getDispatchServiceRequest(date).then(r => {
            setData(r.data.filter((value) => value.status !== 'Выполнено'))
        })
    }, [date]);

    useEffect(() => {
        main &&
            WidgetService.getInfoDispatchServiceRequest(main).then(
                (r) => {
                    setMainInfo(r.data[0])
                    setSelectObjectRepair({value: r.data[0]?.Defect_repair[0]?.repair_obj_id, label: r.data[0]?.Defect_repair[0]?.repair_obj})
                    setSelectMainEquipment({value: r.data[0]?.repair_id, label: r.data[0]?.repair})
                    setSelectWarehouse({value: r.data[0].sklad_id, label: r.data[0].sklad_name})
                    setSelectOrganization({value: r.data[0].org_id, label: r.data[0].org_name})
                    WidgetService.getInfoObject(dateTime, r.data[0].sklad_id, r.data[0].repair_id)
                        .then((r) => setInfo(r.data))
                }
            )
    }, [main, dateTime]);

    useEffect(() => {
        WidgetService.getInfoOrganization().then(r => {
            setOrganizations(r.data)
            setSelectOrganization({value: r.data[0].uid, label: r.data[0].name})
        })
        WidgetService.getListTypeView().then(r => setTypeView(r.data))
    }, []);

    useEffect(() => {
        selectWarehouse &&
        WidgetService.getListObjectRepair(dateTime, selectWarehouse.value)
            .then((r) => setObjectRepair(r.data))
    }, [dateTime, selectWarehouse]);

    useEffect(() => {
        !main && selectWarehouse && selectObjectRepair &&
        WidgetService.getInfoObject(dateTime, selectWarehouse.value, selectObjectRepair.value)
            .then((r) => setInfo(r.data))
    }, [dateTime, selectWarehouse, selectObjectRepair, main]);

    const customStyles = {
        control: (ds: any) => ({
            ...ds,
            backgroundColor: "#fff",
        }),
        option: (ds: any, state: any) => ({
            ...ds,
            backgroundColor: state.isFocused ? "#4b38b3" : "#fff",
            color: state.isFocused ? "#fff" : "#000"
        })
    }
    useEscapeKey(() => dispatch(setModalAdd(false)), modalAddActEquipmentInspectionCertificate, "modalAddActEquipmentInspectionCertificate");

    return (
        <Modal id="modalAddActEquipmentInspectionCertificate" isOpen={modalAddActEquipmentInspectionCertificate}
               toggle={() => dispatch(setModalAddActEquipmentInspectionCertificate(!modalAddActEquipmentInspectionCertificate))}
               modalClassName="zoomIn" tabIndex={-1} centered size={"xl"}>
            <ModalHeader toggle={() => dispatch(setModalAddActEquipmentInspectionCertificate(!modalAddActEquipmentInspectionCertificate))}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавить акт осмотра оборудования
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            organization: mainInfo?.org_id,
                            stock: mainInfo?.sklad_id,
                            fixed_asset: mainInfo?.repair_id,
                            element: mainInfo?.repair_obj_id,
                            zakl: '',
                            type_view: typeView[0]?.uid
                        }
                    }
                    onSubmit={(values) => {
                        const data = {
                            nomer: '',
                            disp_zayavka_uid: main,
                            date: convertDateTimeFull1C(dateTime),
                            date_plan: convertDate(planRemont),
                            date_fact: factRemont ? convertDate(factRemont) : '',
                            sklad_id: selectWarehouse.value,
                            org_uid: selectOrganization.value,
                            repair_obj_id: selectObjectRepair.value,
                            repair_id: selectMainEquipment.value,
                            zavod: info.length > 0 ? info[0].zavod : '',
                            inv_nomer: info.length > 0 ? info[0]?.inv_nomer : '',
                            zavod_nomer: info.length > 0 ? info[0]?.zavod_nomer : '',
                            main_price: info.length > 0 ? info[0]?.main_price : '',
                            date_of_operation:  info.length > 0 ? info[0]?.date_of_operation : '',
                            useful_life: info.length > 0 ? info[0]?.useful_life : '',
                            priority: info.length > 0 ? info[0]?.priority : '',
                            phasing: info.length > 0 ? info[0]?.phasing : '',
                            marka: info.length > 0 ? info[0]?.marka : '',
                            defect_repair: defects.length > 0 ? defects : Array(0),
                            zakl: values.zakl,
                            compl: compl.length > 0 ? compl : Array(0),
                            zamer: zamers.length > 0 ? zamers : Array(0),
                            type_view: values.type_view
                        }
                        dispatch(setIsSend(true));
                        AxiosClient.post('/save_inspection_act', {doc_id: '', data: data})
                            .then(() => {
                                dispatch(setModalAddActEquipmentInspectionCertificate(false))
                                WidgetService.getInspectionAct(date).then(r => setUpdateIncpectionAct(r.data))
                                dispatch(setIsSend(false));
                            })
                            .catch(() => dispatch(setIsSend(false)))
                    }}>
                    {({
                          handleChange
                      }) => (
                        <Form>
                            <Row className="mb-1">
                                <Col>
                                    <fieldset className="border-dark">
                                        <legend>Информация по акту осмотра</legend>
                                        <Row className="mb-1">
                                            <Col>
                                                <label>Диспетчерские заявки:</label>
                                                <Select options={data.map(data => ({value: data.doc_id, label: data.date + " "  + data.equipment + " " + data.sklad + " " + data.dispatch_service}))}
                                                        name="stock"
                                                        onChange={(change: any) => {
                                                            setMain(change.value)
                                                            handleChange('stock')(change.value)
                                                        }}
                                                        styles={customStyles}
                                                        placeholder="Поиск"/>
                                            </Col>
                                        </Row>
                                        <Row className="mb-1" >
                                            <Col className="mb-1">
                                                <label>Дата:</label>
                                                <div className="col ">
                                                    <div className="input-group">
                                                        <Flatpickr
                                                            className="form-control border-0 dash-filter-picker shadow"
                                                            value={dateTime}
                                                            options={{
                                                                locale: Russian,
                                                                mode: "single",
                                                                dateFormat: "d.m.Y",
                                                            }}
                                                            onChange={(date) => setDateTime(date[0])}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col>
                                                <label>Организация:</label>
                                                <Select options={organizations?.map(org => ({value: org.uid, label: org.name}))}
                                                        name="organization"
                                                        value={selectOrganization}
                                                        onChange={(change: any) => {
                                                            setSelectOrganization({value: change.value, label: change.label})
                                                            handleChange('organization')(change.value)
                                                        }}
                                                        styles={customStyles}
                                                        placeholder="Поиск"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-1">
                                            <Col>
                                                <label>Склад:</label>
                                                <Select options={warehouses.map(warehouses => ({value: warehouses.uid, label: warehouses.name}))}
                                                        name="stock"
                                                        value={selectWarehouse}
                                                        onChange={(change: any) => {
                                                            setSelectWarehouse({value: change.value, label: change.label})
                                                            handleChange('stock')(change.value.toString())
                                                        }}
                                                        styles={customStyles}
                                                        placeholder="Поиск"/>
                                            </Col>
                                        </Row>
                                        <Row className="mb-1">
                                            <Col>
                                                <label>Основное средство:</label>
                                                <Select options={objectRepair.map(object => ({value: object.os_uid, label: object.os_name, or_uid: object.uid, or_name: object.repair_name}))}
                                                        name="fixed_asset"
                                                        value={selectMainEquipment}
                                                        onChange={(change: any) => {
                                                            setSelectMainEquipment({value: change.value, label: change.label})
                                                            setSelectObjectRepair({value: change.or_uid, label: change.or_name})
                                                            handleChange('fixed_asset')(change.value)
                                                        }}
                                                        styles={customStyles}
                                                        placeholder="Поиск"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-1">
                                            <Col>
                                                <label>Объект ремонта:</label>
                                                <Select options={objectRepair.map(object => ({value: object.uid, label: object.repair_name}))}
                                                        name="element"
                                                        value={selectObjectRepair}
                                                        onChange={(change: any) => {
                                                            handleChange('element')(change.value)
                                                            setSelectObjectRepair({value: change.value, label: change.label})
                                                        }}
                                                        styles={customStyles}
                                                        placeholder="Поиск"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-1">
                                            <Col>
                                                <label>Вид ремонтных работ:</label>
                                                <Select options={typeView.map(object => ({value: object.uid, label: object.name}))}
                                                        name="element"
                                                        onChange={(change: any) => {
                                                            handleChange('type_view')(change.value)
                                                        }}
                                                        styles={customStyles}
                                                        placeholder="Поиск"
                                                />
                                            </Col>
                                        </Row>

                                    </fieldset>
                                </Col>
                                <Col>
                                    <fieldset className="border-dark">
                                        <legend>Информация по основному средству</legend>
                                        <Row className="mb-1">
                                            <Col>
                                                <label>Завод изготовитель:</label>
                                                <input className="form-control" defaultValue={info[0]?.zavod} disabled/>
                                            </Col>
                                        </Row>
                                        <Row className="mb-1">
                                            <Col xs="12" md="6" >
                                                <label>Инвентарный номер:</label>
                                                <div className="input-group">
                                                    <input className="form-control" name="invent_number" defaultValue={info[0]?.inv_nomer} disabled/>
                                                </div>
                                            </Col>
                                            <Col xs="12" md="6" >
                                                <label>Заводской номер:</label>
                                                <div className="input-group">
                                                    <input className="form-control" name="invent_number" defaultValue={info[0]?.zavod_nomer} disabled/>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-1">
                                            <Col xs="12" md="6" >
                                                <label>Cтоимость:</label>
                                                <div className="input-group">
                                                    <input className="form-control" name="invent_number" defaultValue={info[0]?.main_price} disabled/>
                                                </div>
                                            </Col>
                                            <Col xs="12" md="6" >
                                                <label>Ввод в эксплуатацию:</label>
                                                <div className="col">
                                                    <div className="input-group">
                                                        <input className="form-control" name="invent_number" defaultValue={info[0]?.date_of_operation} disabled/>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-1">
                                            <Col xs="12" md="6" >
                                                <label>Срок использования:</label>
                                                <div className="input-group">
                                                    <input className="form-control" name="invent_number" defaultValue={info[0]?.useful_life} disabled/>
                                                </div>
                                            </Col>
                                            <Col xs="12" md="6" >
                                                <label>Приоритет:</label>
                                                <input className="form-control" name="invent_number" defaultValue={info[0]?.priority} disabled/>
                                            </Col>
                                        </Row>
                                        <Row className="mb-1">
                                            <Col xs="12" md="6" >
                                                <label>Фазность:</label>
                                                <input className="form-control" name="invent_number" defaultValue={info[0]?.phasing} disabled/>
                                            </Col>
                                            <Col xs="12" md="6" >
                                                <label>Марка:</label>
                                                <input className="form-control" name="invent_number" defaultValue={info[0]?.marka} disabled/>
                                            </Col>
                                        </Row>
                                        <Row className="mb-1">
                                            <Col  xs="12" md="6" >
                                                <label className="w-100">Плановая дата ремонта:
                                                <div className="col">
                                                    <div className="input-group">
                                                        <Flatpickr
                                                            className="form-control border-0 dash-filter-picker shadow"
                                                            value={planRemont}
                                                            options={{
                                                                locale: Russian,
                                                                mode: "single",
                                                                dateFormat: "d.m.Y"
                                                            }}
                                                            onChange={(date) => setPlanRemont(date[0])}
                                                        />
                                                    </div>
                                                </div></label>
                                            </Col>
                                            <Col xs="12" md="6" >
                                                <label className="w-100">Фактическая дата ремонта:
                                                    <div className="col">
                                                        <div className="input-group">
                                                            <Flatpickr
                                                                className="form-control border-0 dash-filter-picker shadow"
                                                                value={factRemont}
                                                                options={{
                                                                    locale: Russian,
                                                                    mode: "single",
                                                                    dateFormat: "d.m.Y"
                                                                }}
                                                                onChange={(date) => setFactRemont(date[0])}
                                                            />
                                                        </div>
                                                    </div></label>
                                            </Col>
                                        </Row>
                                    </fieldset>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="h-100">
                                    <label>Заключение:</label>
                                    <Field name="zakl" as="textarea" className="form-control h-100" rows={9} />
                                </Col>
                            </Row>
                            <fieldset>
                                <legend>Список замеров</legend>
                                <TableZamers compl={zamers} setCompl={(zam) => setZamers(zam)}/>
                                <button type="button" className="btn mt-3 btn-primary" id="addNewProject" onClick={() => setAddZamers(true)}>Добавить замеры</button>
                            </fieldset>
                            <fieldset>
                                <legend>Список комплектующих</legend>
                                <TableCompl compl={compl} setCompl={(comp) => setCompl(comp)}/>
                                <button type="button" className="btn mt-3 btn-primary" id="addNewProject" onClick={() => setAddCompl(true)}>Добавить комплектующие</button>
                            </fieldset>
                            <fieldset className="mt-3">
                                <legend>Список дефектов</legend>
                                <TableDefect defect={defects} setCompl={(defect) => setDefects(defect)}/>
                                <button type="button" className="btn mt-3 btn-primary" id="addNewProject" onClick={() => setAddDefect(true)}>Добавить дефект</button>
                            </fieldset>
                            <div className="hstack gap-2 justify-content-end mt-3">
                                <SubmitWithLoading text={"Сохранить"}/>
                            </div>
                        </Form>
                        )
                    }
                </Formik>
            </ModalBody>
            <ModalAddZamer addDefect={addZamers} setAddDefect={(data) => setAddZamers(data)} setAddNewDefect={(zam) => setZamers([...zamers, zam])}/>
            <ModalAddCompl addDefect={addCompl} setAddDefect={(data) => setAddCompl(data)} setAddNewDefect={(comp) => setCompl([...compl, comp])}/>
            <ModalAddDefect addDefect={addDefect} setAddDefect={(data) => setAddDefect(data)} setAddNewDefect={(defect) => setDefects([...defects, defect])}/>
        </Modal>
    );
};



export default ModalAddActEquipmentInspectionCertificate;
