import React, {FC, memo, useEffect} from 'react';
import DataTable from "react-data-table-component";
import {getAllDeviationChild} from "../../../store/reducers/parent/ActionCreator";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {IAllDeviationChild} from "../../../models/parent/IAllDeviationChild";

interface ITableAttendanceChildren {
    date: Date,
    child: string
}
const TableAttendanceChildren: FC<ITableAttendanceChildren> = ({date, child}) => {

    const dispatch = useAppDispatch();
    const {allDeviationChild} = useAppSelector(state => state.ParentReducer)

    const columns:any = [
        {
            name: <span className='font-weight-bold accessibility_size_table'>Дата</span>,
            selector: (row: IAllDeviationChild) => row.data,
            cell: (row: IAllDeviationChild) => <span className="accessibility_size_table">{row.data}</span>,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Посещал</span>,
            cell: (row: IAllDeviationChild) => <span className="accessibility_size_table">{row.present > 0 ? row.present : "-"}</span>,
            center: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Не посещал</span>,
            selector: (row: IAllDeviationChild) => <span className="accessibility_size_table">{row.no_present > 0 ? row.no_present : "-"}</span>,
            center: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Причина</span>,
            selector: (row: IAllDeviationChild) => <span className="accessibility_size_table">{row.name ? row.name : "Присутствовал"}</span>,
            center: true,
        }
    ];

    useEffect(() => {
        dispatch(getAllDeviationChild(date, child)).then()
    }, [date, child, dispatch])

    return (
        <DataTable
            columns={columns}
            data={allDeviationChild}
            pagination
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            paginationComponentOptions={
                {
                    rowsPerPageText: 'Показать:',
                    rangeSeparatorText: 'из'
                }
            }
            noDataComponent={'Данных нет'}
            defaultSortAsc={false}
            highlightOnHover
            customStyles={{
                rows: {
                    highlightOnHoverStyle: {
                        backgroundColor: "#ffebb5"
                    }
                }
            }}
        />
    );
};

export default memo(TableAttendanceChildren);
