import React, {FC, useCallback, useEffect, useState} from 'react';
import {Card, CardBody, Col, Input, Row} from "reactstrap";
import {Form, Formik} from "formik";
import DataTable from "react-data-table-component";
import Select from "react-select";
import {customStyles} from "../../../settings";
import AxiosClient from "../../../../api/AxiosClient";
import {setShowNotification} from "../../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../../hooks/redux";

interface ITableConfirmationImportSchool {
    listImport: any,
    setListImport(value: any) : any,
    modalData: any,
}

const TableConfirmationImportSchool: FC<ITableConfirmationImportSchool>   = ({listImport, setListImport, modalData}) => {
    const dispatch = useAppDispatch()
    const [listImportTable, setListImportTable] = useState<any[]>([]);
    const [listShifts, setListShifts] = useState<any[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [expandedRows, setExpandedRows] = useState<{ [key: string]: { [key: string]: boolean } }>({});

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (newRowsPerPage: number) => {
        setRowsPerPage(newRowsPerPage);
    };

    useEffect(() => {  
        console.log("modalData"+ JSON.stringify(modalData))     
        const updatedList = listImport.map((school: any) => ({
            ...school,
            isAdd: true, 
            address: modalData[0].address,
            catering_organizer: modalData[0].catering_organizer,
            max_credit_nutrition: modalData[0].max_credit_nutrition,
            max_deposit: modalData[0].max_deposit,
            isSaturday: modalData[0].isSaturday,
            contract: modalData[0].contract,
            discount_price: modalData[0].discount_price,
            date_contract: modalData[0].date_contract,
            date_begin: modalData[0].date_begin,
            date_end: modalData[0].date_end,
            build: school.build.map((build: any) => ({
                ...build,
                isAddBuild: true,
                classes: build.classes.map((cls: any) => ({
                    ...cls,
                    isAddClass: cls.shift_id ? true : false,
                    //shift_id: cls.shift_id ? cls.shift_id : 1,
                    schoolName: school.name,
                    buildName: build.name,
                })),
                schoolName: school.name,
            }))
        }));
        setSelectAll(true)

        setListImportTable(updatedList);     
        AxiosClient.get('/shift')
            .then((r) => setListShifts(r.data))
            .catch(() => console.log('error'))
    }, [listImport])

    const toggleSelectAll = useCallback(() => {
            const newSelectAll = !selectAll;
            setSelectAll(newSelectAll);
    
            const updatedList = listImportTable.map((school: any) => ({
                ...school,
                isAdd: newSelectAll,
                build: school.build.map((build: any) => ({
                    ...build,
                    isAddBuild: newSelectAll,
                    classes: build.classes.map((cls: any) => ({
                        ...cls,
                        isAddClass: newSelectAll && cls.shift_id ? newSelectAll : false
                    }))
                }))
            }));    
            setListImportTable(updatedList);
    }, [selectAll, listImportTable]);

    const handleRowToggle = (row: any, tableKey: string) => {
        var rowName=''
        if(tableKey==='classes') rowName=row.name+row.schoolName+row.buildName
        if(tableKey==='builds') rowName=row.name+row.schoolName
        if(tableKey==='schools') rowName=row.name
        setExpandedRows((prev) => ({
            ...prev,
            [tableKey]: {
                ...prev[tableKey],
                [rowName]: !prev[tableKey]?.[rowName],
            },
        }));
    };

    const handleSchoolChange = useCallback((index: number, row: any) => {  
            const updatedList = [...listImportTable];
            updatedList[index].isAdd = !updatedList[index].isAdd;
    
            updatedList[index].build.forEach((build: any) => {
                build.isAddBuild = updatedList[index].isAdd;
                build.classes.forEach((cls:any)=>{
                    cls.isAddClass=updatedList[index].isAdd && cls.shift_id ? updatedList[index].isAdd : false
                })
            });
    
            setListImportTable(updatedList);
            setSelectAll(false);
        }, [listImportTable]);

    const handleBuildChange = useCallback((index: number, row: any) => {        
        const updatedList = listImportTable.map((school) => ({                            
            ...school,
            build: school.build.map((build: any, buildIndex:any) => {
                if(buildIndex=== index && school.name===row.schoolName) {
                    const newIsAddBuild=!build.isAddBuild
                    const updatedClasses=build.classes.map((cls:any)=>({
                        ...cls,
                        isAddClass: newIsAddBuild && cls.shift_id ? newIsAddBuild : false
                    }))
                    return {
                        ...build,
                        isAddBuild: newIsAddBuild, 
                        classes: updatedClasses                                                                             
                    };
                }
                return build;          
            }),                            
        }));

        const schoolIndex = updatedList.findIndex(school => school.name === row.schoolName);
        const schoolHasUncheckedBuild = updatedList[schoolIndex].build.some((build: any) => !build.isAddBuild);        
        if (schoolHasUncheckedBuild) {
            updatedList[schoolIndex].isAdd = false;
        } else {
            updatedList[schoolIndex].isAdd = true; 
        }

        setListImportTable(updatedList);  
        setSelectAll(false);
    }, [listImportTable]);

    const handleClassChange = useCallback((index: number, row: any) => {
        const updatedList = listImportTable.map((school) => ({
            ...school,
            build: school.build.map((build: any) => ({
                ...build,
                classes: build.classes.map((cls:any, classIndex:any)=> {
                    if(build.name===row.buildName && classIndex===index && school.name===row.schoolName) {
                        const newIsAddClass = !cls.isAddClass
                        return {
                            ...cls,
                            isAddClass: newIsAddClass
                        };
                    }
                    return cls;
                })
            }))
        }))

        const schoolIndex = updatedList.findIndex(school => school.name === row.schoolName);        
        const buildIndex = updatedList[schoolIndex].build.findIndex((build:any) => build.name === row.buildName);
        const hasUncheckedClass = updatedList[schoolIndex].build[buildIndex].classes.some((cls:any) => !cls.isAddClass);
        if (hasUncheckedClass) {
            updatedList[schoolIndex].isAdd = false;
            updatedList[schoolIndex].build[buildIndex].isAddBuild = false
        } else {
            updatedList[schoolIndex].build[buildIndex].isAddBuild = true
            const schoolHasUncheckedBuild = updatedList[schoolIndex].build.some((build: any) => !build.isAddBuild);        
            if (schoolHasUncheckedBuild) {
                updatedList[schoolIndex].isAdd = false;
            } else {
                updatedList[schoolIndex].isAdd = true; 
            }
        } 
        
        setListImportTable(updatedList);  
        setSelectAll(false); 
    }, [listImportTable]);

    const handleShiftChange = useCallback((row: any, v: any)=>{
        const updatedList = listImportTable.map((school) => ({
            ...school,
            build: school.build.map((build: any) => ({
                ...build,
                classes: build.classes.map((cls: any) => {
                    if (cls.name === row.name && school.name===row.schoolName && build.name===row.buildName) {
                        if(!v.value) {
                            return {                                            
                                ...cls,                                            
                                shift_id: '',
                                isAddClass: false
                            };
                        }
                        return {                                            
                            ...cls,                                            
                            shift_id: v.value 
                        };
                    }
                    return cls; 
                })
            }))
        })); 
        const schoolIndex = updatedList.findIndex(school => school.name === row.schoolName);        
        const buildIndex = updatedList[schoolIndex].build.findIndex((build:any) => build.name === row.buildName);
        const hasUncheckedClass = updatedList[schoolIndex].build[buildIndex].classes.some((cls:any) => !cls.isAddClass);
        if (hasUncheckedClass) {
            updatedList[schoolIndex].isAdd = false;
            updatedList[schoolIndex].build[buildIndex].isAddBuild = false
        } else {
            updatedList[schoolIndex].build[buildIndex].isAddBuild = true
            const schoolHasUncheckedBuild = updatedList[schoolIndex].build.some((build: any) => !build.isAddBuild);        
            if (schoolHasUncheckedBuild) {
                updatedList[schoolIndex].isAdd = false;
            } else {
                updatedList[schoolIndex].isAdd = true; 
            } 
        }
        setListImportTable(updatedList); 
    },[listImportTable])

    const columns = [
        {
            id: "CodeSchool",
            name: <span className='font-weight-bold accessibility_size_table'>Школа \ Корпус \ Класс</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.name}</span>,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Смена</span>,
            wrap: true,
            'width': '290px'
        },
        {
            id: "isAdd",
            name: <Input type="checkbox" className='fs-18' checked={selectAll} onChange={toggleSelectAll}/>,                        
            cell: (row: any, index: number) =>
                <Input type="checkbox" className='fs-18'     
                    checked={row.isAdd}
                    onChange={()=> handleSchoolChange(index, row)}
                />,
            'width' : '70px'
        },
    ]

    const columnsForBuild = [
        {
            id: "NameBuild",
            name: <span className='font-weight-bold accessibility_size_table'>Корпус</span>,
            cell: (row: any, index: number) => <span className="accessibility_size_table">{row.name}</span>,
            wrap: true,
           
        },
        {         
            wrap: true,
            'width': '290px'
        },        
        {
            id: "isAdd2",
            name: <Input type="checkbox" className='fs-18' />,            
            cell: (row: any, index: number) =>
                <Input type="checkbox" className='fs-18'                       
                    checked={row.isAddBuild}
                    onChange={()=> handleBuildChange(index, row)}
                    //disabled={row.classes.some((cls: any) => !cls.shift_id)}            
                />,
            'width' : '70px'
        },
    ]
    
    const columnsForClass= [
        {
            id: "NameClass",
            name: <span className='font-weight-bold accessibility_size_table'>Класс</span>,
            cell: (row: any, index: number) => <span  className= {'ps-5 accessibility_size_table'}>{row.name}</span>,
            wrap: true,           
        },
        {
            id: "NameShift",
            name: <span className='font-weight-bold accessibility_size_table'>Смена</span>,
            cell: (row: any, index: number) =>             
                <Select 
                    options={listShifts?.length > 0 ? [{
                        value: '',
                        label: 'Не выбрано'
                    }, ...listShifts.map((v: any) => ({ value: v.id, label: v.name}))] : [{ value: '', label: 'Не выбрано' }]}
                    value={row.shift_id ? {value: row.shift_id, label: listShifts.find(shift => shift.id === row.shift_id)?.name} 
                    :  {value: '', label: 'Не выбрано'}}
                    onChange={(v: any)=> {  handleShiftChange(row, v)}}                    
                    styles={customStyles}
                    className='w-100'
                    placeholder="Поиск смен"
                    name="shift"
                />,
            wrap: true,
           'width': '290px'
        },
        {
            id: "isAdd3",
            name: <Input type="checkbox" className='fs-18' />,            
            cell: (row: any, index: number) =>
                <Input type="checkbox" className='fs-18'                       
                    checked={row.isAddClass}
                    onChange={()=> handleClassChange(index, row)}
                    disabled={!row.shift_id}
                />,
            'width' : '70px'
        },
        
    ]

    return (
        <Formik
            enableReinitialize
            initialValues={{ school: '', build: '' }}
            onSubmit={() => {
                AxiosClient.post('/schools/import_schools', listImportTable)
                    .then((r) => {
                        dispatch(setShowNotification({type: "success", message: "Готово, данные загружены!", show: true}))
                    })
                    .catch(() => {
                        console.log("error");
                        dispatch(setShowNotification({type: "error", message: "Ошибка, данные не загружены!", show: true}))
                    })
            }}>
            {() => 
                (
                    <Form>
                        <Row>
                            <Col>
                                <Card className="mt-3">
                                    <CardBody>
                                        <DataTable
                                            columns={columns}
                                            data={listImportTable}
                                            noDataComponent={'Нет данных'}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handleRowsPerPageChange}
                                            pagination
                                            paginationRowsPerPageOptions={[100, 500, 1000]}
                                            paginationPerPage={100}
                                            paginationComponentOptions={
                                                {
                                                    rowsPerPageText: 'Видно школ:',
                                                    rangeSeparatorText: 'из'
                                                }
                                            }
                                            highlightOnHover
                                            customStyles={{
                                                rows: {
                                                    highlightOnHoverStyle: {
                                                        backgroundColor: "#ffebb5", 
                                                    },
                                                    style:{
                                                        backgroundColor: "#ebebfc", 
                                                    }
                                                },
                                                headCells: {
                                                    style: {
                                                        borderRight: '1px solid #ccc',
                                                    },
                                                },
                                                cells:{
                                                    style: {
                                                        borderRight: '1px solid #ccc',
                                                    },
                                                },
                                            }}
                                            fixedHeader={true}
                                            expandableRowDisabled={(row) => row.name === 'Итого'}
                                            expandableRows
                                            expandOnRowClicked
                                            expandableRowsComponent={(row: any) => 
                                                (                                               
                                                    <Card className="mt-0 mb-0">
                                                        <CardBody className='p-0'>
                                                            <DataTable
                                                                columns={columnsForBuild}
                                                                data={row.data.build}
                                                                noDataComponent={'Нет данных'}
                                                                highlightOnHover
                                                                responsive={false}
                                                                customStyles={{
                                                                    headCells: {
                                                                        style: {
                                                                            borderRight: '1px solid #ccc',
                                                                        },
                                                                    },
                                                                    rows: {
                                                                        highlightOnHoverStyle: {
                                                                            backgroundColor: "#ffebb5"
                                                                        },
                                                                        style:{
                                                                            backgroundColor: "#f6f5ff", 
                                                                        }
                                                                    }, 
                                                                    cells:{
                                                                        style: {
                                                                            borderRight: '1px solid #ccc',
                                                                        },
                                                                    },                                                                   
                                                                }}                                                                 
                                                                noTableHead                                                                
                                                                expandableRows
                                                                expandOnRowClicked                                                                
                                                                expandableRowsComponent={(row: any) => 
                                                                    (
                                                                        <Card className="mt-0 mb-0">
                                                                            <CardBody className='p-0'>
                                                                                <DataTable
                                                                                    columns={columnsForClass}
                                                                                    data={row.data.classes}
                                                                                    noDataComponent={'Нет данных'}
                                                                                    highlightOnHover
                                                                                    responsive={false}
                                                                                    customStyles={{
                                                                                        headCells: {
                                                                                            style: {
                                                                                                borderRight: '1px solid #ccc',
                                                                                            },
                                                                                        },
                                                                                        rows: {
                                                                                            highlightOnHoverStyle: {
                                                                                                backgroundColor: "#ffebb5"
                                                                                            },
                                                                                        }, 
                                                                                        cells:{
                                                                                            style: {
                                                                                                borderRight: '1px solid #ccc',
                                                                                            },
                                                                                        },                                                                   
                                                                                    }}
                                                                                    noTableHead   
                                                                                    // onRowClicked={(row) => handleRowToggle(row, 'classes')}
                                                                                    // expandableRowExpanded={(row)=>expandedRows['classes']?.[row.name+row.schoolName+row.buildName]}                                                                                  
                                                                                />
                                                                            </CardBody>
                                                                        </Card>
                                                                    )
                                                                }
                                                                onRowClicked={(row) => handleRowToggle(row, 'builds')}
                                                                expandableRowExpanded={(row)=>expandedRows['builds']?.[row.name+row.schoolName]}
                                                            />
                                                        </CardBody>
                                                    </Card>
                                                )
                                            }
                                            onRowClicked={(row) => handleRowToggle(row, 'schools')}
                                            expandableRowExpanded={(row)=>expandedRows['schools']?.[row.name]}
                                        />
                                        <div className="hstack gap-2" >
                                            <button type="submit" className="btn btn-primary" id="addNewProject">Добавить</button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                )
            }
        </Formik>
    )
}

export default TableConfirmationImportSchool;