import React, {lazy, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {ISettingsShot, IShot} from "../../models/game/IShot";
import {IGame} from "../../models/game/IGame";
import {validateGame} from "./validateGame";
import AxiosClient from "../../api/AxiosClient";
import {Col, Container, Modal, ModalBody, ModalHeader, Row, Spinner} from "reactstrap";
import FullShot from "./constructorUpdate/FullShot";
import Notification from "../widgets/Notification";
import {ISettingsTexture} from "../../models/game/ISettingsTexture";
import RightMenuUpdate from "./RightMenu/RightMenuUpdate";
import LeftMenuUpdate from "./LeftMenu/LeftMenuUpdate";
import {TypeSettingsGenderVoice} from "../../models/game/typesSettings/TypeSettingsGenderVoice";
import {v4 as uuidv4} from 'uuid';
import {TypeSettingsShot} from "../../models/game/typesSettings/TypeSettingsShot";
import {TypeSettings} from "../../models/game/typesSettings/TypeSettings";

const GameUpdate = () => {
    const {id} = useParams()
    const [selectid, setSelectid] = useState('')
    const [update, setUpdate] = useState(false);
    const [modalGameName, setModalGameName] = useState(false);
    const [openFullShot, setOpenFullShot] = useState(false);
    const [modalAddShot, setModalAddShot] = useState(false);
    const [selectSettingsShot, setSelectSettingsShot] = useState<IShot | null>(null);
    const [game, setGame] = useState<IGame | null>(null);
    const [savingGame, setSavingGame] = useState(false);
    const [error, setError] = useState('');
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState(false);
    //const [selectCopyShot, setSelectCopyShot] = useState<IShot | null>(null);
    const [isOpenCopy, setIsOpenCopy] = useState(false);
    const [isOpenRightMenu, setIsOpenRightMenu] = useState(true);
    const [isOpenLeftMenu, setIsOpenLeftMenu] = useState(false);
    const navigate = useNavigate()

    const [IdenticalImage, setIdenticalImage] = useState([])
    const toggleSave = (game: IGame) => {
        setGame(game)
        setModalGameName(!modalGameName)
    };

    const handleOpenSettings = () => {
        setOpenFullShot(true)
        setIsOpenLeftMenu(true)
    }

    const toggleAddShotSave = () => {
        const minId = game?.shots.reduce((min, item) => (item.id < min ? item.id : min), 0) ?? 0;
        setGame(game => {
            if (game) {
                const shot: IShot = {
                    id: minId > 0 ? 0 : minId - 1,
                    index: game.shots.length,
                    uid: uuidv4(),
                    useSoundBack: false,
                    widthTextures: 250,
                    heightTextures: 250,
                    name: `Кадр ${game.shots.length}`,
                    pathHint: '',
                    startText: '',
                    hint: '',
                    personages: [],
                    textures: [],
                    backgroundImage: null,
                    backgroundSound: null,
                    isGPTChat: false,
                    NextShot: null,
                    settings: {"type": 4, "settings": []},
                    voice: TypeSettingsGenderVoice.WOMAN,
                    defaultAnimate: 'none',
                    settingsAutoPlay: {
                        play: false,
                        time: 1
                    }
                }
                setSelectSettingsShot(shot)
                setIsOpenLeftMenu(true)
                return {
                    ...game,
                    shots: [...game.shots, shot]
                }
            }
            return null
        })
        setModalAddShot(!modalAddShot)
        setOpenFullShot(true)
        setIsOpenLeftMenu(true)
    };

    const saveSettingsShot = (shot: IShot) => {
        setUpdate(true)
        setSelectSettingsShot(shot)
    }

    const saveShotInGame = () => {
        if (selectSettingsShot) {
            setGame((game) => {
                if (game) {
                    return {
                        ...game,
                        shots: game.shots.map((oldShot) => {
                            if (oldShot.uid === selectSettingsShot.uid) {
                                return selectSettingsShot
                            }
                            return oldShot
                        })
                    }
                }
                return null
            })
            setUpdate(false)
        }
        console.log(game)
    }

    const saveGame = () => {
        if (game) {
            if (update) {
                setError('Сохраните текущий кадр')
                setShowNotification(true)
            } else {
                const validError = validateGame(game)
                if (validError) {
                    setError(validError)
                    setShowNotification(true)
                } else {
                    setSavingGame(true)
                    const formData = new FormData()
                    if (game?.file) {
                        formData.append('file', game.file)
                        formData.append('data', JSON.stringify(game))
                    } else {
                        formData.append('data', JSON.stringify(game))
                    }
                    AxiosClient.post(`/game/update/${game.id}`, formData, {headers: {"Content-Type": "multipart/form-data"}})
                        .then(r => {
                            setSavingGame(false)
                            navigate('/games/teacher')
                        })
                        .catch((reason) => {
                            setError(reason.response.data.message ?? 'Ошибка создания игры')
                            setShowNotification(true)
                            setSavingGame(false)
                        })
                }
            }
        }
        console.log(game)
    }

    useEffect(() => {
        if(selectid !== id) {
            id &&
            AxiosClient.get<IGame>(`/game/game/${id}`)
                .then((r) => {
                    const convertGame: IGame = {
                        ...r.data,
                        classes: JSON.parse(r.data.classes as any) as any[],
                        shots: r.data.shots.map((shot, index) => {
                            const shotSettings = JSON.parse(shot.settings as any) as ISettingsShot
                            let settingsAutoPlay = JSON.parse(shot.settingsAutoPlay as any)
                            if (!settingsAutoPlay || settingsAutoPlay === 'null') {
                                settingsAutoPlay = {
                                    play: false,
                                    time: 1,
                                }
                            }
                            return {
                                ...shot,
                                uid: shot.uid ? shot.uid : uuidv4(),
                                index: index,
                                settings: shotSettings ? shotSettings : null,
                                settingsAutoPlay: settingsAutoPlay,
                                backgroundSound: shot.background_sound ? shot.background_sound : null,
                                backgroundImage: shot.background ? shot.background : null,
                                widthTextures: 250,
                                heightTextures: 250,
                                text: (shot as any).startText,
                                textures: shot.textures.map((texture) => {
                                    const textureSettings = JSON.parse(texture.pivot.settings as any) as ISettingsTexture;
                                    return {
                                        ...texture,
                                        pivot: {
                                            ...texture.pivot,
                                            description: texture.pivot.description ? texture.pivot.description : texture.name,
                                            settings: {
                                                ...textureSettings
                                            }
                                        }
                                    }
                                }),
                            }
                        })
                    }
                    setGame(convertGame)
                })
            if(id) {
                setSelectid(id)
            }
        }
    }, [id]);

    const deleteShot = () => {
        setIsOpen(true)
    }

    const copyShot = () => {
        setIsOpenCopy(true)
    }

    const copy = () => {
        if (selectSettingsShot) {
            const maxId = game?.shots.reduce((max, item) => Math.max(max, item.id), -Infinity)//(item.id < min ? item.id : min), 0) ?? 0;
            console.log(maxId)
                setGame(game => {
                    if (game) {
                        return {
                            ...game,
                            shots: [...game.shots, {
                                ...selectSettingsShot,
                                uid: uuidv4(),
                                id: maxId! + 1,
                                index: game.shots.length,
                                name: selectSettingsShot.name + ' - копия'
                            }]
                        }
                    }
                    return null
                })
                setIsOpenCopy(false)
            }
    }

    const removeShot = () => {
        selectSettingsShot &&
        setGame((game) => {
            if (game) {
                return {
                    ...game,
                    shots: game.shots.filter((oldShot) => oldShot.id !== selectSettingsShot.id)
                }
            }
            return null
        })
        setUpdate(false)
        setSelectSettingsShot(null)
        setIsOpen(false)
    }

    const toggleDelete = () => {
        setIsOpen((value) => !value)
    }

    const toggleCopy = () => {
        setIsOpenCopy((value) => !value)

    }

    return (
        <div className="page-content" style={{height: '100vh'}}>
            <Helmet>
                <meta charSet="utf-8"/>  
                <title>Обновление игры</title>
            </Helmet>
            <Container fluid className="h-100 pb-3">
                <Row>
                    <Col sm={12}>
                        <div className="bg-white fs-1 d-flex align-items-center gap-2 rounded shadow-lg">
                            <i className='bx bx-save cursor-pointer menu'
                               onClick={() => saveGame()}
                            ></i>
                        </div>
                    </Col>
                </Row>
                <div className="h-100 mt-3 pb-5">
                    <div className="h-100 w-100 bg-white position-relative" id="window">
                        <RightMenuUpdate saveShot={saveShotInGame} isOpen={isOpenRightMenu} setIsOpen={setIsOpenRightMenu} dataGame={game} toggleSave={toggleSave}
                                   toggleAddShotSave={toggleAddShotSave} handleOpenSettings={handleOpenSettings}
                                   setSelectSettingsShot={(shot) => {
                                       if (update) {
                                           setError('Сохраните текущий кадр')
                                           setShowNotification(true)
                                       } else {
                                           setSelectSettingsShot(shot)
                                       }
                                   }}
                                         copyShot={copyShot}
                                         deleteShot={deleteShot}
                        />
                        {
                            selectSettingsShot && game &&
                            <LeftMenuUpdate isOpen={isOpenLeftMenu} setIsOpen={setIsOpenLeftMenu} game={game}
                                            setGame={game => setGame(game)} selectedShot={selectSettingsShot}
                                            saveSettingsShot={(shot) => saveSettingsShot(shot)}/>
                        }
                        <button className="text-black btn bg-white position-absolute top-50 border"
                                disabled={!(!!selectSettingsShot)} style={{marginLeft: -20, zIndex: 20}}
                                onClick={() => setIsOpenLeftMenu(!isOpenLeftMenu)}>
                            <i className="bx bxs-right-arrow"></i>
                        </button>
                        <button className="text-black btn bg-white position-absolute end-0 top-50 border"
                                style={{marginRight: -20, zIndex: 20}} onClick={() => setIsOpenRightMenu(!isOpenRightMenu)}>
                            <i className="bx bxs-left-arrow"></i>
                        </button>
                        {
                            savingGame && <div
                                className="d-flex justify-content-center align-items-center bg-dark bg-opacity-50 position-absolute top-0 start-0 w-100 h-100"
                                style={{zIndex: 9999}}>
                                <div className="text-center">
                                    <div className="text-light fs-3 mb-3">Сохранение</div>
                                    <Spinner className="text-light" style={{width: '3rem', height: '3rem'}}/>
                                </div>
                            </div>
                        }
                        {
                            selectSettingsShot &&
                            <FullShot game={game} shot={selectSettingsShot} openFullShot={openFullShot} saveSettingsShot={saveSettingsShot}/>
                        }
                        {showNotification && <Notification type={'error'} message={error}
                                                           setShowNotification={(value: boolean) => setShowNotification(value)}/>}
                    </div>
                </div>
            </Container>
            <Modal isOpen={isOpen} toggle={toggleDelete} centered>
                <ModalHeader toggle={toggleDelete} className="p-3 bg-soft-primary">Удаление кадра</ModalHeader>
                <ModalBody>
                    Вы уверены, что хотите удалить кадр?
                    <div className="mt-3 gap-2 d-flex">
                        <button className="btn btn-primary" type="button" onClick={() => removeShot()}>
                            Удалить
                        </button>
                        <button className="btn btn-primary" type="button" onClick={toggleDelete}>
                            Отмена
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={isOpenCopy} toggle={toggleCopy} centered>
                <ModalHeader toggle={toggleCopy} className="p-3 bg-soft-primary">Копирование кадра</ModalHeader>
                <ModalBody>
                    Вы уверены, что хотите скопировать кадр?
                    <div className="mt-3 gap-2 d-flex">
                        <button className="btn btn-primary" type="button" onClick={() => copy()}>
                            Копировать
                        </button>
                        <button className="btn btn-primary" type="button" onClick={toggleCopy}>
                            Отмена
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default GameUpdate;
