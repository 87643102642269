import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import "flatpickr/dist/plugins/monthSelect/style.css";
import Select from "react-select";
import * as Yup from "yup";
import {useAppDispatch} from "../../../../hooks/redux";
import AxiosClient from "../../../../api/AxiosClient";
import {setIsSend, setModalAdd, setShowNotification} from "../../../../store/reducers/widgets/WidgetsSlice";
import {customStyles} from "../../../settings";
import SubmitWithLoading from "../../../layouts/UI/SubmitWithLoading";
import moment from "moment/moment";
import {useEscapeKey} from "../../../../hooks/useEscapeKey";

interface IModalNutritionChildrenShift {
    openModalFileNutritionChildren: boolean,
    setOpenModalFileNutritionChildren(value: boolean) : any,
    setTableConfirmationImport(value: any) : any,
    setListImport(value: any[]) : any
}

const ModalNutritionChildrenShift: FC<IModalNutritionChildrenShift> = ({openModalFileNutritionChildren, setOpenModalFileNutritionChildren, setTableConfirmationImport, setListImport}) => {
    const dispatch = useAppDispatch();

    const [organizationNutrition, setOrganizationNutrition] = useState<any[]>([]);
    const [schools, setSchools] = useState<any[]>([]);
    const [builds, setBuilds] = useState<any[]>([]);
    const [classes, setClasses] = useState<any[]>([]);
    const [shifts, setShifts] = useState<any[]>([]);

    const [selectOrganizationNutrition, setSelectOrganizationNutrition] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectSchool, setSelectSchool] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectBuild, setSelectBuild] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectClass, setSelectClass] = useState<any>({value: '', label: 'Не выбрано'});
    const [selectShist, setSelectShift] = useState<any>({value: '', label: 'Не выбрано'});

    const [date, setDate] = useState<any[]>([moment(new Date()).toDate(), moment(new Date()).toDate()]);
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [loadingOptionsBuildings, setLoadingOptionsBuildings] = useState(false);
    const [totalOptions, setTotalOptions] = useState(0);
    const [pageOption, setPageOption] = useState(1);

    useEffect(() => {
        fetchMoreSchools(1)
            .then((r) => {
                setPageOption(state => state + 1)
                setSchools(r)
            })
    }, []);

    useEffect(() => {
        if(openModalFileNutritionChildren){
            AxiosClient.get('/catering_organizaers')
                .then(r => setOrganizationNutrition(r.data))
            AxiosClient.get('/shift')
                .then(r => {
                    setShifts(r.data)
                })
        }

    }, [openModalFileNutritionChildren]);


    const onChangeBuild = (data: { value: string, label: string }) => {
        const sortClass = builds?.find(s => s.id.toString() === data.value.toString())?.class
        setSelectBuild(data)
        setClasses(sortClass)
        setSelectClass({ value: '', label: 'Не выбрано' })
    }

    const onChangeSchool = (data: { value: string, label: string }) => {
        fetchBuildings(data.value)
            .then(r => setBuilds(r))
        setSelectSchool(data)
    }

    const fetchMoreSchools = async (page: number) => {
        setLoadingOptions(true)
        const response = await AxiosClient.get(`/schools`, {params: {page: page, per_page: 3}})
        setLoadingOptions(false)
        setTotalOptions(response?.data.total)
        return response?.data.schools
    };

    const fetchBuildings = async (selectSchool: string) => {
        if(selectSchool) {
            setLoadingOptionsBuildings(true)
            const response = await AxiosClient.get(`/schools/${selectSchool}/buildings/classes`)
            setLoadingOptionsBuildings(false)
            return response?.data
        }
        return []
    };

    const loadMoreData = async () => {
        fetchMoreSchools(pageOption)
            .then(r => {
                setPageOption(state => state + 1)
                setSchools(prevOptions => [...prevOptions, ...r]);
            })

    };

    const handleMenuScroll = async (event: any) => {
        const target = event.target;
        if (totalOptions !== schools.length && target.scrollHeight - target.scrollTop === target.clientHeight) {
            await loadMoreData();
        }
    };
    useEscapeKey(() => dispatch(setModalAdd(false)), openModalFileNutritionChildren, "openModalFileNutritionChildren");


    return (
        <>
            <Modal id="openModalFileNutritionChildren" isOpen={openModalFileNutritionChildren}
                   toggle={() => setOpenModalFileNutritionChildren(!openModalFileNutritionChildren)}
                   modalClassName="zoomIn" tabIndex={-1} centered >
                <ModalHeader toggle={() => setOpenModalFileNutritionChildren(!openModalFileNutritionChildren)}
                             className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Отчет «Питание по сменам»
                </ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={
                            {
                                school: selectSchool.value,
                                build: selectBuild.value,
                                class: selectClass.value,
                            }
                        }
                        validationSchema={
                            Yup.object({
                                school: Yup.string().required('Обязательное поле'),
                                build: Yup.string().required('Обязательное поле'),
                            })
                        }
                        onSubmit={() => {
                            dispatch(setIsSend(true));
                            const params = {
                                dateStart: date[0],
                                dateEnd: date[1],
                                organization_nutrition: selectOrganizationNutrition.value,
                                school: selectSchool.value,
                                build: selectBuild.value,
                                class: selectClass.value,
                                shift: selectShist.value,
                            }

                            AxiosClient.post('/request_for_nutrition/report_nutrition_children_shift', params)
                                .then((r: any) => {
                                    setOpenModalFileNutritionChildren(false);
                                    setTableConfirmationImport("nutritionChildrenShift");
                                    setListImport(r.data);
                                    dispatch(setIsSend(false));
                                })
                                .catch((error: any) => {
                                    dispatch(setIsSend(false));
                                    dispatch(setShowNotification({type: "error", message: "Ошибка, данных!", show: true}))
                                })
                        }}>
                        {
                            ({errors, touched}) => (
                                <Form>
                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">Дата:
                                                <Flatpickr
                                                    name="dateRecord"
                                                    className="form-control border-0 dash-filter-picker shadow"
                                                    value={[moment(date[0]).toDate(), moment(date[1]).toDate()]}
                                                    options={{
                                                        locale: Russian,
                                                        mode: "range",
                                                        dateFormat: "d.m.Y",
                                                        disableMobile: true
                                                    }}
                                                    onClose={(date) => {
                                                        setDate([moment(date[0]).toDate(), moment(date[1]).toDate()])
                                                    }}
                                                />
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">Организатор питания:
                                                <Field name="organization_nutrition">
                                                    {() => (
                                                        <Select
                                                            options={organizationNutrition?.length > 0 ? [{
                                                                value: '',
                                                                label: 'Не выбрано'
                                                            }, ...organizationNutrition?.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                                                            value={selectOrganizationNutrition}
                                                            onChange={(change: any) => {
                                                                setSelectOrganizationNutrition({value: change.value, label: change.label})
                                                            }}
                                                            styles={customStyles}
                                                            className={`${errors.build && touched.build && "border rounded border-danger"}`}
                                                            placeholder="Поиск"
                                                        />
                                                    )}
                                                </Field>
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>
                                            <label  className="w-100">Школа:
                                            <Field name="school">
                                                {() => (
                                                    <Select
                                                        options={schools?.length > 0 ? [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }, ...schools.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                                                        value={selectSchool}
                                                        onChange={(change: any) => {
                                                            onChangeSchool({value: change.value, label: change.label})
                                                        }}
                                                        isLoading={loadingOptions}
                                                        onMenuScrollToBottom={handleMenuScroll}
                                                        styles={customStyles}
                                                        className={`${errors.school && touched.school && "border rounded border-danger"}`}
                                                        placeholder="Поиск"
                                                        name="school"
                                                    />
                                                )}
                                            </Field>
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">Корпус:
                                            <Field name="build">
                                                {() => (
                                                    <Select
                                                        options={builds?.length > 0 ? [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }, ...builds?.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                                                        value={selectBuild}
                                                        onChange={(change: any) => {
                                                            onChangeBuild({value: change.value, label: change.label})
                                                        }}
                                                        styles={customStyles}
                                                        className={`${errors.build && touched.build && "border rounded border-danger"}`}
                                                        placeholder="Поиск"
                                                        name="build"
                                                        isLoading={loadingOptionsBuildings}
                                                    />
                                                )}
                                            </Field>
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">Класс:
                                            <Field name="class">
                                                {() => (
                                                    <Select
                                                        options={classes?.length > 0 ? [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }, ...classes?.map(v => ({value: v.id, label: v.name}))] : [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }]}
                                                        value={selectClass}
                                                        onChange={(change: any) => {
                                                            setSelectClass({value: change.value, label: change.label})
                                                        }}
                                                        styles={customStyles}
                                                        placeholder="Поиск"
                                                        name="class"
                                                        isLoading={loadingOptionsBuildings}
                                                    />
                                                )}
                                            </Field>
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">Смена:
                                                <Field name="shift">
                                                    {() => (
                                                        <Select
                                                            options={shifts?.length > 0 ? [{
                                                                value: '',
                                                                label: 'Не выбрано'
                                                            }, ...shifts?.map(v => ({value: v.id, label: v.name}))] : [{
                                                                value: '',
                                                                label: 'Не выбрано'
                                                            }]}
                                                            value={selectShist}
                                                            onChange={(change: any) => {
                                                                setSelectShift({value: change.value, label: change.label})
                                                            }}
                                                            styles={customStyles}
                                                            placeholder="Поиск"
                                                        />
                                                    )}
                                                </Field>
                                            </label>
                                        </Col>
                                    </Row>
                                    <div className="hstack gap-2">
                                        <SubmitWithLoading text={'Готово'}/>
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    );
};

export default ModalNutritionChildrenShift;
