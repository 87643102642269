import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import WidgetService from "../../../api/services/widgetService/WidgetService";
import {setToUpdateGraphMainPage} from "../../../store/reducers/widgets/WidgetsSlice";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {ProjectsJournalHaccpMeasuringTemperetureBody} from "../../widgets/ProjectStatusCharts";

const WidgetGraphMeasuringTemperatureBody = () => {
    const {groupAllDeviations, toUpdateGraphMainPage, date, journalHaccpMeasuringTemperatureBody} = useAppSelector(state => state.WidgetReducer)
    const dispatch = useAppDispatch();

    useEffect(() => {
        toUpdateGraphMainPage &&
        WidgetService.getDeviationGroupMonth(null, date)
            .then(res => {
                dispatch(setToUpdateGraphMainPage(false))
            })
    }, [toUpdateGraphMainPage, date, dispatch])


    return (
        <>
            <Card>
                <CardHeader className="border-0 align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Журнал замера t° тела посетителей</h4>
                </CardHeader>

                <CardHeader className="p-0 border-0 bg-soft-light">
                    <Row className="g-0 text-center">
                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <span className="counter-value">
                                        {journalHaccpMeasuringTemperatureBody ?
                                            journalHaccpMeasuringTemperatureBody.filter((value) => value.temperature < 37).length
                                        : "Загрузка"}
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Количество здоровых</p>
                            </div>
                        </Col>
                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <span className="counter-value">
                                        {journalHaccpMeasuringTemperatureBody ?
                                            journalHaccpMeasuringTemperatureBody.filter((value) => value.temperature >= 37).length
                                            : "Загрузка"
                                        }
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Количество заболевших</p>
                            </div>
                        </Col>
                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <span className="counter-value">
                                        {
                                            journalHaccpMeasuringTemperatureBody ?
                                                (journalHaccpMeasuringTemperatureBody.filter((value) => value.temperature >= 37).length /
                                            journalHaccpMeasuringTemperatureBody.filter((value) => value.temperature < 37).length
                                            * 100).toFixed() + "%"
                                                : "Загрузка"
                                        }
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Процент заболевших</p>
                            </div>
                        </Col>
                    </Row>
                </CardHeader>

                <CardBody className="p-0 pb-2">
                    <div>
                        {
                            journalHaccpMeasuringTemperatureBody && <ProjectsJournalHaccpMeasuringTemperetureBody series={journalHaccpMeasuringTemperatureBody}
                                                            dataColors='["--vz-green", "--vz-warning", "--vz-danger"]'/>
                        }
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default WidgetGraphMeasuringTemperatureBody;
