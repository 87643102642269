import React, {ChangeEvent, FC, useEffect, useRef, useState} from 'react';
import {Col, Label, Modal, ModalBody, ModalHeader, Row, Spinner} from "reactstrap";
import AxiosClient from "../../../../api/AxiosClient";
import ModalCropImage from "../ModalCropImage";
import classNames from "classnames";
interface IProps {
    isAddBackgroundGenerate: boolean,
    toggleIsAddBackgroundGenerate: () => void,
    categoryBackgrounds: { id: number, name: string }[],
    addBackground: (data: any) => void
}

type WorkerResponse = {
    success: boolean;
    type: string;
    data?: string;
    error?: string;
};

const ModalTextureGenerate: FC<IProps> = ({toggleIsAddBackgroundGenerate, isAddBackgroundGenerate, categoryBackgrounds, addBackground}) => {

    const [countImage, setCountImage] = useState(1);
    const [descriptionImage, setDescriptionImage] = useState('');
    const [generateImage, setGenerateImage] = useState('');
    const [isOpenCrop, setIsOpenCrop] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [width, setWidth] = useState(1024);
    const [height, setHeight] = useState(1024);
    const [category, setCategory] = useState('null');
    const [name, setName] = useState('');
    const workerRef = useRef<Worker | null>(null);
    const [errorField, setErrorField] = useState({});

    useEffect(() => {
        setName('')
        setGenerateImage('')
        setDescriptionImage('')
    }, [isAddBackgroundGenerate])

    useEffect(() => {
        workerRef.current = new Worker(new URL('../../workerImage.ts', import.meta.url));

        workerRef.current.onmessage = (event: MessageEvent<WorkerResponse>) => {
            const { success, data, error, type } = event.data;

            if (success) {
                setGenerateImage(type + data || '');
            } else {
                setError(error || 'An unknown error occurred');
            }

            setLoading(false);
        };

        return () => {
            workerRef.current?.terminate();
        };
    }, []);

    const handleGenerate = () => {
        if (!workerRef.current) return;
        if (!descriptionImage){
            setErrorField({
                descriptionImage: 'name',
                error: 'Введите описание'
            })
            return
        }
        setErrorField({})
        setLoading(true);
        setError(null);
        setGenerateImage('');
        workerRef.current.postMessage({
            url: 'game/generate_images',
            images: countImage,
            text_generation: descriptionImage,
            width: width,
            height: height,
        });
    };

    const removeBackgroundInImages = () => {
        if (!workerRef.current) return;

        workerRef.current.postMessage({
            url: 'game/remove_background_in_image',
            image: generateImage.split(',')[1],
        });

    }

    const toggleSize = (event: ChangeEvent<HTMLSelectElement>) => {
        const size = event.target.value.split('x')
        setWidth(+size[0])
        setHeight(+size[1])
    }

    const valueSize = () => {
        return `${width}x${height}`
    }

    const addTextureHandle = () => {
        if (!name){
            setErrorField({
                name: 'name',
                error: 'Введите имя текстуры'
            })
            return
        }
        const data = {
            name: name,
            background: generateImage,
            category_background_id: category
        }
        addBackground(data)
        setName('')
        setGenerateImage('')
        setDescriptionImage('')
        setErrorField({})
    }

    return (
        <>
            <Modal isOpen={isAddBackgroundGenerate} toggle={toggleIsAddBackgroundGenerate} centered size='lg'>
                <ModalHeader toggle={toggleIsAddBackgroundGenerate}>Генерирование заднего фона</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg={12}>
                            <div>
                                <Label htmlFor='countImage'>
                                    Количество картинок
                                </Label>
                                <input type="number" id="countImage" min={1} max={3} className="form-control w-100"
                                       value={countImage} onChange={(e) => setCountImage(+e.target.value)}
                                />
                            </div>
                        </Col>
                        <Col lg={12} className="mt-2">
                            <div>
                                <Label htmlFor="descriptionImage">
                                    Описание картинок
                                </Label>
                                <input type="text" id='descriptionImage' className={classNames('form-control w-100', {
                                        'border-danger': errorField.hasOwnProperty('descriptionImage')
                                    })
                                }
                                       value={descriptionImage}
                                       onChange={(e) => setDescriptionImage(e.target.value)}
                                />
                            </div>
                        </Col>
                        <Col lg={12} className="mt-2">
                            <div>
                                <Label htmlFor="sizes">
                                    Размеры
                                </Label>
                                <select name="sizes" id="sizes" value={valueSize()} className="form-select" onChange={toggleSize}>
                                    <option value="1024x1024">1:1 (1024x1024)</option>
                                    <option value="1025x576">16:9 (1025x576)</option>
                                    <option value="576x1025">9:16 (576x1025)</option>
                                    <option value="1024x680">3:2 (1024x680)</option>
                                    <option value="680x1024">2:3 (680x1024)</option>
                                </select>
                            </div>
                        </Col>
                        <Col lg={12} className="mt-2">
                            <Label htmlFor="category">
                                Категория
                            </Label>
                            <select name="category" id="category" className="form-select" value={category} onChange={(e) => setCategory(e.target.value)}>
                                <option value={'null'}>Без категории</option>
                                {
                                    categoryBackgrounds.map((el) => (
                                        <option key={el.id} value={el.id}>{el.name}</option>
                                    ))
                                }
                            </select>
                        </Col>
                        <Col lg={12} className="mt-2">
                            <div>
                                <Label htmlFor="name">
                                    Название
                                </Label>
                                <input type="text" id='name' className={classNames('form-control w-100', {
                                    'border-danger': errorField.hasOwnProperty('name')
                                })}
                                       value={name}
                                       onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </Col>
                        <Col lg={12} className="mt-3">
                            <div className="d-flex gap-2">
                                <button type="button"
                                        onClick={() => handleGenerate()}
                                        disabled={loading}
                                        className="btn btn-primary">Сгенерировать
                                </button>
                                <button type="button"
                                        onClick={() => removeBackgroundInImages()}
                                        disabled={loading || !(!!generateImage)}
                                        className="btn btn-primary">Удалить фон
                                </button>
                                <button type="button"
                                        onClick={() => setIsOpenCrop(true)}
                                        disabled={loading || !(!!generateImage)}
                                        className="btn btn-primary">Обрезать
                                </button>
                                <button type="button"
                                        onClick={() => addTextureHandle()}
                                        disabled={loading || !(!!generateImage)}
                                        className="btn btn-primary">Сохранить
                                </button>
                            </div>
                        </Col>
                        <Col>
                            {
                                loading &&
                                    <div className="text-center">
                                        <div className="fs-3 mb-3">Идет генерация</div>
                                        <Spinner style={{width: '3rem', height: '3rem'}}/>
                                    </div>
                            }
                            {
                                !!generateImage && !loading &&
                                <img
                                    alt="Source"
                                    src={generateImage}
                                    style={{ maxWidth: "100%" }}
                                    className="mt-3"
                                />
                            }
                        </Col>
                    </Row>
                    <ModalCropImage base64={generateImage} setGenerateImage={setGenerateImage} isOpenCrop={isOpenCrop} toggleIsOpenCrop={() => setIsOpenCrop(!isOpenCrop)}/>
                </ModalBody>
            </Modal>
        </>
    );
};

export default ModalTextureGenerate;
