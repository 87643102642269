import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import WidgetService from "../../../api/services/widgetService/WidgetService";
import {setToUpdateGraphMainPage} from "../../../store/reducers/widgets/WidgetsSlice";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {ProjectsJournalHaccp, ProjectsJournalHaccpVentilationRoom} from "../../widgets/ProjectStatusCharts";
import {IDeviationGroupMonth} from "../../../models/widgets/IDeviationGroupMonth";
import WidgetTableVentilationRoom from "./WidgetTableVentilationRoom";

const WidgetGraphVentilationRoom = () => {
    const {groupAllDeviations, toUpdateGraphMainPage, date, journalHaccpVentilationRoom} = useAppSelector(state => state.WidgetReducer)
    const dispatch = useAppDispatch();

    useEffect(() => {
        toUpdateGraphMainPage &&
        WidgetService.getDeviationGroupMonth(null, date)
            .then(res => {
                dispatch(setToUpdateGraphMainPage(false))
            })
    }, [toUpdateGraphMainPage, date, dispatch])


    return (
        <>
            <Card>
                <CardHeader className="border-0 align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Журнал проветриваний помещений</h4>
                </CardHeader>

                <CardHeader className="p-0 border-0 bg-soft-light">
                    <Row className="g-0 text-center">
                        <Col xs={6} >
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <span className="counter-value">
                                        {journalHaccpVentilationRoom ? journalHaccpVentilationRoom.length : "Загрузка"}
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Количество замеров</p>
                            </div>
                        </Col>

                        <Col xs={6} >
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <span className="counter-value">
                                        {journalHaccpVentilationRoom.length > 0 ?
                                            (journalHaccpVentilationRoom.reduce((total, item) => {
                                                return total + item.temperature;
                                            }, 0) / journalHaccpVentilationRoom.length).toFixed(2)
                                            : 'Загрузка'}
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Средняя температура</p>
                            </div>
                        </Col>

                    </Row>
                </CardHeader>

                <CardBody className="p-0 pb-2">
                    <div>
                        {
                            journalHaccpVentilationRoom && <ProjectsJournalHaccpVentilationRoom series={journalHaccpVentilationRoom}
                                                            dataColors='["--vz-green", "--vz-warning", "--vz-danger"]'/>
                        }
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default WidgetGraphVentilationRoom;
