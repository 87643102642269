import React, {FC, useEffect, useRef, useState} from 'react';
import {Col, Input, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import ListBuilds from "./ListBuilds";
import ModalAddBuilding from "./ModalAddBuilding";
import AxiosClient from "../../../api/AxiosClient";
import ModalAddClass from "./ModalAddClass";
import classNames from "classnames";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {useAppDispatch} from "../../../hooks/redux";
import {setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {ICateringOrganizer} from "../../../models/KSHP/ICateringOrganizer";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import moment from "moment";
import ModalConfirmExit from "./ModalConfirmExit";
import _ from "lodash";
import {customStyles} from "../../settings";
import Select from "react-select";
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface IModelAddSchool {
    addSchool: boolean,
    setAddSchool: (value: boolean) => void,
    setSchools: (value: any) => void
}

const ModelAddSchool: FC<IModelAddSchool> = ({setAddSchool, addSchool, setSchools}) => {
    const idButtonSave: string = 'buttonSaveModelAddSchool';
    const [addClass, setAddClass] = useState(false);
    const [addBuild, setAddBuild] = useState(false);
    const [builds, setBuilds] = useState<any[]>([]);
    const [cateringOrganizers, setCateringOrganizers] = useState<ICateringOrganizer[]>([]);
    const [selectCateringOrganizers, setSelectCateringOrganizers] = useState<any>({value: '', label: 'Не выбрано'});
    const [withoutChangesBuilds, setWithoutChangesBuilds] = useState<any[]>([]);
    const [confirmExit, setConfirmExit] = useState(false);
    const [currentObject, setCurrentObject] = useState<any>(
        {name: '', address: '', max_credit_nutrition: '', max_deposit: '', isSaturday: '', catering_organizer: '',  contract: '',
            date_contract: '', date_begin: '', date_end: ''});
    const [newObject, setNewObject] = useState<any>(
        {name: '', address: '', max_credit_nutrition: '', max_deposit: '', isSaturday: '', catering_organizer: '',  contract: '',
        date_contract: '', date_begin: '', date_end: ''});
    const [isChanges, setIsChanges] = useState(false);
    const dispatch = useAppDispatch()
    const [dateContract, setDateContract] = useState(new Date());
    const [dateBegin, setDateBegin] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const [activeTab, setActiveTab] = useState("1");
    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEscapeKey(() => setAddSchool(false), addSchool, "createSchoolKSHP");

    useEffect(() => {
        AxiosClient.get<ICateringOrganizer[]>('/catering_organizaers')
            .then((r) => setCateringOrganizers(r.data))
            .catch(() => console.log('error'))
    }, []);

    useEffect(() => {
        if (addSchool) {
           setCurrentObject(
                {name: '', address: '', max_credit_nutrition: '', max_deposit: '', isSaturday: '', catering_organizer: '',  contract: '',
                    date_contract: '', date_begin: '', date_end: ''});
            setNewObject(
                {name: '', address: '', max_credit_nutrition: '', max_deposit: '', isSaturday: '', catering_organizer: '',  contract: '',
                    date_contract: '', date_begin: '', date_end: ''});
        }
    }, [addSchool]);

    const confirmOrExit = () => {
        if(!_.isEqual(currentObject, newObject)  || !_.isEqual(builds, withoutChangesBuilds)){
            setConfirmExit(true);
        }else{
            setAddSchool(false);
        }
    }
    useEscapeKey(() => dispatch(setModalAdd(false)), addSchool, "addSchool");

    return (
        <Modal id="addSchool" isOpen={addSchool}
               toggle={() => confirmOrExit()}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => confirmOrExit()}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление школы
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={
                        {
                            name: '',
                            catering_organizer: selectCateringOrganizers.value,
                            address: '',
                            max_credit_nutrition: '',
                            max_deposit: '',
                            isSaturday: false,
                            builds: [{}],
                            contract: '',
                            date_contract: '',
                            date_begin: '',
                            date_end: '',
                        }
                    }
                    validationSchema={
                        Yup.object({
                            contract: Yup.string().matches(/^[a-zA-Zа-яА-Я0-9]+$/, 'Можно использовать только буквы и цифры').required('Обязательное поле'),
                            catering_organizer: Yup.string().required('Обязательное поле'),
                            name: Yup.string().required('Обязательное поле'),
                            address: Yup.string().required('Обязательное поле'),
                            max_credit_nutrition: Yup.number().required('Обязательное поле'),
                            max_deposit: Yup.number().required('Обязательное поле'),
                        })
                    }
                    onSubmit={(values) => {
                        values.date_contract = moment(dateContract).format('YYYY-MM-DD')
                        values.date_begin = moment(dateBegin).format('YYYY-MM-DD')
                        values.date_end = moment(dateEnd).format('YYYY-MM-DD')
                        dispatch(setIsSend(true))
                        AxiosClient.post('/schools/create', {...values, builds})
                            .then(() => {
                                dispatch(setIsSend(false))
                                setAddSchool(false)
                                AxiosClient.get('/schools', {params: {page: 1, per_page: 10}})
                                    .then((r) => setSchools(r.data))
                                    .catch(() => console.log('error'))
                            })
                            .catch(() => {
                                dispatch(setIsSend(false))
                                console.log('error')
                            })

                    }}>
                    {
                        ({setFieldValue, errors, touched}) => (
                            <Form>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                     role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "1", "p-2": true})}
                                            onClick={() => {
                                                tabChange("1");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Общая информация
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "2", "p-2": true})}
                                            onClick={() => {
                                                tabChange("2");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Корпуса, классы
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "3", "p-2": true})}
                                            onClick={() => {
                                                tabChange("3");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Организатор питания
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="mt-2">
                                    <TabPane tabId="1" className="align-items-center">
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">Название школы:
                                                <Field name="name"
                                                       className={`form-control ${errors.name && touched.name && "border border-2 border-danger"}`}
                                                       onChange={(e: any) => {
                                                            setNewObject({...newObject, 'name': e.target.value});
                                                           setFieldValue('name', e.target.value);
                                                       }}
                                                />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">Адрес:
                                                <Field name="address"
                                                       className={`form-control ${errors.address && touched.address && "border border-2 border-danger"}`}
                                                       onChange={(e: any) => {
                                                            setNewObject({...newObject, 'address': e.target.value});
                                                           setFieldValue('address', e.target.value);
                                                       }}
                                                />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">Макс. кредит питания:
                                                <Field name="max_credit_nutrition"
                                                       className={`form-control ${errors.max_credit_nutrition && touched.max_credit_nutrition && "border border-2 border-danger"}`}
                                                       onChange={(e: any) => {
                                                            setNewObject({...newObject, 'max_credit_nutrition': e.target.value});
                                                           setFieldValue('max_credit_nutrition', e.target.value);
                                                       }}
                                                />
                                                </label>
                                            </Col>
                                            <Col>
                                                <label className="w-100">Макс. сумма пополнения:
                                                <Field name="max_deposit"
                                                       className={`form-control ${errors.max_deposit && touched.max_deposit && "border border-2 border-danger"}`}
                                                       onChange={(e: any) => {
                                                            setNewObject({...newObject, 'max_deposit': e.target.value});
                                                           setFieldValue('max_deposit', e.target.value);
                                                       }}
                                                />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col className="d-flex align-items-center mt-1">
                                                <label className="m-0 w-100">Учитывать субботу:
                                                <Field name="isSaturday" type="checkbox">
                                                    {({
                                                          field,
                                                      }: any) => (
                                                            <Input name="isSaturday" type="checkbox" {...field}
                                                                   className="mx-2 my-0" style={{fontSize: 18}}
                                                                   onChange={(e: any) => {
                                                                        setNewObject({...newObject, 'isSaturday': e.target.checked});
                                                                       setFieldValue('isSaturday', e.target.checked);
                                                                   }}
                                                            />
                                                    )}
                                                </Field>
                                                </label>
                                            </Col>
                                        </Row>
                                        <div className="hstack gap-2">
                                            <SubmitWithLoading id={idButtonSave} text={"Добавить"}/>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="2" className="align-items-center">
                                        <Row>
                                            <Col>
                                                <ListBuilds builds={builds}/>
                                            </Col>
                                        </Row>
                                        <div className="hstack gap-2 mt-2">
                                            <button className="btn btn-primary" type="button" id="addNewProject"
                                                    onClick={() => setAddBuild(true)}>Добавить корпус
                                            </button>
                                            <button className="btn btn-primary" type="button" id="addNewProject"
                                                    onClick={() => setAddClass(true)}>Добавить класс
                                            </button>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="3" className="align-items-center">
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">Организатор питания:
                                                    <Select
                                                        options={cateringOrganizers?.length > 0 ? [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }, ...cateringOrganizers.map(v => ({value: v.id.toString(), label: v.name}))] : [{
                                                            value: '',
                                                            label: 'Не выбрано'
                                                        }]}
                                                        value={selectCateringOrganizers}
                                                        onChange={(change: any) => {
                                                            setSelectCateringOrganizers({value: change.value, label: change.label});
                                                            setNewObject({...newObject, 'catering_organizer': change.value});
                                                            setFieldValue('catering_organizer', change.value);
                                                        }}
                                                        styles={customStyles}
                                                        placeholder="Поиск"
                                                        name="catering_organizer"
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">Номер договора с ОП:
                                                <Field name="contract" className={`form-control ${errors.contract && touched.contract && "border border-2 border-danger"}`}
                                                       onChange={(e: any) => {
                                                            setNewObject({...newObject, 'contract': e.target.value});
                                                           setFieldValue('contract', e.target.value);
                                                       }}
                                                />
                                                </label>
                                            </Col>
                                            <Col>
                                                <label className="w-100">Дата договора с ОП:
                                                <Flatpickr
                                                    className="form-control border-0 dash-filter-picker shadow"
                                                    value={dateContract}
                                                    options={{
                                                        locale: Russian, dateFormat: "d M Y"
                                                    }}
                                                    onClose={(date) => {
                                                        setDateContract(date[0])
                                                        setNewObject({...newObject, 'date_contract': date[0]});
                                                    }
                                                }
                                                /></label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">Дата с:
                                                <Flatpickr
                                                    className="form-control border-0 dash-filter-picker shadow"
                                                    value={dateBegin}
                                                    options={{
                                                        locale: Russian, dateFormat: "d M Y"
                                                    }}
                                                    onClose={(date) => {
                                                        setDateBegin(date[0])
                                                        setNewObject({...newObject, 'date_begin': date[0]});
                                                    }}
                                                /></label>
                                            </Col>
                                            <Col>
                                                <label className="w-100">Дата по:
                                                <Flatpickr
                                                    className="form-control border-0 dash-filter-picker shadow"
                                                    value={dateEnd}
                                                    options={{
                                                        locale: Russian, dateFormat: "d M Y"
                                                    }}
                                                    onClose={(date) => {
                                                        setDateEnd(date[0])
                                                        setNewObject({...newObject, 'date_end': date[0]});
                                                    }}
                                                /></label>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
            <ModalConfirmExit idButtonSave={idButtonSave} setUpdate={value => setAddSchool(value)} confirmExit={confirmExit} setConfirmExit={value => setConfirmExit(value)} />
            <ModalAddBuilding setBuilds={(value) => setBuilds([...builds, value])} addClass={addBuild}
                              setAddClass={(value) => setAddBuild(value)}/>
            <ModalAddClass builds={builds} setBuilds={(value) => setBuilds(value)} addClass={addClass}
                           setAddClass={value => setAddClass(value)}/>
        </Modal>
    );
};

export default ModelAddSchool;
