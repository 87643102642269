import React, {FC} from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";
import DataTable from "react-data-table-component";

interface ITableConfirmationExportNutritionClildren {
    listImport: any,
    daysInMonth: number
}

const TableConfirmationExportNutritionClildren: FC<ITableConfirmationExportNutritionClildren> = ({listImport, daysInMonth}) => {

    const columns = [...Array(daysInMonth)].map((_, index) => ({
        name: <span className='font-weight-bold accessibility_size_table mx-2'>{index + 1}</span>,
        cell: (row: any) => <span className='m-auto accessibility_size_table'>{row.nutrition[index] === 0? '-' : row.nutrition[index]}</span>,
        sortable: true,
        compact: true,
        'width': '30px',
        wrap: false
    }));

    columns.unshift({
        name: <span className='font-weight-bold accessibility_size_table mx-1'>ФИО ребенка (лицевой счет)</span>,
        cell: (row: any) => <span className='px-1 accessibility_size_table'>{row.student.last_name+' '+row.student.name+' '+row.student.middle_name+' ('+row.personal_account+')'}</span>,
        sortable: true,
        compact: true,
        'width': '192px',
        wrap: true

    },
        {
            name: <span className='font-weight-bold accessibility_size_table mx-1'>Сумма</span>,
            cell: (row: any) => <span className='m-auto accessibility_size_table'>{row.nutrition_sum === 0? '-' : row.nutrition_sum}</span>,
            sortable: true,
            compact: true,
            'width': '45px',
            wrap: true
        });


    return (
        <Row>
            <Col>
                <Card className="my-0">
                    <CardBody className='py-0'>
                        <DataTable
                            columns={columns}
                            data={listImport}
                            noDataComponent={'Нет данных'}
                            noTableHead
                            highlightOnHover
                            customStyles={{
                                headCells: {
                                    style: {
                                        borderRight: '1px solid #ccc',
                                    },
                                },
                                rows: {
                                    highlightOnHoverStyle: {
                                        backgroundColor: "#ffebb5"
                                    },

                                },
                                cells:{
                                    style: {
                                        borderRight: '1px solid #ccc'
                                    },
                                },
                                expanderRow: {
                                    style: {
                                        backgroundColor: "#ffebb5",
                                        paddingTop: '5px',
                                        paddingBottom: '5px'
                                    },
                                },
                            }}
                            className='py-0'

                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default TableConfirmationExportNutritionClildren;
