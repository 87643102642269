import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    setDateAdd, setGroupModalAdd,
    setIsSend,
    setModalAdd,
    WidgetsSlice
} from "../../../store/reducers/widgets/WidgetsSlice";
import {Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import moment from "moment/moment";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import AxiosClient from "../../../api/AxiosClient";
import {getJournalPurchaseMaterials} from "../../../store/reducers/widgets/ActionCreator";
import classNames from "classnames";
import * as Yup from "yup";
import Notification from "../../widgets/Notification";
import WidgetTableMaterials from "../tables/WidgetTableMaterials";
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface IModalAddPurchaseMaterials{
    updateData: any
}

const ModalAddPurchaseMaterials: FC<IModalAddPurchaseMaterials> = ({updateData}) => {
    const dispatch = useAppDispatch();
    const {modalAdd, date, groupModalAdd, groups, dateAdd, successUpdate} = useAppSelector(state => state.WidgetReducer);

    const [dateFrom, setDateFrom] = useState(moment().startOf('day').toDate());
    const [dateTo, setDateTo] = useState(moment().startOf('day').toDate());
    const [activeTab, setActiveTab] = useState("1");
    const [addMyMaterials, setAddMyMaterials] = useState<any>();

    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<string>('');
    const [notificationMessage, setNotificationMessage] = useState<string>('');


    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(WidgetsSlice.actions.successUpdate(""))
        }, 5000)
        return () => clearTimeout(timer)
    }, [successUpdate, dispatch]);

    useEffect(() => {
        if (updateData) setAddMyMaterials(updateData.materials);
        else setAddMyMaterials([]);
    }, [updateData]);

    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    useEscapeKey(() => dispatch(setModalAdd(false)), modalAdd, "ModalAddPurchaseMaterials");


    return (
        <>
        <Modal id="ModalAddPurchaseMaterials" isOpen={modalAdd}
               modalClassName="zoomIn" tabIndex={-1} centered toggle={() => dispatch(setModalAdd(!modalAdd))}>
            <ModalHeader toggle={() => dispatch(setModalAdd(!modalAdd))} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление заявки на приобретение материалов
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        group: `${groupModalAdd}`,
                        date: moment(dateAdd).format("DD-MM-YYYY"),
                        data_from: updateData? updateData.timeStart : '',
                        data_to: updateData? updateData.timeEnd : '',
                        description: updateData? updateData.description :'',
                        materials: addMyMaterials,
                    }}
                    validationSchema={
                        Yup.object({
                            disinfectants: Yup.array().min(1, 'Требуется добавить дезинфицирующее средство')
                        })}
                    onSubmit={(value) => {
                        if (addMyMaterials){
                            dispatch(setIsSend(true));
                            let param = {
                                uid: updateData? updateData.uid : '',
                                create_date: updateData? updateData.data : moment(dateAdd).format("DD.MM.YYYY"),
                                data_from: moment(dateFrom).format("DD.MM.YYYY"),
                                data_to: moment(dateTo).format("DD.MM.YYYY"),
                                cabinet_uid : groupModalAdd,
                                description: value.description,
                                materials: addMyMaterials,
                            }

                            AxiosClient.post('saveJournalPurchaseMaterials', param)
                                .then((r: any) => {
                                    dispatch(getJournalPurchaseMaterials(date, groupModalAdd)).then();
                                    dispatch(setIsSend(false));
                                    dispatch(setModalAdd(false));
                                    setNotificationType('success');
                                    setNotificationMessage('Готово, журнал добавлен!');
                                    setShowNotification(true);
                                })
                                .catch((error) => {
                                    dispatch(setIsSend(false));
                                    setNotificationType('error');
                                    setNotificationMessage('Ошибка, проверьте корректность данных!');
                                    setShowNotification(true);
                                })
                        }
                        else {

                            setNotificationType('error');
                            setNotificationMessage('Ошибка, требуется добавить дезинфицирующее средство!');
                            setShowNotification(true);
                        }
                    }}
                >
                    {({ errors, touched, setFieldValue }) => (
                    <Form>
                        <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                             role="tablist">
                            <NavItem>
                                <NavLink
                                    className={classNames({active: activeTab === "1", "p-2": true})}
                                    onClick={() => {
                                        tabChange("1");
                                    }}
                                    type="button">
                                    <i className="fas fa-home"></i>
                                    Основное
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classNames({active: activeTab === "2", "p-2": true})}
                                    onClick={() => {
                                        tabChange("2");
                                    }}
                                    type="button">
                                    <i className="fas fa-home"></i>
                                    Материалы
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab} className="mt-2">
                            <TabPane tabId="1" className="align-items-center">
                                <div className="col-auto">
                                    <label>Дата:</label>
                                    <div className="col mb-3">
                                        <div className="input-group col-auto">
                                            <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                value={dateAdd}
                                                options={{
                                                    locale: Russian, dateFormat: "d M Y", disableMobile: true, mode: 'single'
                                                }}
                                                onChange={(date) => {
                                                    dispatch(setDateAdd(moment(date[0]).toDate()))
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="w-100">Группа:
                                        <Field as="select" className="form-select shadow" value={groupModalAdd}
                                               onChange={(e: any) => dispatch(setGroupModalAdd(e.target.value))} name="group">
                                            {groups.map((group) => {
                                                return <option key={group.group_id}
                                                               value={group.group_id}>{group.group_name}</option>
                                            })}
                                        </Field>
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label className='w-100'>Дата с:
                                        <div className="col mb-3">
                                            <div className="input-group col-auto">
                                                <Flatpickr
                                                    className="form-control border-0 dash-filter-picker shadow"
                                                    value={dateFrom}
                                                    options={{
                                                        locale: Russian,
                                                        dateFormat: "d.m.Y",
                                                        disableMobile: true,
                                                    }}
                                                    onChange={(date) => setDateFrom(date[0])}
                                                />
                                            </div>
                                        </div>
                                    </label>
                                    <label className='w-100'>Дата по:
                                        <div className="col mb-3">
                                            <div className="input-group col-auto">
                                                <Flatpickr
                                                    className="form-control border-0 dash-filter-picker shadow"
                                                    value={dateTo}
                                                    options={{
                                                        locale: Russian,
                                                        dateFormat: "d.m.Y",
                                                        disableMobile: true,
                                                    }}
                                                    onChange={(date) => setDateTo(date[0])}
                                                />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label className="w-100">Обоснование:
                                        <Field name="description" className={`form-control`}/>
                                    </label>
                                </div>
                            </TabPane>
                            <TabPane tabId="2" className="align-items-center">
                                <WidgetTableMaterials addMyMaterials={addMyMaterials} setAddMyMaterials={(value) => setAddMyMaterials(value)}/>
                            </TabPane>
                        </TabContent>

                        {errors.materials && (
                            <div style={{ color: 'red', marginTop: '5px' }}>Требуется выбрать материалы!</div>
                        )}
                        <div className="hstack gap-2 justify-content-end mt-1">
                            <SubmitWithLoading text={"Сохранить"}/>
                        </div>
                    </Form>

                    )}
                </Formik>
            </ModalBody>
        </Modal>
        {showNotification && <Notification type={notificationType} message={notificationMessage}
                                       setShowNotification={(value: boolean) => setShowNotification(value)}/>}
    </>

);
};

export default ModalAddPurchaseMaterials;
