
import React, {FC, useState} from "react"
import DataTable from "react-data-table-component";
import {Field} from "formik";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {setLoadingStudentsDO} from "../../../../store/reducers/widgets/WidgetsSlice";

interface ITableChildInfoDO {
    student: any,
}

const TableChildInfoDO: FC<ITableChildInfoDO> = ({student}) => {
    const dispatch = useAppDispatch();
    const {studentsDO} = useAppSelector(state => state.WidgetReducer);
    const [grades, setGrades] = useState(student[0]?.grade || []);

    const handleSelectChange = (gradeId: string, selectedValue: string) => {
        const updatedGrades = grades.map((grade: any) => {
            if (grade.qw_grade_id === gradeId) {
                return {
                    ...grade,
                    value: grade.value.map((item: any) => ({
                        ...item,
                        default: item.grade_id === selectedValue ? "Да" : "Нет",
                    })),
                };
            }
            return grade;
        });
        setGrades(updatedGrades);

        const updatedStudent = { ...student[0], grade: updatedGrades };
        const updatedStudentsDO = studentsDO.map(s =>
            s.code === updatedStudent.code ? updatedStudent : s
        );
        dispatch(setLoadingStudentsDO(updatedStudentsDO));
    };

    const columns = [
        {
            cell: (row: any) => <span className="accessibility_size_table">{row.qw_grade_name}</span>,
            style: {padding: '0'},
            wrap: true
        },
        {
            cell: (row: any) => (
                <Field as="select"
                       className="form-select table-add-ceil selectStudentsDev"
                       name={`childInfoDO.${row.grade_id}`}
                       value={row.value.find((item: any) => item.default === "Да")?.grade_id || ""}
                       onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                           handleSelectChange(row.qw_grade_id, e.target.value)
                       }
                >
                    {
                        (row.value || []).map((item: any) => (
                            <option key={item.grade_id} value={item.grade_id}>{item.grade_name}</option>
                        ))
                    }
                </Field>
            ),
            center: true,
            style: {padding: '0'}
        }
    ]

    return (
        <>
            <DataTable
                columns={columns}
                data={grades}
                noTableHead
                noDataComponent={'Нет информации'}
                highlightOnHover
                customStyles={{
                    rows: {
                        highlightOnHoverStyle: {
                            backgroundColor: "#ffebb5"
                        },
                        style: {
                            paddingLeft: '65px'
                        },
                    }
                }}
            />
        </>
    )
}

export default TableChildInfoDO;
