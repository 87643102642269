import { useEffect } from "react";

/**
 * Хук для обработки нажатия клавиши Escape с учётом вложенности модальных окон.
 * @param callback Функция, вызываемая при нажатии Escape.
 * @param isActive Флаг, показывающий, активно ли окно.
 * @param modalId Уникальный id модального окна.
 */
export function useEscapeKey(callback: () => void, isActive: boolean, modalId: string) {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                event.stopPropagation();

                const modalElement = document.getElementById(modalId);
                if (!modalElement) return;

                const modals = Array.from(document.querySelectorAll(".modal.show"));
                const topModal = modals[modals.length - 1];

                if (modalElement === topModal) {
                    callback();
                }
            }
        };

        if (isActive) {
            document.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [callback, isActive, modalId]);
}
