import React, {useEffect, useState} from 'react';
import AxiosClient from "../../../api/AxiosClient";
import {Card, CardBody, CardFooter, Col, Row} from "reactstrap";
import DataTable from "react-data-table-component";
import ModalAddShift from "./ModalAddShift";
import ModalUpdateShift from "./ModalUpdateShift";
import {setShowNotification} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../hooks/redux";

const ListShift = () => {
    const dispatch = useAppDispatch()

    const [shifts, setShifts] = useState<any[]>([]);
    const [addShift, setAddShift] = useState(false);
    const [selectShift, setSelectShift] = useState<any | null>(null);
    const [updateShift, setUpdateShift] = useState(false);

    useEffect(() => {
        AxiosClient.get('/shift')
            .then(r => {
                setShifts(r.data)
            })
    }, []);

    const columns: any = [
        {
            id: "date",
            name: <span className='font-weight-bold accessibility_size_table'>Наименование</span>,
            cell: (row: any) => <span className='accessibility_size_table'> {row.name}</span>,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'></span>,
            selector: (row: any) => <i className="bx bx-archive-in fs-3 cursor-pointer" title="Добавить в архив" onClick={() => {
                AxiosClient.post(`/shift/archive/${row.id}`)
                    .then(() => {
                        dispatch(setShowNotification({type: "success", message: "Смена добавлена в архив", show: true}))
                        AxiosClient.get('/shift')
                            .then(r => setShifts(r.data))
                    })
                    .catch((e: any) => {
                        dispatch(setShowNotification({type: "error", message: "Ошибка при добавлении в архив", show: true}))
                    })
            }}></i>,
            width: '60px',
        }
    ]

    return (
        <>
            <Row>
                <Col>
                    <Card className="mt-2">
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={shifts}
                                noDataComponent={'Нет данных'}
                                pagination
                                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно смен:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        },
                                    },
                                    expanderRow: {
                                        style: {
                                            backgroundColor: "#ffebb5",
                                            paddingTop: '5px',
                                            paddingBottom: '5px'
                                        },
                                    },
                                }}
                                onRowClicked={
                                    (row) => {
                                        if(window.innerWidth <= 768){
                                            setSelectShift(row)
                                            setUpdateShift(true)
                                        }
                                    }
                                }
                                onRowDoubleClicked={
                                    (row) => {
                                        if(window.innerWidth > 768){
                                            setSelectShift(row)
                                            setUpdateShift(true)
                                        }
                                    }
                                }
                            />
                        </CardBody>
                        <CardFooter>
                            <button className="btn btn-primary" onClick={() => setAddShift(true)}>Добавить</button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>

            <ModalAddShift addShift={addShift} setAddShift={(value) => setAddShift(value)} setShifts={(value) => setShifts(value)} />
            <ModalUpdateShift updateShift={updateShift} setUpdateShift={(value) => setUpdateShift(value)} setShifts={(value) => setShifts(value)} selectShift={selectShift} />

        </>
    );
};

export default ListShift;
