import React, {useEffect} from 'react';
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import TableInfoEvolutionsChildren from "../../components/parent/table/TableInfoEvolutionsChildren";
import GraphDonutChildrenEvolution from "../../components/parent/graph/GraphDonutChildrenEvolution";
import TableAllInfoEvolutionsChildren from "../../components/parent/table/TableAllInfoEvolutionsChildren";
import {ParentSlice} from "../../store/reducers/parent/ParentSlice";
import {getAllInfoEvolutionChild, getChildren} from "../../store/reducers/parent/ActionCreator";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import TableSelectDateEvolutionsChildren from "../../components/parent/table/TableSelectDateEvolutionsChildren";
import {Helmet} from "react-helmet";

const ChildrenEvolution = () => {

    const dispatch = useAppDispatch()
    const {children, child, selectDatesEvolution} = useAppSelector(state => state.ParentReducer)
    useEffect(() => {
        dispatch(getChildren()).then()
    }, [dispatch]);

    useEffect(() => {
        child &&
        dispatch(getAllInfoEvolutionChild(child, selectDatesEvolution)).then()
    }, [child, selectDatesEvolution, dispatch])

    return (
        <div className="page-content">
            <Container fluid>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Развитие ребенка</title>
                </Helmet>
                <Row>
                    <Col xxl={3}>
                        <Card>
                            <CardHeader className="card_header_size_text">
                                Ребенок:
                                <select className="form-select mt-1 fs-14" value={child}
                                        onChange={(event) => dispatch(ParentSlice.actions.setChild(event.target.value))}>
                                    {
                                        children.map(child =>
                                            (
                                                <option key={child.account_uid} value={child.account_uid}
                                                        className="fs-14">{child.account_name}</option>
                                            )
                                        )
                                    }
                                </select>
                            </CardHeader>
                            <CardBody>
                                <TableInfoEvolutionsChildren/>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <TableSelectDateEvolutionsChildren/>
                            </CardBody>
                        </Card>
                        <GraphDonutChildrenEvolution/>
                    </Col>
                    <Col>
                        <div>
                            <Card>
                                <CardHeader className="card_header_size_text">
                                    Развитие ребенка
                                </CardHeader>
                                <CardBody className='py-0'>
                                    <TableAllInfoEvolutionsChildren/>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ChildrenEvolution;
