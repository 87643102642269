import React, {useEffect, useState, lazy, Suspense} from 'react';
import {useParams} from "react-router-dom";
import AxiosClient from "../../api/AxiosClient";
import bgsound from '../../assetsGame/sounds/bgsoundmenu.mp3'
import bg from '../../assetsGame/RPG/bgStartEnd.png'
import btn from '../../assetsGame/Menu/FonButton.png'
import bgmenu from '../../assetsGame/Menu/OsnovnoyFonMenu.png'
import shapka from '../../assetsGame/Menu/Shapka.png'
import {Spinner} from "reactstrap";
import {IShot} from "../../models/game/IShot";
import {IGame} from "../../models/game/IGame";
import {useStateContext} from "../../hooks/useStateContext";
import './style.scss'
import cn from "classnames";
import Seconder from "./timer/Seconder";
import moment from "moment";
import {v4 as uuidv4} from 'uuid';
import {ISettingsTexture} from "../../models/game/ISettingsTexture";
import {TypeSettings} from "../../models/game/typesSettings/TypeSettings";
import Recorder from "recorder-js";

const Shot = lazy(() => import("./play/Shot"));

const OpenGame = () => {
    const {id} = useParams();
    const [selected, setSelectid] = useState('')
    const {user} = useStateContext();
    const [start, setStart] = useState(true);
    const [isFullScreen, setIsFullScreen] = useState(true);
    const [end, setEnd] = useState(false);
    const [game, setGame] = useState<IGame | null>(null);
    const [shots, setShots] = useState<IShot[]>([]);
    const [shotIndex, setShotIndex] = useState(-1);
    const [nextSelectShot, setNextSelectShot] = useState<IShot | null>(null);
    const [selectShot, setSelectShot] = useState<IShot | null>(null);
    const [rotate, setRotate] = useState(false);
    const [loadingGame, setLoadingGame] = useState(true);
    const [result, setResult] = useState<{all: number, true: number, error: number, shots: any[], time: string, typeTime: string}>({
        all: 0,
        true: 0,
        error: 0,
        shots: [],
        time: '00:00:00',
        typeTime: ''
    });
    const [seconds, setSeconds] = useState(0);
    const [timerStart, setTimerStart] = useState(false);

    const [recorder, setRecorder] = useState<Recorder | null>(null);

    useEffect(() => {
            const audioContext = new (window.AudioContext)();

            navigator.mediaDevices.getUserMedia({audio: true})
                .then(stream => {
                    const rec = new Recorder(audioContext);
                    rec.init(stream);
                    setRecorder(rec);
                })
                .catch(error => {
                    console.error('Error accessing microphone:', error);
                })
    }, []);

    useEffect(() => {
        if(selected !== id) {
            id &&
            AxiosClient.get<IGame>(`/game/game/${id}`)
                .then((r) => {
                    const data: IGame = {
                        ...r.data,
                        shots: r.data.shots.map((el) => ({...el, uid: el.uid ? el.uid : uuidv4()}))
                    }
                    setGame(data)
                    setShots(data.shots)
                    setLoadingGame(false)
                    let typeTime = ''
                    if (data.timer) {
                        if (data.closeTimer) {
                            typeTime = 'timer'
                            setSeconds(data.time ? moment.duration(data.time).asSeconds() : 0)
                        } else {
                            typeTime = 'seconder';
                        }
                    }
                    setResult((prevState) => ({
                        ...prevState,
                        all: data.shots.filter((el) => {
                            const s = JSON.parse(el.settingsAutoPlay as any)
                            if (s && s !== 'null')
                                return !s.play
                            return true
                        }).length,
                        typeTime: typeTime
                    }))
                })
                .catch(() => setLoadingGame(false))
           if(id) {
                setSelectid(id)
            }
        }


    }, [id]);

    useEffect(() => {
        const checkOrientation = () => {
            if (window.screen.orientation.type.startsWith("portrait")) {
                setRotate(false)
                console.warn("Пожалуйста, переверните устройство в альбомную ориентацию для лучшего игрового опыта.");
            } else {
                setRotate(true)
                console.log("Устройство находится в альбомной ориентации.");
            }
            if (document.body.requestFullscreen) {
                document.body.requestFullscreen().catch(err => {
                    console.error("Не удалось перейти в полноэкранный режим:", err);
                });
            } else {
                console.warn("Ваш браузер не поддерживает полноэкранный режим.");
            }
        };

        // Первоначальная проверка ориентации при загрузке
        checkOrientation();

        // Проверка ориентации при её изменении
        window.addEventListener("orientationchange", checkOrientation);
        // Удаление обработчика события при размонтировании компонента
        return () => {
            window.removeEventListener("orientationchange", checkOrientation);
        };
    }, []);

    const nextShotTexture = (shot: IShot) => {
        const find = shots.find((el, index) => el.uid === shot.uid)
        if (find) {
            const findIndex = shots.findIndex((el, index) => el.uid === shot.uid)
            setShotIndex(findIndex)
            setSelectShot(find)
            setNextSelectShot(shots[findIndex + 1] ?? null)
        }
    }

    const nextShot = (value?: {id: number, time: number}) => {
        const findSwitch = selectShot?.textures.find((el) => (JSON.parse(el.pivot.settings as any) as ISettingsTexture).type === TypeSettings.SWITCH_SHOT)
        if (!findSwitch){
            if (shotIndex === shots.length - 1){
                setSelectShot(null)
                setEnd(true)
                setTimerStart(false)
                if (value) {
                    setResult((result) => (
                        {
                            ...result,
                            shots: [...result.shots, value],
                            time: moment.utc(moment.duration(seconds, "seconds").asMilliseconds()).format("HH:mm:ss")
                        }
                    ))
                }
            } else {
                if (value) {
                    setResult((result) => (
                        {
                            ...result,
                            shots: [...result.shots, value]
                        }
                    ))
                }
                setShotIndex(prevState => prevState + 1)
                setSelectShot(shots[shotIndex + 1])
                setNextSelectShot(shots[shotIndex + 2] ?? null)
            }
        }
    }

    const onChangeTrue = () => {
        setResult((prevState) => ({
            ...prevState,
            true: prevState.true + 1
        }))
    }

    const onChangeError = () => {
        setResult((prevState) => ({
            ...prevState,
            error: prevState.error + 1
        }))
    }

    const showSeconder = () => {
        if(game && !start && !end) {
            if (game.timer) {
                return <div className="bg-white bg-opacity-75">
                    <Seconder game={game} setSeconds={setSeconds} seconds={seconds} timerStart={timerStart} setTimerStart={setTimerStart} type={result.typeTime as any}/>
                </div>
            }
        }
    }

    const backimage = () => {
        if (!game) return bg;
        if (!game.file) return bg;
        return process.env.REACT_APP_API_BASE_URL ? game.file : bg
    }

    const backsound = () => {
        if (!game) return bgsound;
        if (!game.file_music) return bgsound;
        return process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL + game.file_music : bgsound
    }

    const PlayAudioStartMenu = () => {
        if (start || end) {
            if (game) {
                return (
                    <audio
                        loop
                        autoPlay>
                        <source src={`${backsound()}`}
                                type='audio/mp3'/>
                    </audio>
                )
            }
        }
    }

    return (
        <>
            <div className={cn({normal: !isFullScreen, fullScreen: isFullScreen})} id="window">
                {
                    loadingGame && <div
                        className="d-flex justify-content-center align-items-center bg-dark bg-opacity-75 position-absolute top-0 start-0 w-100 h-100"
                        style={{zIndex: 9999}}>
                        <div className="text-center">
                            <div className="text-light fs-3 mb-3">Загрузка игры</div>
                            <Spinner className="text-light" style={{width: '3rem', height: '3rem'}}/>
                        </div>
                    </div>
                }
                {
                    !rotate && !loadingGame && <div
                        className="d-flex justify-content-center align-items-center bg-dark bg-opacity-75 position-absolute top-0 start-0 w-100 h-100"
                        style={{zIndex: 9999}}>
                        <div className="text-center">
                            <div className="text-light fs-3 mb-3">Переверните телефон</div>
                        </div>
                    </div>
                }
                <div className="position-absolute start-0 m-2" style={{zIndex: 10}}>
                    {
                        showSeconder()
                    }
                </div>
                {
                    start &&
                <div className="position-absolute end-0 cursor-pointer" id="openFull" style={{zIndex: 10, margin: "0.5vw"}} onClick={() => {
                    setIsFullScreen((prevState) => {
                        if (!prevState) {
                            document.body.requestFullscreen()
                            return !prevState
                        }
                        document.fullscreenElement &&
                        document.exitFullscreen()
                        return !prevState
                    })
                }}
                >
                    <i className="bx bx-fullscreen text-black fw-bold" style={{fontSize: "1.5vw"}}></i>
                </div>
                }
                {
                    start &&
                    <div className="flex-column d-flex align-items-center"
                         style={{height: '100%', width: '100%', backgroundImage: `url(${backimage()})`, backgroundSize: '100% 100%', backgroundPosition: 'center',
                             backgroundRepeat: 'no-repeat'}}>
                        {
                            PlayAudioStartMenu()
                        }
                        <div className="d-flex justify-content-center align-items-center"
                             style={{marginTop: '1.2vw', height: `12vw`, width: `50vw`, backgroundImage: `url(${shapka})`, backgroundSize: '100% 100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
                            <div className="overflow-hidden d-flex justify-content-center align-items-center" style={{width: '27vw', height: '5vw', marginTop: '1vw'}}>
                                <span className = 'align-items-center' style={{ wordBreak: "break-word", lineHeight: '2vw', textAlign: "center", fontFamily: 'Ratanegra-Cyrillic', fontSize: `2vw`}}>{game?.name}</span>
                            </div>
                            </div>
                        <div className="center flex-column d-flex justify-content-center align-items-center"
                             style={{marginTop: '1.7vw', height: '25vw', width: `30vw` , backgroundImage: `url(${bgmenu})`, backgroundSize: '100% 100%',
                                 backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
                                    <div className="flex-column d-flex justify-content-center align-items-center">
                                        <div className="button_tik flex-column d-flex justify-content-center align-items-center w-100" style={{ backgroundImage: `url(${btn})`,
                                            backgroundSize: '100% 100%', backgroundPosition: 'center', backgroundRepeat: "no-repeat"}}>
                                            <button type={"button"} className="position-relative pt-2 border-0 bg-transparent cursor-pointer fw-bold text-black text-center"
                                                    style={{width: `23vw`, fontFamily: 'Ratanegra-Cyrillic',
                                                fontSize: `3vw`}} onClick={() => {
                                                setStart(false)
                                                nextShot()
                                                setTimerStart(true)
                                            }}>ИГРАТЬ</button>
                                        </div>
                                            <div className="button_tik flex-column d-flex justify-content-center align-items-center w-100" style={{ backgroundImage: `url(${btn})`,
                                                backgroundSize: '100% 100%', backgroundPosition: 'center', backgroundRepeat: "no-repeat"}}>
                                        <button type={"button"} className="position-relative pt-2 border-0 bg-transparent cursor-pointer fw-bold text-black text-center"
                                                style={{ width: `23vw`, fontFamily: 'Ratanegra-Cyrillic',
                                            fontSize: `3vw`}} onClick={() => {
                                            window.location.href = `/games/update/${id}`
                                        }}>НАСТРОЙКИ</button>
                                            </div>
                                        <div className="button_tik flex-column d-flex justify-content-center align-items-center w-100" style={{ backgroundImage: `url(${btn})`,
                                            backgroundSize: '100% 100%', backgroundPosition: 'center', backgroundRepeat: "no-repeat"}}>
                                        <button type={"button"} className="position-relative pt-2 border-0 bg-transparent cursor-pointer fw-bold text-black text-center"
                                                style={{width: `23vw`, fontFamily: 'Ratanegra-Cyrillic',
                                            fontSize: `3vw`}} onClick={() => {
                                            window.location.href = '/games/teacher'
                                        }}>ВЫХОД</button>
                                        </div>
                                        </div>
                         </div>
                    </div>
                }
                {
                    selectShot && game &&
                    <Suspense fallback={<div>loading...</div>}>
                    <Shot recorder={recorder} ParentAndTeachers={'teacher'} nextShotTexture={nextShotTexture} nextSelectShot={nextSelectShot} idgame={game.id} inventar={game.is_inventar} RPG={game.is_rpg} height={game?.height} width={game.width} shot={selectShot} nextShot={nextShot} onChangeError={onChangeError} onChangeTrue={onChangeTrue}/>
                    </Suspense>
                    }
                {
                    end &&
                    <div className="flex-column d-flex  align-items-center h-100 w-100"
                         style={{backgroundImage: `url(${backimage()})`, backgroundSize: '100% 100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
                        {
                            PlayAudioStartMenu()
                        }
                        <div className="position-absolute end-0 cursor-pointer" style={{zIndex: 10, margin: "0.5vw"}} onClick={() => {
                            setIsFullScreen((prevState) => {
                                if (!prevState) {
                                    document.body.requestFullscreen()
                                    return !prevState
                                }
                                document.fullscreenElement &&
                                    document.exitFullscreen()
                                return !prevState
                            })
                        }}>
                            <i className="bx bx-fullscreen text-black fw-bold" style={{fontSize: "1.5vw"}}></i>
                        </div>
                        <div className="d-flex justify-content-center align-items-center "
                             style={{marginTop: '1.2vw', height: `10vw`, width: `45vw`, backgroundImage: `url(${shapka})`, backgroundSize: '100% 100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
                            <div className=" d-flex justify-content-center align-items-center" style={{width: '23vw', height: '7vw', marginTop: '1vw'}}>
                                <span className = 'align-items-center' style={{textAlign: "center", fontFamily: 'Ratanegra-Cyrillic', fontSize: `2vw`}}>{game?.name}</span></div>
                        </div>
                        <div className="center flex-column d-flex justify-content-center align-items-center"
                             style={{marginTop: '1.7vw', height: '25vw', width: `30vw` , backgroundImage: `url(${bgmenu})`, backgroundSize: '100% 100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
                            <div className=" text-black fw-bold">
                                <h3 className=" text-black" style={{fontSize: `1.5vw`}}>Результаты</h3>
                                <div>
                                    <h4 className="text-black" style={{fontSize: `1.2vw`}}>Всего вопросов: {result.all}</h4>
                                </div>
                                <div>
                                    <h4 className=" text-black" style={{fontSize: `1.2vw`}}>Всего правильных ответов: {result.true}</h4>
                                </div>
                                <div>
                                    <h4 className=" text-black" style={{fontSize: `1.2vw`}}>Всего ошибок: {result.error}</h4>
                                </div>
                            </div>
                            <div className="flex-column d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${btn})`,
                                backgroundSize: '100% 100%', backgroundPosition: 'center', backgroundRepeat: "no-repeat"}}>
                                <button type={"button"} className="position-relative pt-2 border-0 bg-transparent cursor-pointer fw-bold text-black text-center"
                                        style={{width: `15vw`, fontFamily: 'Ratanegra-Cyrillic',
                                            fontSize: `2.5vw`}} onClick={() => {
                                            setSelectShot(null)
                                            setShotIndex(-1)
                                            setEnd(false)
                                            setStart(true)
                                            setResult((prevState) => ({
                                                ...prevState,
                                                true: 0,
                                                error: 0}))
                                        }}>ИГРАТЬ
                                        </button>
                                    </div>

                        <div className="flex-column d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${btn})`,
                            backgroundSize: '100% 100%', backgroundPosition: 'center', backgroundRepeat: "no-repeat"}}>
                            <button type={"button"} className="position-relative pt-2 border-0 bg-transparent cursor-pointer fw-bold text-black text-center"
                                    style={{width: `24vw`, fontFamily: 'Ratanegra-Cyrillic',
                                        fontSize: `1.8vw`}} onClick={() => {
                                        setIsFullScreen(() => {
                                            document.fullscreenElement &&
                                            document.exitFullscreen()
                                            return false
                                        })
                                        window.location.href = '/games/teacher'
                                    }}
                                    >ВСЕ ИГРЫ</button>
                                </div>
                        </div>
                    </div>
                }
                </div>
        </>
    );
};

export default OpenGame;
