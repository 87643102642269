import React, {FC, lazy, Suspense, useEffect, useState} from 'react';
import {Alert, Badge, Button, Col, Collapse, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row} from "reactstrap";
import {
    ISettingsAnswerShot,
    ISettingsBattle,
    ISettingsCountTextures,
    ISettingsSearch,
    IShot
} from "../../../models/game/IShot";
import {IGame} from "../../../models/game/IGame";
import classNames from "classnames";
import {ITexture} from "../../../models/game/ITexture";
import {IBackgroundImage} from "../../../models/game/IBackgroundImage";
import {IBackgroundSound} from "../../../models/game/IBackgroundSound";
import AxiosClient from "../../../api/AxiosClient";
import {TypeSettingsShot} from "../../../models/game/typesSettings/TypeSettingsShot";
import {TypeSettings} from "../../../models/game/typesSettings/TypeSettings";
import {TypeSettingsSelectAnswer} from "../../../models/game/typesSettings/TypeSettingsSelectAnswer";
import {TextEditorProvider} from "../../../hooks/UseEditorApi";
//import TextEditor from "../Editor/TextEditor";
//import ModalTextureAdd from "../UploadTextureNew/settingTexture/ModalTextureAdd";
//import ModalTextureCategoryAdd from "../UploadTextureNew/settingTexture/ModalTextureCategoryAdd";
//import ModalTextureUpdate from "../UploadTextureNew/settingTexture/ModalTextureUpdate";
//import TextureList from "../UploadTextureNew/settingTexture/TextureList";
//import BackgroundList from "../UploadTextureNew/settingBackground/BackgroundList";
//import ModalBackgroundAdd from "../UploadTextureNew/settingBackground/ModalBackgroundAdd";
//import ModalBackgroundUpdate from "../UploadTextureNew/settingBackground/ModalBackgroundUpdate";
//import ModalBackgroundCategoryAdd from "../UploadTextureNew/settingBackground/ModalBackgroundCategoryAdd";
// BackgroundSoundList from "../UploadTextureNew/settingsBackgroundSound/BackgroundSoundList";
//import ModalBackgroundSoundCategoryAdd
    //from "../UploadTextureNew/settingsBackgroundSound/ModalBackgroundSoundCategoryAdd";
//import ModalBackgroundSoundAdd from "../UploadTextureNew/settingsBackgroundSound/ModalBackgroundSoundAdd";
//import ModalBackgroundSoundUpdate from "../UploadTextureNew/settingsBackgroundSound/ModalBackgroundSoundUpdate";
import ModalTextureGenerate from "../UploadTextureNew/settingTexture/ModalTextureGenerate";
import ModalBackgroundGenerate from "../UploadTextureNew/settingBackground/ModalBackgroundGenerate";
import {TypeSettingsGenderVoice} from "../../../models/game/typesSettings/TypeSettingsGenderVoice";
//import ModalTextureCategoryDelete from "../UploadTextureNew/settingTexture/ModalTextureCategoryDelete";

const TextEditor = lazy(() => import("../Editor/TextEditor"))
const ModalTextureAdd = lazy(() => import("../UploadTextureNew/settingTexture/ModalTextureAdd"))
const ModalTextureCategoryAdd = lazy(() => import( "../UploadTextureNew/settingTexture/ModalTextureCategoryAdd"))
const ModalTextureCategoryDelete = lazy(() => import("../UploadTextureNew/settingTexture/ModalTextureCategoryDelete"))
const ModalTextureUpdate = lazy(() => import( "../UploadTextureNew/settingTexture/ModalTextureUpdate"))
const TextureList = lazy(() => import( "../UploadTextureNew/settingTexture/TextureList"))
const BackgroundList = lazy(() => import( "../UploadTextureNew/settingBackground/BackgroundList"))
const ModalBackgroundAdd = lazy(() => import( "../UploadTextureNew/settingBackground/ModalBackgroundAdd"))
const ModalBackgroundUpdate = lazy(() => import( "../UploadTextureNew/settingBackground/ModalBackgroundUpdate"))
const ModalBackgroundCategoryAdd = lazy(() => import( "../UploadTextureNew/settingBackground/ModalBackgroundCategoryAdd"))
const ModalBackgroundCategoryDelete = lazy(() => import("../UploadTextureNew/settingBackground/ModalBackgroundCategoryDelete"))
const BackgroundSoundList = lazy(() => import( "../UploadTextureNew/settingsBackgroundSound/BackgroundSoundList"))
const ModalBackgroundSoundCategoryAdd
    = lazy(() => import( "../UploadTextureNew/settingsBackgroundSound/ModalBackgroundSoundCategoryAdd"))
const ModalBackgroundSoundCategoryDelete
    = lazy(() => import( "../UploadTextureNew/settingsBackgroundSound/ModalBackgroundSoundCategoryDelete"))
const ModalBackgroundSoundAdd = lazy(() => import( "../UploadTextureNew/settingsBackgroundSound/ModalBackgroundSoundAdd"))
const ModalBackgroundSoundUpdate = lazy(() => import( "../UploadTextureNew/settingsBackgroundSound/ModalBackgroundSoundUpdate"))


interface IProps {
    isOpen: boolean,
    setIsOpen: (value: boolean) => void
    selectedShot: IShot,
    game: IGame,
    saveSettingsShot: (shot: IShot) => void
    setGame: (game: IGame) => void
}

const LeftMenuUpdate: FC<IProps> = ({isOpen, setIsOpen, game, setGame, saveSettingsShot, selectedShot}) => {
    const [open, setOpen] = useState('');
    const [openTextureCategory, setOpenTextureCategory] = useState<string | null | number>('');
    const [openBackgroundCategory, setOpenBackgroundCategory] = useState<string | null | number>('');
    const [openBackgroundSoundCategory, setOpenBackgroundSoundCategory] = useState<string | null | number>('');
    const [shot, setShot] = useState<IShot>(selectedShot);
    const [wTexture, setWTexture] = useState(0);
    const [hTexture, setHTexture] = useState(0);
    const [nameshot, setnameshot] = useState('');
    const [textstart, settextstart] = useState('');
    const [hint, sethint] = useState('');
    const [textures, setTextures] = useState<ITexture[]>([]);
    const [selectedtexture, setSelectedtexture] = useState<ITexture[]>([]);
    const [backgroundImages, setBackgroundImages] = useState<IBackgroundImage[]>([]);
    const [selectedbackgroundImages, setselectedbackgroundImages] = useState<IBackgroundImage[]>([]);
    const [backgroundSounds, setBackgroundSounds] = useState<IBackgroundSound[]>([]);
    const [selectedbackgroundSounds, setselectedBackgroundSounds] = useState<IBackgroundSound[]>([]);
    const [categoryBackgrounds, setCategoryBackgrounds] = useState<{
        id: number,
        name: string,
        backgrounds_count: number
    }[]>([]);
    const [setlectedcategoryBackgrounds, setsetlectedcategoryBackgrounds] = useState<{
        id: number,
        name: string,
        backgrounds_count: number
    }[]>([]);
    const [categoryBackgroundSounds, setCategoryBackgroundSounds] = useState<{
        id: number,
        name: string,
        background_sounds_count: number
    }[]>([]);
    const [selectedcategoryBackgroundSounds, setselectedcategoryBackgroundSounds] = useState<{
        id: number,
        name: string,
        background_sounds_count: number
    }[]>([]);
    const [categoryTextures, setCategoryTextures] = useState<{
        id: number,
        name: string,
        textures_count: number
    }[]>([]);
    const [selectedcategoryTextures, setselectedcategoryTextures] = useState<{
        id: number,
        name: string,
        textures_count: number
    }[]>([]);
    const [activeTabSettings, setActiveTabSettings] = useState('0');
    const [activeTabUploadTexture, setActiveTabUploadTexture] = useState("");
    const [isAddTexture, setIsAddTexture] = useState(false);
    const [error, setError] = useState({show: false, message: ''});
    const [isAddBackground, setIsAddBackground] = useState(false);
    const [isAddBackgroundSound, setIsAddBackgroundSound] = useState(false);
    const [isAddCategoryBackground, setIsAddCategoryBackground] = useState(false);
    const [isDeleteCategoryBackground, setIsDeleteCategoryBackground] = useState(false);
    const [selectBackground, setSelectBackground] = useState<IBackgroundImage | null>(null);
    const [isUpdateBackground, setIsUpdateBackground] = useState(false);
    const [isAddCategoryBackgroundSound, setIsAddCategoryBackgroundSound] = useState(false);
    const [isDeleteCategoryBackgroundSound, setIsDeleteCategoryBackgroundSound] = useState(false);
    const [selectBackgroundSound, setSelectBackgroundSound] = useState<IBackgroundSound | null>(null);
    const [isUpdateBackgroundSound, setIsUpdateBackgroundSound] = useState(false);
    const [isAddCategoryTexture, setIsAddCategoryTexture] = useState(false);
    const [isDeleteCategoryTexture, setIsDeleteCategoryTexture] = useState(false);
    const [selectTexture, setSelectTexture] = useState<ITexture | null>(null);
    const [isUpdateTexture, setIsUpdateTexture] = useState(false);
    const [isAddTextureGenerate, setIsAddTextureGenerate] = useState(false);
    const [isAddBackgroundGenerate, setIsAddBackgroundGenerate] = useState(false);

    const [addPrimer, setAddPrimer] = useState(false)
    const [EditPrimer, setEditPrimer] = useState(false)
    const [idprimer, setidprimer] = useState(0)
    const [namePrimer, setNamePrimer] = useState('')
    const [answer, setAnswer] = useState('')

    const [saveName, setsaveName] = useState({show: false, message: ''});
    const [saveHint, setsaveHint] = useState({show: false, message: ''});
    const [saveVvodSlova, setsaveVvodSlova] = useState({show: false, message: ''});
    const [Updatenameshot, setUpdatenameshot] = useState(false)
    const [Updatetextstart, setUpdatetextstart] = useState(false)
    const [Updatehint, setUpdatehint] = useState(false)

    const [shot1, setShot1] = useState<IShot | null>(null);
    const [UpdateEditorText, setUpdateEditorText] = useState(false)

    useEffect(() => {
        if(selectedtexture !== textures) {
            AxiosClient.get<ITexture[]>('/game/textures')
                .then((r) => {
                    setTextures(r.data)
                })
            setSelectedtexture(textures)
        }
        if(selectedbackgroundImages !== backgroundImages) {
        AxiosClient.get<IBackgroundImage[]>('/game/backgroundImage')
            .then((r) => {
                setBackgroundImages(r.data)
            })
            setselectedbackgroundImages(backgroundImages)
        }
        if(selectedbackgroundSounds !== backgroundSounds) {
        AxiosClient.get<IBackgroundSound[]>('/game/backgroundAudio')
            .then((r) => {
                setBackgroundSounds(r.data)
            })
            setselectedBackgroundSounds(backgroundSounds)
        }
        if(setlectedcategoryBackgrounds !== categoryBackgrounds) {
        AxiosClient.get<{ id: number, name: string, backgrounds_count: number }[]>('/game/category_backgrounds')
            .then((r) => {
                setCategoryBackgrounds(r.data)
            })
            setsetlectedcategoryBackgrounds(categoryBackgrounds)
        }
        if(selectedcategoryBackgroundSounds !== categoryBackgroundSounds) {
        AxiosClient.get<{
            id: number,
            name: string,
            background_sounds_count: number
        }[]>('/game/category_background_sounds')
            .then((r) => setCategoryBackgroundSounds(r.data))
            setselectedcategoryBackgroundSounds(categoryBackgroundSounds)
        }
            if(selectedcategoryTextures !== categoryTextures) {
        AxiosClient.get<{ id: number, name: string, textures_count: number }[]>('/game/category_textures')
            .then((r) => setCategoryTextures(r.data))
                setselectedcategoryTextures(categoryTextures)
            }
    }, []);

    useEffect(() => {
        setWTexture(selectedShot.widthTextures)
        setHTexture(selectedShot.heightTextures)
        setShot(selectedShot)
        if (selectedShot.settings) {
            if (selectedShot.settings.type === TypeSettingsShot.NOSETTINGS)
                setActiveTabSettings("0")
            if (selectedShot.settings.type === TypeSettingsShot.COUNT_TEXTURE)
                setActiveTabSettings("1")
            if (selectedShot.settings.type === TypeSettingsShot.ANSWER)
                setActiveTabSettings("2")
            if (selectedShot.settings.type === TypeSettingsShot.SEARCH)
                setActiveTabSettings("3")
            if (selectedShot.settings.type === TypeSettingsShot.BATTLE)
                setActiveTabSettings("4")
        }
    }, [selectedShot]);

    useEffect(() => {
        if(shot1 !== shot){
            save()
            setShot1(shot)
        }
    }, [shot]);

    const save = () => {
        saveSettingsShot(shot)
    }

    const handleAddTexture = (texture: ITexture) => {
        if (shot.textures.find(el => el.id === texture.id)) {
            setShot((shot) => ({
                ...shot,
                textures: shot.textures.filter(el => el.id !== texture.id)
            }))
        } else {
            setShot((shot) => {
                const numbers = shot.textures.map(obj => {
                    const match = obj.pivot.name.match(/\d+/); // Находим число в 'name'
                    return match ? parseInt(match[0], 10) : 0; // Преобразуем в число или возвращаем 0
                });
                const maxNumber = numbers.length > 0 ? Math.max(...numbers) + 1 : 1;
                return {
                    ...shot, textures: [...shot.textures,
                        {
                            ...texture,
                            pivot: {
                                ...texture.pivot,
                                x: 0,
                                y: 0,
                                percentX: 0,
                                percentY: 0,
                                name: 'Текстура' + maxNumber,
                                description: texture.name,
                                size: 1,
                                settings: {
                                    type: TypeSettings.NO_SETTINGS,
                                    settings: {
                                        select: TypeSettingsSelectAnswer.TRUE,
                                        text: '',
                                        path: ''
                                    },
                                    width: shot.widthTextures,
                                    height: shot.heightTextures,
                                    typeAnimation: 'none',
                                }
                            }
                        }]
                }
            })
        }
    }

    const updateWidthHeightTextures = () => {
        setShot((shot) => ({
            ...shot,
            widthTextures: wTexture,
            heightTextures: hTexture,
            textures: shot.textures.map((texture) => (
                {
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: {
                            ...texture.pivot.settings,
                            width: wTexture,
                            height: hTexture
                        }
                    }
                }
            ))
        }))
    }

    const updateNames = () => {
        setShot((shot) => ({
            ...shot,
            name: nameshot,
        }))
        setsaveName({
            show: true,
            message: 'Название кадра успешно сохранено'
        })
        setTimeout(() => {
            setsaveName({
                show: false,
                message: ''
            })
        }, 5000)
    }
    const updateHint = () => {
        setShot((shot) => ({
            ...shot,
            hint: hint
        }))
        setsaveHint({
            show: true,
            message: 'Подсказка успешно сохранена'
        })
        setTimeout(() => {
            setsaveHint({
                show: false,
                message: ''
            })
        }, 5000)
    }
    const updateStartText = () => {
        setShot((shot) => ({
            ...shot,
            startText: textstart
        }))
        setsaveVvodSlova({
            show: true,
            message: 'Слова автора успешно сохранены'
        })
        setTimeout(() => {
            setsaveVvodSlova({
                show: false,
                message: ''
            })
        }, 5000)
    }

    const handleAddBackgroundImage = (backgroundImage: IBackgroundImage) => {
        if (shot.backgroundImage) {
            setShot((shot) => ({
                ...shot,
                backgroundImage: null
            }))
        } else {
            setShot((shot) => ({...shot, backgroundImage: backgroundImage}))
        }
    }

    const handleAddBackgroundSound = (backgroundSound: IBackgroundSound) => {
        if (shot.backgroundSound) {
            setShot((shot) => ({
                ...shot,
                backgroundSound: null
            }))

            const indexSelectShot = game.shots.findIndex(el => el.name === shot.name)
            let stop = false;
            const newGame: IGame = {
                ...game,
                shots: game.shots.map((el, index) => {
                    if (index > indexSelectShot && !stop) {
                        if (el.useSoundBack) {
                            return {
                                ...el,
                                backgroundSound: null
                            }
                        } else {
                            stop = true
                        }
                    }
                    return el
                })
            }
            setGame(newGame)
        } else {
            setShot((shot) => ({...shot, backgroundSound: backgroundSound}))
            const indexSelectShot = game.shots.findIndex(el => el.name === shot.name)
            let stop = false;
            const newGame: IGame = {
                ...game,
                shots: game.shots.map((el, index) => {
                    if (index > indexSelectShot && !stop) {
                        if (el.useSoundBack) {
                            return {
                                ...el,
                                backgroundSound: backgroundSound
                            }
                        } else {
                            stop = true
                        }
                    }
                    return el
                })
            }
            setGame(newGame)
        }
    }

    const handleActiveTabSettings = (value: string) => {
        const Nosettings = {
            type: TypeSettingsShot.NOSETTINGS,
            settings: {}
        }
        const settingsCountTexture = {
            type: TypeSettingsShot.COUNT_TEXTURE,
            settings: {
                textTrue: '',
                pathTrue: '',
                textFalse: '',
                pathFalse: '',
                answer: ''
            }
        }
        const settingsSearch = {
            type: TypeSettingsShot.SEARCH,
            settings: {
                textTrue: '',
                pathTrue: '',
                textFalse: '',
                pathFalse: '',
                startShow: true,
                timeShow: 1
            }
        }
        const settingsAnswer = {
            type: TypeSettingsShot.ANSWER,
            settings: {
                html: [],
                htmlVid: '',
                text: '',
                textFalse: '',
                path: '',
                pathFalse: '',
                isGPTChat: false,
                width: 300,
                height: 100,
                x: 0,
                y: 0,
                percentY: 0,
                percentX: 0
            }
        }
            const settingsBattle = {
                type: TypeSettingsShot.BATTLE,
                settings: {}
            }
        if (value === "0") {
            setShot((shot) => ({...shot, settings: Nosettings}))
        }
        if (value === "1") {
            setShot((shot) => ({
                ...shot, settings: settingsCountTexture,
                textures: shot.textures.map((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: {
                            type: TypeSettings.NO_SETTINGS,
                            settings: {
                                select: TypeSettingsSelectAnswer.TRUE,
                                text: '',
                                path: ''
                            },
                            width: shot.widthTextures,
                            height: shot.heightTextures,
                            typeAnimation: 'none',
                        }
                    }
                }))
            }))
        }
        if (value === "2") {
            setShot((shot) => ({...shot, settings: settingsAnswer}))
        }
        if (value === "3") {
            setShot((shot) => ({...shot, settings: settingsSearch}))
        }
        if (value === "4") {
            setShot((shot) => ({...shot, settings: settingsBattle}))
        }

        setActiveTabSettings(value)
    }

    const removeTexture = (id: number) => {
        AxiosClient.post(`/game/textures/delete/${id}`)
            .then(() => setTextures((prevState) => prevState.filter((el) => el.id !== id)))
            .catch((reason) => {
                setError({
                    show: true,
                    message: reason.response.data.message
                })
            })
    }

    const addTexture = (data: FormData) => {
        AxiosClient.post(`/game/upload_texture_game`, data, {headers: {"Content-Type": "multipart/form-data"}})
            .then((r) => {
                setTextures((prevState) => [...prevState, r.data])
                setIsAddTexture(false)
            })
    }

    const addTextureBase = (data: any) => {
        AxiosClient.post(`/game/upload_texture_game_base`, data)
            .then((r) => {
                setTextures((prevState) => [...prevState, r.data])
                setIsAddTextureGenerate(false)
            })
    }

    const addBackgroundBase = (data: any) => {
        AxiosClient.post(`/game/upload_background_game_base`, data)
            .then((r) => {
                setBackgroundImages((prevState) => [...prevState, r.data])
                setIsAddBackgroundGenerate(false)
            })
            .catch(() => {
                setError({
                    show: true,
                    message: 'Ошибка при добавлении, возможно файл превышает 2мб'
                })
            })
    }
    const addTexturesCategory = (name: string) => {
        AxiosClient.post(`/game/upload_category_textures`, {name})
            .then((r) => {
                setCategoryTextures((prevState) => [...prevState, r.data])
                setIsAddCategoryTexture(false)
            })
    }

    const DeleteTexturesCategory = (id: number) => {
        AxiosClient.post(`/game/category_textures/delete/${id}`)
            .then(() =>
                setCategoryTextures((prevState) => prevState.filter((el) => el.id !== id))
            )
                .catch((reason) => {
                    setError({
                        show: true,
                        message: reason.response.data.message
                    })
                })
        setIsDeleteCategoryTexture(false)
            }

    const updateTexture = (id: number, data: { name: string, category_texture_id: number | null }) => {
        AxiosClient.post(`/game/textures/update/${id}`, data)
            .then((r) => {
                setTextures((prevState) => prevState.map((el) => el.id === id ? r.data : el))
                setCategoryTextures((prevState) => {
                    return prevState.map((el) => {
                        if (data.category_texture_id !== selectTexture?.category_texture_id) {
                            if (el.id === data.category_texture_id) {
                                return {
                                    ...el,
                                    textures_count: el.textures_count + 1
                                }
                            }
                            if (el.id === selectTexture?.category_texture_id) {
                                return {
                                    ...el,
                                    textures_count: el.textures_count - 1
                                }
                            }
                        }
                        return el
                    })
                })
                setIsUpdateTexture(false)
                setSelectTexture(null)
            })
            .catch((reason) => {
                setError({
                    show: true,
                    message: reason.response.data.message
                })
            })
    }
    const removeBackground = (id: number) => {
        AxiosClient.post(`/game/backgrounds/delete/${id}`)
            .then(() => setBackgroundImages((prevState) => prevState.filter((el) => el.id !== id)))
            .catch((reason) => {
                setError({
                    show: true,
                    message: reason.response.data.message
                })
            })
    }

    const updateBackground = (id: number, data: { name: string, category_background_id: number | null }) => {
        AxiosClient.post(`/game/backgrounds/update/${id}`, data)
            .then((r) => {
                setBackgroundImages((prevState) => prevState.map((el) => el.id === id ? r.data : el))
                setCategoryBackgrounds((prevState) => {
                    return prevState.map((el) => {
                        if (data.category_background_id !== selectBackground?.category_background_id) {
                            if (el.id === data.category_background_id) {
                                return {
                                    ...el,
                                    backgrounds_count: el.backgrounds_count + 1
                                }
                            }
                            if (el.id === selectBackground?.category_background_id) {
                                return {
                                    ...el,
                                    backgrounds_count: el.backgrounds_count - 1
                                }
                            }
                        }
                        return el
                    })
                })
                setIsUpdateBackground(false)
                setSelectBackground(null)
            })
            .catch((reason) => {
                setError({
                    show: true,
                    message: reason.response.data.message
                })
            })
    }

    const addBackground = (data: FormData) => {
        AxiosClient.post(`/game/upload_background_game`, data, {headers: {"Content-Type": "multipart/form-data"}})
            .then((r) => {
                setBackgroundImages((prevState) => [...prevState, r.data])
                setIsAddBackground(false)
            })
            .catch(() => {
                setError({
                    show: true,
                    message: 'Ошибка при добавлении, возможно файл превышает 2мб'
                })
            })
    }

    const addCategoryBackground = (name: string) => {
        AxiosClient.post(`/game/upload_category_background`, {name})
            .then((r) => {
                setCategoryBackgrounds((prevState) => [...prevState, r.data])
                setIsAddCategoryBackground(false)
            })
    }

    const DeleteCategoryBackground = (id: number) => {
        AxiosClient.post(`/game/category_backgrounds/delete/${id}`)
            .then(() =>
                setCategoryBackgrounds((prevState) => prevState.filter((el) => el.id !== id))
            )
            .catch((reason) => {
                setError({
                    show: true,
                    message: reason.response.data.message
                })
            })
        setIsDeleteCategoryBackground(false)
    }

    const removeBackgroundSound = (id: number) => {
        AxiosClient.post(`/game/backgroundSounds/delete/${id}`)
            .then(() => setBackgroundSounds((prevState) => prevState.filter((el) => el.id !== id)))
            .catch((reason) => {
                setError({
                    show: true,
                    message: reason.response.data.message
                })
            })
    }

    const addBackgroundSound = (data: FormData) => {
        AxiosClient.post(`/game/upload_background_audio_game`, data, {headers: {"Content-Type": "multipart/form-data"}})
            .then((r) => {
                setBackgroundSounds((prevState) => [...prevState, r.data])
                setIsAddBackgroundSound(false)
            })
    }

    const addBackgroundSoundCategory = (name: string) => {
        AxiosClient.post(`/game/upload_category_background_sound`, {name})
            .then((r) => {
                setCategoryBackgroundSounds((prevState) => [...prevState, r.data])
                setIsAddCategoryBackgroundSound(false)
            })
    }

    const DeleteBackgroundSoundCategory = (id: number) => {
        AxiosClient.post(`/game/category_background_sound/delete/${id}`)
            .then(() =>
                setCategoryBackgroundSounds((prevState) => prevState.filter((el) => el.id !== id))
            )
            .catch((reason) => {
                setError({
                    show: true,
                    message: reason.response.data.message
                })
            })
        setIsDeleteCategoryBackgroundSound(false)
    }

    const updateBackgroundSound = (id: number, data: { name: string, category_background_sound_id: number | null }) => {
        AxiosClient.post(`/game/backgroundAudio/update/${id}`, data)
            .then((r) => {
                setBackgroundSounds((prevState) => prevState.map((el) => el.id === id ? r.data : el))
                setCategoryBackgroundSounds((prevState) => {
                    return prevState.map((el) => {
                        if (data.category_background_sound_id !== selectBackgroundSound?.category_background_sound_id) {
                            if (el.id === data.category_background_sound_id) {
                                return {
                                    ...el,
                                    background_sounds_count: el.background_sounds_count + 1
                                }
                            }
                            if (el.id === selectBackgroundSound?.category_background_sound_id) {
                                return {
                                    ...el,
                                    background_sounds_count: el.background_sounds_count - 1
                                }
                            }
                        }
                        return el
                    })
                })
                setIsUpdateBackgroundSound(false)
                setSelectBackgroundSound(null)

            })
            .catch((reason) => {
                setError({
                    show: true,
                    message: reason.response.data.message
                })
            })
    }

    const onChangeUseBackSound = (checked: boolean) => {
        const indexSelectShot = game.shots.findIndex(el => el.name === shot.name)
        if (indexSelectShot !== 0 && checked) {
            const oldShot = game.shots[indexSelectShot - 1];
            setShot({
                ...shot,
                useSoundBack: checked,
                backgroundSound: oldShot.backgroundSound
            })
            let stop = false;
            const newGame: IGame = {
                ...game,
                shots: game.shots.map((el, index) => {
                    if (index > indexSelectShot && !stop) {
                        if (el.useSoundBack) {
                            return {
                                ...el,
                                backgroundSound: oldShot.backgroundSound
                            }
                        } else {
                            stop = true
                        }
                    }
                    return el
                })
            }
            setGame(newGame)
        }

        if (!checked) {
            setShot({
                ...shot,
                useSoundBack: checked,
                backgroundSound: null
            })
        }
    }

    const onChangeAnswerShotText = (value: string) => {
        if (shot.settings) {
            setShot((shot) => {
                const {settings} = shot;
                if (settings) {
                    return {
                        ...shot,
                        settings: {
                            ...settings,
                            // Обновляем только необходимые поля settings, избегая лишней вложенности
                            ...(settings.settings && {
                                settings: {
                                    ...settings.settings,
                                    text: value // Обновляем поле text
                                }
                            }),
                        }
                    };
                }
                return shot
            });
        }
    }
    const onChangeAnswerShotTextFalse = (value: string) => {
        if (shot.settings) {
            setShot((shot) => {
                const {settings} = shot;
                if (settings) {
                    return {
                        ...shot,
                        settings: {
                            ...settings,
                            // Обновляем только необходимые поля settings, избегая лишней вложенности
                            ...(settings.settings && {
                                settings: {
                                    ...settings.settings,
                                    textFalse: value // Обновляем поле text
                                }
                            }),
                        }
                    };
                }
                return shot
            });
        }
    }

    const onChangeAnswerShotIsChatGPT = (value: boolean) => {
        if (shot.settings) {
            setShot((shot) => {
                const {settings} = shot;
                if (settings) {
                    return {
                        ...shot,
                        settings: {
                            ...settings,
                            // Обновляем только необходимые поля settings, избегая лишней вложенности
                            ...(settings.settings && {
                                settings: {
                                    ...settings.settings,
                                    isGPTChat: value // Обновляем поле text
                                }
                            }),
                        }
                    };
                }
                return shot
            });
        }
    }

    const handleEditAnswerShot = (id: number, value: string, value2: string) => {
        if(value && value2) {
            if (shot.settings) {
                setShot((shot) => {
                    const {settings} = shot;
                    if (settings) {
                        return {
                            ...shot,
                            settings: {
                                ...settings,
                                ...(settings.settings && {
                                    settings: {
                                        ...settings.settings,
                                        html: (shot.settings?.settings as
                                            ISettingsAnswerShot).html.map(item =>
                                            item.id === id
                                                ? {...item, id: id, primer: value, textAnswer: value2}
                                                : item
                                        )
                                    }
                                })
                            }
                        }
                    }
                    return shot
                })
            }
            setNamePrimer('')
            setAnswer('')
            setEditPrimer(false)
            setError({
                show: false,
                message: ''
            })
        }
        else{
            setError({
                show: true,
                message: 'Пример или ответ пуст'
            })
        }
    }

    const onChangeAnswerShotHtml = (value: string) => {
        if (shot.settings) {
            setShot((shot) => {
                const {settings} = shot;
                if (settings) {
                    return {
                        ...shot,
                        settings: {
                            ...settings,
                            ...(settings.settings && {
                                settings: {
                                    ...settings.settings,
                                    htmlVid: value
                                }
                            }),
                        }
                    }
                }
                return shot
            })
        }
    }

    const handleAddAnswerShot = (value: string, value2: string) => {
        if(value && value2) {
            if (shot.settings) {
                const minId = game?.shots.reduce((min, item) => (item.id < min ? item.id : min), 0) ?? 0;
                setShot((shot) => {
                    const {settings} = shot;
                    if (settings) {
                        return {
                            ...shot,
                            settings: {
                                ...settings,
                                ...(settings.settings && {
                                    settings: {
                                        ...settings.settings,
                                        html: [...(shot.settings?.settings as
                                            ISettingsAnswerShot).html,
                                            {
                                                id: minId > 0 ? 0 : minId - 1,
                                                primer: value,
                                                textAnswer: value2
                                            }]
                                    }
                                })
                            }
                        }
                    }
                    return shot
                })
            }
            setNamePrimer('')
            setAnswer('')
            setAddPrimer(false)
            setError({
                show: false,
                message: ''
            })
        }
        else{
            setError({
                show: true,
                message: 'Текст задания или ответ пуст'
            })
        }
    }


    const onChangeTypeAnimateAll = (value: 'shadow' | 'pulse' | 'none') => {
        setShot((shot) => ({
            ...shot,
            defaultAnimate: value,
            textures: shot.textures.map((texture) => ({
                ...texture,
                pivot: {...texture.pivot, settings: {...texture.pivot.settings, typeAnimation: value}}
            }))
        }))
    }
    const toggle = (id: any) => {
        if (open !== id) {
            setOpen(id);
        } else {
            setOpen('')
        }
    };

    const toggleTextureCategory = (id: string | null | number) => {
        if (openTextureCategory !== id) {
            setOpenTextureCategory(id);
        } else {
            setOpenTextureCategory('')
        }
    };

    const toggleBackgroundCategory = (id: string | null | number) => {
        if (openBackgroundCategory !== id) {
            setOpenBackgroundCategory(id);
        } else {
            setOpenBackgroundCategory('')
        }
    };

    const toggleBackgroundSoundCategory = (id: string | null | number) => {
        if (openBackgroundSoundCategory !== id) {
            setOpenBackgroundSoundCategory(id);
        } else {
            setOpenBackgroundSoundCategory('')
        }
    };

    const toggleActiveTabUploadTexture = (id: string) => {
        if (activeTabUploadTexture !== id) {
            setActiveTabUploadTexture(id);
        } else {
            setActiveTabUploadTexture('')
        }
    };

    return (
        <Offcanvas
            autoFocus
            isOpen={isOpen}
            backdrop={false}
            scrollable
            direction='start'
        >
            <OffcanvasHeader toggle={() => setIsOpen(!isOpen)}>
                Настройка кадра
            </OffcanvasHeader>
            <OffcanvasBody>
                <div className="accordion" id="genques-accordion">
                    <div className="accordion-item border-0 border-bottom ">
                        <h2 className="accordion-header" id="genques-headingTwo">
                            <button
                                className={classNames(
                                    'accordion-button fw-medium bg-white text-black',
                                    {
                                        collapsed: open !== '1'
                                    }
                                )}
                                type="button"
                                onClick={() => toggle('1')}
                                style={{cursor: "pointer"}}
                            >
                                Основная информация
                            </button>
                        </h2>
                        <Collapse isOpen={open === '1'} className="accordion-collapse">
                            <div className="accordion-body p-0 text-black fw-normal py-2">
                                <Row>
                                    <Col sm={12}>
                                        <div>
                                            <label htmlFor="name" className="form-label m-0">Название кадра</label>
                                            <div className="d-flex">
                                                <input type="text" id="name" value={shot.name ? shot.name : nameshot}
                                                       onChange={(event) =>{
                                                           setUpdatenameshot(true)
                                                           shot.name = ''
                                                           setnameshot(event.target.value)
                                                           setsaveName({
                                                               show: false,
                                                               message: ''
                                                           })
                                                       }}
                                                       name='name' className="form-control"/>
                                                {Updatenameshot &&
                                                    <button className="bx bx-save fs-3 bg-transparent border-0"
                                                            onClick={() => {
                                                                updateNames()
                                                                setnameshot('')
                                                                setUpdatenameshot(false)
                                                            }}>
                                                    </button>}
                                            </div>
                                        </div>
                                        {
                                            saveName.show &&
                                            <Alert style={{color: 'green'}}>{saveName.message}</Alert>
                                        }
                                    </Col>
                                    <Col sm={12}>
                                        <div className="mt-2">
                                            <label htmlFor="inputWords" className="form-label m-0">Слова автора при открытии кадра</label>
                                            <div className="d-flex">
                                                <input type="text" id="inputWords" name="inputWords"
                                                       className="form-control" value={shot.startText ? shot.startText : textstart}
                                                       onChange={(event) => {
                                                           setUpdatetextstart(true)
                                                           shot.startText = ''
                                                           settextstart(event.target.value)
                                                           setsaveVvodSlova({
                                                               show: false,
                                                               message: ''
                                                           })
                                                       }}/>
                                                {Updatetextstart &&
                                                    <button className="bx bx-save fs-3 bg-transparent border-0"
                                                            onClick={() => {
                                                                updateStartText()
                                                                settextstart('')
                                                                setUpdatetextstart(false)
                                                            }}>
                                                    </button>}
                                            </div>
                                        </div>
                                        {
                                            saveVvodSlova.show &&
                                            <Alert style={{color: 'green'}}>{saveVvodSlova.message}</Alert>
                                        }
                                    </Col>
                                    <Col sm={12}>
                                        <div className="mt-2">
                                            <label htmlFor="hintWords" className="form-label m-0">Подсказка</label>
                                            <div className="d-flex">
                                                <input type="text" id="hintWords" name="hintWords"
                                                       className="form-control" value={shot.hint ? shot.hint : hint}
                                                       onChange={(event) => {
                                                           setUpdatehint(true)
                                                           shot.hint = ''
                                                           sethint(event.target.value)
                                                           setsaveHint({
                                                               show: false,
                                                               message: ''
                                                           })}}/>
                                                {Updatehint &&
                                                    <button className="bx bx-save fs-3 bg-transparent border-0"
                                                            onClick={() => {
                                                                updateHint()
                                                                sethint('')
                                                                setUpdatehint(false)
                                                            }}>
                                                    </button>}
                                            </div>
                                        </div>
                                        {
                                            saveHint.show &&
                                            <Alert style={{color: 'green'}}>{saveHint.message}</Alert>
                                        }
                                    </Col>
                                    <Col sm={12}>
                                        <div className="mt-2">
                                            <Label check className="w-100">
                                                Голос автора
                                                <select className="form-select w-100" value={shot.voice}
                                                        onChange={(e) => setShot({
                                                            ...shot,
                                                            voice: e.target.value as any
                                                        })}>
                                                    <option value={TypeSettingsGenderVoice.MAN}>
                                                        Мужчина
                                                    </option>
                                                    <option value={TypeSettingsGenderVoice.WOMAN}>
                                                        Женщина
                                                    </option>
                                                </select>
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <div className="mt-2">
                                            <Label check className="w-100">
                                                Анимации
                                                <select className="form-select w-100" value={shot.defaultAnimate}
                                                        onChange={(e) => onChangeTypeAnimateAll(e.target.value as any)}>
                                                    <option value="none">
                                                        Без анимации
                                                    </option>
                                                    <option value="shadow">
                                                        Тень
                                                    </option>
                                                    <option value="pulse">
                                                        Пульсация
                                                    </option>
                                                </select>
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <div className="d-grid gap-2 mt-2">
                                            <div className="d-flex align-items-center gap-3">
                                                <label htmlFor="isChatGPT" className="form-label m-0">Автопереключение
                                                    кадра</label>
                                                <input type="checkbox" id="isAutoPlay" name="isAutoPlay"
                                                       className="form-check-input fs-3"
                                                       checked={shot.settingsAutoPlay.play}
                                                       onChange={(event) => setShot({
                                                           ...shot,
                                                           settingsAutoPlay: {
                                                               ...shot.settingsAutoPlay,
                                                               play: event.target.checked
                                                           }
                                                       })}/>
                                            </div>
                                            {
                                                shot.settingsAutoPlay.play &&
                                                <div className="mt-2">
                                                    <label htmlFor="isAutoPlayTime" className="form-label m-0">Время
                                                        задержки после озвучки вводных слов (сек.)</label>
                                                    <input type="text" id="isAutoPlayTime" min={0}
                                                           name="isAutoPlayTime"
                                                           className="form-control"
                                                           value={shot.settingsAutoPlay.time}
                                                           onChange={(event) => {
                                                               if (!isNaN(+event.target.value))
                                                                   setShot({
                                                                       ...shot,
                                                                       settingsAutoPlay: {
                                                                           ...shot.settingsAutoPlay,
                                                                           time: +event.target.value
                                                                       }
                                                                   })
                                                           }}/>
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <div className="mt-2 d-flex gap-3 align-items-center">
                                            <label htmlFor="isChatGPT" className="form-label m-0">Использовать
                                                GPT</label>
                                            <input type="checkbox" id="isChatGPT" name="isChatGPT"
                                                   className="form-check-input fs-3"
                                                   checked={shot.isGPTChat} onChange={(event) => setShot({
                                                ...shot,
                                                isGPTChat: event.target.checked
                                            })}/>
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <div className="gap-2 mt-2">
                                            <div className="mt-2">
                                                <label htmlFor="wTextureWords" className="form-label m-0">Ширина
                                                    текстур</label>
                                                <input type="number" id="hintWords" name="wTextureWords"
                                                       className="form-control" value={hTexture}
                                                       onChange={(event) => {
                                                           setWTexture(+event.target.value)
                                                           setHTexture(+event.target.value)
                                                       }}/>
                                            </div>
                                            <div>
                                                <label htmlFor="hTextureWords" className="form-label m-0">Высота
                                                    текстур</label>
                                                <input type="text" id="hintWords" disabled
                                                       name="hTextureWords"
                                                       className="form-control" value={wTexture}
                                                       onChange={(event) => {
                                                           setHTexture(+event.target.value)
                                                       }}/>
                                            </div>
                                        </div>
                                        <button className={classNames("btn btn-light mt-2 w-100", {
                                            "mb-4": open === "1"
                                        })}
                                                onClick={() => updateWidthHeightTextures()}>
                                            Применить размеры
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </Collapse>
                    </div>
                    <div className="accordion-item border-0 border-bottom">
                        <h2 className="accordion-header" id="genques-headingTwo">
                            <button
                                className={classNames(
                                    'accordion-button fw-medium bg-white text-black',
                                    {
                                        collapsed: open !== '3'
                                    }
                                )}
                                type="button"
                                onClick={() => toggle('3')}
                                style={{cursor: "pointer"}}
                            >
                                Элементы
                            </button>
                        </h2>
                        <Collapse isOpen={open === '3'} className="accordion-collapse">
                            <div className="accordion-body p-0 text-black fw-normal py-2">
                                <div className="accordion ms-3" id="genques-accordion">
                                    <div className="accordion-item border-0 border-bottom">
                                        <h2 className="accordion-header" id="genques-headingTwo">
                                            <button
                                                className={classNames(
                                                    'accordion-button fw-medium bg-white text-black',
                                                    {
                                                        collapsed: openTextureCategory !== 'all'
                                                    }
                                                )}
                                                type="button"
                                                onClick={() => toggleTextureCategory('all')}
                                                style={{cursor: "pointer"}}
                                            >
                                                Все
                                            </button>
                                        </h2>
                                        <Collapse isOpen={openTextureCategory === 'all'}
                                                  className="accordion-collapse">
                                            <div className="accordion-body p-0 text-black fw-normal py-2 overflow-auto"
                                                 style={{maxHeight: '400px'}}>
                                                {
                                                    textures.map((texture) => <Row className="mt-1 w-100">
                                                        <Col sm={5}
                                                             className="position-relative d-flex justify-content-center"
                                                             key={texture.id}>
                                                            <img
                                                                src={process.env.REACT_APP_API_BASE_URL + texture.path}
                                                                alt="user" className="cursor-pointer"
                                                                style={{height: 110, width: 110}} onClick={() => {
                                                                handleAddTexture(texture)
                                                            }}/>
                                                        </Col>
                                                        {
                                                            shot.textures.find(el => el.id === texture.id) &&
                                                            <Col>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-start h-100 w-100">
                                                                    <Badge color="success"
                                                                           style={{width: 30, height: 30}}
                                                                           className="rounded-circle d-flex justify-content-center align-items-center"><i
                                                                        className="bx bx-check fs-3"></i></Badge>
                                                                </div>
                                                            </Col>
                                                        }
                                                    </Row>)
                                                }
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item border-0 border-bottom">
                                        <h2 className="accordion-header" id="genques-headingTwo">
                                            <button
                                                className={classNames(
                                                    'accordion-button fw-medium bg-white text-black',
                                                    {
                                                        collapsed: openTextureCategory !== null
                                                    }
                                                )}
                                                type="button"
                                                onClick={() => toggleTextureCategory(null)}
                                                style={{cursor: "pointer"}}
                                            >
                                                Без категории
                                            </button>
                                        </h2>
                                        <Collapse isOpen={openTextureCategory === null}
                                                  className="accordion-collapse">
                                            <div className="accordion-body p-0 text-black fw-normal py-2 overflow-auto"
                                                 style={{maxHeight: '400px'}}>
                                                {
                                                    textures.filter(el => el.category_texture_id === null).map((texture) =>
                                                        <Row className="mt-1 w-100">
                                                            <Col sm={5}
                                                                 className="position-relative d-flex justify-content-center"
                                                                 key={texture.id}>
                                                                <img
                                                                    src={process.env.REACT_APP_API_BASE_URL + texture.path}
                                                                    alt="user" className="cursor-pointer"
                                                                    style={{height: 110, width: 110}} onClick={() => {
                                                                    handleAddTexture(texture)
                                                                }}/>
                                                            </Col>
                                                            {
                                                                shot.textures.find(el => el.id === texture.id) &&
                                                                <Col>
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-start h-100 w-100">
                                                                        <Badge color="success"
                                                                               style={{width: 30, height: 30}}
                                                                               className="rounded-circle d-flex justify-content-center align-items-center"><i
                                                                            className="bx bx-check fs-3"></i></Badge>
                                                                    </div>
                                                                </Col>
                                                            }
                                                        </Row>)
                                                }
                                            </div>
                                        </Collapse>
                                    </div>
                                    {
                                        categoryTextures.sort((category1, category2
                                        ) => category1['name'] > category2['name'] ? 1 : -1).map((category) => (
                                            <div className="accordion-item border-0 border-bottom">
                                                <h2 className="accordion-header" id="genques-headingTwo">
                                                    <button
                                                        className={classNames(
                                                            'accordion-button fw-medium bg-white text-black',
                                                            {
                                                                collapsed: openTextureCategory !== category.id
                                                            }
                                                        )}
                                                        type="button"
                                                        onClick={() => {
                                                            toggleTextureCategory(category.id)
                                                        }}
                                                        style={{cursor: "pointer"}}
                                                    >
                                                        {category.name}
                                                    </button>
                                                </h2>
                                                <Collapse isOpen={openTextureCategory === category.id}
                                                          className="accordion-collapse">
                                                    <div
                                                        className="accordion-body p-0 text-black fw-normal py-2 overflow-auto"
                                                        style={{maxHeight: '400px'}}>
                                                        {
                                                            textures.filter(el => el.category_texture_id === category.id).map((texture) =>
                                                                <Row className="mt-1 w-100">
                                                                    <Col sm={5}
                                                                         className="position-relative d-flex justify-content-center"
                                                                         key={texture.id}>
                                                                        <img
                                                                            src={process.env.REACT_APP_API_BASE_URL + texture.path}
                                                                            alt="user" className="cursor-pointer"
                                                                            style={{height: 110, width: 110}}
                                                                            onClick={() => {
                                                                                handleAddTexture(texture)
                                                                            }}/>
                                                                    </Col>
                                                                    {
                                                                        shot.textures.find(el => el.id === texture.id) &&
                                                                        <Col>
                                                                            <div
                                                                                className="d-flex align-items-center justify-content-start h-100 w-100">
                                                                                <Badge color="success"
                                                                                       style={{width: 30, height: 30}}
                                                                                       className="rounded-circle d-flex justify-content-center align-items-center"><i
                                                                                    className="bx bx-check fs-3"></i></Badge>
                                                                            </div>
                                                                        </Col>
                                                                    }
                                                                </Row>)
                                                        }
                                                    </div>
                                                </Collapse>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className={classNames({
                                    "mb-4": open === "3"
                                })}></div>
                            </div>
                        </Collapse>
                    </div>
                    <div className="accordion-item border-0 border-bottom">
                        <h2 className="accordion-header" id="genques-headingTwo">
                            <button
                                className={classNames(
                                    'accordion-button fw-medium bg-white text-black',
                                    {
                                        collapsed: open !== '4'
                                    }
                                )}
                                type="button"
                                onClick={() => toggle('4')}
                                style={{cursor: "pointer"}}
                            >
                                Фон
                            </button>
                        </h2>
                        <Collapse isOpen={open === '4'} className="accordion-collapse">
                            <div className="accordion-body p-0 text-black fw-normal py-2">
                                <div className="accordion ms-3" id="genques-accordion">
                                    <div className="accordion-item border-0 border-bottom">
                                        <h2 className="accordion-header" id="genques-headingTwo">
                                            <button
                                                className={classNames(
                                                    'accordion-button fw-medium bg-white text-black',
                                                    {
                                                        collapsed: openBackgroundCategory !== 'all'
                                                    }
                                                )}
                                                type="button"
                                                onClick={() => toggleBackgroundCategory('all')}
                                                style={{cursor: "pointer"}}
                                            >
                                                Все
                                            </button>
                                        </h2>
                                        <Collapse isOpen={openBackgroundCategory === 'all'}
                                                  className="accordion-collapse">
                                            <div className="accordion-body p-0 text-black fw-normal py-2 overflow-auto"
                                                 style={{maxHeight: '400px'}}>
                                                {
                                                    backgroundImages.map((bg) => <Row className="mt-1 w-100">
                                                        <Col sm={6}
                                                             className="position-relative d-flex justify-content-center"
                                                             key={bg.id}>
                                                            <img
                                                                src={process.env.REACT_APP_API_BASE_URL + bg.path}
                                                                alt="user" className="cursor-pointer"
                                                                style={{height: 110, width: 200}} onClick={() => {
                                                                handleAddBackgroundImage(bg)
                                                            }}/>
                                                        </Col>
                                                        {
                                                            shot.backgroundImage && shot.backgroundImage.id === bg.id &&
                                                            <Col>
                                                                <div
                                                                    className="d-flex align-items-center p-3 justify-content-start h-100 w-100">
                                                                    <Badge color="success"
                                                                           style={{width: 30, height: 30}}
                                                                           className="rounded-circle d-flex justify-content-center align-items-center"><i
                                                                        className="bx bx-check fs-3"></i></Badge>
                                                                </div>
                                                            </Col>
                                                        }
                                                    </Row>)
                                                }
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item border-0 border-bottom">
                                        <h2 className="accordion-header" id="genques-headingTwo">
                                            <button
                                                className={classNames(
                                                    'accordion-button fw-medium bg-white text-black',
                                                    {
                                                        collapsed: openBackgroundCategory !== null
                                                    }
                                                )}
                                                type="button"
                                                onClick={() => toggleBackgroundCategory(null)}
                                                style={{cursor: "pointer"}}
                                            >
                                                Без категории
                                            </button>
                                        </h2>
                                        <Collapse isOpen={openBackgroundCategory === null}
                                                  className="accordion-collapse">
                                            <div className="accordion-body p-0 text-black fw-normal py-2 overflow-auto"
                                                 style={{maxHeight: '400px'}}>
                                                {
                                                    backgroundImages.filter(el => el.category_background_id === null).map((bg) =>
                                                        <Row className="mt-1 w-100">
                                                            <Col sm={6}
                                                                 className="position-relative d-flex justify-content-center"
                                                                 key={bg.id}>
                                                                <img
                                                                    src={process.env.REACT_APP_API_BASE_URL + bg.path}
                                                                    alt="user" className="cursor-pointer"
                                                                    style={{height: 110, width: 200}} onClick={() => {
                                                                    handleAddBackgroundImage(bg)
                                                                }}/>
                                                            </Col>
                                                            {
                                                                shot.backgroundImage && shot.backgroundImage.id === bg.id &&
                                                                <Col>
                                                                    <div
                                                                        className="d-flex align-items-center p-3 justify-content-start h-100 w-100">
                                                                        <Badge color="success"
                                                                               style={{width: 30, height: 30}}
                                                                               className="rounded-circle d-flex justify-content-center align-items-center"><i
                                                                            className="bx bx-check fs-3"></i></Badge>
                                                                    </div>
                                                                </Col>
                                                            }
                                                        </Row>)
                                                }
                                            </div>
                                        </Collapse>
                                    </div>
                                    {
                                        categoryBackgrounds.sort((category1, category2
                                        ) => category1['name'] > category2['name'] ? 1 : -1).map((category) => (
                                            <div className="accordion-item border-0 border-bottom">
                                                <h2 className="accordion-header" id="genques-headingTwo">
                                                    <button
                                                        className={classNames(
                                                            'accordion-button fw-medium bg-white text-black',
                                                            {
                                                                collapsed: openBackgroundCategory !== category.id
                                                            }
                                                        )}
                                                        type="button"
                                                        onClick={() => toggleBackgroundCategory(category.id)}
                                                        style={{cursor: "pointer"}}
                                                    >
                                                        {category.name}
                                                    </button>
                                                </h2>
                                                <Collapse isOpen={openBackgroundCategory === category.id}
                                                          className="accordion-collapse">
                                                    <div
                                                        className="accordion-body p-0 text-black fw-normal py-2 overflow-auto"
                                                        style={{maxHeight: '400px'}}>
                                                        {
                                                            backgroundImages.filter(el => el.category_background_id === category.id).map((bg) =>
                                                                <Row className="mt-1 w-100">
                                                                    <Col sm={6}
                                                                         className="position-relative d-flex justify-content-center"
                                                                         key={bg.id}>
                                                                        <img
                                                                            src={process.env.REACT_APP_API_BASE_URL + bg.path}
                                                                            alt="user" className="cursor-pointer"
                                                                            style={{height: 110, width: 200}}
                                                                            onClick={() => {
                                                                                handleAddBackgroundImage(bg)
                                                                            }}/>
                                                                    </Col>
                                                                    {
                                                                        shot.backgroundImage && shot.backgroundImage.id === bg.id &&
                                                                        <Col>
                                                                            <div
                                                                                className="d-flex align-items-center p-3 justify-content-start h-100 w-100">
                                                                                <Badge color="success"
                                                                                       style={{width: 30, height: 30}}
                                                                                       className="rounded-circle d-flex justify-content-center align-items-center"><i
                                                                                    className="bx bx-check fs-3"></i></Badge>
                                                                            </div>
                                                                        </Col>
                                                                    }
                                                                </Row>)
                                                        }
                                                    </div>
                                                </Collapse>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className={classNames({
                                    "mb-4": open === "4"
                                })}></div>
                            </div>
                        </Collapse>
                    </div>
                    <div className="accordion-item border-0 border-bottom">
                        <h2 className="accordion-header" id="genques-headingTwo">
                            <button
                                className={classNames(
                                    'accordion-button fw-medium bg-white text-black',
                                    {
                                        collapsed: open !== '5'
                                    }
                                )}
                                type="button"
                                onClick={() => toggle('5')}
                                style={{cursor: "pointer"}}
                            >
                                Музыка заднего фона
                            </button>
                        </h2>
                        <Collapse isOpen={open === '5'} className="accordion-collapse">
                            <div className="accordion-body p-0 text-black fw-normal py-2">
                                <Row className="ms-3">
                                    <Col sm={12}>
                                        <div className="d-flex justify-content-start">
                                            <div className="d-flex justify-content-center align-items-center">
                                                <input type="checkbox" id="useSoundBack" name="useSoundBack"
                                                       className="form-check-input fs-3 mx-2"
                                                       checked={shot.useSoundBack}
                                                       onChange={(event) => onChangeUseBackSound(event.target.checked)}/>
                                                <label htmlFor="useSoundBack" className="form-label mt-1 mb-0">Использовать
                                                    из прошлого кадра</label>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="accordion ms-3" id="genques-accordion">
                                    <div className="accordion-item border-0 border-bottom">
                                        <h2 className="accordion-header" id="genques-headingTwo">
                                            <button
                                                className={classNames(
                                                    'accordion-button fw-medium bg-white text-black',
                                                    {
                                                        collapsed: openBackgroundSoundCategory !== 'all'
                                                    }
                                                )}
                                                type="button"
                                                onClick={() => toggleBackgroundSoundCategory('all')}
                                                style={{cursor: "pointer"}}
                                            >
                                                Все
                                            </button>
                                        </h2>
                                        <Collapse isOpen={openBackgroundSoundCategory === 'all'}
                                                  className="accordion-collapse">
                                            <div className="accordion-body p-0 text-black fw-normal py-2 overflow-auto"
                                                 style={{maxHeight: '400px'}}>
                                                {
                                                    backgroundSounds.map((bgSound) => <Row className="mt-1 w-100">
                                                        <Col sm={9}
                                                             className="position-relative"
                                                             key={bgSound.id}>
                                                            <audio controls className="w-100">
                                                                <source
                                                                    src={process.env.REACT_APP_API_BASE_URL + bgSound.path}
                                                                    type="audio/mp3" className="cursor-pointer flex-1"/>
                                                            </audio>
                                                            <label htmlFor=""
                                                                   className="w-100 text-center fw-bold fs-14"
                                                                   onClick={() => handleAddBackgroundSound(bgSound)}>{bgSound.name}</label>
                                                        </Col>
                                                        {
                                                            shot.backgroundSound && shot.backgroundSound.id === bgSound.id &&
                                                            <Col>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-start h-100 w-100">
                                                                    <Badge color="success"
                                                                           style={{width: 30, height: 30}}
                                                                           className="rounded-circle d-flex justify-content-center align-items-center"><i
                                                                        className="bx bx-check fs-3"></i></Badge>
                                                                </div>
                                                            </Col>
                                                        }
                                                    </Row>)
                                                }
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item border-0 border-bottom">
                                        <h2 className="accordion-header" id="genques-headingTwo">
                                            <button
                                                className={classNames(
                                                    'accordion-button fw-medium bg-white text-black',
                                                    {
                                                        collapsed: openBackgroundSoundCategory !== null
                                                    }
                                                )}
                                                type="button"
                                                onClick={() => toggleBackgroundSoundCategory(null)}
                                                style={{cursor: "pointer"}}
                                            >
                                                Без категории
                                            </button>
                                        </h2>
                                        <Collapse isOpen={openBackgroundSoundCategory === null}
                                                  className="accordion-collapse">
                                            <div className="accordion-body p-0 text-black fw-normal py-2 overflow-auto"
                                                 style={{maxHeight: '400px'}}>
                                                {
                                                    backgroundSounds.filter(el => el.category_background_sound_id === null).map((bgSound) =>
                                                        <Row className="mt-1 w-100">
                                                            <Col sm={9}
                                                                 className="position-relative"
                                                                 key={bgSound.id}>
                                                                <audio controls className="w-100">
                                                                    <source
                                                                        src={process.env.REACT_APP_API_BASE_URL + bgSound.path}
                                                                        type="audio/mp3" className="cursor-pointer"/>
                                                                </audio>
                                                                <label htmlFor=""
                                                                       className="w-100 text-center fw-bold fs-14"
                                                                       onClick={() => handleAddBackgroundSound(bgSound)}>{bgSound.name}</label>
                                                            </Col>
                                                            {
                                                                shot.backgroundSound && shot.backgroundSound.id === bgSound.id &&
                                                                <Col>
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-start h-100 w-100">
                                                                        <Badge color="success"
                                                                               style={{width: 30, height: 30}}
                                                                               className="rounded-circle d-flex justify-content-center align-items-center"><i
                                                                            className="bx bx-check fs-3"></i></Badge>
                                                                    </div>
                                                                </Col>
                                                            }
                                                        </Row>)
                                                }
                                            </div>
                                        </Collapse>
                                    </div>
                                    {
                                        categoryBackgroundSounds.sort((category1, category2
                                        ) => category1['name'] > category2['name'] ? 1 : -1).map((category) => (
                                            <div className="accordion-item border-0 border-bottom">
                                                <h2 className="accordion-header" id="genques-headingTwo">
                                                    <button
                                                        className={classNames(
                                                            'accordion-button fw-medium bg-white text-black',
                                                            {
                                                                collapsed: openBackgroundSoundCategory !== category.id
                                                            }
                                                        )}
                                                        type="button"
                                                        onClick={() => toggleBackgroundSoundCategory(category.id)}
                                                        style={{cursor: "pointer"}}
                                                    >
                                                        {category.name}
                                                    </button>
                                                </h2>
                                                <Collapse isOpen={openBackgroundSoundCategory === category.id}
                                                          className="accordion-collapse">
                                                    <div
                                                        className="accordion-body p-0 text-black fw-normal py-2 overflow-auto"
                                                        style={{maxHeight: '400px'}}>
                                                        {
                                                            backgroundSounds.filter(el => el.category_background_sound_id === category.id).map((bgSound) =>
                                                                <Row className="mt-1 w-100">
                                                                    <Col sm={9}
                                                                         className="position-relative"
                                                                         key={bgSound.id}>
                                                                        <audio controls className="w-100">
                                                                            <source
                                                                                src={process.env.REACT_APP_API_BASE_URL + bgSound.path}
                                                                                type="audio/mp3"
                                                                                className="cursor-pointer"/>
                                                                        </audio>
                                                                        <label htmlFor=""
                                                                               className="w-100 text-center fw-bold fs-14"
                                                                               onClick={() => handleAddBackgroundSound(bgSound)}>{bgSound.name}</label>
                                                                    </Col>
                                                                    {
                                                                        shot.backgroundSound && shot.backgroundSound.id === bgSound.id &&
                                                                        <Col>
                                                                            <div
                                                                                className="d-flex align-items-center justify-content-start h-100 w-100">
                                                                                <Badge color="success"
                                                                                       style={{width: 30, height: 30}}
                                                                                       className="rounded-circle d-flex justify-content-center align-items-center"><i
                                                                                    className="bx bx-check fs-3"></i></Badge>
                                                                            </div>
                                                                        </Col>
                                                                    }
                                                                </Row>)
                                                        }
                                                    </div>
                                                </Collapse>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className={classNames({
                                    "mb-4": open === "5"
                                })}></div>
                            </div>
                        </Collapse>
                    </div>
                    <div className="accordion-item border-0 border-bottom">
                        <h2 className="accordion-header" id="genques-headingTwo">
                            <button
                                className={classNames(
                                    'accordion-button fw-medium bg-white text-black',
                                    {
                                        collapsed: open !== '6'
                                    }
                                )}
                                type="button"
                                onClick={() => toggle('6')}
                                style={{cursor: "pointer"}}
                            >
                                Механика
                            </button>
                        </h2>
                        <Collapse isOpen={open === '6'} className="accordion-collapse">
                            <div className="accordion-body p-0 text-black fw-normal py-2">
                                <Row>
                                    <Col sm={12}>
                                        <div className="mt-2">
                                            <Label check className="w-100">
                                                Выбор механики
                                                <select className="form-select w-100" value={activeTabSettings}
                                                        onChange={(e) =>
                                                            handleActiveTabSettings(e.target.value as any)}>
                                                    <option value="0">
                                                        Нет механики
                                                    </option>
                                                    <option value="1">
                                                        Посчитать кол-во текстур
                                                    </option>
                                                    <option value="2">
                                                        Решить пример
                                                    </option>
                                                    <option value="3">
                                                        Найти одинаковые картинки
                                                    </option>
                                                    <option value="4">
                                                        Битва
                                                    </option>
                                                </select>
                                            </Label>
                                        </div>
                                    </Col>
                                </Row>
                                {
                                    activeTabSettings === "1" &&
                                    <Row className="mt-4 gap-4">
                                        <Col sm={12}>
                                            <label htmlFor="textTrueCountTexture" className="form-label">Текст
                                                верного ответа</label>
                                            <input type="text" id="textTrueCountTexture"
                                                   value={shot.settings ? (shot.settings.settings as ISettingsCountTextures).textTrue : ''}
                                                   onChange={(event) => {
                                                       setShot((shot) => {
                                                           if (shot.settings) {
                                                               return {
                                                                   ...shot,
                                                                   settings: {
                                                                       ...shot.settings,
                                                                       settings: {
                                                                           ...shot.settings.settings,
                                                                           textTrue: event.target.value
                                                                       }
                                                                   }
                                                               }
                                                           }
                                                           return shot
                                                       })
                                                   }} className="form-control"/>
                                        </Col>
                                        <Col sm={12}>
                                            <label htmlFor="textFalseCountTexture" className="form-label">Текст не
                                                верного ответа</label>
                                            <input type="text" id="textFalseCountTexture"
                                                   className="form-control"
                                                   value={shot.settings ? (shot.settings.settings as ISettingsCountTextures).textFalse : ''}
                                                   onChange={(event) => {
                                                       setShot((shot) => {
                                                           if (shot.settings) {
                                                               return {
                                                                   ...shot,
                                                                   settings: {
                                                                       ...shot.settings,
                                                                       settings: {
                                                                           ...shot.settings.settings,
                                                                           textFalse: event.target.value
                                                                       }
                                                                   }
                                                               }
                                                           }
                                                           return shot
                                                       })
                                                   }}/>
                                        </Col>
                                        <Col sm={12}>
                                            <label htmlFor="answerCountTexture" className="form-label">Ответ</label>
                                            <input type="number" id="answerCountTexture"
                                                   className="form-control"
                                                   value={shot.settings ? (shot.settings.settings as ISettingsCountTextures).answer : ''}
                                                   onChange={(event) => {
                                                       setShot((shot) => {
                                                           if (shot.settings) {
                                                               return {
                                                                   ...shot,
                                                                   settings: {
                                                                       ...shot.settings,
                                                                       settings: {
                                                                           ...shot.settings.settings,
                                                                           answer: event.target.value
                                                                       }
                                                                   }
                                                               }
                                                           }
                                                           return shot
                                                       })
                                                   }}/>
                                        </Col>
                                    </Row>
                                }
                                {
                                    activeTabSettings === "2" &&
                                    <Row className="mt-4">
                                        <Col sm={12}>
                                            <label htmlFor="trueAnswer" className="form-label">Текст правильного
                                                ответа</label>
                                            <input type="text" id="trueAnswer"
                                                   value={shot.settings ? (shot.settings.settings as ISettingsAnswerShot).text : ''}
                                                   onChange={(event) => onChangeAnswerShotText(event.target.value)}
                                                   className="form-control"/>
                                        </Col>
                                        <Col sm={12}>
                                            <label htmlFor="falseAnswer" className="form-label">Текст не
                                                правильного ответа</label>
                                            <input type="text" id="falseAnswer"
                                                   value={shot.settings ? (shot.settings.settings as ISettingsAnswerShot).textFalse : ''}
                                                   onChange={(event) => onChangeAnswerShotTextFalse(event.target.value)}
                                                   className="form-control"/>
                                        </Col>
                                        <Col sm={12}>
                                            <label htmlFor="isChatGPTAnswer" className="form-label">Использовать
                                                GPT</label>
                                            <div>
                                                <input type="checkbox" id="isChatGPTAnswer"
                                                       name="isChatGPTAnswer"
                                                       className="form-check-input fs-3"
                                                       checked={shot.settings ? (shot.settings.settings as ISettingsAnswerShot).isGPTChat : false}
                                                       onChange={(event) => onChangeAnswerShotIsChatGPT(event.target.checked)}/>
                                            </div>
                                        </Col>
                                        {
                                            (shot.settings?.settings as ISettingsAnswerShot).html[0] && !UpdateEditorText &&
                                            <TextEditorProvider
                                                html={(shot.settings?.settings as ISettingsAnswerShot).htmlVid ?
                                                    (shot.settings?.settings as ISettingsAnswerShot).htmlVid :
                                                    (shot.settings?.settings as ISettingsAnswerShot).html[0].primer}>
                                                <Suspense>
                                                    <TextEditor
                                                        handleHtml={(html) => {onChangeAnswerShotHtml(html)}}/>
                                                </Suspense>
                                            </TextEditorProvider>
                                        }
                                        {
                                            (addPrimer || EditPrimer) &&
                                            <Col sm={12}>
                                                <label htmlFor="primer" className="form-label">Текст задания</label>
                                                <input type="text" id="primer"
                                                       value={namePrimer}
                                                       onChange={(event) => {
                                                           setNamePrimer(event.target.value)}}
                                                       className="form-control"/>
                                                <label htmlFor="textAnswer" className="form-label">Ответ</label>
                                                <input type="text" id="textAnswer"
                                                       value={answer}
                                                       onChange={(event) => {
                                                           setAnswer(event.target.value)
                                                       }}
                                                       className="form-control"/>
                                            </Col>
                                        }
                                        {EditPrimer ?
                                            <button type="button"
                                                    className="btn btn-primary mt-2"
                                                    onClick={() => {
                                                        handleEditAnswerShot(idprimer, namePrimer, answer)
                                                        setUpdateEditorText(false)
                                                    }}>
                                                Изменить пример
                                            </button>
                                            :
                                            addPrimer ?
                                                <button type="button"
                                                        className="btn btn-primary mt-2"
                                                        onClick={() => {
                                                            handleAddAnswerShot(namePrimer, answer)
                                                        }}>
                                                    Сохранить пример
                                                </button>
                                                :
                                                <button type="button"
                                                        className="btn btn-primary mt-2"
                                                        onClick={() => {
                                                            setAddPrimer(true)
                                                        }}>
                                                    Добавить пример
                                                </button>
                                        }
                                        {
                                            error.show &&
                                            <Alert color="danger">{error.message}</Alert>
                                        }
                                        {
                                            (shot.settings?.settings as ISettingsAnswerShot).html.map((el) => (
                                                <Col sm={12}>
                                                    <Badge className="fs-5 mt-2">
                                                        <div
                                                            className="d-flex gap-3 justify-content-center align-items-center">
                                                            <p className="m-0">{el.primer}</p>
                                                        </div>
                                                    </Badge>
                                                    <Badge className="fs-5 mt-2">
                                                        <div
                                                            className="d-flex gap-3 justify-content-center align-items-center">
                                                            <p className="m-0">ответ: {el.textAnswer}</p>
                                                        </div>
                                                    </Badge>
                                                    <i className="bx bx-edit fs-3 m-0 cursor-pointer"
                                                       onClick={() => {
                                                           setAddPrimer(false)
                                                           setEditPrimer(true)
                                                           setidprimer(el.id)
                                                           setNamePrimer(el.primer)
                                                           setAnswer(el.textAnswer)
                                                           if(el === (shot.settings?.settings as ISettingsAnswerShot).html[0]) {
                                                               setUpdateEditorText(true)
                                                               if (shot.settings) {
                                                                   setShot((shot) => {
                                                                       const {settings} = shot;
                                                                       if (settings) {
                                                                           return {
                                                                               ...shot,
                                                                               settings: {
                                                                                   ...settings,
                                                                                   ...(settings.settings && {
                                                                                       settings: {
                                                                                           ...settings.settings,
                                                                                           htmlVid: ''
                                                                                       }
                                                                                   }),
                                                                               }
                                                                           }
                                                                       }
                                                                       return shot
                                                                   })
                                                               }
                                                           }
                                                       }}></i>
                                                    <i className="bx bx-x fs-3 m-0 cursor-pointer" onClick={() => {
                                                        if(el === (shot.settings?.settings as ISettingsAnswerShot).html[0]) {
                                                            if (shot.settings) {
                                                                setShot((shot) => {
                                                                    const {settings} = shot;
                                                                    if (settings) {
                                                                        return {
                                                                            ...shot,
                                                                            settings: {
                                                                                ...settings,
                                                                                ...(settings.settings && {
                                                                                    settings: {
                                                                                        ...settings.settings,
                                                                                        htmlVid: ''
                                                                                    }
                                                                                }),
                                                                            }
                                                                        }
                                                                    }
                                                                    return shot
                                                                })
                                                            }
                                                            setUpdateEditorText(true)
                                                            setTimeout(() => {
                                                                setUpdateEditorText(false)
                                                            }, 100)
                                                        }
                                                        if (shot.settings) {
                                                            setShot((shot) => {
                                                                const {settings} = shot;
                                                                if (settings) {
                                                                    return {
                                                                        ...shot,
                                                                        settings: {
                                                                            ...settings,
                                                                            ...(settings.settings && {
                                                                                settings: {
                                                                                    ...settings.settings,
                                                                                    html: (shot.settings?.settings as ISettingsAnswerShot).html.filter(prev => prev !== el)
                                                                                }
                                                                            })
                                                                        }
                                                                    }
                                                                }
                                                                return shot
                                                            })
                                                        }
                                                    }}></i>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                }
                                {
                                    activeTabSettings === "3" &&
                                    <Row className="mt-4 gap-4">
                                        <Col sm={12}>
                                            <label htmlFor="textTrueSearchTexture" className="form-label">Текст
                                                верного ответа</label>
                                            <input type="text" id="textTrueSearchTexture"
                                                   value={shot.settings ? (shot.settings.settings as ISettingsSearch).textTrue : ''}
                                                   onChange={(event) => {
                                                       setShot((shot) => {
                                                           if (shot.settings) {
                                                               return {
                                                                   ...shot,
                                                                   settings: {
                                                                       ...shot.settings,
                                                                       settings: {
                                                                           ...shot.settings.settings,
                                                                           textTrue: event.target.value
                                                                       }
                                                                   }
                                                               }
                                                           }
                                                           return shot
                                                       })
                                                   }} className="form-control"/>
                                        </Col>
                                        <Col sm={12}>
                                            <label htmlFor="textFalseSearchTexture" className="form-label">Текст не
                                                верного ответа</label>
                                            <input type="text" id="textFalseSearchTexture"
                                                   className="form-control"
                                                   value={shot.settings ? (shot.settings.settings as ISettingsSearch).textFalse : ''}
                                                   onChange={(event) => {
                                                       setShot((shot) => {
                                                           if (shot.settings) {
                                                               return {
                                                                   ...shot,
                                                                   settings: {
                                                                       ...shot.settings,
                                                                       settings: {
                                                                           ...shot.settings.settings,
                                                                           textFalse: event.target.value
                                                                       }
                                                                   }
                                                               }
                                                           }
                                                           return shot
                                                       })
                                                   }}/>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="d-flex align-items-center">
                                                <label htmlFor="startShowSearchTexture" className="form-label m-0">Показать текстуры с задержкой</label>
                                                <input type="checkbox" id="startShowSearchTexture"
                                                       className="form-check-input fs-3 mx-2"
                                                       checked={shot.settings ? (shot.settings.settings as ISettingsSearch).startShow : true}
                                                       onChange={(event) => {
                                                           setShot((shot) => {
                                                               if (shot.settings) {
                                                                   return {
                                                                       ...shot,
                                                                       settings: {
                                                                           ...shot.settings,
                                                                           settings: {
                                                                               ...shot.settings.settings,
                                                                               startShow: event.target.checked
                                                                           }
                                                                       }
                                                                   }
                                                               }
                                                               return shot
                                                           })
                                                       }}/>
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <div>
                                                <label htmlFor="startShowSearchTexture" className="form-label">Время задержки</label>
                                                <div>
                                                    <input type="range" id="startShowSearchTexture"
                                                           className="form-range fs-3 mx-2"
                                                           max={10}
                                                           min={1}
                                                           value={shot.settings ? (shot.settings.settings as ISettingsSearch).timeShow ?? 1 : 1}
                                                           onChange={(event) => {
                                                               setShot((shot) => {
                                                                   if (shot.settings) {
                                                                       return {
                                                                           ...shot,
                                                                           settings: {
                                                                               ...shot.settings,
                                                                               settings: {
                                                                                   ...shot.settings.settings,
                                                                                   timeShow: +event.target.value
                                                                               }
                                                                           }
                                                                       }
                                                                   }
                                                                   return shot
                                                               })
                                                           }}/>
                                                    <p>Задержка: {shot.settings ? (shot.settings.settings as ISettingsSearch).timeShow ?? 1 : 1}</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                                    <Col sm={12}>
                                        {/*
                                            activeTabSettings === "4" &&
                                            <Row>
                                                <Col sm={12} className="mt-2">
                                                    <label htmlFor="selectShotSwitch"
                                                           className="form-label m-0">Кадр в который перейти</label>
                                                    <select name="selectShotSwitch" id="selectShotSwitch"
                                                            value={(shot.settings?.settings as ISettingsBattle).shot.uid ?? ''}
                                                            className="form-select" onChange={(event) => {
                                                        const shot = game?.shots.find((el) => el.uid === event.target.value)
                                                        if (shot) {
                                                            setTexture((texture) => ({
                                                                ...texture,
                                                                pivot: {
                                                                    ...texture.pivot,
                                                                    settings: {
                                                                        ...texture.pivot.settings,
                                                                        settings: {
                                                                            ...texture.pivot.settings.settings,
                                                                            shot: shot
                                                                        }
                                                                    }
                                                                }
                                                            }))
                                                        }
                                                    }}>
                                                        {
                                                            game?.shots.filter(el => el.uid !== shot.uid).map((shot) => (
                                                                <option value={shot.uid}>{shot.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </Col>
                                            </Row>
                                        */}
                                    </Col>
                                <div className={classNames({
                                    "mb-4": open === "6"
                                })}></div>
                            </div>
                        </Collapse>
                    </div>
                    {/*<div className="accordion-item border-0 border-bottom">
                        <h2 className="accordion-header" id="genques-headingTwo">
                            <button
                                className={classNames(
                                    'accordion-button fw-medium bg-white text-black',
                                    {
                                        collapsed: open !== '7'
                                    }
                                )}
                                type="button"
                                onClick={() => toggle('7')}
                                style={{cursor: "pointer"}}
                            >
                                Переход
                            </button>
                        </h2>
                        <Collapse isOpen={open === '7'} className="accordion-collapse">
                            <div className="accordion-body p-0 text-black fw-normal py-2">
                                <Row>
                                    <Col sm={12}>
                                        <div className="mt-2">
                                            <Label check className="w-100" htmlFor="selectNextShot">
                                                Переход на кадр:
                                                <select name="selectNextShot" id="selectNextShot"
                                                        value={shot.NextShot?.uid}
                                                        className="form-select" onChange={(event) => {
                                                    console.log(typeof (shot.NextShot))
                                                    const Nextshot = game?.shots.find((el) => el.uid === event.target.value)
                                                    if (Nextshot) {
                                                        setShot((shot) => {
                                                            return {
                                                                ...shot,
                                                                NextShot: Nextshot
                                                            }
                                                        })
                                                    }
                                                    return shot
                                                }
                                                }>
                                                    <option value='0'></option>
                                                    {
                                                        game.shots.filter(selectshot => selectshot.uid !== shot.uid).map((shot) => (
                                                            <option value={shot.uid}>{shot.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </Label>
                                        </div>
                                    </Col>
                                </Row>
                                <div className={classNames({
                                    "mb-4": open === "7"
                                })}></div>
                            </div>
                        </Collapse>
                    </div>*/}
                    <div className="accordion-item border-0 border-bottom">
                        <h2 className="accordion-header" id="genques-headingTwo">
                            <button
                                className={classNames(
                                    'accordion-button fw-medium bg-white text-black',
                                    {
                                        collapsed: open !== '8'
                                    }
                                )}
                                type="button"
                                onClick={() => toggle('8')}
                                style={{cursor: "pointer"}}
                            >
                                Загрузка медиа
                            </button>
                        </h2>
                        <Collapse isOpen={open === '8'} className="accordion-collapse">
                            <div className="accordion-body p-0 text-black fw-normal py-2">
                                <div className="accordion ms-3" id="genques-accordion">
                                    <div className="accordion-item border-0 border-bottom">
                                        <h2 className="accordion-header" id="genques-headingTwo">
                                            <button
                                                className={classNames(
                                                    'accordion-button fw-medium bg-white text-black',
                                                    {
                                                        collapsed: activeTabUploadTexture !== '1'
                                                    }
                                                )}
                                                type="button"
                                                onClick={() => toggleActiveTabUploadTexture('1')}
                                                style={{cursor: "pointer"}}
                                            >
                                                Элементы
                                            </button>
                                        </h2>
                                        <Collapse isOpen={activeTabUploadTexture === '1'}
                                                  className="accordion-collapse">
                                            <div className="accordion-body p-0 text-black fw-normal py-2 overflow-auto"
                                                 style={{maxHeight: '400px'}}>
                                                {
                                                    error.show &&
                                                    <Alert color="danger">{error.message}</Alert>
                                                }
                                                <div className="d-flex gap-3 mb-3">
                                                    <Button color="light" title="Добавить элемент"
                                                            onClick={() => setIsAddTexture(true)}>
                                                        <i className="bx bx-plus-circle fs-3"></i>
                                                    </Button>
                                                    <Button color="light" title="Добавить категорию"
                                                            onClick={() => setIsAddCategoryTexture(true)}>
                                                        <i className="bx bx-category-alt fs-3"></i>
                                                    </Button>
                                                    <Button color="light" title="Удалить категорию"
                                                            onClick={() => setIsDeleteCategoryTexture(true)}>
                                                        <i className="bx bx-x fs-3"></i>
                                                    </Button>
                                                    <Button color="light" title="Добавить элемент AI"
                                                            onClick={() => setIsAddTextureGenerate(true)}>
                                                        <i className="bx bx-brain fs-3"></i>
                                                    </Button>
                                                </div>
                                                <Suspense fallback={<div>loading...</div>}>
                                                <TextureList onChangeSelectTexture={(texture) => {
                                                    setSelectTexture(texture)
                                                    setIsUpdateTexture(true)
                                                }} textures={textures} removeTexture={removeTexture}/>
                                                </Suspense>
                                                <Suspense fallback={<div>loading...</div>}>
                                                <ModalTextureAdd categoryTextures={categoryTextures}
                                                                 isAddTexture={isAddTexture}
                                                                 toggleIsAddTexture={() => setIsAddTexture(!isAddTexture)}
                                                                 addTexture={addTexture}/>
                                                </Suspense>
                                                <ModalTextureGenerate isAddTextureGenerate={isAddTextureGenerate}
                                                                      toggleIsAddTextureGenerate={() => setIsAddTextureGenerate(!isAddTextureGenerate)}
                                                                      categoryTextures={categoryTextures}
                                                                      addTexture={addTextureBase}/>
                                                        <Suspense fallback={<div>loading...</div>}>
                                                <ModalTextureCategoryAdd isAddTextureCategory={isAddCategoryTexture}
                                                                         toggleIsAddTextureCategory={() => setIsAddCategoryTexture(!isAddCategoryTexture)}
                                                                         addTextureCategory={addTexturesCategory}/>
                                                        </Suspense>
                                                <Suspense fallback={<div>loading...</div>}>
                                                    <ModalTextureCategoryDelete isDeleteTextureCategory={isDeleteCategoryTexture} categoryTextures={categoryTextures}
                                                                             toggleIsDeleteTextureCategory={() => setIsDeleteCategoryTexture(!isDeleteCategoryTexture)}
                                                                             DeleteTextureCategory={DeleteTexturesCategory}/>
                                                </Suspense>
                                                            <Suspense fallback={<div>loading...</div>}>
                                                <ModalTextureUpdate isUpdateTexture={isUpdateTexture}
                                                                    toggleIsUpdateTexture={() => setIsUpdateTexture(!isUpdateTexture)}
                                                                    updateTexture={updateTexture}
                                                                    selectTexture={selectTexture}
                                                                    categoryTextures={categoryTextures}/>
                                                            </Suspense>
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item border-0 border-bottom">
                                        <h2 className="accordion-header" id="genques-headingTwo">
                                            <button
                                                className={classNames(
                                                    'accordion-button fw-medium bg-white text-black',
                                                    {
                                                        collapsed: activeTabUploadTexture !== '3'
                                                    }
                                                )}
                                                type="button"
                                                onClick={() => toggleActiveTabUploadTexture('3')}
                                                style={{cursor: "pointer"}}
                                            >
                                                Задний фон
                                            </button>
                                        </h2>
                                        <Collapse isOpen={activeTabUploadTexture === '3'}
                                                  className="accordion-collapse">
                                            <div className="accordion-body p-0 text-black fw-normal py-2 overflow-auto"
                                                 style={{maxHeight: '400px'}}>
                                                {
                                                    error.show &&
                                                    <Alert color="danger">{error.message}</Alert>
                                                }
                                                <div className="d-flex gap-3 mb-3">
                                                    <Button color="light" title="Добавить задний фон"
                                                            onClick={() => setIsAddBackground(true)}>
                                                        <i className="bx bx-plus-circle fs-3"></i>
                                                    </Button>
                                                    <Button color="light" title="Добавить категорию"
                                                            onClick={() => setIsAddCategoryBackground(true)}>
                                                        <i className="bx bx-category-alt fs-3"></i>
                                                    </Button>
                                                    <Button color="light" title="Удалить категорию"
                                                             onClick={() => setIsDeleteCategoryBackground(true)}>
                                                        <i className="bx bx-x fs-3"></i>
                                                    </Button>
                                                    <Button color="light" title="Добавить задний фон AI"
                                                            onClick={() => setIsAddBackgroundGenerate(true)}>
                                                        <i className="bx bx-brain fs-3"></i>
                                                    </Button>
                                                </div>
                                            <Suspense fallback={<div>loading...</div>}>
                                                <BackgroundList background={backgroundImages}
                                                                removeBackground={removeBackground}
                                                                onChangeSelectBackground={(bg) => {
                                                                    setSelectBackground(bg)
                                                                    setIsUpdateBackground(true)
                                                                }}
                                                />
                                            </Suspense>
                                                <Suspense fallback={<div>loading...</div>}>
                                                <ModalBackgroundAdd isAddBackground={isAddBackground}
                                                                    categoryBackgrounds={categoryBackgrounds}
                                                                    toggleIsAddBackground={() => setIsAddBackground(!isAddBackground)}
                                                                    addBackground={addBackground}/>
                                                </Suspense>
                                                <ModalBackgroundGenerate
                                                    isAddBackgroundGenerate={isAddBackgroundGenerate}
                                                    toggleIsAddBackgroundGenerate={() => setIsAddBackgroundGenerate(!isAddBackgroundGenerate)}
                                                    categoryBackgrounds={categoryBackgrounds}
                                                    addBackground={addBackgroundBase}/>
                                                    <Suspense fallback={<div>loading...</div>}>
                                                <ModalBackgroundUpdate isUpdateBackground={isUpdateBackground}
                                                                       toggleIsUpdateBackground={() => setIsUpdateBackground(!isUpdateBackground)}
                                                                       updateBackground={updateBackground}
                                                                       selectBackground={selectBackground}
                                                                       categoryBackgrounds={categoryBackgrounds}/>
                                                    </Suspense>
                                                        <Suspense fallback={<div>loading...</div>}>
                                                <ModalBackgroundCategoryAdd
                                                    isAddBackgroundCategory={isAddCategoryBackground}
                                                    toggleIsAddBackgroundCategory={() => setIsAddCategoryBackground(!isAddCategoryBackground)}
                                                    addBackgroundCategory={addCategoryBackground}/>
                                                        </Suspense>
                                                <Suspense fallback={<div>loading...</div>}>
                                                    <ModalBackgroundCategoryDelete
                                                        isDeleteBackgroundCategory={isDeleteCategoryBackground}
                                                        toggleIsDeleteBackgroundCategory={() => setIsDeleteCategoryBackground(!isDeleteCategoryBackground)}
                                                        DeleteCategoryBackground={DeleteCategoryBackground} categoryBackgrounds={categoryBackgrounds}/>
                                                </Suspense>
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item border-0 border-bottom">
                                        <h2 className="accordion-header" id="genques-headingTwo">
                                            <button
                                                className={classNames(
                                                    'accordion-button fw-medium bg-white text-black',
                                                    {
                                                        collapsed: activeTabUploadTexture !== '4'
                                                    }
                                                )}
                                                type="button"
                                                onClick={() => toggleActiveTabUploadTexture('4')}
                                                style={{cursor: "pointer"}}
                                            >
                                                Музыка заднего фона
                                            </button>
                                        </h2>
                                        <Collapse isOpen={activeTabUploadTexture === '4'}
                                                  className="accordion-collapse">
                                            <div className="accordion-body p-0 text-black fw-normal py-2 overflow-auto"
                                                 style={{maxHeight: '400px'}}>
                                                {
                                                    error.show &&
                                                    <Alert color="danger">{error.message}</Alert>
                                                }
                                                <div className="d-flex gap-3 mb-3">
                                                    <Button color="light" title="Добавить звук заднего фона"
                                                            onClick={() => setIsAddBackgroundSound(true)}>
                                                        <i className="bx bx-plus-circle fs-3"></i>
                                                    </Button>
                                                    <Button color="light" title="Добавить категорию"
                                                            onClick={() => setIsAddCategoryBackgroundSound(true)}>
                                                        <i className="bx bx-category-alt fs-3"></i>
                                                    </Button>
                                                    <Button color="light" title="Удалить категорию"
                                                            onClick={() => setIsDeleteCategoryBackgroundSound(true)}>
                                                        <i className="bx bx-x fs-3"></i>
                                                    </Button>
                                                </div>
                                                <Suspense fallback={<div>loading...</div>}>
                                                <BackgroundSoundList sounds={backgroundSounds}
                                                                     removeSound={removeBackgroundSound}
                                                                     onChangeSelectBackgroundSound={(sound) => {
                                                                         setSelectBackgroundSound(sound)
                                                                         setIsUpdateBackgroundSound(true)
                                                                     }}/>
                                                </Suspense>
                                                    <Suspense fallback={<div>loading...</div>}>
                                                <ModalBackgroundSoundCategoryAdd
                                                    isAddBackgroundSoundCategory={isAddCategoryBackgroundSound}
                                                    toggleIsAddBackgroundSoundCategory={() => setIsAddCategoryBackgroundSound(!isAddCategoryBackgroundSound)}
                                                    addBackgroundSoundCategory={addBackgroundSoundCategory}/>
                                                    </Suspense>
                                                <Suspense fallback={<div>loading...</div>}>
                                                    <ModalBackgroundSoundCategoryDelete
                                                        isDeleteBackgroundSoundCategory={isDeleteCategoryBackgroundSound}
                                                        toggleIsDeleteBackgroundSoundCategory={() => setIsDeleteCategoryBackgroundSound(!isDeleteCategoryBackgroundSound)}
                                                        DeleteBackgroundSoundCategory={DeleteBackgroundSoundCategory} categoryBackgroundSounds={categoryBackgroundSounds}/>
                                                </Suspense>
                                                        <Suspense fallback={<div>loading...</div>}>
                                                <ModalBackgroundSoundAdd
                                                    categoryBackgroundSounds={categoryBackgroundSounds}
                                                    isAddSound={isAddBackgroundSound}
                                                    toggleIsAddSound={() => setIsAddBackgroundSound(!isAddBackgroundSound)}
                                                    addSound={addBackgroundSound}/>
                                                        </Suspense>
                                                            <Suspense fallback={<div>loading...</div>}>
                                                <ModalBackgroundSoundUpdate
                                                    isUpdateBackgroundSound={isUpdateBackgroundSound}
                                                    toggleIsUpdateBackgroundSound={() => setIsUpdateBackgroundSound(!isUpdateBackgroundSound)}
                                                    updateBackgroundSound={updateBackgroundSound}
                                                    selectBackgroundSound={selectBackgroundSound}
                                                    categoryBackgroundSounds={categoryBackgroundSounds}/>
                                                            </Suspense>
                                            </div>
                                        </Collapse>
                                    </div>
                                </div>
                                <div className={classNames({
                                    "mb-4": open === "8"
                                })}></div>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </OffcanvasBody>
        </Offcanvas>
    );
};

export default LeftMenuUpdate;
