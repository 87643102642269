import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Card, CardBody, CardFooter, Col, Row, Container} from "reactstrap";
import AxiosClient from "../../../api/AxiosClient";
import DataTable from "react-data-table-component";
import {setModalAdd, setShowNotification} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../hooks/redux";
import NotificationShow from "../../widgets/NotificationShow";
import ModalAddTypeEvents from "./ModalAddTypeEvents";

const ListTypeEvents = () => {
    const dispatch = useAppDispatch();

    const [search, setSearch] = useState('');
    const [dataFiltering, setDataFiltering] = useState<any[]>([]);
    const [typeEvents, setTypeEvents] = useState<any[]>([]);
    const [updateData, setUpdateData] = useState<any>(null);

    useEffect(() => {
        fetchTypeEvents();
    }, []);

    const fetchTypeEvents = () => {
        AxiosClient.post('/event-types')
            .then(r => {
                setDataFiltering(r.data);
                setTypeEvents(r.data);
            })
            .catch(error => {
                console.log('Ошибка загрузки типов мероприятий:', error);
            });
    };

    useEffect(() => {
        setDataFiltering(filtering());
    }, [search, typeEvents]);

    const filtering = () => {
        let data = typeEvents;

        if (search) {
            data = data.filter((e: any) => e.name.toLowerCase().includes(search.toLowerCase()));
        }

        return data;
    };

    const removeTypeEvent = (id: any) => {
        AxiosClient.post(`/event-types/archive/${id}`)
            .then((r) => {
                fetchTypeEvents();
                dispatch(setShowNotification({ type: "success", message: `Готово, тип мероприятия в архиве`, show: true }));
            })
            .catch((error) => {
                console.log(error);
                dispatch(setShowNotification({ type: "error", message: `Ошибка, не удалось добавить в архив`, show: true }));
            });
    };

    const columns: any = [
        {
            id: "name",
            name: <span className='font-weight-bold accessibility_size_table'>Тип мероприятия</span>,
            selector: (row: any) => row.name,
            cell: (row: any) => <span className='accessibility_size_table'>{row.name}</span>,
            wrap: true,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'></span>,
            selector: (row: any) => <i className="bx bx-archive-in fs-24 cursor-pointer" onClick={() => { removeTypeEvent(row.id) }}></i>,
            width: '60px'
        }
    ];

    return (
        <>
            <Helmet>
                <title>Типы мероприятий</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col xxl={3} xl={3} lg={6}>
                        <label className="w-100">Поиск по названию:
                            <input
                                type="text"
                                className='form-control'
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="mt-2">
                            <CardBody>
                                <DataTable
                                    columns={columns}
                                    data={dataFiltering}
                                    noDataComponent={'Нет данных'}
                                    pagination
                                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                    paginationComponentOptions={{
                                        rowsPerPageText: 'Видно типов мероприятий:',
                                        rangeSeparatorText: 'из'
                                    }}
                                    highlightOnHover
                                    customStyles={{
                                        rows: {
                                            highlightOnHoverStyle: {
                                                backgroundColor: "#ffebb5"
                                            },
                                        },
                                        expanderRow: {
                                            style: {
                                                backgroundColor: "#ffebb5",
                                                paddingTop: '5px',
                                                paddingBottom: '5px'
                                            },
                                        },
                                    }}
                                    onRowClicked={(row) => {
                                        if (window.innerWidth <= 768) {
                                            setUpdateData(row);
                                            dispatch(setModalAdd(true));
                                        }
                                    }}
                                    onRowDoubleClicked={(row) => {
                                        if (window.innerWidth > 768) {
                                            setUpdateData(row);
                                            dispatch(setModalAdd(true));
                                        }
                                    }}
                                />
                            </CardBody>
                            <CardFooter>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        setUpdateData(null);
                                        dispatch(setModalAdd(true));
                                    }}
                                >
                                    Добавить
                                </button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
                <ModalAddTypeEvents
                    updateData={updateData}
                    onSuccess={fetchTypeEvents}
                />
                <NotificationShow />
            </Container>
        </>
    );
};

export default ListTypeEvents;