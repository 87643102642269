import React, {FC, useEffect} from 'react';
import DataTable from "react-data-table-component";
import {CardBody} from "reactstrap";
import {IDetail} from "../../../models/parent/ICashAccounting";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getCashAccounting} from "../../../store/reducers/parent/ActionCreator";

interface ITableInfoCashAccountingBalance {
    date: Date[]
}
const TableCashAccounting: FC<ITableInfoCashAccountingBalance> = ({date}) => {

    const dispatch = useAppDispatch();
    const {cashAccounting, child} = useAppSelector(state => state.ParentReducer)
    useEffect(() => {
        child &&
        dispatch(getCashAccounting(date, child)).then()
    }, [date, child, dispatch]);

    const columns: any = [
        {
            name: <span className='font-weight-bold accessibility_size_table'>Дата</span>,
            selector: (row: IDetail) => row.date,
            cell: (row: IDetail) => <span className={"accessibility_size_table"}>{row.date}</span>,
            sortable: true,
            style: {
                minWidth: '110px'
            },
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Поступления</span>,
            selector: (row: IDetail) => {
                if (!row.income){
                    return <span className="accessibility_size_table">&mdash;</span>
                }
                return <span className="accessibility_size_table">{row.income}</span>
            },
            center: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Образовательные услуги</span>,
            selector: (row: IDetail) => {
                if (!row.services){
                    return <span className="accessibility_size_table">&mdash;</span>
                }
                return <span className="accessibility_size_table">{row.services}</span>
            },
            center: true,
            minWidth: '160px'
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Платные услуги</span>,
            selector: (row: IDetail) => {
                if (!row.paid){
                    return <span className="accessibility_size_table">&mdash;</span>
                }
                return <span className="accessibility_size_table">{row.paid}</span>
            },
            center: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Услуги за питание</span>,
            selector: (row: IDetail) => {
                if (!row.food){
                    return <span className="accessibility_size_table">&mdash;</span>
                }
                return <span className="accessibility_size_table">{row.food}</span>
            },
            center: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Услуга</span>,
            selector: (row: IDetail) => {
                if (!row.service){
                    return <span className="accessibility_size_table">&mdash;</span>
                }
                return <span className="accessibility_size_table">{row.service}</span>
            },
            center: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Итого расход</span>,
            selector: (row: IDetail) => {
                if (!row.outgo){
                    return <span className="accessibility_size_table">&mdash;</span>
                }
                return <span className="accessibility_size_table">{row.outgo}</span>
            },
            center: true
        },
    ];


    return (
        <>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={cashAccounting[0]?.detail}
                    noDataComponent={'Нет данных'}
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </>

    );
};

export default TableCashAccounting;
