import {IGame} from "../../models/game/IGame";
import {TypeSettingsShot} from "../../models/game/typesSettings/TypeSettingsShot";
import {ISettingsAnswerShot, ISettingsCountTextures, ISettingsSearch} from "../../models/game/IShot";
import {TypeSettings} from "../../models/game/typesSettings/TypeSettings";
import {ISettingsCollect, ISettingsSelectAnswer} from "../../models/game/ISettingsTexture";

export const validateGame = (game: Omit<IGame,'id'>): string => { //проверка настроек игры
    let error = ''
    if (!game.name) {
        error =  'Введите имя игры'
    }
    if (game.name.length > 30) {
        error =  'Название игры привышает 30 символов'
    }

    game.shots.forEach((shot) => {
        const settings = shot.settings
        if (!shot.name) {
            error = 'У одного из кадров нет имени'
            return
        }
        if (!shot.backgroundSound && !shot.settingsAutoPlay.play){
            error = `Добавьте музыку заднего фона в кадр "${shot.name}"`
            return
        }
        if (!shot.backgroundImage){
            error = `Добавьте задний фон в кадр "${shot.name}"`
            return
        }
        if (settings) {
            /*if (!shot.NextShot && settings.type !== TypeSettingsShot.NO_SETTING)
            {
                error = `У кадра "${shot.name}" не выбран кадр для перехода`
                return
            }*/
            if(settings.type === TypeSettingsShot.BATTLE) {
                if (!game.is_rpg) {
                    error = `Настройка кадра "${shot.name}" - "Битва", выберите в игровых настройках "Режим РПГ"`
                    return
                }
                const textureBattle = shot.textures.filter(e => e.pivot.settings.type === TypeSettings.BATTLE)
                console.log(textureBattle)
                if(textureBattle.length === 0){
                    error = `В кадре "${shot.name}" нет элемента с настройкой "Сражение"`
                    return
                }
            }
            if (settings.type === TypeSettingsShot.COUNT_TEXTURE) {
                const settingsCountTexture = settings.settings as ISettingsCountTextures
                if (!settingsCountTexture.textTrue) {
                    error = `Добавьте текст верного ответа в кадре "${shot.name}" для настроек "Посчитать кол-во текстур"`
                    return
                }
                if (!settingsCountTexture.textFalse) {
                    error = `Добавьте текст не верного ответа в кадре "${shot.name}" для настроек "Посчитать кол-во текстур"`
                    return
                }
                if (!settingsCountTexture.answer) {
                    error = `Добавьте ответ в кадре "${shot.name}" для настроек "Посчитать кол-во текстур"`
                    return
                }
            }
            if (settings.type === TypeSettingsShot.SEARCH) {
                const settingsSearch = settings.settings as ISettingsSearch
                if (!settingsSearch.textTrue) {
                    error = `Добавьте текст верного ответа в кадре "${shot.name}" для настроек "Найти одинаковые картинки"`
                    return
                }
                if (!settingsSearch.textFalse) {
                    error = `Добавьте текст не верного ответа в кадре "${shot.name}" для настроек ""Найти одинаковые картинки"`
                    return
                }
            }
            if (settings.type === TypeSettingsShot.ANSWER) {
                const settingsAnswerTexture = settings.settings as ISettingsAnswerShot
                if (!settingsAnswerTexture.text) {
                    error = `Добавьте текст верного ответа в кадре "${shot.name}" для настроек "Решить пример"`
                    return
                }
                if (!settingsAnswerTexture.textFalse) {
                    error = `Добавьте текст не верного ответа в кадре "${shot.name}" для настроек "Решить пример"`
                    return
                }
                if (!settingsAnswerTexture.htmlVid) {
                    error = `Примените изменения в кадре "${shot.name}" для их отображения`
                    return
                }
                if (!settingsAnswerTexture.html) {
                    error = `Добавьте примеры в кадр "${shot.name}" для настроек "Решить пример"`
                    return
                }
            }
        }

        shot.textures.forEach((texture) => {
            if (!texture.pivot.name) {
                error = `У одной из текстур в кадре "${shot.name}" нет названия. Обозначение текстуры "${texture.name}"`
                return
            }
            if (!texture.pivot.description) {
                error = `У одной из текстур в кадре "${shot.name}" нет описания. Обозначение текстуры "${texture.name}"`
                return
            }
            const settings = texture.pivot.settings
            if (settings.type === TypeSettings.SELECT_ANSWER) {
                const settingsSelectAnswer = settings.settings as ISettingsSelectAnswer
                if (!settingsSelectAnswer.text) {
                    error = `У одной из текстур в кадре "${shot.name}" нет текста в настройках "Выбор ответа"`
                    return
                }
            }
            if (settings.type === TypeSettings.COLLECT) {
                const settingsCollect = settings.settings as ISettingsCollect
                if (!settingsCollect.textTrue) {
                    error = `У одной из текстур в кадре "${shot.name}" нет верного текста перемещения в настройках "Собрать в кучку"`
                    return
                }
                if (!settingsCollect.textFalse) {
                    error = `У одной из текстур в кадре "${shot.name}" нет не верного текста перемещения в настройках "Собрать в кучку"`
                    return
                }
            }
        })

        const search = shot.textures.find((texture) => {
            const settings = texture.pivot.settings
            return settings.type === TypeSettings.SEARCH
        })

        if (search) {
            if (!shot.settings){
                error = `Добавьте настройки "Найти одинаковые картинки" в кадр "${shot.name}"`
                return
            }

            if (shot.settings.type !== TypeSettingsShot.SEARCH){
                error = `Добавьте настройки "Найти одинаковые картинки" в кадр "${shot.name}"`
                return
            }
        }
    })

    return error
}