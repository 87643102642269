import React, {FC, useState} from 'react';
import {Col, Row} from "reactstrap";
import DataTable from "react-data-table-component";
import AddDishInMenu from "./AddDishInMenu";
import UpdateDishInMenu from "./UpdateDishInMenu";
interface IListMenu {
    listDishMenu: any[],
    setListDishMenu: (value: any[]) => void
}
const ListDishMenu: FC<IListMenu> = ({setListDishMenu, listDishMenu}) => {

    const [addDish, setAddDish] = useState(false);
    const [updateDish, setUpdateDish] = useState(false);
    const [selectDish, setSelectDish] = useState<any | null>(null);
    const columns = [
        {
            id: "date",
            name: <span className='font-weight-bold accessibility_size_table'>Наименование</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.name}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Выход блюда</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.out_dish}</span>,
            hide: 959,
            width: '140px',
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Кол-во</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.count}</span>,
            hide: 599,
            width: '90px'
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Цена</span>,
            cell: (row: any) => <span className="accessibility_size_table">{Number(row.price).toFixed(2)}</span>,
            width: '90px'
        },
    ];
    return (
        <Row>
            <Col>
                <DataTable
                    columns={columns}
                    data={listDishMenu}
                    noDataComponent={'Нет данных'}
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно меню:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            },
                        },
                        expanderRow: {
                            style: {
                                backgroundColor: "#ffebb5",
                                paddingTop: '5px',
                                paddingBottom: '5px'
                            },
                        },
                    }}
                    onRowClicked={(row) => {
                        setSelectDish(row)
                        setUpdateDish(true)
                    }}
                />
                <button type="button" className="btn btn-primary mt-1" id="addNewProject" onClick={() => setAddDish(true)}>Добавить блюдо</button>
            </Col>
            {
                updateDish &&
                <UpdateDishInMenu selectDish={selectDish} listDishMenu={listDishMenu} setListDishMenu={value => setListDishMenu(value)} updateDish={updateDish} setUpdateDish={value => setUpdateDish(value)}/>

            }
            {
                addDish &&
                <AddDishInMenu listDishMenu={listDishMenu} setListDishMenu={value => setListDishMenu(value)} addDish={addDish} setAddDish={value => setAddDish(value)}/>
            }
        </Row>
    );
};

export default ListDishMenu;
