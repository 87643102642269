import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getAllInfoGroup} from "../../../store/reducers/widgets/ActionCreator";
import {Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import {setDateAdd, setGroupModalAdd, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import moment from "moment/moment";
import ModalAddReplacementBedLinen from "./ModalAddReplacementBedLinen";


const WidgetTableReplacementBedLinen = () => {
    const dispatch = useAppDispatch();

    const { date, journalHaccpReplacementBedLinen} = useAppSelector(state => state.WidgetReducer)
    const [updateData, setUpdateData] =  useState<any>();

    useEffect(() => {
        dispatch(getAllInfoGroup(date)).then()
    }, [date, dispatch]);


    const columns: any = [
        {
            id: "date",
            name: <span className='font-weight-bold accessibility_size_table mb-0'>Дата</span>,
            selector: (row: any) => moment(row.data, "DD.MM.YYYY H:mm:ss").format("DD.MM.YYYY"),
            cell: (row: any) =><span className='accessibility_size_table'>{moment(row.data, "DD.MM.YYYY H:mm:ss").format("DD.MM.YYYY")}</span>,
            sortable: true,
            center: true,
            width: '120px'
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Группа</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.group}</span>,
            center: true,
            compact: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Процедура</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.base}</span>,
            center: true,
            compact: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Ответственный</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.inspector_name}</span>,
            center: true,
            compact: true,
        },
    ];


    return (
        <>
            <Card>
                <CardHeader>
                    Журнал замены постельного белья
                </CardHeader>
                <CardBody className="py-0">
                    <DataTable
                        columns={columns}
                        data={journalHaccpReplacementBedLinen ? journalHaccpReplacementBedLinen : []}
                        pagination
                        paginationRowsPerPageOptions={[5, 10, 20, 30]}
                        paginationComponentOptions={
                            {
                                rowsPerPageText: 'Видно журналов:',
                                rangeSeparatorText: 'из'
                            }
                        }
                        noDataComponent={'Нет замеров'}
                        defaultSortFieldId={"date"}
                        defaultSortAsc={false}
                        highlightOnHover
                        pointerOnHover
                        customStyles={{
                            rows: {
                                highlightOnHoverStyle: {
                                    backgroundColor: "#ffebb5"
                                }
                            }
                        }}
                        onRowClicked={
                            (row: any) => {
                                setUpdateData(row);
                                if(window.innerWidth <= 768){
                                    dispatch(setGroupModalAdd(row.group_id));
                                    dispatch(setModalAdd(true));
                                    dispatch(setDateAdd(moment(row.data, "DD.MM.YYYY").toDate()));
                                }
                            }
                        }
                        onRowDoubleClicked={
                            (row: any) => {
                                if(window.innerWidth > 768){
                                    setUpdateData(row);
                                    dispatch(setGroupModalAdd(row.group_id));
                                    dispatch(setModalAdd(true));
                                    dispatch(setDateAdd(moment(row.data, "DD.MM.YYYY").toDate()));
                                }
                            }
                        }
                    />
                </CardBody>
                <CardFooter className="d-flex justify-content-between">
                    <div className='d-flex'>
                        <div className="button_modal mx-1">
                            <button className="btn btn-primary w-100"
                                    onClick={() => {
                                        setUpdateData(null);
                                        dispatch(setModalAdd(true))
                                    }}>Добавить
                            </button>
                        </div>
                    </div>
                </CardFooter>
            </Card>
            <ModalAddReplacementBedLinen updateData={updateData} />
        </>
    );
};

export default WidgetTableReplacementBedLinen;
