import React, {FC, useEffect, useState} from 'react';
import {Col, Input, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import AxiosClient from "../../../api/AxiosClient";
import _ from "lodash";
import ModalConfirmExit from "../Schools/ModalConfirmExit";
import {useAppDispatch} from "../../../hooks/redux";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";

interface IModalUpdateRequestForNutrition {
    setUpdateRequestForNutrition: (value: boolean) => void
    updateRequestForNutrition: boolean
    selectRequestForNutrition: any
    setSelectRequestForNutrition: (value: any) => void
    updateData: () => void
}
const ModalUpdateRequestForNutrition: FC<IModalUpdateRequestForNutrition> = ({setSelectRequestForNutrition, setUpdateRequestForNutrition, updateRequestForNutrition, selectRequestForNutrition, updateData}) => {
    const idButtonSave: string = 'buttonSaveModalUpdateRequestForNutrition';
    const [listMealtime, setListMealtime] = useState<any[]>([]);
    const [confirmExit, setConfirmExit] = useState(false);
    const [newObject, setNewObject] = useState<any>({ personal_account: '', personal_account_id: '', attendance: '', balance: '',
        benefits: '', catering_organizer_id: '', correction: '',  created_at: '', updated_at:'', credit: '', date: '',
        order_day: '', premium: '', sum: '', tabel: '', breakfast: '', hot_breakfast: '', dinner: '', snack: '', lunch: '', sleeps: '',});
    useEffect(() => {
        AxiosClient.get('/mealtimes')
            .then(r => setListMealtime(r.data))
            .catch()
    }, []);

    useEffect(() => {
       setNewObject(selectRequestForNutrition)
    }, [selectRequestForNutrition]);

    const orderDay = () => {
        const mealtime: any[] = selectRequestForNutrition?.personal_account?.catering_organizer_type_planned_menu.map((el: any) => el.mealtime.map((d: any) => ({...d, idx: el.id}))).flat() || [];
        let sum = 0;

        mealtime.length > 0 &&
            mealtime.forEach((m) => {
                if (m.slug === 'breakfast' && !!selectRequestForNutrition?.breakfast){
                    sum += +m.price
                }
                if (m.slug === 'hot_breakfast' && !!selectRequestForNutrition?.hot_breakfast){
                    sum += +m.price
                }
                if (m.slug === 'dinner' && !!selectRequestForNutrition?.dinner){
                    sum += +m.price
                }
                if (m.slug === 'snack' && !!selectRequestForNutrition?.snack){
                    sum += +m.price
                }
                if (m.slug === 'lunch' && !!selectRequestForNutrition?.lunch){
                    sum += +m.price
                }
                if (m.slug === 'sleeps' && !!selectRequestForNutrition?.sleeps){
                    sum += +m.price
                }
            })
        return sum
    }

    const orderDayDiscount = () => {
        const mealtime: any[] = selectRequestForNutrition?.personal_account?.catering_organizer_type_planned_menu.map((el: any) => el.mealtime.map((d: any) => ({...d, idx: el.id}))).flat() || [];
        let sum = 0;
        const isDiscountPrice = selectRequestForNutrition?.personal_account?.catering_organizer_type_planned_menu.find((el: any) => el?.type_planned_menu?.benefits?.length > 0)

        mealtime.length > 0 &&
        mealtime.forEach((m) => {
            if (m.slug === 'breakfast' && !!selectRequestForNutrition?.breakfast){
                sum += +m.price
            }
            if (m.slug === 'hot_breakfast' && !!selectRequestForNutrition?.hot_breakfast){
                sum += +m.price
            }
            if (m.slug === 'dinner' && !!selectRequestForNutrition?.dinner){
                sum += +m.price
            }
            if (m.slug === 'snack' && !!selectRequestForNutrition?.snack){
                sum += +m.price
            }
            if (m.slug === 'lunch' && !!selectRequestForNutrition?.lunch){
                sum += +m.price
            }
            if (m.slug === 'sleeps' && !!selectRequestForNutrition?.sleeps){
                sum += +m.price
            }
        })
        if(isDiscountPrice){
            sum -= +selectRequestForNutrition.discount_price
        }

        if (sum < 0) return 0;
        return sum
    }

    const orderDayBenefit = () => {
        const benefits: any[] = selectRequestForNutrition?.personal_account?.benefits
        const menus: any[] = selectRequestForNutrition?.personal_account?.catering_organizer_type_planned_menu;
        const isDiscountPrice = selectRequestForNutrition?.personal_account?.catering_organizer_type_planned_menu.find((el: any) => el?.type_planned_menu?.benefits?.length > 0)
        let count = 0;
        benefits?.forEach((b: any) => {
            menus?.forEach((el: any) => {
                let all = false;
                let isBenefit = false;
                let sum = 0;

                el?.type_planned_menu?.benefits?.forEach((ben: any) => {
                    if (ben.id === b.id){
                        isBenefit = true
                    }
                })

                el?.mealtime?.forEach((m: any) => {
                    if (m.slug === 'breakfast' && !!selectRequestForNutrition?.breakfast){
                        all = true
                        sum += +m.price
                    }
                    if (m.slug === 'hot_breakfast' && !!selectRequestForNutrition?.hot_breakfast){
                        all = true
                        sum += +m.price
                    }
                    if (m.slug === 'dinner' && !!selectRequestForNutrition?.dinner){
                        all = true
                        sum += +m.price
                    }
                    if (m.slug === 'snack' && !!selectRequestForNutrition?.snack){
                        all = true
                        sum += +m.price
                    }
                    if (m.slug === 'lunch' && !!selectRequestForNutrition?.lunch){
                        all = true
                        sum += +m.price
                    }
                    if (m.slug === 'sleeps' && !!selectRequestForNutrition?.sleeps){
                        all = true
                        sum += +m.price
                    }
                })
                if(all && isBenefit){
                    count += sum - +b.pivot.sum > 0 ? sum - +b.pivot.sum : 0
                } else {
                    count += sum
                }
            })
        })
        benefits.length === 0 &&
        menus?.forEach((el: any) => {
            let all = false;
            let sum = 0;

            el?.mealtime?.forEach((m: any) => {
                if (m.slug === 'breakfast' && !!selectRequestForNutrition?.breakfast){
                    all = true
                    sum += +m.price
                }
                if (m.slug === 'hot_breakfast' && !!selectRequestForNutrition?.hot_breakfast){
                    all = true
                    sum += +m.price
                }
                if (m.slug === 'dinner' && !!selectRequestForNutrition?.dinner){
                    all = true
                    sum += +m.price
                }
                if (m.slug === 'snack' && !!selectRequestForNutrition?.snack){
                    all = true
                    sum += +m.price
                }
                if (m.slug === 'lunch' && !!selectRequestForNutrition?.lunch){
                    all = true
                    sum += +m.price
                }
                if (m.slug === 'sleeps' && !!selectRequestForNutrition?.sleeps){
                    all = true
                    sum += +m.price
                }
            })
            if(all){
                count += sum
            }
        })
        if(isDiscountPrice){
            count -= +selectRequestForNutrition.discount_price
        }
        return count;
    }

    const benefits = () => {
        const benefits: any[] = selectRequestForNutrition?.personal_account?.benefits
        const menus: any[] = selectRequestForNutrition?.personal_account?.catering_organizer_type_planned_menu;
        const isDiscountPrice = selectRequestForNutrition?.personal_account?.catering_organizer_type_planned_menu.find((el: any) => el?.type_planned_menu?.benefits?.length > 0)
        let count = 0;
        benefits?.forEach((b: any) => {
            menus?.forEach((el: any) => {
                let all = false;
                let isBenefit = false;

                el?.type_planned_menu?.benefits?.forEach((ben: any) => {
                    if (ben.id === b.id){
                        isBenefit = true
                    }
                })

                el?.mealtime?.forEach((m: any) => {
                    if (m.slug === 'breakfast' && !!selectRequestForNutrition?.breakfast){
                        all = true
                    }
                    if (m.slug === 'hot_breakfast' && !!selectRequestForNutrition?.hot_breakfast){
                        all = true
                    }
                    if (m.slug === 'dinner' && !!selectRequestForNutrition?.dinner){
                        all = true
                    }
                    if (m.slug === 'snack' && !!selectRequestForNutrition?.snack){
                        all = true
                    }
                    if (m.slug === 'lunch' && !!selectRequestForNutrition?.lunch){
                        all = true
                    }
                    if (m.slug === 'sleeps' && !!selectRequestForNutrition?.sleeps){
                        all = true
                    }
                })

                if(all && isBenefit){
                    count += +b.pivot.sum
                }
            })
        })
        if(isDiscountPrice){
            count -= +selectRequestForNutrition.discount_price
        }
        if (count < 0) return 0
        return count;
    }
    const check = (name: string, check: boolean) => {
        const mealtime: any[] = selectRequestForNutrition?.personal_account?.catering_organizer_type_planned_menu.map((el: any) => el.mealtime.map((d: any) => ({...d, idx: el.id}))).flat() || [];
        const idx = mealtime.find((m: any) => m.slug === name)?.idx
        switch (name) {
            case 'breakfast': {
                if (mealtime.find((m: any) => m.slug === name)){
                    if (!check) {
                        setSelectRequestForNutrition({
                            ...selectRequestForNutrition,
                            breakfast: 0,
                            hot_breakfast: mealtime.find((m: any) => m.slug === 'hot_breakfast' && m.idx === idx) ? 0 : selectRequestForNutrition.hot_breakfast,
                            dinner: mealtime.find((m: any) => m.slug === 'dinner' && m.idx === idx) ? 0 : selectRequestForNutrition.dinner,
                            snack: mealtime.find((m: any) => m.slug === 'snack' && m.idx === idx) ? 0 : selectRequestForNutrition.snack,
                            lunch: mealtime.find((m: any) => m.slug === 'lunch' && m.idx === idx) ? 0 : selectRequestForNutrition.lunch,
                            sleeps: mealtime.find((m: any) => m.slug === 'sleeps' && m.idx === idx) ? 0 : selectRequestForNutrition.sleeps,
                            order_day: +selectRequestForNutrition?.order_day - +mealtime.find((m: any) => m.name === name)?.price
                    })
                    } else {
                        setSelectRequestForNutrition({
                            ...selectRequestForNutrition,
                            breakfast: 1,
                            hot_breakfast: mealtime.find((m: any) => m.slug === 'hot_breakfast' && m.idx === idx) ? 1 : selectRequestForNutrition.hot_breakfast,
                            dinner: mealtime.find((m: any) => m.slug === 'dinner' && m.idx === idx) ? 1 : selectRequestForNutrition.dinner,
                            snack: mealtime.find((m: any) => m.slug === 'snack' && m.idx === idx) ? 1 : selectRequestForNutrition.snack,
                            lunch: mealtime.find((m: any) => m.slug === 'lunch' && m.idx === idx) ? 1 : selectRequestForNutrition.lunch,
                            sleeps: mealtime.find((m: any) => m.slug === 'sleeps' && m.idx === idx) ? 1 : selectRequestForNutrition.sleeps,
                            order_day: +selectRequestForNutrition.order_day + +mealtime.find((m: any) => m.name === name)?.price
                        })
                    }
                }
                break;
            }
            case 'hot_breakfast': {
                if (mealtime.find((m: any) => m.slug === name)){
                    if (!check) {
                        setSelectRequestForNutrition({
                            ...selectRequestForNutrition,
                            breakfast: mealtime.find((m: any) => m.slug === 'breakfast' && m.idx === idx) ? 0 : selectRequestForNutrition.breakfast,
                            hot_breakfast: 0,
                            dinner: mealtime.find((m: any) => m.slug === 'dinner' && m.idx === idx) ? 0 : selectRequestForNutrition.dinner,
                            snack: mealtime.find((m: any) => m.slug === 'snack' && m.idx === idx) ? 0 : selectRequestForNutrition.snack,
                            lunch: mealtime.find((m: any) => m.slug === 'lunch' && m.idx === idx) ? 0 : selectRequestForNutrition.lunch,
                            sleeps: mealtime.find((m: any) => m.slug === 'sleeps' && m.idx === idx) ? 0 : selectRequestForNutrition.sleeps,
                            order_day: +selectRequestForNutrition.order_day - +mealtime.find((m: any) => m.name === name)?.price
                        })
                    } else {
                        setSelectRequestForNutrition({
                            ...selectRequestForNutrition,
                            breakfast: mealtime.find((m: any) => m.slug === 'breakfast' && m.idx === idx) ? 1 : selectRequestForNutrition.breakfast,
                            hot_breakfast: 1,
                            dinner: mealtime.find((m: any) => m.slug === 'dinner' && m.idx === idx) ? 1 : selectRequestForNutrition.dinner,
                            snack: mealtime.find((m: any) => m.slug === 'snack' && m.idx === idx) ? 1 : selectRequestForNutrition.snack,
                            lunch: mealtime.find((m: any) => m.slug === 'lunch' && m.idx === idx) ? 1 : selectRequestForNutrition.lunch,
                            sleeps: mealtime.find((m: any) => m.slug === 'sleeps' && m.idx === idx) ? 1 : selectRequestForNutrition.sleeps,
                            order_day: +selectRequestForNutrition.order_day + +mealtime.find((m: any) => m.name === name)?.price
                        })
                    }
                }
                break;
            }
            case 'dinner': {
                if (mealtime.find((m: any) => m.slug === name)){
                    if (!check) {
                        setSelectRequestForNutrition({
                            ...selectRequestForNutrition,
                            breakfast: mealtime.find((m: any) => m.slug === 'breakfast' && m.idx === idx) ? 0 : selectRequestForNutrition.breakfast,
                            hot_breakfast: mealtime.find((m: any) => m.slug === 'hot_breakfast' && m.idx === idx) ? 0 : selectRequestForNutrition.hot_breakfast,
                            dinner: 0,
                            snack: mealtime.find((m: any) => m.slug === 'snack' && m.idx === idx) ? 0 : selectRequestForNutrition.snack,
                            lunch: mealtime.find((m: any) => m.slug === 'lunch' && m.idx === idx) ? 0 : selectRequestForNutrition.lunch,
                            sleeps: mealtime.find((m: any) => m.slug === 'sleeps' && m.idx === idx) ? 0 : selectRequestForNutrition.sleeps,
                            order_day: +selectRequestForNutrition.order_day - +mealtime.find((m: any) => m.name === name)?.price
                        })
                    } else {
                        setSelectRequestForNutrition({
                            ...selectRequestForNutrition,
                            breakfast: mealtime.find((m: any) => m.slug === 'breakfast' && m.idx === idx) ? 1 : selectRequestForNutrition.breakfast,
                            hot_breakfast: mealtime.find((m: any) => m.slug === 'hot_breakfast' && m.idx === idx) ? 1 : selectRequestForNutrition.hot_breakfast,
                            dinner: 1,
                            snack: mealtime.find((m: any) => m.slug === 'snack' && m.idx === idx) ? 1 : selectRequestForNutrition.snack,
                            lunch: mealtime.find((m: any) => m.slug === 'lunch' && m.idx === idx) ? 1 : selectRequestForNutrition.lunch,
                            sleeps: mealtime.find((m: any) => m.slug === 'sleeps' && m.idx === idx) ? 1 : selectRequestForNutrition.sleeps,
                            order_day: +selectRequestForNutrition.order_day + +mealtime.find((m: any) => m.name === name)?.price
                        })
                    }
                }
                break;
            }
            case 'snack': {
                if (mealtime.find((m: any) => m.slug === name)){
                    if (!check) {
                        setSelectRequestForNutrition({
                            ...selectRequestForNutrition,
                            breakfast: mealtime.find((m: any) => m.slug === 'breakfast' && m.idx === idx) ? 0 : selectRequestForNutrition.breakfast,
                            hot_breakfast: mealtime.find((m: any) => m.slug === 'hot_breakfast' && m.idx === idx) ? 0 : selectRequestForNutrition.hot_breakfast,
                            dinner: mealtime.find((m: any) => m.slug === 'dinner' && m.idx === idx) ? 0 : selectRequestForNutrition.dinner,
                            snack: 0,
                            lunch: mealtime.find((m: any) => m.slug === 'lunch' && m.idx === idx) ? 0 : selectRequestForNutrition.lunch,
                            sleeps: mealtime.find((m: any) => m.slug === 'sleeps' && m.idx === idx) ? 0 : selectRequestForNutrition.sleeps,
                            order_day: +selectRequestForNutrition.order_day - +mealtime.find((m: any) => m.name === name)?.price
                        })
                    } else {
                        setSelectRequestForNutrition({
                            ...selectRequestForNutrition,
                            breakfast: mealtime.find((m: any) => m.slug === 'breakfast' && m.idx === idx) ? 1 : selectRequestForNutrition.breakfast,
                            hot_breakfast: mealtime.find((m: any) => m.slug === 'hot_breakfast' && m.idx === idx) ? 1 : selectRequestForNutrition.hot_breakfast,
                            dinner: mealtime.find((m: any) => m.slug === 'dinner' && m.idx === idx) ? 1 : selectRequestForNutrition.dinner,
                            snack: 1,
                            lunch: mealtime.find((m: any) => m.slug === 'lunch' && m.idx === idx) ? 1 : selectRequestForNutrition.lunch,
                            sleeps: mealtime.find((m: any) => m.slug === 'sleeps' && m.idx === idx) ? 1 : selectRequestForNutrition.sleeps,
                            order_day: +selectRequestForNutrition.order_day + +mealtime.find((m: any) => m.name === name)?.price
                        })
                    }
                }
                break;
            }
            case 'lunch': {
                if (mealtime.find((m: any) => m.slug === name)){
                    if (!check) {
                        setSelectRequestForNutrition({
                            ...selectRequestForNutrition,
                            breakfast: mealtime.find((m: any) => m.slug === 'breakfast' && m.idx === idx) ? 0 : selectRequestForNutrition.breakfast,
                            hot_breakfast: mealtime.find((m: any) => m.slug === 'hot_breakfast' && m.idx === idx) ? 0 : selectRequestForNutrition.hot_breakfast,
                            dinner: mealtime.find((m: any) => m.slug === 'dinner' && m.idx === idx) ? 0 : selectRequestForNutrition.dinner,
                            snack: mealtime.find((m: any) => m.slug === 'snack' && m.idx === idx) ? 0 : selectRequestForNutrition.snack,
                            lunch: 0,
                            sleeps: mealtime.find((m: any) => m.slug === 'sleeps' && m.idx === idx) ? 0 : selectRequestForNutrition.sleeps,
                            order_day: +selectRequestForNutrition.order_day - +mealtime.find((m: any) => m.name === name)?.price
                        })
                    } else {
                        setSelectRequestForNutrition({
                            ...selectRequestForNutrition,
                            breakfast: mealtime.find((m: any) => m.slug === 'breakfast' && m.idx === idx) ? 1 : selectRequestForNutrition.breakfast,
                            hot_breakfast: mealtime.find((m: any) => m.slug === 'hot_breakfast' && m.idx === idx) ? 1 : selectRequestForNutrition.hot_breakfast,
                            dinner: mealtime.find((m: any) => m.slug === 'dinner' && m.idx === idx) ? 1 : selectRequestForNutrition.dinner,
                            snack: mealtime.find((m: any) => m.slug === 'snack' && m.idx === idx) ? 1 : selectRequestForNutrition.snack,
                            lunch: 1,
                            sleeps: mealtime.find((m: any) => m.slug === 'sleeps' && m.idx === idx) ? 1 : selectRequestForNutrition.sleeps,
                            order_day: +selectRequestForNutrition.order_day + +mealtime.find((m: any) => m.name === name)?.price
                        })
                    }
                }
                break;
            }
            case 'sleeps': {
                if (mealtime.find((m: any) => m.slug === name)){
                    if (!check) {
                        setSelectRequestForNutrition({
                            ...selectRequestForNutrition,
                            breakfast: mealtime.find((m: any) => m.slug === 'breakfast' && m.idx === idx) ? 0 : selectRequestForNutrition.breakfast,
                            hot_breakfast: mealtime.find((m: any) => m.slug === 'hot_breakfast' && m.idx === idx) ? 0 : selectRequestForNutrition.hot_breakfast,
                            dinner: mealtime.find((m: any) => m.slug === 'dinner' && m.idx === idx) ? 0 : selectRequestForNutrition.dinner,
                            snack: mealtime.find((m: any) => m.slug === 'snack' && m.idx === idx) ? 0 : selectRequestForNutrition.snack,
                            lunch: mealtime.find((m: any) => m.slug === 'lunch' && m.idx === idx) ? 0 : selectRequestForNutrition.lunch,
                            sleeps: 0,
                            order_day: +selectRequestForNutrition.order_day - +mealtime.find((m: any) => m.name === name)?.price
                        })
                    } else {
                        setSelectRequestForNutrition({
                            ...selectRequestForNutrition,
                            breakfast: mealtime.find((m: any) => m.slug === 'breakfast' && m.idx === idx) ? 1 : selectRequestForNutrition.breakfast,
                            hot_breakfast: mealtime.find((m: any) => m.slug === 'hot_breakfast' && m.idx === idx) ? 1 : selectRequestForNutrition.hot_breakfast,
                            dinner: mealtime.find((m: any) => m.slug === 'dinner' && m.idx === idx) ? 1 : selectRequestForNutrition.dinner,
                            snack: mealtime.find((m: any) => m.slug === 'snack' && m.idx === idx) ? 1 : selectRequestForNutrition.snack,
                            lunch: mealtime.find((m: any) => m.slug === 'lunch' && m.idx === idx) ? 1 : selectRequestForNutrition.lunch,
                            sleeps: 1,
                            order_day: +selectRequestForNutrition.order_day + +mealtime.find((m: any) => m.name === name)?.price
                        })
                    }
                }
                break;
            }
        }
    }

    const attendance = () => {
        setSelectRequestForNutrition({
            ...selectRequestForNutrition,
            attendance: !(!!selectRequestForNutrition?.attendance),
            tabel: !(!!selectRequestForNutrition?.attendance) ? selectRequestForNutrition?.order_day : 0
        })
    }

    const confirmOrExit = () => {
        if(!_.isEqual(selectRequestForNutrition, newObject)){
            setConfirmExit(true);
        }else{
            setUpdateRequestForNutrition(false);
        }
    }
    const dispatch = useAppDispatch();
    useEscapeKey(() => dispatch(setModalAdd(false)), updateRequestForNutrition, "updateRequestForNutrition");


    return (
        <Modal id="updateRequestForNutrition" isOpen={updateRequestForNutrition}
               toggle={() => confirmOrExit()}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => confirmOrExit()}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Создание корр. заявки
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={{
                        personal_account: selectRequestForNutrition?.personal_account.personal_account,
                        personal_account_id: selectRequestForNutrition?.personal_account.id,
                        date: selectRequestForNutrition?.date,
                        personal_account_info: selectRequestForNutrition?.personal_account,
                        fio: `${selectRequestForNutrition?.personal_account.student.last_name} ${selectRequestForNutrition?.personal_account.student.name} ${selectRequestForNutrition?.personal_account.student.middle_name}`,
                        balance: selectRequestForNutrition?.balance,
                        order_day: orderDayDiscount(),
                        benefits: benefits(),
                        premium: selectRequestForNutrition?.premium,
                        sum: orderDay(),
                        tabel: !!selectRequestForNutrition?.attendance && orderDayBenefit() > 0 ? orderDayBenefit() : 0,
                        attendance: !!selectRequestForNutrition?.attendance,
                        credit: !!selectRequestForNutrition?.credit,
                        breakfast: !!selectRequestForNutrition?.breakfast,
                        hot_breakfast: !!selectRequestForNutrition?.hot_breakfast,
                        dinner: !!selectRequestForNutrition?.dinner,
                        snack: !!selectRequestForNutrition?.snack,
                        lunch: !!selectRequestForNutrition?.lunch,
                        sleeps: !!selectRequestForNutrition?.sleeps
                    }}
                    onSubmit={(values) => {
                        AxiosClient.post(`/request_for_nutrition/correct/${selectRequestForNutrition.id}`, values)
                            .then(() => {
                                setUpdateRequestForNutrition(false)
                                updateData()
                            })
                            .catch(() => console.log('error'))
                    }}
                >
                    {({setFieldValue}) => (
                    <Form>
                        <Row>
                            <Col>
                                <div className="">
                                    <label className="m-0 w-100">Лицевой счет:
                                        <Field name="personal_account" className="form-control" disabled/>
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="">
                                    <label className="m-0 w-100">ФИО:
                                        <Field name="fio" className="form-control" disabled/>
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="">
                                    <label className="m-0 w-100">Баланс:
                                        <Field name="balance" className="form-control" disabled/>
                                    </label>
                                </div>
                            </Col>
                            <Col>
                                <div className="">
                                    <label className="m-0 w-100">Сумма:
                                        <Field name="sum" className="form-control" disabled/>
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="">
                                    <label className="m-0 w-100">Льготы:
                                        <Field name="benefits" className="form-control" disabled/>
                                    </label>
                                </div>
                            </Col>
                            <Col>
                                <div className="">
                                    <label className="m-0 w-100">Заказ на день:
                                        <Field name="order_day" className="form-control" disabled/>
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="">
                                    <label className="m-0 w-100">Надбавка:
                                        <Field name="premium" className="form-control" disabled/>
                                    </label>
                                </div>
                            </Col>
                            <Col>
                                <div className="">
                                    <label className="m-0 w-100">Табель:
                                        <Field name="tabel" className="form-control" disabled/>
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <div className="d-flex gap-2 align-items-center">
                                    <label className="m-0 w-100">Явка: &nbsp;
                                    <Field name="attendance" type="checkbox">
                                        {
                                            ({field}: any) => (
                                                <Input type="checkbox" name="attendance" className="fs-18 m-0" {...field}
                                                       onChange={(e: any) => {
                                                           attendance()
                                                           setNewObject({...newObject, 'attendance': e.target.value});
                                               }}/>
                                            )
                                        }
                                    </Field>
                                    </label>
                                </div>
                            </Col>
                            <Col>
                                <div className="d-flex gap-2 align-items-center">
                                    <label className="m-0">В кредит: &nbsp;
                                    <Field name="credit" type="checkbox">
                                        {
                                            ({field}: any) => (
                                                <Input type="checkbox" name="credit" className="fs-18" {...field}
                                                       onChange={(e: any) => {
                                                           setNewObject({...newObject, 'credit': e.target.checked});
                                                           setFieldValue('credit', e.target.checked);
                                                       }}
                                                />
                                            )
                                        }
                                    </Field>
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <div className="d-flex gap-2 align-items-center">
                                    <label className="m-0 w-100">{listMealtime[0]?.name}: &nbsp;
                                    <Field name="breakfast" type="checkbox">
                                        {
                                            ({field}: any) => (
                                                <Input type="checkbox" name="breakfast" className="fs-18" {...field} onChange={event => {
                                                    check(listMealtime[0]?.slug, event.target.checked)
                                                    setNewObject({...newObject, 'breakfast': event.target.checked});
                                                }}/>
                                            )
                                        }
                                    </Field>
                                    </label>
                                </div>
                            </Col>
                            <Col>
                                <div className="d-flex gap-2 align-items-center">
                                    <label className="m-0 w-100">{listMealtime[3]?.name}: &nbsp;
                                    <Field name="snack" type="checkbox">
                                        {
                                            ({field}: any) => (
                                                <Input type="checkbox" name="snack" className="fs-18" {...field} onChange={event => {
                                                    check(listMealtime[3]?.slug, event.target.checked)
                                                    setNewObject({...newObject, 'snack': event.target.checked});
                                                }}/>
                                            )
                                        }
                                    </Field>
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <div className="d-flex gap-2 align-items-center">
                                    <label className="m-0 w-100">{listMealtime[2]?.name}: &nbsp;
                                    <Field name="hot_breakfast" type="checkbox">
                                        {
                                            ({field}: any) => (
                                                <Input type="checkbox" name="hot_breakfast" className="fs-18" {...field} onChange={event => {
                                                    check(listMealtime[2]?.slug, event.target.checked)
                                                    setNewObject({...newObject, 'hot_breakfast': event.target.checked});
                                                }}/>
                                            )
                                        }
                                    </Field>
                                    </label>
                                </div>
                            </Col>
                            <Col>
                                <div className="d-flex gap-2 align-items-center">
                                    <label className="m-0 w-100">{listMealtime[4]?.name}: &nbsp;
                                    <Field name="lunch" type="checkbox">
                                        {
                                            ({field}: any) => (
                                                <Input type="checkbox" name="lunch" className="fs-18" {...field} onChange={event => {
                                                    check(listMealtime[4]?.slug, event.target.checked)
                                                    setNewObject({...newObject, 'lunch': event.target.checked});
                                                }}/>
                                            )
                                        }
                                    </Field>
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <div className="d-flex gap-2 align-items-center">
                                    <label className="m-0 w-100">{listMealtime[1]?.name}: &nbsp;
                                    <Field name="dinner" type="checkbox">
                                        {
                                            ({field}: any) => (
                                                <Input type="checkbox" name="dinner" className="fs-18" {...field} onChange={event => {
                                                    check(listMealtime[1]?.slug, event.target.checked)
                                                    setNewObject({...newObject, 'dinner': event.target.checked});
                                                }}/>
                                            )
                                        }
                                    </Field>
                                    </label>
                                </div>
                            </Col>
                            <Col>
                                <div className="d-flex gap-2 align-items-center">
                                    <label className="m-0 w-100">{listMealtime[5]?.name}: &nbsp;
                                    <Field name="sleeps" type="checkbox">
                                        {
                                            ({field}: any) => (
                                                <Input type="checkbox" name="sleeps" className="fs-18" {...field}
                                                       onChange={event => {
                                                           check(listMealtime[5]?.slug, event.target.checked)
                                                           setNewObject({...newObject, 'sleeps': event.target.checked});
                                                       }}
                                                />
                                            )
                                        }
                                    </Field>
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <button id={idButtonSave} type={"submit"} className="btn btn-primary mt-3">Сохранить</button>
                    </Form>
                    )
                }
                </Formik>
            </ModalBody>
            <ModalConfirmExit idButtonSave={idButtonSave} setUpdate={value => setUpdateRequestForNutrition(value)} confirmExit={confirmExit} setConfirmExit={value => setConfirmExit(value)} />
        </Modal>
    );
};

export default ModalUpdateRequestForNutrition;
