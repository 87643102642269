export enum TypeSettings {
    SELECT_ANSWER, //выбор ответа
    COLLECT, //собрать в кучку
    SELECT_COLLECT, //выбраный предмет
    NO_SETTINGS, //нет настроек
    APPEARANCE, //появление
    SEARCH, // одинаковые картинки
    SWITCH_SHOT, //переключение между кадрами
    BATTLE ,//сражение
    TAKE //взять в инвентарь
}