import React, {FC, useEffect, useState} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Field, Form, Formik} from "formik";
import AxiosClient from "../../../api/AxiosClient";
import * as Yup from 'yup'
import {useAppDispatch} from "../../../hooks/redux";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";

interface IModalAddBenefit {
    addBenefit: boolean,
    setAddBenefit: (value: boolean) => void,
    addMyBenefits: any[],
    setAddMyBenefits: (value: any[]) => void
}
const ModalAddBenefit: FC<IModalAddBenefit> = ({addBenefit, setAddBenefit, setAddMyBenefits, addMyBenefits}) => {

    const [benefits, setBenefits] = useState<any[]>([]);

    useEffect(() => {
        AxiosClient.get('/benefits')
            .then(r => setBenefits(r.data))
    }, []);

    const dispatch = useAppDispatch();
    useEscapeKey(() => dispatch(setModalAdd(false)), addBenefit, "addBenefit");

    return (
        <Modal id="addBenefit" isOpen={addBenefit}
               toggle={() => setAddBenefit(!addBenefit)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setAddBenefit(!addBenefit)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление льготы
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={
                        {benefit_id: ''}
                    }
                    validationSchema={
                        Yup.object({
                            benefit_id: Yup.string().required('Require')
                        })
                    }
                    onSubmit={(values) => {
                        setAddMyBenefits([
                            ...addMyBenefits,
                            benefits.find(el => el.id.toString() === values.benefit_id.toString())
                        ])

                        setAddBenefit(false)
                    }}
                >
                    {
                        ({errors, touched}) => (
                            <Form>
                                <Field as="select" name="benefit_id" className={`form-select ${errors.benefit_id && touched.benefit_id && "border border-2 border-danger"}`}>
                                    <option value="">Не выбрано</option>
                                    {
                                        benefits.map(el => (<option key={el.id} value={el.id}>{el.name}</option>))
                                    }
                                </Field>
                                <button type="submit" className="btn btn-primary mt-1" id="addNewProject">Добавить</button>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddBenefit;
