import React, {FC, PropsWithChildren} from 'react';
import {Container, Row, Col, Card, CardBody} from "reactstrap";

import {Link} from "react-router-dom";
const LoginLayout: FC<PropsWithChildren> = ({children}) => {

    return (
        <>
            <div className="auth-page-wrapper pt-5" >
                <div className="auth-one-bg-position auth-one-bg" id="auth-particles" >

                    <div className="bg-overlay" ></div>

                    <div className="shape" >
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink"
                             viewBox="0 0 1440 120">
                            <path  d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
                        </svg>
                    </div>
                </div>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img alt="" height="20"/>
                                        </Link>
                                    </div>
                                    <p className="mt-3 fs-24 fw-medium">Вход <br/>
                                        в Систему контроля деятельности <br/> Образовательного учреждения</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5} className="mt-3">
                                <Card style={{boxShadow: '0px 0px 10px 10px rgba(0,0,0,0.5)'}}>
                                    <CardBody>
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Добро пожаловать</h5>
                                            <p className="text-muted">Войдите, чтобы продолжить</p>
                                        </div>
                                            {children}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <p className="mb-0 text-muted">&copy; 2022. ООО "БИЗНЕС-ПРЕМИУМ"</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
};

export default LoginLayout;
