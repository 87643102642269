import React, {FC, useEffect, useState} from 'react';
import {Col, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Russian} from "flatpickr/dist/l10n/ru";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import {Field, Form, Formik} from "formik";
import FromMyTaskService from "../../../../api/services/documentsFlowService/FromMyTaskService";
import {useAppDispatch} from "../../../../hooks/redux";
import {useEscapeKey} from "../../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../../store/reducers/widgets/WidgetsSlice";

interface IModalFromMyTask {
    setOpenModalFromMyTask: (value: boolean) => void,
    openModalFromMyTask: boolean,
}

const ModalFromMyTask: FC<IModalFromMyTask> = ({setOpenModalFromMyTask, openModalFromMyTask}) => {
    const [date, setDate] = useState(new Date());
    const [businessProcess, setBusinessProcess] = useState<any[]>([]);
    const [selectBusinessProcess, setSelectBusinessProcess] = useState<any | null>(null);
    const [waypoints, setWaypoints] = useState<any[]>([]);
    const [changeWaypoints, setChangeWaypoints] = useState<any[]>([]);
    const [performers, setPerformers] = useState<any[]>([]);

    useEffect(() => {
        FromMyTaskService.getBusinessProcess()
            .then(r => setBusinessProcess(
                r.data.map((bp: any) => ({
                        value: bp.route_uid,
                        label: bp.route_name,
                }))
            ))
        FromMyTaskService.getAllUsers()
            .then(r => setPerformers(r.data.map(p => ({value: p.user_uid, label: p.user_login}))))
    }, []);

    useEffect(() => {
        if (selectBusinessProcess){
            FromMyTaskService.getWaypoints(selectBusinessProcess.value)
                .then((r) => {
                    setWaypoints(r.data)
                    setChangeWaypoints(r.data)
                })
        }
    }, [selectBusinessProcess]);



    const customStyles = {
        control: (ds: any) => ({
            ...ds,
            backgroundColor: "#fff",
        }),
        option: (ds: any, state: any) => ({
            ...ds,
            backgroundColor: state.isFocused ? "#4b38b3" : "#fff",
            color: state.isFocused ? "#fff" : "#000"
        })
    }
    const dispatch  =useAppDispatch()
    useEscapeKey(() => dispatch(setModalAdd(false)), openModalFromMyTask, "ModalFromMyTask");

    return (
        <Modal id="ModalFromMyTask" isOpen={openModalFromMyTask}
               toggle={() => setOpenModalFromMyTask(!openModalFromMyTask)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setOpenModalFromMyTask(!openModalFromMyTask)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавить задачу
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            name: '',
                            description: ''
                        }
                    }
                    onSubmit={(values) => {
                        const task = {
                            uid: '',
                            enddate: date,
                            reason_uid: '',
                            process_uid: selectBusinessProcess.value,
                            waypoint_uid: changeWaypoints.map(cwp => cwp.waypoint_uid),
                            waypoint_performer: changeWaypoints.map(cwp => cwp.waypoint_performer),
                            performer_uid: [changeWaypoints.map(r => r.waypoint_performer)],
                            name: values.name,
                            description: values.description
                        }
                        FromMyTaskService.addTask(task)
                            .then()
                    }}
                >
                    <Form>
                        <Row>
                            <Col>
                                <Row className="mt-2">
                                    <Col>
                                        <Label>
                                            Название
                                        </Label>
                                        <Field name="name" className="form-control" placeholder="Название"/>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <Label>
                                            Описание
                                        </Label>
                                        <Field as="textarea" name="description" className="form-control" placeholder="Описание"/>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <Label>
                                            Срок
                                        </Label>
                                        <Flatpickr
                                            className="form-control border-0 dash-filter-picker shadow"
                                            value={date}
                                            options={{
                                                locale: Russian,
                                                mode: "single",
                                                dateFormat: "d.m.Y H:i",
                                                enableTime: true,
                                                minDate: new Date()
                                            }}
                                            onChange={(date) => setDate(date[0])}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <Label>
                                            Бизнес-процесс
                                        </Label>
                                        <Select
                                            options={businessProcess}
                                            defaultValue={selectBusinessProcess}
                                            onChange={(newValue) => {
                                                setSelectBusinessProcess({value: newValue.value, label: newValue.label})
                                            }}
                                            styles={customStyles}
                                            placeholder="Поиск"
                                        />
                                    </Col>
                                </Row>
                                {
                                    waypoints.map((wp, i) => (
                                        <Row className="mt-2" key={i}>
                                            <Col>
                                                <Label>
                                                    {
                                                        wp.waypoint_name
                                                    }
                                                </Label>
                                                <Select
                                                    options={performers}
                                                    defaultValue={{...(performers.find(p => p.value ===  wp.waypoint_performer))}}
                                                    onChange={(newValue) => {
                                                        setChangeWaypoints(changeWaypoints.map(cwp => {
                                                            if (cwp.waypoint_uid === wp.waypoint_uid){
                                                                return {...cwp, waypoint_performer: newValue?.value}
                                                            }
                                                            return cwp
                                                        }))
                                                    }}
                                                    styles={customStyles}
                                                    placeholder="Поиск"
                                                />
                                            </Col>
                                        </Row>
                                    ))
                                }
                                <Row className="mt-2">
                                    <Col>
                                        <button className="btn btn-primary" type={"submit"}>Начать процесс</button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Formik>
            </ModalBody>
        </Modal>

    );
};

export default ModalFromMyTask;
