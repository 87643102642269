import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    setDateAdd, setGroupAllDeviations, setGroupModalAdd,
    setIsSend,
    setModalAdd,
    WidgetsSlice
} from "../../../store/reducers/widgets/WidgetsSlice";
import {Col, Input, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import moment from "moment/moment";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import AxiosClient from "../../../api/AxiosClient";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {getJournalHaccpAntisepticSurface} from "../../../store/reducers/widgets/ActionCreator";
import DataTable from "react-data-table-component";
import ModalConfirmExitHaccp from "../ModalConfirmExitHaccp";
import {useModalWithConfirm} from "../../../hooks/useModalWithConfirm";

interface IModalAddAntisepticSurface{
    updateData: any
}

const ModalAddAntisepticSurface: FC<IModalAddAntisepticSurface> = ({updateData}) => {
    const dispatch = useAppDispatch();
    const {modalAdd, groupAllDeviations, date, groupModalAdd, groups, dateAdd, successUpdate} = useAppSelector(state => state.WidgetReducer);

    const [surface, setSurface] = useState<any>([]);
    const [surfaceList, setSurfaceList] = useState<any>([]);

    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [selectedSurfaceSetting, setSelectedSurfaceSetting] = useState<string>('');

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(WidgetsSlice.actions.successUpdate(""))
        }, 5000)
        return () => clearTimeout(timer)
    }, [successUpdate, dispatch]);

    useEffect(() => {
        if(modalAdd){
            setSurfaceList([])
            setSelectedSurfaceSetting('')
            setSurface([])
            AxiosClient.get(`haccp/getSurfaceSettingListGroup/${groupModalAdd}`)
                .then((r: any) => {
                    setSurfaceList(r.data[0].premises)
                    setSelectedSurfaceSetting(r.data[0].premises[0].uid)
                    AxiosClient.get(`haccp/getSurfaceSetting/${r.data[0].premises[0].uid}`)
                        .then((r: any) => {
                            setSurface(r.data[0].surfaces)
                        })
                        .catch((error) => console.log(error))
                })
                .catch((error) => console.log(error))
        }
    }, [modalAdd, groupAllDeviations])

    useEffect(() => {
        if (modalAdd && !updateData) {
            dispatch(setDateAdd(new Date()));
        }
    }, [modalAdd]);
    const fetchSurfaces = (settingUid: string) => {
        AxiosClient.get(`haccp/getSurfaceSetting/${settingUid}`)
            .then((r: any) => {
                setSurface(r.data[0].surfaces);
            })
            .catch((error) => console.log(error));
    };

    const handleSelectRow = (surface_uid: string) => {
        setSelectedRows((prevSelected) => {
            if (prevSelected.includes(surface_uid)) {
                return prevSelected.filter(uid => uid !== surface_uid);
            } else {
                return [...prevSelected, surface_uid];
            }
        });
    };

    const handleSelectAll = () => {
        if (selectedRows.length === surface.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(surface.map((row:any) => row.surface_uid));
        }
    };

    const isAllSelected = () => {
        return selectedRows.length === surface.length;
    };
    useEffect(() => {
        if (updateData && updateData.surfaces) {
            const selectedSurfaceUids = updateData.surfaces.map((surface: any) => surface.surface_uid);
            setSelectedRows(selectedSurfaceUids);
        }
    }, [updateData]);
    useEffect(() => {
        if (!modalAdd) {
            setSelectedRows([]);
        }
    }, [modalAdd]);
    const {
        isFormChange,
        showConfirmExit,
        handleClose,
        handleFormChange,
        handleConfirmClose,
        handleStay,
    } = useModalWithConfirm("addAntisepticSurface");
    const columns : any = [
        {
            name: <span className='font-weight-bold accessibility_size_table'>Поверхность</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.surface_name}</span>,
            //center: true,
            compact: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Площадь</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.area}</span>,
            compact: true,
            center: true,
            width: '150px',
        },
        {
            name: <Input type="checkbox" className='fs-18' onChange={handleSelectAll} checked={isAllSelected()} />,
            cell: (row: any) => (
                <Input
                    type="checkbox" className='fs-18'
                    checked={selectedRows.includes(row.surface_uid)}
                    onChange={() => handleSelectRow(row.surface_uid)}
                />
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]

    return (
        <>
            <Modal id="addAntisepticSurface" isOpen={modalAdd} toggle={handleClose}
                   modalClassName="zoomIn" tabIndex={-1} centered>
                <ModalHeader toggle={handleClose} className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Добавление параметров обработки поверхностей
                </ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            group: `${groupAllDeviations}`,
                            date: moment(dateAdd).format("DD-MM-YYYY"),
                            setting: updateData? updateData.setting : selectedSurfaceSetting,
                            surfaces: surface.map((s: any) => ({
                                ...s,
                                remove: selectedRows.includes(s.surface_uid) ? '0' : '1'
                            }))
                        }}
                        onSubmit={(values) => {
                            dispatch(setIsSend(true));

                            let prop = {
                                uid: updateData? updateData.uid : '',
                                group: values.group,
                                data: moment(dateAdd).format("DD-MM-YYYY"),
                                setting: values.setting,
                                surfaces: values.surfaces,
                            }
                            AxiosClient.post('haccp/saveJournalAntisepticSurface', prop)
                                .then((r: any) => {
                                    dispatch(getJournalHaccpAntisepticSurface(date,groupAllDeviations)).then()
                                    dispatch(setIsSend(false));
                                    dispatch(setModalAdd(false));
                                    handleConfirmClose();
                                })
                                .catch((error) => {
                                    dispatch(setIsSend(false))
                                })
                        }}
                    >
                    {({ errors, touched, setFieldValue }) => (
                    <Form onChange={handleFormChange}>
                        <Row>
                            <Col>
                                <label className="w-100">Дата:
                                    <Flatpickr
                                        className="form-control border-0 dash-filter-picker shadow"
                                        value={dateAdd}
                                        options={{
                                            locale: Russian, dateFormat: "d M Y", disableMobile: true, mode: 'single'
                                        }}
                                        onChange={(date) => {
                                            dispatch(setDateAdd(moment(date[0]).toDate()))
                                        }}
                                    />
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <label className="w-100">Группа:
                                <Field as="select" className="form-select shadow" value={groupModalAdd}
                                       onChange={(e: any) => {dispatch(setGroupAllDeviations(e.target.value))
                                           dispatch(setGroupModalAdd(e.target.value))}} name="group">
                                    {groups.map((group) => {
                                        return <option key={group.group_id}
                                                       value={group.group_id}>{group.group_name}</option>
                                    })}
                                </Field>
                            </label>
                        </Row>
                        <Row>
                            <Col>
                                <label className='w-100'>Настройка:
                                    <Field as="select" className="form-select shadow" name="surfaceSettings"
                                           onChange={(e:any) => {
                                            setSelectedSurfaceSetting(e.target.value);
                                            setFieldValue("surfaceSettings", e.target.value);
                                            fetchSurfaces(e.target.value)
                                    }}>
                                        {surfaceList.map((surface:any) => (
                                            <option key={surface.uid} value={surface.uid}>
                                                {surface.name}
                                            </option>
                                        ))}
                                    </Field>
                                </label>
                            </Col>
                        </Row>
                        <DataTable
                            columns={columns}
                            data={surface}
                            noDataComponent={'Нет данных'}
                            pagination
                            paginationRowsPerPageOptions={[3, 5, 10, 20]}
                            paginationComponentOptions={
                                {
                                    rowsPerPageText: 'Видно:',
                                    rangeSeparatorText: 'из'
                                }
                            }
                            highlightOnHover
                            customStyles={{
                                rows: {
                                    highlightOnHoverStyle: {
                                        backgroundColor: "#ffebb5"
                                    },
                                },
                                expanderRow: {
                                    style: {
                                        backgroundColor: "#ffebb5",
                                        paddingTop: '5px',
                                        paddingBottom: '5px'
                                    },
                                },
                            }}
                            conditionalRowStyles={[
                                {
                                    when: (row: any) => selectedRows.includes(row.surface_uid),
                                    style: {
                                        backgroundColor: "rgba(236,234,247, 0.5)",
                                        borderLeft: "5px solid #4a37b2",
                                    }
                                }
                            ]}
                        />
                        <div className="d-flex gap-5 justify-content-between">
                            <div></div>
                            <div className="hstack gap-2 justify-content-end mt-1">
                                <SubmitWithLoading text={"Сохранить"}/>
                            </div>
                        </div>
                    </Form>
                    )}
                    </Formik>

                </ModalBody>
            </Modal>
            <ModalConfirmExitHaccp
                isOpen={showConfirmExit}
                onClose={handleConfirmClose}
                onStay={handleStay}
                hasUnsavedChanges={isFormChange}
            />
        </>
    )
};

export default ModalAddAntisepticSurface;