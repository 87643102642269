import {Field} from "formik";
import {FC, useEffect, useState} from "react";
import DataTable from "react-data-table-component";
import AxiosClient from "../../../../api/AxiosClient";
import { getStudents } from "../../../../store/reducers/widgets/ActionCreator";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";

interface ITableAddDayliReportNutrationSecondBreakfast {
    studentsDayliReport: any[],
    date: any,
    group: any,
}
const TableAddDayliReportNutrationSecondBreakfast: FC<ITableAddDayliReportNutrationSecondBreakfast> = ({studentsDayliReport, date, group}) => {
    const dispatch = useAppDispatch();

    const {students} = useAppSelector(state => state.WidgetReducer)

    const [breakfast, setBreakfast] = useState<any[]>([]);

    useEffect(() => {
        AxiosClient.get('/second_brunch').then(
            r => setBreakfast(r.data)
        )
    }, []);
    
    useEffect(() => {
        dispatch(getStudents(date, group)).then()
    }, [date, group, dispatch])

    const columns: any = [
        {
            name: <span className='font-weight-bold fs-13'>ФИО ребенка</span>,
            selector: (row: any) => row.name,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Второй ужин</span>,
            selector: (row: any, i: number) => 
                (row.second_brunch_id && !students[i].deviation_code) ? 
                <Field as="select" className="form-select table-add-ceil" name={`second_brunch[${i}].${row.children_id}`}>
                    {
                        breakfast.map(br => <option key={br.id} value={br.id}>{br.name}</option>)
                    }
                </Field>
                : <><span className="text-danger mt-1 mb-0">Отсутствует</span>
                <Field as="select" className="form-select table-add-ceil" name={`second_brunch[${i}].${row.children_id}`} style={{ display: 'none' }}> 
                    {
                    <option key={null} value={''}></option>
                    }
                </Field>
            </>
            ,
            center: true,
            style: {padding: '0'}
        }
    ];

    return (
        <DataTable
            columns={columns}
            data={studentsDayliReport}
            className="custom-scroll-news"
            fixedHeader
            fixedHeaderScrollHeight={'400px'}
            noDataComponent={'В данной группе нет детей'}
            highlightOnHover
            customStyles={{
                rows: {
                    highlightOnHoverStyle: {
                        backgroundColor: "#ffebb5"
                    }
                }
            }}
        />
    );
}

export default TableAddDayliReportNutrationSecondBreakfast;