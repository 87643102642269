import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import AxiosClient from "../../../api/AxiosClient";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import Select from "react-select";
import {customStyles} from "../../settings";
import {areIntervalsOverlapping} from "../../../functions/ConvertDate";
import moment from "moment/moment";
import {useAppDispatch} from "../../../hooks/redux";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";

interface IModalAddBenefit {
    addBenefit: boolean,
    setAddBenefit: (value: boolean) => void
    listBenefit: any[],
    setListBenefit: (value: any[]) => void
}

const ModalAddBenefit: FC<IModalAddBenefit> = ({setAddBenefit, addBenefit, setListBenefit, listBenefit}) => {

    const [dataBegin, setDataBegin] = useState(new Date());
    const [dateEnd, setDateEnd] = useState<Date | string>('');
    const [benefits, setBenefits] = useState<any[]>([]);
    const [selectBenefit, setSelectBenefit] = useState<any>({value: '', label: 'Не выбрано'});

    useEffect(() => {
        addBenefit &&
        AxiosClient.get('benefits')
            .then(r => setBenefits(r.data))
            .catch(() => console.log('error'))
    }, [addBenefit]);

    const dispatch = useAppDispatch();
    useEscapeKey(() => dispatch(setModalAdd(false)), addBenefit, "addBenefit");

    return (
        <Modal id="addBenefit" isOpen={addBenefit}
               toggle={() => setAddBenefit(!addBenefit)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setAddBenefit(!addBenefit)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление льготу
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={{
                        date_begin: new Date(),
                        date_end: '',
                        benefit_id: selectBenefit.value,
                        sum: ''
                    }}
                    validationSchema={
                        Yup.object({
                            benefit_id: Yup.string().required('Обязательное поле'),
                            sum: Yup.string().required('Обязательное поле'),
                        })
                    }
                    onSubmit={(values, { setErrors }) => {
                        const isDuplicate = listBenefit.some(m => (m.id.toString() === values.benefit_id.toString()) && areIntervalsOverlapping(m, {date_begin: dataBegin,
                            date_end: dateEnd}));
                        if (isDuplicate) {
                            setErrors({benefit_id: 'Такая льгота уже существует или совпадают периоды'});
                        } else {
                            setListBenefit([...listBenefit, {
                                ...benefits.find(b => b.id.toString() === values.benefit_id.toString()),
                                date_begin: moment(dataBegin).format('YYYY-MM-DD'),
                                date_end: dateEnd ? moment(dateEnd).format('YYYY-MM-DD') : "",
                                sum: values.sum
                            }])
                            setAddBenefit(false)
                        }
                    }}
                >
                    {
                        ({errors, touched, setFieldValue}) => (
                            <Form>
                                <Row>
                                    <Col>
                                        <div>
                                            <label className="w-100">Льгота:
                                            <Select
                                                options={benefits?.length > 0 ? [{
                                                    value: '',
                                                    label: 'Не выбрано'
                                                }, ...benefits?.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                                                value={selectBenefit}
                                                onChange={(change: any) => {
                                                    setFieldValue('benefit_id', change.value)
                                                    setSelectBenefit({value: change.value, label: change.label})
                                                    setFieldValue('sum', benefits.find(el => el.id.toString() == change.value)?.price)
                                                }}
                                                styles={customStyles}
                                                placeholder="Поиск"
                                                name="name"
                                            /></label>
                                            {errors.benefit_id && touched.benefit_id ? (
                                                <div className="text-danger">{errors.benefit_id.toString()}</div>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <label className="w-100">Дата с:
                                            <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                value={dataBegin}
                                                options={{
                                                    locale: Russian, dateFormat: "d M Y"
                                                }}
                                                onClose={(date) => {
                                                    setDataBegin(date[0])
                                                }}
                                            />
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <label className="w-100">Дата по:
                                            <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                value={dateEnd}
                                                options={{
                                                    locale: Russian, dateFormat: "d M Y"
                                                }}
                                                onClose={(date) => {
                                                    setDateEnd(date[0])
                                                }}
                                            />
                                            </label>
                                            {errors.date_end && touched.date_end ? (
                                                <div className="text-danger">{errors.date_end.toString()}</div>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <label className="w-100">Сумма:
                                                <Field name="sum" className={`form-control ${errors.sum && touched.sum && "border border-2 border-danger"}`}/>
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                                <div>
                                    <div className="hstack gap-2">
                                        <button type="submit" className="btn btn-primary mt-1">Добавить</button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddBenefit;
