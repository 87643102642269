import React, {createContext, useContext, useEffect, useState} from "react";

interface AccessibilitySettings {
    fontSize: number;
    fontSizeGraph: string;
    viewportScale: number;
    imagesVisible: boolean;
    kerning: number;
    grayscaleMode: boolean;
}

interface AccessibilityContextProps extends AccessibilitySettings {
    setFontSize: (size: number) => void;
    setFontSizeGraph: (size: string) => void;
    setViewportScale: (scale: number) => void;
    toggleImages: () => void;
    setKerning: (kerning: number) => void;
    toggleGrayscale: () => void;
}

const AccessibilityContext = createContext<AccessibilityContextProps | null>(null);

export const AccessibilityProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [fontSize, setFontSize] = useState(90);
    const [fontSizeGraph, setFontSizeGraph] = useState('13px');
    const [viewportScale, setViewportScale] = useState(1);
    const [imagesVisible, setImagesVisible] = useState(false);
    const [kerning, setKerning] = useState(1);
    const [grayscaleMode, setGrayscaleMode] = useState(false);

    useEffect(() => {
        if (fontSize === 90) {
            document.body.style.fontSize = '';
            document.documentElement.style.setProperty("--card-title-font-size", '');
            document.documentElement.style.setProperty("--table-font-size", '');
            document.documentElement.style.setProperty("--calendar-font-size", '');
            setFontSizeGraph('13px');
        }
        else if (fontSize === 100) {
            document.body.style.fontSize = `${fontSize}% `;
            document.documentElement.style.setProperty("--card-title-font-size", `${fontSize}%`);
            document.documentElement.style.setProperty("--table-font-size", `15px`);
            document.documentElement.style.setProperty("--calendar-font-size", '26px');
            setFontSizeGraph('16px');
        }
        else{
            document.body.style.fontSize = `${fontSize}% `;
            document.documentElement.style.setProperty("--card-title-font-size", `${fontSize}%`);
            document.documentElement.style.setProperty("--table-font-size", `17px`);
            document.documentElement.style.setProperty("--calendar-font-size", '28px');
            setFontSizeGraph('20px');
        }
    }, [fontSize]);

    useEffect(() => {
        if (grayscaleMode) {
            document.body.classList.add("grayscale-mode");
        } else {
            document.body.classList.remove("grayscale-mode");
        }
    }, [grayscaleMode]);

    useEffect(() => {
        if (imagesVisible) {
            document.body.classList.add("accessibility-hide-images");
        } else {
            document.body.classList.remove("accessibility-hide-images");
        }
    }, [imagesVisible]);

    useEffect(() => { //shepherd-text
        if(kerning!==1) {
            document.body.style.letterSpacing = `${kerning}px`;
            document.documentElement.style.setProperty("--shepherd-text-letter-spacing", `${kerning}px`);
        }
        else {
            document.body.style.letterSpacing = '';
            document.documentElement.style.setProperty("--shepherd-text-letter-spacing", '');
        }
    }, [kerning]);

    return (
        <AccessibilityContext.Provider
            value={{
                fontSize,
                fontSizeGraph,
                viewportScale,
                imagesVisible,
                kerning,
                setFontSize,
                setFontSizeGraph,
                setViewportScale,
                toggleImages: () => setImagesVisible((prev) => !prev),
                setKerning,
                grayscaleMode,
                toggleGrayscale: () => setGrayscaleMode((prev) => !prev), }}
        >
            {children}
        </AccessibilityContext.Provider>
    );
};

export const useAccessibility = () => {
    const context = useContext(AccessibilityContext);
    if (!context) {
        throw new Error("useAccessibility must be used within an AccessibilityProvider");
    }
    return context;
};
