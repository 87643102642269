import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getAllInfoGroup} from "../../../store/reducers/widgets/ActionCreator";
import {Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import {setDateAdd, setGroupModalAdd, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import moment from "moment/moment";
import ModalAddReportJournalHaccp from "../TemperaturesRoom/ModalAddReportJournalHaccp";
import ModalAddReportJournalVentilationRoom from "./ModalAddReportJournalVentilationRoom";


const WidgetTableVentilationRoom = () => {
    const dispatch = useAppDispatch();

    const { date, groupAllDeviations, allInfoGroup, journalHaccpVentilationRoom} = useAppSelector(state => state.WidgetReducer)
    const [updateData, setUpdateData] =  useState<any>();
    const newDate = (dateItem1: string) => {
        return dateItem1.split('.')[1] + '-' + dateItem1.split('.')[0] + '-' + dateItem1.split('.')[2];
    }


    useEffect(() => {
        dispatch(getAllInfoGroup(date)).then()
    }, [date, dispatch])

    const columns: any = [
        {
            id: "date",
            name: <span className='font-weight-bold accessibility_size_table mb-0'>Дата</span>,
            selector: (row: any) => row.data,
            cell: (row: any) => <span className='accessibility_size_table'>{row.data}</span>,
            sortable: true,
            center: true,
            width: '120px'
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Группа</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.room}</span>,
            center: true,
            compact: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Тип</span>,
            cell: (row: any) => row.tip === "Несколько раз в день" ? <span className='accessibility_size_table'>Н</span> : <span className='accessibility_size_table'>Р</span>,
            center: true,
            compact: true,
            width: '80px'
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Начало проветривания</span>,
            cell: (row: any) => {
                const times = row.data_time_list.map((item: any) => item.time);
                return times.length > 0 ?
                    times.reduce((minTime:any, currentTime:any) => minTime < currentTime ?
                        <span className='accessibility_size_table'>{minTime}</span>
                        : <span className='accessibility_size_table'>{currentTime}</span>)
                    : <span className='accessibility_size_table'>{row.time}</span>;
            },
            center: true,
            compact: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Окончание проветривания</span>,
            cell: (row: any) => {
                const times = row.data_time_list.map((item: any) => item.endtime);
                return times.length > 0 ? times.reduce((maxTime:any, currentTime:any) => maxTime > currentTime ?
                    <span className='accessibility_size_table'>{ maxTime }</span>
                    : <span className='accessibility_size_table'>{currentTime}</span>)
                    : <span className='accessibility_size_table'>{row.endtime}</span>;
            },
            center: true,
            compact: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Наружная температура</span>,
            //selector: (row: any) => row.temperature,
            cell: (row: any) => {
                const temperatures = row.data_time_list.map((item: any) => item.temperature);
                return temperatures.length > 0
                    ? <span className='accessibility_size_table'>{(temperatures.reduce((acc: number, temp: number) => acc + temp, 0) / temperatures.length).toFixed(1)}</span>
                    : <span className='accessibility_size_table'>{row.temperature}</span>;
            },
            center: true,
            compact: true,
        },
    ];

    return (
        <>
            <Card>
                <CardHeader>
                    Журнал проветриваний помещений
                </CardHeader>
                <CardBody className="py-0">
                    <DataTable
                        columns={columns}
                        data={journalHaccpVentilationRoom ? journalHaccpVentilationRoom : []}
                        pagination
                        paginationRowsPerPageOptions={[5, 10, 20, 30]}
                        paginationComponentOptions={
                            {
                                rowsPerPageText: 'Видно журналов:',
                                rangeSeparatorText: 'из'
                            }
                        }
                        noDataComponent={'Нет замеров'}
                        defaultSortFieldId={"date"}
                        defaultSortAsc={false}
                        highlightOnHover
                        pointerOnHover
                        customStyles={{
                            rows: {
                                highlightOnHoverStyle: {
                                    backgroundColor: "#ffebb5"
                                }
                            }
                        }}
                        onRowClicked={
                            (row: any) => {
                                if(window.innerWidth <= 768){
                                    setUpdateData(row);
                                    dispatch(setGroupModalAdd(row.group_id));
                                    dispatch(setModalAdd(true));
                                    dispatch(setDateAdd(moment(newDate(row.data)).toDate()));
                                }
                            }
                        }
                        onRowDoubleClicked={
                            (row: any) => {
                                if(window.innerWidth > 768){
                                    setUpdateData(row);
                                    dispatch(setGroupModalAdd(row.group_id));
                                    dispatch(setModalAdd(true));
                                    dispatch(setDateAdd(moment(newDate(row.data)).toDate()));
                                }
                            }
                        }
                    />
                </CardBody>
                <CardFooter className="d-flex justify-content-between">
                    <div className='d-flex'>
                        <div className="button_modal mx-1">
                            <button className="btn btn-primary w-100"
                                    onClick={() => {
                                        setUpdateData(null);
                                        dispatch(setModalAdd(true))
                                    }}>Добавить
                            </button>
                        </div>
                    </div>
                </CardFooter>
            </Card>
            <ModalAddReportJournalVentilationRoom updateData={updateData}/>
        </>
    );
};

export default WidgetTableVentilationRoom;
