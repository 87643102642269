import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import AxiosClient from "../../../api/AxiosClient";
import classNames from "classnames";
import DataTable from "react-data-table-component";
import ModalAddStatus from "./ModalAddStatus";
import ModalUpdateStatus from "./ModalUpdateStatus";
import ModalAddParallelClass from "./ModalAddParallelClass";
import ModalUpdateParallelClass from "./ModalUpdateParallelClass";
import {useAppDispatch} from "../../../hooks/redux";
import {setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import _ from "lodash";
import ModalConfirmExit from "../Schools/ModalConfirmExit";
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface IModalUpdateBenefit {
    updateBenefit: boolean,
    setUpdateBenefit: (value: boolean) => void,
    selectBenefit: any,
    setBenefits: (value: any[]) => void
}

const ModalUpdateBenefit: FC<IModalUpdateBenefit> = ({setUpdateBenefit, selectBenefit, updateBenefit, setBenefits}) => {
    const [activeTab, setActiveTab] = useState("1");
    const idButtonSave: string = 'buttonSaveModalUpdateBenefit';
    const [addMyStatuses, setAddMyStatuses] = useState<any[]>([]);
    const [withoutChangesAddMyStatuses, setWithoutChangesAddMyStatuses] = useState<any[]>([]);
    const [selectStatus, setSelectStatus] = useState<any | null>(null);
    const [addStatus, setAddStatus] = useState(false);
    const [updateStatus, setUpdateStatus] = useState(false);

    const [selectParallelClass, setSelectParallelClass] = useState<any | null>(null);
    const [addMyParallelClasses, setAddMyParallelClasses] = useState<any[]>([]);
    const [withoutChangesAddMyParallelClasses, setWithoutChangesAddMyParallelClasses] = useState<any[]>([]);
    const [addParallelClass, setAddParallelClass] = useState(false);
    const [updateParallelClass, setUpdateParallelClass] = useState(false);
    const [confirmExit, setConfirmExit] = useState(false);
    const [newObject, setNewObject] = useState<any>();
    const dispatch = useAppDispatch()

    useEffect(() => {
        setAddMyStatuses(selectBenefit?.statuses)
        setWithoutChangesAddMyStatuses(selectBenefit?.statuses)
        setAddMyParallelClasses(selectBenefit?.parallel_classes)
        setWithoutChangesAddMyParallelClasses(selectBenefit?.parallel_classes)
        setNewObject(selectBenefit)
    }, [selectBenefit]);
    const addAllParallel = () => {
        AxiosClient.get('/parallel_classes')
            .then((r => setAddMyParallelClasses(r.data)))
    }

    const addAllStatuses = () => {
        AxiosClient.get('/statuses')
            .then((r => setAddMyStatuses(r.data)))
    }

    const confirmOrExit = () => {
        if(!_.isEqual(selectBenefit, newObject) || !_.isEqual(addMyParallelClasses, withoutChangesAddMyParallelClasses)  || !_.isEqual(addMyStatuses, withoutChangesAddMyStatuses)){
            setConfirmExit(true);
        }else{
            setUpdateBenefit(false);
        }
    }

    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const removeParallel = (id: number | string) => {
        setAddMyParallelClasses(addMyParallelClasses.filter(el => el.id.toString() !== id.toString()))
    }

    const removeStatus = (id: number | string) => {
        setAddMyStatuses(addMyStatuses.filter(el => el.id.toString() !== id.toString()))
    }
    const columns: any = [
        {
            id: "date",
            name: <span className='font-weight-bold accessibility_size_table'>Наименование</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.name}</span>,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'></span>,
            cell: (row: any) => <i className="bx bx-trash fs-24 cursor-pointer" onClick={() => removeStatus(row.id)}></i>,
            width: '60px'
        }
    ]
    const columnsParallel: any = [
        {
            id: "date",
            name: <span className='font-weight-bold accessibility_size_table'>Наименование</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.name}</span>,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'></span>,
            cell: (row: any) => <i className="bx bx-trash fs-24 cursor-pointer" onClick={() => removeParallel(row.id)}></i>,
            width: '60px'
        }
    ]

    useEscapeKey(() => dispatch(setModalAdd(false)), updateBenefit, "updateBenefit");

    return (
        <Modal id="updateBenefit" isOpen={updateBenefit}
               toggle={() => confirmOrExit()}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => confirmOrExit()}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Обновление льготы
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={
                        {
                            name: selectBenefit?.name,
                            price: selectBenefit?.price
                        }
                    }
                    validationSchema={
                        Yup.object({
                            name: Yup.string().required('Require'),
                            price: Yup.number().required('Require')
                        })
                    }
                    onSubmit={(values) => {
                        dispatch(setIsSend(true))
                        AxiosClient.post(`/benefits/update/${selectBenefit.id}`, {...values, statuses: addMyStatuses, parallel: addMyParallelClasses})
                            .then(() => {
                                dispatch(setIsSend(false))
                                AxiosClient.get('/benefits')
                                    .then(r => {
                                        setBenefits(r.data)
                                    })
                            })
                            .catch(() => {
                                dispatch(setIsSend(false))
                                console.log('error')
                            })
                        setUpdateBenefit(false)
                    }}
                >
                    {

                        ({setFieldValue, errors, touched}) => (
                            <Form>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                     role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "1", "p-2": true})}
                                            onClick={() => {
                                                tabChange("1");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Основное
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "2", "p-2": true})}
                                            onClick={() => {
                                                tabChange("2");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Категория льгот
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "3", "p-2": true})}
                                            onClick={() => {
                                                tabChange("3");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Параллели
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="mt-2">
                                    <TabPane tabId="1" className="align-items-center">
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">Наименование*:
                                                <Field name="name"
                                                       className={`form-control ${errors.name && touched.name && "border border-2 border-danger"}`}
                                                       placeholder="Наименование"
                                                       onChange={(e: any) => {
                                                           setNewObject({...newObject, 'name': e.target.value});
                                                           setFieldValue('name', e.target.value);
                                                       }}
                                                />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">Цена (руб.)*:
                                                <Field name="price"
                                                       className={`form-control ${errors.price && touched.price && "border border-2 border-danger"}`}
                                                       placeholder="Цена"
                                                       onChange={(e: any) => {
                                                           setNewObject({...newObject, 'price': e.target.value});
                                                           setFieldValue('price', e.target.value);
                                                       }}
                                                />
                                                </label>
                                            </Col>
                                        </Row>
                                        <SubmitWithLoading id={idButtonSave} text={"Сохранить"}/>
                                    </TabPane>
                                    <TabPane tabId="2" className="align-items-center">
                                        <DataTable
                                            columns={columns}
                                            data={addMyStatuses}
                                            noDataComponent={'Нет данных'}
                                            pagination
                                            paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                            paginationComponentOptions={
                                                {
                                                    rowsPerPageText: 'Видно статусов:',
                                                    rangeSeparatorText: 'из'
                                                }
                                            }
                                            highlightOnHover
                                            customStyles={{
                                                rows: {
                                                    highlightOnHoverStyle: {
                                                        backgroundColor: "#ffebb5"
                                                    },
                                                },
                                                expanderRow: {
                                                    style: {
                                                        backgroundColor: "#ffebb5",
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px'
                                                    },
                                                },
                                            }}
                                            onRowClicked={(row) => {
                                                setSelectStatus(row)
                                                setUpdateStatus(true)
                                            }}
                                        />
                                        <button type="button" className="btn btn-primary mt-1" id="addNewProject"
                                                onClick={() => setAddStatus(true)}>Добавить
                                        </button>
                                        <button type="button" className="btn btn-primary mt-1 mx-1" id="addNewProject"
                                                onClick={() => addAllStatuses()}>Добавить все категории
                                        </button>
                                    </TabPane>
                                    <TabPane tabId="3" className="align-items-center">
                                        <DataTable
                                            columns={columnsParallel}
                                            data={addMyParallelClasses}
                                            noDataComponent={'Нет данных'}
                                            pagination
                                            paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                            paginationComponentOptions={
                                                {
                                                    rowsPerPageText: 'Видно параллелей:',
                                                    rangeSeparatorText: 'из'
                                                }
                                            }
                                            highlightOnHover
                                            customStyles={{
                                                rows: {
                                                    highlightOnHoverStyle: {
                                                        backgroundColor: "#ffebb5"
                                                    },
                                                },
                                                expanderRow: {
                                                    style: {
                                                        backgroundColor: "#ffebb5",
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px'
                                                    },
                                                },
                                            }}
                                            onRowClicked={(row) => {
                                                setSelectParallelClass(row)
                                                setUpdateParallelClass(true)
                                            }}
                                        />
                                        <button type="button" className="btn btn-primary mt-1" id="addNewProject"
                                                onClick={() => setAddParallelClass(true)}>Добавить
                                        </button>
                                        <button type="button" className="btn btn-primary mt-1 mx-1" id="addNewProject"
                                                onClick={() => addAllParallel()}>Добавить все параллели
                                        </button>
                                    </TabPane>
                                </TabContent>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
            <ModalConfirmExit idButtonSave={idButtonSave} setUpdate={value => setUpdateBenefit(value)} confirmExit={confirmExit} setConfirmExit={value => setConfirmExit(value)} />
            <ModalAddStatus addStatus={addStatus} setAddStatus={value => setAddStatus(value)}
                            addMyStatuses={addMyStatuses} setAddMyStatuses={value => setAddMyStatuses(value)}/>
            <ModalUpdateStatus selectStatus={selectStatus} updateStatus={updateStatus}
                               setUpdateStatus={value => setUpdateStatus(value)} addMyStatuses={addMyStatuses}
                               setAddMyStatuses={value => setAddMyStatuses(value)}/>
            <ModalAddParallelClass addParallelClass={addParallelClass}
                                   setAddParallelClass={value => setAddParallelClass(value)}
                                   addMyParallelClasses={addMyParallelClasses}
                                   setAddMyParallelClasses={value => setAddMyParallelClasses(value)}/>
            <ModalUpdateParallelClass selectParallelClass={selectParallelClass}
                                      updateParallelClass={updateParallelClass}
                                      setUpdateParallelClass={value => setUpdateParallelClass(value)}
                                      addMyParallelClasses={addMyParallelClasses}
                                      setAddMyParallelClasses={value => setAddMyParallelClasses(value)}/>
        </Modal>
    );
};

export default ModalUpdateBenefit;
