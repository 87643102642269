import React from 'react';
import {Helmet} from "react-helmet";
import ListGames from "../../components/GameNew/ListGames";
import {Container} from "reactstrap";
import {useStateContext} from "../../hooks/useStateContext";
import StartMainGame from "../../components/Game/StartMainGame";

const Games = () => {
    const {user} = useStateContext();

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{user?.organization?.type_game_location ? 'Игры «Локация»' : 'Игры' }</title>
            </Helmet>
            <Container fluid>
                {user?.organization?.type_game_location ?
                    <StartMainGame/>
                    : <ListGames/>
                }
            </Container>
        </div>
    );
};

export default Games;
