import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Card, CardBody, CardFooter, Col, Container, Row} from "reactstrap";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import GraphDonutCashAccounting from "../../components/KShP/CashAccounting/GraphDonutCashAccounting";
import TableCashAccountingTotal from "../../components/KShP/CashAccounting/TableCashAccountingTotal";
import TableCashAccounting from "../../components/KShP/CashAccounting/TableCashAccounting";
import AxiosClient from "../../api/AxiosClient";
import ModalTransferMoneyPersonalAccounts
    from "../../components/KShP/CashAccounting/ModalTransferMoneyPersonalAccounts";
import {setIsSend} from "../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../hooks/redux";
import SubmitWithLoading from "../../components/layouts/UI/SubmitWithLoading";
import ModalAddAmountPayment from "../../components/KShP/CashAccounting/ModalAddAmountPayment";

const CashAccounting = () => {
    const dispatch = useAppDispatch();
    const [date, setDate] = useState([new Date(new Date().setDate(1)), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)]);
    const [children, setChildren] = useState<any[]>([]);
    const [selectChildren, setSelectChildren] = useState('');
    const [openTransferMoney, setOpenTransferMoney] = useState(false);
    const [addAmountPayment, setAddAmountPayment] = useState(false);
    useEffect(() => {
        AxiosClient.get('/users/students')
            .then(r => {
                setChildren(r.data)
                r.data.length > 0 && setSelectChildren(r.data[0].id)
            })
            .catch(() => console.log('error'))
    }, []);


    const aktReconciliation = () => {
        dispatch(setIsSend(true))
        const params = {
            date: date,
            children: selectChildren,
        }
        AxiosClient.get('cash_movements/akt_reconciliation_nutrition', {responseType: 'blob', params})
            .then((r: any) => {
                dispatch(setIsSend(false))
                const url = window.URL.createObjectURL(new Blob([r.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Акт сверки питания.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(() => {
                dispatch(setIsSend(false))
                console.log('error')
            })
    }


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Баланс учета денежных средств</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xxl={3}>
                            <div className="col-auto mb-3">
                                <div className="input-group">
                                    <Flatpickr
                                        className="form-control border-0 dash-filter-picker shadow"
                                        value={date}
                                        options={{
                                            locale: Russian, mode: "range", dateFormat: "d m Y", disableMobile: true
                                        }}
                                        onClose={(date) => {
                                            setDate(date)
                                        }}
                                    />
                                </div>
                            </div>
                            <Card>
                                <CardBody className="card_header_size_text">
                                    <div>
                                        Ребенок:
                                        <select className="form-select mt-1" value={selectChildren}
                                                onChange={event => setSelectChildren(event.target.value)}>
                                            {
                                                children.map(child =>
                                                    (<option key={child.id} value={child.id}>{child.name}</option>)
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="mt-2">
                                        <button className="btn btn-primary w-100" onClick={() => setOpenTransferMoney(true)}>Перевести деньги между лиц. счетами</button>
                                    </div>
                                </CardBody>
                            </Card>
                            <GraphDonutCashAccounting selectChildren={selectChildren} date={date}/>
                        </Col>
                        <Col xxl={9}>
                            <div>
                                <Card>
                                    <CardBody>
                                        <TableCashAccountingTotal date={date} selectChildren={selectChildren}/>
                                    </CardBody>
                                    <CardFooter>
                                        <Row>
                                            <Col xxl={3} lg={4}>
                                                <button className="btn btn-primary w-100 mt-1" onClick={() => {
                                                    //setGetReceipt(true)
                                                    //setTypeReceipt("receipt")
                                                    setAddAmountPayment(true)
                                                }}>Получить квитанцию</button>
                                            </Col>
                                            <Col xxl={3} lg={4}>
                                                {/*<button className="btn btn-primary w-100 mt-1" onClick={() => {*/}
                                                {/*    //setGetReceipt(true)*/}
                                                {/*    //setTypeReceipt("compare")*/}
                                                {/*   aktReconciliation()*/}
                                                {/*}}>Получить акт сверки</button>*/}
                                                <SubmitWithLoading className="btn btn-primary w-100 mt-1"
                                                                   onClick={() => aktReconciliation()}
                                                                   text={"Получить акт сверки"}/>
                                            </Col>
                                        </Row>
                                    </CardFooter>
                                </Card>
                            </div>
                            <div>
                                <Card>
                                    <TableCashAccounting date={date} selectChildren={selectChildren}/>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {
                openTransferMoney &&
                <ModalTransferMoneyPersonalAccounts openTransferMoney={openTransferMoney} setOpenTransferMoney={value => setOpenTransferMoney(value)} selectChildren={selectChildren}/>
            }
            <ModalAddAmountPayment selectChildren={selectChildren} addAmountPayment={addAmountPayment} setAddAmountPayment={(value) => setAddAmountPayment(value)}></ModalAddAmountPayment>
        </>
    );
};

export default CashAccounting;
