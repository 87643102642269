import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    setDateAdd, setGroupAllDeviations, setGroupModalAdd,
    setIsSend,
    setModalAdd,
    WidgetsSlice
} from "../../../store/reducers/widgets/WidgetsSlice";
import {Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import moment from "moment/moment";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import AxiosClient from "../../../api/AxiosClient";
import { getJournalHaccpGeneralCleaning } from "../../../store/reducers/widgets/ActionCreator";
import classNames from "classnames";
import * as Yup from "yup";
import UpdateObjectDesinfectinGeneralCleaning from "./UpdateObjectDesinfectinGeneralCleaning";
import UpdateDesinfectionGeneralCleaning from "./UpdateDesinfectionGeneralCleaning";
import Notification from "../../widgets/Notification";
import ModalConfirmExitHaccp from "../ModalConfirmExitHaccp";
import {useModalWithConfirm} from "../../../hooks/useModalWithConfirm";

interface IModalAddReportJournalHaccp{
    updateData: any
}

const ModalAddReportJournalVentilationRoom: FC<IModalAddReportJournalHaccp> = ({updateData}) => {
    const dispatch = useAppDispatch();
    const {modalAdd, groupAllDeviations, date, groupModalAdd, groups, dateAdd, successUpdate} = useAppSelector(state => state.WidgetReducer);

    const [timeStart, setTimeStart] = useState(moment().format('HH:mm'));
    const [timeEnd, setTimeEnd] = useState(moment().format('HH:mm'));
    const [activeTab, setActiveTab] = useState("1");
    const [schedule, setSchedule] = useState<any>([]);
    const [scheduleData, setScheduleData] = useState<any>();
    const [addMyDisinfect, setAddMyDisinfect] = useState<any>();
    const [updateObjectsDisinfection, setUpdateObjectsDisinfection] = useState<any[]>([]);

    const [selectSchedule, setSelectSchedule] = useState<any>(updateData? updateData.schedule : '');
    const [selectScheduleDate, setSelectScheduleDate] = useState<any>(updateData? updateData.data_plan : '');

    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<string>('');
    const [notificationMessage, setNotificationMessage] = useState<string>('');

    const {
        isFormChange,
        showConfirmExit,
        handleClose,
        handleFormChange,
        handleConfirmClose,
        handleStay,
    } = useModalWithConfirm("addReportJournalVentilationRoom");

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(WidgetsSlice.actions.successUpdate(""))
        }, 5000)
        return () => clearTimeout(timer)
    }, [successUpdate, dispatch]);

    useEffect(() => {
        if(modalAdd){
            AxiosClient.get('haccp/getScheduleList')
                .then((r: any) => {
                    setSchedule(r.data)
                    r.data && setSelectSchedule(r.data[0].uid)
                })
                .catch((error) => console.log(error))
        }
    }, [modalAdd])

    useEffect(() => {
        if(selectSchedule){
            const dataList : any = schedule.find((value: any) => value.uid === selectSchedule)?.data_list;
            setScheduleData(dataList);
            setSelectScheduleDate(dataList[0])
        }
    }, [selectSchedule]);

    useEffect(() => {
        if (updateData) setAddMyDisinfect(updateData.disinfectants)
    }, [updateData]);

    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    useEffect(() => {
        if (modalAdd && !updateData) {
            dispatch(setDateAdd(new Date()));
        }
    }, [modalAdd]);

    return (
        <>
        <Modal id="ModalAddReportJournalVentilationRoom" isOpen={modalAdd} toggle={handleClose}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={handleClose} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление параметров генеральной уборки
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        group: `${groupAllDeviations}`,
                        date: moment(dateAdd).format("DD-MM-YYYY"),
                        schedule: '',
                        scheduleDate: '',
                        timeStart: updateData? updateData.timeStart : '',
                        timeEnd: updateData? updateData.timeEnd : '',
                        isKwarc: false,
                        hot_water: false,
                        description: '',
                        disinfectants: addMyDisinfect,
                    }}
                    validationSchema={
                        Yup.object({
                            disinfectants: Yup.array().min(1, 'Требуется добавить дезинфицирующее средство')
                        })}
                    onSubmit={(value) => {
                        if (addMyDisinfect){
                            dispatch(setIsSend(true));
                            let prop = {
                                uid: updateData? updateData.uid : '',
                                number: updateData? updateData.number : '',
                                data: updateData? updateData.data : moment(dateAdd).format("DD-MM-YYYY"),
                                schedule: selectSchedule,
                                cabinet_uid : groupAllDeviations,
                                time: timeStart,
                                endtime: timeEnd,
                                data_plan: selectScheduleDate,
                                hot_water :  updateData? updateData.hot_water :'',
                                description: '',
                                disinfectants: addMyDisinfect,
                                listOfLamps: updateObjectsDisinfection
                            }
                            AxiosClient.post('haccp/saveJournalGeneralCleaning', prop)
                                .then((r: any) => {
                                    dispatch(getJournalHaccpGeneralCleaning(date, groupAllDeviations)).then();
                                    dispatch(setIsSend(false));
                                    dispatch(setModalAdd(false));
                                    setNotificationType('success');
                                    setNotificationMessage('Готово, журнал добавлен!');
                                    setShowNotification(true);
                                    handleConfirmClose();
                                })
                                .catch((error) => {
                                    dispatch(setIsSend(false));
                                    setNotificationType('error');
                                    setNotificationMessage('Ошибка, проверьте корректность данных!');
                                    setShowNotification(true);
                                })
                        }
                        else {

                            setNotificationType('error');
                            setNotificationMessage('Ошибка, требуется добавить дезинфицирующее средство!');
                            setShowNotification(true);
                        }
                    }}
                >
                    {({ errors, touched, setFieldValue }) => (
                    <Form onChange={handleFormChange}>
                        <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                             role="tablist">
                            <NavItem>
                                <NavLink
                                    className={classNames({active: activeTab === "1", "p-2": true})}
                                    onClick={() => {
                                        tabChange("1");
                                    }}
                                    type="button">
                                    <i className="fas fa-home"></i>
                                    Основное
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classNames({active: activeTab === "2", "p-2": true})}
                                    onClick={() => {
                                        tabChange("2");
                                    }}
                                    type="button">
                                    <i className="fas fa-home"></i>
                                    Дезифицирующие средства
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classNames({active: activeTab === "3", "p-2": true})}
                                    onClick={() => {
                                        tabChange("3");
                                    }}
                                    type="button">
                                    <i className="fas fa-home"></i>
                                    Бактерицидные лампы
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab} className="mt-2">
                            <TabPane tabId="1" className="align-items-center">
                                <div className="col-auto">
                                    <label>Дата:</label>
                                    <div className="col mb-3">
                                        <div className="input-group col-auto">
                                            <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                value={dateAdd}
                                                options={{
                                                    locale: Russian, dateFormat: "d M Y", disableMobile: true, mode: 'single'
                                                }}
                                                onChange={(date) => {
                                                    dispatch(setDateAdd(moment(date[0]).toDate()))
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className='w-100'>График:
                                        <Field as="select" className="form-select shadow"
                                               onChange={(e: any) => {
                                                   setSelectSchedule(e.target.value);
                                                   console.log('e')
                                               }} name="schedule">
                                            {schedule.map((value: any) => {
                                                return <option key={value.uid}
                                                               value={value.uid}>{value.name}</option>
                                            })}
                                        </Field>
                                    </label>
                                </div>
                                {selectSchedule && scheduleData &&
                                    <div className="mb-3">
                                        <label className='w-100'>Дата графика:
                                            <Field as="select" className="form-select shadow"
                                                   onChange={(e: any) => {
                                                       setSelectScheduleDate(e.target.value);
                                                       console.log('e')
                                                   }}
                                                   name="scheduleDate">
                                                {scheduleData.map((value: any, key: number) => {
                                                    return <option key={key}
                                                                   value={value}>{value}</option>
                                                })}
                                            </Field>
                                        </label>
                                    </div>}
                                <div className="mb-3">
                                    <label className="w-100">Группа:
                                        <Field as="select" className="form-select shadow" value={groupModalAdd}
                                               onChange={(e: any) => {
                                                dispatch(setGroupAllDeviations(e.target.value))
                                                dispatch(setGroupModalAdd(e.target.value))
                                               }} name="group">
                                            {groups.map((group) => {
                                                return <option key={group.group_id}
                                                               value={group.group_id}>{group.group_name}</option>
                                            })}
                                        </Field>
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label className='w-100'>Время начала уборки:
                                        <div className="col mb-3">
                                            <div className="input-group col-auto">
                                                <Flatpickr
                                                    className="form-control border-0 dash-filter-picker shadow"
                                                    value={timeStart}
                                                    options={{
                                                        locale: Russian,
                                                        enableTime: true,
                                                        noCalendar: true,
                                                        dateFormat: "H:i",
                                                        time_24hr: true,
                                                        disableMobile: true,
                                                        mode: 'single'
                                                    }}
                                                    onChange={(time) => setTimeStart(moment(time[0]).format('HH:mm'))}
                                                />
                                            </div>
                                        </div>
                                    </label>
                                </div>

                                <div className="mb-3">
                                    <label>Время окончания уборки:</label>
                                    <div className="col mb-3">
                                        <div className="input-group col-auto">
                                            <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                value={timeEnd}
                                                options={{
                                                    locale: Russian,
                                                    enableTime: true,
                                                    noCalendar: true,
                                                    dateFormat: "H:i",
                                                    time_24hr: true,
                                                    disableMobile: true,
                                                    mode: 'single'
                                                }}
                                                onChange={(time) => setTimeEnd(moment(time[0]).format('HH:mm'))}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="w-100">Описание:
                                        <Field name="description" className={`form-control`}/>
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label className='w-100'>Промыто горячей водой:
                                        <Field
                                            type="checkbox"
                                            className="form-check-input shadow"
                                            name="hot_water"
                                        />
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label className='w-100'>Было кварцевание:
                                        <Field
                                            type="checkbox"
                                            className="form-check-input shadow"
                                            name="isKwarc"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                if (e.target.checked)  tabChange("3");
                                                else {
                                                    const updatedData = updateObjectsDisinfection.map((item: any) => ({
                                                        ...item,
                                                        timeOfDisinfection: "00:00"
                                                    }));
                                                    setUpdateObjectsDisinfection(updatedData);
                                                }
                                                setFieldValue('isKwarc', e.target.checked);
                                            }}
                                        />
                                    </label>
                                </div>
                            </TabPane>
                            <TabPane tabId="2" className="align-items-center">
                                <UpdateDesinfectionGeneralCleaning addMyDisinfect={addMyDisinfect} setAddMyDisinfect={(value) => setAddMyDisinfect(value)}/>
                            </TabPane>
                            <TabPane tabId="3" className="align-items-center">
                                <UpdateObjectDesinfectinGeneralCleaning selectSchedule={selectSchedule} selectScheduleDate={selectScheduleDate} updateObjectsDisinfection={updateObjectsDisinfection} setUpdateObjectsDisinfection={(value) => setUpdateObjectsDisinfection(value)}/>
                            </TabPane>
                        </TabContent>

                        {errors.disinfectants && (
                            <div style={{ color: 'red', marginTop: '5px' }}>Требуется выбрать дезинфицирующее средство!</div>
                        )}
                        <div className="hstack gap-2 justify-content-end mt-1">
                            <SubmitWithLoading text={"Сохранить"}/>
                        </div>
                    </Form>

                    )}
                </Formik>
            </ModalBody>
        </Modal>
            <ModalConfirmExitHaccp
                isOpen={showConfirmExit}
                onClose={handleConfirmClose}
                onStay={handleStay}
                hasUnsavedChanges={isFormChange}
            />
        {showNotification && <Notification type={notificationType} message={notificationMessage}
                                       setShowNotification={(value: boolean) => setShowNotification(value)}/>}
    </>

);
};

export default ModalAddReportJournalVentilationRoom;
