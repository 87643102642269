import React, {FC} from 'react';
import {Collapse, Modal, ModalBody, ModalHeader} from "reactstrap";
import moment from "moment/moment";
import {useAppDispatch} from "../../hooks/redux";
import {useEscapeKey} from "../../hooks/useEscapeKey";
import {setModalAdd} from "../../store/reducers/widgets/WidgetsSlice";

interface IHelpWindows {
    helpWindow: boolean,
    setHelpWindows: (value: boolean) => void
}



const HelpWindows: FC <IHelpWindows> = ({helpWindow, setHelpWindows}) => {
    const dispatch = useAppDispatch();
    useEscapeKey(() => dispatch(setModalAdd(false)), helpWindow, "helpWindow");
    return (
        <Modal id="helpWindow" isOpen={helpWindow}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader
                toggle={() => setHelpWindows(!helpWindow)}
                className="p-3 bg-soft-primary"
                id="createProjectModalLabel">
                Ошибка
            </ModalHeader>
            <ModalBody>
                <div>
                    <p className="fs-20"></p>
                </div>
                <div>
                    <p className="fs-16">Сообщение не отправлено. Обратитесь на горячую линию: </p>
                    <Collapse isOpen={true} сlassName="accordion-collapse">
                        <div className="accordion-body fs-16">
                            61-38-63
                        </div>
                    </Collapse>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default HelpWindows;
