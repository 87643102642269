import React, {FC, useState} from 'react';
import DataTable from "react-data-table-component";
import AddDisinfectantGeneralCleaning from "./AddDisinfectantGeneralCleaning";

interface  IUpdateDesinfectionGeneralCleaning{
    addMyDisinfect: any,
    setAddMyDisinfect(value: any): void
}

const UpdateDesinfectionGeneralCleaning: FC <IUpdateDesinfectionGeneralCleaning> = ({addMyDisinfect, setAddMyDisinfect}) => {
    const [updateDisinfect, setUpdateDisinfect] = useState(false);
    const [selectDisinfect, setSelectDisinfect] = useState<any | null>(null);

    const columns : any = [
        {
            id: "disinfectant_name",
            name: <span className='font-weight-bold accessibility_size_table'>Наименование</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.disinfectant_name}</span>,
            wrap: true,
        },
        {
            id: "concentration",
            name: <span className='font-weight-bold accessibility_size_table'>Концентрация</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.concentration}</span>,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'></span>,
            cell: (row: any) => <i className="bx bx-trash fs-24 cursor-pointer" onClick={() => {removeDisinfect(row.uid)}}></i>,
            width: '60px'
        }
    ]

    const removeDisinfect = (uid: string) => {
        setAddMyDisinfect(addMyDisinfect.filter((item: any) => item.uid !== uid));
    }

    return (
        <>
            <DataTable
                columns={columns}
                data={addMyDisinfect}
                noDataComponent={'Нет данных'}
                pagination
                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                paginationComponentOptions={
                    {
                        rowsPerPageText: 'Видно льгот:',
                        rangeSeparatorText: 'из'
                    }
                }
                highlightOnHover
                customStyles={{
                    rows: {
                        highlightOnHoverStyle: {
                            backgroundColor: "#ffebb5"
                        },
                    },
                    expanderRow: {
                        style: {
                            backgroundColor: "#ffebb5",
                            paddingTop: '5px',
                            paddingBottom: '5px'
                        },
                    },
                }}
                onRowClicked={(row) => {
                    setSelectDisinfect(row);
                    setUpdateDisinfect(true);
                }}
            />
            <button className="btn btn-primary" type="button"
                    onClick={() => {
                        setSelectDisinfect(null)
                        setUpdateDisinfect(true)}}>Добавить
            </button>
            <AddDisinfectantGeneralCleaning updateDisinfect={updateDisinfect}
                                            setUpdateDisinfect={(value) => setUpdateDisinfect(value)}
                                            addMyDisinfect={addMyDisinfect}
                                            setAddMyDisinfect={(value: any) => setAddMyDisinfect(value)}
                                            selectDisinfect={selectDisinfect}/>
        </>
    );
};

export default UpdateDesinfectionGeneralCleaning;
