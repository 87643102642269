import React, {useEffect, useState} from 'react';
import AxiosClient from "../../../api/AxiosClient";
import DataTable, {ConditionalStyles} from "react-data-table-component";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import Select from "react-select";
import {customStyles} from "../../settings";
import ListPersonalAccount from "../Students/ListPersonalAccount";
import {Russian} from "flatpickr/dist/l10n/ru";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import {useAppDispatch} from "../../../hooks/redux";
import {setIsSend} from "../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import Notification from "../../widgets/Notification";
import useDebounce from "../../../hooks/useDebounce";

const ListStudents = () => {
    const [students, setStudents] = useState<any[]>([])
    const [schools, setSchools] = useState<any[]>([])
    const [selectSchool, setSelectSchool] = useState<any>({ value: '', label: 'Не выбрано' })
    const [selectBuild, setSelectBuild] = useState<any>({ value: '', label: 'Не выбрано' })
    const [selectClass, setSelectClass] = useState<any>({ value: '', label: 'Не выбрано' })
    const [selectParallel, setSelectParallel] = useState<any>({ value: '', label: 'Не выбрано' })
    const [selectTypeBenefit, setSelectTypeBenefit] = useState<any>({ value: '', label: 'Не выбрано' })
    const [builds, setBuilds] = useState<any[]>([])
    const [classes, setClasses] = useState<any[]>([])
    const [personalAccount, setPersonalAccount] = useState('')
    const [fio, setFio] = useState('');
    const [dateBegin, setDateBegin] = useState(new Date());
    const [dateOpen, setDateOpen] = useState(new Date());
    const [dateClose, setDateClose] = useState<string | Date>('');
    const [parallel, setParallel] = useState<any[]>([]);
    const [typeBenefit, setTypeBenefit] = useState<any[]>([]);
    const [benefits, setBenefits] = useState<any[]>([]);
    const [selectBenefit, setSelectBenefit] = useState({ value: '', label: 'Не выбрано' });
    const [plannedMenu, setPlannedMenu] = useState<any[]>([]);
    const [selectPlannedMenu, setSelectPlannedMenu] = useState({ value: '', label: 'Не выбрано', idPlan: '' });
    const [checkAll, setCheckAll] = useState(false);
    const [error, setError] = useState('');
    const [isRemove, setIsRemove] = useState(false);
    const [isClose, setIsClose] = useState(false);
    const [ageCategories, setAgeCategories] = useState<any[]>([]);
    const [selectAgeCategory, setSelectAgeCategory] = useState({value: '', label: 'Не выбрано'});
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [loadingOptionsBuildings, setLoadingOptionsBuildings] = useState(false);
    const [pageOption, setPageOption] = useState(1);
    const [totalOptions, setTotalOptions] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState(false);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<string>('');
    const [notificationMessage, setNotificationMessage] = useState<string>('');
    const [selectCopyTypeBenefit, setSelectCopyTypeBenefit] = useState({value: '', label: 'Не выбрано'});
    const dispatch = useAppDispatch();
    const debouncePersonalAccount = useDebounce(personalAccount, 500)
    const debounceFio = useDebounce(fio, 500)
    useEffect(() => {
        fetchStudents(1)
        fetchMoreSchools(1)
            .then((r) => {
                setPageOption(state => state + 1)
                setSchools(r)
            })
        AxiosClient.get('/parallel_classes')
            .then((r) => {
                setParallel(r.data)
            })
        AxiosClient.get('/statuses')
            .then((r) => {
                setTypeBenefit(r.data)
            })
        AxiosClient.get('/benefits')
            .then((r) => {
                setBenefits(r.data)
            })
        AxiosClient.get('/age_categories')
            .then((r) => {
                setAgeCategories(r.data)
            })
            .catch(() => console.log('error'))
    }, [])

    useEffect(() => {
        search &&
        fetchStudents(currentPage, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, fio, dateBegin, selectParallel.value, selectTypeBenefit.value);
    }, [debouncePersonalAccount]);

    useEffect(() => {
        search &&
        fetchStudents(currentPage, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, fio, dateBegin, selectParallel.value, selectTypeBenefit.value);
    }, [debounceFio]);

    const onChangeDateBegin = (date: Date) => {
        fetchStudents(currentPage, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, fio, date, selectParallel.value, selectTypeBenefit.value);
        setDateBegin(date)
    }

    const onChangeSchool = async (data: { value: string, label: string }) => {
        setSelectBuild({value: '', label: 'Не выбрано'})
        setSelectClass({value: '', label: 'Не выбрано'})
        if (data.value){
            AxiosClient.get(`schools/${data.value}/catering_organizers/type_planned_menu`)
                .then(r => {
                    setPlannedMenu(r.data.catering_organizer?.catering_organizer_type_planned_menu.map((el: any) => ({
                        ...el.type_planned_menu,
                        idPlan: el.id
                    })))
                })
        } else {
            setPlannedMenu([])
        }

        fetchBuildings(data.value)
            .then(r => setBuilds(r))
        setSelectSchool(data)
        await fetchStudents(currentPage, perPage, data.value, '', '', personalAccount, fio, dateBegin, selectParallel.value, selectTypeBenefit.value);
    }

    const onChangeBuilding = async (data: { value: string, label: string }) => {
        setSelectClass({value: '', label: 'Не выбрано'})
        const sortClass = builds?.find(s => s.id.toString() === data.value.toString())?.class
        setSelectBuild(data)
        setClasses(sortClass)
        await fetchStudents(currentPage, perPage, selectSchool.value, data.value, '', personalAccount, fio, dateBegin, selectParallel.value, selectTypeBenefit.value);
    }

    const onChangeClass = async (data: { value: string, label: string }) => {
        setSelectClass(data)
        await fetchStudents(currentPage, perPage, selectSchool.value, selectBuild.value, data.value, personalAccount, fio, dateBegin, selectParallel.value, selectTypeBenefit.value);
    }

    const fetchMoreSchools = async (page: number) => {
        setLoadingOptions(true)
        const response = await AxiosClient.get(`/schools`, {params: {page: page, per_page: 10}})
        setLoadingOptions(false)
        setTotalOptions(response?.data.total)
        return response?.data.schools
    };

    const fetchBuildings = async (selectSchool: string) => {
        if(selectSchool) {
            setLoadingOptionsBuildings(true)
            const response = await AxiosClient.get(`/schools/${selectSchool}/buildings/classes`)
            setLoadingOptionsBuildings(false)
            return response?.data
        }
        return []
    };

    const loadMoreData = async () => {
        fetchMoreSchools(pageOption)
            .then(r => {
                setPageOption(pageOption => pageOption + 1)
                setSchools(prevOptions => [...prevOptions, ...r]);
            })
    };

    const handleMenuScroll = async (event: any) => {
        const target = event.target;
        if (totalOptions !== schools.length && target.scrollHeight - target.scrollTop === target.clientHeight) {
            await loadMoreData();
        }
    };

    const onChangeSelectParallel = async (data: {value: string, label: string}) => {
        await fetchStudents(currentPage, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, fio, dateBegin, data.value, selectTypeBenefit.value);
        setSelectParallel(data)
    }

    const onChangeTypeBenefit = async (data: {value: string, label: string}) => {
        await fetchStudents(currentPage, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, fio, dateBegin, selectParallel.value, data.value);
        setSelectTypeBenefit(data)
    }

    const fetchStudents = async (page: number, size = perPage, schoolId = '', buildingId = '', classId = '', personalAccount = '', fio = '', dateBegin = new Date(), selectParallel = '', typeBenefit = '') => {
        setSearch(true)
        await AxiosClient.get(`/students`, {params: {page: page, per_page: size, typeBenefit, selectParallel, fio, schoolId, buildingId, classId, personalAccount, dateBegin: moment(dateBegin).format('YYYY-MM-DD')}})
            .then((response) => {
                setSearch(false)
                if (checkAll){
                    setStudents(response.data.students.map((el: any) => ({...el, checked: true})));
                } else {
                    setStudents(response.data.students);
                }
                setTotalRows(response.data.total);
            })
            .catch(() => {
                console.log('error')
                setSearch(false)
            });

    };

    const handlePageChange = (page: number) => {
        fetchStudents(page, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, fio, dateBegin, selectParallel.value, selectTypeBenefit.value);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        await fetchStudents(page, newPerPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, fio, dateBegin, selectParallel.value, selectTypeBenefit.value);
        setPerPage(newPerPage);
    };

    const conditionalRowStyles: ConditionalStyles<any>[] = [
        {
            when: (row: any) => !row.personal_accounts.find((pa: any) => pa.date_end === null),
            style: {
                color: 'gray'
            },
        },
    ]


    const columns = [
        {
            id: "date",
            name: <span className='font-weight-bold accessibility_size_table'>ФИО</span>,
            cell: (row: any) => <span className="accessibility_size_table">{`${row.last_name} ${row.name} ${row.middle_name}`}</span>,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Школа</span>,
            cell: (row: any) => {
                const personalAccount = row.personal_accounts.find((pa: any) => pa.date_end === null)
                if (personalAccount)
                    return <span className="accessibility_size_table">{personalAccount.school.name}</span>
                else
                    return <i className="bx bx-minus"></i>
            },
            hide: 599,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Корпус</span>,
            cell: (row: any) => {
                const personalAccount = row.personal_accounts.find((pa: any) => pa.date_end === null)
                if (personalAccount)
                    return <span className="accessibility_size_table">{personalAccount.building.name}</span>
                else
                    return <i className="bx bx-minus"></i>
            },
            hide: 959,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Класс</span>,
            cell: (row: any) => {
                const personalAccount = row.personal_accounts.find((pa: any) => pa.date_end === null)
                if (personalAccount)
                    return <span className="accessibility_size_table">{personalAccount.class.name}</span>
                else
                    return <i className="bx bx-minus"></i>
            },
            hide: 959,
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Сумма льгот</span>,
            cell: (row: any) => {
                const personalAccount = row.personal_accounts.find((pa: any) => pa.date_end === null)
                if (personalAccount)
                    return <span className="accessibility_size_table">{personalAccount.benefits.map((b: any) => b.pivot.sum ? +b.pivot.sum : 0).reduce((a: any, b: any) => a + b, 0) + " р"}</span>
                else
                    return <i className="bx bx-minus"></i>
            },
            hide: 1280
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Сумма питания</span>,
            cell: (row: any) => {
                const personalAccount = row.personal_accounts.find((pa: any) => pa.date_end === null)
                if (personalAccount)
                    return personalAccount.catering_organizer_type_planned_menu?.map((b: any) => b.price ? +b.price : 0)?.reduce((a: any, b: any) => a + b, 0) + " р"
                else
                    return <i className="bx bx-minus"></i>
            },
            hide: 1280
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'><input type="checkbox" checked={checkAll} onChange={(event) => {
                setCheckAll(event.target.checked)
                setStudents(students.map((el: any) => ({...el, checked: event.target.checked})))
            }} className="fs-18 form-check-input"/></span>,
            cell: (row: any) => <input type="checkbox" checked={row.checked} onChange={(event) => setStudents(students.map((el: any) => {
                if (el.id === row.id){
                    return {...el, checked: event.target.checked}
                }
                return el
            }))} className="fs-18 form-check-input"/>,
            width: '60px'
        },
    ]

    const onChangeBenfit = (data: {value: string, label: string}) => {
        setSelectBenefit(data)
        const statuses = benefits.find(el => el.id.toString() === data.value.toString()).statuses
        if (statuses.length === 1){
            setSelectCopyTypeBenefit({value: statuses[0].id, label: statuses[0].name})
        }
    }

    const onChangeCopy = () => {
        if(!selectBenefit.value && !selectPlannedMenu.value && !selectAgeCategory.value && !selectCopyTypeBenefit.value){
            setError('Выберите элемент копирования (льгота или плановое меню или возрастную категорию или категория льготы)');
            return
        }
        const sendStudents = students.filter((el: any) => el.checked)
        if (sendStudents.length < 1){
            setError('Выберите студентов');
            return
        }
        setError('');
        const priceBenefit = benefits.find((el: any) => el.id.toString() === selectBenefit.value.toString())?.price
        dispatch(setIsSend(true))
        AxiosClient.post('/students/copy/benefit_planned_menu', {isClose, students: sendStudents, typeBenefit: selectCopyTypeBenefit.value, ageCategory: selectAgeCategory.value, benefit: selectBenefit.value, typePlannedMenu: selectPlannedMenu.idPlan, isRemove, priceBenefit, dateStart: moment(dateOpen).format('YYYY-MM-DD').toString(), dateEnd: typeof dateClose !== "string" ? moment(dateClose).format('YYYY-MM-DD').toString() : null})
            .then(() => {
                dispatch(setIsSend(false))
                setNotificationType('success');
                setNotificationMessage('Данные скопированы!');
                setShowNotification(true);
                fetchStudents(currentPage, perPage, selectSchool.value, selectBuild.value, selectClass.value, personalAccount, fio, dateBegin, selectParallel.value, selectTypeBenefit.value);
            })
            .catch((reason) => {
                dispatch(setIsSend(false))
                if (reason.response.status === 422) {
                    setNotificationType('error');
                    setNotificationMessage(reason.response.data);
                } else {
                    setNotificationType('error');
                    setNotificationMessage('Ошибка сервера');
                }
                setShowNotification(true);
            })
    }

    return (
        <>
            <Row>
                <Col sm={12} lg={6} xl={3} xxl={3} className="mt-2">
                    Дата с:
                    <Flatpickr
                        className="form-control border-0 dash-filter-picker shadow"
                        value={dateBegin}
                        options={{
                            locale: Russian, dateFormat: "d M Y", disableMobile: true,
                        }}
                        onChange={(date) => onChangeDateBegin(date[0])}
                    />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={3} className="mt-2">
                    Школа:
                    <Select
                        options={schools?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...schools.map(v => ({value: v.id, label: v.name}))] : [{value: '', label: 'Не выбрано'}]}
                        value={selectSchool}
                        onChange={(change: any) => {
                            onChangeSchool({value: change.value, label: change.label})
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                        isLoading={loadingOptions}
                        onMenuScrollToBottom={handleMenuScroll}
                    />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={3} className="mt-2">
                    Корпус:
                    <Select
                        options={builds?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...builds?.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                        value={selectBuild}
                        onChange={(change: any) => {
                            onChangeBuilding({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={3} className="mt-2">
                    Класс:
                    <Select
                        options={classes?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...classes?.map(v => ({ value: v.id, label: v.name }))] : [{
                            value: '',
                            label: 'Не выбрано'
                        }]}
                        value={selectClass}
                        onChange={(change: any) => {
                            onChangeClass({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={3} className="mt-2">
                    Лицевой счет:
                    <input type="text" className="form-control" value={personalAccount} onChange={event => {
                        setPersonalAccount(event.target.value)
                        setSearch(true)
                    }} />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={3} className="mt-2">
                    ФИО ребенка:
                    <input type="text" className="form-control" value={fio} onChange={event => {
                        setFio(event.target.value)
                        setSearch(true)
                    }} />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={3} className="mt-2">
                    Параллель:
                    <Select
                        options={parallel?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...parallel?.map(v => ({ value: v.id, label: v.name }))] : [{
                            value: '',
                            label: 'Не выбрано'
                        }]}
                        value={selectParallel}
                        onChange={(change: any) => {
                            onChangeSelectParallel({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                </Col>
                <Col sm={12} lg={6} xl={3} xxl={3} className="mt-2">
                    Категория льготы:
                    <Select
                        options={typeBenefit?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...typeBenefit?.map(v => ({ value: v.id, label: v.name }))] : [{
                            value: '',
                            label: 'Не выбрано'
                        }]}
                        value={selectTypeBenefit}
                        onChange={(change: any) => {
                            onChangeTypeBenefit({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mt-3">
                        <CardHeader>
                            <Row>
                                <Col sm={12} lg={6} xl={3} xxl={3} className="mt-2">
                                    Льгота:
                                    <Select
                                        options={benefits?.length > 0 ? [{
                                            value: '',
                                            label: 'Не выбрано'
                                        }, ...benefits.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                                        value={selectBenefit}
                                        onChange={(change: any) => {
                                            onChangeBenfit({ value: change.value, label: change.label })
                                        }}
                                        styles={customStyles}
                                        placeholder="Поиск"
                                        name="name"
                                    />
                                </Col>
                                <Col sm={12} lg={6} xl={3} xxl={3} className="mt-2">
                                    Категория льгот:
                                    <Select
                                        options={typeBenefit?.length > 0 ? [{
                                            value: '',
                                            label: 'Не выбрано'
                                        }, ...typeBenefit.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                                        value={selectCopyTypeBenefit}
                                        onChange={(change: any) => {
                                            setSelectCopyTypeBenefit({ value: change.value, label: change.label })
                                        }}
                                        styles={customStyles}
                                        placeholder="Поиск"
                                        name="name"
                                    />
                                </Col>
                                <Col sm={12} lg={6} xl={3} xxl={3} className="mt-2">
                                    Плановое меню:
                                    <Select
                                        options={plannedMenu?.length > 0 ? [{
                                            value: '',
                                            label: 'Не выбрано',
                                            idPlan: ''
                                        }, ...plannedMenu.map(v => ({ value: v.id, label: v.name, idPlan: v.idPlan }))] : [{ value: '', label: 'Не выбрано', idPlan: '' }]}
                                        value={selectPlannedMenu}
                                        onChange={(change: any) => {
                                            setSelectPlannedMenu({ value: change.value, label: change.label, idPlan: change.idPlan })
                                        }}
                                        styles={customStyles}
                                        placeholder="Поиск"
                                        name="name"
                                    />
                                </Col>
                                <Col sm={12} lg={6} xl={3} xxl={3} className="mt-2">
                                    Возрастная категория:
                                    <Select
                                        options={ageCategories?.length > 0 ? [{
                                            value: '',
                                            label: 'Не выбрано'
                                        }, ...ageCategories.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                                        value={selectAgeCategory}
                                        onChange={(change: any) => {
                                            setSelectAgeCategory({ value: change.value, label: change.label })
                                        }}
                                        styles={customStyles}
                                        placeholder="Поиск"
                                        name="name"
                                    />
                                </Col>
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={12} lg={6} xl={3} xxl={3} className="mt-2">
                                            Дата с:
                                            <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                value={dateOpen}
                                                options={{
                                                    locale: Russian, dateFormat: "d M Y", disableMobile: true,
                                                }}
                                                onChange={(date) => setDateOpen(date[0])}
                                            />
                                        </Col>
                                        <Col sm={12} lg={6} xl={3} xxl={3} className="mt-2">
                                            Дата по:
                                            <div className="position-relative">
                                                <Flatpickr
                                                    className="form-control border-0 dash-filter-picker shadow"
                                                    value={dateClose}
                                                    options={{
                                                        locale: Russian, dateFormat: "d M Y", disableMobile: true,
                                                    }}
                                                    onChange={(date) => setDateClose(date[0])}
                                                />
                                                <div title="Удалить дату" onClick={() => setDateClose('')} className="position-absolute cursor-pointer bg-primary rounded-end text-white fs-3 top-0 end-0 h-100 d-flex justify-content-center align-items-center" style={{width: 40}}>
                                                    <i className="ri ri-close-line"></i>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={12} lg={3} xl={2} xxl={1} className="d-flex align-items-end mt-2">
                                            <div className="d-flex align-items-center">
                                                Заменить:&nbsp;&nbsp;
                                                <input type="checkbox" checked={isRemove} onChange={(event) => setIsRemove(event.target.checked)} className="fs-18 form-check-input"/>
                                            </div>
                                        </Col>
                                        <Col sm={12} lg={3} xl={2} xxl={2} className="d-flex align-items-end mt-2">
                                            <div className="d-flex align-items-center" title="Закрыть льготы, плановое меню меню и категорию льгот льгот">
                                                Закрыть периоды:&nbsp;&nbsp;
                                                <input type="checkbox" checked={isClose} onChange={(event) => setIsClose(event.target.checked)} className="fs-18 form-check-input"/>
                                           </div>
                                        </Col>
                                        <Col className="">
                                            <div className="mt-4">
                                                <SubmitWithLoading type={"button"} onClick={() => onChangeCopy()} text={"Копировать"}/>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {error && <span className="text-danger">{error}</span>}
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={students}
                                noDataComponent={'Нет данных'}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                paginationDefaultPage={currentPage}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                paginationRowsPerPageOptions={[10, 50, 100]}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно учеников:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        },
                                    },
                                    expanderRow: {
                                        style: {
                                            backgroundColor: "#ffebb5",
                                            paddingTop: '5px',
                                            paddingBottom: '5px'
                                        },
                                    },
                                }}
                                conditionalRowStyles={conditionalRowStyles}
                                expandableRows
                                expandOnRowClicked
                                expandableIcon={{
                                    collapsed: <i className="bx bx-user fs-3"></i>,
                                    expanded: <i className="bx bx-user fs-3"></i>
                                }}
                                expandableRowsComponent={(row) => {
                                    return <ListPersonalAccount
                                        setStudents={value => {
                                            setStudents(value.students);
                                            setTotalRows(value.total);
                                        }}
                                        listPersonalAccount={row.data.personal_accounts.map((v: any) => ({
                                            ...v,
                                            name: row.data.name,
                                            last_name: row.data.last_name,
                                            middle_name: row.data.middle_name,
                                            type_menu_id: row.data.type_menu_id
                                        }))} />
                                }}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {showNotification && <Notification type={notificationType} message={notificationMessage} setShowNotification={(value: boolean) => setShowNotification(value)}/>}
        </>
    )
};

export default ListStudents;
