import React, {FC, useEffect, useState} from 'react';
import {Badge, Col, Collapse, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row} from "reactstrap";

import ListShots from "../constructor/ListShots";
import AxiosClient from "../../../api/AxiosClient";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {IGame} from "../../../models/game/IGame";
import classNames from "classnames";
import {IShot} from "../../../models/game/IShot";
import {IBackgroundImage} from "../../../models/game/IBackgroundImage";
import {IBackgroundSound} from "../../../models/game/IBackgroundSound";
import bg from '../../../assetsGame/RPG/bgStartEnd.png'
import bgsound from "../../../assetsGame/sounds/bgsoundmenu.mp3";

interface IProps {
    isOpen: boolean,
    setIsOpen: (value: boolean) => void,
    dataGame: IGame | null,
    toggleSave: (game: IGame) => void,
    toggleAddShotSave: () => void,
    handleOpenSettings: () => void,
    copyShot: () => void,
    setSelectSettingsShot: (shot: IShot) => void,
    saveShot: () => void,
    deleteShot: () => void,
}

const RightMenu: FC<IProps> = ({
                                   isOpen,
                                   setIsOpen,
                                   dataGame,
                                   toggleSave,
                                   toggleAddShotSave,
                                   handleOpenSettings,
                                   copyShot,
                                   setSelectSettingsShot,
                                   saveShot,
                                   deleteShot,
                               }) => {
    const [game, setGame] = useState(dataGame)
    const [addClass, setAddClass] = useState(false)
    const [nameClass, setNameClass] = useState('')
    const [listClass, setListClass] = useState<any[]>([])
    const [open, setOpen] = useState('')

    const [backgroundImages, setBackgroundImages] = useState<IBackgroundImage[]>([])
    const [selectedbackgroundImages, setselectedbackgroundImages] = useState<IBackgroundImage[]>([])

    const [backgroundSounds, setBackgroundSounds] = useState<IBackgroundSound[]>([]);
    const [selectedbackgroundSounds, setselectedBackgroundSounds] = useState<IBackgroundSound[]>([]);

    const [OpenFileMusic, setOpenFileMusic] = useState(false)
    const [UpdateFileMusic, setUpdateFileMusic] = useState(false)
    const [fileMusic, setFileMusic] = useState({id: 0, name: 'По умолчанию', path: bgsound})

    const [OpenFileFile, setOpenFileFile] = useState(false)
    const [UpdateFileFile, setUpdateFileFile] = useState(false)
    const [OblozhkaFile, setOblozhkaFile] = useState(bg)

    useEffect(() => {
    if(selectedbackgroundImages !== backgroundImages) {
        AxiosClient.get<IBackgroundImage[]>('/game/backgroundImage')
            .then((r) => {
                setBackgroundImages(r.data)
            })
        setselectedbackgroundImages(backgroundImages)
    }
        if(selectedbackgroundSounds !== backgroundSounds) {
            AxiosClient.get<IBackgroundSound[]>('/game/backgroundAudio')
                .then((r) => {
                    setBackgroundSounds(r.data)
                })
            setselectedBackgroundSounds(backgroundSounds)
        }
    }, []);

    useEffect(() => {
        if(game) {
            if (game.classes) {
                setListClass(game.classes)
            }
        }
    }, [game])

    useEffect(() => {
        setGame(dataGame)
    }, [dataGame]);

        const toggle = (id: any) => {
        if (open !== id) {
            setOpen(id);
        } else {
            setOpen('')
        }
    }

    const backimage = () => {
        if (!game) return OblozhkaFile;
        if (!game.file) return OblozhkaFile;
        if (UpdateFileFile && game.file) return OblozhkaFile
        if (!UpdateFileFile && game.file) return game.file
    }

    const backsound = () => {
        if (!game) return {id: fileMusic.id, name: fileMusic.name, path: fileMusic.path}
        if (!game.file_music) return {id: fileMusic.id, name: fileMusic.name, path: fileMusic.path}
        if (UpdateFileMusic && game.file_music) return {id: fileMusic.id, name: fileMusic.name, path: fileMusic.path}
        if (!UpdateFileMusic && game.file_music) return {id: 100, name: 'Выбраная музыка', path: game.file_music}
        return {id: 0, name: '', path: ''}
    }

    return (
        <Offcanvas
            autoFocus
            isOpen={isOpen}
            backdrop={false}
            scrollable
            direction='end'
        >
            <OffcanvasHeader toggle={() => setIsOpen(!isOpen)}>
                Настройки игры
            </OffcanvasHeader>
            <OffcanvasBody>
                <div>
                    <div className="accordion" id="genques-accordion">
                        <Formik
                            enableReinitialize
                            initialValues={{
                                name: game?.name ?? '',
                                fio: game?.fio ?? '',
                                ageGroup: game?.ageGroup ?? '',
                                objective: game?.objective ?? '',
                                integrationEducationRegion: game?.integrationEducationRegion ?? '',
                                educationTask: game?.educationTask ?? '',
                                evolution: game?.evolution ?? '',
                                education: game?.education ?? '',
                                gameTask: game?.gameTask ?? '',
                                wordsWork: game?.wordsWork ?? '',
                                planResult: game?.planResult ?? '',
                                preparatoryWork: game?.preparatoryWork ?? '',
                                file: game?.file ?? '',
                                file_music: game?.file_music ?? '',
                                private: !!game?.private,
                                password: game?.password ?? '',
                                timer: !!game?.timer,
                                closeTimer: !!game?.closeTimer,
                                time: game?.time ?? '00:00:00',
                                isOrganization: game?.isOrganization ?? false,
                                is_inventar: !!game?.is_inventar,
                                is_rpg: !!game?.is_rpg,
                            }}
                            onSubmit={(values, {setFieldError}) => {
                                if (values.private) {
                                    AxiosClient.post('/game/check_password', {password: values.password})
                                        .then(() => {
                                            const element = document.getElementById('window')
                                            if (element) {
                                                const width = element?.offsetWidth
                                                const height = element?.offsetHeight
                                                game &&
                                                toggleSave({...game, ...values, width, height, shots: game?.shots ?? [], classes: listClass})
                                                setOpen('3')
                                            }
                                        })
                                        .catch(() => {
                                            setFieldError('password', 'Создайте другой пароль доступа к игре')
                                        })
                                } else {
                                    const element = document.getElementById('window')
                                    if (element) {
                                        const width = element?.offsetWidth
                                        const height = element?.offsetHeight
                                        game &&
                                        toggleSave({...game, ...values, width, height, shots: game?.shots ?? [], classes: [], password: ''})
                                        setOpen('3')
                                    }
                                }
                            }}
                        >
                            {
                                ({setFieldValue, values}) => (
                                    <Form>
                                        <div className="accordion-item border-0 border-bottom">
                                            <h2 className="accordion-header" id="genques-headingTwo">
                                                <button
                                                    className={classNames(
                                                        'accordion-button fw-medium bg-white text-black',
                                                        {
                                                            collapsed: open !== '1'
                                                        }
                                                    )}
                                                    type="button"
                                                    onClick={() => toggle('1')}
                                                    style={{cursor: "pointer"}}
                                                >
                                                    Общие настройки игры
                                                </button>
                                            </h2>
                                            <Collapse isOpen={open === '1'} className="accordion-collapse">
                                                <div className="accordion-body p-0 text-black fw-normal py-2">
                                                    <Row>
                                                        <Col sm={12}>
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <label htmlFor="name" className="form-label">Название
                                                                        игры</label>
                                                                    <Field type="text" id="name" name="name"
                                                                           className="form-control"/>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <label htmlFor="fio"
                                                                           className="form-label">ФИО</label>
                                                                    <Field type="text" id="fio" name="fio"
                                                                           className="form-control"/>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <label htmlFor="ageGroup" className="form-label">Возрастная
                                                                        группа</label>
                                                                    <Field as="textarea" id="ageGroup" name="ageGroup"
                                                                           className="form-control"/>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <label htmlFor="objective" className="form-label">Цель
                                                                        игры</label>
                                                                    <Field as="textarea" id="objective" name="objective"
                                                                           className="form-control"/>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <label htmlFor="integrationEducationRegion"
                                                                           className="form-label">Интеграция
                                                                        образовательных областей</label>
                                                                    <Field as="textarea" id="integrationEducationRegion"
                                                                           name="integrationEducationRegion"
                                                                           className="form-control"/>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <label htmlFor="educationTask"
                                                                           className="form-label">Образовательная
                                                                        задача</label>
                                                                    <Field as="textarea" id="educationTask"
                                                                           name="educationTask"
                                                                           className="form-control"/>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <label htmlFor="evolution" className="form-label">Развивающая
                                                                        задача</label>
                                                                    <Field as="textarea" id="evolution" name="evolution"
                                                                           className="form-control"/>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <label htmlFor="education" className="form-label">Воспитательная
                                                                        задача</label>
                                                                    <Field as="textarea" id="education" name="education"
                                                                           className="form-control"/>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <label htmlFor="gameTask" className="form-label">Игровая
                                                                        задача</label>
                                                                    <Field as="textarea" id="gameTask" name="gameTask"
                                                                           className="form-control"/>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <label htmlFor="wordsWork" className="form-label">Словарная
                                                                        работа</label>
                                                                    <Field as="textarea" id="wordsWork" name="wordsWork"
                                                                           className="form-control"/>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <label htmlFor="planResult" className="form-label">Планируемый
                                                                        результат</label>
                                                                    <Field as="textarea" id="planResult"
                                                                           name="planResult"
                                                                           className="form-control"/>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <label htmlFor="preparatoryWork"
                                                                           className="form-label">Подготовительная
                                                                        работа</label>
                                                                    <Field as="textarea" id="preparatoryWork"
                                                                           name="preparatoryWork"
                                                                           className="form-control"/>
                                                                </Col>
                                                                <Row>
                                                                    <Col>
                                                                        <div className="mt-1 gap-2 d-flex">
                                                                            <button className="btn btn-light w-100" type="submit">
                                                                                Сохранить
                                                                            </button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Collapse>
                                        </div>
                                        <div className="accordion-item border-0 border-bottom">
                                            <h2 className="accordion-header" id="genques-headingTwo">
                                                <button
                                                    className={classNames(
                                                        'accordion-button fw-medium bg-white text-black',
                                                        {
                                                            collapsed: open !== '2'
                                                        }
                                                    )}
                                                    type="button"
                                                    onClick={() => toggle('2')}
                                                    style={{cursor: "pointer"}}
                                                >
                                                    Игровые настройки
                                                </button>
                                            </h2>
                                            <Collapse isOpen={open === '2'} className="accordion-collapse">
                                                <div className="accordion-body p-0 text-black fw-normal py-2">
                                                    <Col sm={12} className="mt-2">
                                                        <label htmlFor="isOrganization" className="form-label m-0">Для моей организации</label>
                                                        <Field type="checkbox" id="isOrganization" name="isOrganization"
                                                               className="form-check-input fs-3 m-0 mx-2"/>
                                                    </Col>
                                                    <Col sm={10} className="mt-2">
                                                        <Label className="form-label">Обложка игры</Label>
                                                        {backimage() != bg &&
                                                        <label className="form-label" style={{paddingLeft: '7vw', color: 'blue' }}
                                                        onClick={() => {
                                                            setOblozhkaFile(bg)
                                                            setUpdateFileFile(true)
                                                            setFieldValue("file", '')
                                                        }}
                                                        >Сбросить</label>
                                                        }
                                                        <div style={{height: 140, width: 360, backgroundImage: `url(${backimage()})`, backgroundSize: '100% 100%',
                                                            backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}
                                                             className='bg-white p-5 rounded d-flex justify-content-center align-items-center shadow border-3 border-dark'
                                                             onClick={(e) => {
                                                                 setOpenFileFile(prev => !prev)
                                                             }}></div>
                                                        {OpenFileFile &&
                                                            <div className="accordion-body p-0 text-black fw-normal py-2 overflow-auto"
                                                                 style={{maxHeight: 400}}>
                                                                {
                                                                    backgroundImages.map((bg) => <Row className="mt-1 w-100">
                                                                        <Col sm={15}
                                                                             className="position-relative d-flex justify-content-center"
                                                                             key={bg.id}>
                                                                            <img
                                                                                src={process.env.REACT_APP_API_BASE_URL + bg.path}
                                                                                alt="user" className="cursor-pointer"
                                                                                style={{ height: 80, width: 130}}
                                                                            onClick={(event) => {
                                                                                setFieldValue("file", event.currentTarget.src ? event.currentTarget.src : '')
                                                                                setOpenFileFile(false)
                                                                                setUpdateFileFile(true)
                                                                                setOblozhkaFile(event.currentTarget.src)
                                                                        }}/>
                                                                    </Col>
                                                                </Row>)
                                                            }
                                                        </div>
                                                        }
                                                        </Col>
                                                    <Row>
                                                        <Col sm={11} className="position-relative mt-2">
                                                            <Label htmlFor="textureLabelmusic"
                                                                   className="form-label" style={{top: '1vw'}}>Музыка в главном меню</Label>
                                                            {backsound().name !== 'По умолчанию' &&
                                                            <label className="form-label" style={{paddingLeft: '5vw', color: 'blue' }}
                                                                   onClick={() => {
                                                                       setFileMusic({id: 0, name: 'По умолчанию', path: bgsound})
                                                                   setUpdateFileMusic(true)
                                                                       setFieldValue("file_music", '')
                                                                   }}
                                                            >Сбросить</label>
                                                            }
                                                            <div className="accordion-body p-0 text-black fw-normal py-2 overflow-auto">
                                                                <audio controls className="w-100" key={backsound().id}>
                                                                    <source
                                                                        src={backsound().path}
                                                                        type="audio/mp3" className="cursor-pointer flex-1"/>
                                                                </audio>
                                                                <label htmlFor=""
                                                                       className="w-100 text-center fw-bold fs-14"
                                                                       onClick={() => {
                                                                           setOpenFileMusic(prev => !prev)}}
                                                                >{backsound().name}</label>
                                                            </div>
                                                        </Col>
                                                        <div className="accordion-body p-5 text-black fw-normal py-2 overflow-auto"
                                                             style={{maxHeight: 200}}>
                                                            {OpenFileMusic &&
                                                                backgroundSounds.map((bgSound) => <Row className="mt-1 w-100">
                                                                    <Col sm={13} className="position-relative"
                                                                         key={bgSound.id}>
                                                                        <audio controls className="w-100">
                                                                            <source
                                                                                src={process.env.REACT_APP_API_BASE_URL + bgSound.path}
                                                                                type="audio/mp3" className="cursor-pointer flex-1"/>
                                                                        </audio>
                                                                        <label htmlFor=""
                                                                               className="w-100 text-center fw-bold fs-14"
                                                                               onClick={() => {
                                                                                   setFieldValue("file_music", bgSound.path ? bgSound.path : '')
                                                                                   setFileMusic({
                                                                                       id: bgSound.id,
                                                                                       name: bgSound.name,
                                                                                       path: process.env.REACT_APP_API_BASE_URL + bgSound.path})
                                                                                   setUpdateFileMusic(true)
                                                                                   setOpenFileMusic(false)
                                                                               }}
                                                                        >{bgSound.name}</label>
                                                                    </Col>
                                                                </Row>)
                                                            }
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12} className="mt-2">
                                                            <label htmlFor="private" className="form-label m-0">Приватная
                                                                игра</label>
                                                            <Field type="checkbox" id="private" name="private"
                                                                   className="form-check-input fs-3 m-0 mx-2"/>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        values.private &&
                                                        <>
                                                            <Row>
                                                                <Col sm={12} className="mt-2">
                                                                    <label htmlFor="password"
                                                                           className="form-label m-0">Пароль
                                                                        доступа</label>
                                                                    <Field type="text" id="password"
                                                                           name="password"
                                                                           className="form-control"/>
                                                                    <ErrorMessage name="password">{(msg) => (
                                                                        <p className="m-0 text-danger">{msg}</p>)}</ErrorMessage>
                                                                </Col>
                                                            </Row><Row className="mt-2">
                                                            <Col>
                                                                <div className="d-flex gap-2">
                                                                    {
                                                                            listClass.map((el) => (
                                                                                <Badge className="fs-5">
                                                                                    <div
                                                                                        className="d-flex gap-3 justify-content-center align-items-center">
                                                                                        <p className="m-0">{el}</p>
                                                                                        <i className="bx bx-x fs-3 m-0 cursor-pointer"
                                                                                           onClick={() => {
                                                                                               setListClass((prevState) => prevState.filter(prev => prev !== el))
                                                                                           }}></i>
                                                                                    </div>
                                                                                </Badge>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            {
                                                                addClass &&
                                                                <Row>
                                                                    <Col sm={12}>
                                                                        <label htmlFor="nameClass"
                                                                               className="form-label">Название
                                                                            класса</label>
                                                                        <input type="text" id="nameClass"
                                                                               className="form-control"
                                                                               value={nameClass}
                                                                               onChange={(e) => setNameClass(e.target.value)}/>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            <Row>
                                                                <Col>
                                                                    {
                                                                        addClass ?
                                                                            <button type="button"
                                                                                    className="btn btn-primary mt-2"
                                                                                    onClick={() => {
                                                                                        setListClass((prevState) => [...prevState, nameClass])
                                                                                        setNameClass('')
                                                                                        setAddClass(false)
                                                                                    }}>
                                                                                Сохранить класс
                                                                            </button>
                                                                            :
                                                                            <button type="button"
                                                                                    className="btn btn-primary mt-2"
                                                                                    onClick={() => setAddClass(true)}>
                                                                                Добавить класс
                                                                            </button>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    }
                                                    {!values.closeTimer &&
                                                        <Row>
                                                            <Col sm={12} className="mt-2">
                                                                <label htmlFor="timer"
                                                                       className="form-label m-0">Таймер</label>

                                                                <Field type="checkbox" id="timer" name="timer"
                                                                       className="form-check-input fs-3 m-0 mx-2"/>
                                                            </Col>
                                                        </Row>}
                                                    {!values.timer &&
                                                        <Row>
                                                            <Col sm={12} className="mt-2">

                                                                <label htmlFor="closeTimer"
                                                                       className="form-label m-0">Обратный
                                                                    отсчет</label>

                                                                <Field type="checkbox" id="closeTimer"
                                                                       name="closeTimer"
                                                                       className="form-check-input fs-3 m-0 mx-2"/>
                                                            </Col>
                                                        </Row>}
                                                    {
                                                        values.closeTimer &&
                                                        <Row>
                                                            <Col sm={12} className="mt-2">
                                                                <label htmlFor="time"
                                                                       className="form-label m-0">Время</label>
                                                                <Field
                                                                    type="time"
                                                                    name="time"
                                                                    id="time"
                                                                    className="form-control"
                                                                    step="1"
                                                                />
                                                            </Col>
                                                        </Row>
                                                    }
                                                    <Col sm={12} className="mt-2">
                                                        <label htmlFor="is_inventar"
                                                               className="form-label m-0">Инвентарь</label>
                                                        <Field type="checkbox" id="is_inventar" name="is_inventar"
                                                               className="form-check-input fs-3 m-0 mx-2"/>

                                                        <label htmlFor="is_rpg"
                                                               className="form-label m-0">Режим RPG</label>
                                                        <Field type="checkbox" id="is_rpg" name="is_rpg"
                                                               className="form-check-input fs-3 m-0 mx-2"/>
                                                    </Col>
                                                    <Row>
                                                        <Col>
                                                            <div className="mt-1 gap-2 d-flex">
                                                                <button className="btn btn-light w-100" type="submit">
                                                                    Сохранить
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Collapse>
                                        </div>
                                    </Form>
                                )
                            }
                        </Formik>
                        <div className="overflow-auto" style={{maxHeight: 250}}>
                            <ListShots handleOpenSettings={handleOpenSettings} listShot={game?.shots ?? []}
                                       selectShot={(shot) => setSelectSettingsShot(shot)} onListShot={(shots) => {
                                setGame((game) => {
                                    game &&
                                    toggleSave({...game, shots: shots})
                                    if (game) {
                                        return {...game, shots: shots}
                                    }
                                    return null
                                })
                            }}/>
                        </div>
                        <div className="accordion-item border-0 border-bottom">
                            <h2 className="accordion-header" id="genques-headingTwo">
                                <button
                                    className={classNames(
                                        'accordion-button fw-medium bg-white text-black',
                                        {
                                            collapsed: open !== '3'
                                        }
                                    )}
                                    type="button"
                                    onClick={() => toggle('3')}
                                    style={{cursor: "pointer"}}
                                >
                                    Редактирование кадра
                                </button>
                            </h2>
                            <Collapse isOpen={open === '3'} className="accordion-collapse">
                                <div className="accordion-body p-0 text-black fw-normal py-2">
                                    <Row>
                                        <Col sm={12}>
                                            <div className="d-flex gap-2">
                                                <button
                                                    className="btn btn-light d-flex align-items-center justify-content-center"
                                                    onClick={() => toggleAddShotSave()}
                                                    title="Новый кадр"
                                                >
                                                    <i className="bx bx-plus fs-3 cursor-pointer"></i>
                                                </button>
                                                <button
                                                    className="btn btn-light d-flex align-items-center justify-content-center"
                                                    title="Сохранить"
                                                    onClick={() =>{
                                                        saveShot()
                                                    }}>
                                                    <i className="bx bx-save fs-3 cursor-pointer"></i>
                                                </button>
                                                <button
                                                    className="btn btn-light d-flex align-items-center justify-content-center"
                                                    onClick={() => copyShot()}
                                                    title="Копировать"
                                                >
                                                    <i className="bx bx-copy fs-3 cursor-pointer"></i>
                                                </button>
                                                <button
                                                    className="btn btn-light d-flex align-items-center justify-content-center"
                                                    title="Удалить"
                                                    onClick={() => deleteShot()}
                                                >
                                                    <i className="bx bx-trash fs-3 cursor-pointer"></i>
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Collapse>
                        </div>
                    </div>
                </div>
            </OffcanvasBody>
        </Offcanvas>
    );
};

export default RightMenu;
