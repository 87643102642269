
import React, {FC, useEffect, useState} from "react"
import DataTable from "react-data-table-component";
import {Col, Input, Row} from "reactstrap";
import userDummayImage from "../../../../assets/images/users/user-dummy-img.jpg";
import ModalCropPhotoChildInfo from "../ModalCropPhotoChildInfo";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import moment from "moment/moment";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {setLoadingStudents, setShowNotification} from "../../../../store/reducers/widgets/WidgetsSlice";
import AxiosClient from "../../../../api/AxiosClient";

interface ITableChildInfo {
    student: any,
    values: any
}

const TableChildInfo: FC<ITableChildInfo> = ({student, values}) => {
    const dispatch = useAppDispatch();
    const {students} = useAppSelector(state => state.WidgetReducer);
    const [selectModalCropPhoto, setSelectModalCropPhoto]  = useState<boolean>(false);
    const [date, setDate] = useState<Date | undefined>(student[0]?.deviation_data ? moment(student[0].deviation_data, "YYYY-MM-DD").toDate() : undefined);
    const [datePrev, setDatePrev] = useState<string>(student[0]?.deviation_data_prev || "");
    const [pathPhoto, setPathPhoto]  = useState<any>(student[0].hasOwnProperty('base64') ? `data:image/png;base64,${student[0].base64}` : userDummayImage);
    const [downloadPhoto64, setDownloadPhoto64] = useState<string | ArrayBuffer | null>();
    const [isDownloadPortfolioPage, setIsDownloadPortfolioPage] = useState<boolean>(false);
    const [isDisableTime] = useState<boolean>(
        values.find((elem: any) => {
            const hasField = elem.hasOwnProperty(student[0].code);
            const isFieldEmpty = hasField && !elem[student[0].code];
            return isFieldEmpty;
        }) ? false : true
    );

    useEffect(() => {
        AxiosClient.post('/user/photo', {"child_uid": student[0].code})
            .then((r) => setPathPhoto(r.data.hasOwnProperty('base64') ? `data:image/png;base64,${r.data.base64}` : userDummayImage))
            .catch((e) => console.log(e))
    }, [])

    const handleImageClick = () => {
        if (isDownloadPortfolioPage) {
            document.getElementById('inputForDownload')?.click();
            setIsDownloadPortfolioPage(false);
        }
    };

    const handleSelectChange = (selectedDate: Date) => {
        const newDeviationDataPrev = datePrev || moment(date).format("YYYY-MM-DD");
        const newDeviationData = moment(selectedDate).format("YYYY-MM-DD");


        setDatePrev(datePrev || moment(date).format("YYYY-MM-DD"));
        setDate(selectedDate);

        const updatedStudent = {
            ...student[0],
            deviation_data: newDeviationData,
            deviation_data_prev: newDeviationDataPrev,
        };

        const updatedStudents = students.map((s) =>
            s.code === updatedStudent.code ? updatedStudent : s
        );

        dispatch(setLoadingStudents(updatedStudents));
    };

    const columns = [
        {
            cell: (row: any) => (
                <Col className="container pt-3 accessibility_size_table">
                    <Row>
                        <label>Дата по:
                            <Flatpickr
                                className="form-control border-1 dash-filter-picker shadow"
                                value={date}
                                options={{
                                    locale: Russian, dateFormat: "d M Y", disableMobile: true, mode: 'single'
                                }}
                                onChange={(date) => {
                                    handleSelectChange(date[0])
                                }}
                            />
                        </label>
                    </Row>
                    <Row>
                        <label>Время прихода:
                            <div className="input-group">
                                <Flatpickr
                                    className="form-control border-1 dash-filter-picker shadow"
                                    value={
                                        moment(student[0].time_c, "H:mm").isValid()
                                            ? moment(student[0].time_c, "H:mm").toDate()
                                            : undefined
                                    }
                                    options={{
                                        locale: Russian,
                                        enableTime: true,
                                        noCalendar: true,
                                        dateFormat: "H:i",
                                        time_24hr: true,
                                        disableMobile: true,
                                        mode: 'single',
                                        defaultMinute: 0,
                                    }}
                                    onClose={(selectedDates) => {
                                        const time = selectedDates[0];
                                        if (time) {
                                            if (moment(student[0].time_po, "H:mm").isValid() && moment(student[0].time_po, "H:mm").isBefore(time)) {
                                                dispatch(setShowNotification({
                                                    type: "error",
                                                    message: "Время прихода должно быть меньше времени ухода!",
                                                    show: true
                                                }));
                                            } else {
                                                const updatedStudent = {
                                                    ...student[0],
                                                    time_c: moment(time).format("H:mm"),
                                                };

                                                const updatedStudents = students.map((s) =>
                                                    s.code === updatedStudent.code ? updatedStudent : s
                                                );

                                                dispatch(setLoadingStudents(updatedStudents));
                                            }
                                        }
                                    }}
                                    disabled={isDisableTime}
                                />
                                <button
                                    type="button"
                                    className="btn"
                                    style={{ borderColor: 'lightgray' , backgroundColor: "white"}}
                                    onClick={() => {
                                        const updatedStudent = {
                                            ...student[0],
                                            time_c: "",
                                        };

                                        const updatedStudents = students.map((s) =>
                                            s.code === updatedStudent.code ? updatedStudent : s
                                        );

                                        dispatch(setLoadingStudents(updatedStudents));
                                    }}
                                    title="Очистить"
                                >
                                    ×
                                </button>
                            </div>
                        </label>
                    </Row>
                    <Row>
                        <label>Время ухода:
                            <div className="input-group">
                                <Flatpickr
                                    className="form-control border-1 dash-filter-picker shadow"
                                    value={
                                        moment(student[0].time_po, "H:mm").isValid()
                                            ? moment(student[0].time_po, "H:mm").toDate()
                                            : undefined
                                    }
                                    options={{
                                        locale: Russian,
                                        enableTime: true,
                                        noCalendar: true,
                                        dateFormat: "H:i",
                                        time_24hr: true,
                                        disableMobile: true,
                                        mode: 'single',
                                        defaultMinute: 0,
                                    }}
                                    onClose={(selectedDates) => {
                                        const time = selectedDates[0];
                                        if (time) {
                                            if (moment(student[0].time_c, "H:mm").isValid() && moment(student[0].time_c, "H:mm").isAfter(time)) {
                                                dispatch(setShowNotification({
                                                    type: "error",
                                                    message: "Время ухода должно быть больше времени прихода!",
                                                    show: true
                                                }));
                                            } else {
                                                const updatedStudent = {
                                                    ...student[0],
                                                    time_po: moment(time).format("H:mm"),
                                                };

                                                const updatedStudents = students.map((s) =>
                                                    s.code === updatedStudent.code ? updatedStudent : s
                                                );

                                                dispatch(setLoadingStudents(updatedStudents));
                                            }
                                        }
                                    }}
                                    disabled={isDisableTime}
                                />
                                <button
                                    type="button"
                                    className="btn"
                                    style={{ borderColor: 'lightgray' , backgroundColor: "white"}}
                                    onClick={() => {
                                        const updatedStudent = {
                                            ...student[0],
                                            time_po: "",
                                        };

                                        const updatedStudents = students.map((s) =>
                                            s.code === updatedStudent.code ? updatedStudent : s
                                        );

                                        dispatch(setLoadingStudents(updatedStudents));
                                    }}
                                    title="Очистить"
                                >
                                    ×
                                </button>
                            </div>
                        </label>
                    </Row>

                    <Row>
                        <label>Примечание: <span>{row.note}</span></label>
                    </Row>
                    <Row>
                        <Col>
                            <label className="w-100">Загрузить фото:
                                <img
                                    src={pathPhoto}
                                    alt="Загрузить фото"
                                    className="user-profile-image w-100 h-auto"
                                    onClick={handleImageClick}
                                    style={{ cursor: 'pointer' }}
                                />
                                <Input
                                    name="photo"
                                    type="file"
                                    id="inputForDownload"
                                    className={`form-control d-none`}
                                    accept=".png, .jpg"
                                    onChange={(e: any) => {
                                        const file = e.target.files[0];

                                        if (file) {
                                            const reader = new FileReader();
                                            reader.onloadend = () => {
                                                setDownloadPhoto64(reader.result);
                                                setPathPhoto(reader.result as string);
                                            };
                                            reader.readAsDataURL(file);
                                            setSelectModalCropPhoto(true)
                                        }
                                    }}
                                />
                            </label>
                        </Col>
                        <Col style={{ lineHeight: "1.5" }}>
                            <label className="w-100">Алергены:</label>
                            {row.discriptions_taboo
                                .split(";")
                                .filter((item: any) => item.trim())
                                .map((item: any, index: number) => (
                                    <React.Fragment key={index}>
                                        {item};
                                        <br />
                                    </React.Fragment>
                                ))}
                        </Col>
                    </Row>
                </Col>)
        },
    ]

    return (
        <>
            <DataTable
                columns={columns}
                data={student}
                noTableHead
                noDataComponent={'Нет информации'}
                highlightOnHover
                customStyles={{
                    rows: {
                        style: {
                            paddingLeft: "20px"
                        },
                        highlightOnHoverStyle: {
                            backgroundColor: "#ffebb5"
                        },
                    }
                }}
            />
            <ModalCropPhotoChildInfo uid={student[0].code} selectModalCropPhoto={selectModalCropPhoto} setSelectModalCropPhoto={(value: boolean) => setSelectModalCropPhoto(value)}
                                     pathPhoto={pathPhoto}
                                     downloadPhoto64={downloadPhoto64} setDownloadPhoto64={(value: any) => setDownloadPhoto64(value)}/>
        </>
    )
}

export default TableChildInfo;
