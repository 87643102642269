import React from 'react';
import {Col, Row} from "reactstrap";
import profileBg from '../../assets/images/childrenRom.jpeg';
import MyInfo from "./MyInfo";
import MyPhotoForm from "./MyPhotoForm";
import {useAccessibility} from "../../context/AccessibilityContext";
const MyHeaderInfo = () => {
    const {imagesVisible} = useAccessibility();

    return (
        <>
            <div className="profile-foreground position-relative mx-n4 mt-n4">
                <div className="profile-wid-bg">
                    <img src={profileBg} alt="" className="profile-wid-img"/>
                </div>
            </div>
            <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
                <Row className="g-4">
                    <Col lg={1} hidden={imagesVisible}>
                        <MyPhotoForm/>
                    </Col>
                    <Col>
                        <MyInfo/>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default MyHeaderInfo;
