import React, {FC, useState} from 'react';
import DataTable from "react-data-table-component";
import AddTableware from "./AddTableware";

interface ITableTableware {
    tableware: any,
    setTableware(value: any): void,
    selectClothesSetting: any
}

const TableTableware: FC <ITableTableware> = ({tableware, setTableware, selectClothesSetting}) => {
    const [modalUpdateTableware, setModalUpdateTableware] = useState(false);
    const [selectTableware, setSelectTableware] = useState<any | null>(null);

    const columns : any = [
        {
            id: "name",
            name: <span className='font-weight-bold accessibility_size_table'>Наименование</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.name}</span>,
            wrap: true,
        },
        {
            id: "concentration",
            name: <span className='font-weight-bold accessibility_size_table'>Количество</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.units}</span>,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'></span>,
            cell: (row: any) => <i className="bx bx-trash fs-24 cursor-pointer" onClick={() => {removeTableware(row.uid)}}></i>,
            width: '60px'
        }
    ]

    const removeTableware = (uid: string) => {
        setTableware(tableware.filter((item: any) => item.uid !== uid));
    }

    return (
        <>
            <DataTable
                columns={columns}
                data={tableware}
                noDataComponent={'Нет данных'}
                pagination
                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                paginationComponentOptions={
                    {
                        rowsPerPageText: 'Видно льгот:',
                        rangeSeparatorText: 'из'
                    }
                }
                highlightOnHover
                customStyles={{
                    rows: {
                        highlightOnHoverStyle: {
                            backgroundColor: "#ffebb5"
                        },
                    },
                    expanderRow: {
                        style: {
                            backgroundColor: "#ffebb5",
                            paddingTop: '5px',
                            paddingBottom: '5px'
                        },
                    },
                }}
                onRowClicked={(row) => {
                    setSelectTableware(row);
                    setModalUpdateTableware(true);
                }}
            />
            <button className="btn btn-primary" type="button"
                    onClick={() => {
                        setSelectTableware(null)
                        setModalUpdateTableware(true)}}>Добавить
            </button>
            <AddTableware modalUpdateTableware={modalUpdateTableware} setModalUpdateTableware={(value) => setModalUpdateTableware(value)} tableware={tableware} setTableware={(value) => setTableware(value)} selectTableware={selectTableware} selectClothesSetting={selectClothesSetting}/>
        </>

    );
};

export default TableTableware;
