import React, {FC, useState} from 'react';
import {useAppDispatch} from "../../../hooks/redux";
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import * as Yup from "yup";
import {setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import AxiosClient from "../../../api/AxiosClient";
import {Field, Form, Formik} from "formik";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import Notification from "../../widgets/Notification";
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface IUpdateStatusBenefit {
    updateStatus: boolean
    setUpdateStatus: (value: boolean) => void
    setStatuses: (value: any[]) => void
    selectStatus: any
}
const UpdateStatusBenefit: FC<IUpdateStatusBenefit> = ({setUpdateStatus, updateStatus, setStatuses, selectStatus}) => {

    const dispatch = useAppDispatch()
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<string>('');
    const [notificationMessage, setNotificationMessage] = useState<string>('');
    useEscapeKey(() => dispatch(setModalAdd(false)), updateStatus, "updateStatus");

    return (
        <>
        <Modal id="updateStatus" isOpen={updateStatus}
               toggle={() => setUpdateStatus(!updateStatus)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setUpdateStatus(!updateStatus)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Обновление статуса льготы
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            name: selectStatus?.name,
                        }
                    }
                    validationSchema={
                        Yup.object({
                            name: Yup.string().required('Require'),
                        })
                    }
                    onSubmit={(values) => {
                        dispatch(setIsSend(true))
                        AxiosClient.post(`/statuses/update/${selectStatus.id}`, {name: values.name})
                            .then(() => {
                                setNotificationType('success');
                                setNotificationMessage('Готово, данные обновлены!');
                                setShowNotification(true);
                                dispatch(setIsSend(false))
                                setUpdateStatus(false)
                                AxiosClient.get('/statuses')
                                    .then((r) => {
                                        setStatuses(r.data)
                                    })
                            })
                            .catch(() => {
                                setNotificationType('error');
                                setNotificationMessage('Ошибка, данные не загружены!');
                                setShowNotification(true);
                                console.log('error')
                                dispatch(setIsSend(false))
                            })
                    }}
                >
                    {
                        ({errors, touched}) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">Наименование*:
                                        <Field name="name"
                                               className={`form-control ${errors.name && touched.name && "border border-2 border-danger"}`}
                                               placeholder="Наименование"/>
                                        </label>
                                    </Col>
                                </Row>
                                <SubmitWithLoading text={"Добавить"}/>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
            {showNotification && <Notification type={notificationType} message={notificationMessage} setShowNotification={(value: boolean) => setShowNotification(value)}/>}
        </>
    );
};

export default UpdateStatusBenefit;
