import React, {FC, useEffect, useState} from 'react';
import {ICashAccountingTotal} from "../../../models/parent/ICashAccountingTotal";
import DataTable from "react-data-table-component";
import AxiosClient from "../../../api/AxiosClient";
import moment from "moment";

interface ITableCashAccountingTotal {
    selectChildren: string | number,
    date: Date[]
}

const TableCashAccountingTotal: FC<ITableCashAccountingTotal> = ({selectChildren, date}) => {
    const [cashAccountingTotal, setCashAccountingTotal] = useState<any[]>([]);

    useEffect(() => {
        selectChildren &&
       AxiosClient.get('/students/cash_account_total', {params: {id: selectChildren, date: [moment(date[0]).format('YYYY-MM-DD').toString(), moment(date[1]).format('YYYY-MM-DD').toString()]}})
           .then(r => setCashAccountingTotal(r.data))
    }, [selectChildren, date]);


    const columnsEvolutions: any = [
        {
            selector: (row: ICashAccountingTotal) => <span className="accessibility_size_table">{row.name}</span>,
            wrap: true,
            width: '50%'
        },
        {
            selector: (row: ICashAccountingTotal) => {
                if (!row.count){
                    return <span className="accessibility_size_table">&mdash;</span>
                }
                return <span className="accessibility_size_table">{row.count}</span>
            },
            minWidth: '50px',
            center: true
        }
    ];


    return (
        <DataTable
            columns={columnsEvolutions}
            data={cashAccountingTotal}
            noDataComponent={'Нет данных'}
            noTableHead
            highlightOnHover
            customStyles={{
                rows: {
                    highlightOnHoverStyle: {
                        backgroundColor: "#ffebb5"
                    }
                }
            }}
        />
    );
};

export default TableCashAccountingTotal;
