import React, { useEffect, useState } from 'react'
import AxiosClient from "../../../api/AxiosClient"
import { CardBody, Card, Row, Col } from "reactstrap"
import DataTable from "react-data-table-component"
import ListTeacher from "./ListTeacher"
import ModalAddTeacher from "./ModalAddTeacher";
import Select from "react-select";
import {customStyles} from "../../settings";

const ListSchools = () => {
    const [schools, setSchool] = useState<any[]>([])
    const [addTeacher, setAddTeacher] = useState(false);
    const [schoolId, setSchoolId] = useState('')
    const [search, setSearch] = useState('')
    const [selectSchool, setSelectSchool] = useState<any>({ value: '', label: 'Не выбрано' })
    const [schoolsFil, setSchoolsFil] = useState<any[]>([])
    const [dataFilter, setDataFilter] = useState<any[]>([]);


    useEffect(() => {
        AxiosClient.get('/schools/get_users')
            .then((r) => {
                setSchool(r.data)
                setDataFilter(r.data)
            })
        AxiosClient.get('/schools')
            .then((r) => {
                setSchoolsFil(r.data.schools)
            })
    }, [])

    useEffect(() => {
        setDataFilter(filtering())
    }, [search, selectSchool.value, schools]);


    const filtering = () => {
        let data = schools

        if (selectSchool.value){
            data = data.filter(el => el.id.toString() === selectSchool.value.toString())
        }

        if (search) {
            data = data.filter(el =>
                el.users.filter((e: any) => {
                    const fullName = `${e.name} ${e.last_name} ${e.middle_name}`.toLowerCase();
                    return fullName.includes(search.toLowerCase());
                }).length > 0
            ).map(el => ({
                ...el,
                users: el.users.filter((e: any) => {
                    const fullName = `${e.name} ${e.last_name} ${e.middle_name}`.toLowerCase();
                    return fullName.includes(search.toLowerCase());
                })
            }));
        }
        return data
    }

    const columns: any = [
        {
            name: <span className='font-weight-bold accessibility_size_table'>Школа</span>,
            selector: (row: any) => row.name,
            cell: (row: any) => <span className="accessibility_size_table">{row.name}</span>,
            sortable: true,
            style: {
                minWidth: '110px'
            },
            wrap: true
        },
        {
            cell: (row: any) => <i className="bx bx-plus fs-3 cursor-pointer" title="Новый учитель" onClick={() => {
                setAddTeacher(true)
                setSchoolId(row.id)
            }}></i>,
            width: '50px'
        }
    ]


    return (
        <>
            <Row>
                <Col xxl={3} xl={3} lg={6}>
                    <label htmlFor="">ФИО учителя</label>
                    <input type="text" className='form-control' value={search} onChange={(e) => setSearch(e.target.value)} />
                </Col>
                <Col sm={12} lg={6} xl={4} xxl={3} >
                    <label htmlFor="">Школа</label>
                    <Select
                        options={schoolsFil?.length > 0 ? [{
                            value: '',
                            label: 'Не выбрано'
                        }, ...schoolsFil.map(v => ({ value: v.id, label: v.name }))] : [{ value: '', label: 'Не выбрано' }]}
                        value={selectSchool}
                        onChange={(change: any) => {
                            setSelectSchool({ value: change.value, label: change.label })
                        }}
                        styles={customStyles}
                        placeholder="Поиск"
                        name="name"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mt-2">
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={dataFilter}
                                noDataComponent={'Нет данных'}
                                pagination
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        }
                                    }
                                }}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: 'Видно школ:',
                                        rangeSeparatorText: 'из'
                                    }
                                }
                                expandableRows
                                expandableRowsComponent={({ data }) => {
                                    return <ListTeacher setSchool={value => setSchool(value)} teacher={data.users} schoolId={data.id} />
                                }}
                            />
                        </CardBody>
                    </Card>
                    <ModalAddTeacher teacher={dataFilter.find(school => school.id === schoolId)} setSchool={value => setSchool(value)} schoolId={schoolId} addTeacher={addTeacher} setAddTeacher={value => setAddTeacher(value)} />
                </Col>
            </Row>
        </>

    )
}

export default ListSchools
