import React, { FC } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label } from "reactstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useAppDispatch} from "../../../../hooks/redux";
import { setShowNotification} from "../../../../store/reducers/widgets/WidgetsSlice";

interface IModalCreateCenterProps {
    isOpen: boolean;
    toggle: () => void;
    onSave: (data: { name: string; link: string }) => void;
}

const ModalCreateCenter: FC<IModalCreateCenterProps> = ({ isOpen, toggle, onSave }) => {
    const dispatch = useAppDispatch();

    return (
        <Modal
            id="ModalCreateCenter"
            isOpen={isOpen}
            toggle={toggle}
            modalClassName="zoomIn"
            tabIndex={-1}
            centered
        >
            <ModalHeader toggle={toggle} className="p-3 bg-soft-primary" id="createCenterModalLabel">
                Создать центр тестирования
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={{
                        name: '',
                        link: '',
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string().required('Название центра обязательно'),
                        link: Yup.string()
                            // .url('Ссылка должна быть действительным URL')
                            .required('Ссылка обязательна'),
                    })}
                    onSubmit={(values) => {
                        onSave({
                            name: values.name,
                            link: values.link,
                        });
                        dispatch(setShowNotification({ type: "success", message: 'Центр тестирования успешно создан!', show: true }));
                        toggle();
                    }}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <FormGroup className="mb-3">
                                <Label for="name">Название</Label>
                                <Field
                                    name="name"
                                    type="text"
                                    className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`}
                                    placeholder="Введите название центра"
                                />
                                {errors.name && touched.name && (
                                    <div className="invalid-feedback">{errors.name}</div>
                                )}
                            </FormGroup>

                            <FormGroup className="mb-3">
                                <Label for="link">Ссылка</Label>
                                <Field
                                    name="link"
                                    type="text"
                                    className={`form-control ${errors.link && touched.link ? 'is-invalid' : ''}`}
                                    placeholder="Введите ссылку на центр"
                                />
                                {errors.link && touched.link && (
                                    <div className="invalid-feedback">{errors.link}</div>
                                )}
                            </FormGroup>

                            <div className="hstack gap-2 justify-content-end mt-1">
                                <Button color="primary" type="submit">
                                    Сохранить
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalCreateCenter;