import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    setDateAdd, setGroupAllDeviationsHaccp, setGroupModalAdd,
    setIsSend,
    setModalAdd, setShowNotification,
    WidgetsSlice
} from "../../../store/reducers/widgets/WidgetsSlice";
import {Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import moment from "moment/moment";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import AxiosClient from "../../../api/AxiosClient";
import {getJournalHaccpMorningFilterChildren} from "../../../store/reducers/widgets/ActionCreator";
import classNames from "classnames";
import * as Yup from "yup";
import TableChildren from "./TableChildren";
import ModalConfirmExitHaccp from "../ModalConfirmExitHaccp";
import {useModalWithConfirm} from "../../../hooks/useModalWithConfirm";

interface IModalAddMorningFilterChildren {
    updateData: any
}

const ModalAddMorningFilterChildren: FC<IModalAddMorningFilterChildren> = ({updateData}) => {
    const dispatch = useAppDispatch();
    const {
        modalAdd,
        groupAllDeviationsHaccp,
        date,
        groupModalAdd,
        groupsHaccp,
        dateAdd,
        successUpdate,
        journalHaccpMorningFilterChildren
    } = useAppSelector(state => state.WidgetReducer);
    const [activeTab, setActiveTab] = useState("1");
    const [deti, setDeti] = useState([])
    const [role, setRole] = useState<any>([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [uidGroup, setUidGroup] = useState("");

    const {
        isFormChange,
        showConfirmExit,
        handleClose,
        handleFormChange,
        handleConfirmClose,
        handleStay,
    } = useModalWithConfirm("addAddMorningFilterChildren");

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(WidgetsSlice.actions.successUpdate(""))
        }, 5000)
        return () => clearTimeout(timer)
    }, [successUpdate, dispatch]);

    useEffect(() => {
        if (modalAdd) {
            setActiveTab("1")
            if (updateData) {
                setUidGroup("")
                dispatch(setGroupAllDeviationsHaccp(updateData.group_uid))
                setDeti(updateData.deti.map((child: any) => {
                    const {index, color, ...rest} = child;
                    return {
                        ...rest,
                        uid: child.uid,
                        name: child.name,
                        parent: child.parent || "Мама",
                        temperature: child.temperature || "36.6",
                        remove: child.remove || "",
                        throat: child.throat || "Обновление",
                        skin: child.skin || "Кожа чистая, высыпаний нет",
                        information: child.information || "Замечаний нет",
                        conclusion: child.conclusion || "Допущен",
                        orvi: child.orvi || "Отсутствуют",
                        time: child.time || ""
                    };
                }));
                setDataLoaded(false);
            } else {
                const selectedDate = moment(dateAdd).format("DD.MM.YYYY");
                const journalEntry = journalHaccpMorningFilterChildren.find((entry: any) => entry.data === selectedDate);

                if (journalEntry) {
                    setUidGroup(journalEntry.uid)
                    setDataLoaded(true);
                    setDeti(journalEntry.deti.map((child: any) => {
                        return {
                            uid: child.uid,
                            name: child.name,
                            parent: child.parent || "Мама",
                            temperature: child.temperature || "36.6",
                            remove: child.remove || "",
                            throat: child.throat || "Горло без покраснений и налета",
                            skin: child.skin || "Кожа чистая, высыпаний нет",
                            information: child.information || "Замечаний нет",
                            conclusion: child.conclusion || "Допущен",
                            orvi: child.orvi || "Отсутствуют",
                            time: child.time || ""
                        }
                    }));
                } else {
                    setDataLoaded(false);
                    setUidGroup("")
                    AxiosClient.get(`haccp/getListChildren/${groupAllDeviationsHaccp}`, {params: {date: moment(dateAdd)}})
                        .then((r: any) => {
                            setDeti(r.data.map((child: any) => {
                                return {
                                    uid: child.children_uid,
                                    name: child.child_name,
                                    parent: "Мама",
                                    temperature: "36.6",
                                    remove: "",
                                    throat: "Горло без покраснений и налета",
                                    skin: "Кожа чистая, высыпаний нет",
                                    information: "Замечаний нет",
                                    conclusion: "Допущен",
                                    orvi: "Отсутствуют",
                                    time: ""
                                }
                            }))
                        })
                        .catch((error) => console.log(error))
                }
            }

            AxiosClient.get('haccp/getRoleParent')
                .then((r: any) => {
                    setRole(r.data)
                })
                .catch((error) => console.log(error))
        }
    }, [modalAdd, groupAllDeviationsHaccp, dateAdd]);

    const checkExistingDate = (newDate: Date) => {
        if (updateData) {
            const formattedNewDate = moment(newDate).format("DD.MM.YYYY");
            const originalDate = moment(updateData.data, "DD.MM.YYYY").format("DD.MM.YYYY");

            if (formattedNewDate !== originalDate) {
                const existingEntry = journalHaccpMorningFilterChildren.find(
                    (entry: any) => entry.data === formattedNewDate && entry.group_uid === groupAllDeviationsHaccp
                );

                if (existingEntry) {
                    dispatch(setShowNotification({type: "error", message: `Запись на ${formattedNewDate} уже существует для этой группы. Пожалуйста, отредактируйте существующую запись.`, show: true}))
                    return false;
                }
            }
        }
        return true;
    };

    const message = dataLoaded ? (
        <div className="text-danger mb-3">
            Запись на выбранную дату уже существует, данные автоматически подставлены
        </div>
    ) : null;
    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        if (modalAdd && !updateData) {
            dispatch(setDateAdd(new Date()));
        }
    }, [modalAdd]);

    return (
        <>
        <Modal id="ModalAddMorningFilterChildren" isOpen={modalAdd}  toggle={handleClose}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader  toggle={handleClose} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление утреннего фильтра детей
            </ModalHeader>
            <ModalBody>

                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        date: moment(dateAdd, "DD.MM.YYYY").toDate(),
                        deti: deti
                    }}
                    validationSchema={
                        Yup.object({
                            linen: Yup.array().min(1, '')
                        })}
                    onSubmit={(value) => {
                        dispatch(setIsSend(true));

                        const formattedDate = moment(dateAdd || new Date()).format('DD.MM.YYYY');

                        let prop = {
                            uid: updateData ? updateData.uid : uidGroup !== '' ? uidGroup : '',
                            data: formattedDate, //moment(dateAdd).format('DD.MM.YYYY'),
                            group_uid: groupAllDeviationsHaccp,
                            deti: deti,
                        };

                        AxiosClient.post('haccp/saveJournalMorningFilterChildren', prop)
                            .then((r: any) => {
                                dispatch(getJournalHaccpMorningFilterChildren(date, groupAllDeviationsHaccp)).then();
                                dispatch(setIsSend(false));
                                dispatch(setModalAdd(false));
                                dispatch(setShowNotification({type: "success", message: `Сохранено ${formattedDate}`, show: true}))
                                handleConfirmClose();
                            })
                            .catch((error) => {
                                dispatch(setIsSend(false))
                                console.error("Ошибка при сохранении журнала:", error);
                                const errorMessage = error.response?.data?.message || "Ошибка при сохранении. Попробуйте снова.";
                                const debugInfo = {
                                    errorMessage,
                                    dateAddRaw: dateAdd,
                                    formattedDate: formattedDate
                                };
                                dispatch(setShowNotification({
                                    type: "error",
                                    message: `Ошибка: ${errorMessage}. Подробности: ${JSON.stringify(debugInfo)}`,
                                    show: true
                                }));
                            })
                    }}
                >
                    {({errors, touched, setFieldValue}) => (
                        <Form onChange={handleFormChange}>
                            <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                 role="tablist">
                                <NavItem>
                                    <NavLink
                                        className={classNames({active: activeTab === "1", "p-2": true})}
                                        onClick={() => {
                                            tabChange("1");
                                        }}
                                        type="button">
                                        <i className="fas fa-home"></i>
                                        Основное
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classNames({active: activeTab === "2", "p-2": true})}
                                        onClick={() => {
                                            tabChange("2");
                                        }}
                                        type="button">
                                        <i className="fas fa-home"></i>
                                        Список детей
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab} className="mt-2">
                                <TabPane tabId="1" className="align-items-center">
                                    {message}
                                    <div className="col-auto">
                                        <label className="w-100">Дата:
                                            <div className="col mb-3">
                                                <div className="input-group col-auto">
                                                    <Flatpickr
                                                        className="form-control border-0 dash-filter-picker shadow"
                                                        value={dateAdd}
                                                        options={{
                                                            locale: Russian,
                                                            dateFormat: "d M Y",
                                                            mode: 'single',
                                                            disableMobile: true
                                                        }}
                                                        onChange={(date) => {
                                                            const newDate = date && date.length > 0 ? moment(date[0]).toDate() : new Date();
                                                            if (checkExistingDate(newDate)) {
                                                                dispatch(setDateAdd(newDate));
                                                            } else if (updateData) {
                                                                dispatch(setDateAdd(moment(updateData.data, "DD.MM.YYYY").toDate()));
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="mb-3">
                                        <label className="w-100">Группа:
                                            <Field as="select" className="form-select shadow"
                                                   value={groupAllDeviationsHaccp} //groupModalAdd //groupAllDeviationsHaccp
                                                   onChange={(e: any) => {
                                                       dispatch(setGroupAllDeviationsHaccp(e.target.value))
                                                       dispatch(setGroupModalAdd(e.target.value))
                                                   }} name="group"
                                                   disabled={!!updateData}>
                                                {groupsHaccp.map((group) => {
                                                    return <option key={group.group_id}
                                                                   value={group.group_id}>{group.group_name}</option>
                                                })}
                                            </Field>
                                        </label>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2" className="align-items-center">
                                    <TableChildren
                                        role={role}
                                        deti={deti}
                                        setDeti={value => setDeti(value)}
                                        handleFormChange={handleFormChange}
                                    />
                                </TabPane>
                            </TabContent>
                            {errors.deti && (
                                <div style={{color: 'red', marginTop: '5px'}}>Требуется добавить данные о детях!</div>
                            )}
                            <div className="hstack gap-2 justify-content-end mt-1">
                                <SubmitWithLoading text={"Сохранить"}/>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
            <ModalConfirmExitHaccp
                isOpen={showConfirmExit}
                onClose={handleConfirmClose}
                onStay={handleStay}
                hasUnsavedChanges={isFormChange}
            />
        </>
    );
};

export default ModalAddMorningFilterChildren;
