import React, {FC, useEffect, useState} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import AxiosClient from "../../../api/AxiosClient";
import ModalAddClass from "./ModalAddClass";
import {setIsSend, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch} from "../../../hooks/redux";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import _ from "lodash";
import ModalConfirmExit from "../Schools/ModalConfirmExit";
import {useEscapeKey} from "../../../hooks/useEscapeKey";

interface IModalTeacherClasses {
    openTeacherClasses: boolean,
    setOpenTeacherClasses: (value: boolean) => void,
    teacherId: number | string,
    schoolId: number | string
}

const ModalTeacherClasses: FC<IModalTeacherClasses> = ({setOpenTeacherClasses, openTeacherClasses, teacherId, schoolId}) => {
    const idButtonSave: string = 'buttonSaveModalTeacherClasses';
    const [classes, setClasses] = useState<any[]>([]);
    const [withoutChangesClasses, setWithoutChangesClasses] = useState<any[]>([]);
    const [addClass, setAddClass] = useState(false);
    const [confirmExit, setConfirmExit] = useState(false);
    const dispatch = useAppDispatch()


    useEffect(() => {
        teacherId &&
        AxiosClient.get('school_building_classes/teacher_classes', {params: {id: teacherId}})
            .then((r) => {
                setClasses(r.data);
                setWithoutChangesClasses(r.data);
            })
    }, [teacherId]);

    const deleteClass = (id: number) => {
        setClasses(classes.filter(c => c.id.toString() !== id.toString()))
    }

    const save = () => {
        dispatch(setIsSend(true))
        AxiosClient.post('school_building_classes/save_classes_teacher', {id: teacherId, classes: classes})
            .then(() => {
                dispatch(setIsSend(false))
                setOpenTeacherClasses(false)
            })
            .catch(() => dispatch(setIsSend(false)))

    }

    const confirmOrExit = () => {
        if(!_.isEqual(classes, withoutChangesClasses)){
            setConfirmExit(true);
        }else{
            setOpenTeacherClasses(false);
        }
    }

    const columns: any = [
        {
            name: <span className='font-weight-bold accessibility_size_table'>Корпус</span>,
            selector: (row: any) => row?.school_building?.name,
            cell: (row: any) => <span className="accessibility_size_table">{row?.school_building?.name}</span>,
            sortable: true,
            style: {
                minWidth: '110px'
            },
            wrap: true
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Класс</span>,
            selector: (row: any) => row.name,
            cell: (row: any) => <span className="accessibility_size_table">{row.name}</span>,
            sortable: true,
            style: {
                minWidth: '110px'
            },
            wrap: true
        },
        {
            cell: (row: any) => <i className="bx bx-trash fs-3 cursor-pointer" title="Удалить" onClick={() => deleteClass(row.id)}></i>,
            center: true,
            width: '60px'
        }
    ];

    useEscapeKey(() => dispatch(setModalAdd(false)), openTeacherClasses, "openTeacherClasses");

    return (
        <Modal id="createProjectModal" isOpen={openTeacherClasses}
               toggle={() => confirmOrExit()}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => confirmOrExit()}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Классы
            </ModalHeader>
            <ModalBody>
                <DataTable
                    columns={columns}
                    data={classes}
                    noDataComponent={'Нет данных'}
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                    onRowClicked={() => {

                    }}
                />
            </ModalBody>
            <ModalFooter className="justify-content-start">
                    <div className="hstack gap-2">
                        <SubmitWithLoading id={idButtonSave} text={"Сохранить"} onClick={() => save()}/>
                    </div>
                    <div className="hstack gap-2">
                        <button type="button" className="btn btn-primary" id="addNewProject" onClick={() => setAddClass(true)}>Добавить класс</button>
                    </div>
            </ModalFooter>
            <ModalConfirmExit idButtonSave={idButtonSave} setUpdate={value => setOpenTeacherClasses(value)} confirmExit={confirmExit} setConfirmExit={value => setConfirmExit(value)} />
            <ModalAddClass classes={classes} addClass={addClass} setAddClass={value => setAddClass(value)} setClasses={value => setClasses(value)} schoolId={schoolId}/>
        </Modal>
    );
};

export default ModalTeacherClasses;
