import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import AxiosClient from "../../../api/AxiosClient";
import {customStyles} from "../../settings";
import Select from "react-select";
import {useAppDispatch} from "../../../hooks/redux";
import {useEscapeKey} from "../../../hooks/useEscapeKey";
import {setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";

interface IModalAddMealtime {
    addMealtime: boolean,
    setAddMealtime: (value: boolean) => void,
    setListMealtime: (value: any[]) => void,
    listMealtime: any[]
}
const ModalAddMealtime: FC<IModalAddMealtime> = ({setAddMealtime, addMealtime, setListMealtime, listMealtime}) => {

    const [mealtime, setMealtime] = useState<any[]>([]);
    const [selectMealtime, setSelectMealtime] = useState<{value: string, label: string}>({value: '', label: 'Не выбрано'});

    useEffect(() => {
        AxiosClient.get('/mealtimes')
            .then((r) => setMealtime(r.data))
            .catch(() => console.log('error'))
    }, []);
    const dispatch = useAppDispatch();
    useEscapeKey(() => dispatch(setModalAdd(false)), addMealtime, "addMealtime");

    return (
        <Modal id="addMealtime" isOpen={addMealtime}
               toggle={() => setAddMealtime(!addMealtime)}
               modalClassName="zoomIn" tabIndex={-1} centered >
            <ModalHeader toggle={() => setAddMealtime(!addMealtime)}
                         className="p-3 bg-soft-primary">
                Добавление приема пищи
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={
                        {
                            id: selectMealtime.value,
                            price: ''
                        }
                    }
                    validationSchema={
                        Yup.object({
                            id: Yup.string().required('Обязательное поле'),
                            price: Yup.number().typeError('Только числа').required('Обязательное поле')
                        })
                    }
                    onSubmit={(values, { setErrors }) => {
                        const isDuplicate = listMealtime.some(item => item.id == values.id);

                        if (isDuplicate) {
                            setErrors({ id: 'Такой прием пищи уже существует' });
                        } else {
                            setListMealtime([...listMealtime, {id: values.id, name: mealtime.find(m => m.id.toString() === values.id.toString()).name, price: values.price}])
                            setAddMealtime(false)
                        }
                    }}
                >
                    {
                        ({errors, touched, setFieldValue}) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <Col>
                                            <label className="w-100">Прием пищи*:
                                                <Select
                                                    options={mealtime.map(mealtimeValue => ({value: mealtimeValue.id, label: mealtimeValue.name}))}
                                                    value={selectMealtime}
                                                    onChange={(change: any) => {
                                                        setSelectMealtime({value: change.value, label: change.label});
                                                        setFieldValue('id', change.value);
                                                    }}
                                                    styles={customStyles}
                                                    placeholder="Поиск"
                                                    name="id"
                                                />
                                            </label>
                                            {errors.id && touched.id ? (
                                                <div className="text-danger">Такой прием пищи уже существует</div>
                                            ) : null}
                                        </Col>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <Col>
                                            <label className="w-100">Цена (руб.)*:
                                            <Field name="price" className={`form-control ${errors.price && touched.price && "border border-2 border-danger"}`} placeholder="Наименование" />
                                            </label>
                                        </Col>
                                    </Col>
                                </Row>
                                <button type="submit" className="btn btn-primary">Добавить</button>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddMealtime;
