import React, {FC, useState} from 'react';
import DataTable from "react-data-table-component";
import AddLamps from "./AddLamps";

interface  IUpdateLamps{
    addLamp: any,
    setAddLamp(value: any): void
}

const UpdateLamps: FC <IUpdateLamps> = ({addLamp, setAddLamp}) => {
    const [updateLamps, setUpdateLamps] = useState(false);
    const [selectLamps, setSelectLamps] = useState<any | null>(null);

    const columns : any = [
        {
            id: "date",
            name: <span className='font-weight-bold accessibility_size_table mb-0'>Дата</span>,
            selector: (row: any) => row.data,
            cell: (row: any) => <span className='accessibility_size_table'>{row.data}</span>,
            sortable: true,
            center: true,
            width: '120px'
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Группа</span>,
            cell: (row: any) => <span className='accessibility_size_table'>{row.group}</span>,
            center: true,
            compact: true,
            wrap: true
        },
        {
            id: "disinfectant_name",
            name: <span className='font-weight-bold accessibility_size_table'>Наименование</span>,
            cell: (row: any) => <span className="accessibility_size_table">{row.name}</span>,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'></span>,
            cell: (row: any) => <i className="bx bx-trash fs-24 cursor-pointer" onClick={() => {removeDisinfect(row.uid)}}></i>,
            width: '60px'
        }
    ]

    const removeDisinfect = (uid: string) => {
        setAddLamp(addLamp.filter((item: any) => item.uid !== uid));
    }

    return (
        <>
            <DataTable
                columns={columns}
                data={addLamp}
                noDataComponent={'Нет данных'}
                pagination
                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                paginationComponentOptions={
                    {
                        rowsPerPageText: 'Видно льгот:',
                        rangeSeparatorText: 'из'
                    }
                }
                highlightOnHover
                customStyles={{
                    rows: {
                        highlightOnHoverStyle: {
                            backgroundColor: "#ffebb5"
                        },
                    },
                    expanderRow: {
                        style: {
                            backgroundColor: "#ffebb5",
                            paddingTop: '5px',
                            paddingBottom: '5px'
                        },
                    },
                }}
                onRowClicked={(row) => {
                    setSelectLamps(row);
                    setUpdateLamps(true);
                }}
            />
            <button className="btn btn-primary" type="button"
                    onClick={() => {
                        setSelectLamps(null)
                        setUpdateLamps(true)}}>Добавить
            </button>
            <AddLamps updateLamps={updateLamps}
                                            setUpdateLamps={(value: any) => setUpdateLamps(value)}
                                            addLamp={addLamp}
                                            setAddLamp={(value: any) => setAddLamp(value)}
                                            selectLamps={selectLamps}/>
        </>
    );
};

export default UpdateLamps;
