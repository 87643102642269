import React from 'react';
import DataTable from "react-data-table-component";
import {ListGroup, ListGroupItem} from "reactstrap";
import {IEvolutionWay} from "../../../models/parent/IAllInfoEvolution";
import {useAppSelector} from "../../../hooks/redux";

const TableAllInfoEvolutionsChildren = () => {

    const {evolutionWays, allInfoEvolutionChild, selectDatesEvolution} = useAppSelector(state => state.ParentReducer)


    const columnsEvolutions: any = [
        {
            selector: (row: IEvolutionWay) => row.name,
            cell: (row: IEvolutionWay) => <span className="accessibility_size_table">{row.name}</span>,
            sortable: true,
            wrap: true,
            minWidth: '180px'
        },
        {
            cell: (row: IEvolutionWay) => (
                row.schoolyear.map((year, key: number) => {
                    if (selectDatesEvolution.find(k => k.period === year.name)) {
                        return <ListGroup key={key} className={'my-1'}>
                            <ListGroupItem className='table-list-group-hover mb-1' style={{minWidth: '280px'}}>
                                <span className='fw-bold accessibility_size_table'>{year.name}</span>
                                {
                                    year.period.map((date, key: number) => (
                                        <ListGroup key={key}>
                                            <ListGroupItem className='mb-1 table-list-group-hover accessibility_size_table'>
                                                {date.name}
                                                <ul>
                                                    {
                                                        date.teacher.map((info) => (
                                                            info.indicators.map((indicator, key) => (
                                                                <li key={key}
                                                                    className="ms-lg-1 my-1">{indicator.indicator} -
                                                                    <span className="fw-bold "
                                                                          style={{color: indicator.color === '#FFFF00' ? '#ff9900' : indicator.color}}> {indicator.grade}</span>
                                                                </li>
                                                            ))
                                                        ))
                                                    }
                                                </ul>
                                            </ListGroupItem>
                                        </ListGroup>
                                    ))
                                }
                            </ListGroupItem>
                        </ListGroup>
                    }
                    return null
                })
            ),
            wrap: true,
            grow: 4,
            style: {
                display: 'block',
            }
        }
    ];

    return (
        <DataTable
            columns={columnsEvolutions}
            data={allInfoEvolutionChild.length > 0 ?  allInfoEvolutionChild[0].evolution_way?.filter(item => evolutionWays.includes(item.name)) : []}
            noDataComponent={'Выберите развитие'}
            defaultSortAsc={false}
            noTableHead
            highlightOnHover
            customStyles={{
                rows: {
                    highlightOnHoverStyle: {
                        backgroundColor: "#ffebb5"
                    }
                }
            }}
        />
    );
};

export default TableAllInfoEvolutionsChildren;
